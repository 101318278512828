import React from 'react';

import { Layout, LoadingSpinner } from '~/eds';
import { api } from '~/redux';
import { SearchParams } from '~/redux/api/methods';
import { Chart } from '~/types';

import { ChartContainer } from './ChartContainer';
import { EmptyChart } from './EmptyChart';
import { SingleValueChart } from './SingleValueChart';

interface Props {
  chart: Chart;
  searchParams: SearchParams;
  isFullWidth?: boolean;
}
export function SearchResultTotalChart({
  chart,
  searchParams,
  isFullWidth,
}: Props) {
  const {
    data,
    isFetching,
    error,
    isError,
  } = api.endpoints.search.useQueryState(searchParams);
  const height = isFullWidth ? 100 : 300;
  if (isFetching) {
    return (
      <Layout justify="center" align="center" h={height}>
        <LoadingSpinner />
      </Layout>
    );
  }

  function renderChartComponent({ width }: { width: number }) {
    if (!data || !data.meta) {
      return (
        <EmptyChart
          errorCode={error?.response?.status}
          chart={chart}
          isError={isError}
          width={width}
          isDataOverload={false}
        />
      );
    }

    return (
      <SingleValueChart value={data.meta.total} width={width} height={height} />
    );
  }

  return (
    <Layout direction="column" bg="#fff">
      <ChartContainer renderChart={renderChartComponent} />
    </Layout>
  );
}
