import PT from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Checkbox } from '~/eds';
import { Box, Card, FlexLayout } from '~/ui';

function FieldsCard({
  field,
  fieldsChosen = [],
  shouldCollapse,
  onFieldSelect,
}) {
  const [fieldSectionSelected, setFieldSectionSelect] = useState(false);
  const [fieldsSelected, setFieldsSelect] = useState(
    field.fields.filter((f) => f.value && f.selected).map((itm) => itm.value),
  );
  const [isCollapsed, setIsCollapsed] = useState(shouldCollapse);

  useEffect(() => {
    setIsCollapsed(shouldCollapse);
  }, [shouldCollapse]);

  useEffect(() => {
    if (!fieldsChosen.length) {
      setFieldSectionSelect(false);
      setFieldsSelect([]);
    } else {
      setFieldsSelect(fieldsChosen);
    }
  }, [fieldsChosen]);

  useEffect(() => {
    if (
      field.fields
        .map((itm) => itm.value)
        .every((f) => fieldsChosen.includes(f))
    ) {
      setFieldSectionSelect(true);
    }
  }, [field.fields, fieldsChosen]);

  const header = (
    <Card.Header
      title={
        <FlexLayout
          alignItems="center"
          pl={1}
          space={2}
          onClick={(e) => e.stopPropagation()}
        >
          <Checkbox
            label={field.sectionName}
            option={{
              label: field.sectionName,
              value:
                fieldsChosen.length && fieldsChosen.length < field.fields.length
                  ? null
                  : fieldSectionSelected,
            }}
            value={
              fieldsChosen.length && fieldsChosen.length < field.fields.length
                ? null
                : fieldSectionSelected
            }
            onChange={() => handleAllCheckboxClick(!fieldSectionSelected)}
          />
        </FlexLayout>
      }
    />
  );

  function handleAllCheckboxClick(isSelected) {
    setFieldSectionSelect(isSelected);
    if (isSelected) {
      setFieldsSelect(field.fields.map((item) => item.value));
      onFieldSelect(
        field.sectionName,
        field.fields.map((item) => item.value),
      );
    } else {
      setFieldsSelect([]);
      onFieldSelect(field.sectionName, []);
    }
  }

  function handleFieldCheckboxClick(itemValue) {
    let newSelectedItems = [...fieldsSelected];

    if (fieldsSelected.includes(itemValue)) {
      newSelectedItems = newSelectedItems.filter((item) => item !== itemValue);
      setFieldSectionSelect(false);
    } else {
      newSelectedItems.push(itemValue);
    }
    setFieldsSelect(newSelectedItems);
    onFieldSelect(field.sectionName, newSelectedItems);
  }

  return (
    <Card
      collapseIconPosition="right"
      header={header}
      isBordered={false}
      isCollapsed={isCollapsed}
      onToggleCollapse={() => setIsCollapsed(!isCollapsed)}
    >
      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
        {field.fields.map((item) => {
          return (
            <FlexLayout
              alignItems="center"
              px={2}
              py={2}
              space={2}
              key={`field_id=${item.value}`}
            >
              <Checkbox
                label={item.label}
                option={{
                  label: item.label,
                  value:
                    fieldsSelected.includes(item.value) ||
                    fieldsChosen.includes(item.value),
                }}
                value={
                  fieldsSelected.includes(item.value) ||
                  fieldsChosen.includes(item.value)
                }
                onChange={() => handleFieldCheckboxClick(item.value)}
              />
            </FlexLayout>
          );
        })}
      </Box>
    </Card>
  );
}

FieldsCard.propTypes = {
  field: PT.object.isRequired,
  fieldsChosen: PT.array,
  onFieldSelect: PT.func.isRequired,
};

export default FieldsCard;
