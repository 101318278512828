import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { workflowRemoveQuestion, workflowSetSectionQuestion } from '~/actions';
import ActionsMenu from '~/components/Shared/ActionsMenu';
import ConditionRuleInput from '~/components/Workflow/shared/ConditionRuleInput';
import DescriptionTextAreaForm from '~/components/Workflow/shared/DescriptionTextAreaForm';
import FieldSelect from '~/components/Workflow/shared/FieldSelect';
import { Button } from '~/eds';
import { FlagType, useFlag } from '~/flags';
import { getUsedFormFieldIds } from '~/reducers/workflow';
import { Box, Checkbox, EditableText, FlexLayout } from '~/ui';
import { PHASE_TITLE_CHARACTER_LIMIT } from '~/ui/enums';
import {
  coerceName,
  DefaultCustomSettings as defaultCustomSettings,
  getDefaultCustomSettingsByFieldType,
} from '~/utils/workflow';

import FieldSettings from './FieldSettings';
import { testIsInvalidQuestion } from './QuestionEditable.utils';

function QuestionEditable({
  question = {},
  sectionId,
  onClose,
  onReorder,
  // connected
  fields,
  usedFormFieldIds,
  workflowRemoveQuestion,
  workflowSetSectionQuestion,
}) {
  const initialFieldId = question.fieldId;
  const [questionFieldId, setQuestionFieldId] = useState(initialFieldId);
  const questionField = fields[questionFieldId];
  const hasQuestionFieldChanged = questionFieldId !== initialFieldId;

  const [questionTitle, setQuestionTitle] = useState(question.name);
  const [
    questionFieldCustomSettings,
    setQuestionFieldCustomSettings,
  ] = useState(questionField?.customSettings || defaultCustomSettings);
  const [questionDescription, setQuestionDescription] = useState(
    question.description,
  );
  const [questionRequired, setQuestionRequired] = useState(question.isRequired);
  const [questionReadOnly, setQuestionReadOnly] = useState(question.isReadOnly);
  const [questionConditionRule, setQuestionConditionRule] = useState(
    question.conditionRule,
  );

  const isIntakeFormFieldWithReadOnlyEnabled = useFlag(
    FlagType.IntakeFormFieldWithReadOnly,
  );

  useEffect(() => {
    // reset custom settings if they are empty or if the field changed
    if (
      questionField &&
      (isEmpty(questionField.customSettings) || hasQuestionFieldChanged)
    ) {
      const customSettings = getDefaultCustomSettingsByFieldType(
        questionField.type,
      );

      setQuestionFieldCustomSettings({ ...customSettings });
    }
  }, [questionField, questionFieldId]);

  function handleOnSaveClick() {
    const data = {
      id: question.id,
      name: questionTitle,
      fieldId: questionFieldId,
      questionFieldCustomSettings,
      description: questionDescription,
      isRequired: !!questionRequired,
      conditionRule: questionConditionRule,
      creating: false,
    };

    if (isIntakeFormFieldWithReadOnlyEnabled) {
      data.isReadOnly = !!questionReadOnly;
    }

    workflowSetSectionQuestion({
      sectionId,
      data,
      fieldCustomSettings: questionFieldCustomSettings,
    });
    onClose();
  }

  function handleOnCancelClick() {
    if (question.creating && question.id) {
      workflowRemoveQuestion({ sectionId, questionId: question.id });
    } else {
      onClose();
    }
  }

  const questionName = coerceName(
    questionTitle,
    'Untitled Question',
    question.position,
  );

  const getMenuItems = () => {
    const items = [];

    items.push({
      content: 'Reorder Questions',
      onClick: () => onReorder(),
    });

    items.push({
      content: 'Delete Question',
      onClick: () =>
        workflowRemoveQuestion({ sectionId, questionId: question.id }),
    });

    return items;
  };

  const isNameInvalid = questionTitle?.length > PHASE_TITLE_CHARACTER_LIMIT;

  return (
    <FlexLayout flexDirection="column" bg="gray-200" sx={{ borderRadius: 'm' }}>
      <FlexLayout
        alignItems="center"
        px={6}
        py={3}
        space={21}
        sx={{ borderBottom: 'border', minHeight: 'card-header-height' }}
      >
        <EditableText
          id={`workflow--form-edit-question-name=${questionName}-name`}
          placeholder="Untitled Question"
          value={questionTitle}
          onChange={setQuestionTitle}
          error={
            isNameInvalid
              ? `Your title may contain up to ${PHASE_TITLE_CHARACTER_LIMIT} characters.`
              : null
          }
        />
        <FlexLayout alignItems="center" flexShrink="0" space={6}>
          {isIntakeFormFieldWithReadOnlyEnabled && (
            <Checkbox
              id={`workflow--form-set-question-name=${questionName}-read-only`}
              label="Read Only in Evisort"
              value={questionReadOnly}
              onChange={() => setQuestionReadOnly(!questionReadOnly)}
            />
          )}
          <Checkbox
            id={`workflow--form-set-question-name=${questionName}-required`}
            label="Required question"
            value={questionRequired}
            onChange={() => setQuestionRequired(!questionRequired)}
          />
          <ActionsMenu
            id={`workflow_builder_form_question_actions_menu_id=${questionFieldId}`}
            items={getMenuItems()}
            align="end"
          />
        </FlexLayout>
      </FlexLayout>
      <Box mx={6} py={3} sx={{ borderBottom: 'border' }}>
        <DescriptionTextAreaForm
          description={questionDescription}
          id={`workflow--form-edit-question-name=${questionName}-description`}
          noDescriptionMessage="Provide a description (optional)..."
          onUpdate={setQuestionDescription}
        />
      </Box>
      <FlexLayout
        flexDirection="column"
        mx={6}
        py={6}
        space={6}
        sx={{ borderBottom: 'border' }}
      >
        <FieldSelect
          enableAddField
          shouldShowLabel
          blacklistValues={usedFormFieldIds.filter(
            (usedFormFieldId) => usedFormFieldId !== initialFieldId,
          )}
          id={`workflow--form-edit-question-name=${questionName}-field-select`}
          placeholder="Not connected"
          value={questionFieldId}
          width="l"
          onChange={setQuestionFieldId}
        />
        {questionField && (
          <FieldSettings
            customSettings={questionFieldCustomSettings}
            fieldType={questionField.type}
            onUpdate={setQuestionFieldCustomSettings}
          />
        )}
      </FlexLayout>
      <Box p={6} sx={{ borderBottom: 'border' }}>
        <ConditionRuleInput
          conditionRule={questionConditionRule}
          id={`workflow--form-edit-question-name=${questionName}-condition-rule`}
          onChange={setQuestionConditionRule}
        />
      </Box>
      <FlexLayout
        alignItems="center"
        justifyContent="flex-end"
        px={6}
        py={3}
        space={4}
      >
        <Button text="Cancel" onClick={handleOnCancelClick} />
        <Button
          disabled={
            testIsInvalidQuestion(
              questionField,
              questionFieldCustomSettings,
              questionTitle,
            ) || isNameInvalid
          }
          text="Save"
          onClick={handleOnSaveClick}
        />
      </FlexLayout>
    </FlexLayout>
  );
}

const mapStateToProps = ({ workflow }) => ({
  fields: workflow.fields,
  usedFormFieldIds: getUsedFormFieldIds(workflow),
});

export default connect(mapStateToProps, {
  workflowRemoveQuestion,
  workflowSetSectionQuestion,
})(QuestionEditable);
