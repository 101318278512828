import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { RETRY_ERROR } from '~/constants/errors';
import { MAX_LENGTH_TEXTAREA } from '~/constants/form';
import {
  Button,
  CheckboxGroups,
  FormField,
  Layout,
  Panel_DEPRECATED,
  SearchInput,
  StatusMessage,
  Text,
  TextArea,
  TextInput,
  useDebouncedState,
  useToggle,
} from '~/eds';
import { RoleType } from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { useCurrentUser } from '~/hooks';
import { NOT_IMPLEMENTED_PERMISSIONS } from '~/permissions';
import { api } from '~/redux';
import { PermissionGroupAttributes, RoleAttributes } from '~/redux/api/methods';
import { Nullable } from '~/types';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { openInNewTab } from '~/utils/browser';

import { trackSegment } from '../SegmentAnalytics';
import { showToast } from '../Shared/EcToast';

type Props = {
  visible: boolean;
  isDuplicate?: boolean;
  onHide: () => void;
  role: Nullable<RoleAttributes>;
  clientId?: string;
};

type PermissionGroupName = string;
type PermissionsIds = string[];
type PermissionsDict = Nullable<Record<PermissionGroupName, PermissionsIds>>;

export const RoleFormPanel = ({
  visible,
  role,
  isDuplicate,
  onHide,
  clientId,
}: Props) => {
  const user = useCurrentUser();
  const [isEnlarged, toggleIsEnlarged] = useToggle(false);
  const [search, setSearch] = useState<Nullable<string>>('');
  const [permissionsDict, setPermissionsDict] = useState<PermissionsDict>({});
  const [debouncedName, name, setName] = useDebouncedState<Nullable<string>>(
    '',
  );
  const [description, setDescription] = useState<Nullable<string>>('');
  const permissionsList = Object.values(permissionsDict ?? {}).flat();

  const hasPremiumPermissions = useFlag(FlagType.PremiumPermissions);
  const hasAskAnything = useFlag(FlagType.AskAnything);

  const {
    data: permissionGroups,
    isFetching,
  } = api.endpoints.getPermissions.useQuery({ hasAskAnything });

  const [createRole, createRoleResult] = api.endpoints.createRole.useMutation();
  const {
    isLoading: isLoadingCreate,
    isError: isErrorCreate,
    isSuccess: isSuccessCreate,
  } = createRoleResult;

  const [updateRole, updateRoleResult] = api.endpoints.updateRole.useMutation();
  const {
    isLoading: isLoadingRoles,
    isError: isErrorUpdate,
    isSuccess: isSuccessUpdate,
  } = updateRoleResult;

  const [
    validateRoleName,
    validateRoleNameResult,
  ] = api.endpoints.validateRoleName.useLazyQuery();
  const {
    isLoading: isValidatingRoleName,
    data: validateRoleNameData,
  } = validateRoleNameResult;

  const { isUnique: isRoleNameUnique } = validateRoleNameData?.attributes ?? {};

  const additionalPermissions = useMemo(() => {
    const rolePermissions = role?.permissions ?? [];
    return NOT_IMPLEMENTED_PERMISSIONS.filter((permission) =>
      rolePermissions.includes(permission),
    );
  }, [role?.permissions]);

  const noChanges = !role
    ? !name && !description && permissionsList.length === 0
    : name === role.name &&
      description === role.description &&
      permissionsList.length === role.permissions?.length &&
      permissionsList.every((permission) =>
        role.permissions?.includes(permission),
      );

  const isDebouncedNameValid = debouncedName !== null && debouncedName !== '';
  const shouldValidateRoleName =
    isDuplicate || !role
      ? isDebouncedNameValid
      : isDebouncedNameValid &&
        role?.name.toLowerCase() !== debouncedName?.toLowerCase();

  useEffect(() => {
    if (shouldValidateRoleName) {
      validateRoleName({ name: debouncedName! });
    }
  }, [debouncedName, shouldValidateRoleName]);

  const hasRoleNameError =
    isDuplicate || !role
      ? isRoleNameUnique === false
      : isRoleNameUnique === false && name !== role?.name;

  const isSaveDisabled =
    !name || permissionsList.length === 0 || noChanges || hasRoleNameError;

  const isPreBuiltAdmin =
    role?.name?.toLowerCase().includes('admin') &&
    role.role_type === RoleType.PreBuiltRole;

  const handleSubmitRole = useCallback(() => {
    const params = {
      name: name!,
      description: description ?? '',
      permissions: role
        ? [...permissionsList, ...additionalPermissions]
        : permissionsList,
      client_id: clientId ?? user?.client?.toString(),
    };
    if (isDuplicate || !role) {
      createRole(params);
      trackSegment(isDuplicate ? 'duplicateRole' : 'createRole', {
        ...params,
        user_id: user.id,
        client_id: user.client,
      });
    } else {
      updateRole({ roleId: role.id, params });
      trackSegment('updateRole', {
        ...params,
        user_id: user.id,
        client_id: user.client,
      });
    }
  }, [
    isDuplicate,
    role,
    permissionsList,
    additionalPermissions,
    name,
    description,
    user,
  ]);

  const handleHide = () => {
    setName('');
    setDescription('');
    setPermissionsDict({});
    onHide();
  };

  useEffect(() => {
    if (isSuccessCreate) {
      showToast(SUCCESS, `"${name}" was successfully created `);
      handleHide();
    }
  }, [isSuccessCreate]);

  useEffect(() => {
    if (isSuccessUpdate) {
      showToast(SUCCESS, `"${name}" was successfully updated `);
      handleHide();
    }
  }, [isSuccessUpdate]);

  useEffect(() => {
    if (isErrorCreate) {
      showToast(ERROR, RETRY_ERROR);
    }
  }, [isErrorCreate]);

  useEffect(() => {
    if (isErrorUpdate) {
      showToast(ERROR, RETRY_ERROR);
    }
  }, [isErrorUpdate]);

  useEffect(() => {
    if (role) {
      setName(isDuplicate ? `${role.name} (1)` : role.name);
      setDescription(role.description);
      const initialPermissionsDict = role.permissions?.reduce(
        (dict, permission) => {
          const groupName = permissionGroups?.find((group) =>
            group.permissions.some((p) => p.id === permission),
          )?.name;
          if (groupName) {
            dict[groupName] = [...(dict[groupName] ?? []), permission];
          }
          return dict;
        },
        {} as Record<PermissionGroupName, PermissionsIds>,
      );
      setPermissionsDict(initialPermissionsDict);
    }
  }, [role, permissionGroups, isDuplicate]);

  const nonDuplicateTitle = role ? `Edit ${role.name}` : 'New Role';
  const title = isDuplicate
    ? `Duplicate ${role?.name ?? 'Role'}`
    : nonDuplicateTitle;

  return (
    // eslint-disable-next-line react/jsx-pascal-case -- deprecating
    <Panel_DEPRECATED
      actions={[
        {
          icon: 'maximize',
          isActive: isEnlarged,
          label: isEnlarged ? 'Condense' : 'Enlarge',
          value: isEnlarged ? 'Condense' : 'Enlarge',
          onClick: toggleIsEnlarged,
        },
      ]}
      isVisible={visible}
      width={isEnlarged ? 'fill' : 'l'}
      title={title}
      onHide={handleHide}
      isPadded={true}
      mode="blocking"
      isLoading={isFetching}
      footer={
        <Layout spacing="2" justify="flex-end" borderTop="border" p="3">
          <Button variant="tertiary" text="Cancel" onClick={handleHide} />
          <Button
            variant="primary"
            text="Save"
            onClick={handleSubmitRole}
            isLoading={
              isLoadingCreate || isLoadingRoles || isValidatingRoleName
            }
            disabled={isSaveDisabled}
          />
        </Layout>
      }
    >
      <Layout direction="column" spacing="2">
        {!hasPremiumPermissions && (
          <StatusMessage
            message="Custom roles are available with the Advanced Administration add-on. Contact your CSM to learn more."
            status="active"
            action={{
              text: 'Click here',
              onClick: () => openInNewTab('mailto:success@evisort.com'),
            }}
          />
        )}
        {isPreBuiltAdmin && (
          <StatusMessage
            title="Some Administrator permissions cannot be duplicated"
            message="Administrators can access all folders, documents and Workflow tickets; can manage folder access settings; and, can manage sharing settings for shared saved searches and custom dashboards. To grant these permissions, assign a user to the Administrator role."
            action={{
              text: 'Learn more',
              onClick: () => {
                openInNewTab(
                  'https://support.evisort.com/hc/en-us/articles/360042492434-Admin-How-To-Set-User-Permissioning-with-Access-Levels-and-Departments#h_de1ecf9e-e395-4937-8bca-ad207b1ad2e9',
                );
              },
            }}
          ></StatusMessage>
        )}
        {isDuplicate ? (
          <Text>Duplicate and modify role information and permissions.</Text>
        ) : (
          <Text>
            {role
              ? 'Edit custom role information and permissions.'
              : 'Create a custom role to apply to users in your organization.'}
          </Text>
        )}

        <Text variant="section">General Information</Text>

        <Layout direction="column" spacing="1">
          <FormField
            input={TextInput}
            label="Role name"
            placeholder="e.g. User Account Admin"
            name="name"
            value={name}
            required={true}
            onChange={(name) => setName(name as string)}
            error={
              hasRoleNameError && isDebouncedNameValid
                ? `The ${debouncedName} role already exists. Looks like you want to assigning users to an existing role instead of creating a New Role.`
                : ''
            }
            disabled={!hasPremiumPermissions}
          />
        </Layout>
        <Layout direction="column" spacing="1">
          <Text variant="body-bold">
            Role Description{' '}
            <Text variant="tiny" color="text.secondary">
              (Optional)
            </Text>
          </Text>
          <Text preset="description">
            Add a clear description to explain the purpose of the role for
            reference.
          </Text>
          <TextArea
            placeholder="e.g. Manages user accounts and system permissions"
            name="description"
            value={description}
            onChange={setDescription}
            maxLength={MAX_LENGTH_TEXTAREA}
            disabled={!hasPremiumPermissions}
          ></TextArea>
          <Text variant="tiny" color="text.secondary" fontWeight="500">
            Maximum {MAX_LENGTH_TEXTAREA} characters
          </Text>
        </Layout>
      </Layout>

      <Layout mt="6" direction="column" spacing="2">
        <Text variant="section">Permission Settings</Text>
        <Text>
          Select the permissions you wish to include in this custom role.
        </Text>
        <SearchInput
          width="100%"
          name="search"
          value={search}
          onChange={setSearch}
          placeholder="Search for permission..."
        />

        <CheckboxGroups
          mode="descriptive"
          groups={(permissionGroups ?? [])
            .map((group) => asOptionGroup(group, hasPremiumPermissions))
            .map((group) => ({
              ...group,
              disabled: !hasPremiumPermissions,
            }))}
          value={permissionsDict}
          onChange={setPermissionsDict}
          search={search ?? ''}
        />
      </Layout>
    </Panel_DEPRECATED>
  );
};

const asOptionGroup = (
  permission: PermissionGroupAttributes,
  hasPremiumPermissions = false,
) => {
  return {
    label: permission.name,
    name: permission.name,
    options: permission.permissions.map((permission) => ({
      label: permission.name,
      description: permission.description,
      value: permission.id,
      disabled: hasPremiumPermissions ? false : !!permission.premium,
    })),
  };
};
