import {
  formatDistance,
  format as formatFn,
  formatISO,
  formatISO9075,
} from 'date-fns';

import { DATE_TIME_FORMAT_PARAMS } from '../constants';
import { HtmlEntityType } from '../enums';
import { DateFormat, Nullable } from '../types';

/**
 * Formats a date based on Intl.DateTimeFormat.
 * */
export const formatDate = (
  date: Nullable<Date>,
  format: DateFormat = 'iso',
): string => {
  if (date === null) {
    return HtmlEntityType.Dash;
  }

  const params = DATE_TIME_FORMAT_PARAMS[format];
  switch (format) {
    case 'relative':
      return formatDistance(date, new Date(), { addSuffix: true });
    case 'full':
      return formatFn(date, "yyyy-MM-dd 'at' hh:mm a");
    case 'utc':
      // YYYY-MM-DDTHH:MM:SSZ
      return date.toISOString().split('.')[0] + 'Z';
    case 'iso_date':
      return formatISO(date, { representation: 'date' });
    case 'iso_datetime':
      // YYYY-MM-DD HH:MM:SS
      return formatISO9075(date);
    case 'iso':
    default:
      return Intl.DateTimeFormat(...params).format(date);
  }
};
