import { useSelector } from 'react-redux';
import { matchPath, useHistory } from 'react-router-dom';

import { Entity, EntityContext, EntityType } from '~/types';

import {
  selectDocument,
  selectDocumentGroup,
  selectFolderTree,
} from './selectors';
import {
  DEFAULT_CHAT_CONTEXT,
  DEFAULT_DOCUMENTS_ID,
  DEFAULT_SEARCH_ID,
  getDocumentGroupQuery,
  getFolderContext,
  pathMatch,
  ROUTES,
} from './utils';

const resolveRoute = (pathname: string) => {
  const routePath = Object.values(ROUTES).find((route) => {
    return matchPath(pathname, { path: route });
  });
  return routePath ?? null;
};

interface UseResolveChatBotEntityProps {
  entity: Entity<EntityType>;
  context: EntityContext | undefined;
  isEntityLoading?: boolean;
}

export const useResolveChatBotEntity = (): UseResolveChatBotEntityProps => {
  const history = useHistory();
  const resolvedRoute = resolveRoute(history.location.pathname);
  let isEntityLoading = false;

  // redux store selectors
  const document = useSelector(selectDocument);
  const group = useSelector(selectDocumentGroup);
  const folderTree = useSelector(selectFolderTree);

  const context = [DEFAULT_CHAT_CONTEXT];

  switch (resolvedRoute) {
    case ROUTES.ticketViewPage:
    case ROUTES.reviewerPage:
      if (document) {
        return {
          entity: {
            type: 'document_version',
            id: String(document.versionId),
          },
          context: [
            {
              query: [],
              label: 'This Document Version',
              title: document.name,
              icon: document.type === '.pdf' ? 'file-pdf' : 'file-doc',
              primary: true,
              searchEntity: 'document',
              selectedIds: [document.id.toString()],
              id: document.id.toString(),
            },
          ],
        };
      }
      isEntityLoading = true;
      break;
    case ROUTES.folder:
      const folderId = Number(
        pathMatch(history.location.pathname, ROUTES.folder),
      );
      const folderContext = getFolderContext(folderId, folderTree, true);
      if (folderContext) context.push(folderContext);

      return {
        entity: { type: 'all_documents', id: folderId.toString() },
        context,
      };
    case ROUTES.document:
      if (document) {
        const folderContext = getFolderContext(document.folder, folderTree);
        if (folderContext) context.push(folderContext);
        if (group) {
          context.push({
            label: 'This Group',
            query: getDocumentGroupQuery(String(group.groupId)),
            title: group.name,
            icon: 'groups',
            searchEntity: 'document',
            selectedIds: [],
            id: String(group.groupId),
          });
        }
        context.push({
          label: 'This Document',
          title: document.name,
          icon: document.type === '.pdf' ? 'file-pdf' : 'file-doc',
          primary: true,
          searchEntity: 'document',
          query: [],
          selectedIds: [document.id.toString()],
          id: document.id.toString(),
        });

        return {
          entity: {
            type: 'document_version',
            id: String(document.versionId),
          },
          context,
        };
      }
      break;
    case ROUTES.dashboard:
      return {
        entity: {
          type: 'all_documents',
          id: pathMatch(history.location.pathname, ROUTES.dashboard)!,
        },
        context: undefined,
      };
    case ROUTES.search:
    case ROUTES.savedSearch:
      return {
        entity: {
          type: 'all_documents',
          id:
            pathMatch(history.location.pathname, ROUTES.savedSearch) ??
            DEFAULT_SEARCH_ID,
        },
        context: undefined,
      };
    case ROUTES.documentGroup:
      return {
        entity: {
          type: 'all_documents',
          id: pathMatch(history.location.pathname, ROUTES.documentGroup)!,
        },
        context: undefined,
      };
    default:
      break;
  }

  return {
    entity: {
      type: 'all_documents',
      id: DEFAULT_DOCUMENTS_ID,
    },
    context: [
      {
        ...DEFAULT_CHAT_CONTEXT,
        primary: true,
      },
    ],
    isEntityLoading,
  };
};
