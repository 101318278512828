import React from 'react';

import { Box, FlexLayout, Icon } from '~/ui';

function OoDocsViewerEditModeControls({
  disableEdit,
  editMode,
  tooltip,
  setEditMode,
}) {
  function getColorByMode(activeMode) {
    return activeMode ? 'blue-500' : 'gray-600';
  }

  return (
    <FlexLayout
      pt={4}
      space={4}
      alignItems="center"
      flexDirection="column"
      sx={{ borderRight: 'border', minWidth: '64px' }}
    >
      <Box>
        <Icon
          color={getColorByMode(!editMode)}
          icon="view"
          title="View mode"
          onClick={() => setEditMode(false)}
        />
      </Box>
      <Box>
        <Icon
          color={getColorByMode(editMode)}
          disabled={disableEdit}
          icon="edit"
          title="Edit mode"
          tooltip={tooltip}
          onClick={() => setEditMode(true)}
        />
      </Box>
    </FlexLayout>
  );
}

export default OoDocsViewerEditModeControls;
