import { capitalize } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { PersistedTable } from '~/components/Shared/PersistedTable';
import { User } from '~/components/Shared/User';
import { Chip } from '~/eds';
import { TableContextType } from '~/enums';
import { useTableSettings } from '~/hooks';
import { actions, api } from '~/redux';
import { RoutePathType, useRouting } from '~/routing';

import { ModelPreviewSearchFilters } from '../fields/shared/ModelPreviewSearchFilters';
import { FieldModel } from '../fields/types';
import { ModelResultProgress } from '../ModelResults/ModelResultProgress';
import { ModelActionType } from '../types';
import { getModelOptimizeStateChip, testIsActivePublishStatus } from '../utils';

interface Props {
  models: FieldModel[];
  pageIndex: number;
  totalCount: number;
}

export const ModelList = ({ models, pageIndex, totalCount }: Props) => {
  const { history, setSearchParams } = useRouting();
  const dispatch = useDispatch();
  const navigateToModelDetails = (model: FieldModel) => {
    history.push(`${RoutePathType.AutomationHubFields}/${model.id}`);
  };

  const handleEditModel = (model: FieldModel) => {
    navigateToModelDetails(model);
  };

  const [
    updatePromptModelProgress,
    { isLoading: isUpdatingPromptModelProgress },
  ] = api.endpoints.updatePromptModelProgress.useMutation();

  const {
    tableSettings = {
      pageSize: 10,
    },
  } = useTableSettings(TableContextType.FieldAi);

  const { pageSize } = tableSettings;

  const columns = useMemo(
    () => [
      {
        key: 'name',
        cellType: 'link',
        disableSortBy: true,
        title: 'Field Model',
        mapCellProps: (model: FieldModel) => ({
          text: model.name,
          pathname: `${RoutePathType.AutomationHubFields}/${model.id}`,
          onClick: () => {
            if (model.optimizeState) {
              dispatch(
                actions.setFieldAiOptimize({
                  modelId: model.id,
                  version: model.version,
                }),
              );
            }
            handleEditModel(model);
          },
        }),
      },
      {
        key: 'scope',
        cellType: 'text',
        disableSortBy: true,
        title: 'Scope',
        width: 'l',
        renderCell: (model: FieldModel) => (
          <ModelPreviewSearchFilters model={model} />
        ),
      },
      {
        key: 'docsInScope',
        align: 'right',
        disableSortBy: true,
        title: 'Documents in Scope',
        width: 'm',
        renderCell: (model: FieldModel) => (
          <ModelResultProgress modelId={model.id} metric="docsInScope" />
        ),
      },
      {
        key: 'valuesFound',
        align: 'right',
        disableSortBy: true,
        title: 'Values Found',
        width: 'm',
        renderCell: (model: FieldModel) => (
          <ModelResultProgress modelId={model.id} metric="valuesFound" />
        ),
      },
      {
        key: 'modifiedBy',
        cellType: 'user',
        disableSortBy: true,
        title: 'Modified By',
        mapCellProps: ({ modifiedBy }: FieldModel) => ({
          asyncUser: {
            id: modifiedBy,
            render: User,
          },
          mode: 'avatar-name',
        }),
      },
      {
        key: 'modifiedDate',
        cellType: 'datetime',
        disableSortBy: true,
        title: 'Modified Date',
        mapCellProps: ({ modifiedDate }: FieldModel) => ({
          datetime: modifiedDate,
          format: 'short',
        }),
      },
      {
        key: 'publishStatus',
        disableSortBy: true,
        title: 'Status',
        width: 'm',
        renderCell: (model: FieldModel) => {
          if (model.optimizeState) {
            const { status, text } = getModelOptimizeStateChip(
              model.optimizeState,
            );
            return <Chip icon="ai" status={status} text={text} />;
          }
          return (
            <ModelResultProgress
              initialPublishStatus={model.publishStatus}
              modelId={model.id}
              metric="publishStatus"
            />
          );
        },
      },
    ],
    [],
  );

  const getRowActions = useCallback((model: FieldModel) => {
    const { publishStatus } = model;
    const modelActions = [
      publishStatus === 'suspended'
        ? 'resume'
        : testIsActivePublishStatus(publishStatus)
        ? 'suspend'
        : null,
      'delete',
    ].filter((action) => action !== null) as ModelActionType[];

    return modelActions.map((action) => ({
      key: action,
      label: capitalize(action),
      isLoading: isUpdatingPromptModelProgress,
      onClick: () => {
        if (action === 'resume') {
          updatePromptModelProgress({ modelId: model.id });
          dispatch(actions.setFieldAiActiveModel(model));
        }
        dispatch(actions.setFieldAiModelAction({ model, action }));
      },
    }));
  }, []);

  const handlePaginate = useCallback(
    ({ pageIndex }: { pageIndex: number }) => {
      setSearchParams({
        pageIndex: String(pageIndex),
      });
    },
    [pageIndex],
  );

  const handleShowDetails = (model: FieldModel) => {
    dispatch(actions.setFieldAiActiveModel(model));
  };

  return (
    <PersistedTable
      name="field-model-list"
      columns={columns}
      context={TableContextType.FieldAi}
      data={models}
      getRowActions={getRowActions}
      options={{
        enableExportXlsx: false,
        enableSelectRows: false,
      }}
      reactTableOptions={{
        autoResetHiddenColumns: false,
        autoResetResize: false,
      }}
      state={{
        pageIndex,
        pageSize,
      }}
      totalCount={totalCount}
      onPaginate={handlePaginate}
      rowDetails={{
        onClick: handleShowDetails,
      }}
    />
  );
};
