import { orderBy, uniqBy } from 'lodash';
import { useMemo } from 'react';

import { PAGE_SIZE } from '~/constants/page';
import { selectWidths } from '~/constants/selectWidths';
import { Box, DepartmentSelect as EdsDepartmentSelect, types } from '~/eds';
import { withClient } from '~/hocs';
import { useDebouncedCallback } from '~/hooks';
import { api } from '~/redux';
import {
  DepartmentV2,
  DEPRECATED_Client,
  NormalizedDepartments,
  PilotId,
} from '~/types';
import { normalizeDepartmentTree } from '~/utils/departments';

import {
  transformDeletedDepartmentToOption,
  transfromDepartmentsToOptions,
} from '../UserDepartmentSelect/UserDepartmentSelect';

type SelectWidths = 's' | 'm' | 'l' | 'fullWidth';

interface Props<M>
  extends Omit<types.SharedSelectProps<types.PilotId, M>, 'width'> {
  /** Options that we want to hide */
  notAvailableDepartments?: types.PilotId[];
  /** Options we want to retrict the user to */
  specificDepartments?: types.PilotId[];
  /** Width of the country select. It defaults to 'm' */
  width?: SelectWidths;
  // connected
  client: DEPRECATED_Client;
  /** Fetch departments of a specific client instead of current client (mostly for super admin) */
  clientId?: PilotId;
}

const DepartmentSelect = <M extends boolean>({
  notAvailableDepartments,
  client,
  clientId,
  error,
  isMulti,
  value,
  specificDepartments,
  width = 'm',
  onChange,
  name,
  ...rest
}: Props<M>) => {
  const {
    data: clientDepartmentsTree,
    isFetching: isFetchingDepartmentsTree,
  } = api.endpoints.getClientDepartmentsTree.useQuery(clientId, {
    skip: !clientId,
  });

  const clientOverride = clientId
    ? {
        id: clientId,
        departmentTree: clientDepartmentsTree,
      }
    : undefined;

  const currentClient = clientOverride ?? client;

  const [
    findDepartments,
    departmentsResult,
  ] = api.endpoints.findDepartmentsByName.useLazyQuery();
  const {
    error: fetchDepartmentsError,
    isFetching: isFetchingDepartments,
  } = departmentsResult;
  const initialSearchParams = useMemo(
    () =>
      currentClient.id
        ? { clientId: currentClient.id, pageSize: PAGE_SIZE, query: '' }
        : null,
    [currentClient.id],
  );
  const debouncedFindDepartments = useDebouncedCallback(
    findDepartments,
    initialSearchParams,
  );
  const fetchedDepartments = departmentsResult?.data?.results || [];

  const departments: NormalizedDepartments = useMemo(
    () =>
      normalizeDepartmentTree(
        currentClient.departmentTree ?? [],
      ) as NormalizedDepartments,
    [currentClient.departmentTree],
  );

  const preloadedDepartments = useMemo(() => {
    const departmentOptions: DepartmentV2[] = [];
    if (Array.isArray(value)) {
      value?.forEach((val: types.PilotId) => {
        const typedValue = val as number;
        if (departments[typedValue]) {
          departmentOptions.push(
            transfromDepartmentsToOptions(departments[typedValue], departments),
          );
        } else {
          departmentOptions.push(
            transformDeletedDepartmentToOption(typedValue),
          );
        }
      });
    } else {
      // if there is no value, we should skip
      if (value) {
        const typedValue = value as number;
        if (departments[typedValue]) {
          departmentOptions.push(
            transfromDepartmentsToOptions(departments[typedValue], departments),
          );
        } else {
          departmentOptions.push(
            transformDeletedDepartmentToOption(typedValue),
          );
        }
      }
    }

    if (specificDepartments?.length) {
      specificDepartments.forEach((depId: types.PilotId) => {
        const typedDepId = depId as number;
        if (
          departments[typedDepId] &&
          !departmentOptions.find((department) => department.id === typedDepId)
        ) {
          departmentOptions.push(
            transfromDepartmentsToOptions(departments[typedDepId], departments),
          );
        }
      });
    }

    return departmentOptions;
  }, [value, departments]);

  const allDepartments = useMemo(() => {
    // this guarantees that we only show the first PAGE_SIZE departments with the preloaded departments
    const deps = uniqBy(
      [...preloadedDepartments, ...fetchedDepartments],
      'id',
    ).slice(0, PAGE_SIZE);
    return orderBy(deps, 'name', 'asc');
  }, [fetchedDepartments, preloadedDepartments]);

  const filterOptions = (options: DepartmentV2[]) =>
    options.filter((option) => {
      const isWhitelisted =
        specificDepartments?.length && specificDepartments?.includes(option.id);
      const isNotBlacklisted =
        notAvailableDepartments?.length &&
        !notAvailableDepartments?.includes(option.id);

      return isWhitelisted || isNotBlacklisted;
    });

  const departmentOptions = useMemo(
    () =>
      specificDepartments?.length || notAvailableDepartments?.length
        ? filterOptions(allDepartments)
        : allDepartments,
    [specificDepartments, notAvailableDepartments, allDepartments],
  );

  return (
    <Box w={selectWidths[width]}>
      <EdsDepartmentSelect
        {...rest}
        // disabling until we have BE support
        enableSelectAll={false}
        value={value}
        isMulti={isMulti}
        name={
          name ??
          (isMulti ? 'Multi-Department Select' : 'Single Department Select')
        }
        placeholder={
          isMulti ? 'Specify one or more departments' : 'Specify a department'
        }
        departments={departmentOptions}
        error={fetchDepartmentsError || error}
        isLoading={
          isFetchingDepartments ||
          isFetchingDepartmentsTree ||
          !currentClient.id
        }
        width="100%"
        onSearch={(query: string) =>
          debouncedFindDepartments({
            query,
            pageSize: PAGE_SIZE,
            clientId: currentClient.id,
          })
        }
        onChange={onChange}
      />
    </Box>
  );
};

export default withClient(DepartmentSelect);
