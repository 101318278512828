import { sortBy } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { User } from '~/components/Shared/User';
import {
  getUnassignedUserDisplay,
  unassignedCoordinator,
} from '~/components/Workflow/TicketsViewPage/TicketUserItem/UserInfo';
import { getFileBasename } from '~/eds';
import {
  EsignatureType,
  InboundAttachmentException,
  OutboundEmailFileType,
  TicketActivityType,
  TicketStatusType,
} from '~/enums';
import { withUsers } from '~/hocs';
import { Link, Text } from '~/ui';
import { joinWith } from '~/utils/array';
import { tokenizeContent } from '~/utils/comments';

const defaultMentionRender = (value) => (
  <Text color="gray-900" variant="xs-dense-bold">
    {value}
  </Text>
);

const joinStrings = (array) =>
  joinWith(array, { delimiter: ', ', lastDelimiter: ' and ' });

const mentionRenderers = {
  default: defaultMentionRender,
  user: (value) =>
    defaultMentionRender(<User id={Number(value)} mode="name" />),
  version: (value) => defaultMentionRender(`Version ${value}`),
  versionLink: (value) => {
    const [versionNo, ticketId] = value.split('_');
    return (
      <Link
        to={`/workflow/tickets/${ticketId}/reviewer/${versionNo}`}
        variant="xs-dense-bold"
        color="blue-500"
      >
        Version {versionNo}
      </Link>
    );
  },
  fields: (value) => {
    let fields = value.split(',');
    let lastElement = `and ${fields.pop()}`;

    return defaultMentionRender(`${fields.join(', ')} ${lastElement}`);
  },
};

function getActivityContent({ action, data, user, users, ticket }) {
  const { id: userId } = user;
  switch (action) {
    case TicketActivityType.AddParticipant: {
      const { user } = data;
      return `{{user:${userId}}} added {{user:${user.id}}} as a participant.`;
    }
    case TicketActivityType.CreateEnvelope: {
      return `{{user:${userId}}} opened the draft for collecting signatures.`;
    }
    case TicketActivityType.CancelEnvelope: {
      return `{{user:${userId}}} cancelled the current request for collecting signatures.`;
    }
    case TicketActivityType.CancelTicket: {
      return `{{user:${userId}}} cancelled {{default:${ticket.name}}} ticket.`;
    }
    case TicketActivityType.DeclineEnvelope: {
      const { signer } = data;
      const signerMention = signer.extId
        ? `{{user:${signer.extId}}}`
        : `{{default:${signer.name}}}`;
      return `${signerMention} declined to sign.`;
    }
    case TicketActivityType.CollectSignatures: {
      const { externalUsers, internalUsers } = data;
      const getMentionString = ({ email, name }) =>
        `{{default:${name} (${email})}}`;
      const fieldsString = joinStrings(
        internalUsers.concat(externalUsers).map(getMentionString),
      );
      return `{{user:${userId}}} sent the document for signature to ${fieldsString}.`;
    }
    case TicketActivityType.CollectSignaturesCancelled: {
      const { signingService } = data;
      return `${
        signingService.toLowerCase() === EsignatureType.Adobesign
          ? 'AdobeSign'
          : 'DocuSign'
      } failed to process the file for signatures. Please create a new version of your file and try sending it for signatures again.`;
    }
    case TicketActivityType.CoordinatorReassignment: {
      const { newUserId, oldUserId, stage } = data;
      const getUserString = (id) =>
        !users[id] ? `{{default:${unassignedCoordinator}}}` : `{{user:${id}}}`;
      return `{{user:${userId}}} reassigned the {{default:${stage}}} coordinator from ${getUserString(
        oldUserId,
      )} to ${getUserString(newUserId)}.`;
    }
    case TicketActivityType.SignerReassignment: {
      const { oldSigner, newSigner, user } = data;
      return `{{default:${user.email}}} reassigned the signature from {{default:${oldSigner.name} (${oldSigner.email})}} to {{default:${newSigner.name} (${newSigner.email})}}`;
    }
    case TicketActivityType.CreateTicket: {
      return `{{user:${userId}}} started {{default:${data.document.name}}}.`;
    }
    case TicketActivityType.DeleteVersion: {
      return `{{user:${userId}}} deleted Version ${data.version.tag}.`;
    }
    case TicketActivityType.DocumentSave: {
      const {
        comment,
        fileName,
        redlineVersionInfo,
        versionData,
        versionType,
      } = data;

      if (comment === TicketActivityType.ConvertedDocument) {
        const formattedFileName = getFileBasename(fileName);
        return `{{user:${userId}}} converted {{default:${formattedFileName}}} into a docx format which generated {{${versionType}:${versionData}}}`;
      }

      return `{{user:${userId}}} saved {{${versionType}:${versionData}}} from a redline comparing ${redlineVersionInfo}.`;
    }
    case TicketActivityType.DocumentSigned: {
      // TODO - BE response should not return pilotId, but signer name and email
      return `{{user:${userId}}} signed the document via eSignature.`;
    }
    case TicketActivityType.DocumentUpload: {
      const { fileName, versionData, versionType } = data;

      return `{{user:${userId}}} uploaded {{default:${fileName}}} which generated {{${versionType}:${versionData}}}.`;
    }
    case TicketActivityType.InboundAttachmentAccepted: {
      const { counterpartySenderEmail, fileName, versionData, fileSize } = data;
      const message = fileSize
        ? 'via email submission, and its file size exceeds limitation in e-signature'
        : 'via email submission';

      return `{{default:${counterpartySenderEmail}}} uploaded {{default:${fileName}}} which generated {{versionLink:${versionData}}} ${message}.`;
    }
    case TicketActivityType.InboundAttachmentRejected: {
      const {
        counterpartySenderEmail,
        exception,
        stageAttempted,
        ticketName,
        totalAttachments,
      } = data;
      let message;
      switch (exception) {
        case InboundAttachmentException.WrongStageException: {
          message = ` in ${stageAttempted}`;
          break;
        }
        case InboundAttachmentException.TicketAlreadyCompletedException: {
          message = ` because ${ticketName} was completed`;
          break;
        }
        case InboundAttachmentException.TicketCancelledException: {
          message = ` because ${ticketName} was cancelled`;
          break;
        }
        case InboundAttachmentException.NoneOrMultipleAttachmentsException: {
          const quantifier =
            totalAttachments > 0 ? 'multiple attachments' : 'no attachment';
          message = ` for adding ${quantifier}`;
          break;
        }
        default: {
          message = '';
        }
      }

      return `Email submission sent in by {{default:${counterpartySenderEmail}}} was rejected${message}.`;
    }
    case TicketActivityType.DownloadDocument:
    case TicketActivityType.OodocsDownloadVersion: {
      const { versionData, versionType } = data;

      return `{{user:${userId}}} downloaded {{${versionType}:${versionData}}}.`;
    }
    case TicketActivityType.Esignature: {
      const { externalUser, internalUser } = data;
      const userMention = internalUser
        ? `{{user:${internalUser.id}}}`
        : `{{default:${externalUser.name}}}`;
      return `${userMention} signed the document via eSignature.`;
    }
    case TicketActivityType.IntakeFormEdit: {
      const { created, versionData, versionType } = data;

      return created
        ? `{{user:${userId}}} changed form information fields which generated {{${versionType}:${versionData}}}.`
        : `{{user:${userId}}} changed form information fields.`;
    }
    case TicketActivityType.JudgmentResultStatusUpdate: {
      const { status } = data;
      let actionString = '';
      if (status === TicketStatusType.Approved) {
        actionString = 'completed';
      } else if (status === TicketStatusType.Rejected) {
        actionString = 'rejected';
      } else if (status === TicketStatusType.Done) {
        actionString = 'completed';
      } else if (status === TicketStatusType.Pending) {
        actionString = 'reset';
      }
      return `{{user:${userId}}} ${actionString} the following item.`;
    }
    case TicketActivityType.NextStage:
    case TicketActivityType.PreviousStage:
    case TicketActivityType.TicketCompletion: {
      const { newStage, oldStage } = data;
      return userId
        ? `{{user:${userId}}} moved the document from {{default:${oldStage}}} to {{default:${newStage}}}.`
        : `The document was moved from {{default:${oldStage}}} to {{default:${newStage}}}.`;
    }
    case TicketActivityType.JudgmentResultReassignment: {
      const { name, newUser, oldUser, phase, currentStage } = data;
      const isUnassignedUser = (id) => !users[id];
      const getUserString = ({ email, id, isInternal, name }) =>
        isUnassignedUser(id)
          ? `{{default:${getUnassignedUserDisplay(currentStage)}}}`
          : isInternal
          ? `{{user:${id}}}`
          : `{{default:${name} (${email})}}`;
      return `${getUserString(
        user,
      )} reassigned {{default:${name}}} in {{default:${
        phase.name
      }}} from ${getUserString(oldUser)} to ${getUserString(newUser)}.`;
    }
    case TicketActivityType.OodocsDownloadRedline: {
      const { name, redlineVersionInfo } = data;

      return `{{user:${userId}}} downloaded ${name} - redline changes ${redlineVersionInfo}.docx.`;
    }
    case TicketActivityType.RemoveParticipant: {
      const { user } = data;
      return `{{user:${userId}}} removed {{user:${user.id}}} as a participant.`;
    }
    case TicketActivityType.RenameTicket: {
      const { newName, oldName } = data;
      return `{{user:${userId}}} renamed the ticket from {{default:${oldName}}} to {{default:${newName}}}.`;
    }
    case TicketActivityType.ResetApprovals: {
      return `{{user:${userId}}} reset all items.`;
    }
    case TicketActivityType.ShareDocument: {
      const { emails } = data;
      const fieldsString = joinStrings(
        emails.map((email) => `{{default:${email}}}`),
      );
      const { attachments = [] } = data;
      if (attachments.length === 0) {
        return `{{user:${userId}}} shared the ticket with ${fieldsString}.`;
      }
      const versionAttachments = sortBy(
        attachments.filter(
          (attachment) =>
            attachment.type === OutboundEmailFileType.ExistingVersion,
        ),
        ['tag'],
        ['asc'],
      ).map(
        (attachment) =>
          `{{${attachment.versionType}:${attachment.versionData}}}`,
      );
      const fileAttachments = attachments
        .filter(
          (attachment) =>
            attachment.type === OutboundEmailFileType.ExistingAttachment,
        )
        .map((attachment) => `{{default:${attachment.name}}}`);
      const attachmentsNames = [...versionAttachments, ...fileAttachments].join(
        ', ',
      );

      return `{{user:${userId}}} shared ${attachmentsNames} and ticket with ${fieldsString}.`;
    }
    case TicketActivityType.StageChangeArchive: {
      return `The document was moved from {{default:${data.stageFrom}}} to {{default:${data.stageTo}}} and was archived to {{default:${data.destination.path}}}.`;
    }
    case TicketActivityType.StageChangeArchiveUser: {
      return `{{user:${userId}}} moved the document from {{default:${data.stageFrom}}} to {{default:${data.stageTo}}} and archived it {{default:${data.destination.path}}}.`;
    }
    case TicketActivityType.SignatureUpload: {
      const { externalUsers, internalUsers } = data;
      const internalUserIds = internalUsers.map(({ id }) => id);
      const getMentionString = ({ id, name }) =>
        internalUserIds.includes(id) ? `{{user:${id}}}` : `{{default:${name}}}`;
      const fieldsString = joinStrings(
        internalUsers.concat(externalUsers).map(getMentionString),
      );
      return `{{user:${userId}}} uploaded a document with signature(s) from ${fieldsString}.`;
    }
    case TicketActivityType.VersionBumpForEditing: {
      const { versionData, versionType } = data;
      return `{{user:${userId}}} created {{${versionType}:${versionData}}} for editing.`;
    }
    default: {
      return null;
    }
  }
}

function ActivityLogEventHeader({
  action,
  data,
  user,
  //connected
  users,
  ticket,
}) {
  const content = getActivityContent({
    action,
    data,
    user,
    users,
    ticket,
  });
  const tokens = tokenizeContent(content || '', mentionRenderers);

  return (
    <Text color="gray-900" variant="xs-dense">
      {tokens.map((token, i) => (
        <React.Fragment key={i}>{token.content}</React.Fragment>
      ))}
    </Text>
  );
}

const mapStateToProps = ({ ticket }) => ({
  ticket,
});

export default connect(mapStateToProps)(withUsers(ActivityLogEventHeader));
