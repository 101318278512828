import React, { useState } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';

import { ticketReviewerSetSelectedDocumentHighlights } from '~/actions';
import {
  extractTextFromEditor,
  getOffset,
  useDocumentEditorContext,
} from '~/components/Shared/DocumentEditor';
import { showToast } from '~/components/Shared/EcToast';
import StatusText from '~/components/Shared/StatusText';
import {
  HIGHLIGHT_NAVIGATOR_DOWN_TESTID,
  HIGHLIGHT_NAVIGATOR_UP_TESTID,
} from '~/constants/testids';
import { OoDocsDocModeType, TicketStatusType } from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { getIsHighlightDisabled } from '~/reducers/ticket';
import { api } from '~/redux';
import * as toastTypes from '~/types/toast.types';
import { FlexLayout, Icon, Text, theme, Tooltip } from '~/ui';
import { captureException } from '~/utils';
import { getStatusTextStatus } from '~/utils/ticket';

const { colors } = theme;

const highlightStyles = {
  [TicketStatusType.Rejected]: {
    background: colors['red-100'],
    color: colors['red-600'],
  },
  [TicketStatusType.Approved]: {
    background: colors['green-100'],
    color: colors['green-500'],
  },
  [TicketStatusType.Pending]: {
    background: colors['yellow-100'],
    color: colors['yellow-500'],
  },
};

function HighlightNavigator({
  htmlHighlights = [],
  riskId,
  status,
  onRiskClick,
  // injected
  classes,
  // connected
  selectedDocumentHighlights,
  ticket,
  ticketReviewerSetSelectedDocumentHighlights,
  isHighlightDisabled,
}) {
  const [
    tokenizeRiskContent,
  ] = api.endpoints.tokenizeRiskContent.useLazyQuery();
  const isEJ2DocumentEditorEnabled = useFlag(FlagType.EJ2DocumentEditor);
  const {
    bookmarks,
    documentEditor,
    insertBookmark,
    setIsLoading: setIsDocumentEditorLoading,
  } = useDocumentEditorContext();
  const [htmlHighlightIndex, setHtmlHighlightIndex] = useState(-1);

  const hasTokens = htmlHighlights.some(
    (htmlHighlight) => htmlHighlight.tokens.length > 0,
  );
  if (!hasTokens) {
    return null;
  }

  const isActive =
    htmlHighlights[htmlHighlightIndex]?.id === selectedDocumentHighlights.id;

  function handleHighlights(updatedHtmlHighlightIndex) {
    setHtmlHighlightIndex(updatedHtmlHighlightIndex);
    const { id, tokens = [] } = htmlHighlights[updatedHtmlHighlightIndex];
    const highlights = tokens.map((token) => {
      return {
        id: token,
        className: classes[status],
      };
    });
    ticketReviewerSetSelectedDocumentHighlights({
      id,
      highlights,
      updatedHtmlHighlightIndex,
      riskId,
    });
  }

  const statusTextStatus = isActive ? getStatusTextStatus(status) : 'muted';

  const isChevronUpDisabled = htmlHighlightIndex <= 0 || isHighlightDisabled;
  const isChevronDownDisabled =
    htmlHighlightIndex >= htmlHighlights.length - 1 || isHighlightDisabled;

  function getHighlightEnabledModeText() {
    if (ticket.isInEditorMode) {
      return 'switch to View mode';
    } else if (ticket.docMode !== OoDocsDocModeType.AllMarkup) {
      return 'select Show All Markup';
    }
  }

  function createRiskClausesInDocumentEditor(updatedHtmlHighlightIndex) {
    setHtmlHighlightIndex(updatedHtmlHighlightIndex);
    if (documentEditor) {
      // this is necessary to map the return of the endpoint since it
      // returns the index of all extracted blocks and EJ2 has a block index for each section
      const {
        text: extractedText,
        mapping: blockToInternalBlockAndSectionMapping,
      } = extractTextFromEditor(documentEditor);
      const existingRisks = bookmarks.risk;
      const bookmarkId = `_risk_${riskId}`;
      if (!existingRisks.has(bookmarkId)) {
        setIsDocumentEditorLoading(true);
        tokenizeRiskContent({ riskId, textList: extractedText })
          .unwrap()
          .then((response) => {
            const blocksIndex = Object.keys(response);
            if (blocksIndex.length > 0) {
              try {
                // find initial section and initial internal block
                const firstBlockIndex = blocksIndex[0];
                const lastBlockIndex = blocksIndex[blocksIndex.length - 1];
                const [
                  initialSectionIndex,
                  initialInternalBlockIndex,
                ] = blockToInternalBlockAndSectionMapping[firstBlockIndex];

                // find final section and final internal block
                const [
                  finalSectionIndex,
                  finalInternalBlockIndex,
                ] = blockToInternalBlockAndSectionMapping[lastBlockIndex];
                const firstParagraphStartPosition =
                  response[firstBlockIndex][0][1];
                const lastParagraph = response[lastBlockIndex];
                const lastParagraphEndPosition =
                  response[lastBlockIndex][lastParagraph.length - 1][1];
                const startOffset = getOffset(
                  firstParagraphStartPosition,
                  extractedText[blocksIndex[0]],
                );
                const endOffset = getOffset(
                  lastParagraphEndPosition,
                  extractedText[lastBlockIndex],
                  true,
                );

                // select uses the format select('section;block;startOffset', 'section;block;endOffset');
                documentEditor.selection.select(
                  `${initialSectionIndex};${initialInternalBlockIndex};${startOffset}`,
                  `${finalSectionIndex};${finalInternalBlockIndex};${endOffset}`,
                );

                insertBookmark(bookmarkId);
                onRiskClick?.(riskId);
              } catch (error) {
                captureException(
                  `An error occurred when generating risk with id ${riskId}.`,
                  error,
                  {
                    section: 'ej2 document editor',
                  },
                );

                showToast(
                  toastTypes.ERROR,
                  'An error occurred when generating one of the risks.',
                );
              }
            }
          })
          .finally(() => {
            setIsDocumentEditorLoading(false);
          });
      } else {
        onRiskClick?.(riskId);
      }
    }
  }

  return (
    <StatusText status={statusTextStatus}>
      <FlexLayout alignItems="center" justifyContent="space-between">
        <FlexLayout alignItems="center" space={4}>
          <Icon icon="view" color="gray-900" size="s" />
          <Text color="gray-900" variant="xs-dense-bold">
            {Math.max(htmlHighlightIndex, 0) + 1} of {htmlHighlights.length}
          </Text>
        </FlexLayout>
        <Tooltip
          content={
            isHighlightDisabled
              ? `To enable Risk review Highlighting, ${getHighlightEnabledModeText()}`
              : null
          }
        >
          <FlexLayout alignItems="center" space={4}>
            <Icon
              icon="chevronUp"
              color={isChevronUpDisabled ? 'black-alpha-10' : 'black-alpha-20'}
              size="s"
              disabled={isHighlightDisabled}
              className={HIGHLIGHT_NAVIGATOR_UP_TESTID}
              onClick={() => {
                const highlightIndex = Math.max(htmlHighlightIndex - 1, 0);
                return isEJ2DocumentEditorEnabled
                  ? createRiskClausesInDocumentEditor(highlightIndex)
                  : handleHighlights(highlightIndex);
              }}
            />
            <Icon
              icon="chevronDown"
              color={
                isChevronDownDisabled ? 'black-alpha-10' : 'black-alpha-20'
              }
              size="s"
              disabled={isHighlightDisabled}
              className={HIGHLIGHT_NAVIGATOR_DOWN_TESTID}
              onClick={() => {
                const highlightIndex = Math.min(
                  htmlHighlightIndex + 1,
                  htmlHighlights.length - 1,
                );
                return isEJ2DocumentEditorEnabled
                  ? createRiskClausesInDocumentEditor(highlightIndex)
                  : handleHighlights(highlightIndex);
              }}
            />
          </FlexLayout>
        </Tooltip>
      </FlexLayout>
    </StatusText>
  );
}

const mapStateToProps = ({ ticketReviewer, ticket, currentUser }) => ({
  selectedDocumentHighlights: ticketReviewer.selectedDocumentHighlights,
  isHighlightDisabled: getIsHighlightDisabled(ticket, currentUser),
  ticket,
});

export default connect(mapStateToProps, {
  ticketReviewerSetSelectedDocumentHighlights,
})(injectSheet(highlightStyles)(HighlightNavigator));
