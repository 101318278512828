import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import * as allApis from '~/api';

import { createEndpoint, createMutation } from './helper';
import * as allTypedApis from './methods';
import { TagType } from './TagType';
import * as transformers from './transformers';

export const api = createApi({
  reducerPath: 'API Service',
  baseQuery: fetchBaseQuery(),
  keepUnusedDataFor: 0,
  tagTypes: Object.values(TagType),
  endpoints: (build) => ({
    // auth
    getSsoProvider: createEndpoint(build, allTypedApis.getSsoProvider),

    // users
    getAllUsers: createEndpoint(build, allApis.getAllUsers),
    getFieldValues: createEndpoint(build, allApis.getFieldValues),
    getUserImportJob: createEndpoint(build, allApis.getUserImportJobsData),
    getUsersImportStatus: createEndpoint(
      build,
      allTypedApis.getUsersImportStatus,
    ),
    acknowledgeUserImport: createMutation(
      build,
      allTypedApis.acknowledgeUserImport,
    ),
    getDataFieldSections: createEndpoint(build, allApis.getDataFieldSections),
    findUsersByNameOrEmail: createEndpoint(
      build,
      allTypedApis.findUsersByNameOrEmail,
    ),
    exportUsersExcelFile: createEndpoint(
      build,
      allTypedApis.exportUsersExcelFile,
    ),
    downloadImportResultsExcelFile: createEndpoint(
      build,
      allTypedApis.downloadImportResultsExcelFile,
    ),
    startImportUsersListProcessing: createMutation(
      build,
      allTypedApis.startImportUsersListProcessing,
    ),
    importUsersListExcelFile: createMutation(
      build,
      allTypedApis.importUsersListExcelFile,
    ),
    cancelImportJob: createMutation(build, allTypedApis.cancelImportJob),

    //clients
    updateClientAdmin: createMutation(build, allTypedApis.updateClientAdmin),

    // Clause Library
    clauseLibraryCreateClause: createMutation(
      build,
      allTypedApis.clauseLibraryCreateClause,
    ),
    clauseLibraryListClauses: createEndpoint(
      build,
      allTypedApis.clauseLibraryListClauses,
    ),
    clauseLibrarySearchClauses: createEndpoint(
      build,
      allTypedApis.clauseLibrarySearchClauses,
    ),
    clauseLibraryGetClause: createEndpoint(
      build,
      allTypedApis.clauseLibraryGetClause,
    ),
    clauseLibraryEditClause: createMutation(
      build,
      allTypedApis.clauseLibraryEditClause,
    ),
    clauseLibraryDeleteClause: createMutation(
      build,
      allTypedApis.clauseLibraryDeleteClause,
    ),
    clauseLibraryCreateVariation: createMutation(
      build,
      allTypedApis.clauseLibraryCreateVariation,
    ),
    clauseLibraryEditVariation: createMutation(
      build,
      allTypedApis.clauseLibraryEditVariation,
    ),
    clauseLibraryDeleteVariation: createMutation(
      build,
      allTypedApis.clauseLibraryDeleteVariation,
    ),

    // Clause Library V2
    getClauseTypes: createEndpoint(build, transformers.getClauseTypes),

    // Automation Hub
    getClientClause: createEndpoint(build, allApis.getClientClause),
    getClauses: createEndpoint(build, allTypedApis.getClauses),
    suspendClause: createMutation(build, allApis.suspendClause),
    resumeClause: createMutation(build, allApis.resumeClause),
    deleteClause: createMutation(build, allApis.deleteClause),
    cancelTraining: createMutation(build, allApis.cancelTraining),
    renameClause: createMutation(build, allApis.renameClause),
    updateClause: createMutation(build, allTypedApis.updateClause),
    getIdentifications: createEndpoint(build, allTypedApis.getIdentifications),
    getSamples: createEndpoint(build, allApis.getSamples),
    createSample: createMutation(build, allTypedApis.createSample),
    suspendSample: createMutation(build, allApis.suspendSample),
    resumeSample: createMutation(build, allApis.resumeSample),
    updateSample: createMutation(build, allApis.updateSample),
    deleteSample: createMutation(build, allApis.deleteSample),

    // SmartClause
    createTraining: createMutation(build, allApis.createTraining),
    updateTrainingLabels: createMutation(build, allApis.updateTrainingLabels),
    createRetraining: createMutation(build, allApis.createRetraining),
    getTrainingStatus: createEndpoint(build, allApis.getTrainingStatus),
    getFiltersData: createEndpoint(build, allApis.getFiltersData),
    publishTrainedModel: createMutation(build, allApis.publishTrainedModel),
    getPredictionStatus: createEndpoint(build, allApis.getPredictionStatus),
    updatePredictionFilters: createMutation(
      build,
      allApis.updatePredictionFilters,
    ),
    deleteTrainingModel: createMutation(build, allApis.deleteTrainingModel),
    updateDescription: createMutation(build, allApis.updateDescription),
    getFolderFilterOptions: createEndpoint(
      build,
      allApis.getFolderFilterOptions,
    ),

    // Workflow
    getNewTicketsData: createEndpoint(build, allApis.getNewTickets),
    getAvailableWorkflows: createEndpoint(build, allApis.getAvailableWorkflows),

    // ApiKey
    getApiKeys: createEndpoint(build, allTypedApis.getApiKeys),
    createApiKey: createMutation(build, allTypedApis.createApiKey),
    updateApiKey: createMutation(build, allTypedApis.updateApiKey),
    deleteApiKey: createMutation(build, allTypedApis.deleteApiKey),

    // Client
    getClientBySubdomain: createEndpoint(build, allApis.getClientBySubdomain),
    getClientContractTypes: createEndpoint(
      build,
      allTypedApis.getClientContractTypes,
      {
        keepUnusedDataFor: 60,
      },
    ),

    // Dashboard
    getDashboards: createEndpoint(build, allTypedApis.getDashboards),
    getDashboard: createEndpoint(build, allTypedApis.getDashboard),

    // Departments
    getClientDepartmentsTree: createEndpoint(
      build,
      allApis.getClientDepartmentsTree,
    ),
    getAllDepartments: createEndpoint(build, allTypedApis.getAllDepartments),
    findDepartmentsByName: createEndpoint(
      build,
      allTypedApis.findDepartmentsByName,
    ),
    listDepartmentMembers: createEndpoint(
      build,
      allTypedApis.listDepartmentMembers,
    ),

    // Document Groups
    getAllDocumentGroups: createEndpoint(
      build,
      allTypedApis.getAllDocumentGroups,
    ),
    getDocumentGroup: createEndpoint(build, allTypedApis.getDocumentGroup),
    addDocumentsToGroup: createMutation(
      build,
      allTypedApis.addDocumentsToGroup,
    ),
    addDocumentsToGroupByFolder: createMutation(
      build,
      allTypedApis.addDocumentsToGroupByFolder,
    ),
    addDocumentsToGroupLinkParent: createMutation(
      build,
      allTypedApis.addDocumentsToGroupLinkParent,
    ),
    linkDocumentsToGroup: createMutation(
      build,
      allTypedApis.linkDocumentsToGroup,
    ),
    unlinkDocumentsFromGroup: createMutation(
      build,
      allTypedApis.unlinkDocumentsFromGroup,
    ),
    createDocumentGroup: createMutation(
      build,
      allTypedApis.createDocumentGroup,
    ),
    removeDocumentInGroup: createMutation(
      build,
      allTypedApis.removeDocumentInGroup,
    ),
    editDocumentGroup: createMutation(build, allTypedApis.editDocumentGroup),
    deleteDocumentGroup: createMutation(
      build,
      allTypedApis.deleteDocumentGroup,
    ),

    // Document Restore
    getDeletedDocuments: createEndpoint(
      build,
      allTypedApis.getDeletedDocuments,
    ),
    getDeletedDocumentsForCurrentUser: createEndpoint(
      build,
      allTypedApis.getDeletedDocumentsForCurrentUser,
    ),
    permanentlyDeleteDocuments: createMutation(
      build,
      allTypedApis.permanentlyDeleteDocuments,
    ),
    permanentlyDeleteDocumentsForCurrentUser: createMutation(
      build,
      allTypedApis.permanentlyDeleteDocumentsForCurrentUser,
    ),
    restoreDeletedDocuments: createMutation(
      build,
      allTypedApis.restoreDeletedDocuments,
    ),
    restoreDeletedDocumentsForCurrentUser: createMutation(
      build,
      allTypedApis.restoreDeletedDocumentsForCurrentUser,
    ),
    getDeletedDocumentsOriginalFolder: createEndpoint(
      build,
      allTypedApis.getDeletedDocumentsOriginalFolder,
    ),
    getDeletedDocumentsOriginalFolderForCurrentUser: createEndpoint(
      build,
      allTypedApis.getDeletedDocumentsOriginalFolderForCurrentUser,
    ),
    getDeletedDocumentsDeletedByUsers: createEndpoint(
      build,
      allTypedApis.getDeletedDocumentsDeletedByUsers,
    ),

    // Evisync
    getExternalId: createEndpoint(build, allApis.getExternalId),
    getEvisortFolders: createEndpoint(build, allApis.getInternalFolders),
    createS3TokenId: createMutation(build, allApis.createS3TokenId),
    updateSyncPair: createMutation(build, allApis.updateSyncPair),
    updateSyncPairTokens: createMutation(build, allApis.upddateTokensSyncPair),
    createAribaTokenId: createMutation(build, allApis.authenticateAriba),
    createAribaMultiTokens: createMutation(
      build,
      allApis.authenticateMultiTokensAriba,
    ),
    getSyncPairsAriba: createEndpoint(build, allApis.getSyncPairsAriba),
    getAribaLocations: createEndpoint(build, allApis.getAribaLocations),
    getAribaWorkspaceCount: createEndpoint(
      build,
      allApis.getAribaWorkspaceCount,
    ),
    getAribaFields: createEndpoint(build, allApis.getAribaFields),
    validateAribaDocumentIdFile: createMutation(
      build,
      allApis.validateAribaDocumentIdFile,
    ),
    validateFieldMappingCsv: createMutation(
      build,
      allApis.validateFieldMappingCsv,
    ),
    processFieldMappingCsv: createMutation(
      build,
      allApis.processFieldMappingCsv,
    ),
    validateRealm: createMutation(build, allApis.validateRealm),
    updateAribaFileSyncPair: createMutation(
      build,
      allApis.updateAribaFileSyncPair,
    ),

    // search
    searchDocuments: createEndpoint(build, allTypedApis.searchDocuments),
    pilotSearchDocuments: createEndpoint(
      build,
      allTypedApis.pilotSearchDocuments,
    ),
    exportExcel: createEndpoint(build, allTypedApis.exportSearchResults),
    validateBooleanQuery: createEndpoint(
      build,
      allTypedApis.validateBooleanQuery,
    ),
    getFilterSections: createEndpoint(build, transformers.getFilterSections, {
      keepUnusedDataFor: 60,
    }),
    getClauseSuggestions: createEndpoint(
      build,
      allTypedApis.getClauseSuggestions,
    ),
    getFolderTree: createEndpoint(build, allTypedApis.getFolderTree),
    getFolders: createEndpoint(build, allTypedApis.getFolders),
    saveSearch: createMutation(build, allTypedApis.saveSearch),
    getSavedSearches: createEndpoint(build, allTypedApis.getSavedSearches),
    deleteSavedSearch: createMutation(build, allTypedApis.deleteSavedSearch),
    updateSavedSearch: createMutation(build, allTypedApis.updateSavedSearch),
    checkDownloadExportReportById: createEndpoint(
      build,
      allTypedApis.checkDownloadExportReportById,
    ),
    getQuickSearchRecords: createEndpoint(
      build,
      allTypedApis.getQuickSearchRecords,
    ),
    quickSearchDocuments: createMutation(
      build,
      allTypedApis.quickSearchDocuments,
    ),
    updatePinnedField: createMutation(build, allTypedApis.updatePinnedField),

    // table views
    getCurrentTableView: createEndpoint(
      build,
      allTypedApis.getCurrentTableView,
    ),
    getTableViews: createEndpoint(build, allTypedApis.getTableViews),
    createTableView: createMutation(build, allTypedApis.createTableView),
    updateTableView: createMutation(build, allTypedApis.updateTableView),
    loadTableView: createMutation(build, allTypedApis.loadTableView),
    loadDefaultTableView: createMutation(
      build,
      allTypedApis.loadDefaultTableView,
    ),
    getDefaultTableView: createEndpoint(
      build,
      allTypedApis.getDefaultTableView,
    ),

    // documentMetadata
    getDocumentMetadataImportJob: createEndpoint(
      build,
      allApis.getDocumentMetadataImportJobsData,
    ),

    // provisions
    getProvisions: createEndpoint(build, allTypedApis.getProvisions),
    getMigratedProvisions: createEndpoint(
      build,
      allTypedApis.getMigratedProvisions,
    ),
    getAllProvisions: createEndpoint(build, allTypedApis.getAllProvisions),
    getAllProvisionsGrouped: createEndpoint(
      build,
      transformers.getAllProvisionsGrouped,
    ),
    updateProvisionContent: createMutation(
      build,
      allTypedApis.updateProvisionContent,
    ),
    deleteProvision: createMutation(build, allTypedApis.deleteProvision),

    // comments
    getComments: createEndpoint(build, allTypedApis.comments.getComments),
    createComment: createMutation(build, allTypedApis.comments.createComment),
    updateComment: createMutation(build, allTypedApis.comments.updateComment),
    deleteComment: createMutation(build, allTypedApis.comments.deleteComment),
    resolveComment: createMutation(build, allTypedApis.comments.resolveComment),
    unresolveComment: createMutation(
      build,
      allTypedApis.comments.unresolveComment,
    ),
    getCommentImportStatus: createEndpoint(
      build,
      allTypedApis.comments.getCommentImportStatus,
    ),

    //highlights
    getHighlights: createEndpoint(build, allTypedApis.getHighlights),
    createHighlight: createMutation(build, allTypedApis.postHighlight),

    // permission
    getEntityPermissions: createEndpoint(
      build,
      allTypedApis.acl.getEntityPermissions,
    ),

    // navigation
    getSearchPositionByHandler: createEndpoint(
      build,
      allTypedApis.getSearchPositionByHandler,
    ),
    getSearchHandlersByPosition: createEndpoint(
      build,
      allTypedApis.getSearchHandlersByPosition,
    ),

    //Departments
    getDepartmentChildren: createEndpoint(
      build,
      allTypedApis.getDepartmentChildren,
    ),

    // ticket document version
    downloadTicketDocumentVersionEmailAttachment: createEndpoint(
      build,
      allTypedApis.downloadTicketDocumentVersionEmailAttachment,
    ),
    getTicketDocumentVersionEditorKey: createEndpoint(
      build,
      allTypedApis.getTicketDocumentVersionEditorKey,
    ),

    // User Preferences
    getPinnedFilters: createEndpoint(build, allTypedApis.getPinnedFilters),
    updatePinnedFilters: createMutation(
      build,
      allTypedApis.updatePinnedFilters,
    ),

    // Ticket Reviewer
    getDocumentDetailClauses: createEndpoint(
      build,
      allTypedApis.getDocumentDetailClauses,
    ),

    //Document
    getDocument: createEndpoint(build, allTypedApis.getDocument),

    //documents
    getPdfDocumentOcr: createEndpoint(build, allTypedApis.downloadOcrPdf),
    convertPdfToDocx: createMutation(build, allTypedApis.convertPdfToDocx),
    getDocumentByTag: createEndpoint(build, allTypedApis.getDocumentByTag),
    getDocumentFromVersion: createEndpoint(
      build,
      allTypedApis.getDocumentFromVersion,
      {
        keepUnusedDataFor: 60,
      },
    ),
    getDocumentOriginal: createEndpoint(
      build,
      allTypedApis.getDocumentOriginal,
    ),
    convertContentToSfdt: createMutation(
      build,
      allTypedApis.convertContentToSfdt,
    ),
    getDocumentInformation: createEndpoint(
      build,
      transformers.getDocumentInformation,
    ),
    getDocumentSfdt: createEndpoint(build, allTypedApis.getDocumentSfdt),
    compareDocumentsSfdt: createEndpoint(
      build,
      allTypedApis.compareDocumentsSfdt,
    ),
    saveDocumentSfdt: createMutation(build, allTypedApis.saveDocumentSfdt),
    backupDocumentSfdt: createMutation(build, allTypedApis.backupDocumentSfdt),
    tokenizeSfdtContent: createMutation(
      build,
      allTypedApis.tokenizeSfdtContent,
    ),
    tokenizeRiskContent: createEndpoint(
      build,
      allTypedApis.tokenizeRiskContent,
    ),
    getDocumentContent: createEndpoint(build, allTypedApis.getDocumentContent),
    getWorkflowFile: createEndpoint(build, allApis.getWorkflowFile),
    searchV2BulkDelete: createMutation(build, allTypedApis.searchV2BulkDelete),
    searchV2BulkCopy: createMutation(build, allTypedApis.searchV2BulkCopy),
    searchV2BulkMove: createMutation(build, allTypedApis.searchV2BulkMove),
    searchV2BulkEditClause: createMutation(
      build,
      allTypedApis.searchV2BulkEditClause,
    ),
    searchV2BulkEditData: createEndpoint(
      build,
      allTypedApis.searchV2BulkEditData,
    ),
    searchV2BulkAddReplaceFields: createMutation(
      build,
      allTypedApis.documentBulkAddReplaceFields,
    ),
    searchV2BulkEditFields: createMutation(
      build,
      allTypedApis.documentBulkEditFields,
    ),
    //dashboardV2
    createDefaultDashboards: createEndpoint(
      build,
      allTypedApis.createDefaultDashboards,
    ),
    getDashboardV2: createEndpoint(build, transformers.getDashboardV2),
    getDashboardsV2: createEndpoint(build, transformers.getDashboardsV2),
    saveDashboardChanges: createMutation(
      build,
      allTypedApis.saveDashboardChanges,
    ),
    saveAsDashboard: createMutation(build, allTypedApis.saveAsDashboard),
    updateDashboard: createMutation(build, allTypedApis.updateDashboard),
    deleteDashboard: createMutation(build, allTypedApis.deleteDashboard),

    // fields
    createField: createMutation(build, allTypedApis.createField),
    getFields: createEndpoint(build, allTypedApis.getFields, {
      keepUnusedDataFor: 60,
    }),
    getFieldSections: createEndpoint(build, allTypedApis.getFieldSections, {
      keepUnusedDataFor: 60,
    }),

    //search v3
    getChartData: createEndpoint(build, allTypedApis.getChartData),
    getFilters: createEndpoint(build, allTypedApis.getFilters, {
      keepUnusedDataFor: 60,
    }),
    getFilterSuggestions: createEndpoint(
      build,
      allTypedApis.getFilterSuggestions,
    ),
    searchV3Documents: createEndpoint(build, allTypedApis.searchV3Documents),
    getSearchExportOptions: createEndpoint(
      build,
      transformers.getSearchExportOptions,
    ),
    exportExcelV2: createMutation(build, allTypedApis.exportExcelV2),
    getSearchV3SavedSearches: createEndpoint(
      build,
      allTypedApis.getSearchV3SavedSearches,
    ),
    deleteSearchV3SavedSearch: createMutation(
      build,
      allTypedApis.deleteSearchV3SavedSearch,
    ),
    postSavedSearch: createMutation(build, allTypedApis.postSavedSearch),
    getSavedSearch: createEndpoint(build, allTypedApis.getSavedSearch),
    putSavedSearch: createMutation(build, allTypedApis.putSavedSearch),
    getSearchV3PositionByHandler: createEndpoint(
      build,
      allTypedApis.getSearchV3PositionByHandler,
    ),
    getSearchV3HandlersByPosition: createEndpoint(
      build,
      allTypedApis.getSearchV3HandlersByPosition,
    ),

    //roles
    getRoles: createEndpoint(build, transformers.getRoles),
    getRolesSimple: createEndpoint(build, transformers.getRolesSimple),
    createRole: createMutation(build, allTypedApis.createRole),
    updateRole: createMutation(build, allTypedApis.updateRole),
    validateRoleName: createEndpoint(build, allTypedApis.validateRoleName),
    deleteRole: createMutation(build, allTypedApis.deleteRole),
    getPermissions: createEndpoint(build, transformers.getPermissions),
    getUsers: createEndpoint(build, transformers.getUsers),
    createUser: createMutation(build, allTypedApis.createUser),
    sendInvitationEmail: createMutation(
      build,
      allTypedApis.sendInvitationEmail,
    ),
    updateUser: createMutation(build, allTypedApis.updateUser),
    deactivateUser: createMutation(build, allTypedApis.deactivateUser),
    reactivateUser: createMutation(build, allTypedApis.reactivateUser),
    cancelUserInvite: createMutation(build, allTypedApis.cancelUserInvite),
    resendReactivatedUserInvite: createMutation(
      build,
      allTypedApis.resendReactivatedUserInvite,
    ),
    checkPermission: createEndpoint(build, allTypedApis.checkPermission),

    //ticket
    cancelTicket: createMutation(build, allTypedApis.cancelTicket),
    getTicketDocument: createEndpoint(build, allTypedApis.getTicketDocument),
    getTicketCurrentStage: createEndpoint(
      build,
      allTypedApis.getTicketCurrentStage,
    ),
    getTicketIntakeForm: createEndpoint(
      build,
      allTypedApis.getTicketIntakeForm,
    ),
    getTicketSummary: createEndpoint(build, allTypedApis.getTicketSummary),
    getActivities: createEndpoint(build, allTypedApis.getActivities),
    createActivityLogEvent: createMutation(
      build,
      allTypedApis.createActivityLog,
    ),
    updateRecipients: createMutation(build, allTypedApis.updateRecipients),
    deleteRecipients: createMutation(build, allTypedApis.deleteRecipients),
    bumpVersion: createMutation(build, allTypedApis.bumpVersion),
    addTicketParticipants: createMutation(
      build,
      allTypedApis.addTicketParticipants,
    ),
    getTicketParticipants: createEndpoint(
      build,
      allTypedApis.getTicketParticipants,
    ),
    collectSignatures: createEndpoint(build, allTypedApis.collectSignatures),
    updateEnvelope: createMutation(build, allTypedApis.updateEnvelope),
    reassignApproval: createMutation(build, allApis.reassignApproval),

    // Turn Tracking
    getTurnTrackingHistory: createEndpoint(
      build,
      allTypedApis.getTurnTrackingHistory,
    ),
    getTurnTrackingParties: createEndpoint(
      build,
      allTypedApis.getTurnTrackingParties,
    ),
    updateTurnTrackingEntry: createMutation(
      build,
      allTypedApis.updateTurnTrackingEntry,
    ),
    createTurnTrackingEntry: createMutation(
      build,
      allTypedApis.createTurnTrackingEntry,
    ),

    // Conversational AI
    acceptLegalDisclaimer: createEndpoint(
      build,
      allTypedApis.acceptLegalDisclaimer,
    ),
    getConversations: createEndpoint(build, transformers.getConversations),
    createConversation: createMutation(build, transformers.createConversation),
    getConversation: createEndpoint(build, transformers.getConversation),
    postUserMessage: createMutation(build, transformers.postUserMessage),
    postUserFeedback: createMutation(build, transformers.postUserFeedback),
    interruptAiMessage: createMutation(build, allTypedApis.interruptAiMessage),

    // sylvanus
    checkPromptModelConfig: createMutation(
      build,
      allTypedApis.checkPromptModelConfig,
    ),
    createPromptModelTestCase: createMutation(
      build,
      allTypedApis.createPromptModelTestCase,
    ),
    createPromptModelVersion: createMutation(
      build,
      allTypedApis.createPromptModelVersion,
    ),
    deletePromptModel: createMutation(build, allTypedApis.deletePromptModel),
    executePromptModelTestCase: createMutation(
      build,
      allTypedApis.executePromptModelTestCase,
    ),
    executePromptModelTestCases: createMutation(
      build,
      allTypedApis.executePromptModelTestCases,
    ),
    getPromptModelProgress: createEndpoint(
      build,
      allTypedApis.getPromptModelProgress,
    ),
    getPromptModelOptimizeResults: createEndpoint(
      build,
      allTypedApis.getPromptModelOptimizeResults,
    ),
    getPromptModelOptimizeTestCases: createEndpoint(
      build,
      allTypedApis.getPromptModelOptimizeTestCases,
    ),
    optimizePromptModel: createMutation(
      build,
      allTypedApis.optimizePromptModel,
    ),
    getPromptModelOptimizeSummary: createEndpoint(
      build,
      allTypedApis.getPromptModelOptimizeSummary,
    ),
    publishPromptModel: createMutation(build, allTypedApis.publishPromptModel),
    suggestPromptModelInstructions: createEndpoint(
      build,
      allTypedApis.suggestPromptModelInstructions,
    ),
    suspendPromptModel: createMutation(build, allTypedApis.suspendPromptModel),
    tagAnswerForPromptModelTestCase: createMutation(
      build,
      allTypedApis.tagAnswerForPromptModelTestCase,
    ),
    updatePromptModelProgress: createMutation(
      build,
      allTypedApis.updatePromptModelProgress,
    ),
    updatePromptModelTestCase: createMutation(
      build,
      allTypedApis.updatePromptModelTestCase,
    ),

    // Document X-Ray
    getDocsInScope: createEndpoint(build, allTypedApis.getDocsInScope),
    getPromptModel: createEndpoint(build, allTypedApis.getPromptModel, {
      providesTags: (_result, _error, arg) => [
        { type: TagType.PromptModel, id: arg },
      ],
    }),
    DEPRECATED_getPromptModels: createEndpoint(
      build,
      allTypedApis.DEPRECATED_getPromptModels,
    ),
    getPromptModelResults: createEndpoint(
      build,
      allTypedApis.getPromptModelResults,
    ),
    getPromptModelTestCase: createEndpoint(
      build,
      allTypedApis.getPromptModelTestCase,
    ),
    getPromptModelTestCases: createEndpoint(
      build,
      allTypedApis.getPromptModelTestCases,
    ),
    getPromptModelVersion: createEndpoint(
      build,
      allTypedApis.getPromptModelVersion,
      {
        keepUnusedDataFor: 600,
      },
    ),
    DEPRECATED_getPromptModelVersion: createEndpoint(
      build,
      allTypedApis.DEPRECATED_getPromptModelVersion,
    ),
    getPromptModelFieldIds: createEndpoint(
      build,
      allTypedApis.getPromptModelFieldIds,
    ),
    resolvePromptModelTargetEntityDetails: createEndpoint(
      build,
      allTypedApis.resolvePromptModelTargetEntityDetails,
    ),
    savePromptModelDraft: createMutation(
      build,
      allTypedApis.savePromptModelDraft,
      {
        invalidatesTags: (_result, _error, arg) =>
          arg.modelId // TODO: API method should be atomic instead of having nullable modelId
            ? [
                {
                  type: TagType.PromptModel,
                  id: arg.modelId,
                },
              ]
            : [],
      },
    ),

    // Gen AI
    getGenClauseResult: createEndpoint(build, allTypedApis.getGenClauseResult),
    getGenClauseResults: createEndpoint(
      build,
      allTypedApis.getGenClauseResults,
    ),

    // resolvers
    resolveDocument: createEndpoint(build, allTypedApis.resolveDocument, {
      keepUnusedDataFor: 600,
    }),
    resolveUsers: createEndpoint(build, allTypedApis.resolveUsers),
    resolveUser: createEndpoint(build, allTypedApis.resolveUser),

    //evisearch
    search: createEndpoint(build, allTypedApis.search),
    getFiltersV2: createEndpoint(build, transformers.getFiltersV2),
    getFilterSuggestionsV2: createEndpoint(
      build,
      allTypedApis.getFilterSuggestionsV2,
    ),
    getChartDataV2: createEndpoint(build, allTypedApis.getChartDataV2),
    getSearchExportOptionsV2: createEndpoint(
      build,
      allTypedApis.getSearchExportOptionsV2,
    ),
    exportExcelV3: createMutation(build, allTypedApis.exportExcelV3),
    //post sig document
    getDocumentsViewsetFields: createEndpoint(
      build,
      allTypedApis.getDocumentsViewsetFields,
    ),
    getFolderData: createEndpoint(build, allTypedApis.getFolderData),

    // upload
    getUploadFiles: createEndpoint(build, allTypedApis.getUploadFiles),
    getFolderVisibility: createEndpoint(
      build,
      allTypedApis.getFolderVisibility,
    ),
    addUploadFiles: createMutation(build, allTypedApis.addUploadFiles),
    // process documents
    getAlgorithmMetrics: createEndpoint(
      build,
      allTypedApis.getAlgorithmMetrics,
    ),
    reprocessAlgorithms: createMutation(
      build,
      allTypedApis.reprocessAlgorithms,
    ),
    stopReprocessAlgorithms: createMutation(
      build,
      allTypedApis.stopReprocessAlgorithms,
    ),

    // Manage Party Lists
    getParties: createEndpoint(build, allTypedApis.getParties),
    updateParty: createMutation(build, allTypedApis.updateParty),
    deleteParty: createMutation(build, allTypedApis.deleteParty),
    addParty: createMutation(build, allTypedApis.addParty),
    uploadParty: createMutation(build, allTypedApis.uploadParty),
    deleteParties: createMutation(build, allTypedApis.deleteParties),
    // Super Admin
    createSuperAdmin: createMutation(build, allTypedApis.createSuperAdmin),
    deactivateSuperAdmin: createMutation(
      build,
      allTypedApis.deactivateSuperAdmin,
    ),

    // Wopi
    getWopiSetup: createEndpoint(build, allTypedApis.getWopiSetup),
    getWopiStaticFile: createEndpoint(build, allTypedApis.getWopiStaticFiles),

    // CodeCash
    createOneTimeCreditBalance: createMutation(
      build,
      allTypedApis.createOneTimeCreditBalance,
    ),
    editOneTimeCreditBalance: createMutation(
      build,
      allTypedApis.editOneTimeCreditBalance,
    ),
    getCreditSummary: createEndpoint(build, allTypedApis.getCreditSummary),
    getCreditEstimation: createEndpoint(
      build,
      allTypedApis.getCreditEstimation,
    ),
    getCreditLedger: createEndpoint(build, allTypedApis.getCreditLedger),
    updateRecurringCredits: createMutation(
      build,
      allTypedApis.updateRecurringCredits,
    ),

    // Audit Logs
    getAuditLogs: createEndpoint(build, allTypedApis.getAuditLogs),

    // Question & Question groups
    getQuestion: createEndpoint(build, allTypedApis.getQuestion),
    getQuestions: createEndpoint(build, allTypedApis.getQuestions),
    createQuestion: createMutation(build, allTypedApis.createQuestion),
    updateQuestion: createMutation(build, allTypedApis.updateQuestion),
    deleteQuestion: createMutation(build, allTypedApis.deleteQuestion),
    resolveQuestions: createEndpoint(build, allTypedApis.resolveQuestions),

    getQuestionGroups: createEndpoint(build, allTypedApis.getQuestionGroups),
    createQuestionGroup: createMutation(
      build,
      allTypedApis.createQuestionGroup,
    ),
    updateQuestionGroup: createMutation(
      build,
      allTypedApis.updateQuestionGroup,
    ),
    getQuestionGroupQuestions: createEndpoint(
      build,
      allTypedApis.getQuestionGroupQuestions,
    ),
    resolveQuestionGroups: createEndpoint(
      build,
      allTypedApis.resolveQuestionGroups,
    ),
    deleteQuestionGroup: createMutation(
      build,
      allTypedApis.deleteQuestionGroup,
    ),
  }),
});

export const endpointNames = Object.keys(api.endpoints);
