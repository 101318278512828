import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import UserName from '~/components/Client/UserName';
import Datetime from '~/components/Shared/Datetime';
import { Text } from '~/eds';
import { EntityType, TicketActivityType } from '~/enums';
import { api } from '~/redux';
import { Alert } from '~/ui';

const TicketCancelledWarning = ({ ticketId, cancelledDate }) => {
  const { data: activities = [] } = api.endpoints.getActivities.useQuery(
    {
      entityId: ticketId,
      entityType: EntityType.Ticket,
    },
    { skip: !ticketId },
  );

  const cancelledTicketActivity = useMemo(
    () =>
      activities.find(
        (activity) => activity.action === TicketActivityType.CancelTicket,
      ),
    [activities],
  );
  return cancelledTicketActivity ? (
    <Alert variant="warning">
      <Text variant="body-medium">
        Ticket was cancelled on <Datetime datetime={cancelledDate} /> by{' '}
        <UserName userId={cancelledTicketActivity.user.id} />.
      </Text>
    </Alert>
  ) : null;
};

const mapStateToProps = ({ ticket }) => ({
  ticketId: ticket?.id,
  cancelledDate: ticket?.cancelledDate,
});
export default connect(mapStateToProps)(TicketCancelledWarning);
