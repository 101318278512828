import React, { useEffect, useState } from 'react';

import { PersistedTable } from '~/components/Shared/PersistedTable';
import { ContentContainer, Layout, PageLayout, useToast } from '~/eds';
import { TableContextType } from '~/enums';
import { Filter } from '~/evifields';
import {
  AuditLogRecord,
  AuditLogsFilters,
  initialFilters,
  MAX_AUDIT_LOGS_COUNT,
  validateFilters,
} from '~/features/audit-logs';
import { useCurrentUser, useTableSettings } from '~/hooks';
import { api } from '~/redux';
import { generateNav, RoutePathType, useRouting } from '~/routing';
import { exportXlsx } from '~/utils/files';

const Page = () => {
  const { client } = useCurrentUser();
  const { toast } = useToast();
  const { getSearchParams, setSearchParams } = useRouting();

  const nav = generateNav({
    current: {
      text: 'Audit Logs',
      pathname: RoutePathType.AdminConsoleClientAuditLogs,
    },
    from: RoutePathType.AdminConsoleClient,
    params: {
      clientId: client,
    },
  });

  const { tableSettings = initialTableSettings } = useTableSettings(
    TableContextType.AuditLogs,
  );

  const { pageSize } = tableSettings;

  const searchParams = getSearchParams();

  const pageIndex = Number(
    searchParams.pageIndex ?? initialTableSettings.pageIndex,
  );

  const sortBy = parseSortByParam(searchParams.sortBy);

  const persistedFilters = searchParams.filters
    ? parseFiltersParams(searchParams.filters)
    : initialFilters;

  const [filters, setFilters] = useState<Filter[]>(persistedFilters);

  const {
    data = { results: [], totalCount: 0 },
    isFetching: isLoading,
    error,
  } = api.endpoints.getAuditLogs.useQuery({
    filters,
    pageIndex,
    pageSize,
    sortBy,
  });

  const [
    getAuditLogsExport,
    { isFetching: isLoadingExportRecords },
  ] = api.endpoints.getAuditLogs.useLazyQuery();

  const { results, totalCount } = data;

  useEffect(() => {
    if (isLoadingExportRecords) {
      toast({
        status: 'info',
        message: 'Exporting audit logs…',
      });
    }
    if (error?.response?.data?.data) {
      toast({
        status: 'danger',
        message: `${error.response.data.data}`,
      });
    }
  }, [isLoadingExportRecords, error]);

  const placeholderContent = error
    ? { message: 'Failed to load audit logs.' }
    : totalCount === 0
    ? { message: 'No audit logs found.' }
    : totalCount > MAX_AUDIT_LOGS_COUNT
    ? { message: 'Too many results to display. Please refine your search.' }
    : undefined;

  const loadingContent = {
    isLoading,
    message: 'Loading audit log',
  };

  return (
    <PageLayout loadingContent={loadingContent} nav={nav} title="Audit Logs">
      <Layout preset="sections">
        <AuditLogsFilters
          filters={filters}
          onChange={(_updatedFilters: Filter[]) => {
            const updatedFilters = validateFilters(_updatedFilters);

            setFilters(updatedFilters);
            setSearchParams({ filters: serializeFiltersParam(updatedFilters) });
          }}
        />
        <ContentContainer placeholderContent={placeholderContent}>
          <PersistedTable
            actions={[
              {
                label: 'Export',
                icon: 'download',
                tooltip: 'Export Excel',
                onClick: (tableState: any) => {
                  const { columnOrder, name, totalCount } = tableState;
                  getAuditLogsExport({
                    filters,
                    sortBy,
                    pageSize: totalCount,
                    pageIndex: 1,
                  })
                    .then((response) => {
                      const prepareResponse = response.data?.results.map(
                        (record) => {
                          return columnOrder.reduce(
                            (newRecord: any, key: string) => {
                              if (key in record) {
                                newRecord[key] =
                                  record[key as keyof AuditLogRecord];
                              }

                              return newRecord;
                            },
                            {},
                          );
                        },
                      );
                      exportXlsx({
                        data: prepareResponse,
                        name,
                      });
                      toast({
                        status: 'success',
                        message: 'Audit logs exported successfully.',
                      });
                    })
                    .catch(() => {
                      toast({
                        status: 'danger',
                        message: 'Failed to export audit logs.',
                      });
                    });
                },
              },
            ]}
            context={TableContextType.AuditLogs}
            name="Audit Logs"
            data={results}
            columns={tableColumns}
            options={tableOptions}
            state={{
              pageIndex,
              pageSize,
              sortBy: [sortBy],
            }}
            totalCount={totalCount}
            reactTableOptions={{
              manualSortBy: true,
            }}
            onPaginate={({ pageIndex }) => {
              setSearchParams({
                pageIndex: String(pageIndex),
              });
            }}
            onUpdate={(state, action) => {
              if (action?.type === 'toggleSortBy') {
                setSearchParams({
                  pageIndex: '1', // reset page index when sorting
                  sortBy: serializeSortByParam({
                    id: action.columnId,
                    desc: state.sortBy[0]?.desc,
                  }),
                });
              }
            }}
          />
        </ContentContainer>
      </Layout>
    </PageLayout>
  );
};

const initialTableSettings = {
  pageIndex: 1,
  pageSize: 1000,
  columnOrder: [
    'datetime',
    'initiatorName',
    'entityType',
    'entityName',
    'activity',
    'details',
  ],
  sortBy: {
    id: 'datetime',
    desc: true,
  },
};

const tableColumns = [
  {
    key: 'datetime',
    cellType: 'datetime',
    title: 'Date (UTC)',
    mapCellProps: (d: AuditLogRecord) => ({
      datetime: d.datetime ? new Date(d.datetime) : null,
      format: 'full',
    }),
    width: 'l',
  },
  {
    key: 'initiatorName',
    cellType: 'text',
    title: 'Initiator Name',
    mapCellProps: (d: AuditLogRecord) => ({
      text: d.initiatorName,
    }),
  },
  {
    key: 'recordType',
    cellType: 'text',
    title: 'Record Type',
    mapCellProps: (d: AuditLogRecord) => ({
      text: d.recordType,
    }),
  },
  {
    key: 'recordName',
    cellType: 'text',
    title: 'Record Name',
    mapCellProps: (d: AuditLogRecord) => ({
      text: d.recordName,
    }),
  },
  {
    key: 'activity',
    cellType: 'text',
    title: 'Activity',
    mapCellProps: (d: AuditLogRecord) => ({
      text: d.activity,
    }),
  },
  {
    key: 'details',
    cellType: 'text',
    title: 'Details',
    mapCellProps: (d: AuditLogRecord) => {
      return {
        text: d.details,
        limit: 60,
        shouldTruncate: true,
      };
    },
  },
  {
    key: 'initiatorId',
    cellType: 'text',
    title: 'Initiator ID',
    mapCellProps: (d: AuditLogRecord) => ({
      text: d.initiatorId,
    }),
  },
  {
    key: 'initiatorType',
    cellType: 'text',
    title: 'Initiator Type',
    mapCellProps: (d: AuditLogRecord) => ({
      text: d.initiatorType,
    }),
  },
  {
    key: 'recordId',
    cellType: 'text',
    title: 'Record ID',
    mapCellProps: (d: AuditLogRecord) => ({
      text: d.recordId,
    }),
  },
];

const tableOptions = {
  enableExportXlsx: false,
  enablePagination: true,
  enableManageColumns: true,
  enableSelectRows: false,
  pageSizes: [100, 250, 500, 750, 1000],
};

const serializeFiltersParam = (filters: Filter[]) => {
  const filtersWithDatesConvertedToStrings = filters.map((filter) => {
    if (filter.fieldType === 'date') {
      return {
        ...filter,
        values: filter.values.map((value) =>
          value instanceof Date ? value.toISOString() : value,
        ),
      };
    }
    return filter;
  });
  return btoa(JSON.stringify(filtersWithDatesConvertedToStrings));
};

const parseFiltersParams = (filtersParam: string) => {
  return JSON.parse(atob(filtersParam)).map((filter: Filter) => {
    if (filter.fieldType === 'date') {
      return {
        ...filter,
        values: filter.values.map(
          (value) => new Date(value as string | number),
        ),
      };
    }
    return filter;
  });
};

const parseSortByParam = (sortByParam = '') => {
  const [sortColumn, sortOrder] = sortByParam.split(':') as [
    keyof AuditLogRecord,
    'asc' | 'desc',
  ];
  return sortColumn && sortOrder
    ? {
        id: sortColumn,
        desc: sortOrder === 'desc',
      }
    : undefined;
};

const serializeSortByParam = ({ id, desc }: { id: string; desc: boolean }) =>
  `${id}:${desc ? 'desc' : 'asc'}`;

export default Page;
