import { createSelector } from 'reselect';

import { DocumentRestoreSlice, name } from '../slices/restorationHub';

const selectSlice = (rootState: any): DocumentRestoreSlice => rootState[name];

export const selectColumnOrder = createSelector(
  selectSlice,
  (state) => state.columnOrder,
);
