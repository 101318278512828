import { hideSpinner, showSpinner } from '@syncfusion/ej2-popups';
import {
  CommentElementBox,
  DocumentEditor,
  DocumentEditorContainerComponent,
  Revision,
} from '@syncfusion/ej2-react-documenteditor';
import { noop, throttle } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';

import { extractClausesIds } from '~/components/Workflow/TicketReviewerPage/TicketReviewerPage.utils';
import { diffTextWithSpaces } from '~/eds';
import { EntityType } from '~/enums';
import { Highlight } from '~/features/document-viewer';
import { useStateAndRef } from '~/hooks';
import { api } from '~/redux';
import {
  ClauseContent,
  DocumentViewClause,
  Entity,
  Nullable,
  Uuid,
} from '~/types';
import { captureException } from '~/utils';

import { DOCUMENT_START_POSITIONS, TEMP_BOOKMARK } from './constants';
import { IHighlight } from './Highlight';
import { Bookmarks, bookmarkTypes, RevisionType } from './types';
import {
  approveRevisionsInSelection,
  extendSelection,
  generateBookmarks,
  getBookmarkType,
  getComment,
  getCommentText,
  getHighlightRects,
  getSelectedTextRevisionType,
  mapTextToWordPosition,
  mapWordsToEj2Words,
  moveCursorInText,
  selectTokens,
  validateBookmark,
  validateEditMode,
} from './utils';

interface CreateClausesHighlightsParams {
  clauses: DocumentViewClause[];
  /**
   * if true, it will not create the util bookmark when finishing the process
   */
  disableUtils: boolean;
  documentContent: { words: { word: string; id: Nullable<number> }[] };
  versionId: string;
  onClausesCreated: () => void;
}

export interface DocumentEditorContextType {
  /**
   * Flag for when the document is loaded and ready to be used
   */
  isDocumentReady: boolean;
  /**
   * Check if it's in edit mode
   */
  isInEditMode: boolean;
  /**
   * Whenever the editor is loading. Setting this to true will enable the loading spinners in the document editor
   */
  isLoading: boolean;
  /** A dictionary of bookmarks that exist in the document */
  bookmarks: Bookmarks;
  /** An array of comments */
  comments: CommentElementBox[];
  /**
   * The documentEditor instance that can be used to modify the document.
   */
  documentEditor: Nullable<DocumentEditor>;
  /**
   * The documentEditor container that has other features like the toolbar module
   */
  documentEditorContainer: Nullable<DocumentEditorContainerComponent>;
  /**
   * An array of revisions
   */
  revisions: Revision[];
  /**
   * The width of the viewer container
   */
  viewerClientWidth: number;
  /**
   * The zoom factor of the document editor
   */
  zoomFactor: number;
  clearSelection: () => void;
  /**
   *
   * @param entity the entity to be resolved of EditorHighlight type, which represents a editor comment
   * @returns the text of the comment and it's id
   */
  commentResolver: (
    entity: Entity<EntityType.EditorHighlight>,
  ) => { id: string; text: string };
  /**
   *
   * @param props the parameters to create the clauses highlights
   * @returns if the operation was successful or not
   */
  createClausesHighlights: (props: CreateClausesHighlightsParams) => boolean;
  /**
   * Delete a comment with a given id inside the document editor
   * @param id the id of the comment
   */
  deleteComment: (id: string) => void;
  /**
   * Extract the bookmark content as string
   *
   * @param id the bookmark id
   * @returns the bookmark content as string
   */
  getBookmarkContent: (id: string) => string;
  /** Get the text selected in the editor */
  getSelectedText: () => string;
  /**
   * Creates or updates a bookmark.
   *
   * @param bookmarkId The id for the new or existing bookmark
   */
  insertBookmark: (bookmarkId: string) => void;
  /**
   * Deletes a bookmark.
   *
   * @param bookmarkId The id of the bookmark
   */
  deleteBookmark: (bookmarkId: string) => void;
  /**
   * This function will insert the text into the document editor exactly where the cursor is. Can only be used in edit mode
   *
   * @param text a raw text string or a stringfied HTML
   * @param isHtml should be set to true if the text is a stringfied HTML.
   */
  insertText: (text: string, isHtml?: boolean) => void;
  /**
   * Navigate to a bookmark inside the document
   *
   * @param id the bookmark id
   */
  navigateToBookmark: (id: string) => void;
  /**
   * Navigate to a comment inside the document
   *
   * @param id the comment id
   */
  navigateToComment: (id: string) => void;
  /**
   * Parse the highlights to be visible in te document editor
   */
  parseHighlights: (
    documentEditor: DocumentEditor,
    highlights: Highlight<{ type: string }>[],
    documentContent?: { words: { word: string; id: Nullable<number> }[] },
  ) => IHighlight[];
  /**
   * Replace a bookmark content by the provided text content. Can only be used in edit mode
   *
   * @param id the bookmark id
   * @param text the text to be inserted in the bookmark
   */
  replaceBookmarkContent: (id: string, text: string) => void;
  /**
   * Replace a bookmark content by the provided text content which will try to replace only the diff parts. Can only be used in edit mode
   *
   * @param id the bookmark id
   * @param text the text to be inserted in the bookmark
   */
  replaceBookmarkContentFromDiff: (id: string, text: string) => void;
  /**
   * Replace a selected text content by the provided text content which will try to replace only the diff parts. Can only be used in edit mode
   * @param text the text to be inserted
   */
  replaceTextFromDiff: (
    text: string,
    options?: { shouldAcceptRevisions: boolean },
  ) => void;
  /**
   * Reply a comment with a given id inside the document editor
   * @param id the id of the comment
   * @param text the text of the comment
   */
  replyComment: (id: string, text: string) => void;
  /**
   * Resolve a comment with a given id inside the document editor
   * @param id the id of the comment
   */
  resolveComment: (id: string) => void;
  /**
   * Select a clause in the document editor
   * @param clauseContent the clause content
   * @param documentContent the document content
   */
  selectClauseInDocument: (props: {
    clauseContent: ClauseContent;
    documentContent: { words: { word: string; id: Nullable<number> }[] };
  }) => void;
  /**
   * Select content from html tokens in the document editor
   * @param tokens the array of tokens to be selected
   * @param documentContent the document content
   */
  selectContentFromTokens: (props: {
    tokens: number[];
    documentContent: { words: { word: string; id: Nullable<number> }[] };
  }) => boolean;
  /**
   * Select a text using the EJ2 positioning which is "section;block;offset"
   * Example:
   *
   * `selectText("0;0;0", "0;0;20");`
   *
   * will select the text with 20 offset within the same section and block.
   * @param start the start position with format "section;block;offset"
   * @param end the end position with format "section;block;offset"
   */
  selectText: (start: string, end: string) => void;
  /**
   * Set the documentEditor instance
   *
   * @param editor a documentEditor instance
   */
  setDocumentEditorContainer: (
    editor: DocumentEditorContainerComponent,
  ) => void;
  /**
   *
   * @param isInEditMode if its in edit mode
   */
  setIsInEditMode: (isInEditMode: boolean) => void;
  /**
   *
   * @param isDocumentReady a boolean for whether the document is ready or not
   */
  setIsDocumentReady: (isDocumentReady: boolean) => void;
  /**
   *
   * @param isLoading a boolean for whether the document editor is loading or not. Setting this to true will enable the loading spinners in the document editor.
   */
  setIsLoading: (isLoading: boolean) => void;
  /**
   * Sets the zoom factor of the editor
   *
   * @param zoomFactor the zoom factor of the document editor
   */
  setZoomFactor: (zoomFactor: number) => void;
  /**
   * enable/disable track changes. Should only be used in edit mode
   */
  toggleTrackChanges: (enable: boolean) => void;
  /**
   * Unresolve a comment with a given id inside the document editor
   * @param id the id of the comment
   */
  unresolveComment: (id: string) => void;
  /**
   * Unresolve a comment with a given id inside the document editor
   * @param id the id of the comment
   */
  updateComment: (id: string, text: string) => void;
}

interface Props {
  children: React.ReactNode;
}

export const DocumentEditorContext = React.createContext<DocumentEditorContextType>(
  {
    isDocumentReady: false,
    isInEditMode: false,
    isLoading: false,
    bookmarks: bookmarkTypes.reduce((acc, curr) => {
      acc[curr] = new Map();
      return acc;
    }, {} as Bookmarks),
    comments: [],
    revisions: [],
    documentEditor: null,
    documentEditorContainer: null,
    viewerClientWidth: 0,
    zoomFactor: 1,
    clearSelection: noop,
    commentResolver: () => ({ id: '', text: '' }),
    createClausesHighlights: () => true,
    deleteBookmark: noop,
    deleteComment: noop,
    getBookmarkContent: () => '',
    getSelectedText: () => '',
    insertBookmark: noop,
    insertText: noop,
    navigateToBookmark: noop,
    navigateToComment: noop,
    parseHighlights: () => [],
    replaceBookmarkContent: noop,
    replaceBookmarkContentFromDiff: noop,
    replaceTextFromDiff: noop,
    replyComment: noop,
    resolveComment: noop,
    selectClauseInDocument: noop,
    selectContentFromTokens: () => false,
    selectText: noop,
    setDocumentEditorContainer: noop,
    setIsDocumentReady: noop,
    setIsInEditMode: noop,
    setIsLoading: noop,
    setZoomFactor: noop,
    toggleTrackChanges: noop,
    unresolveComment: noop,
    updateComment: noop,
  },
);

export const DocumentEditorProvider = ({ children }: Props) => {
  const [
    documentEditorContainer,
    setDocumentEditorContainer,
    documentEditorContainerRef,
  ] = useStateAndRef<Nullable<DocumentEditorContainerComponent>>(null);
  const [bookmarks, setBookmarks] = useState<Bookmarks>(
    bookmarkTypes.reduce((acc, curr) => {
      acc[curr] = new Map();
      return acc;
    }, {} as Bookmarks),
  );
  const documentEditor =
    documentEditorContainerRef.current?.documentEditor || null;
  const [
    convertContentToSfdt,
  ] = api.endpoints.convertContentToSfdt.useMutation();
  const [isDocumentReady, updateIsDocumentReady] = useState(false);
  const [isLoading, _setIsLoading] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [zoomFactor, _setZoomFactor, zoomFactorRef] = useStateAndRef(1);
  const [
    viewerClientWidth,
    setViewerClientWidth,
    viewerClientWidthRef,
  ] = useStateAndRef(0);

  /**
   * The viewerContainer shrinks when opening the sidepanel and it's not monitored by documentEditor,
   * nor has any callback. We need to watch for this resize to be able to position the highlight correctly
   */
  useEffect(() => {
    const element = documentEditor?.documentHelper?.viewerContainer;

    if (element && isDocumentReady) {
      const resize = throttle((entries) => {
        if (!entries || entries.length === 0) {
          return;
        }

        if (element.clientWidth !== viewerClientWidthRef.current) {
          setViewerClientWidth(element.clientWidth);
        }
      }, 100);

      const resizeObserver = new ResizeObserver(resize);
      resizeObserver.observe(element);

      return () => resizeObserver.unobserve(element);
    }
  }, [isDocumentReady]);

  const setIsLoading = (loading: boolean) => {
    const container = documentEditor?.element;
    if (container) {
      loading ? showSpinner(container) : hideSpinner(container);
    }
    _setIsLoading(loading);
  };

  const insertBookmark = (bookmarkId: string) => {
    if (documentEditor) {
      documentEditor.editor.insertBookmark(bookmarkId);
    }
    if (documentEditor && bookmarkId !== TEMP_BOOKMARK) {
      const bookmark = documentEditor.documentHelper.bookmarks.get(bookmarkId);
      const type = getBookmarkType(bookmarkId);
      if (type) {
        bookmarks[type].set(bookmarkId, bookmark);
      }
    }
  };

  const deleteBookmark = (bookmarkId: string) => {
    if (documentEditor) {
      documentEditor.editor.deleteBookmark(bookmarkId);
    }
    if (documentEditor && bookmarkId !== TEMP_BOOKMARK) {
      const type = getBookmarkType(bookmarkId);
      if (type) {
        bookmarks[type].delete(bookmarkId);
      }
    }
  };

  const insertText = async (text: string, isHtml = false) => {
    validateEditMode(documentEditor!);
    if (documentEditor) {
      try {
        if (isHtml) {
          const container = documentEditor.element;
          container && showSpinner(container);
          await convertContentToSfdt({ content: text, type: '.html' })
            .unwrap()
            .then((payload) => {
              documentEditor.editor.paste(payload?.text);
            })
            .catch((error) => {
              throw new Error(error);
            })
            .finally(() => {
              container && hideSpinner(container);
            });
        } else {
          documentEditor.editor.insertText(text);
        }
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
  };

  // Not being used in other places.
  const replaceBookmarkContent = (id: string, text: string) => {
    validateEditMode(documentEditor!);
    validateBookmark(id, bookmarks);
    if (documentEditor) {
      try {
        documentEditor.selection.selectBookmark(id);
        const originalStartOffset = documentEditor.selection.startOffset;
        documentEditor.editor.deleteBookmark(id);
        // delete old bookmark
        documentEditor.editor.delete();
        // insert new text
        documentEditor.editor.insertText(text);
        // select all the new content including the revisions if track changes is enabled
        documentEditor.selection.select(
          originalStartOffset,
          documentEditor.selection.endOffset,
        );
        // create a bookmark with the previous id.
        insertBookmark(id);
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
  };

  /**
   * This method is designed to replace the content of a bookmark with the provided text.
   *
   * @param id id of the bookmark
   * @param text the text to be inserted
   * @param options shouldAcceptRevisions: if true, it will try to fix the offset of the cursor after the insertion
   */
  const replaceBookmarkContentFromDiff = (
    id: string,
    text: string,
    options: { shouldAcceptRevisions: boolean } = {
      shouldAcceptRevisions: false,
    },
  ) => {
    const { shouldAcceptRevisions } = options;
    validateEditMode(documentEditor!);
    validateBookmark(id, bookmarks, documentEditor!);
    if (documentEditor) {
      try {
        documentEditor.selection.selectBookmark(id);
        if (shouldAcceptRevisions) {
          approveRevisionsInSelection(documentEditor);
          documentEditor.selection.selectBookmark(id);
        }
        const originalStartOffset = documentEditor.selection.startOffset;
        const bookmarkText = documentEditor.selection.text;
        const dText = diffTextWithSpaces(bookmarkText, text);
        documentEditor.selection.select(
          originalStartOffset,
          originalStartOffset,
        );
        let shouldMoveCursor = false;
        for (const diff of dText) {
          // if it's not adding or removing, then it's only moving
          if (!diff.added && !diff.removed) {
            moveCursorInText(diff.value.length, documentEditor);
          } else if (diff.removed) {
            // since the text extracted contains \r and the compared text doesnt,
            // it create a lot of removals for \r which is not necessary and we should ignore it
            // so we check if the diff is a break line and ignore it (but still move the cursor)
            const isBreakLine = diff.value === '\r';
            // there is an edge case where the diff is a break line and the next diff is an addition
            // because it will be adding content at the end of the paragraph, so it does make sense to
            // just ignore the \r and NOT move to next position since it would cause to change lines.
            const isAddingNext = dText[dText.indexOf(diff) + 1]?.added;
            if (!isBreakLine) {
              // select the text to be removed
              extendSelection('end', diff.value.length, documentEditor);
              documentEditor.editor.delete();
            }
            if (isBreakLine && isAddingNext) {
              shouldMoveCursor = true;
              continue;
            }
            // move the cursor back to the end of the removed content since it was created a track change, so it keeps the navigation
            moveCursorInText(diff.value.length, documentEditor, isBreakLine);
          } else if (diff.added) {
            documentEditor.editor.insertText(diff.value);
            if (shouldMoveCursor) {
              moveCursorInText(1, documentEditor, true);
              shouldMoveCursor = false;
            }
          }
        }
        // select all the new content including the revisions if track changes is enabled
        documentEditor.selection.select(
          originalStartOffset,
          documentEditor.selection.endOffset,
        );
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
  };

  const replaceTextFromDiff = (
    text: string,
    options?: { shouldAcceptRevisions: boolean },
  ) => {
    if (documentEditor) {
      if (!documentEditor.selection.isEmpty) {
        documentEditor.editor.insertBookmark(TEMP_BOOKMARK);
        replaceBookmarkContentFromDiff(TEMP_BOOKMARK, text, options);
        documentEditor.editor.deleteBookmark(TEMP_BOOKMARK);
      }
    }
  };

  const navigateToBookmark = (id: string) => {
    validateBookmark(id, bookmarks);
    if (documentEditor) {
      documentEditor.selection.selectBookmark(id);
      documentEditor.selection.clearSelectionHighlightInSelectedWidgets();
    }
  };

  const getBookmarkContent = (id: string) => {
    validateBookmark(id, bookmarks);
    if (documentEditor) {
      documentEditor.selection.selectBookmark(id);
      return documentEditor.selection.text;
    }
    return '';
  };

  const toggleTrackChanges = (enable: boolean) => {
    if (documentEditorContainer) {
      validateEditMode(documentEditor!);
      documentEditorContainer.toolbarModule.toggleTrackChanges(enable);
    }
  };

  const navigateToComment = (id: string) => {
    if (documentEditor) {
      const comment = getComment(id, documentEditor);
      comment && documentEditor.selection.selectComment(comment);
      documentEditor.selection.clear();
    }
  };

  const commentResolver = (entity: Entity<EntityType.EditorHighlight>) => {
    const comment = {
      id: '',
      text: 'Comment not found.',
    };
    if (documentEditor) {
      const editorComment = getComment(entity.id, documentEditor);
      if (editorComment) {
        comment.id = editorComment.commentId;
        comment.text = getCommentText(entity.id, documentEditor);
      }
    }
    return comment;
  };

  const deleteComment = (id: string) => {
    if (documentEditor) {
      const comment = getComment(id, documentEditor);
      if (comment) {
        documentEditor.editor.deleteCommentInternal(comment);
      }
    }
  };

  const resolveComment = (id: string) => {
    if (documentEditor) {
      const editorComment = getComment(id, documentEditor);
      editorComment && documentEditor.editor.resolveComment(editorComment);
    }
  };

  const unresolveComment = (id: string) => {
    if (documentEditor) {
      const editorComment = getComment(id, documentEditor);
      editorComment && documentEditor.editor.reopenComment(editorComment);
    }
  };

  const replyComment = (parentCommentId: string, text: string) => {
    if (documentEditor) {
      const parentComment = getComment(parentCommentId, documentEditor);
      parentComment && documentEditor.editor.replyComment(parentComment, text);
    }
  };

  const updateComment = (id: string, text: string) => {
    if (documentEditor) {
      const comment = getComment(id, documentEditor);
      if (comment) {
        comment.text = text;
        documentEditor.fireContentChange();
      }
    }
  };

  /**
   * @param revisionType the type of revision to be included when getting the selected text
   * possible values: 'All' | 'Insertion' | 'Deletion' | 'None'
   *
   * defaults to 'All'
   * @returns selected text
   */
  const getSelectedText = (revisionType: RevisionType = 'All') => {
    if (documentEditor) {
      if (!documentEditor.selection.isEmpty) {
        if (revisionType === 'All') {
          return documentEditor.selection.text;
        } else {
          return getSelectedTextRevisionType(documentEditor, revisionType);
        }
      }
    }
    return '';
  };

  const setIsDocumentReady = (isReady: boolean) => {
    if (isReady && documentEditor) {
      const generatedBookmarks = generateBookmarks(documentEditor, bookmarks);
      setBookmarks(generatedBookmarks);
    }
    updateIsDocumentReady(isReady);
  };

  const selectText = (start: string, end: string) => {
    if (documentEditor) {
      documentEditor.selection.select(start, end);
    }
  };

  const insertIsProcessedBookmark = (versionId: string) => {
    setTimeout(() => {
      if (!bookmarks.util.has(`_util_isProcessed_${versionId}`)) {
        documentEditor?.selection?.select(
          DOCUMENT_START_POSITIONS,
          DOCUMENT_START_POSITIONS,
        );
        insertBookmark(`_util_isProcessed_${versionId}`);
      }
    }, 200);
  };

  const clearSelection = () => {
    documentEditor &&
      documentEditor.selection.clearSelectionHighlightInSelectedWidgets();
  };

  const selectClauseInDocument = ({
    clauseContent,
    documentContent,
  }: {
    clauseContent: ClauseContent;
    documentContent: { words: { word: string; id: Nullable<number> }[] };
  }): boolean => {
    if (clauseContent.htmlTokens.length === 0) {
      return false;
    }
    return selectContentFromTokens({
      tokens: clauseContent.htmlTokens,
      documentContent,
    });
  };

  const selectContentFromTokens = ({
    tokens,
    documentContent,
  }: {
    tokens: number[];
    documentContent: { words: { word: string; id: Nullable<number> }[] };
  }): boolean => {
    if (documentEditor) {
      try {
        const wordsMapping = documentContent.words;
        const ej2Mapping = mapTextToWordPosition(documentEditor);
        const wordIndexToPosition = mapWordsToEj2Words(
          wordsMapping,
          ej2Mapping,
        );

        const { firstWordLocation, lastWordLocation } = selectTokens(
          tokens,
          wordIndexToPosition,
        );

        if (firstWordLocation && lastWordLocation) {
          documentEditor.selection.select(
            `${firstWordLocation.section};${firstWordLocation.block};${firstWordLocation.startOffset}`,
            `${lastWordLocation.section};${lastWordLocation.block};${lastWordLocation.endOffset}`,
          );
          return true;
        }
      } catch (e) {
        console.log('error', e);
        captureException(`An error occurred when highlighting content.`, e, {
          section: 'ej2 document editor',
        });
      }
    }
    return false;
  };

  /**
   * @deprecated
   * Deprecated. Use selectClauseInDocument instead.
   */
  const createClausesHighlights = ({
    clauses,
    documentContent,
    disableUtils = false,
    versionId,
    onClausesCreated,
  }: CreateClausesHighlightsParams): boolean => {
    let hasErrors = false;
    if (documentEditor) {
      const existingClauses = bookmarks.clause;
      // delete previous clauses, if present, and the old util bookmark
      if (existingClauses.size > 0) {
        existingClauses.forEach((_, id) => {
          if (
            !clauses.some((clause) =>
              clause.content.some((content) =>
                id.includes(content.id.toString()),
              ),
            )
          ) {
            deleteBookmark(id);
          }
        });

        bookmarks.util.forEach((_, id) => {
          if (!id.includes(versionId)) {
            deleteBookmark(id);
          }
        });
      }

      const extractedClauses = extractClausesIds(clauses);
      const clausesToBeGenerated = extractedClauses.filter(
        (clauseId: Uuid) => !existingClauses.has(`_clause_${clauseId}`),
      );

      if (clausesToBeGenerated.length > 0) {
        // maps every word to a position object
        const wordsMapping = documentContent.words;
        const ej2Mapping = mapTextToWordPosition(documentEditor);
        const wordIndexToPosition = mapWordsToEj2Words(
          wordsMapping,
          ej2Mapping,
        );

        for (const clause of clauses) {
          for (const clauseContent of clause.content) {
            try {
              if (clauseContent.htmlTokens.length === 0) {
                continue;
              }

              const { firstWordLocation, lastWordLocation } = selectTokens(
                clauseContent.htmlTokens,
                wordIndexToPosition,
              );

              if (firstWordLocation && lastWordLocation) {
                documentEditor.selection.select(
                  `${firstWordLocation.section};${firstWordLocation.block};${firstWordLocation.startOffset}`,
                  `${lastWordLocation.section};${lastWordLocation.block};${lastWordLocation.endOffset}`,
                );
                insertBookmark(`_clause_${clauseContent.id}`);
              } else {
                hasErrors = true;
              }
            } catch (e) {
              captureException(
                `An error occurred when generating clause with id ${clauseContent.id}.`,
                e,
                {
                  section: 'ej2 document editor',
                },
              );
              hasErrors = true;
            }
          }
        }

        !disableUtils && insertIsProcessedBookmark(versionId);
        onClausesCreated?.();
      } else {
        !disableUtils && insertIsProcessedBookmark(versionId);
        onClausesCreated?.();
      }
    }
    return !hasErrors;
  };

  const comments = documentEditor?.documentHelper?.comments ?? [];

  const revisions = documentEditor?.revisions?.changes ?? [];

  const setZoomFactor = (newZoomFactor: number) => {
    if (newZoomFactor !== zoomFactorRef.current) {
      _setZoomFactor(newZoomFactor);
    }
  };

  const parseHighlights = (
    documentEditor: DocumentEditor,
    highlights: Highlight<{ type: string }>[],
    documentContent?: { words: { word: string; id: Nullable<number> }[] },
  ) => {
    const extractedHighlights: IHighlight[] = [];
    highlights.forEach((eHighlight) => {
      let isFound = false;
      let rectType = '';
      switch (eHighlight?.data?.type) {
        case 'comment':
          rectType = 'comment';
          const comment = getComment(
            String(eHighlight.id) || '',
            documentEditor!,
          );
          if (comment) {
            isFound = true;
          }
          break;
        case 'revision':
          rectType = 'revision';
          const revision = documentEditor?.revisions?.changes?.find(
            (revision) => revision.revisionID === eHighlight.id,
          );
          if (revision) {
            isFound = true;
          }
          break;
        case 'source':
        case 'clause':
          rectType = 'selection';
          if (!documentContent || !eHighlight.htmlTokens) {
            throw new Error(
              'Content resolver and tokens are required to extract highlights',
            );
          }
          isFound = selectContentFromTokens({
            tokens: eHighlight.htmlTokens,
            documentContent,
          });
          break;
        default:
          throw new Error('Highlight type not supported.');
      }
      if (isFound) {
        const rects = getHighlightRects(
          String(eHighlight.id),
          documentEditor,
          rectType as any,
        );
        extractedHighlights.push({
          variant: eHighlight.data.type,
          rects,
          isVisible: false,
          id: String(eHighlight.id),
        });
        clearSelection();
      }
    });
    return extractedHighlights;
  };

  return (
    <DocumentEditorContext.Provider
      value={{
        isDocumentReady,
        isInEditMode,
        isLoading,
        comments,
        bookmarks,
        documentEditor,
        documentEditorContainer,
        revisions,
        viewerClientWidth,
        zoomFactor,
        clearSelection,
        commentResolver,
        createClausesHighlights,
        deleteBookmark,
        deleteComment,
        getBookmarkContent,
        getSelectedText,
        insertBookmark,
        insertText,
        navigateToBookmark,
        navigateToComment,
        parseHighlights,
        replaceBookmarkContent,
        replaceBookmarkContentFromDiff,
        replaceTextFromDiff,
        replyComment,
        resolveComment,
        selectClauseInDocument,
        selectContentFromTokens,
        selectText,
        setDocumentEditorContainer,
        setIsDocumentReady,
        setIsInEditMode,
        setIsLoading,
        setZoomFactor,
        toggleTrackChanges,
        unresolveComment,
        updateComment,
      }}
    >
      {children}
    </DocumentEditorContext.Provider>
  );
};

export const useDocumentEditorContext = (): DocumentEditorContextType =>
  useContext(DocumentEditorContext);
