import React from 'react';
import { connect } from 'react-redux';

import UserName from '~/components/Client/UserName';
import Datetime from '~/components/Shared/Datetime';
import DocVersionMenu from '~/components/Shared/DocVersionMenu';
import {
  getOptionLabel,
  getVersionNumberIds,
} from '~/components/Shared/DocVersionMenu/utils';
import { OutboundEmailFileType } from '~/enums';
import { Box, FlexLayout, Icon, Text } from '~/ui';
import { getFileIcon } from '~/utils/files';

function TicketFilesSelect({
  documentVersions = [],
  attachments = [],
  value,
  onSelect,
}) {
  const isMenuButtonDisabled =
    documentVersions.length < 2 && attachments.length === 0;

  function getAttachmentLabel({ expanded, attachment, isSelected, disabled }) {
    const { fileType, createdAt, fileName, userId } = attachment;

    const dummyFile = new File([], `file.${fileType}`); // create a dummy file for reference with file APIs.

    return (
      <>
        <Box
          p={4}
          disabled={disabled}
          sx={{
            width: '100%',
            backgroundColor: isSelected ? 'gray-200' : 'transparent',
          }}
        >
          <FlexLayout
            alignItems="center"
            justifyContent="space-between"
            space={1}
          >
            <FlexLayout space={1}>
              <Icon icon={getFileIcon(dummyFile)} size="s" />
              <Text color="gray-700" variant="body1">
                {fileName}
              </Text>
            </FlexLayout>
          </FlexLayout>
          {expanded && (
            <>
              <Box>
                <Text color="gray-900" variant="tiny">
                  {userId ? (
                    <>
                      Created by <UserName userId={userId} />
                    </>
                  ) : (
                    <>Created at</>
                  )}
                </Text>
              </Box>

              <Box>
                <Text color="gray-600" variant="2xs-dense">
                  <Datetime datetime={createdAt} format="duration" />
                </Text>
              </Box>
            </>
          )}
        </Box>
      </>
    );
  }

  function isSelected(id) {
    return value.some((file) => file.id === id);
  }

  function handleSelectedFiles({ value: selection }) {
    if (isSelected(selection.id)) {
      onSelect(value.filter((file) => selection.id !== file.id));
    } else {
      onSelect([...value, selection]);
    }
  }

  function renderListItem(expanded) {
    const versionsNumberIds = getVersionNumberIds(documentVersions);
    const versionOptions = documentVersions.map((documentVersion) => {
      return {
        value: {
          id: documentVersion.id,
          type: OutboundEmailFileType.ExistingVersion,
        },
        content: getOptionLabel({
          versionsObject: versionsNumberIds,
          expanded,
          version: documentVersion,
          disabled: isSelected(documentVersion.id),
        }),
        onClick: handleSelectedFiles,
        disabled: isSelected(documentVersion.id),
      };
    });
    const attachmentOptions = attachments.map((attachment) => {
      return {
        value: {
          id: attachment.id,
          type: OutboundEmailFileType.ExistingAttachment,
        },
        content: getAttachmentLabel({
          expanded,
          attachment,
          disabled: isSelected(attachment.id),
        }),
        onClick: handleSelectedFiles,
        disabled: isSelected(attachment.id),
      };
    });

    const menuDivider = { isDivider: true };

    return [...versionOptions, menuDivider, ...attachmentOptions];
  }

  function renderMenuButton() {
    return (
      <FlexLayout space={2} alignItems="center">
        <Icon icon="plus" size="m" />
        <Text variant="body2">Add from ticket</Text>
      </FlexLayout>
    );
  }

  return (
    <DocVersionMenu
      renderListItem={renderListItem}
      renderMenuButton={renderMenuButton}
      documentVersions={documentVersions}
      disabled={isMenuButtonDisabled}
    />
  );
}

const mapStateToProps = ({ ticket }) => ({
  documentVersions: ticket.documentVersions,
  attachments: ticket.attachments,
});

export default connect(mapStateToProps)(TicketFilesSelect);
