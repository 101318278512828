import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import {
  QuerySubState,
  RequestStatusFlags,
  SubscriptionOptions,
} from '@reduxjs/toolkit/dist/query/core/apiState';
import { QueryArgFrom } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
  UseQuery,
  UseQueryStateOptions,
} from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useEffect, useState } from 'react';

const DEFAULT_POLLING_REQUEST_INTERVAL = 3000;

interface Options {
  maxRetries?: number;
}

export const usePollingRequest = <
  Q extends QueryDefinition<any, any, any, any, any>
>(
  request: UseQuery<Q>,
  params: QueryArgFrom<Q>,
  options: SubscriptionOptions & UseQueryStateOptions<Q, any> & Options = {},
): QuerySubState<Q> &
  RequestStatusFlags & {
    isPollingFinished: boolean;
    isPollingFinishedByMaxRetries: boolean;
    reset: () => void;
    stopPolling: () => void;
  } => {
  const { maxRetries = 5, skip, ...queryOptions } = options;
  const [retryCount, setRetryCount] = useState(0);
  const [shouldStopPolling, setShouldStopPolling] = useState(false);

  const result = request(params, {
    pollingInterval: DEFAULT_POLLING_REQUEST_INTERVAL,
    skip: shouldStopPolling || skip,
    ...queryOptions,
  });
  const { requestId } = result;

  useEffect(() => {
    requestId && setRetryCount((prevRetryCount) => prevRetryCount + 1);
  }, [requestId]);

  useEffect(() => {
    retryCount === maxRetries && setShouldStopPolling(true);
  }, [retryCount]);

  const reset = () => {
    setRetryCount(0);
    setShouldStopPolling(false);
  };

  const stopPolling = () => setShouldStopPolling(true);

  return {
    ...result,
    stopPolling,
    isPollingFinishedByMaxRetries:
      shouldStopPolling && retryCount >= maxRetries,
    isPollingFinished: shouldStopPolling || skip,
    reset,
  };
};
