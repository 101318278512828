import { useEffect, useMemo, useState } from 'react';

import { Button, FormField, Layout } from '../../components';
import {
  Field,
  FieldGroup,
  FieldGroupConfig,
  FieldValidator,
  FormErrors,
  FormState,
  StateUpdater,
  TouchedFields,
} from './types';
import { orderFields, prepareFields } from './utils';

type OrderedFields = (Field | FieldGroupConfig)[];

// general idea of how we can use few props and have great control over the state
interface FormProps<V extends Record<string, unknown>> {
  /**
   * Fields can be a mix of Field and FieldGroup where the name of each field should be unique
   */
  fields: Record<string, Field | FieldGroup>;
  state: FormState<V>;
  onChange: (updatedState: StateUpdater<V>) => void;
  validators?: FieldValidator<V[keyof V]>[];
  onSubmit?: (updatedState: StateUpdater<V>) => void;
  onValidate?: (updatedState: StateUpdater<V>) => void;
}

export function Form<V extends Record<string, unknown>>({
  fields: initialFields,
  state: initialState,
  validators = [],
  onChange,
  onSubmit,
  onValidate,
}: FormProps<V>) {
  // we might need a logic to run through all fields and
  // fill the values in case the user passes an empty value
  const [state, setState] = useState<FormState<V>>(initialState);
  const [errors, setErrors] = useState<FormErrors>({});
  const [touchedFields, setTouchedFields] = useState<TouchedFields>(new Set());
  const [dirtyFields, setDirtyFields] = useState<TouchedFields>(new Set());

  // get all fields from a group and spread into the initial object so we have all fields in the same level
  const fields: Record<string, Field> = useMemo(
    () => prepareFields(initialFields),
    [initialFields],
  );

  // this is an initial validation so we can tell upstream if the form is valid or not
  useEffect(() => {
    const updatedErrors = validateFields(state);
    onValidate?.({
      state,
      errors: updatedErrors,
      isTouched: false,
      isDirty: false,
      touchedFields: touchedFields,
      dirtyFields: dirtyFields,
    });
  }, [fields]);

  function onFieldValueChange(field: Field, value: V[Field['name']]) {
    const updatedState: FormState<V> = {
      ...state,
      [field.name]: { ...state[field.name], value },
    };
    const updatedErrors = validateFields(updatedState);

    const updatedDirtyFields = dirtyFields.add(field.name);
    const updatedTouchedFields = touchedFields.add(field.name);

    setState(updatedState);
    setErrors(updatedErrors);
    setDirtyFields(updatedDirtyFields);
    setTouchedFields(updatedTouchedFields);

    onValidate?.({
      state: updatedState,
      errors: updatedErrors,
      touchedFields: updatedDirtyFields,
      dirtyFields: updatedTouchedFields,
      isDirty: updatedDirtyFields.size > 0,
      isTouched: updatedTouchedFields.size > 0,
    });
    onChange({
      state: updatedState,
      errors: updatedErrors,
      touchedFields: updatedDirtyFields,
      dirtyFields: updatedTouchedFields,
      isDirty: updatedDirtyFields.size > 0,
      isTouched: updatedTouchedFields.size > 0,
    });
  }

  function validateFields(currentState: FormState<V>): FormErrors {
    const fieldErrors: FormErrors = {};
    validators.forEach((validate) => {
      Object.entries(fields).forEach(([fieldKey, fieldValue]) => {
        const fieldState = currentState[fieldKey];
        if (!fieldState) return;
        const fieldError = validate(fieldValue, fieldState.value);
        if (fieldError) {
          // we check if the error list exists and add the new error
          fieldErrors[fieldKey] = [
            ...(fieldErrors[fieldKey] ?? []),
            fieldError,
          ];
        }
      });
    });
    return fieldErrors;
  }

  function validateField(
    field: Field,
    currentState: FormState<V>,
    errors: FormErrors,
  ): FormErrors {
    const fieldErrors: FormErrors = {};
    const fieldName = field.name;
    validators.forEach((validate) => {
      const fieldState = currentState[fieldName];
      if (!fieldState) return;
      const fieldError = validate(field, fieldState.value);
      if (fieldError) {
        // we check if the error list exists and add the new error
        fieldErrors[fieldName] = [
          ...(fieldErrors[fieldName] ?? []),
          fieldError,
        ];
      }
    });
    if (errors[fieldName] && !fieldErrors[fieldName]) {
      // we remove the old field error if the new validation is valid
      const { [fieldName]: _toRemove, ...restErrors } = errors;
      return {
        ...restErrors,
        ...fieldErrors,
      };
    }

    return {
      ...errors,
      ...fieldErrors,
    };
  }

  /**
   * setup the touched field logic. onFocus will set the field as touched and onBlur will validate the field
   */
  const setupTouchedField = (
    field: Field,
    {
      onBlur,
    }: {
      onBlur?: () => void;
    },
  ) => {
    return {
      onBlur: () => {
        onBlur?.();
        const updatedTouchedFields = touchedFields.add(field.name);
        setTouchedFields(updatedTouchedFields);
        setErrors((prevErrors) => validateField(field, state, prevErrors));
      },
    };
  };

  /**
   * We need to sort the fields based on the order property
   * For FieldGroups we need to sort the fields inside the group
   * That's why the return type is OrderedFields and we cast the sort return to any
   * Fields without sort goes to the end of the form
   */
  const orderedFields: OrderedFields = useMemo(
    () => orderFields(initialFields),
    [initialFields],
  );

  const renderFields = () => {
    return orderedFields.map((field) => {
      if (field.type === 'group') {
        const fieldGroup = field;
        return (
          <Layout
            key={fieldGroup.key}
            spacing={8}
            direction={fieldGroup.layout?.direction}
            align={fieldGroup.layout?.align}
          >
            {fieldGroup.fields.map((groupedField) => {
              return (
                <FormField
                  {...groupedField}
                  value={state[groupedField.name]?.value}
                  error={errors[groupedField.name]?.join(', ')}
                  onChange={(updatedValue) =>
                    onFieldValueChange(groupedField, updatedValue as any)
                  }
                  inputProps={{
                    // input props is an Object without typing, so we use any
                    ...(groupedField.inputProps as any),
                    ...setupTouchedField(groupedField, {
                      onBlur: (groupedField.inputProps as any)?.onBlur,
                    }),
                  }}
                />
              );
            })}
          </Layout>
        );
      } else if (field.type === 'field') {
        return (
          <FormField
            {...field}
            value={state[field.name]?.value}
            error={
              touchedFields.has(field.name)
                ? errors[field.name]?.join(', ')
                : ''
            } // TODO: validate if this is the best way to show multiple errors
            inputProps={{
              // input props is an Object without typing, so we use any
              ...(field.inputProps as any),
              ...setupTouchedField(field, {
                onBlur: (field.inputProps as any)?.onBlur,
              }),
            }}
            onChange={(updatedValue) =>
              onFieldValueChange(field, updatedValue as any)
            }
          />
        );
      }
      return null;
    });
  };

  return (
    <Layout preset="form-fields">
      {renderFields()}
      <Layout justify="end">
        {onSubmit && (
          <Button
            text="Submit"
            onClick={() =>
              onSubmit({
                state,
                errors,
              })
            }
          />
        )}
      </Layout>
    </Layout>
  );
}
