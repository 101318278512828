import React, { forwardRef, HTMLAttributes, ReactNode } from 'react';

import { useToggle } from '../../hooks';
import { theme } from '../../system/theme';
import { MenuActions } from '../../types';
import { ActionsMenu } from '../ActionsMenu';
import { Box } from '../Box';
import { IconButton } from '../IconButton';
import { Layout } from '../Layout';
import { Text } from '../Text';

export type ItemProps = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
  title: string;
  isDragging?: boolean;
  onRemove?: () => void;
  actions?: MenuActions<string>;
};

export const Item = forwardRef<HTMLDivElement, ItemProps>(
  ({ actions, children, isDragging, style, title, onRemove, ...rest }, ref) => {
    const [isHovering, _toggleIsHovering, hover, unhover] = useToggle();

    const hasChildren = Boolean(children);

    return (
      <Box
        style={{
          ...style,
          ...componentStyles.item({
            enableBorder: hasChildren,
            isDragging,
          }),
        }}
        onMouseEnter={hover}
        onMouseLeave={unhover}
      >
        <div ref={ref}>
          <Layout
            align="center"
            borderBottom={hasChildren ? 'border' : undefined}
            styles={componentStyles.dragTarget({ isDragging })}
          >
            <div {...rest} style={componentStyles.title}>
              <IconButton icon="reorder" size="s" />
              <Text
                flex="auto"
                textAlign={hasChildren ? 'center' : undefined}
                variant={hasChildren ? 'section' : undefined}
                role="heading"
              >
                {title}
              </Text>
            </div>
            {onRemove && (
              <Layout
                styles={componentStyles.remove({ isHovering })}
                onMouseEnter={hover}
              >
                {actions ? (
                  <ActionsMenu
                    enablePortal
                    actions={actions}
                    name="Item actions menu"
                    tooltip="More Actions"
                    icon="gear"
                  />
                ) : (
                  <IconButton icon="x" onClick={onRemove} />
                )}
              </Layout>
            )}
          </Layout>
          {children}
        </div>
      </Box>
    );
  },
);

type Draggable = {
  enableBorder?: boolean;
  isDragging?: boolean;
};

const componentStyles = {
  item: ({ enableBorder, isDragging }: Draggable) => ({
    border: enableBorder ? (theme.borders.border as string) : undefined,
    borderRadius: theme.radii.m as string,
    opacity: isDragging ? '0.5' : '1',
  }),
  dragTarget: ({ isDragging }: Draggable) => ({
    cursor: isDragging ? 'grabbing' : 'grab',
  }),
  remove: ({ isHovering }: { isHovering: boolean }) => ({
    opacity: isHovering ? 1 : 0,
    ':hover': {
      opacity: 1,
    },
  }),
  title: {
    alignItems: 'center',
    display: 'flex',
    height: theme.sizes['height.m'] as string,
    flex: 'auto',
  },
};
