export const heights = {
  'card.banner.height': 24,
  'container.min-height': 740,
  'divider.height.s': 28,
  'filter.height': 32,
  'height.s': 28,
  'height.m': 40,
  'height.l': 40,
  'menu.max-height': 480,
  'page-overlay.body.max-height': 1200,
  'progress.height': 8,
  'footer.height': 64,
  'footer.height.s': 48,
  'header.height': 48,
  'header.height.s': 36,
  'header.height.l': 64,
  'step.bar.height': 6,
  'step.separator.height': 2,
  'textarea.max-height': 320,
};
