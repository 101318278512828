import React from 'react';

import Datetime from '~/components/Shared/Datetime';
import FolderIcon from '~/components/Shared/Icons/FolderIcon';
import StatusText from '~/components/Shared/StatusText';
import { User } from '~/components/Shared/User';
import { TicketActivityType, TicketStatusType } from '~/enums';
import { FlexLayout, Icon, Link, Text } from '~/ui';
import { tokenizeContent } from '~/utils/comments';

import ActivityLogEventHeader from './ActivityLogEventHeader';
import ActivityLogEventIcon from './ActivityLogEventIcon';

const itemStatus = {
  [TicketStatusType.Approved]: 'success',
  [TicketStatusType.Rejected]: 'danger',
  [TicketStatusType.Done]: 'success',
};

const mentionRenderers = {
  user: (value) => (
    <Text variant="s-spaced-bold">
      <User id={Number(value)} mode="name" />
    </Text>
  ),
};

const statusIcon = (type) => {
  let icon;
  switch (type) {
    case TicketStatusType.Approved:
      icon = <Icon icon="checkmark" />;
      break;
    case TicketStatusType.Rejected:
      icon = <Icon icon="close" />;
      break;
    case TicketStatusType.Pending:
      icon = <Icon icon="reload" />;
      break;
    default:
      icon = null;
  }
  return icon;
};

function ActivityLogEvent({ event }) {
  const { action, createdDate, data, user } = event;

  let eventBody;
  switch (action) {
    case TicketActivityType.CreateTicket:
      eventBody = null;
      break;
    case TicketActivityType.DocumentUpload:
      eventBody = null;
      if (data.comment) {
        const tokens = tokenizeContent(data.comment || '', mentionRenderers);
        eventBody = (
          <StatusText status="muted" variant="xs-dense">
            {tokens.map((token, i) => (
              <React.Fragment key={i}>{token.content}</React.Fragment>
            ))}
          </StatusText>
        );
      }
      break;
    case TicketActivityType.StageChangeArchiveUser:
      eventBody = (
        <FlexLayout
          alignItems="center"
          alignSelf="flex-start"
          p={3}
          space={3}
          sx={{
            border: 'border',
            borderRadius: 'm',
            boxShadow: 'depth-2',
          }}
        >
          <FolderIcon yellow />
          <Link variant="xs-dense-bold">{data.destination.folder}</Link>
        </FlexLayout>
      );
      break;
    // TODO - adjust the content of `StatusText` to follow pattern: `review/finalize phase, review/finalize criteria`
    case TicketActivityType.JudgmentResultStatusUpdate:
      const { status } = data;
      const comment = data.comment ? (
        <Text color="gray-900" py="6px" variant="2xs-dense">
          {data.comment}
        </Text>
      ) : null;
      eventBody = (
        <FlexLayout space={3}>
          <StatusText status={itemStatus[status]}>
            <FlexLayout space={3} alignItems="center">
              <FlexLayout mr={2}>{statusIcon(status)}</FlexLayout>{' '}
              {data.phase.name} - {data.name}
            </FlexLayout>
            {comment}
          </StatusText>
        </FlexLayout>
      );
      break;
    case TicketActivityType.CancelTicket:
      eventBody = data.reason ? (
        <StatusText status="muted" variant="xs-dense">
          {data.reason}
        </StatusText>
      ) : null;
      break;
    default:
      eventBody = null;
  }

  return (
    <FlexLayout alignItems="flex-start" space={2}>
      <ActivityLogEventIcon action={action} data={data} />
      <FlexLayout flexDirection="column" space={2}>
        <FlexLayout flexDirection="column" space={1}>
          <ActivityLogEventHeader action={action} data={data} user={user} />
          <Text color="gray-600" variant="2xs-dense">
            <Datetime datetime={createdDate} format="duration" />
          </Text>
        </FlexLayout>
        {eventBody}
      </FlexLayout>
    </FlexLayout>
  );
}

export default ActivityLogEvent;
