import moment from 'moment';
import React from 'react';

import {
  customSettingsKeys,
  customSettingsOptionValues,
  timePeriodFieldOptions,
  valueKeys,
} from '~/constants/workflow';
import {
  FileExtensionType,
  WorkflowFieldDefaultValueInputType,
  WorkflowFieldType,
} from '~/enums';
import { FlexLayout } from '~/ui';

import AvailableOptions from './AvailableOptions';
import CustomSettingsInput from './CustomSettingsInput';
import DefaultValueInput from './DefaultValueInput';
import { Column, Row } from './SettingsLayout';

function FieldSettings({ customSettings, fieldType, onUpdate }) {
  const { defaultValue = {}, options = [] } = customSettings;
  const customSettingKey = customSettingsKeys[fieldType];
  const customSettingsOptionValue = customSettingsOptionValues[fieldType];
  const valueKey = valueKeys[fieldType];

  function customSettingsUpdateFactory(key) {
    return (updatedValue) => {
      let updatedSettings = {
        ...customSettings,
        [key]: updatedValue,
      };
      // should reset defaultValue when changing email_type
      if (
        fieldType === WorkflowFieldType.Email &&
        key === customSettingKey.EMAIL_TYPE
      ) {
        const shouldResetValue =
          updatedValue !== customSettingsOptionValue.EMAIL_TYPE.MANUAL;
        if (shouldResetValue) {
          updatedSettings = {
            ...updatedSettings,
            defaultValue: {
              value: null,
            },
          };
        }
      }
      // should reset defaultValue, min and max when changing operator
      if (
        fieldType === WorkflowFieldType.Percentage &&
        key === customSettingKey.OPERATOR
      ) {
        delete updatedSettings.max;
        delete updatedSettings.min;
        updatedSettings = {
          ...updatedSettings,
          defaultValue: {
            value: null,
          },
        };
      }
      // remove decimal if allowDecimals is changed to false.
      if (
        fieldType === WorkflowFieldType.Number &&
        key === customSettingKey.ALLOW_DECIMALS
      ) {
        if (!updatedValue && updatedSettings.defaultValue?.value) {
          updatedSettings = {
            ...updatedSettings,
            defaultValue: {
              value: Math.trunc(updatedSettings.defaultValue.value),
            },
          };
        }
      }

      onUpdate(updatedSettings);
    };
  }

  function defaultValueUpdateFactory(key = 'value') {
    return (updatedValue) => {
      const updatedSettings = {
        ...customSettings,
        defaultValue: {
          ...customSettings.defaultValue,
          [key]: updatedValue,
        },
      };
      onUpdate(updatedSettings);
    };
  }

  function handleUpdateOptions(updatedOptions) {
    const updatedSettings = {
      ...customSettings,
      options: updatedOptions,
    };
    onUpdate(updatedSettings);
  }

  switch (fieldType) {
    case WorkflowFieldType.Address: {
      const documentPlacementOptions = [
        {
          label: 'Single line',
          value: customSettingsOptionValue.DOCUMENT_PLACEMENT.SINGLE_LINE,
        },
        {
          label: 'Multiple lines',
          value: customSettingsOptionValue.DOCUMENT_PLACEMENT.MULTIPLE_LINES,
        },
      ];
      return (
        <Column space={6}>
          <Row>
            <DefaultValueInput
              defaultValue={defaultValue}
              id="address--country"
              inputType={WorkflowFieldDefaultValueInputType.CountrySingleSelect}
              label="Default Country"
              placeholder="Country"
              valueKey={valueKey.COUNTRY}
              width="input.l.width"
              defaultValueUpdateFactory={defaultValueUpdateFactory}
            />
            <CustomSettingsInput
              customSettings={customSettings}
              id="address--documentPlacement"
              inputType="SINGLE_SELECT"
              label="Document Placement"
              options={documentPlacementOptions}
              settingsKey={customSettingKey.DOCUMENT_PLACEMENT}
              width="fullWidth"
              customSettingsUpdateFactory={customSettingsUpdateFactory}
            />
          </Row>
          <DefaultValueInput
            defaultValue={defaultValue}
            id="address--street1"
            inputType={WorkflowFieldDefaultValueInputType.TextInput}
            label="Default Address"
            placeholder="Address line 1"
            valueKey={valueKey.STREET_1}
            width="l"
            defaultValueUpdateFactory={defaultValueUpdateFactory}
          />
          <DefaultValueInput
            defaultValue={defaultValue}
            id="address--street2"
            inputType={WorkflowFieldDefaultValueInputType.TextInput}
            label=""
            placeholder="Address line 2"
            valueKey={valueKey.STREET_2}
            width="l"
            defaultValueUpdateFactory={defaultValueUpdateFactory}
          />
          <Row>
            <DefaultValueInput
              defaultValue={defaultValue}
              id="address--city"
              inputType={WorkflowFieldDefaultValueInputType.TextInput}
              label="Default Town / City"
              placeholder="City"
              valueKey={valueKey.CITY}
              width="m"
              defaultValueUpdateFactory={defaultValueUpdateFactory}
            />
            <DefaultValueInput
              defaultValue={defaultValue}
              id="address--state"
              inputType={WorkflowFieldDefaultValueInputType.StateSingleSelect}
              label="Default State"
              placeholder="State"
              valueKey={valueKey.STATE}
              width="input.m.width"
              defaultValueUpdateFactory={defaultValueUpdateFactory}
            />
          </Row>
          <DefaultValueInput
            defaultValue={defaultValue}
            id="address--zipCode"
            inputType={WorkflowFieldDefaultValueInputType.TextInput}
            label="Default Zip"
            valueKey={valueKey.ZIP_CODE}
            width="m"
            defaultValueUpdateFactory={defaultValueUpdateFactory}
          />
        </Column>
      );
    }
    case WorkflowFieldType.Checkbox: {
      return (
        <Column>
          <Row>
            <AvailableOptions
              id="checkbox--options"
              options={options}
              values={options.map((option) => option.value)}
              width="l"
              onChange={handleUpdateOptions}
            />
            <DefaultValueInput
              defaultValue={defaultValue}
              disabled={!options.length}
              id="checkbox--VALUE"
              inputType={WorkflowFieldDefaultValueInputType.MultiSelect}
              isRequired={false}
              label="Checked by default"
              options={options}
              valueKey={valueKey.VALUE}
              width="100%"
              defaultValueUpdateFactory={defaultValueUpdateFactory}
            />
          </Row>
          <CustomSettingsInput
            customSettings={customSettings}
            description="User will have to choose from the options listed above"
            id="checkbox--ENABLE_OTHER_OPTION"
            inputType="CHECKBOX"
            label="Enable ”Other” option"
            settingsKey={customSettingKey.ENABLE_OTHER_OPTION}
            customSettingsUpdateFactory={customSettingsUpdateFactory}
          />
          <CustomSettingsInput
            customSettings={customSettings}
            description="For reports and searching purposes, list the entry only as “Other”"
            disabled={!customSettings[customSettingKey.ENABLE_OTHER_OPTION]}
            id="checkbox--EXCLUDE_OTHER_OPTION_IN_DATA_MAPPING"
            inputType="CHECKBOX"
            label="Remove entered “Other” value in the data mapping"
            settingsKey={customSettingKey.EXCLUDE_OTHER_OPTION_IN_DATA_MAPPING}
            customSettingsUpdateFactory={customSettingsUpdateFactory}
          />
        </Column>
      );
    }
    case WorkflowFieldType.Date: {
      const formatOptions = [
        {
          label: moment().format(customSettingsOptionValue.FORMAT.Default),
          value: customSettingsOptionValue.FORMAT.Default,
        },
        {
          label: moment().format(customSettingsOptionValue.FORMAT.EuShort),
          value: customSettingsOptionValue.FORMAT.EuShort,
        },
        {
          label: moment().format(customSettingsOptionValue.FORMAT.UsShort),
          value: customSettingsOptionValue.FORMAT.UsShort,
        },
        {
          label: moment().format(customSettingsOptionValue.FORMAT.Iso),
          value: customSettingsOptionValue.FORMAT.Iso,
        },
        {
          label: moment().format(customSettingsOptionValue.FORMAT.Long),
          value: customSettingsOptionValue.FORMAT.Long,
        },
        {
          label: moment().format(customSettingsOptionValue.FORMAT.Prose),
          value: customSettingsOptionValue.FORMAT.Prose,
        },
      ];
      return (
        <CustomSettingsInput
          customSettings={customSettings}
          id="date--FORMAT"
          inputType="SINGLE_SELECT"
          label="Date Format"
          options={formatOptions}
          settingsKey={customSettingKey.FORMAT}
          width="l"
          customSettingsUpdateFactory={customSettingsUpdateFactory}
        />
      );
    }
    case WorkflowFieldType.Department: {
      const ruleOptions = [
        {
          label: 'All departments',
          value: customSettingsOptionValue.RULE.ALL,
        },
        {
          label: 'All departments, except',
          value: customSettingsOptionValue.RULE.ALL_EXCEPT,
        },
        {
          label: 'Specific departments',
          value: customSettingsOptionValue.RULE.SPECIFIC,
        },
      ];
      return (
        <Column>
          <CustomSettingsInput
            customSettings={customSettings}
            id="department--RULE"
            inputType="SINGLE_SELECT"
            label="Users will be able to choose from"
            options={ruleOptions}
            settingsKey={customSettingKey.RULE}
            width="l"
            customSettingsUpdateFactory={customSettingsUpdateFactory}
          />
          {[
            customSettingsOptionValue.RULE.ALL_EXCEPT,
            customSettingsOptionValue.RULE.SPECIFIC,
          ].includes(customSettings[customSettingKey.RULE]) && (
            <CustomSettingsInput
              customSettings={customSettings}
              id="department--DEPARTMENTS"
              inputType="DEPARTMENT_MULTI_SELECT"
              placeholder="Select"
              settingsKey={customSettingKey.DEPARTMENTS}
              width="l"
              customSettingsUpdateFactory={customSettingsUpdateFactory}
            />
          )}
          <CustomSettingsInput
            customSettings={customSettings}
            id="department--ALLOW_MULTIPLE"
            inputType="CHECKBOX"
            label="Allow users to select multiple departments"
            settingsKey={customSettingKey.ALLOW_MULTIPLE}
            customSettingsUpdateFactory={customSettingsUpdateFactory}
          />
        </Column>
      );
    }
    case WorkflowFieldType.Email: {
      const emailTypeOptions = [
        {
          label: 'None',
          value: customSettingsOptionValue.EMAIL_TYPE.NONE,
        },
        {
          label: 'Manually enter a default email',
          value: customSettingsOptionValue.EMAIL_TYPE.MANUAL,
        },
        {
          label: 'Submitter’s email',
          value: customSettingsOptionValue.EMAIL_TYPE.SUBMITTER,
        },
      ];
      return (
        <Row>
          <CustomSettingsInput
            customSettings={customSettings}
            id="email--EMAIL_TYPE"
            inputType="SINGLE_SELECT"
            isRequired={false}
            label="Default Value"
            options={emailTypeOptions}
            settingsKey={customSettingKey.EMAIL_TYPE}
            width="l"
            customSettingsUpdateFactory={customSettingsUpdateFactory}
          />
          {customSettingsOptionValue.EMAIL_TYPE.MANUAL ===
            customSettings[customSettingKey.EMAIL_TYPE] && (
            <DefaultValueInput
              defaultValue={defaultValue}
              id="email--VALUE"
              inputType={WorkflowFieldDefaultValueInputType.Email}
              label={null}
              placeholder="Enter email"
              valueKey={valueKey.VALUE}
              width="fullWidth"
              defaultValueUpdateFactory={defaultValueUpdateFactory}
            />
          )}
        </Row>
      );
    }
    case WorkflowFieldType.File: {
      const fileLimitOptions = [
        {
          label: 'One attachment',
          value: customSettingsOptionValue.FILE_LIMIT.SINGLE,
        },
        {
          label: 'Multiple attachments',
          value: customSettingsOptionValue.FILE_LIMIT.MULTIPLE,
        },
        {
          label: 'Unlimited attachments',
          value: customSettingsOptionValue.FILE_LIMIT.UNLIMITED,
        },
      ];
      const fileTypeRuleOptions = [
        {
          label: 'Any',
          value: customSettingsOptionValue.FILE_TYPE_RULE.ANY,
        },
        {
          label: 'Specific file type',
          value: customSettingsOptionValue.FILE_TYPE_RULE.SPECIFIC,
        },
        {
          label: 'Any, except',
          value: customSettingsOptionValue.FILE_TYPE_RULE.ANY_EXCEPT,
        },
      ];
      const fileTypeOptions = Object.values(FileExtensionType).map(
        (fileExtension) => ({
          label: fileExtension.substring(1).toUpperCase(),
          value: fileExtension,
        }),
      );

      return (
        <Column space={6}>
          <Row>
            <CustomSettingsInput
              customSettings={customSettings}
              id="file--FILE_LIMIT"
              inputType="SINGLE_SELECT"
              label="Allow users to attach"
              options={fileLimitOptions}
              settingsKey={customSettingKey.FILE_LIMIT}
              width="l"
              customSettingsUpdateFactory={customSettingsUpdateFactory}
            />
            {customSettings[customSettingKey.FILE_LIMIT] ===
              customSettingsOptionValue.FILE_LIMIT.MULTIPLE && (
              <CustomSettingsInput
                customSettings={customSettings}
                id="file--MIN_FILE_COUNT"
                inputType="NUMBER_INPUT"
                label="User must attach at least"
                settingsKey={customSettingKey.MIN_FILE_COUNT}
                width="fullWidth"
                customSettingsUpdateFactory={customSettingsUpdateFactory}
              />
            )}
          </Row>
          <Column space={2}>
            <CustomSettingsInput
              customSettings={customSettings}
              id="file--ALLOWED_FILE_TYPE_RULE"
              inputType="SINGLE_SELECT"
              label="Allowed file types"
              options={fileTypeRuleOptions}
              settingsKey={customSettingKey.ALLOWED_FILE_TYPE_RULE}
              width="l"
              customSettingsUpdateFactory={customSettingsUpdateFactory}
            />
            {[
              customSettingsOptionValue.FILE_TYPE_RULE.SPECIFIC,
              customSettingsOptionValue.FILE_TYPE_RULE.ANY_EXCEPT,
            ].includes(
              customSettings[customSettingKey.ALLOWED_FILE_TYPE_RULE],
            ) && (
              <CustomSettingsInput
                customSettings={customSettings}
                id="file--ALLOWED_FILE_TYPES"
                inputType="MULTI_SELECT"
                options={fileTypeOptions}
                settingsKey={customSettingKey.ALLOWED_FILE_TYPES}
                width="l"
                customSettingsUpdateFactory={customSettingsUpdateFactory}
              />
            )}
          </Column>
        </Column>
      );
    }
    case WorkflowFieldType.ShortResponse:
    case WorkflowFieldType.LongResponse: {
      return (
        <DefaultValueInput
          defaultValue={defaultValue}
          id="response--VALUE"
          isRequired={false}
          valueKey={valueKey.VALUE}
          width="l"
          defaultValueUpdateFactory={defaultValueUpdateFactory}
        />
      );
    }
    case WorkflowFieldType.MultiSelect: {
      return (
        <Column>
          <Row>
            <AvailableOptions
              id="multiSelect--options"
              options={options}
              values={options.map((option) => option.value)}
              width="l"
              onChange={handleUpdateOptions}
            />
            <DefaultValueInput
              defaultValue={defaultValue}
              disabled={!options.length}
              id="multiSelect--VALUE"
              inputType={WorkflowFieldDefaultValueInputType.MultiSelect}
              isRequired={false}
              options={options}
              valueKey={valueKey.VALUE}
              width="100%"
              defaultValueUpdateFactory={defaultValueUpdateFactory}
            />
          </Row>
          <CustomSettingsInput
            customSettings={customSettings}
            id="multiSelect--ENABLE_CREATE_OPTIONS"
            inputType="CHECKBOX"
            label="Users can add their own options"
            settingsKey={customSettingKey.ENABLE_CREATE_OPTIONS}
            customSettingsUpdateFactory={customSettingsUpdateFactory}
          />
        </Column>
      );
    }
    case WorkflowFieldType.MonetaryValue: {
      const currencyRuleOptions = [
        {
          label: 'Any currency',
          value: customSettingsOptionValue.CURRENCY_RULE.ANY,
        },
        {
          label: 'Any currency, except',
          value: customSettingsOptionValue.CURRENCY_RULE.ANY_EXCEPT,
        },
        {
          label: 'Specific currency only',
          value: customSettingsOptionValue.CURRENCY_RULE.SPECIFIC,
        },
      ];

      return (
        <Column>
          <Row>
            <CustomSettingsInput
              customSettings={customSettings}
              id="monetary--CURRENCY_RULE"
              inputType="SINGLE_SELECT"
              label="Allow users to choose"
              options={currencyRuleOptions}
              settingsKey={customSettingKey.CURRENCY_RULE}
              customSettingsUpdateFactory={customSettingsUpdateFactory}
            />
            <DefaultValueInput
              defaultValue={defaultValue}
              id="monetary--CURRENCY"
              inputType={
                WorkflowFieldDefaultValueInputType.CurrencySingleSelect
              }
              label="Default Currency"
              placeholder="Select"
              valueKey={valueKey.CURRENCY}
              defaultValueUpdateFactory={defaultValueUpdateFactory}
            />
            <DefaultValueInput
              defaultValue={defaultValue}
              id="monetary--AMOUNT"
              inputType={WorkflowFieldDefaultValueInputType.NumberInput}
              isRequired={false}
              valueKey={valueKey.AMOUNT}
              defaultValueUpdateFactory={defaultValueUpdateFactory}
            />
          </Row>
          {[
            customSettingsOptionValue.CURRENCY_RULE.ANY_EXCEPT,
            customSettingsOptionValue.CURRENCY_RULE.SPECIFIC,
          ].includes(customSettings[customSettingKey.CURRENCY_RULE]) && (
            <CustomSettingsInput
              customSettings={customSettings}
              id="monetary--CURRENCY_LIST"
              inputType="CURRENCY_MULTI_SELECT"
              settingsKey={customSettingKey.CURRENCY_LIST}
              width="input.l.width"
              customSettingsUpdateFactory={customSettingsUpdateFactory}
            />
          )}
          <CustomSettingsInput
            customSettings={customSettings}
            id="monetary--INCLUDE_SYMBOL_IN_DOCUMENT"
            inputType="CHECKBOX"
            label="Include currency symbol when inserting into document"
            settingsKey={customSettingKey.INCLUDE_SYMBOL_IN_DOCUMENT}
            customSettingsUpdateFactory={customSettingsUpdateFactory}
          />
        </Column>
      );
    }
    case WorkflowFieldType.Number: {
      return (
        <Column>
          <DefaultValueInput
            defaultValue={defaultValue}
            id="number--VALUE"
            inputType={WorkflowFieldDefaultValueInputType.NumberInput}
            isRequired={false}
            valueKey={valueKey.VALUE}
            width="l"
            defaultValueUpdateFactory={defaultValueUpdateFactory}
            customSettings={customSettings}
          />
          <CustomSettingsInput
            customSettings={customSettings}
            id="number--allowDecimals"
            inputType="CHECKBOX"
            label="Allow decimals"
            settingsKey={customSettingKey.ALLOW_DECIMALS}
            customSettingsUpdateFactory={customSettingsUpdateFactory}
          />
        </Column>
      );
    }
    case WorkflowFieldType.Percentage: {
      const operatorOptions = [
        {
          label: 'Greater than',
          value: customSettingsOptionValue.OPERATOR.GREATER,
        },
        {
          label: 'Greater than or equal to',
          value: customSettingsOptionValue.OPERATOR.GREATER_OR_EQUAL,
        },
        {
          label: 'Less than',
          value: customSettingsOptionValue.OPERATOR.LESS,
        },
        {
          label: 'Less than or equal to',
          value: customSettingsOptionValue.OPERATOR.LESS_OR_EQUAL,
        },
        {
          label: 'Between',
          value: customSettingsOptionValue.OPERATOR.BETWEEN,
        },
      ];
      let fields;
      if (
        [
          customSettingsOptionValue.OPERATOR.GREATER,
          customSettingsOptionValue.OPERATOR.GREATER_OR_EQUAL,
        ].includes(customSettings.operator)
      ) {
        fields = (
          <CustomSettingsInput
            customSettings={customSettings}
            id="percentage--MIN"
            inputType="NUMBER_INPUT"
            placeholder="Value"
            settingsKey={customSettingKey.MIN}
            customSettingsUpdateFactory={customSettingsUpdateFactory}
          />
        );
      } else if (
        [
          customSettingsOptionValue.OPERATOR.LESS,
          customSettingsOptionValue.OPERATOR.LESS_OR_EQUAL,
        ].includes(customSettings.operator)
      ) {
        fields = (
          <CustomSettingsInput
            customSettings={customSettings}
            id="percentage--MAX"
            inputType="NUMBER_INPUT"
            placeholder="Value"
            settingsKey={customSettingKey.MAX}
            customSettingsUpdateFactory={customSettingsUpdateFactory}
          />
        );
      } else if (
        customSettings.operator === customSettingsOptionValue.OPERATOR.BETWEEN
      ) {
        fields = (
          <>
            <CustomSettingsInput
              customSettings={customSettings}
              id="percentage--MIN"
              inputType="NUMBER_INPUT"
              placeholder="Min value"
              settingsKey={customSettingKey.MIN}
              customSettingsUpdateFactory={customSettingsUpdateFactory}
            />
            <CustomSettingsInput
              customSettings={customSettings}
              id="percentage--MAX"
              inputType="NUMBER_INPUT"
              placeholder="Max value"
              settingsKey={customSettingKey.MAX}
              customSettingsUpdateFactory={customSettingsUpdateFactory}
            />
          </>
        );
      }

      return (
        <Column>
          <FlexLayout alignItems="flex-end" space={2}>
            <CustomSettingsInput
              customSettings={customSettings}
              id="percentage--operator"
              inputType="SINGLE_SELECT"
              label="Amount should be"
              options={operatorOptions}
              settingsKey={customSettingKey.OPERATOR}
              customSettingsUpdateFactory={customSettingsUpdateFactory}
            />
            {fields}
            <DefaultValueInput
              defaultValue={defaultValue}
              id="percentage--DEFAULT"
              inputType={WorkflowFieldDefaultValueInputType.NumberInput}
              isRequired={false}
              valueKey={valueKey.VALUE}
              defaultValueUpdateFactory={defaultValueUpdateFactory}
            />
          </FlexLayout>
          <CustomSettingsInput
            customSettings={customSettings}
            id="percentage--INCLUDE_SYMBOL_IN_DOCUMENT"
            inputType="CHECKBOX"
            label="Include percentage symbol when inserting into document"
            settingsKey={customSettingKey.INCLUDE_SYMBOL_IN_DOCUMENT}
            customSettingsUpdateFactory={customSettingsUpdateFactory}
          />
        </Column>
      );
    }
    case WorkflowFieldType.Radio: {
      return (
        <Column>
          <Row>
            <AvailableOptions
              id="radio--options"
              options={options}
              values={options.map((option) => option.value)}
              width="l"
              onChange={handleUpdateOptions}
            />
            <DefaultValueInput
              defaultValue={defaultValue}
              disabled={!options.length}
              id="radio--value"
              inputType={WorkflowFieldDefaultValueInputType.SingleSelect}
              isRequired={false}
              options={options}
              valueKey={valueKey.VALUE}
              width="100%"
              defaultValueUpdateFactory={defaultValueUpdateFactory}
            />
          </Row>
          <CustomSettingsInput
            customSettings={customSettings}
            description="User will have to choose from the options listed above"
            id="radio--ENABLE_OTHER_OPTION"
            inputType="CHECKBOX"
            label="Enable ”Other” option"
            settingsKey={customSettingKey.ENABLE_OTHER_OPTION}
            customSettingsUpdateFactory={customSettingsUpdateFactory}
          />
          <CustomSettingsInput
            customSettings={customSettings}
            description="For reports and searching purposes, list the entry only as “Other”"
            disabled={!customSettings[customSettingKey.ENABLE_OTHER_OPTION]}
            id="radio--EXCLUDE_OTHER_OPTION_IN_DATA_MAPPING"
            inputType="CHECKBOX"
            label="Remove entered “Other” value in the data mapping"
            settingsKey={customSettingKey.EXCLUDE_OTHER_OPTION_IN_DATA_MAPPING}
            customSettingsUpdateFactory={customSettingsUpdateFactory}
          />
        </Column>
      );
    }
    case WorkflowFieldType.SingleSelect: {
      return (
        <Column>
          <Row>
            <AvailableOptions
              id="singleSelect--options"
              options={options}
              values={options.map((option) => option.value)}
              width="l"
              onChange={handleUpdateOptions}
            />
            <DefaultValueInput
              disabled={!options.length}
              id="singleSelect--VALUE"
              inputType={WorkflowFieldDefaultValueInputType.SingleSelect}
              options={options}
              defaultValue={defaultValue}
              valueKey={valueKey.VALUE}
              width="100%"
              defaultValueUpdateFactory={defaultValueUpdateFactory}
            />
          </Row>
          <CustomSettingsInput
            customSettings={customSettings}
            id="singleSelect--ENABLE_CREATE_OPTIONS"
            inputType="CHECKBOX"
            label="Users can add their own options"
            settingsKey={customSettingKey.ENABLE_CREATE_OPTIONS}
            customSettingsUpdateFactory={customSettingsUpdateFactory}
          />
        </Column>
      );
    }
    case WorkflowFieldType.TimePeriod: {
      return (
        <Column>
          <AvailableOptions
            hasPresets
            id="timePeriod--options"
            options={timePeriodFieldOptions}
            values={options.map((option) => option.value)}
            width="l"
            onChange={handleUpdateOptions}
          />
          <Row>
            <DefaultValueInput
              defaultValue={defaultValue}
              disabled={!options.length}
              id="timePeriod--PERIOD"
              inputType={WorkflowFieldDefaultValueInputType.SingleSelect}
              isRequired={false}
              label="Default"
              options={options}
              placeholder="Time unit"
              valueKey={valueKey.PERIOD}
              width="input.m.width"
              defaultValueUpdateFactory={defaultValueUpdateFactory}
            />
            <DefaultValueInput
              defaultValue={defaultValue}
              disabled={!options.length}
              id="timePeriod--AMOUNT"
              inputType={WorkflowFieldDefaultValueInputType.NumberInput}
              placeholder="Value"
              valueKey={valueKey.AMOUNT}
              width="m"
              defaultValueUpdateFactory={defaultValueUpdateFactory}
            />
          </Row>
        </Column>
      );
    }
    case WorkflowFieldType.User: {
      const ruleOptions = [
        {
          label: 'All users',
          value: customSettingsOptionValue.RULE.ALL,
        },
        {
          label: 'All users, except',
          value: customSettingsOptionValue.RULE.ALL_EXCEPT,
        },
        {
          label: 'Specific users',
          value: customSettingsOptionValue.RULE.SPECIFIC,
        },
      ];
      return (
        <Column>
          <CustomSettingsInput
            customSettings={customSettings}
            id="user--RULE"
            inputType="SINGLE_SELECT"
            label="Available Users"
            options={ruleOptions}
            settingsKey={customSettingKey.RULE}
            width="l"
            customSettingsUpdateFactory={customSettingsUpdateFactory}
          />
          {[
            customSettingsOptionValue.RULE.ALL_EXCEPT,
            customSettingsOptionValue.RULE.SPECIFIC,
          ].includes(customSettings[customSettingKey.RULE]) && (
            <CustomSettingsInput
              customSettings={customSettings}
              id="user--USERS"
              inputType="USER_MULTI_SELECT"
              settingsKey={customSettingKey.USERS}
              width="l"
              customSettingsUpdateFactory={customSettingsUpdateFactory}
            />
          )}
          <CustomSettingsInput
            customSettings={customSettings}
            id="user--ALLOW_MULTIPLE"
            inputType="CHECKBOX"
            label="Allow multiple"
            settingsKey={customSettingKey.ALLOW_MULTIPLE}
            customSettingsUpdateFactory={customSettingsUpdateFactory}
          />
        </Column>
      );
    }
    case WorkflowFieldType.Boolean:
    default: {
      return null;
    }
  }
}

export default FieldSettings;
