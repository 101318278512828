import xlsx from 'xlsx';

import { FileExtensionType, FileMimeType } from '~/enums';

/**
 * Downloads a JS File
 * @param {Blob} file a JS Blob object
 * @param {string} fileName name of file
 */
export function downloadFile(file, fileName = 'download') {
  const url = URL.createObjectURL(file);

  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };

  const a = document.createElement('a');
  document.body.append(a);
  a.href = url;
  a.download = file.name || fileName;
  a.addEventListener('click', clickHandler, false);
  a.click();
  a.remove();
}

/**
 * Returns the file name without its extension
 * @param {string} fileName name of file
 * @returns {string} file name without extension
 */
export function getBasename(fileName) {
  return fileName.replace(/\.[^/.]+$/, '');
}

/**
 * Returns a JS File/Blob object from a provided data URL
 * @param {string} dataUrl standard web data URL
 * @returns {Blob} a JS Blob object
 */
export function dataUrlToBlob(dataUrl) {
  const [scheme, data] = dataUrl.split(',');
  const mimeType = scheme.match(/:(.*?);/)[1];
  const bstr = atob(data);

  let n = bstr.length;
  const bytes = new Uint8Array(n);
  while (n--) {
    bytes[n] = bstr.charCodeAt(n);
  }

  return new Blob([bytes], { type: mimeType });
}

/**
 * Returns a JS File/Blob object from a bytes stream
 * @param {bytes} bytes standard a bytes streaming
 * @param {string} mimeType standard a enum of file type
 * @returns {Blob} a JS Blob object
 */
export function bytesToBlob(bytes, mimeType) {
  return new Blob([bytes], { type: mimeType });
}

/**
 * Returns file's extension
 * @param {string} file name
 * @returns {string} file type
 */
export function getFileExtension(fileName) {
  return `.${fileName.split(/[. ]+/).pop()}`;
}

/**
 * Return a valid and trimmed file type from potentially invalid file type
 * @param {any} fileType
 * @returns {string} trimmed fileType
 */
export function coerceFileType(fileType) {
  if (typeof fileType !== 'string') {
    return '';
  }
  return fileType.charAt(0) === '.' ? fileType.slice(1) : fileType;
}

/** Attaches a file by creating a DOM input and removing it for the process
 * @param {function} onChangeHandler handle the file attachment event.
 * @param {string} accept formats to accept
 */
export function attachFile(onChangeHandler, accept = '.docx') {
  const input = document.createElement('input');
  input.type = 'file';
  input.accept = accept;

  input.onchange = () => onChangeHandler(input.files[0]);
  input.click();
  input.remove();
}

/**
 * Returns a valid file Icon key given a File object.
 * Infers the file icon from the file extension or mimetype.
 * @param {File} file
 *    A Javascript File
 * @returns {string} a valid file Icon key
 */
export const getFileIcon = (file) => {
  const { name = 'file', type } = file;
  const extension = getFileExtension(name).toLowerCase();

  switch (extension) {
    case FileExtensionType.Doc:
    case FileExtensionType.Docx:
      return 'coloredDocumentDoc';
    case FileExtensionType.Pdf:
      return 'coloredDocumentPdf';
    case FileExtensionType.Jpeg:
    case FileExtensionType.Jpg:
    case FileExtensionType.Png:
    case FileExtensionType.Tiff:
    case FileExtensionType.Webp:
      return 'coloredDocumentImage';
    case FileExtensionType.Xlsx:
    case FileExtensionType.Csv:
      return 'coloredDocumentExcel';
    case FileExtensionType.Ppt:
    case FileExtensionType.Pptm:
    case FileExtensionType.Pptx:
      return 'coloredDocumentSlideshow';
    default:
      break;
  }

  switch (type) {
    case FileMimeType.Doc:
    case FileMimeType.Docx:
    case FileMimeType.DocUnofficial:
    case FileMimeType.DocxUnofficial:
      return 'coloredDocumentDoc';
    case FileMimeType.Pdf:
      return 'coloredDocumentPdf';
    case FileMimeType.Csv:
    case FileMimeType.Xlsx:
      return 'coloredDocumentExcel';
    case FileMimeType.Pptx:
      return 'coloredDocumentSlideshow';
    default:
      if (typeof type === 'string' && type.includes('image/')) {
        return 'coloredDocumentImage';
      }
      break;
  }

  return 'coloredDocumentOutline';
};

/**
 * Returns a data URL given a Blob or File.
 * @param {Blob|File} blob
 *    A Javascript Blob or File.
 * @returns {Promise<string>}
 *    The data URL.
 */
export const blobToDataUrl = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (event) => reject(event);
    reader.readAsDataURL(blob);
  });

// https://github.com/SheetJS/sheetjs/issues/817#issuecomment-331605640
export const exportXlsx = ({ data, name }) => {
  const ws = xlsx.utils.json_to_sheet(data);
  const wb = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws);
  const filename = `${name || 'download'}.xlsx`;
  xlsx.writeFile(wb, filename);
};
