import React, { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Accordion,
  CollapsibleLinks,
  formatNumber,
  Layout,
  Progress,
  Text,
  types,
} from '~/eds';
import { actions, selectors } from '~/redux';

import { FieldModel } from '../../fields/types';
import { testIsTestCaseEvaluating } from '../../fields/utils';

interface OutcomeConfigs {
  [outcome: string /* i.e. TestCaseOutcome */]: {
    label: string;
    status: types.StatusType;
  };
}

interface Stat {
  label: string;
  status: types.StatusType;
  testCases: FieldModel['testCases'];
}

interface Props {
  testCases: FieldModel['testCases'];
  onUpdateTestCaseNumber: (testCaseNumber: number) => void;
  readOnly?: boolean;
}

export const ReviewSummary = memo(
  ({ testCases, readOnly, onUpdateTestCaseNumber }: Props) => {
    const dispatch = useDispatch();

    const activeModel = useSelector(selectors.selectFieldAiActiveModel);

    const isTestCasesEvaluating = testCases.some(testIsTestCaseEvaluating);

    const hasPartiallyCorrectOutcomes =
      activeModel?.config.fieldClassification?.isMulti;

    const stats: Stat[] = useMemo(() => {
      const outcomeConfigs: OutcomeConfigs = {
        correct: {
          label: 'Correct',
          status: 'success',
        },
        ...(hasPartiallyCorrectOutcomes
          ? {
              partially_correct: {
                label: 'Partially Correct',
                status: 'warning',
              },
            }
          : {}),
        incorrect: {
          label: 'Incorrect',
          status: 'danger',
        },
        skipped: {
          label: 'Skipped',
          status: 'inactive',
        },
      };
      return Object.entries(outcomeConfigs).map(([outcome, config]) => ({
        ...config,
        testCases: testCases.filter((testCase) => testCase.outcome === outcome),
      }));
    }, [testCases]);

    const reviewedTestCaseCount = testCases.filter(
      ({ outcome }) =>
        outcome === 'correct' ||
        outcome === 'incorrect' ||
        outcome === 'partially_correct',
    ).length;

    const summaryContent = (stat: Stat, pt?: number) => (
      <Layout
        align="center"
        justify="space-between"
        spacing={2}
        w="100%"
        pt={pt}
      >
        <Text variant="body-bold" w="60px">
          {stat.label}
        </Text>
        <Progress
          isLoading={isTestCasesEvaluating}
          max={reviewedTestCaseCount}
          name={`review test cases (${stat.status})`}
          status={stat.status}
          statsLabel={{
            formatLabel: (value, max) =>
              stat.status === 'inactive'
                ? formatNumber(value)
                : `${formatNumber(value / max || 0, {
                    decimal: 1,
                    style: 'percent',
                  })} (${formatNumber(value)} of ${formatNumber(max)})`,
            textAlign: 'right',
            position: 'left',
            width: '120px',
          }}
          tooltip={isTestCasesEvaluating ? 'Evaluating results…' : undefined}
          value={stat.testCases.length}
        />
      </Layout>
    );

    const summaries = stats.map((stat) => summaryContent(stat, 2));

    const items = stats.map((stat) => ({
      content: (
        <Layout wrap spacing={1}>
          <Text flex="none" variant="body-medium">
            Document No.:
          </Text>
          <CollapsibleLinks
            limit={20}
            links={stat.testCases.map((testCase) => ({
              text: String(testCase.number),
              tooltip: 'View document',
              onClick: () => {
                const documentVersionId = Number(testCase.entity.id);
                onUpdateTestCaseNumber(testCase.number);
                dispatch(
                  actions.setFieldAiDocumentViewer({
                    documentVersionId,
                  }),
                );
              },
            }))}
          />
        </Layout>
      ),
      title: stat.label,
      summary: summaryContent(stat),
    }));

    return (
      <>
        {readOnly ? (
          <>{summaries}</>
        ) : (
          <Accordion isIndented border="none" items={items} />
        )}
      </>
    );
  },
);
