import { useCallback, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import { ticketUpdate } from '~/actions';
import { checkEnvelope, checkEsignatureIntegration } from '~/api';
import { Button, Tooltip, useToast } from '~/eds';
import { api } from '~/redux';

const CheckSignatureStatus = ({ ticketId }: { ticketId: string }) => {
  const { toast } = useToast();
  const dispatch = useDispatch();

  const [
    checkSignature,
    checkSignatureResult,
  ] = api.endpoints.updateEnvelope.useMutation();

  const {
    isLoading: isLoadingCheckSignature,
    error: checkSignatureError,
  } = checkSignatureResult;

  const updateTicket = useCallback(async () => {
    const { data: hasEnvelope } = await checkEnvelope(ticketId);
    const hasEsignatureIntegration = await checkEsignatureIntegration();

    dispatch(ticketUpdate({ hasEnvelope, hasEsignatureIntegration }));
  }, [ticketId]);

  useEffect(() => {
    if (checkSignatureError) {
      toast({
        autoClose: false,
        message:
          checkSignatureError?.response?.data?.[0]?.detail ||
          checkSignatureError?.response?.data,
        status: 'warning',
      });
    }
    updateTicket();
  }, [checkSignatureResult]);

  return (
    <Tooltip tooltip="Check for E-Signature status updates">
      <Button
        text="Check Signature Status"
        variant="primary"
        onClick={() => checkSignature({ ticketId })}
        isLoading={isLoadingCheckSignature}
      />
    </Tooltip>
  );
};

const mapStateToProps = ({ ticket }: { ticket: any }) => {
  return {
    ticketId: ticket.id,
  };
};

export default connect(mapStateToProps)(CheckSignatureStatus);
