import { saveAs } from 'file-saver';
import { isEqual } from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';
import React, { Component, createRef, Fragment } from 'react';
import DropzoneComponent from 'react-dropzone-component';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';

import { handle403 } from '~/api/errorHandlers';
import {
  AddToGroup,
  DocumentsPageHeader,
  DocumentsTable,
  styles,
} from '~/components/DocumentsPage';
import { DocumentsTableV2 } from '~/components/DocumentsPage/DocumentsTable/DocumentsTableV2';
import {
  bulkCopy,
  bulkDelete,
  bulkMove,
  bulkUpdate,
  createFolder,
  editDocumentsViewsetFields,
  getDocumentOCR,
  getFolderData,
  getGroupsForDocuments,
  getMultiEditData,
  getUserCanModifyFolder,
  renameDocument,
  renameFolder,
  updateFolderAccessSettings,
} from '~/components/DocumentsViewPage/Document.data';
import DeleteDocumentWithReasonModal from '~/components/Modals/DeleteDocumentWithReasonModal';
import { trackSegment } from '~/components/SegmentAnalytics';
import ActionsMenu from '~/components/Shared/ActionsMenu';
import BaseLoadView from '~/components/Shared/BaseLoadView';
import BaseSaveView from '~/components/Shared/BaseSaveView';
import EcButton from '~/components/Shared/EcButton';
import EcModal from '~/components/Shared/EcModal';
import EcPaginate from '~/components/Shared/EcPaginate';
import EcSelect from '~/components/Shared/EcSelect';
import { showToast } from '~/components/Shared/EcToast';
import CheckmarkIcon from '~/components/Shared/Icons/CheckmarkIcon';
import LoadingSpinner from '~/components/Shared/Icons/LoadingSpinner';
import PlusIcon from '~/components/Shared/Icons/PlusIcon';
import ReloadIcon from '~/components/Shared/Icons/ReloadIcon';
import {
  BULK_COPY_LIMIT,
  BULK_DELETE_LIMIT,
  BULK_EDIT_LIMIT,
  BULK_MOVE_LIMIT,
} from '~/constants/max_lengths';
import { PAGE_SIZE, PAGE_START } from '~/constants/page';
import { Box, EmptyPage } from '~/eds';
import {
  FeatureFlagType,
  QueryParamType,
  RoleType,
  TableContextType,
} from '~/enums';
import { OnboardingIdType } from '~/features/onboarding';
import { FlagType, withFlags } from '~/flags';
import {
  getDocumentOriginal,
  getDocumentsCount,
  getFolderHandlersByPosition,
  getFolderPositionByHandler,
} from '~/redux/api/methods';
import { initContext } from '~/redux/slices/documentsNavigation';
import { uploadDocuments } from '~/slices/fileUpload';
import {
  MODAL_ACCESS_SETTINGS,
  MODAL_DELETE,
  MODAL_DEPARTMENT_TREE,
  MODAL_DOCUMENT_GROUP,
  MODAL_DOCUMENTS_COLUMN_FIELDS,
  MODAL_DOCUMENTS_COLUMN_FIELDS_SAVE,
  MODAL_DOCUMENTS_COLUMN_FIELDS_VIEW_SWITCH,
  MODAL_FOLDER_TREE,
  MODAL_MULTI_EDIT,
  MODAL_NAME,
  MODAL_UPLOAD,
} from '~/types/modal.types';
import { ERROR, SUCCESS, WARNING } from '~/types/toast.types';
import { parseNavigationResponse as parseResponse } from '~/utils';
import { getStorageItem } from '~/utils/browser';
import { filterValidFiles } from '~/utils/helper.utils';
import {
  getPageSearchQueryByKey,
  updatePageSearchQuery,
} from '~/utils/searchQuery';
import { getStorageKey } from '~/utils/table';
import {
  featureFlagIncluded,
  getClientInfo,
  getUserClientInfo,
  testIsAdmin,
} from '~/utils/user';

// TODO: break down this complex component and manage state logic in Redux
class Page extends Component {
  constructor(props) {
    super(props);
    this.tableV2Ref = createRef();
    this.onFolderCreate = this.onFolderCreate.bind(this);
    this.onUpdateFolderAccessSettings = this.onUpdateFolderAccessSettings.bind(
      this,
    );
    this.onFileFolderRename = this.onFileFolderRename.bind(this);
    this.onBulkDelete = this.onBulkDelete.bind(this);
    this.onBulkMoveCopy = this.onBulkMoveCopy.bind(this);
    this.handleUploadLocationChange = this.handleUploadLocationChange.bind(
      this,
    );
    this.handleAddUploadFiles = this.handleAddUploadFiles.bind(this);
    this.handleRemoveUploadFile = this.handleRemoveUploadFile.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.handleShowSecondaryModal = this.handleShowSecondaryModal.bind(this);
    this.handleHideSecondaryModal = this.handleHideSecondaryModal.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleDepartmentChange = this.handleDepartmentChange.bind(this);
    this.updateDepartmentsArray = this.updateDepartmentsArray.bind(this);
    this.handleSaveEditColumns = this.handleSaveEditColumns.bind(this);
    this.handleRowSelect = this.handleRowSelect.bind(this);
    this.handleSortClick = this.handleSortClick.bind(this);
    this.handleErrorLoadFolderData = this.handleErrorLoadFolderData.bind(this);
    this._updateDocuments = this._updateDocuments.bind(this);
    this.handleOnSelectPageClick = this.handleOnSelectPageClick.bind(this);
    this.handleOnSelectAllClick = this.handleOnSelectAllClick.bind(this);
    this.handleOnConfirmDelete = this.handleOnConfirmDelete.bind(this);

    this.state = {
      isLoading: true,
      isFetching: true,
      folderData: {},
      configuredColumns: [],
      isAllSelected: false,
      isPageSelected: false,
      uploadLocation: null,
      uploadFiles: [],
      primaryModal: null,
      secondaryModal: null,
      sortedColumn: getPageSearchQueryByKey(
        QueryParamType.SortedColumn,
        'name',
      ),
      sortedOrder: getPageSearchQueryByKey(QueryParamType.SortedOrder, 'asc'),
      page: getPageSearchQueryByKey(QueryParamType.Page, PAGE_START),
      pageCount: 1,
      pageSize: getPageSearchQueryByKey(QueryParamType.PageSize, PAGE_SIZE),
      selectedItems: getPageSearchQueryByKey(QueryParamType.SelectedItems, []),
      canModifyFolder: false,
      departmentsSelected: [],
      hasUploadPermission: null,
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { folderId },
      },
      currentUser,
      flags,
    } = this.props;
    const {
      page,
      pageSize,
      sortedColumn,
      sortedOrder,
      selectedItems,
    } = this.state;
    let localStorageTableState;
    if (flags[FlagType.DocumentsTableRevamp]) {
      localStorageTableState = getStorageItem(
        getStorageKey(currentUser, TableContextType.Documents),
      );
    }
    const consolidatedSortedOrder = localStorageTableState?.sortBy
      ? localStorageTableState.sortBy.desc
        ? 'desc'
        : 'asc'
      : sortedOrder;
    const urlParams = {
      page: page,
      pageSize: localStorageTableState?.pageSize ?? pageSize,
      sortedOrder: consolidatedSortedOrder,
      sortedColumn: localStorageTableState?.sortBy.id ?? sortedColumn,
      selectedItems: selectedItems,
    };

    this.setState(
      {
        ...urlParams,
      },
      () => {
        this.loadFolderData({ isFirstLoad: true });
        updatePageSearchQuery(urlParams);
      },
    );

    this.initializeDropzone();

    getUserCanModifyFolder(folderId)
      .then((data) => {
        const canModifyFolder = data.can_modify_folder;
        this.setState({
          canModifyFolder,
          hasUploadPermission: canModifyFolder,
        });
      })
      .catch(handle403);
  }

  componentDidUpdate(prevProps, prevState) {
    const { flags } = this.props;
    const { folderId } = this.props.match.params;
    const { sortedColumn, sortedOrder, pageSize, page } = this.state;

    if (prevState.uploadLocation?.id !== this.state.uploadLocation?.id) {
      getUserCanModifyFolder(this.state.uploadLocation.id)
        .then((res) => {
          this.setState({ hasUploadPermission: res['can_modify_folder'] });
        })
        .catch(handle403);
    }

    if (folderId !== prevProps.match.params.folderId) {
      const firstPage = 1;
      this.setState({ isLoading: true, isFetching: true, page: firstPage });
      getUserCanModifyFolder(folderId)
        .then((data) => {
          this.setState({ canModifyFolder: data.can_modify_folder });
        })
        .catch(handle403);
      getFolderData(
        folderId,
        sortedColumn,
        sortedOrder,
        firstPage,
        pageSize,
      ).then((data) => {
        this.setState(
          {
            folderData: data,
            uploadLocation: data,
            pageCount: data.count,
            isLoading: false,
            isFetching: false,
          },
          this.addGroupInfo,
        );
      });
    }
    if (prevState.primaryModal === MODAL_UPLOAD && !this.state.primaryModal) {
      this.setState(
        { folderData: prevState.folderData, uploadFiles: [] },
        this.addGroupInfo,
      );
    }
    if (prevState.folderData !== this.state.folderData) {
      this.setState({
        secondaryModal: null,
        folderData: this.state.folderData,
      });
    }
    if (
      prevState.selectedItem &&
      this.state.selectedItem &&
      prevState.selectedItem !== this.state.selectedItem &&
      this.state.selectedItem &&
      this.state.selectedItem.folder_access_list
    ) {
      this.setState({
        departmentsSelected: this.state.selectedItem.folder_access_list
          .departments,
      });
    }
    const currentSearchParams = { page, pageSize, sortedColumn, sortedOrder };
    const prevStateSearchParams = {
      page: prevState.page,
      pageSize: prevState.pageSize,
      sortedColumn: prevState.sortedColumn,
      sortedOrder: prevState.sortedOrder,
    };
    if (
      flags[FlagType.DocumentsTableRevamp] &&
      !isEqual(currentSearchParams, prevStateSearchParams)
    ) {
      /**
       * When DocumentsTableRevamp flag is OFF, the function to load folder data is called
       * in each onChange function of the table. While with the new table, only the state is updated and
       * the call to load data is consolidated here in a single callsite when the params change.
       */
      this.loadFolderData();
    }
  }

  initializeNavigationContext = ({ type }) => {
    if (type === 'folder') return;

    const { folderData, sortedColumn, sortedOrder } = this.state;
    const { location } = this.props;
    const fromLocation = { ...location, search: window.location.search };

    const { id: folderId, name } = folderData;

    const api = (position, folder) =>
      getFolderHandlersByPosition({
        position,
        folderId: folder,
        orderByField: sortedColumn,
        order: sortedOrder,
      });
    const getPosition = (handler, folder) =>
      getFolderPositionByHandler({
        handlerId: handler,
        folderId: folder,
        orderByField: sortedColumn,
        order: sortedOrder,
      });

    const initOptions = {
      api,
      name,
      parseResponse,
      getPosition,
      fromLocation,
      folderId,
    };
    this.props.initContext(initOptions);
  };

  initializeDropzone() {
    const dzComponentConfig = { postUrl: 'no-url' };
    const dzConfig = {
      autoQueue: false,
      autoProcessQueue: false,
      dictDefaultMessage: '',
      previewTemplate: '<div class="ignore"></div>',
    };
    const dzEventHandlers = {
      init: (dz) => (this.dropzone = dz),
      addedfiles: async (files) => {
        const validFiles = await filterValidFiles(files);
        if (validFiles.length > 0) {
          setTimeout(() => {
            this.handleAddUploadFiles(validFiles);
            this.handleShowModal(MODAL_UPLOAD);
          }, 0);
        }
      },
    };

    this.setState({ dzComponentConfig, dzConfig, dzEventHandlers });
  }

  _updateDocuments(documentDataArray) {
    const { folderData } = this.state;

    for (let newData of documentDataArray) {
      const documentIndex = folderData.results
        ? folderData.results.findIndex(
            ({ id, type }) => id === newData.documentId && type === 'document',
          )
        : -1;
      if (documentIndex !== -1) {
        folderData.results[documentIndex] = {
          ...folderData.results[documentIndex],
          groups: newData.results,
        };
      }
    }

    this.setState({ folderData });
  }

  addGroupInfo() {
    const { folderData } = this.state;
    const docIds = [];
    for (let folderOrDoc of folderData.results) {
      folderOrDoc.groups = [];
      if (folderOrDoc.type === 'document') {
        docIds.push(folderOrDoc.id);
      }
    }

    const handleErrorLoadGroupData = () =>
      showToast(WARNING, 'There was an error loading group information');
    return getGroupsForDocuments(
      docIds,
      this._updateDocuments,
      handleErrorLoadGroupData,
    );
  }

  _loadFolderData(
    folderId,
    sortedColumn,
    sortedOrder,
    page,
    pageSize,
    isFirstLoad = false,
  ) {
    const items = getPageSearchQueryByKey(QueryParamType.SelectedItems, []);

    this.setState({
      isLoading: isFirstLoad,
      isFetching: true,
    });
    getFolderData(folderId, sortedColumn, sortedOrder, page, pageSize)
      .then((data) => {
        this.setState(
          {
            folderData: data,
            configuredColumns: data.results.length
              ? data.results[0].document_fields
              : [],
            uploadLocation: data,
            pageCount: data.count,

            selectedItems: items,
          },
          this.addGroupInfo,
        );
      })
      .catch(() => this.handleErrorLoadFolderData())
      .finally(() => {
        this.setState({
          isLoading: false,
          isFetching: false,
        });
      });
  }

  loadFolderData = ({ isFirstLoad = false } = {}) => {
    const { folderId } = this.props.match.params;
    const { sortedColumn, sortedOrder, page, pageSize } = this.state;
    this._loadFolderData(
      folderId,
      sortedColumn,
      sortedOrder,
      page,
      pageSize,
      isFirstLoad,
    );
  };

  reloadPage() {
    window.location.reload();
  }

  handleErrorLoadFolderData() {
    this.setState({
      errorLoadingMessage: true,
    });
  }

  handleSortClick(columnName) {
    const { flags } = this.props;
    const disableSortByField =
      flags[FlagType.FieldValueMigrationDeferredFeaturesBypass] ||
      flags[FlagType.DisableDocumentTableSorting];
    if (columnName === 'cog' || columnName === 'selectAll') return;
    else if (columnName === 'name') {
      this.setState(
        {
          page: PAGE_START,
          sortedOrder: this.state.sortedOrder === 'asc' ? 'desc' : 'asc',
          sortedColumn: 'name',
        },
        () => {
          this.loadFolderData();
          updatePageSearchQuery({
            [QueryParamType.SortedColumn]: 'name',
            [QueryParamType.SortedOrder]: this.state.sortedOrder,
          });
        },
      );
    } else if (disableSortByField) {
      // We don't want to sort by field if disableSortByField is true
      return;
    } else {
      const { configuredColumns } = this.state;

      this.setState(
        {
          page: PAGE_START,
          sortedOrder:
            configuredColumns.filter(
              (column) => column.field_name === columnName,
            ).length &&
            configuredColumns.filter(
              (column) => column.field_name === columnName,
            )[0].field_name === columnName &&
            this.state.sortedOrder === 'asc'
              ? 'desc'
              : 'asc',
          sortedColumn:
            configuredColumns.filter(
              (column) => column.field_name === columnName,
            ).length &&
            configuredColumns.filter(
              (column) => column.field_name === columnName,
            )[0].field_id,
        },
        () => {
          this.loadFolderData();
          updatePageSearchQuery({
            [QueryParamType.SortedColumn]: this.state.sortedColumn,
            [QueryParamType.SortedOrder]: this.state.sortedOrder,
          });
        },
      );
    }
  }

  handlePageSizeOnChange = (value) => {
    this.setState({ pageSize: value, page: 1 }, () => {
      this.loadFolderData();
      updatePageSearchQuery({
        [QueryParamType.Page]: 1,
        [QueryParamType.PageSize]: value,
      });
    });
  };

  updateTableState = ({ page, pageSize, sortBy }) => {
    const sortedColumn = sortBy.id;
    const sortedOrder = sortBy.desc ? 'desc' : 'asc';
    this.setState({ page, pageSize, sortedColumn, sortedOrder }, () => {
      updatePageSearchQuery(
        {
          [QueryParamType.Page]: page,
          [QueryParamType.PageSize]: pageSize,
          [QueryParamType.SortedColumn]: sortedColumn,
          [QueryParamType.SortedOrder]: sortedOrder,
        },
        true,
      );
    });
  };

  transformSelectedItemsForPages(selectedItemsOnCurrentPage) {
    const {
      folderData: { results },
      selectedItems,
    } = this.state;

    const currentPageItemIds = results.map((item) => item.id);
    const selectedItemsOnOtherPages = selectedItems.filter(
      (item) => !currentPageItemIds.includes(item.id),
    );
    return {
      selectedItemsOnOtherPages,
      combinedData: [
        ...selectedItemsOnOtherPages,
        ...selectedItemsOnCurrentPage,
      ],
    };
  }

  handleRowSelect(rowsSelected) {
    const selectedItemsOnCurrentPage = rowsSelected.map((item) => {
      return {
        document_id: item.original.document_id, // add to group
        id: item.original.id, // edit copy move
        name: item.original.name, // delete
        type: item.original.type, // copy move
        contains_sync_pair: item.original.contains_sync_pair, // move
      };
    });
    const { combinedData } = this.transformSelectedItemsForPages(
      selectedItemsOnCurrentPage,
    );

    this.setState({ selectedItems: combinedData, isAllSelected: false }, () => {
      updatePageSearchQuery({ [QueryParamType.SelectedItems]: combinedData });
    });
  }

  handlePageClick(clickedPage) {
    const selectedPage = clickedPage.selected + 1;
    this.setState({ page: selectedPage }, () => {
      updatePageSearchQuery({ [QueryParamType.Page]: selectedPage }, true);
      this.loadFolderData();
    });
  }

  handleOnSelectPageClick(isPageSelected) {
    const {
      folderData: { results },
    } = this.state;

    const selectedItemsOnCurrentPage = results.map((item) => {
      return {
        document_id: item.document_id, // add to group
        id: item.id, // edit copy move
        name: item.name, // delete
        type: item.type, // copy move
      };
    });
    const {
      combinedData,
      selectedItemsOnOtherPages,
    } = this.transformSelectedItemsForPages(selectedItemsOnCurrentPage);
    const newSelectedItems = isPageSelected
      ? combinedData
      : selectedItemsOnOtherPages;

    this.setState({ isPageSelected, selectedItems: newSelectedItems }, () => {
      updatePageSearchQuery({
        [QueryParamType.SelectedItems]: newSelectedItems,
      });
    });
  }

  handleOnSelectAllClick(isAllSelected) {
    this.setState({ isAllSelected });
  }

  handleUploadLocationChange(uploadLocation) {
    this.setState({ uploadLocation });
  }

  handleAddUploadFiles(files) {
    const newFiles = Object.keys(files).map((key) => files[key]);
    this.setState({ uploadFiles: [...this.state.uploadFiles, ...newFiles] });
  }

  handleRemoveUploadFile(uploadId) {
    const newStateFiles = this.state.uploadFiles.filter(
      (file) => file.upload.uuid !== uploadId,
    );
    this.setState({ uploadFiles: newStateFiles });
  }

  handleShowModal = (modal, selectedItem, modalBulkAction = null) => {
    const { flags } = this.props;
    this.setState({
      primaryModal: modal,
      modalBulkAction,
      selectedItems:
        Array.isArray(selectedItem) && flags[FlagType.DocumentsTableRevamp]
          ? selectedItem
          : this.state.selectedItems,
      selectedItem,
      departmentsSelected:
        selectedItem && selectedItem.folder_access_list
          ? selectedItem.folder_access_list.departments
          : [],
    });
  };

  handleHideModal() {
    this.setState({
      primaryModal: null,
      modalBulkAction: null,
      selectedItem: null,
    });
  }

  handleShowSecondaryModal(modal) {
    this.setState({ secondaryModal: modal });
  }

  handleHideSecondaryModal() {
    this.setState({ secondaryModal: null });
  }

  handleOnConfirmDelete(deleteReason, deleteComment) {
    const { selectedItem, selectedItems, folderData } = this.state;
    const { history } = this.props;
    const [documents, folders, select_all] = this.setBulkParams();
    bulkDelete(
      selectedItem?.selfUpdate ? selectedItem.parentId : folderData.id,
      documents,
      folders,
      select_all,
      deleteReason,
      deleteComment,
      true,
    )
      .then((res) => {
        if (res.skipped_folder_names) {
          const skipped = res.skipped_folder_names.join(', ');
          const warningMessage = `The following folder(s) were skipped because they contain synchronization(s) with external storage provider(s): ${skipped}`;
          showToast(WARNING, warningMessage);
        } else {
          const successMessage =
            !selectedItems || selectedItems.length
              ? 'Selected items have been deleted.'
              : `"${selectedItem.name}" has been deleted.`;
          showToast(SUCCESS, successMessage);
        }

        if (selectedItem?.selfUpdate) {
          history?.goBack();
        }

        this.updatePageAfterBulkAction(documents);
      })
      .catch((err) => {
        if (err.response && err.response.status === 409) {
          showToast(
            WARNING,
            'Could not delete this folder, it contains a synchronization with an external storage provider.',
          );
        } else {
          const errorMessage =
            !selectedItems || selectedItems.length
              ? 'An error occurred while deleting these items.'
              : `An error occurred while deleting this ${selectedItem.type}`;
          showToast(WARNING, errorMessage);
        }

        this.setState({ isAllSelected: false, isPageSelected: false });
      });
  }

  showNameCollisionErrorToast = () => {
    showToast(
      ERROR,
      'There is a folder somewhere in your organization with the same name. Please choose a different name.',
    );
  };

  onFolderCreate(folderName, parentId) {
    createFolder(folderName, parentId)
      .then(() => {
        showToast(SUCCESS, 'Folder has been created.');
        this.loadFolderData();
        this.handleHideModal();
      })
      .catch((err) => {
        if (err.response && err.response.status === 400) {
          this.showNameCollisionErrorToast();
        } else if (err.response && err.response.status === 422) {
          showToast(
            ERROR,
            "A forward slash '/' is not allowed in a folder name. Please choose a different name.",
          );
        } else {
          showToast(WARNING, 'An error occurred while creating a new folder.');
        }
      });
  }

  onUpdateFolderAccessSettings(
    folderId,
    folderName,
    parentId,
    visibilityLevel,
    folderAccessList,
    departmentAccessList,
  ) {
    const { selectedItem } = this.state;
    updateFolderAccessSettings(
      folderId,
      folderName,
      selectedItem?.selfUpdate ? selectedItem.parentId : parentId,
      visibilityLevel,
      folderAccessList,
      departmentAccessList,
    )
      .then(() => {
        showToast(SUCCESS, 'Folder has been updated.');
        this.loadFolderData();
        this.handleHideModal();
      })
      .catch(() => {
        showToast(
          ERROR,
          'An error occurred while updating the folder access settings.',
        );
      });
  }

  onFileFolderRename(itemName, parentId) {
    const { selectedItem } = this.state;

    const isDocument = selectedItem.type === 'document';
    const selectedItemsUrl = isDocument
      ? `/document/${selectedItem.id}`
      : `/documents/${selectedItem.id}`;
    const renameItem = isDocument ? renameDocument : renameFolder;

    this.setState({ isLoading: true });

    renameItem(selectedItem.id, itemName, parentId)
      .then(() => {
        const newFolderData = { ...this.state.folderData };
        newFolderData.results = newFolderData.results.map((item) => {
          if (item.id === selectedItem.id && item.type === selectedItem.type) {
            item.name = itemName;
          }
          return item;
        });

        this.setState(
          {
            folderData: selectedItem?.selfUpdate
              ? { ...newFolderData, name: itemName }
              : newFolderData,
          },
          showToast(
            SUCCESS,
            `"${selectedItem.name}" has been renamed.`,
            selectedItemsUrl,
          ),
        );
      })
      .catch((err) => {
        if (err?.response?.data?.non_field_errors?.[0]) {
          showToast(ERROR, err.response.data.non_field_errors[0]);
        } else if (err.response && err.response.status === 400) {
          this.showNameCollisionErrorToast();
        } else if (err.response && err.response.status === 422) {
          showToast(
            ERROR,
            `A forward slash '/' is not allowed in a ${selectedItem.type} name. Please choose a different name.`,
          );
        } else {
          showToast(
            ERROR,
            `An error occurred while renaming this ${selectedItem.type}`,
          );
        }
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  updatePageAfterBulkAction = (documents) => {
    // after a bulk action involving all selected documents, redirect to the relevant computed next page
    const { folderData, pageCount, page: currentPage, pageSize } = this.state;

    const maxPage = Math.ceil(pageCount / pageSize);
    let nextPage = currentPage;
    if (documents.length === folderData.results.length && currentPage > 1) {
      nextPage = currentPage >= maxPage ? 1 : currentPage + 1;
    }

    this.setState(
      {
        selectedItems: [],
        isAllSelected: false,
        isPageSelected: false,
        page: nextPage,
      },
      () => {
        updatePageSearchQuery({ [QueryParamType.SelectedItems]: [] });
        if (this.tableV2Ref.current) {
          this.tableV2Ref.current.clearSelectedRows?.();
        }
        this.loadFolderData();
      },
    );
  };

  onBulkMoveCopy(location) {
    const {
      selectedItem,
      selectedItems,
      folderData,
      modalBulkAction,
    } = this.state;
    const bulkAction =
      (modalBulkAction ?? selectedItem.action) === 'Move' ? bulkMove : bulkCopy;
    const [documents, folders, select_all] = this.setBulkParams();

    bulkAction(
      selectedItem?.selfUpdate ? selectedItem.parentId : folderData.id,
      documents,
      folders,
      location.id,
      select_all,
    )
      .then((res) => {
        if (res.skipped_folder_names) {
          const skipped = res.skipped_folder_names.join(', ');
          const warningMessage = `The following folders were skipped because moving them would create overlapping external synchronizations: ${skipped}`;
          showToast(WARNING, warningMessage);
        } else {
          const successVerb = modalBulkAction === 'Move' ? 'moved' : 'copied';
          const successMessage =
            !selectedItems || selectedItems.length
              ? `Selected items have been ${successVerb} to "${location.name}".`
              : `"${selectedItem.name}" has been ${successVerb} to "${location.name}".`;
          showToast(SUCCESS, successMessage);
        }
        if (selectedItem?.selfUpdate) {
          this.loadFolderData();
        } else {
          this.updatePageAfterBulkAction(documents);
        }
      })
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.error || 'Action has failed to execute.';
        showToast(ERROR, errorMessage);
        this.setState({ isAllSelected: false, isPageSelected: false });
      });
  }

  onBulkDelete() {
    const { selectedItem, selectedItems, folderData } = this.state;
    const { history } = this.props;
    const [documents, folders, select_all] = this.setBulkParams();

    bulkDelete(
      selectedItem?.selfUpdate ? selectedItem.parentId : folderData.id,
      documents,
      folders,
      select_all,
      null,
      null,
      true,
    )
      .then((res) => {
        if (res.skipped_folder_names) {
          const skipped = res.skipped_folder_names.join(', ');
          const warningMessage = `The following folder(s) were skipped because they contain synchronization(s) with external storage provider(s): ${skipped}`;
          showToast(WARNING, warningMessage);
        } else {
          const successMessage =
            !selectedItems || selectedItems.length
              ? 'Selected items have been deleted.'
              : `"${selectedItem.name}" has been deleted.`;
          showToast(SUCCESS, successMessage);
        }
        if (selectedItem?.selfUpdate) {
          history?.goBack();
        } else {
          this.updatePageAfterBulkAction(documents);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 409) {
          showToast(
            WARNING,
            'Could not delete this folder, it contains a synchronization with an external storage provider.',
          );
        } else {
          const genericMessage =
            !selectedItems || selectedItems.length
              ? 'An error occurred while deleting these items.'
              : `An error occurred while deleting this ${selectedItem.type}`;

          const errorMessage = err.response?.data?.error || genericMessage;
          showToast(WARNING, errorMessage);
        }

        this.setState({ isAllSelected: false, isPageSelected: false });
      });
  }

  setBulkParams() {
    const { selectedItem, selectedItems, isAllSelected } = this.state;

    let documents = [];
    let folders = [];
    let select_all = false;

    if (isAllSelected) {
      select_all = true;
    } else {
      if (selectedItems) {
        if (selectedItems.length) {
          selectedItems.forEach((item) =>
            item.type === 'folder'
              ? folders.push(item.id)
              : documents.push(item.id),
          );
        } else {
          selectedItem.type === 'folder'
            ? folders.push(selectedItem.id)
            : documents.push(selectedItem.id);
        }
      }
    }

    return [documents, folders, select_all];
  }

  handleOnDownloadOriginalClick(item) {
    const { id, name, file_type } = item;

    getDocumentOriginal(id)
      .then((res) => saveAs(res, `${name}${file_type}`))
      .catch(() =>
        showToast(ERROR, `Something went wrong with downloading "${name}".`),
      );
  }

  handleOnDownloadOcrClick(item) {
    const { id, name } = item;

    getDocumentOCR(id)
      .then((res) => saveAs(res, `${name}.${res.type.split('/').pop()}`))
      .catch(() =>
        showToast(ERROR, `Something went wrong with downloading "${name}".`),
      );
  }

  handleSaveEditColumns(fields) {
    editDocumentsViewsetFields(fields).then(() => this.loadFolderData());
  }

  handleSelectRowScreenReader(text) {
    document.querySelector('#srHolder').textContent = text;
  }

  renderPagination() {
    const { page, pageCount, pageSize } = this.state;

    if (pageCount <= pageSize) return null;

    return (
      <EcPaginate
        onPageChange={this.handlePageClick}
        pageCount={Math.ceil(pageCount / pageSize)}
        forcePage={page - 1}
      />
    );
  }

  getDocumentCountCall = () => {
    const {
      selectedItems,
      selectedItem,
      isAllSelected,
      folderData,
    } = this.state;
    let folders = [];
    if (selectedItems?.length) {
      folders = selectedItems.filter((folder) => folder.type === 'folder');
    } else if (selectedItem?.type === 'folder') {
      folders = [selectedItem];
    }
    const documents = selectedItems.filter(
      (document) => document.type === 'document',
    );
    if (folders.length === 0 && !isAllSelected) {
      return {};
    } else {
      return getDocumentsCount({
        folder_id: folderData.id,
        documents: !isAllSelected
          ? documents.map((document) => document.id)
          : [],
        folders: !isAllSelected ? folders.map((folder) => folder.id) : [],
        select_all: isAllSelected,
      });
    }
  };

  getMenuItemAccessSettings = (item) => ({
    content: <Box id={OnboardingIdType.AccessSettingsTab}>Access Settings</Box>,
    id: `documents_row_actionAccessSettings?${item.type}=${item.id}`,
    onClick: () => this.handleShowModal(MODAL_ACCESS_SETTINGS, item),
  });

  getMenuItemDelete = (item) => {
    if (item.contains_sync_pair) {
      return {
        content: 'Delete',
        id: `documents_row_actionDelete?${item.type}=${item.id}`,
        disabled: true,
        tooltip:
          'Cannot delete folders containing a synchronization with an ' +
          'external provider. Please contact your ' +
          'Evisort admin for help deleting this folder',
      };
    } else {
      return {
        content: 'Delete',
        id: `documents_row_actionDelete?${item.type}=${item.id}`,
        onClick: () => this.handleShowModal(MODAL_DELETE, item),
      };
    }
  };

  getMenuItemDownload = (item) => ({
    content: 'Download',
    id: `documents_row_actionDownloadOriginal?${item.type}=${item.id}`,
    onClick: () => this.handleOnDownloadOriginalClick(item),
  });

  getMenuItemMove = (item) => ({
    content: 'Move',
    id: `documents_row_actionMove?${item.type}=${item.id}`,
    onClick: () => this.handleShowModal(MODAL_FOLDER_TREE, item, 'Move'),
  });

  getMenuItemRename = (item) => ({
    content: 'Rename',
    id: `documents_row_actionRename?${item.type}=${item.id}`,
    onClick: () => this.handleShowModal(MODAL_NAME, item),
  });

  getMenuItemAddToGroup = (item) => ({
    content: 'Add to Group',
    id: `documents_row_actionAddToGroup?${item.type}=${item.id}`,
    onClick: () => this.handleShowModal(MODAL_DOCUMENT_GROUP, item),
  });

  getMenuItems = (item, currentUser) => {
    const items = [this.getMenuItemRename(item), this.getMenuItemMove(item)];
    const isCustomRole = !!(
      currentUser?.role_uuid?.role_type === RoleType.CustomRole
    );

    if (item.type === 'document') items.push(this.getMenuItemAddToGroup(item));
    if (item.type === 'document') items.push(this.getMenuItemDownload(item));
    if (item.type === 'folder' && testIsAdmin(currentUser) && !isCustomRole) {
      items.push(this.getMenuItemAccessSettings(item));
    }

    items.push(this.getMenuItemDelete(item));

    return items;
  };

  renderExtraOptions(item) {
    const { classes, currentUser } = this.props;
    const isAdmin = testIsAdmin(currentUser);

    if (isAdmin || item.user_visibility_level === 'OPEN') {
      return (
        <div className={classes.actionsWrapper}>
          <ActionsMenu
            id={`documents_row_actions_menu?${item.type}=${item.id}`}
            items={this.getMenuItems(item, currentUser)}
            smallIcon
            title={`edit ${item.type} menu`}
          />
        </div>
      );
    }
    return null;
  }

  renderPageSizeDropdown() {
    const { classes } = this.props;
    const { pageSize } = this.state;

    return (
      <div className={classes.documentsPageSize}>
        <EcSelect
          aria-label={`page size menu has ${pageSize} rows per page selected`}
          width="200px"
          height="100%"
          options={[
            { label: 20, value: 20 },
            { label: 30, value: 30 },
            { label: 50, value: 50 },
          ]}
          defaultValue={{ label: pageSize, value: pageSize }}
          onChange={(option) => this.handlePageSizeOnChange(option.value)}
          isSearchable={false}
        />
      </div>
    );
  }

  renderFolderContent() {
    const { classes, currentUser, flags } = this.props;
    const {
      folderData,
      sortedColumn,
      sortedOrder,
      dzComponentConfig,
      dzConfig,
      dzEventHandlers,
      canModifyFolder,
      isAllSelected,
      selectedItems,
      isFetching,
      page,
      pageSize,
      pageCount,
    } = this.state;

    const configuredColumns = folderData.results.length
      ? folderData.results[0].document_fields
      : [];

    const mappedFolderData = folderData.results.length
      ? folderData.results.map((item) => {
          const mapped_document_fields =
            item && item.document_fields
              ? item.document_fields.map((item) => {
                  const fieldName = item.field_name;
                  const displayValue =
                    item.core_name === 'Upload Date' &&
                    item.display_value &&
                    item.display_value[0]
                      ? moment(item.display_value[0]).format(
                          'YYYY-MM-DD hh:mm A',
                        )
                      : item.display_value.join(' • ');
                  return {
                    [fieldName]: displayValue,
                  };
                })
              : null;

          const row = {
            ...item,
            name: item.name,
            id: item.id,
            fileType: item.file_type,
            type: item.type,
            isMovable: item.is_movable,
            folderPath: folderData.path,
            content_visibility_level: item.content_visibility_level,
            folder_access_list: {
              departments: item.folder_access_list.departments,
              members: item.folder_access_list.members,
            },
            ...mapped_document_fields,
          };

          return row;
        })
      : [];

    const dataWithMenuItems = mappedFolderData.map((row) => {
      return {
        ...row,
        actionMenu: this.renderExtraOptions(row),
      };
    });

    let sortField = 'name';
    const columnSorted = configuredColumns.filter(
      (field) => field.field_id.toString() === sortedColumn.toString(),
    );
    if (columnSorted.length) {
      sortField = columnSorted[0].field_name;
    }

    return (
      <Fragment>
        <div>
          <div className="screenReaderText" id="srHolder" aria-live="polite" />
          {canModifyFolder && featureFlagIncluded(currentUser, 'UPLOAD') ? (
            <DropzoneComponent
              className={classes.documentsDropzone}
              config={dzComponentConfig}
              djsConfig={dzConfig}
              eventHandlers={dzEventHandlers}
            >
              {flags[FlagType.DocumentsTableRevamp] ? (
                <DocumentsTableV2
                  onNavigate={this.initializeNavigationContext}
                  data={dataWithMenuItems}
                  isLoading={isFetching}
                  tableState={{
                    page,
                    pageSize,
                    sortBy: {
                      id: columnSorted.length
                        ? `${columnSorted[0].field_id}`
                        : 'name',
                      desc: sortedOrder === 'desc',
                    },
                    totalCount: pageCount,
                  }}
                  onUpdateTableState={this.updateTableState}
                  getMenuItems={this.getMenuItems}
                  handleShowModal={this.handleShowModal}
                  refetchData={this.loadFolderData}
                  onSelectAllClick={this.handleOnSelectAllClick}
                  ref={this.tableV2Ref}
                />
              ) : (
                <DocumentsTable
                  onNavigate={this.initializeNavigationContext}
                  user={currentUser}
                  folderItemsCount={folderData.count}
                  folderPath={folderData.path}
                  data={dataWithMenuItems}
                  columns={configuredColumns}
                  sortField={sortField}
                  order={sortedOrder}
                  handleShowModal={this.handleShowModal}
                  handleSortClick={this.handleSortClick}
                  handleRowSelect={this.handleRowSelect}
                  handleOnSelectPageClick={this.handleOnSelectPageClick}
                  handleOnSelectAllClick={this.handleOnSelectAllClick}
                  isAllSelected={isAllSelected}
                  handleSelectRowScreenReader={this.handleSelectRowScreenReader}
                  selectedItems={selectedItems}
                />
              )}
            </DropzoneComponent>
          ) : flags[FlagType.DocumentsTableRevamp] ? (
            <DocumentsTableV2
              onNavigate={this.initializeNavigationContext}
              data={dataWithMenuItems}
              isLoading={isFetching}
              tableState={{
                page,
                pageSize,
                sortBy: {
                  id: columnSorted.length
                    ? `${columnSorted[0].field_id}`
                    : 'name',
                  desc: sortedOrder === 'desc',
                },
                totalCount: pageCount,
              }}
              onUpdateTableState={this.updateTableState}
              getMenuItems={this.getMenuItems}
              handleShowModal={this.handleShowModal}
              refetchData={this.loadFolderData}
              onSelectAllClick={this.handleOnSelectAllClick}
              ref={this.tableV2Ref}
            />
          ) : (
            <DocumentsTable
              onNavigate={this.initializeNavigationContext}
              user={currentUser}
              folderItemsCount={folderData.count}
              folderPath={folderData.path}
              data={dataWithMenuItems}
              columns={configuredColumns}
              sortField={sortField}
              order={sortedOrder}
              handleShowModal={this.handleShowModal}
              handleSortClick={this.handleSortClick}
              handleRowSelect={this.handleRowSelect}
              handleOnSelectPageClick={this.handleOnSelectPageClick}
              handleOnSelectAllClick={this.handleOnSelectAllClick}
              isAllSelected={isAllSelected}
              handleSelectRowScreenReader={this.handleSelectRowScreenReader}
              selectedItems={selectedItems}
            />
          )}
        </div>
        <span className={classes.horizontalSeparator} />
        {!flags[FlagType.DocumentsTableRevamp] && (
          <div className={classes.documentsPaginationAndSize}>
            {this.renderPagination()}
            {this.renderPageSizeDropdown()}
          </div>
        )}
      </Fragment>
    );
  }

  renderEmptyFolder() {
    const { classes } = this.props;
    const { dzComponentConfig, dzConfig, dzEventHandlers } = this.state;

    return (
      <DropzoneComponent
        className={classes.documentsDropzone}
        config={dzComponentConfig}
        djsConfig={dzConfig}
        eventHandlers={dzEventHandlers}
      >
        <div className={classes.emptyPageWrapper}>
          <EmptyPage preset="no-documents" />
        </div>
      </DropzoneComponent>
    );
  }

  renderDocumentsColumnFieldsModal() {
    const { configuredColumns } = this.state;

    return (
      <EcModal
        modalType={MODAL_DOCUMENTS_COLUMN_FIELDS}
        width="650px"
        data={configuredColumns}
        hideModal={this.handleHideModal}
        handleOnResetClick={this.handleOnResetClick}
        handleSaveEditColumns={this.handleSaveEditColumns}
      />
    );
  }

  renderDocumentsColumnFieldsSaveModal = () => {
    const { configuredColumns } = this.state;
    const { currentUser, flags } = this.props;
    const localStorgaTableState = getStorageItem(
      getStorageKey(currentUser, TableContextType.Documents),
    );
    let fields = [];
    if (
      flags[FlagType.DocumentsTableRevamp] &&
      localStorgaTableState?.columnOrder
    ) {
      fields = localStorgaTableState.columnOrder
        .filter((column) => column !== 'name')
        .map((column, index) => ({
          column_number: index,
          field_id: isNaN(parseInt(column)) ? column : parseInt(column),
        }));
    } else {
      fields = configuredColumns.map((field, index) => ({
        column_number: index,
        field_id: field.field_id,
      }));
    }

    return (
      <BaseSaveView
        modalType={MODAL_DOCUMENTS_COLUMN_FIELDS_SAVE}
        fields={fields}
        hideModal={this.handleHideModal}
      />
    );
  };

  renderDocumentsColumnFieldsViewSwitchModal() {
    return (
      <BaseLoadView
        modalType={MODAL_DOCUMENTS_COLUMN_FIELDS_VIEW_SWITCH}
        context={undefined}
        onViewSelected={this.loadFolderData}
        hideModal={this.handleHideModal}
      />
    );
  }

  renderNameModal() {
    const { folderData, selectedItem } = this.state;

    let title;
    let labelText;
    let confirmButtonIcon;
    let confirmButtonText;
    let currentName;
    let handleNameChange;

    if (selectedItem) {
      labelText = `${selectedItem.type.toUpperCase()} NAME`;
      title = `Rename ${selectedItem.type}`;
      confirmButtonText =
        selectedItem.type === 'document' ? 'Save' : 'Rename Folder';
      currentName = selectedItem.name;
      handleNameChange = this.onFileFolderRename;
    } else {
      labelText = 'FOLDER NAME';
      title = 'New Folder';
      confirmButtonIcon = <PlusIcon size="20" color="#000" />;
      confirmButtonText = 'Create Folder';
      currentName = '';
      handleNameChange = this.onFolderCreate;
    }

    return (
      <EcModal
        modalType={MODAL_NAME}
        width="560px"
        title={title}
        labelText={labelText}
        itemsParentFolderId={
          selectedItem?.selfUpdate ? selectedItem.parentId : folderData.id
        }
        itemsCurrentName={currentName}
        confirmButtonIcon={confirmButtonIcon}
        confirmButtonText={confirmButtonText}
        handleNameChange={handleNameChange}
        hideModal={this.handleHideModal}
      />
    );
  }

  updateDepartmentsArray(departmentsSelected) {
    this.setState({ departmentsSelected });
  }

  renderAccessSettingsModal() {
    const { currentUser } = this.props;
    const { client } = currentUser;
    const { folderData, selectedItem, departmentsSelected } = this.state;
    const folderId = selectedItem ? selectedItem.id : null;

    let title;
    let labelText;
    let confirmButtonIcon;
    let confirmButtonText;
    let currentName;
    let deptAccessList;
    let folderAccessList;
    let visibilityLevel;
    let handleNameChange;

    if (!!selectedItem) {
      title = `Access Settings for ${selectedItem.name} folder`;
      confirmButtonText = 'Save Changes';
      currentName = selectedItem.name;
      visibilityLevel = selectedItem.content_visibility_level;
      deptAccessList = selectedItem?.folder_access_list?.departments ?? [];
      folderAccessList = selectedItem?.folder_access_list?.members ?? [];
      handleNameChange = this.onUpdateFolderAccessSettings;
    } else {
      labelText = 'FOLDER NAME';
      title = 'New Folder';
      confirmButtonIcon = <PlusIcon size="20" color="#000" />;
      confirmButtonText = 'Create Folder';
      currentName = '';
      visibilityLevel = '';
      folderAccessList = [];
      handleNameChange = this.onFolderCreate;
    }

    return (
      <EcModal
        id={OnboardingIdType.AccessSettingsModal}
        modalType={MODAL_ACCESS_SETTINGS}
        width="660px"
        client={client}
        user={currentUser}
        title={title}
        labelText={labelText}
        itemsParentFolderId={folderData.id}
        folderId={folderId}
        itemsCurrentName={currentName}
        visibilityLevel={visibilityLevel}
        deptAccessList={deptAccessList}
        folderAccessList={folderAccessList}
        confirmButtonIcon={confirmButtonIcon}
        confirmButtonText={confirmButtonText}
        handleNameChange={handleNameChange}
        hideModal={this.handleHideModal}
        departmentsSelected={departmentsSelected}
        handleShowSecondaryModal={this.handleShowSecondaryModal}
        updateDepartmentsArray={this.updateDepartmentsArray}
      />
    );
  }

  renderAddToGroupModal() {
    const { selectedItem, selectedItems, isAllSelected } = this.state;
    const { currentUser } = this.props;
    const { folderId } = this.props.match.params;
    const [documents] = this.setBulkParams();

    let documentIds = [];
    if (selectedItems.length) {
      documentIds = selectedItems.map((item) =>
        item.original ? item.original.document_id : item.document_id,
      );
    } else if (selectedItem) {
      documentIds = [selectedItem.document_id];
    }

    return featureFlagIncluded(
      currentUser,
      FeatureFlagType.DocumentGroupRevamp,
    ) ? (
      <AddToGroup
        hideModal={this.handleHideModal}
        documentIds={isAllSelected ? undefined : documentIds}
        folderId={isAllSelected ? folderId : undefined}
        reloadDocumentContent={() => this.updatePageAfterBulkAction(documents)}
      />
    ) : (
      <EcModal
        modalType={MODAL_DOCUMENT_GROUP}
        width="660px"
        title="Add to Group"
        selectedItems={selectedItem ? selectedItem : selectedItems}
        documentIds={documentIds}
        folderId={isAllSelected ? folderId : null}
        hideModal={this.handleHideModal}
        reloadDocumentContent={() => this.updatePageAfterBulkAction(documents)}
      />
    );
  }

  renderBulkDeleteModalText() {
    const { folderData, selectedItems, isAllSelected } = this.state;

    if (!selectedItems && !isAllSelected) return null;

    const itemsCount = isAllSelected ? folderData.count : selectedItems.length;

    return (
      <Fragment>
        Are you sure you want to delete the&nbsp;
        <span>
          {itemsCount} {pluralize('item', itemsCount)}
        </span>
        &nbsp;you have selected? You won’t be able to undo this action.
      </Fragment>
    );
  }

  renderDeleteDocumentModalText() {
    return (
      <Fragment>
        Are you sure you want to delete
        <span> {this.state.selectedItem.name}</span>? You won’t be able to undo
        this action.
      </Fragment>
    );
  }

  renderDeleteFolderModalText() {
    return (
      <Fragment>
        Are you sure you want to delete
        <span> {this.state.selectedItem.name}</span>? Deleting this folder will
        also delete the documents it contains. You won’t be able to undo this
        action.
      </Fragment>
    );
  }

  renderDeleteModal() {
    const {
      selectedItem,
      selectedItems,
      primaryModal,
      isAllSelected,
      folderData,
    } = this.state;
    const { currentUser } = this.props;
    const itemsCount = isAllSelected ? folderData.count : selectedItems?.length;

    const documentDeleteReasonRequired = !!currentUser?.client_config
      ?.document_delete_reason_required;

    if (documentDeleteReasonRequired) {
      const count = selectedItems?.length ? itemsCount : 1;
      let type = 'item';
      let label = '';
      if (count === 1) {
        if (selectedItems?.length) {
          type = selectedItems[0]?.type;
          label = `"${selectedItems[0]?.name}"`;
        } else if (selectedItem) {
          type = selectedItem.type;
          label = `"${selectedItem?.name}"`;
        }
      }
      return (
        <DeleteDocumentWithReasonModal
          type={selectedItem?.selfUpdate ? selectedItem.type : type}
          isVisible={primaryModal === MODAL_DELETE}
          count={count}
          selectedItemName={label}
          fetchDocumentsCount={this.getDocumentCountCall}
          hideModal={this.handleHideModal}
          onConfirmDelete={this.handleOnConfirmDelete}
        />
      );
    }

    let modalTitle;
    let modalText;
    if (selectedItems.length) {
      modalTitle = 'Delete Items?';
      modalText = this.renderBulkDeleteModalText();
    } else {
      modalTitle = `Delete ${selectedItem.type}?`;
      modalText =
        selectedItem.type === 'document'
          ? this.renderDeleteDocumentModalText()
          : this.renderDeleteFolderModalText();
    }

    return (
      <EcModal
        modalType={MODAL_DELETE}
        width="560px"
        title={modalTitle}
        overLimit={itemsCount > BULK_DELETE_LIMIT}
        fetchDocumentsCount={this.getDocumentCountCall}
        text={modalText}
        confirmButtonText="Delete"
        deleteItem={this.onBulkDelete}
        hideModal={this.handleHideModal}
      />
    );
  }

  renderFolderTreeBulkModal() {
    const {
      folderTree,
      uploadLocation,
      modalBulkAction,
      selectedItems,
      selectedItem,
      isAllSelected,
      folderData,
    } = this.state;
    const { flags } = this.props;
    const bulkAction = modalBulkAction ?? selectedItem.action;
    const modalTitle = `${bulkAction} to Folder`;

    // Prevent selection of sync_paired folders iff the selectedItem(s) all contain sync pairs
    const onlySyncPairedItemsSelected =
      selectedItem?.contains_sync_pair ||
      (selectedItems.every((item) => item.contains_sync_pair) &&
        selectedItems.length);
    const disableSyncPaired =
      bulkAction === 'Move' && onlySyncPairedItemsSelected;
    const limitCountPerActionType =
      bulkAction === 'Move' ? BULK_MOVE_LIMIT : BULK_COPY_LIMIT;
    const itemsCount = isAllSelected ? folderData.count : selectedItems?.length;

    const hasSearchFolderPanel = flags[FlagType.SearchFolderPanel];

    return (
      <EcModal
        modalType={MODAL_FOLDER_TREE}
        bulkAction={bulkAction}
        overLimit={itemsCount > limitCountPerActionType}
        width="540px"
        title={modalTitle}
        folderTree={folderTree}
        folderIdsSelected={[]}
        fetchDocumentsCount={this.getDocumentCountCall}
        uploadLocation={uploadLocation}
        handleUploadLocationChange={this.onBulkMoveCopy}
        confirmButtonText={bulkAction}
        hideModal={this.handleHideModal}
        disableSyncPaired={disableSyncPaired}
        enableSearch={hasSearchFolderPanel}
      />
    );
  }

  renderFolderTreeUploadModal() {
    const { uploadLocation } = this.state;
    const { flags } = this.props;
    const hasSearchFolderPanel = flags[FlagType.SearchFolderPanel];

    return (
      <EcModal
        modalType={MODAL_FOLDER_TREE}
        width="540px"
        title="Choose Location"
        uploadLocation={uploadLocation}
        handleUploadLocationChange={this.handleUploadLocationChange}
        confirmButtonIcon={<CheckmarkIcon size="20" />}
        confirmButtonText="Choose Location"
        hideModal={this.handleHideSecondaryModal}
        enableSearch={hasSearchFolderPanel}
      />
    );
  }

  renderUploadModal() {
    const { uploadFiles, uploadLocation, hasUploadPermission } = this.state;
    const { currentUser, uploadDocuments } = this.props;

    const extendedUploadFiles = uploadFiles.map((file) => {
      file.folderId = uploadLocation.id;
      return file;
    });

    return (
      <EcModal
        key="primary-modal"
        modalType={MODAL_UPLOAD}
        width="755px"
        title="Upload"
        uploadLocationName={uploadLocation.name}
        uploadLocationPath={uploadLocation.path}
        hasUploadPermission={hasUploadPermission}
        uploadFiles={uploadFiles}
        confirmButtonText="Upload Documents"
        handleAddUploadFiles={this.handleAddUploadFiles}
        handleRemoveUploadFile={this.handleRemoveUploadFile}
        hideModal={this.handleHideModal}
        handleUploadFiles={(formFields) => {
          trackSegment('User Uploads Document', {
            fileNames: extendedUploadFiles.map((file) => file.name),
            groupId: getClientInfo(currentUser),
            userId: getUserClientInfo(currentUser),
          });
          (async () => {
            await uploadDocuments({
              files: extendedUploadFiles,
              uploadFormFields: formFields,
              apiConfig: {
                service: 'pilot',
                method: 'post',
                url: '/document/upload/',
              },
            });

            this.loadFolderData();
          })();
        }}
        showSecondaryModal={this.handleShowSecondaryModal}
      />
    );
  }

  handleDepartmentChange(departmentsSelected) {
    this.setState({ departmentsSelected });
  }

  renderDepartmentTreeModal() {
    const {
      currentUser: { client },
    } = this.props;
    const { selectedItem, departmentsSelected } = this.state;
    const deptsSelected =
      departmentsSelected ||
      selectedItem?.original?.folder_access_list?.departments;

    return (
      <EcModal
        modalType={MODAL_DEPARTMENT_TREE}
        width="540px"
        client={client}
        title="Select a Department"
        departmentsSelected={deptsSelected}
        handleDepartmentChange={this.handleDepartmentChange}
        confirmButtonIcon={<CheckmarkIcon size="20" />}
        confirmButtonText="Select Department"
        hideModal={this.handleHideSecondaryModal}
      />
    );
  }

  renderMultiEditModal() {
    const { folderData, selectedItems } = this.state;
    const [documents, folders, select_all] = this.setBulkParams();
    const itemsCount = select_all ? folderData.count : selectedItems.length;
    return (
      <EcModal
        modalType={MODAL_MULTI_EDIT}
        width="800px"
        hideModal={this.handleHideModal}
        fetchDocumentsCount={this.getDocumentCountCall}
        getModalData={() =>
          getMultiEditData(folderData.id, documents, folders, select_all)
        }
        overLimit={itemsCount > BULK_EDIT_LIMIT}
        update={(fieldsData) =>
          bulkUpdate(folderData.id, {
            documentFields: fieldsData,
            select_all,
            documents,
            folders,
          })
        }
        onUpdateComplete={() => this.updatePageAfterBulkAction(documents)}
      />
    );
  }

  shouldRenderLoadingState = () => {
    const { flags } = this.props;
    const { isLoading, isFetching } = this.state;
    return (!flags[FlagType.DocumentsTableRevamp] && isFetching) || isLoading;
  };

  render() {
    const { history, match, classes, currentUser } = this.props;
    const {
      folderData,
      primaryModal,
      secondaryModal,
      isLoading,
      errorLoadingMessage,
      canModifyFolder,
    } = this.state;
    return (
      <div className={classes.documentsPage}>
        <DocumentsPageHeader
          handleShowModal={this.handleShowModal}
          handleAddUploadFiles={this.handleAddUploadFiles}
          handleSort={this.handleSortOnChange}
          history={history}
          folderPathId={match.params.folderId}
          user={currentUser}
          folderData={folderData}
          loading={isLoading}
          canModifyFolder={canModifyFolder}
        />
        {this.shouldRenderLoadingState() && !errorLoadingMessage ? (
          <div className={classes.loadingContainer}>
            <LoadingSpinner size="medium" />
          </div>
        ) : !isLoading && errorLoadingMessage ? (
          <div className={classes.errorLoadingWrapper}>
            <div className={classes.errorLoadingMessage}>
              An error occurred when loading the documents.
            </div>
            <EcButton
              text="Reload Page"
              iconLeft={<ReloadIcon />}
              onClick={this.reloadPage}
            />
          </div>
        ) : !isLoading &&
          folderData &&
          folderData.results &&
          folderData.results.length ? (
          this.renderFolderContent()
        ) : (
          this.renderEmptyFolder()
        )}
        {primaryModal === MODAL_ACCESS_SETTINGS
          ? this.renderAccessSettingsModal()
          : null}
        {primaryModal === MODAL_NAME ? this.renderNameModal() : null}
        {primaryModal === MODAL_DOCUMENT_GROUP
          ? this.renderAddToGroupModal()
          : null}
        {primaryModal === MODAL_DELETE ? this.renderDeleteModal() : null}
        {primaryModal === MODAL_UPLOAD ? this.renderUploadModal() : null}
        {primaryModal === MODAL_FOLDER_TREE
          ? this.renderFolderTreeBulkModal()
          : null}
        {primaryModal === MODAL_MULTI_EDIT ? this.renderMultiEditModal() : null}
        {primaryModal === MODAL_DOCUMENTS_COLUMN_FIELDS
          ? this.renderDocumentsColumnFieldsModal()
          : null}
        {primaryModal === MODAL_DOCUMENTS_COLUMN_FIELDS_SAVE
          ? this.renderDocumentsColumnFieldsSaveModal()
          : null}
        {primaryModal === MODAL_DOCUMENTS_COLUMN_FIELDS_VIEW_SWITCH
          ? this.renderDocumentsColumnFieldsViewSwitchModal()
          : null}
        {secondaryModal === MODAL_FOLDER_TREE
          ? this.renderFolderTreeUploadModal()
          : null}
        {secondaryModal === MODAL_DEPARTMENT_TREE
          ? this.renderDepartmentTreeModal()
          : null}
      </div>
    );
  }
}

const mapStateToProps = ({ currentUser }) => ({
  currentUser,
});

Page = connect(mapStateToProps, {
  uploadDocuments,
  initContext,
})(Page);

export default injectSheet(styles)(withFlags(Page));
