import React, { useEffect, useMemo, useState } from 'react';

import { trackSegment } from '~/components/SegmentAnalytics';
import { showToast } from '~/components/Shared/EcToast';
import {
  FormField,
  INPUT_DEBOUNCE_MS,
  Layout,
  Modal,
  pluralize,
  Select,
  StatusMessage,
  Text,
  useDebounce,
} from '~/eds';
import { useCurrentUser, usePermission } from '~/hooks';
import { api } from '~/redux';
import { RoleAttributes } from '~/redux/api/methods';
import { ParsedType } from '~/redux/api/transformers';
import { Nullable } from '~/types';
import { ERROR, SUCCESS } from '~/types/toast.types';

type Props = {
  role: RoleAttributes;
  isVisible: boolean;
  isDefaultRole?: boolean;
  clientId?: string;
  onClose: () => void;
};

export const DeleteRoleModal = ({
  role,
  isVisible,
  isDefaultRole,
  clientId,
  onClose,
}: Props) => {
  const user = useCurrentUser();

  const [deleteRole, deleteRoleResult] = api.endpoints.deleteRole.useMutation();
  const { isError, isLoading, isSuccess, error } = deleteRoleResult;
  const [
    getRoles,
    { isFetching: isFetchingRoles, data: roles, isLoading: isLoadingRoles },
  ] = api.endpoints.getRolesSimple.useLazyQuery();

  const [selectedRole, setSelectedRole] = useState<Nullable<string>>('');
  const [defaultRole, setDefaultRole] = useState<Nullable<string>>('');

  const {
    hasPermission: hasSSOPermission,
    isLoading: isLoadingSSOPermission,
  } = usePermission({
    permission: { resourceId: 'sso', resourceType: 'edit' },
  });

  const {
    hasPermission: hasUsersManagePermission,
    isLoading: isLoadingUsersManagePermission,
  } = usePermission({
    permission: { resourceId: 'users', resourceType: 'edit' },
  });

  const roleOptions = useMemo(
    () =>
      roles?.results
        .filter((roleItem) => roleItem.id !== role.id)
        .map(roleAsSelectOption) || [],
    [roles?.results, role],
  );

  const handleSearchRole = useDebounce((search: string) => {
    getRoles({
      page: 1,
      pageSize: 100,
      name: search,
      client_id: clientId,
    });
  }, INPUT_DEBOUNCE_MS);

  const shouldShowNoSSOPermissionWarning =
    isDefaultRole && !hasSSOPermission && !isLoadingSSOPermission;

  const shouldShowManageUsersPermissionWarning =
    !hasUsersManagePermission && !isLoadingUsersManagePermission && role.users;

  const isButtonDisabled = useMemo(() => {
    if (isDefaultRole && !hasSSOPermission) {
      return true;
    }

    if (role.users && isDefaultRole) {
      return !selectedRole || !defaultRole;
    }
    if (role.users) {
      return !selectedRole;
    }
    if (isDefaultRole) {
      return !defaultRole;
    }

    return false;
  }, [role, isDefaultRole, selectedRole, defaultRole]);

  const noUsersManagePermissionWarningText =
    'This role cannot be deleted because it is currently assigned to active users. Please contact an Admin user with permissions to manage user roles to reassign active users to another role before deleting this role.';

  const tooltipText = useMemo(() => {
    if (shouldShowManageUsersPermissionWarning) {
      return noUsersManagePermissionWarningText;
    }

    if (role.users && isDefaultRole) {
      return 'Select a replacement role for the SSO default role and the users that are currently assigned to this role.';
    }
    if (role.users) {
      return 'Select a replacement role for the users that are currently assigned to this role.';
    }
    if (isDefaultRole) {
      return 'Select a replacement role for the SSO default role.';
    }

    return '';
  }, [role, isDefaultRole, selectedRole, defaultRole]);

  useEffect(() => {
    if (isSuccess) {
      showToast(SUCCESS, 'Role deleted successfully.');
      onClose();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      const resp = error?.response || {};
      const msg = resp.data?.errors?.[0];
      showToast(
        ERROR,
        msg?.title || 'An error occurred while deleting the role.',
      );
    }
  }, [isError]);

  useEffect(() => {
    getRoles({
      page: 1,
      pageSize: 100,
      client_id: clientId,
    });
  }, []);

  return (
    <Modal
      isVisible={isVisible}
      title={`Delete ${role.name}`}
      onCancel={onClose}
      onHide={onClose}
      primaryAction={{
        text: 'Delete',
        onClick: () => {
          trackSegment('submitDeleteRole', {
            id: role.id,
            name: role.name,
            user_id: user.id,
            client_id: user.client,
          });
          deleteRole({
            roleId: role.id,
            newRoleId: selectedRole || undefined,
            defaultRoleId: defaultRole || undefined,
          });
        },
        isLoading:
          isLoading ||
          isLoadingRoles ||
          isLoadingSSOPermission ||
          isLoadingUsersManagePermission,
        disabled: isButtonDisabled,
        tooltip: isButtonDisabled ? tooltipText : undefined,
        variant: 'danger',
      }}
    >
      {role.users ? (
        <>
          {isDefaultRole ? (
            <Layout direction="column" spacing="1">
              <Text>
                The <strong>{role.name} </strong>role is assigned to{' '}
                {pluralize(role.users, 'user')} and is the default role for new
                users signing in with SSO. Before permanently deleting it, you
                need to:
              </Text>
              <Text>
                1. Select a replacement role for the{' '}
                {pluralize(role.users, 'user')}
              </Text>
              <Text>
                2. Select a replacement default role for new users signing in
                with SSO
              </Text>
            </Layout>
          ) : (
            <Text>
              Select a new role for the {role.users} users that are currently
              assigned to <strong>{role.name}</strong>. Deleting a role is
              permanent.
            </Text>
          )}

          <Layout direction="column" spacing="1" mt={6}>
            <FormField
              label="User role"
              description={`Select a replacement role for the ${pluralize(
                role.users,
                'user',
              )}.`}
              tooltip={
                shouldShowManageUsersPermissionWarning
                  ? noUsersManagePermissionWarningText
                  : undefined
              }
              input={Select}
              inputProps={{
                onSearch: handleSearchRole,
                isMulti: false,
                isSearchable: true,
                options: roleOptions,
                isLoading: isFetchingRoles,
              }}
              placeholder="Select a role..."
              name="User role"
              value={
                shouldShowManageUsersPermissionWarning
                  ? undefined
                  : selectedRole
              }
              onChange={(value) => setSelectedRole(value as string)}
              disabled={!!shouldShowManageUsersPermissionWarning}
            />
          </Layout>
          {isDefaultRole && (
            <Layout
              direction="column"
              spacing="1"
              mt={4}
              aria-labelledby="sso-default-label"
            >
              <Text variant="body-bold" id="sso-default-label">
                SSO default role
              </Text>

              <Text color="text.secondary">
                Select a replacement role for new users signing in with SSO.
              </Text>
              <Select
                placeholder="Select a role..."
                isLoading={isFetchingRoles}
                options={roleOptions}
                name="SSO default role"
                value={defaultRole}
                onChange={setDefaultRole}
                onSearch={handleSearchRole}
                isMulti={false}
                isSearchable={true}
                disabled={!!shouldShowNoSSOPermissionWarning}
              />
            </Layout>
          )}
        </>
      ) : (
        <>
          {isDefaultRole ? (
            <Layout direction="column" spacing="1">
              <Text>
                The <strong>{role.name} </strong>role is currently the default
                role for new users signing in with SSO. Select a replacement
                role for new users signing in with SSO. Deleting a role is
                permanent and it cannot be undone.
              </Text>

              <Layout
                direction="column"
                spacing="1"
                mt={6}
                aria-labelledby="sso-default-label"
              >
                <Text variant="body-bold" id="sso-default-label">
                  SSO default role
                </Text>
                <Text color="text.secondary">
                  Select a replacement role for new users signing in with SSO.
                </Text>
                <Select
                  placeholder="Select a role..."
                  isLoading={isFetchingRoles}
                  options={roleOptions}
                  name="SSO default role"
                  value={defaultRole}
                  onChange={setDefaultRole}
                  onSearch={handleSearchRole}
                  isMulti={false}
                  isSearchable={true}
                  disabled={!!shouldShowNoSSOPermissionWarning}
                />
              </Layout>
            </Layout>
          ) : (
            <Text>
              Are you sure you want to permanently delete{' '}
              <strong>{role.name}</strong>?
            </Text>
          )}
        </>
      )}
      {shouldShowNoSSOPermissionWarning && (
        <Layout mt={2}>
          <StatusMessage
            status="warning"
            message="This role cannot be deleted because it is configured as the SSO default role. Please contact an Admin user with  permissions to the SSO setting page to edit this configuration before deleting this role."
          />
        </Layout>
      )}
    </Modal>
  );
};

const roleAsSelectOption = (role: ParsedType<RoleAttributes>) => ({
  label: role.name,
  value: role.id,
  description: role.roleType,
});
