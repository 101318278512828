import React, { useEffect } from 'react';

import { trackSegment } from '~/components/SegmentAnalytics';
import { getUserName, Modal, Text, useToast } from '~/eds';
import { useCurrentUser } from '~/hooks';
import { api } from '~/redux';
import { UserAttributes } from '~/redux/api/methods';
import { ParsedType } from '~/redux/api/transformers';
import { Nullable } from '~/types';

type Props = {
  user: Nullable<ParsedType<UserAttributes>>;
  isVisible: boolean;
  onHide: () => void;
};

export const DeactivateUserModal = ({ user, isVisible, onHide }: Props) => {
  const [
    deactivateUser,
    deactivateUserResult,
  ] = api.endpoints.deactivateSuperAdmin.useMutation();
  const { toast } = useToast();
  const { isError, isLoading, isSuccess, error } = deactivateUserResult;
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (isSuccess) {
      toast({
        message: `“${user?.firstName} ${user?.lastName}”  deactivated.`,
        status: 'success',
      });
      onHide();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      const resp = error?.response || {};
      const msg = resp.data?.errors?.[0];
      toast({
        message: msg?.title || 'An error occurred while deleting the user.',
        status: 'danger',
      });
    }
  }, [isError]);

  return (
    <Modal
      isVisible={isVisible}
      title="Deactivate User?"
      onCancel={onHide}
      onHide={onHide}
      primaryAction={{
        text: 'Deactivate',
        onClick: () => {
          trackSegment('submitDeleteUser', {
            id: user!.id,
            name: getUserName(user!),
            user_id: currentUser.id,
            client_id: currentUser.client,
          });
          deactivateUser({ superAdminId: user!.id });
        },
        isLoading,
        variant: 'danger',
      }}
    >
      <Text>
        Are you sure you want to deactivate “{user?.firstName} {user?.lastName}
        ”? Users can be reactivated at any time on the Users page.
      </Text>
    </Modal>
  );
};
