import React from 'react';
import { connect } from 'react-redux';

import { getPhases } from '~/reducers/ticketReviewer';
import { FlexLayout } from '~/ui';

import ReviewPhase from './ReviewPhase';
import ViewTypeToggle from './ViewTypeToggle';

function Sidebar({
  // connected
  phases,
}) {
  return (
    <FlexLayout flex="1 1 auto" flexDirection="column">
      <ViewTypeToggle />
      {phases.map(({ id, isCollapsed, isDisabled }) => (
        <ReviewPhase
          key={id}
          isCollapsed={isCollapsed}
          isDisabled={isDisabled}
          phaseId={id}
        />
      ))}
    </FlexLayout>
  );
}

const mapStateToProps = ({ ticketReviewer }) => ({
  phases: getPhases(ticketReviewer),
});

export default connect(mapStateToProps)(Sidebar);
