import { aegis, pilot } from '~/services';

export const createSAMLConfig = ({ clientId, data }) => {
  return pilot.post(`/client/${clientId}/sso/saml/`, data);
};

export const fetchSAMLConfigs = ({ clientId }) => {
  return pilot.get(`/client/${clientId}/sso/saml/`);
};

export const fetchSAMLConfig = ({ clientId, configId }) => {
  return pilot.get(`/client/${clientId}/sso/saml/${configId}/`);
};

export const updateSAMLConfig = ({ clientId, configId, data }) => {
  return pilot.patch(`/client/${clientId}/sso/saml/${configId}/`, data);
};

export const deleteSAMLConfig = ({ clientId, configId }) => {
  return pilot.remove(`/client/${clientId}/sso/saml/${configId}/`);
};

export const fetchSAMLProvider = ({ clientName }) => {
  return aegis.get(`/auth/saml-info?client=${clientName}`);
};

export const fetchSAMLData = async ({
  clientId,
  clientName,
  configId,
  isEditing,
}) => {
  const config = isEditing ? await fetchSAMLConfig({ clientId, configId }) : {};
  const provider = await fetchSAMLProvider({ clientName });

  const providerId = config.sp_id ? config.sp_id : provider.sp_id;
  return { ...provider, ...config, sp_id: providerId };
};
