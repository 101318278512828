import React, { memo, useEffect, useState } from 'react';
import { useStyles } from 'uinix-ui';

import { isDev } from '../../../../dev';
import {
  Box,
  Button,
  Divider,
  EmailInput,
  FormField,
  Layout,
  Logo,
  PasswordInput,
  StatusMessage,
  Text,
  TextInput,
} from '../../components';
import loginForgotPasswordImageSrc from '../../tokens/images/login-forgot-password.png';
import loginSignInImageSrc from '../../tokens/images/login-sign-in.png';
import loginWorkspaceImageSrc from '../../tokens/images/login-workspace.png';
import { Nullable, Queryable } from '../../types';
import { testIsEmailValid } from '../../utils';
import { Account, Sso, Stage } from './types';
import { testCanLogin } from './utils';

interface Props {
  /** Query when workspace is changed */
  queryChangeWorkspace: Queryable<(workspace: string) => void>;
  /** Query when logging in with account */
  queryLoginAccount: Queryable<(account: Account) => void>;
  /** Query when logging in with SSO provider */
  queryLoginSso: Queryable<(sso: Sso) => void>;
  /** Query when password reset is sent */
  queryResetPassword: Queryable<
    ({ email, workspace }: { email: string; workspace: string }) => void
  >;
  /** Callback when returning to sign in stage */
  onBackToSignIn: () => void;
  /** Callback to resolve login sso error */
  onResolveLoginSsoError: () => void;
  /** Specify a valid SSO provider */
  sso: Nullable<Sso>;
  /** Renders only SSO login elements */
  isSsoOnly?: boolean;
  /** Controlled stage */
  stage?: Stage;
  /** Customize the welcome banner */
  welcomeBanner?: {
    banner?: React.ReactNode;
    bannerImageBackgroundSize?: string;
    bannerImagePosition?: string;
    bannerImageSrc?: string;
    padding?: number;
  };
  /** Controlled workspace */
  workspace?: string;
}

export const LoginPage = memo(
  ({
    isSsoOnly,
    queryChangeWorkspace,
    queryLoginAccount,
    queryLoginSso,
    queryResetPassword,
    onBackToSignIn,
    onResolveLoginSsoError,
    sso,
    stage: initialStage = 'workspace',
    welcomeBanner,
    workspace: initialWorkspace = '',
  }: Props) => {
    const [email, setEmail] = useState<Nullable<string>>('');
    const [password, setPassword] = useState<Nullable<string>>('');
    const [workspace, setWorkspace] = useState<Nullable<string>>(
      initialWorkspace,
    );
    const [stage, setStage] = useState<Stage>(initialStage);

    const styles = useStyles();

    useEffect(() => setStage(initialStage), [initialStage]); // sync state from props

    const [
      onChangeWorkspace,
      { isFetching: isLoadingChangeWorkspace },
    ] = queryChangeWorkspace;
    const [
      onLoginAccount,
      { isFetching: isLoadingLoginAccount, error: errorLoginAccount },
    ] = queryLoginAccount;
    const [
      onLoginSso,
      { error: errorLoginSso, isFetching: isLoadingLoginSso },
    ] = queryLoginSso;
    const [
      onResetPassword,
      { isFetching: isLoadingResetPassword, isSuccess: isSuccessResetPassword },
    ] = queryResetPassword;

    const resetLogin = () => {
      setEmail('');
      setPassword('');
    };

    let title;
    let description;
    let contents;
    let banner;
    let bannerAlign;
    let bannerImageBackgroundSize;
    let bannerImageSrc;
    let bannerImagePosition;
    let handleSubmit: () => void;
    switch (stage) {
      case 'login': {
        title = 'Sign in to your Workspace';
        description = (
          <>
            Enter your credentials to sign in to the{' '}
            <strong>{workspace}</strong> Workspace.
          </>
        );
        contents = (
          <>
            {sso && (
              <Layout direction="column" spacing={8}>
                {errorLoginSso && (
                  <StatusMessage
                    action={{
                      icon: 'clipboard',
                      text: 'Copy',
                      onClick: onResolveLoginSsoError,
                    }}
                    status="danger"
                    message={errorLoginSso}
                  />
                )}
                <Button
                  isLoading={isLoadingLoginSso}
                  text={`Sign In with ${sso.name}`}
                  onClick={() => {
                    resetLogin();
                    onLoginSso(sso);
                  }}
                />
                {isSsoOnly ? null : (
                  <Divider flex="none" label="or" theme="dark" />
                )}
              </Layout>
            )}
            {errorLoginAccount && (
              <StatusMessage status="danger" message={errorLoginAccount} />
            )}
            {isSsoOnly ? null : (
              <>
                <FormField<string, false>
                  input={isDev ? TextInput : EmailInput} // TODO EKP-13758 loosen the check in local development as credentials are not valid emails (yet)
                  label="Email"
                  name="email"
                  placeholder="Type email address"
                  theme="dark"
                  value={email}
                  onChange={setEmail}
                />
                <FormField<string, false>
                  input={PasswordInput}
                  label="Password"
                  name="password"
                  placeholder="Type password"
                  theme="dark"
                  value={password}
                  width="100%"
                  onChange={setPassword}
                />
                {/* TODO EKP-13758: loosen the check in local development as credentials are not valid emails (yet) */}
                <Button
                  disabled={!testCanLogin({ email, password })}
                  isLoading={isLoadingLoginAccount}
                  text="Sign In"
                  type="submit"
                  variant="primary"
                />
                <Button
                  text="Change Workspace"
                  theme="dark"
                  variant="action"
                  onClick={() => {
                    resetLogin();
                    setWorkspace('');
                    setStage('workspace');
                  }}
                />
                <Button
                  text="Forgot Password"
                  theme="dark"
                  variant="action"
                  onClick={() => {
                    resetLogin();
                    setStage('reset-password');
                  }}
                />
              </>
            )}
          </>
        );
        banner = welcomeBanner?.banner ?? (
          <>
            <Box as="blockquote" m={0}>
              With Evisort, we spend our time doing the work that matters most
              while still keeping our promises to our customers.
            </Box>
            <Text as="cite" color="inverse.text.secondary" fontStyle="normal">
              <strong>Jonathan</strong> Sickler AE2S
            </Text>
          </>
        );
        bannerAlign = 'flex-end';
        bannerImageBackgroundSize = welcomeBanner?.bannerImageBackgroundSize;
        bannerImagePosition = welcomeBanner?.bannerImagePosition ?? 'top';
        bannerImageSrc = welcomeBanner?.bannerImageSrc ?? loginSignInImageSrc;
        handleSubmit = () => {
          if (email && password) {
            onLoginAccount({ email, password });
          }
        };
        break;
      }
      case 'reset-password': {
        title = isSuccessResetPassword
          ? 'Check your Inbox'
          : 'Reset your Password';
        description = isSuccessResetPassword
          ? null
          : 'Enter your email address to reset your password. You may need to check your spam folder or unblock evisort@evisort.com.';
        const backToSignIn = (
          <Button
            text="Sign In"
            theme="dark"
            variant="action"
            onClick={() => {
              setStage('login');
              setEmail('');
              onBackToSignIn();
            }}
          />
        );
        if (isSuccessResetPassword) {
          contents = (
            <>
              <StatusMessage
                status="success"
                message="You will receive a reset link shortly. You may need to check your spam folder or unblock evisort@evisort.com."
              />
              {backToSignIn}
            </>
          );
        } else {
          contents = (
            <>
              <FormField<string, false>
                input={EmailInput}
                label="Email"
                name="email"
                placeholder="Type email address"
                theme="dark"
                value={email}
                onChange={setEmail}
              />
              <Button
                disabled={!testIsEmailValid(email)}
                isLoading={isLoadingResetPassword}
                text="Send Password Reset Link"
                type="submit"
                variant="primary"
              />
              {backToSignIn}
            </>
          );
        }
        banner = "Missing house keys?\nLet's help you get back in…";
        bannerAlign = 'flex-start';
        bannerImagePosition = 'top';
        bannerImageSrc = loginForgotPasswordImageSrc;
        handleSubmit = () => {
          if (email && workspace) {
            onResetPassword({ email, workspace });
          }
        };
        break;
      }
      case 'workspace':
      default: {
        title = 'Sign in to your Workspace';
        description = "Enter your workspace's Evisort URL.";
        contents = (
          <>
            <Layout align="center" as="label" htmlFor="workspace">
              <Text color="inverse.text.primary">clients.evisort.com/</Text>
              <TextInput
                name="workspace"
                placeholder="your-workspace"
                value={workspace}
                width="auto"
                onChange={setWorkspace}
              />
            </Layout>
            <Button
              disabled={!workspace}
              isLoading={isLoadingChangeWorkspace}
              text="Continue"
              type="submit"
              variant="primary"
            />
          </>
        );
        banner = "New workspace…\nWho's this?";
        bannerAlign = 'flex-start';
        bannerImagePosition = 'bottom';
        bannerImageSrc = loginWorkspaceImageSrc;
        handleSubmit = () => {
          if (workspace) {
            onChangeWorkspace(workspace);
            setStage('login');
          }
        };
        break;
      }
    }

    const componentStyles = {
      bannerImage: {
        alignItems: bannerAlign,
        backgroundImage: `url(${bannerImageSrc})`,
        backgroundOrigin: 'content-box',
        backgroundPosition: `center ${bannerImagePosition}`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: bannerImageBackgroundSize,
      },
      form: {
        borderBottomLeftRadius: 'l',
        borderBottomRightRadius: ['l', 'unset'],
        borderTopLeftRadius: 'l',
        borderTopRightRadius: ['l', 'unset'],
      },
    };

    return (
      <Layout minH="container.min-height" justify="center" w="100%">
        <Layout
          align="stretch"
          aria-label={stage}
          as="form"
          bg="inverse.background.quiet"
          direction="column"
          flex="none"
          p={8}
          spacing={4}
          styles={componentStyles.form}
          w="form.width.m"
          onSubmit={(event: Event) => {
            event.preventDefault();
            handleSubmit();
          }}
        >
          <a href="/" title="Evisort login page">
            <Logo theme="dark" />
          </a>
          <Text as="h1" color="inverse.text.primary" variant="title">
            {title}
          </Text>
          <Text color="inverse.text.primary">{description}</Text>
          {contents}
        </Layout>
        {!isSsoOnly && (
          <Box
            bg="inverse.background"
            display={['none', 'flex']}
            flex="auto"
            as="aside"
            p={welcomeBanner?.padding ?? 16}
            position="relative"
            styles={[
              styles.borderRadius.containerRight,
              componentStyles.bannerImage,
            ]}
          >
            <Text
              color="inverse.text.primary"
              variant="banner"
              whiteSpace="pre-wrap"
            >
              {banner}
            </Text>
          </Box>
        )}
      </Layout>
    );
  },
);
