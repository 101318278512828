import { createSelector } from 'reselect';

const selectTicketSlice = (rootState: any): any => rootState['ticket'];

export const selectTicketId = createSelector(
  selectTicketSlice,
  (state) => state.id,
);

export const selectTicketWorkflowId = createSelector(
  selectTicketSlice,
  (state) => state.workflowId,
);
