import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';

import DeprecatedUserMultiSelect from '~/components/Client/DeprecatedUserMultiSelect';
import { formatDate, parseDate } from '~/eds';
import { FlagType, withFlags } from '~/flags';
import { withUsers } from '~/hocs';

import { MODAL_DEPARTMENT_TREE } from '../../../types/modal.types';
import { debounce } from '../../../utils/helper.utils';
import EcDataField from '../../Shared/EcDataField';
import EcDatepicker from '../../Shared/EcDatepicker';
import EcModal from '../../Shared/EcModal';
import EcMultipleSelectValue from '../../Shared/EcMultipleSelectValue';
import EcSelect from '../../Shared/EcSelect';
import CheckmarkIcon from '../../Shared/Icons/CheckmarkIcon';
import LoadingSpinner from '../../Shared/Icons/LoadingSpinner';
import PlusIcon from '../../Shared/Icons/PlusIcon';
import ReloadIcon from '../../Shared/Icons/ReloadIcon';
import { filterFields } from './AlertsFilters.config';
import styles from './AlertsFilters.styles';
import { preprocessSearchPayload } from './AlertsFilters.utils';

const activeFieldOptions = filterFields.activeField.values.map((val) => ({
  label: val.display_value,
  value: val.value,
}));

const initialFiltersState = {
  entered_name: { value: '' },
  last_sent_start_date: null,
  last_sent_end_date: null,
  upcoming_send_start_date: null,
  upcoming_send_end_date: null,
  selected_users: [],
  selected_departments: [],
  created_by: [],
  active: null,
  formExpanded: true,
  primaryModal: null,
};

class AlertsFilters extends Component {
  constructor(props) {
    super(props);

    this.handleOnResetClick = this.handleOnResetClick.bind(this);
    this.handleAddDepartmentClick = this.handleAddDepartmentClick.bind(this);
    this.handleDepartmentChange = this.handleDepartmentChange.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.debounceNameSearch = debounce(this.handleSearch, 400);
    this.state = {
      ...initialFiltersState,
    };
  }

  handleAddDepartmentClick() {
    this.setState({ primaryModal: MODAL_DEPARTMENT_TREE });
  }

  handleDepartmentChange(selected_departments) {
    this.setState({ selected_departments }, this.handleSearch);
  }

  handleHideModal() {
    this.setState({ primaryModal: null });
  }

  handleOnResetClick() {
    this.setState({ ...initialFiltersState }, () =>
      this.props.handleOnSearch({}),
    );
  }

  handleSearch() {
    const {
      loading: _loading,
      loadingError: _loadingError,
      clientsOptions: _clientsOptions,
      primaryModal: _primaryModal,
      ...filters
    } = this.state;

    const searchPayload = preprocessSearchPayload(filters);

    this.props.handleOnSearch(searchPayload);
  }

  renderDepartmentTreeModal() {
    const { currentUser } = this.props;
    const { selected_departments } = this.state;

    return (
      <EcModal
        modalType={MODAL_DEPARTMENT_TREE}
        width="540px"
        client={currentUser.client}
        title="Select a Department"
        departmentsSelected={selected_departments}
        handleDepartmentChange={this.handleDepartmentChange}
        confirmButtonIcon={<CheckmarkIcon size="20" />}
        confirmButtonText="Select Department"
        hideModal={this.handleHideModal}
      />
    );
  }

  renderRecipientsDepartments() {
    const { classes } = this.props;
    const { selected_departments } = this.state;

    if (!selected_departments.length) {
      return (
        <span className={classes.departmentsPlaceholder}>
          You haven’t selected any departments.
        </span>
      );
    }

    return selected_departments.map((department, index) => (
      <EcMultipleSelectValue
        label={department.department_name}
        key={`${department.department_name}-${index}`}
        marginBottom="8px"
        closeHandler={() => {
          const newDepartments = selected_departments.filter(
            (recDepartment) =>
              recDepartment.department_id !== department.department_id,
          );
          this.setState(
            { selected_departments: newDepartments },
            this.handleSearch,
          );
        }}
      />
    ));
  }

  renderDatepickerField(
    startDate,
    endDate,
    startDateName,
    endDateName,
    fieldTitle,
  ) {
    const { classes, flags } = this.props;
    const formattedDate = (date) => (date ? formatDate(date) : null);
    const refactorMoment = flags[FlagType.DeprecateMoment];
    return (
      <div className={classes.fieldBody}>
        <div className={classes.fieldDatepicker}>
          {endDate /* Accessibility won't work if set maxDate to null */ ? (
            <EcDatepicker
              date={startDate ? parseDate(startDate, refactorMoment) : null}
              placeholder="Select..."
              onDateChange={(date) => {
                this.setState(
                  { [startDateName]: formattedDate(date) },
                  this.handleSearch,
                );
              }}
              maxDate={parseDate(endDate, refactorMoment)}
              isClearable={true}
              title={`${fieldTitle} from date`}
            />
          ) : (
            <EcDatepicker
              date={startDate ? parseDate(startDate, refactorMoment) : null}
              placeholder="Select..."
              onDateChange={(date) => {
                this.setState(
                  { [startDateName]: formattedDate(date) },
                  this.handleSearch,
                );
              }}
              isClearable={true}
              title={`${fieldTitle} from date`}
            />
          )}
        </div>
        <span className={classes.datesSeparator}>to</span>
        <div className={classes.fieldDatepicker}>
          {startDate ? (
            <EcDatepicker
              date={endDate ? parseDate(endDate, refactorMoment) : null}
              placeholder="Select..."
              onDateChange={(date) => {
                this.setState(
                  { [endDateName]: formattedDate(date) },
                  this.handleSearch,
                );
              }}
              minDate={parseDate(startDate, refactorMoment)}
              isClearable={true}
              title={`${fieldTitle} to date`}
            />
          ) : (
            <EcDatepicker
              date={endDate ? parseDate(endDate, refactorMoment) : null}
              placeholder="Select..."
              onDateChange={(date) => {
                this.setState(
                  { [endDateName]: formattedDate(date) },
                  this.handleSearch,
                );
              }}
              isClearable={true}
              title={`${fieldTitle} to date`}
            />
          )}
        </div>
      </div>
    );
  }

  renderLoadingContainer() {
    const { classes } = this.props;
    const { loadingError } = this.state;

    let containerContent = <LoadingSpinner size="medium" />;

    if (loadingError) {
      containerContent = (
        <div className={classes.filtersErrorContainer}>
          <div className={classes.filtersErrorMessage}>
            There's been an error with loading filters data.
          </div>
          <button
            className={classes.filtersErrorAction}
            onClick={() => this.loadAlertFiltersData()}
          >
            Retry
          </button>
        </div>
      );
    }

    return <div className={classes.loadingContainer}>{containerContent}</div>;
  }

  calculateFiltersCount() {
    const {
      loading: _loading,
      loadingError: _loadingError,
      clientsOptions: _clientOptions,
      primaryModal: _primaryModal,
      ...filters
    } = this.state;
    return Object.values(filters).reduce((count, field) => {
      if (field && (field.length || field.value)) {
        return ++count;
      }
      return count;
    }, 0);
  }

  render() {
    const { classes, resultsCount } = this.props;
    const {
      entered_name,
      last_sent_start_date,
      last_sent_end_date,
      upcoming_send_start_date,
      upcoming_send_end_date,
      selected_users,
      created_by,
      active,
      loading,
      loadingError,
      primaryModal,
    } = this.state;

    const filtersCount = this.calculateFiltersCount();
    const activeFieldValue = active && {
      label: active.display_value,
      value: active.value,
    };

    return (
      <div className={classes.alertsFiltersForm} role="search">
        <div className={classes.filterFormTitleWrapper}>
          <div className={classes.formTitle}>
            Filter {!!filtersCount && <span>{filtersCount}</span>}
          </div>
          {!!filtersCount && (
            <div className={classes.formTitleMeta}>
              <span className={classes.resultsMeta}>
                {resultsCount} {pluralize('result', resultsCount)}
              </span>
              <button
                aria-haspopup="true"
                className={classes.resetMeta}
                onClick={this.handleOnResetClick}
              >
                <ReloadIcon blue size="20" />
                Reset
              </button>
            </div>
          )}
        </div>
        <div className={classes.filterFormFields}>
          {(loading || loadingError) && this.renderLoadingContainer()}
          <div className={classes.formField}>
            <EcDataField
              field={filterFields.nameField}
              fieldValue={entered_name}
              fieldPlaceholder="Filter alerts by name"
              onFieldChange={(value) => {
                this.setState({ ...value });
                this.debounceNameSearch();
              }}
            />
          </div>
          <div className={classes.formField}>
            <div className={classes.fieldTitle}>Last sent</div>
            {this.renderDatepickerField(
              last_sent_start_date,
              last_sent_end_date,
              'last_sent_start_date',
              'last_sent_end_date',
              'Last sent',
            )}
          </div>
          <div className={classes.formField}>
            <div className={classes.fieldTitle}>Next send</div>
            {this.renderDatepickerField(
              upcoming_send_start_date,
              upcoming_send_end_date,
              'upcoming_send_start_date',
              'upcoming_send_end_date',
              'Next send',
            )}
          </div>
          {!loading && (
            <Fragment>
              <div className={classes.formField}>
                <div className={classes.fieldTitle}>Recipients (Users)</div>
                <DeprecatedUserMultiSelect
                  ariaLabel="recipients"
                  width="280px"
                  selectedUserIds={selected_users}
                  onChange={(selectedUserIds) =>
                    this.setState(
                      { selected_users: selectedUserIds },
                      this.handleSearch,
                    )
                  }
                />
              </div>
              <div className={classes.formField}>
                <div className={classes.fieldTitle}>
                  Recipients (Departments)
                </div>
                <div className={classes.fieldBody}>
                  {this.renderRecipientsDepartments()}
                </div>
                <div className={classes.fieldActions}>
                  <button
                    aria-haspopup="true"
                    className={classes.fieldAction}
                    onClick={this.handleAddDepartmentClick}
                  >
                    <PlusIcon isActive size="20" /> Add a Department
                  </button>
                </div>
              </div>
              <div className={classes.formField}>
                <div className={classes.fieldTitle}>Created By</div>
                <DeprecatedUserMultiSelect
                  ariaLabel="created by"
                  width="280px"
                  selectedUserIds={created_by}
                  onChange={(selectedUserIds) =>
                    this.setState(
                      { created_by: selectedUserIds },
                      this.handleSearch,
                    )
                  }
                />
              </div>
            </Fragment>
          )}
          <div className={classes.formField}>
            <EcSelect
              aria-label="alert status active or inactive"
              value={activeFieldValue}
              options={activeFieldOptions}
              onChange={(newValue) => {
                const newState = newValue
                  ? { display_value: newValue.label, value: newValue.value }
                  : null;
                this.setState({ active: newState }, this.handleSearch);
              }}
              placeholder="Active and Inactive"
              isClearable
            />
          </div>
        </div>
        {primaryModal === MODAL_DEPARTMENT_TREE &&
          this.renderDepartmentTreeModal()}
      </div>
    );
  }
}

AlertsFilters.propTypes = {
  collapsed: PropTypes.bool,
  handleOnSearch: PropTypes.func.isRequired,
  resultsCount: PropTypes.number,
};

const mapStateToProps = ({ currentUser }) => ({ currentUser });

AlertsFilters = withFlags(withUsers(connect(mapStateToProps)(AlertsFilters)));

export default injectSheet(styles)(AlertsFilters);
