import arrayMove from 'array-move';
import React from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

import { black3 } from '~/assets/shared-styles/general';
import ReorderIcon from '~/components/Shared/Icons/ReorderIcon';
import { User } from '~/components/Shared/User';
import { Chip, IconButton, Layout } from '~/eds';
import { TicketStatusType } from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { testIsCounterpartySigner } from '~/utils/ticket';
import { externalUserToUser } from '~/utils/user';

import { UserInfo } from '../TicketUserItem';

const SortRecipients = ({
  recipients,
  approvals,
  onSort,
  onDelete,
  judgments,
  stage,
}) => {
  const hasCCRecipientsFlag = useFlag(FlagType.CCRecipients);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const sortedRecipients = arrayMove(recipients, oldIndex, newIndex);
    onSort(sortedRecipients);
  };

  const renderSortableItems = () => {
    const DragHandle = SortableHandle(() => <ReorderIcon color={black3} />);
    const SortableList = SortableContainer(({ items }) => {
      return (
        <Layout direction="column" spacing={2} role="menu">
          {items.map((value, index) => {
            const approvalItem =
              value.approvalIds && value.approvalIds.length
                ? approvals[value.approvalIds[0]]
                : null;
            return (
              <SortableItem
                key={value.id}
                index={index}
                recipient={value}
                approvalItem={approvalItem}
              />
            );
          })}
        </Layout>
      );
    });

    const SortableItem = SortableElement(
      ({ approvalItem, recipient, index }) => {
        let judgment = null;
        let isCounterpartySigner = false;
        let userId;
        let externalUser;
        let isCarbonCopy = true;
        if (approvalItem) {
          const {
            judgmentId,
            status,
            userId: approvalItemUserId,
            externalUser: approvalItemExternalUser,
          } = approvalItem;
          judgment = judgments[judgmentId];
          isCounterpartySigner =
            status === TicketStatusType.SignatureRequestNotSent
              ? testIsCounterpartySigner(judgment)
              : approvalItemUserId === null;
          isCarbonCopy = status === TicketStatusType.NotApplicable;
          userId = approvalItem.userId;
          externalUser = approvalItemExternalUser
            ? externalUserToUser(approvalItemExternalUser)
            : undefined;
        } else {
          // If approvalItem is null, it means the user has just been added as CC recipient.
          const { name, email, userId: recipientUserId } = recipient;
          userId = recipientUserId;
          externalUser = !userId
            ? externalUserToUser({ name, email })
            : undefined;
        }
        return (
          <Layout
            key={index}
            border="border"
            borderRadius={4}
            p={2}
            role="menuitem"
            tabIndex={0}
          >
            <Layout alignSelf="center" styles={{ cursor: 'row-resize' }}>
              <DragHandle />
            </Layout>
            {hasCCRecipientsFlag ? (
              <Layout justify="space-between" w="100%">
                <span>
                  <Layout align="center" spacing={4} p={2}>
                    <User
                      id={userId}
                      user={externalUser}
                      placeholder="Unassigned Signer"
                      mode="info"
                    />
                    {isCarbonCopy && <Chip text="CC" variant="info" />}
                    {isCounterpartySigner && (
                      <Chip text="Counterparty Signer" variant="info" />
                    )}
                  </Layout>
                </span>
                {isCarbonCopy && (
                  <IconButton
                    icon="trash"
                    onClick={() => {
                      onDelete(index);
                    }}
                  />
                )}
              </Layout>
            ) : (
              <UserInfo
                isCounterpartySigner={isCounterpartySigner}
                userId={userId}
                externalUser={externalUser}
                currentStage={stage}
              />
            )}
          </Layout>
        );
      },
    );

    return (
      <SortableList distance={1} items={recipients} onSortEnd={onSortEnd} />
    );
  };

  return <div>{renderSortableItems()}</div>;
};

export default SortRecipients;
