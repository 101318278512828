import { noop } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackSegment } from '~/components/SegmentAnalytics';
import { RETRY_ERROR } from '~/constants/errors';
import { EVISORT_AI } from '~/constants/users';
import { types, useToast } from '~/eds';
import { HttpStatusCodeType } from '~/enums';
import { PROMPT_WORD_LIMIT } from '~/features/x-ray';
import { FlagType, useFlag } from '~/flags';
import { useCurrentUser } from '~/hooks';
import { api, selectors } from '~/redux';
import {
  mapUserRatingFromFeedbackDetails,
  MessageStreamEvent,
  parseServerMessage,
} from '~/redux/api/transformers';
import { chatbotSlice } from '~/redux/slices/chatbot';
import {
  Entity,
  EntityContext,
  EntityContextItem,
  EntityType,
  Nullable,
  Uuid,
} from '~/types';

import { Chat } from '../chat';
import { useConversationPipeline } from '../hooks';
import { Footer } from './Footer';
import { useGetMessageActions, useLegalDisclaimer, useSse } from './hooks';
import { NewConversation } from './NewConversation';
import type {
  FeedbackDetails,
  InlineSource,
  MessageAction,
  MessageSource,
  MessageType,
} from './types';
import {
  getStreamEndpoint,
  getUserTheme,
  parseMarkdownAndExtractInlineSources,
  parseStreamEndMessage,
  replyAiMessage,
  replyUserMessage,
  SSE_REQUEST_PROPS,
  STATIC_CONTEXT_ACTIONS,
  testIsInlineSource,
} from './utils';

export interface Props {
  entity: Entity<EntityType>;
  context?: EntityContext;
  selectedContext?: Nullable<EntityContextItem>;
  hasAskAnything?: boolean;
  onDisclaimerChange?: (hasAccepted: boolean) => void;
  onSelectContextFilter?: (context: Nullable<EntityContextItem>) => void;
  onSelectMessageSource: (message: MessageType, sourceIndex: number) => void;
  shouldDisableSources?: (message: MessageType) => boolean;
  panelProps?: types.SharedPanelProps;
  userMessageActions?: ({
    resolveIcon?: (message: MessageType) => types.IconType;
  } & MessageAction)[];
}

export const ChatBot = ({
  entity,
  context,
  selectedContext,
  hasAskAnything = false,
  panelProps,
  userMessageActions,
  onDisclaimerChange,
  onSelectContextFilter,
  onSelectMessageSource,
  shouldDisableSources,
}: Props) => {
  const isInlineSourcesEnabled = useFlag(
    FlagType.AskAnythingMessageLinksInSources,
  );
  const hasConversationalAIV2 = useFlag(FlagType.ConversationalAIV2);
  const { createSseConnection } = useSse();
  const { isActive, toast } = useToast();
  let toastId: string | number = '';
  let toastIndex = 0;
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [streamMessage, setStreamMessage] = useState<
    types.Nullable<MessageType>
  >(null);
  const [isStreaming, setIsStreaming] = useState(false);

  const currentUser = useCurrentUser();

  const isEntityDocumentVersion = entity.type === 'document_version';

  const messageActions = useGetMessageActions();

  // Since this component manages fetching conversation data, it should also dispatch the current conversation ID to the store.
  const dispatch = useDispatch();
  const currentConversationId = useSelector(
    selectors.selectCurrentConversationId,
  );
  const {
    data: conversations,
    isLoading: isLoadingConversations,
    isError: isErrorConversations,
    error: errorConversations,
    isSuccess: isSuccessGetConversations,
    refetch: refetchGetConversations,
  } = api.endpoints.getConversations.useQuery(entity);

  const [
    createConversation,
    {
      isLoading: isLoadingCreateConversation,
      isError: isErrorCreateConversation,
    },
  ] = api.endpoints.createConversation.useMutation();

  const [
    getConversation,
    {
      isLoading: isLoadingGetConversation,
      isError: isErrorGetConversation,
      isUninitialized: isUninitializedGetConversation,
    },
  ] = api.endpoints.getConversation.useLazyQuery();

  const [
    postUserMessage,
    { isLoading: isLoadingSendMessage },
  ] = api.endpoints.postUserMessage.useMutation();

  const [
    interruptAiMessage,
    {
      isLoading: isInterruptingAiMessage,
      isSuccess: isInterruptingAiMessageSuccess,
    },
  ] = api.endpoints.interruptAiMessage.useMutation();

  const [postUserFeedback] = api.endpoints.postUserFeedback.useMutation();

  const hasAcceptedLegalDisclaimer =
    errorConversations?.request.status !==
    HttpStatusCodeType.UnavailableForLegalReasons;

  const isLoadingRenderData =
    isSuccessGetConversations && isUninitializedGetConversation;

  const isLoadingConversation =
    isLoadingConversations ||
    isLoadingCreateConversation ||
    isLoadingGetConversation ||
    isLoadingRenderData;

  const isErrorConversation =
    (isErrorConversations && hasAcceptedLegalDisclaimer) ||
    isErrorCreateConversation ||
    isErrorGetConversation;
  const isPosting = isStreaming || isLoadingSendMessage;
  const disclaimer = useLegalDisclaimer({
    hasAccepted: hasAcceptedLegalDisclaimer,
    onAccept: refetchGetConversations,
  });

  useEffect(() => {
    onDisclaimerChange?.(disclaimer.hasAccepted);
  }, [hasAcceptedLegalDisclaimer]);

  useEffect(() => {
    if (conversations) {
      if (conversations.items.length > 0) {
        const currentConversationId = conversations.items[0].id;
        dispatch(
          chatbotSlice.actions.setCurrentConversationId(currentConversationId),
        );
      } else {
        handleNewConversation();
      }
    }
  }, [conversations]);

  useEffect(() => {
    if (currentConversationId) {
      getConversation({
        conversationId: currentConversationId,
        isInlineSourcesEnabled,
      })
        .unwrap()
        .then((conversation) => {
          if (conversation) {
            setMessages(conversation.messages as MessageType[]);
          }
        });
    }
  }, [currentConversationId]);

  useEffect(() => {
    if (isInterruptingAiMessageSuccess) {
      // Clear the stream message if doesn't contain any text
      if (!streamMessage?.text) {
        setStreamMessage(null);
      }
    }
  }, [isInterruptingAiMessageSuccess]);

  const resetStream = () => {
    setStreamMessage(null);
    setIsStreaming(false);
    onFinishMessage();
  };

  const handleNewConversation = () =>
    createConversation(entity)
      .unwrap()
      .then((newConversationId) => {
        if (newConversationId && newConversationId !== currentConversationId) {
          dispatch(
            chatbotSlice.actions.setCurrentConversationId(newConversationId),
          );

          if (!hasAskAnything) {
            setStreamMessage(replyAiMessage());
            setIsStreaming(true);
            handleNewSseConnection(newConversationId);
          }
        }
      });

  const handleNewSseConnection = (id: Uuid) =>
    createSseConnection(
      getStreamEndpoint(id),
      {
        onAuthenticationError: resetStream,
        onClose: noop,
        onError: resetStream,
        onMessage: handleStreamMessage,
        onOpen: handleStreamOpen,
        onRateLimitError: resetStream,
      },
      SSE_REQUEST_PROPS,
    );

  const handleStreamOpen = () => setStreamMessage(replyAiMessage());

  const handleStreamMessage = (data: unknown) => {
    const messageEvent = data as MessageStreamEvent;
    switch (messageEvent.eventType) {
      case 'message_start': {
        setStreamMessage((prevMessage) => {
          if (prevMessage) {
            return { ...prevMessage, text: messageEvent.text || '' };
          }
          return prevMessage;
        });
        break;
      }
      case 'message_delta': {
        if (messageEvent?.text) {
          setStreamMessage((prevMessage) => {
            if (prevMessage) {
              const text = prevMessage.text + messageEvent.text;
              return {
                ...prevMessage,
                text: text,
                inlineSources: isInlineSourcesEnabled
                  ? parseMarkdownAndExtractInlineSources(text ?? '')
                      .inlineSources
                  : undefined,
              };
            }
            return prevMessage;
          });
        }
        break;
      }
      case 'message_response_suggestion': {
        setStreamMessage((prevMessage) => {
          if (prevMessage && messageEvent?.text) {
            const updatedSuggestions = prevMessage.choices
              ? [...prevMessage.choices, messageEvent.text]
              : [messageEvent.text];
            return { ...prevMessage, choices: updatedSuggestions };
          }
          return prevMessage;
        });
        break;
      }
      case 'message_end': {
        if (messageEvent.message) {
          const newMessage = parseServerMessage(
            messageEvent.message,
            isInlineSourcesEnabled,
          );
          setMessages(
            (prevMessages) => [...prevMessages, newMessage] as MessageType[],
          );
        }
        setStreamMessage(null);
        break;
      }
      case 'stream_end': {
        setIsStreaming(false);
        onFinishMessage();
        if (messageEvent.text) {
          const serverMessage = parseStreamEndMessage(messageEvent.text);
          showToast(serverMessage);
        }
        setStreamMessage(null);
        break;
      }
      default:
        return;
    }
  };

  const handleSubmitFeedback = useCallback(
    (message: MessageType, feedbackDetails: FeedbackDetails) => {
      postUserFeedback({
        conversationId: currentConversationId!,
        messageId: message.id,
        feedbackDetails,
      })
        .unwrap()
        .then(() => {
          trackSegment('selectChatMessageFeedback', {
            messageId: message.id,
            userRating: mapUserRatingFromFeedbackDetails(feedbackDetails),
          });
        })
        .catch((error) => showToast(error?.detail ?? RETRY_ERROR));
    },
    [currentConversationId],
  );

  const handleChoose = useCallback((message: MessageType, choice: string) => {
    trackSegment('selectChatMessageChoice', {
      messageId: message.id,
      choice,
    });
  }, []);
  const handlePost = useCallback(
    (
      updatedText: string,
      metadata?: { questionId: Uuid; questionGroupId: Uuid },
    ) => {
      if (hasAskAnything && !selectedContext) {
        showToast('Please select a context to continue.');
      } else {
        if (currentConversationId) {
          setMessages((prevMessages) => [
            ...prevMessages,
            replyUserMessage(updatedText),
          ]);
          postUserMessage({
            conversationId: currentConversationId,
            message: updatedText,
            metadata: metadata,
            context: selectedContext
              ? [
                  {
                    search_entity: selectedContext.searchEntity,
                    search_query: selectedContext.query,
                    selected_ids: selectedContext.selectedIds,
                  },
                ]
              : [],
          })
            .unwrap()
            .then((message) => {
              if (message) {
                setMessages((prevMessages) => {
                  const updatedMessages = [...prevMessages];
                  const userMessage = updatedMessages.pop();
                  updatedMessages.push({
                    ...userMessage,
                    ...(message as MessageType),
                  });
                  return updatedMessages;
                });
                setIsStreaming(true);
                handleNewSseConnection(currentConversationId);
                trackSegment('enterChatQuestion', {
                  conversationId: currentConversationId,
                });
              }
            })
            .catch((error) => {
              onFinishMessage();
              showToast(error?.detail ?? RETRY_ERROR);
            });
        }
      }
    },
    [currentConversationId, selectedContext, hasAskAnything],
  );

  // this will allow other components to call the handlePost function
  // returning noop if no context is selected
  const { onFinishMessage } = useConversationPipeline({
    processHandler: selectedContext ? handlePost : noop,
  });

  const handleInterruptMessage = useCallback(() => {
    if (currentConversationId) {
      interruptAiMessage(currentConversationId);
      trackSegment('chatStopGenerating', {
        conversationId: currentConversationId,
      });
    }
  }, [currentConversationId]);

  // TODO: Update API to only send the handlerFunction
  const interruptAction = useMemo(() => {
    return {
      icon: 'pause',
      isLoading: isInterruptingAiMessage,
      onClick: handleInterruptMessage,
      text: 'Stop Generating',
    };
  }, [handleInterruptMessage, isInterruptingAiMessage]);

  const showToast = (message: string) => {
    if (!isActive(toastId)) {
      toastId = toast({
        message,
        status: 'danger',
        key: `chatbot-error-${toastIndex}`,
        onDismiss: () => toastIndex++,
      });
    }
  };

  const loadingContent = disclaimer.hasAccepted
    ? {
        isLoading: isLoadingConversation,
        message: 'Loading chat…',
      }
    : {
        isLoading: false,
      };

  const placeholderContent = isErrorConversation
    ? {
        icon: 'chatbot' as const,
        message: 'Unable to load chat history. Refresh the page to try again.',
      }
    : undefined;

  const moreActions = hasAcceptedLegalDisclaimer
    ? [
        {
          text: 'Clear your chat history',
          tooltip: isStreaming ? 'Disabled while generating.' : undefined,
          disabled: isLoadingConversation || isStreaming,
          onClick: () => {
            setMessages([]);
            handleNewConversation();
            trackSegment('selectClearChatHistory', {
              conversationId: currentConversationId,
            });
          },
        },
      ]
    : [];
  const contextFilters: types.UserAction[] = useMemo(
    () =>
      (context ?? []).map((contextItem) => ({
        text: contextItem.label,
        onClick: () => {
          // Disable callback if scope question already selected
          if (selectedContext?.label !== contextItem.label) {
            onSelectContextFilter?.(contextItem);
          }
        },
        mode: 'chip' as const,
        level:
          selectedContext?.label === contextItem.label
            ? 'secondary-active'
            : undefined,
      })),
    [context, selectedContext, isEntityDocumentVersion],
  );

  const footer = useMemo(() => <Footer entity={entity} />, []);

  const getActiveContext = () => {
    if (!hasAskAnything) return undefined;

    if (selectedContext) {
      return {
        icon: selectedContext.icon,
        text: selectedContext.title,
        onReset: () => onSelectContextFilter?.(null),
        // Disable reset if the entity is a document version interim solution while we fully support scope switch
        disableReset: !hasConversationalAIV2
          ? entity.type === 'document_version'
          : false,
      };
    } else {
      return {
        icon: 'filter' as const,
        text: 'Documents',
        onReset: undefined,
      };
    }
  };

  const getMessageSources = useCallback(
    (message: MessageType) => {
      const { sources = [], inlineSources = [] } = message;
      const isSourceDisabled = shouldDisableSources?.(message);
      const sourcesToRender = sources.length ? sources : inlineSources;
      return sourcesToRender.map(
        (source: MessageSource | InlineSource, index: number) => {
          if (testIsInlineSource(source)) {
            return {
              level: 'secondary' as const,
              text: `${index + 1}`,
              tooltip: 'View document',
              onClick: () => {
                onSelectMessageSource(message, index);
              },
              label: source.title,
              disabled: isSourceDisabled,
            };
          } else {
            return {
              level: 'secondary' as const,
              text: `${index + 1}`,
              tooltip: isSourceDisabled
                ? 'Source highlights are accessible when viewing the document version.'
                : `View source ${index + 1}`,
              onClick: () => {
                onSelectMessageSource(message, index);
              },
              disabled: isSourceDisabled,
            };
          }
        },
      );
    },
    [shouldDisableSources],
  );

  return (
    <Chat<MessageSource, InlineSource>
      currentUser={currentUser}
      disclaimer={disclaimer}
      footer={!hasAskAnything ? footer : null}
      context={
        hasAskAnything
          ? {
              label: 'Search',
              actions: contextFilters,
            }
          : undefined
      }
      activeContext={getActiveContext()}
      activeContextActions={STATIC_CONTEXT_ACTIONS}
      getMessageSources={getMessageSources}
      interruptAction={interruptAction}
      emptyConversation={hasAskAnything ? <NewConversation /> : undefined}
      isPosting={isPosting}
      isPrivate={true}
      limit={PROMPT_WORD_LIMIT}
      loadingContent={loadingContent}
      messages={messages}
      moreActions={moreActions}
      otherUser={EVISORT_AI}
      placeholderContent={placeholderContent}
      replyMessage={streamMessage}
      shouldDisableSources={shouldDisableSources}
      title="Conversational AI"
      messageActions={messageActions}
      panelProps={panelProps}
      chatActions={
        hasAskAnything
          ? [
              {
                icon: 'plus',
                mode: 'icon',
                text: '',
                tooltip: 'New Conversation',
                onClick: () => {
                  setMessages([]);
                  handleNewConversation();
                  trackSegment('selectClearChatHistory', {
                    conversationId: currentConversationId,
                  });
                },
              },
            ]
          : []
      }
      getUserTheme={getUserTheme}
      onChoose={handleChoose}
      onPost={handlePost}
      onSelectMessageSource={onSelectMessageSource}
      onSubmitFeedback={handleSubmitFeedback}
      userMessageActions={userMessageActions}
    />
  );
};
