import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom';

import { EM_DASH } from '~/constants/unicode';
import { formatDate } from '~/eds';
import { HtmlContent } from '~/ui';

import { documentTypeIcon } from '../../DocumentsPage/Document.utils';
import EcCard from '../../Shared/EcCard';
import EcCheckbox from '../../Shared/EcCheckbox';
import EcMultipleLocationsLink from '../../Shared/EcMultipleLocationsLink';
import ExpandIcon from '../../Shared/Icons/ExpandIcon';
import DownloadResultItemMenu from './DownloadResultItemMenu';
import styles from './SearchResultsItem.styles';

class SearchResultsItem extends Component {
  constructor(props) {
    super(props);

    this.handleOnDetailsClick = this.handleOnDetailsClick.bind(this);
    this.handleOnShowAllPartiesClick = this.handleOnShowAllPartiesClick.bind(
      this,
    );
    this.handleOnSelectClick = this.handleOnSelectClick.bind(this);

    this.state = {
      showDetails: this.props.showExpanded,
      showAllParties: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.showExpanded !== prevProps.showExpanded) {
      this.setState({ showDetails: this.props.showExpanded });
    }
  }

  handleOnDetailsClick() {
    this.setState((prevState) => ({ showDetails: !prevState.showDetails }));
  }

  handleOnShowAllPartiesClick() {
    this.setState({ showAllParties: true });
  }

  handleOnSelectClick() {
    const { document, onSelect } = this.props;

    onSelect(document);
  }

  renderProvisionsTable(provisions) {
    const { classes } = this.props;

    if (!provisions || !provisions.length) {
      return null;
    }

    const sortedProvisions = provisions.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
    );

    return (
      <table className={classes.provisionsTable}>
        <thead>
          <tr>
            <th className={classes.provisionsHeaderTitle}>Provision Title</th>
            <th className={classes.provisionsHeaderText}>Provision Text</th>
          </tr>
        </thead>
        <tbody>
          {sortedProvisions.map((provision, index) => (
            <tr key={index}>
              <td className={classes.provisionsBodyTitle}>{provision.name}</td>
              <td className={classes.provisionsBodyText}>
                {provision.content.map((content, provisionIndex) => {
                  return (
                    <p key={provisionIndex}>
                      <HtmlContent html={content} />
                    </p>
                  );
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  renderItemProvisions() {
    const { classes, document } = this.props;
    const { showDetails } = this.state;

    if (
      document.provision_list &&
      !document.provision_list.length &&
      document.rebate_table &&
      !document.rebate_table.length &&
      document.payment_table &&
      !document.payment_table.length
    ) {
      return null;
    }

    return (
      <Fragment>
        {showDetails ? (
          <Fragment>
            <button
              aria-expanded={true}
              className={classes.toggleProvisionsVisibility}
              onClick={this.handleOnDetailsClick}
            >
              <ExpandIcon />
              Hide Details
            </button>
            <div className={classes.provisionsWrapper}>
              {this.renderProvisionsTable(document.provision_list)}
            </div>
          </Fragment>
        ) : (
          <button
            aria-expanded={false}
            id={`analyzer-results-show-details-${document.document_handlers[0]?.id}-link`}
            className={classes.toggleProvisionsVisibility}
            onClick={this.handleOnDetailsClick}
          >
            <ExpandIcon />
            Show Details
          </button>
        )}
      </Fragment>
    );
  }

  renderDateItem(title, date) {
    const { classes } = this.props;
    const formattedDate = formatDate(date ? new Date(date) : null);

    return (
      <div className={classes.metadataItem}>
        <div className={classes.metadataHeader}>{title}</div>
        <div className={classes.metadataBody}>{formattedDate}</div>
      </div>
    );
  }

  renderListItem(title, list) {
    const { classes } = this.props;

    return (
      <div className={classes.metadataItem}>
        <div className={classes.metadataHeader}>{title}</div>
        <div className={classes.metadataBody}>
          {list && list.length
            ? list.map((item, index) => (
                <div
                  key={`${title}-${index}`}
                  className={classes.metadataListItem}
                >
                  {item}
                </div>
              ))
            : EM_DASH}
        </div>
      </div>
    );
  }

  renderPartiesListItem(parties) {
    const { classes } = this.props;
    const { showAllParties } = this.state;

    return (
      <div className={classes.metadataItem}>
        <div className={classes.metadataHeader}>PARTIES</div>
        <div className={classes.metadataBody}>
          {parties && parties.length ? (
            !showAllParties && parties.length > 2 ? (
              <Fragment>
                <div className={classes.metadataListItem}>{parties[0]}</div>
                <div className={classes.metadataListItem}>{parties[1]}</div>
                <button
                  className={classes.metadataListShowAll}
                  onClick={this.handleOnShowAllPartiesClick}
                >
                  Show All Parties
                </button>
              </Fragment>
            ) : (
              parties.map((party, index) => (
                <div
                  key={`party-${index}`}
                  className={classes.metadataListItem}
                >
                  {party}
                </div>
              ))
            )
          ) : (
            EM_DASH
          )}
        </div>
      </div>
    );
  }

  renderItemMetadata() {
    const { classes, document } = this.props;

    return (
      <div className={classes.resultsItemMetadata}>
        {this.renderPartiesListItem(document.party_list)}
        {this.renderDateItem('UPLOAD DATE', document.date_added)}
        <Fragment>
          {this.renderDateItem('EFFECTIVE DATE', document.effective_date)}
          {this.renderDateItem('EXPIRATION DATE', document.ex_date)}
          {this.renderListItem('CONTRACT TYPE', document.contract_type_list)}
        </Fragment>
      </div>
    );
  }

  renderItemHeader() {
    const { classes, document, isSelected, onNavigate } = this.props;
    const hasMultipleLocations = document.document_handlers.length > 1;
    const documentHandler = document.document_handlers[0];

    if (!documentHandler) {
      return <div>An error occurs while loading this document.</div>;
    }

    return (
      <div className={classes.resultsItemHeader}>
        <div className={classes.resultsItemTitle}>
          <EcCheckbox
            id={`doc-id-${documentHandler.id}-checkbox`}
            checked={isSelected}
            onClick={this.handleOnSelectClick}
          />
          <label
            className="screenReaderText"
            htmlFor={`doc-id-${documentHandler.id}-checkbox`}
          >
            select {documentHandler.document_name}
          </label>
          <div className={classes.resultsItemIcon}>
            {documentTypeIcon(documentHandler.file_type)}
          </div>
          {hasMultipleLocations ? (
            <EcMultipleLocationsLink
              documentName={documentHandler.document_name}
              documentHandlers={document.document_handlers}
              wrapperStyles={classes.resultsItemNameLink}
              multipleLocationsCallback={onNavigate}
            >
              <span className={classes.resultsItemName}>
                {documentHandler.document_name}
              </span>
            </EcMultipleLocationsLink>
          ) : (
            <Link
              to={`/document/${documentHandler.id}`}
              onClick={() => onNavigate(documentHandler)}
            >
              <span className={classes.groupDocumentName}>
                {documentHandler.document_name}
              </span>
            </Link>
          )}
        </div>
        <DownloadResultItemMenu document={document} />
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.resultsItemCardWrapper}>
        <EcCard contentStyles={{ padding: '16px' }}>
          {this.renderItemHeader()}
          {this.renderItemMetadata()}
          {this.renderItemProvisions()}
        </EcCard>
      </div>
    );
  }
}

export default injectSheet(styles)(SearchResultsItem);
