import PropTypes from 'prop-types';
import { useState } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';

import { ticketParticipantsSet, ticketSet, ticketStagesSet } from '~/actions';
import {
  addNewReminder,
  checkESignatureLogin,
  getTicketParticipants,
  patchTicketApproval,
  signDocument,
} from '~/api';
import AccessControl from '~/components/Shared/AccessControl';
import Datetime from '~/components/Shared/Datetime';
import { showToast } from '~/components/Shared/EcToast';
import SendReminder from '~/components/Shared/SendReminder';
import {
  TicketPermissionType,
  TicketStageType,
  TicketStatusType,
} from '~/enums';
import { withUsers } from '~/hocs';
import { useAsync } from '~/hooks';
import { getJudgmentById } from '~/reducers/ticketReviewer';
import { api } from '~/redux';
import * as toastTypes from '~/types/toast.types';
import { Button, FlexLayout, Icon, Text } from '~/ui';
import { capitalizeWords } from '~/utils/strings';
import { testIsCounterpartySigner, testIsUnassignedUser } from '~/utils/ticket';

import TicketUserItem from '../TicketUserItem';
import ApprovalItemApproveReject from './ApprovalItemApproveReject';
import styles from './TicketApprovalItem.styles';

const reminderPermission = {
  [TicketStageType.Review]: TicketPermissionType.RemindReviewers,
  [TicketStageType.Sign]: TicketPermissionType.RemindSignatories,
  [TicketStageType.Finalize]: TicketPermissionType.RemindFinalizers,
};

const TicketApprovalItem = ({
  issueId,
  entityType,
  approvalList,
  approvalName,
  // injected props
  classes,
  users,
  // connected props
  approvalItem,
  currentUserId,
  isCompleted,
  ticket,
  ticketParticipantsSet,
  ticketStagesSet,
}) => {
  const approvalId = approvalItem.id;
  const ticketId = ticket.id;
  const [eSignatureInfo, setESignatureInfo] = useState({ error: true });

  const [
    getTicketCurrentStage,
  ] = api.endpoints.getTicketCurrentStage.useLazyQuery();

  useAsync(
    checkESignatureLogin,
    {
      ticketId,
      esignatureProvider: ticket.esignatureProvider,
      ticketUrl: window.location.href,
    },
    {
      condition: !!ticket.esignatureProvider,
      deps: [ticketId],
      successHandler: setESignatureInfo,
      errorToastMessage:
        'There was an issue communicating with the e-signature provider. Please try again.',
    },
  );

  const { executor: sendNewReminder } = useAsync(
    addNewReminder,
    { ticketId, approvalId },
    {
      successHandler: async () => {
        const stagesResp = await getTicketCurrentStage({ ticketId }).unwrap();
        ticketStagesSet(stagesResp);
      },
      successToastMessage: 'Reminder sent successfully.',
      errorToastMessage: 'Sending reminder failed.',
    },
  );

  const {
    executor: handleOnMarkAsDoneClick,
    isLoading: isMarkDoneLoading,
  } = useAsync(
    patchTicketApproval,
    { ticketId, status: TicketStatusType.Done, approvalId },
    {
      successHandler: async () => {
        const stagesResp = await getTicketCurrentStage({ ticketId }).unwrap();
        const { data: participants } = await getTicketParticipants({
          ticketId,
        });
        ticketParticipantsSet(participants);
        ticketStagesSet(stagesResp);
      },
    },
  );

  const handleOnSignClick = () => {
    if (eSignatureInfo.isUserEsignatureLoggedIn) {
      signDocument({ ticketId: ticket.id, ticketUrl: window.location.href })
        .then(({ redirectUrl }) => (window.location.href = redirectUrl))
        .catch(() =>
          showToast(
            toastTypes.ERROR,
            'Unable to sign the document. Please refresh the page and try again.',
          ),
        );
    } else {
      window.location.href = eSignatureInfo.redirectUrl;
    }
  };

  const renderApprovalItemActions = () => {
    switch (ticket.stage) {
      case TicketStageType.Finalize:
        return (
          <FlexLayout alignItems="center">
            <Button
              text="Mark as Done"
              iconLeft="checkmark"
              isLoading={isMarkDoneLoading}
              onClick={handleOnMarkAsDoneClick}
            />
          </FlexLayout>
        );
      case TicketStageType.Sign:
        return (
          <FlexLayout alignItems="center">
            {ticket.hasEnvelope &&
              approvalItem.status === TicketStatusType.SignaturePending && (
                <Button
                  text="Sign Document"
                  iconLeft="signature"
                  onClick={handleOnSignClick}
                />
              )}
            <span className={classes.horizontalSeparator} />
            {renderSingleStatus(
              approvalItem.status,
              <Icon icon="donut" color="yellow-500" size="s" />,
            )}
          </FlexLayout>
        );
      case TicketStageType.Review:
        return (
          <ApprovalItemApproveReject
            ticketId={ticket.id}
            approvalName={approvalName}
            approvalId={approvalId}
            phaseId={approvalItem.phaseId}
          />
        );
      default:
        return null;
    }
  };

  const renderSingleStatus = (label, icon, datetime) => {
    return (
      <FlexLayout
        className={classes.approvalItemStatus}
        alignItems="center"
        space={1}
      >
        {icon}
        <Text color="gray-700" variant="xs-dense">
          {capitalizeWords(label)}{' '}
          {datetime && (
            <>
              (<Datetime datetime={datetime} format="duration" />)
            </>
          )}
        </Text>
      </FlexLayout>
    );
  };

  const [assignAction, setAssignAction] = useState(false);
  const handleReassignment = () => setAssignAction(true);
  const judgment = getJudgmentById(ticket, issueId);

  /**
   * If the signature request has already been sent and the userId is null, that means the signer has been
   * reassigned to some external user in Docusign, and it should be marked as a counterparty signer
   */
  const isCounterpartySigner =
    approvalItem.status === TicketStatusType.SignatureRequestNotSent
      ? testIsCounterpartySigner(judgment)
      : approvalItem.userId === null;
  const isUnassignedUser = testIsUnassignedUser(approvalItem, users);

  let itemStatus;
  switch (approvalItem.status) {
    case TicketStatusType.Done:
    case TicketStatusType.Approved:
      itemStatus = (
        <FlexLayout alignItems="center">
          {renderSingleStatus(
            TicketStatusType.Completed,
            <Icon icon="checkmarkCircledFilled" color="green-500" size="s" />,
            approvalItem.lastModified,
          )}
        </FlexLayout>
      );
      break;
    case TicketStatusType.Signed:
      itemStatus = (
        <FlexLayout alignItems="center">
          {renderSingleStatus(
            approvalItem.signatureUploadDate
              ? `${approvalItem.status} via Upload`
              : approvalItem.status,
            <Icon icon="checkmarkCircledFilled" color="green-500" size="s" />,
            approvalItem.lastModified,
          )}
        </FlexLayout>
      );
      break;
    case TicketStatusType.Rejected:
      itemStatus = (
        <FlexLayout alignItems="center">
          {renderSingleStatus(
            'Rejected',
            <Icon icon="stop" color="red-600" size="s" />,
            approvalItem.lastModified,
          )}
        </FlexLayout>
      );
      break;
    case TicketStatusType.EmailDeliveryFailed:
      itemStatus = (
        <FlexLayout alignItems="center">
          {renderSingleStatus(
            approvalItem.status,
            <Icon icon="warning" color="red-600" size="s" />,
            approvalItem.lastModified,
          )}
        </FlexLayout>
      );
      break;
    default:
      if (!isCounterpartySigner && isUnassignedUser) {
        itemStatus = (
          <>
            <AccessControl
              userPermissions={ticket.permissions}
              requiredPermission={TicketPermissionType.ReassignAny}
            >
              <Text
                color="blue-500"
                variant="xs-dense-bold"
                sx={{ justifySelf: 'center' }}
                onClick={handleReassignment}
              >
                Assign
              </Text>
            </AccessControl>

            {renderSingleStatus(
              approvalItem.status,
              <Icon icon="donut" color="red-400" size="s" />,
            )}
          </>
        );
      } else if (
        approvalItem.userId === currentUserId ||
        (ticket.permissions.includes(TicketPermissionType.FinalizeItem) &&
          ticket.stage === TicketStageType.Finalize)
      ) {
        itemStatus = renderApprovalItemActions();
      } else {
        itemStatus = (
          <>
            <AccessControl
              userPermissions={ticket.permissions}
              requiredPermission={reminderPermission[ticket.stage]}
            >
              <FlexLayout alignItems="center">
                {(ticket.stage !== TicketStageType.Sign ||
                  ticket.stages.sign.status !==
                    TicketStatusType.SignatureRequestNotSent) && (
                  <SendReminder
                    actionHandler={sendNewReminder}
                    lastReminderDate={
                      approvalItem.lastReminderDate || undefined
                    }
                  />
                )}
              </FlexLayout>
            </AccessControl>
            {renderSingleStatus(
              approvalItem.status,
              <Icon
                icon="donut"
                color={isUnassignedUser ? 'red-400' : 'yellow-500'}
                size="s"
              />,
            )}
          </>
        );
      }
  }

  const { userId: approverId, status } = approvalItem;

  const note = (status === TicketStatusType.Approved ||
    status === TicketStatusType.Rejected) && (
    <div className={classes.note}>
      {approvalItem.comment || <i>No note provided.</i>}
    </div>
  );

  return (
    <div className={classes.ticketApprovalItemWrapper}>
      <TicketUserItem
        userId={approverId}
        externalUser={approvalItem.externalUser}
        currentUserId={currentUserId}
        userPermissions={ticket.permissions}
        isListItem={true}
        note={note}
        ticketId={ticketId}
        issueId={issueId}
        entityType={entityType}
        approvalList={approvalList}
        status={status}
        disableAdditionalActions={
          isCompleted ||
          [TicketStatusType.Signed].includes(status) ||
          approvalItem.externalUser
        }
        assignAction={assignAction}
        setAssignAction={setAssignAction}
        isCounterpartySigner={isCounterpartySigner}
      >
        {itemStatus}
      </TicketUserItem>
    </div>
  );
};

export const propTypes = {
  approvalId: PropTypes.string.isRequired,
};

TicketApprovalItem.propTypes = propTypes;

function mapStateToProps({ currentUser, ticket }, { approvalId }) {
  const { approvals } = ticket;
  return {
    currentUserId: currentUser.id,
    approvalItem: approvals[approvalId],
    isCompleted: ticket.status === TicketStatusType.Completed,
    ticket,
  };
}

export default injectSheet(styles)(
  connect(mapStateToProps, {
    ticketSet,
    ticketParticipantsSet,
    ticketStagesSet,
  })(withUsers(TicketApprovalItem)),
);
