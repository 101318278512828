export enum OoDocsDataMessageType {
  ClearHighlights = 'clear_highlights',
  ClearHighlightsError = 'clear_highlights_error',
  EnableEditMode = 'enable_edit_mode',
  GetBookmarks = 'get_bookmarks',
  NavigateToBookmark = 'navigate_to_bookmark',
  NavigateToBookmarkError = 'navigate_to_bookmark_error',
  MoveToComment = 'move_to_comment',
  ParticipantNotAdded = 'participant_not_added',
  ProcessClauses = 'process_clauses',
  ProcessClausesError = 'process_clauses_error',
  SetReviewMode = 'set_review_mode',
  SrchselHighlight = 'srchsel_highlight',
  StartCompare = 'start_comparing_documents',
  SuccessfullyAddedParticipant = 'successfully_added_participant',
}
