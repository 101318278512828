import { difference, orderBy, uniqBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { PAGE_SIZE } from '~/constants/page';
import { UserSelect as EdsUserSelect, types } from '~/eds';
import { useCurrentUser, useDebouncedCallback } from '~/hooks';
import { api } from '~/redux';

import { getSelectedUsers, getUsers } from './utils';

interface UserSelectProps<M> extends types.SharedSelectProps<types.PilotId, M> {
  notAvailableUsers?: types.PilotId[];
  roles?: types.Option<string>[];
  specificUsers?: types.PilotId[];
}

const initialSearchParams = {
  query: '',
  pageSize: PAGE_SIZE,
};

export function getFilteredUsers({
  notAvailableUsers,
  users,
  specificUsers,
}: {
  notAvailableUsers: types.PilotId[];
  users: types.User[];
  specificUsers: types.PilotId[];
}) {
  return users.filter((user) => {
    const shouldFilter =
      specificUsers.length > 0 || notAvailableUsers.length > 0;
    const isAvailable =
      specificUsers.length > 0 && specificUsers.includes(user.id);
    const isNotAvailable =
      notAvailableUsers.length > 0 && !notAvailableUsers.includes(user.id);

    return !shouldFilter || isAvailable || isNotAvailable;
  });
}

function UserSelect<M extends boolean>({
  notAvailableUsers = [],
  roles = [],
  specificUsers = [],
  ...rest
}: UserSelectProps<M>) {
  const [
    findUsers,
    result,
  ] = api.endpoints.findUsersByNameOrEmail.useLazyQuery();
  const [
    resolveUsers,
    { data: resolvedUsersData, isFetching: isFetchingResolvedUsers },
  ] = api.endpoints.resolveUsers.useLazyQuery();
  const currentUser = useCurrentUser();
  const clientId = currentUser.client;
  const [whitelistedUsers, setWhitelistedUsers] = useState<
    Record<types.PilotId, types.User>
  >({});
  const [shouldShowSpecificUsers, setShouldShowSpecificUsers] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState<
    Record<types.PilotId, types.User>
  >({});
  const { error: fetchError, isFetching, originalArgs } = result;
  const users = result?.data?.results || [];

  useEffect(() => {
    if (resolvedUsersData && !isFetchingResolvedUsers) {
      if (rest.value) {
        const users = getUsers(
          rest.value as number | number[],
          resolvedUsersData,
        );
        setSelectedUsers(users);
      }
      if (specificUsers.length > 0) {
        const whitelistUsers = getUsers(
          specificUsers.slice(0, PAGE_SIZE) as number[],
          resolvedUsersData,
        );
        setWhitelistedUsers(whitelistUsers);
      }
    }
  }, [resolvedUsersData, isFetchingResolvedUsers]);

  useEffect(() => {
    const whitelistedUsersIds = specificUsers.slice(0, PAGE_SIZE) as number[];
    let userIds = whitelistedUsersIds;
    if (rest.value) {
      if (Array.isArray(rest.value)) {
        const userIdsOnly = rest.value.filter(
          (v) => !roles.some((role) => role.value === v),
        ) as number[];
        userIds = [...userIdsOnly, ...userIds];
      } else {
        userIds = roles.some((role) => role.value === rest.value)
          ? [...userIds]
          : [rest.value as number, ...userIds];
      }
    }

    // Excluding external email users (endpoint can only resolve IDs)
    const unresolvedUsers = difference(
      userIds,
      Object.keys(selectedUsers).map(Number),
    ).filter((value) => !isNaN(value)) as number[];

    unresolvedUsers.length &&
      resolveUsers({
        ids: unresolvedUsers,
        params: { clientId, includeDeleted: true },
      });
  }, [rest.value]);

  // map users to { [user.id]: user } format.
  const usersMap = useMemo(
    () => users.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}),
    [users],
  );

  const handleOnChange = (updatedValue: any, updatedAsyncValue: any) => {
    setSelectedUsers(getSelectedUsers(updatedValue, selectedUsers, usersMap));
    rest.onChange(updatedValue, updatedAsyncValue);
  };

  const debouncedFindUsers = useDebouncedCallback(
    findUsers,
    initialSearchParams,
  );

  const filteredUsers = useMemo(
    () => getFilteredUsers({ users, notAvailableUsers, specificUsers }),
    [users, notAvailableUsers, specificUsers],
  );

  const usersAndSelectedUsers = useMemo(() => {
    const allAvailableUsers = shouldShowSpecificUsers
      ? [...filteredUsers, ...Object.values(whitelistedUsers)]
      : filteredUsers;
    return orderBy(
      uniqBy([...allAvailableUsers, ...Object.values(selectedUsers)], 'id'),
      [(user) => user.firstName?.toLowerCase()],
      ['asc'],
    );
  }, [filteredUsers, selectedUsers, shouldShowSpecificUsers]);

  const availableRoles = useMemo(() => {
    const searchQuery = originalArgs?.query?.toLowerCase() || '';
    return roles.filter((role) =>
      role.label.toLowerCase().includes(searchQuery),
    );
  }, [originalArgs, roles]);

  return (
    <EdsUserSelect
      {...rest}
      error={rest.error || fetchError}
      isLoading={isFetching}
      /** TODO: adjust this when API can handle select all users async. */
      enableSelectAll={false}
      roles={availableRoles}
      users={usersAndSelectedUsers}
      onChange={handleOnChange}
      onSearch={(updatedSearch) => {
        setShouldShowSpecificUsers(!updatedSearch);
        debouncedFindUsers({ query: updatedSearch, pageSize: PAGE_SIZE });
      }}
    />
  );
}

export default UserSelect;
