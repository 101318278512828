import { createReducer } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';

import * as actions from '~/actions';
import {
  FeatureFlagType,
  FileExtensionType,
  OoDocsDocModeType,
  OutboundEmailFileType,
  TicketActivityType,
  TicketPermissionType,
  TicketStageType,
  TicketStatusType,
} from '~/enums';
import { testHasFlag } from '~/permissions';
import { coerceFileType } from '~/utils/files';
import { getStagePermissions, testIsCcSigner } from '~/utils/ticket';

function getInitialState() {
  return {
    id: '',
    name: '',
    creatorId: null,
    createdDate: null,
    stage: null,
    status: null,
    participantIds: [],
    permissionMap: {},
    currentVersionId: null,
    document: {}, // maybe/maybe keep in ticket
    documentVersions: [],
    hasEnvelope: false,
    hasEsignatureIntegration: false,
    hasUnpopulatedSignerField: false,
    stages: {},
    phases: {},
    judgments: {},
    risks: {},
    approvals: {},
    events: {},
    participants: {},
    docMode: OoDocsDocModeType.AllMarkup,
    currentVersion: 0,
    isInEditorMode: false,
    inboundEmailAddress: '',
    enabledStageNames: [],
  };
}

export function activityToEvent(activity) {
  return {
    action: activity.action,
    id: activity.id,
    type: activity.type,
    createdDate: activity.modifiedDate,
    note: activity.data.comment,
    user: activity.user,
    userId: activity.user.id,
    data: {
      ...activity.data,
      versionNumber: activity.versionNumber,
    },
  };
}

function formatParticipant(participant) {
  return {
    id: participant.userId,
    role: participant.role,
  };
}

function decoupleApprovals(phase, judgment, approval, containers) {
  containers.approvals[approval.id] = {
    ...approval,
    lastModified: approval.modifiedDate,
    phaseId: phase.id,
    judgmentId: judgment.id,
  };
}

function decoupleRiskApprovals(phase, risk, approval, containers) {
  containers.approvals[approval.id] = {
    ...approval,
    lastModified: approval.modifiedDate,
    phaseId: phase.id,
    riskId: risk.id,
  };
}

function decoupleRisks(phase, risk, containers) {
  const { approvals, ...rest } = risk;
  containers.risks[risk.id] = {
    ...rest,
    approvalIds: approvals.map((approval) => {
      decoupleRiskApprovals(phase, risk, approval, {
        approvals: containers.approvals,
      });
      return approval.id;
    }),
  };
}

function decoupleJudgment(phase, judgment, containers) {
  const { approvals, ...rest } = judgment;
  containers.judgments[judgment.id] = {
    ...rest,
    approvalIds: approvals.map((approval) => {
      decoupleApprovals(phase, judgment, approval, {
        approvals: containers.approvals,
      });
      return approval.id;
    }),
  };
}

function decouplePhase(phase, stage, containers) {
  const { judgments, risks, ...rest } = phase;
  containers.phases[phase.id] = {
    ...rest,
    judgments,
    judgmentIds: judgments.map((judgment) => {
      decoupleJudgment(phase, judgment, {
        judgments: containers.judgments,
        approvals: containers.approvals,
      });
      return judgment.id;
    }),
    risks,
    riskIds: risks.map((risk) => {
      decoupleRisks(phase, risk, {
        risks: containers.risks,
        approvals: containers.approvals,
      });
      return risk.id;
    }),
    stage,
  };
}

export default createReducer(getInitialState(), (builder) => {
  builder.addCase(actions.ticketReset, () => getInitialState());
  /** prefer to use this to update after operations */
  builder.addCase(actions.ticketUpdate, (state, action) => {
    const toUpdateTicket = {};
    const { payload } = action;
    const { stages, ...payloadRest } = payload;
    if (stages) {
      const {
        stages: processedStages,
        phases,
        judgments,
        risks,
        approvals,
      } = processStages(stages);
      toUpdateTicket.stages = processedStages;
      toUpdateTicket.phases = phases;
      toUpdateTicket.judgments = judgments;
      toUpdateTicket.risks = risks;
      toUpdateTicket.approvals = approvals;
    }
    return { ...state, ...payloadRest, ...toUpdateTicket };
  });
  builder.addCase(actions.ticketSet, (state, action) => {
    const { payload } = action;
    const {
      id,
      name,
      creatorId,
      createdDate,
      esignatureProvider,
      hasEnvelope,
      hasEsignatureIntegration,
      hasUnpopulatedSignerField,
      isCancelled,
      cancelledDate,
      stage,
      status,
      permissionMap,
      pilotDocHandlerId,
      document,
      documentVersions,
      wasManuallyUpdated,
      workflow,
      inboundEmailAddress,
    } = payload;

    const ticket = {
      id,
      name,
      creatorId,
      createdDate,
      esignatureProvider,
      hasEnvelope,
      hasEsignatureIntegration,
      hasUnpopulatedSignerField,
      isCancelled,
      cancelledDate,
      stage,
      status,
      workflow,
      permissionMap,
      // TODO: kristjian to explicitly read from permissionMap + TicketStageType via hasPermissions selector to make things less 'magical' and more explicit.
      permissions: getStagePermissions(permissionMap, stage),
      pilotDocHandlerId,
      document,
      documentVersions,
      wasManuallyUpdated,
      inboundEmailAddress,
    };

    const stageNames = Object.keys(payload.stages);

    const orderedDocumentVersions = orderBy(
      documentVersions,
      'versionNumber',
      'desc',
    );

    let stages = {};
    let phases = {};
    let judgments = {};
    let risks = {};
    let approvals = {};

    stageNames.forEach((name) => {
      stages[name] = {
        coordinatorId: null,
        phaseIds: [],
        id: null,
        isEnabled: false,
        status: null,
      };
      if (payload.stages[name]) {
        stages[name].coordinatorId = payload.stages[name].coordinatorId;
        stages[name].id = payload.stages[name].id;
        stages[name].isEnabled = payload.stages[name].isEnabled;
        stages[name].status = payload.stages[name].status;

        const { phases: currentPhases } = payload.stages[name];
        if (currentPhases) {
          stages[name].phaseIds = currentPhases.map((phase) => {
            decouplePhase(phase, name, { phases, judgments, risks, approvals });
            return phase.id;
          });
        }
      }
    });

    ticket.stages = stages;
    ticket.phases = phases;
    ticket.judgments = judgments;
    ticket.risks = risks;
    ticket.approvals = approvals;
    ticket.enabledStageNames = Object.entries(payload.stages)
      .filter(([_stageName, stageValue]) => stageValue.isEnabled)
      .map(([stageName]) => stageName);

    return { ...state, ...ticket, documentVersions: orderedDocumentVersions };
  });
  builder.addCase(actions.ticketActivitiesSet, (state, action) => {
    let events = {};

    const activities = action.payload;
    activities.forEach((activity) => {
      const event = activityToEvent(activity);
      const { id: eventId } = event;
      events[eventId] = event;
    });
    state.activities = activities;
    state.events = events;
  });
  builder.addCase(actions.ticketParticipantsSet, (state, action) => {
    const users = action.payload.map(formatParticipant);
    const participants = users.reduce((acc, user) => {
      acc[user.id] = user;
      return acc;
    }, {});
    state.participants = participants;
    state.participantIds = Object.keys(participants).map(Number);
  });
  builder.addCase(actions.ticketDocModeSet, (state, action) => {
    state.docMode = action.payload;
  });
  builder.addCase(actions.ticketCurrentVersionSet, (state, action) => {
    state.currentVersion = action.payload;
  });
  builder.addCase(actions.ticketIsInEditorModeSet, (state, action) => {
    state.isInEditorMode = action.payload;
  });
  builder.addCase(actions.ticketDocumentVersionsSet, (state, action) => {
    const orderedDocumentVersions = orderBy(
      action.payload,
      'versionNumber',
      'desc',
    );
    state.documentVersions = orderedDocumentVersions;
  });
  builder.addCase(actions.ticketAttachmentsSet, (state, action) => {
    state.attachments = action.payload;
  });
  builder.addCase(actions.ticketDocumentSet, (state, action) => {
    state.document = action.payload;
  });
  builder.addCase(actions.ticketStagesSet, (state, action) => {
    const { payload } = action;
    const stageName = payload.name;
    let stages = {};
    let phases = {};
    let judgments = {};
    let risks = {};
    let approvals = {};

    stages[stageName] = {
      coordinatorId: payload.coordinatorId || null,
      phaseIds: [],
      id: payload.id || null,
      isEnabled: payload.isEnabled || false,
      status: payload.status || null,
    };

    const { phases: currentPhases } = payload;
    if (currentPhases) {
      stages[stageName].phaseIds = currentPhases.map((phase) => {
        decouplePhase(phase, stageName, {
          phases,
          judgments,
          risks,
          approvals,
        });
        return phase.id;
      });
    }

    return {
      ...state,
      phases,
      judgments,
      risks,
      approvals,
      stages,
    };
  });
  builder.addCase(actions.ticketSummarySet, (state, action) => {
    const { payload } = action;
    const {
      id,
      name,
      creator,
      createdDate,
      esignatureProvider,
      hasUnpopulatedSignerField,
      isCancelled,
      cancelledDate,
      stage,
      status,
      permissionMap,
      pilotDocHandlerId,
      wasManuallyUpdated,
      inboundEmailAddress,
      workflowId,
      enabledStageNames,
      acceptedFileTypes,
    } = payload;

    const ticket = {
      id,
      name,
      creatorId: creator.extId,
      createdDate,
      esignatureProvider,
      hasUnpopulatedSignerField,
      isCancelled,
      cancelledDate,
      stage,
      status,
      permissionMap,
      // TODO: kristjian to explicitly read from permissionMap + TicketStageType via hasPermissions selector to make things less 'magical' and more explicit.
      permissions: getStagePermissions(permissionMap, stage),
      pilotDocHandlerId,
      wasManuallyUpdated,
      inboundEmailAddress,
      workflowId,
      workflow: {
        acceptedFileTypes,
      },
      // enabledStageNames has the "ticket" stage that isn't a valid navigable stage,
      // although is a valid one, so we remove it from the enabledStageNames
      enabledStageNames: enabledStageNames.filter(
        (stageName) => stageName !== 'ticket',
      ),
    };

    return { ...state, ...ticket };
  });
  builder.addCase(actions.ticketEnvelopeSet, (state, action) => {
    state.hasEnvelope = action.payload;
  });
  builder.addCase(actions.ticketEsignatureSet, (state, action) => {
    state.hasEsignatureIntegration = action.payload;
  });
  builder.addCase(actions.ticketCurrentVersionIdSet, (state, action) => {
    state.currentVersionId = action.payload;
  });
});

export function getStageApprovals(state) {
  const { approvals, judgments, risks, phases, stage } = state;
  const stagePhases = Object.values(phases).filter(
    (phase) => phase.stage === stage,
  );

  let approvalIds = [];
  stagePhases.forEach((stagePhase) => {
    const { judgmentIds, riskIds } = stagePhase;
    judgmentIds.forEach((judgmentId) => {
      approvalIds = [...approvalIds, ...judgments[judgmentId].approvalIds];
    });
    riskIds.forEach((riskId) => {
      approvalIds = [...approvalIds, ...risks[riskId].approvalIds];
    });
  });

  return approvalIds.map((approvalId) => approvals[approvalId]);
}

function calculateIsNextStageDisabled(approvals, status) {
  return approvals.some((approval) => approval.status !== status);
}

export function getLatestActivity(activities) {
  let latestActivity = null;

  if (Array.isArray(activities) && activities.length > 0) {
    const activityArray = activities.map((activity) => {
      const obj = Object.assign({}, activity);
      obj.modifiedDate = new Date(activity.modifiedDate);
      return obj;
    });

    latestActivity = activityArray.slice(1).reduce((acc, activity) => {
      acc = acc.modifiedDate > activity.modifiedDate ? acc : activity;
      return acc;
    }, activityArray[0]);
  }

  return latestActivity;
}

export function getIsNextStageDisabled(state) {
  const { stage } = state;

  const approvals = getStageApprovals(state);
  // handle other logic to disable next stage.
  switch (stage) {
    case TicketStageType.Review:
      return calculateIsNextStageDisabled(approvals, TicketStatusType.Approved);
    case TicketStageType.Sign:
      return calculateIsNextStageDisabled(
        approvals.filter((approval) => !testIsCcSigner(approval)),
        TicketStatusType.Signed,
      );
    case TicketStageType.Finalize:
      return calculateIsNextStageDisabled(approvals, TicketStatusType.Done);
    default:
      return false;
  }
}

// return latest version if versionNumber is not provided or 0, otherwise find it.
export function getDocumentVersion(state, versionNumber) {
  const { documentVersions } = state;
  const latestDocumentVersion = documentVersions[0];

  const documentVersion = Number(versionNumber)
    ? documentVersions.find(
        (documentVersion) =>
          documentVersion.versionNumber === Number(versionNumber),
      )
    : latestDocumentVersion;

  return {
    ...documentVersion,
    isLatestVersion: documentVersion === latestDocumentVersion,
  };
}

export function getDocumentVersionNumberById(state, versionId) {
  const { documentVersions } = state;
  return documentVersions.find((version) => version.id === versionId)
    ?.versionNumber;
}

export function getRedlineVersionsInfoText(
  shortFormat = false,
  state,
  referenceVersionId,
  targetVersionId,
) {
  const referenceVersion =
    getDocumentVersionNumberById(state, referenceVersionId) || '(deleted)';
  const targetVersion =
    getDocumentVersionNumberById(state, targetVersionId) || '(deleted)';
  if (shortFormat) {
    return `v${referenceVersion} to v${targetVersion}`;
  } else {
    return `Version ${referenceVersion} to Version ${targetVersion}`;
  }
}

// TODO: Backend needs to implement this to honor permissions.  This should be removed ASAP
export function getHasTemporaryViewAnyPermission(state, stage = state.stage) {
  const permissions = getStagePermissions(state.permissionMap, stage);
  return (
    permissions.includes(TicketPermissionType.ViewApprovalDetailed) ||
    permissions.includes(TicketPermissionType.ViewApprovalBasic)
  );
}

export function getEvents(state) {
  const hasReviewerPermission = getStagePermissions(
    state.permissionMap,
    state.stage,
  ).includes(TicketPermissionType.Reviewer);

  return Object.values(state.events).map((event) => {
    switch (event.action) {
      case TicketActivityType.ShareDocument:
        const { attachments, version } = event.data;
        const ticketId = state.id;
        // there is no way to check for feature flag inside a reducer because we don't have access to current user, so we are checking if attachments is present which an attribute only seen with the feature flag active
        if (attachments) {
          const hydratedAttachments = attachments.map((attachment) => {
            const versionExists =
              attachment.type === OutboundEmailFileType.ExistingVersion &&
              !!getDocumentVersionNumberById(state, attachment.id);
            const shouldLinkVersion = hasReviewerPermission && versionExists;
            const versionType = shouldLinkVersion ? 'versionLink' : 'version';
            const versionData = shouldLinkVersion
              ? `${attachment?.tag}_${state.id}`
              : attachment?.tag;
            return {
              ...attachment,
              versionType,
              versionData,
            };
          });

          return {
            ...event,
            hasReviewerPermission,
            data: {
              ...event.data,
              attachments: hydratedAttachments,
              ticketId,
            },
          };
        } else {
          const versionExists = !!getDocumentVersionNumberById(
            state,
            version.id,
          );
          const shouldLinkVersion = hasReviewerPermission && versionExists;
          const versionType = shouldLinkVersion ? 'versionLink' : 'version';
          const versionData = shouldLinkVersion
            ? `${version?.tag}_${state.id}`
            : version?.tag;
          const ticketId = state.id;
          return {
            ...event,
            hasReviewerPermission,
            data: {
              ...event.data,
              versionType,
              versionData,
              ticketId,
            },
          };
        }
      case TicketActivityType.DownloadDocument:
      case TicketActivityType.DocumentSave:
      case TicketActivityType.DocumentSigned:
      case TicketActivityType.IntakeFormEdit:
      case TicketActivityType.DocumentUpload:
      case TicketActivityType.OodocsDownloadRedline:
      case TicketActivityType.OodocsDownloadVersion:
      case TicketActivityType.InboundAttachmentAccepted:
      case TicketActivityType.VersionBumpForEditing: {
        const { referenceVersionId, targetVersionId, version } = event.data;
        const shortFormat =
          event.action === TicketActivityType.OodocsDownloadRedline;
        const redlineVersionInfo = getRedlineVersionsInfoText(
          shortFormat,
          state,
          referenceVersionId,
          targetVersionId,
        );
        const versionExists = !!getDocumentVersionNumberById(state, version.id);
        const shouldLinkVersion = hasReviewerPermission && versionExists;
        const versionType = shouldLinkVersion ? 'versionLink' : 'version';
        const versionData = shouldLinkVersion
          ? `${version?.tag}_${state.id}`
          : version?.tag;
        const ticketId = state.id;
        return {
          ...event,
          hasReviewerPermission,
          data: {
            ...event.data,
            redlineVersionInfo,
            versionType,
            versionData,
            ticketId,
          },
        };
      }
      default: {
        return event;
      }
    }
  });
}

export function getParticipants(state, users) {
  return Object.values(state.participants).map((participant) => {
    const user = users[participant.id] || {};
    return {
      ...participant,
      ...user,
    };
  });
}

export function getIsHighlightDisabled(ticket, currentUser) {
  const isOoDocsEnabled = testHasFlag(FeatureFlagType.OoDocs)(currentUser);
  const activeDocumentVersion = getDocumentVersion(
    ticket,
    ticket.currentVersion,
  );
  const coerceDocFileTypes = [
    FileExtensionType.Doc,
    FileExtensionType.Docx,
  ].map((type) => coerceFileType(type));
  const isOoDocsSupportedDocType = coerceDocFileTypes.includes(
    activeDocumentVersion?.fileType,
  );
  return (
    isOoDocsEnabled &&
    isOoDocsSupportedDocType &&
    (ticket.docMode !== OoDocsDocModeType.AllMarkup || ticket.isInEditorMode)
  );
}

const processStages = (initialStages) => {
  const stageNames = Object.keys(initialStages);

  let stages = {};
  let phases = {};
  let judgments = {};
  let risks = {};
  let approvals = {};

  stageNames.forEach((name) => {
    stages[name] = {
      coordinatorId: null,
      phaseIds: [],
      id: null,
      isEnabled: false,
      status: null,
    };
    if (initialStages[name]) {
      stages[name].coordinatorId = initialStages[name].coordinatorId;
      stages[name].id = initialStages[name].id;
      stages[name].isEnabled = initialStages[name].isEnabled;
      stages[name].status = initialStages[name].status;

      const { phases: currentPhases } = initialStages[name];
      if (currentPhases) {
        stages[name].phaseIds = currentPhases.map((phase) => {
          decouplePhase(phase, name, {
            phases,
            judgments,
            risks,
            approvals,
          });
          return phase.id;
        });
      }
    }
  });
  return {
    stages,
    phases,
    judgments,
    risks,
    approvals,
  };
};
