import { createSelector } from 'reselect';

import { SliceType } from '~/enums';

import { PagesState, RootState } from '../types';

const slice = (rootState: RootState): PagesState => rootState[SliceType.Pages];

export const selectPageBanner = (pathname: string) =>
  createSelector(slice, (state) => state[pathname]?.banner);
