import { useDispatch, useSelector } from 'react-redux';

import { formatNumber, FormField, Layout, TextArea, types } from '~/eds';
import { actions, selectors } from '~/redux';
import { Nullable } from '~/types';

import { FEEDBACK_MAX_LENGTH } from '../../constants';
import { FieldClassificationGoldValue } from '../../fields/shared/FieldValue/FieldClassificationGoldValue';
import { FieldExtractionGoldValue } from '../../fields/shared/FieldValue/FieldExtractionGoldValue';
import {
  FieldClassificationValue,
  FieldExtractionValue,
  FieldValue,
} from '../../fields/types';
import { prepareClassificationOptions } from '../../fields/utils';
import { PromptModelTestCase } from '../../types';

interface Props {
  feedback: string;
  goldValue: Nullable<FieldValue>;
  testCase: PromptModelTestCase;
  onUpdateFeedback: (value: string) => void;
  onUpdateGoldValue: (value: FieldValue) => void;
  readOnly?: boolean;
}

export const ReviewGoldValueFeedback = ({
  feedback,
  goldValue,
  readOnly,
  testCase,
  onUpdateFeedback,
  onUpdateGoldValue,
}: Props) => {
  const dispatch = useDispatch();

  const modelVersion = useSelector(selectors.selectFieldAiActiveModel);

  const config = useSelector(selectors.selectFieldAiConfig);
  const classificationOptions = config.fieldClassification
    ? prepareClassificationOptions(config, modelVersion?.testCases ?? [])
    : [];

  const isFeedbackValid = feedback.length <= FEEDBACK_MAX_LENGTH;
  const feedbackDisplayLength = `${feedback.length}/${formatNumber(
    FEEDBACK_MAX_LENGTH,
  )} characters`;
  const feedbackFooter = isFeedbackValid ? feedbackDisplayLength : undefined;
  const feedbackError = !isFeedbackValid ? feedbackDisplayLength : undefined;

  const goldValueInput = config.fieldClassification ? (
    <FormField<FieldClassificationValue, false>
      required
      input={FieldClassificationGoldValue}
      inputProps={{
        initialValue: testCase.modelValue,
        isMulti: config.fieldClassification.isMulti,
        options: classificationOptions,
        onAddOption: (newOption: types.Option<string>) => {
          if (newOption) {
            dispatch(
              actions.setFieldAiOptions([...classificationOptions, newOption]),
            );
          }
        },
        readOnly,
      }}
      label="What are the correct values?"
      name="user-feedback-correct-value"
      placeholder="Select or enter correct value(s)"
      value={goldValue as FieldClassificationValue}
      onChange={(updatedValue) => onUpdateGoldValue(updatedValue as FieldValue)}
    />
  ) : (
    <FormField<FieldExtractionValue, false>
      required
      input={FieldExtractionGoldValue}
      inputProps={{
        type: config.field?.type,
        readOnly,
      }}
      label="What is the correct value?"
      name="user-feedback-correct-value"
      placeholder="Type correct value"
      value={goldValue as FieldExtractionValue}
      onChange={(updatedValue) => onUpdateGoldValue(updatedValue as FieldValue)}
    />
  );

  return (
    <Layout preset="form-fields" w="100%">
      {goldValueInput}
      <FormField
        optional
        description="Provide input to improve the AI."
        disabled={Boolean(readOnly)}
        error={feedbackError}
        footer={feedbackFooter}
        input={TextArea}
        label="Why did the AI get it wrong?"
        name="user-feedback-reason-incorrect"
        placeholder="e.g. The value is usually going to be under the first clause."
        value={feedback}
        onChange={(updatedValue) => onUpdateFeedback(updatedValue as string)}
      />
    </Layout>
  );
};
