import React, { useEffect } from 'react';

import { useToast } from '~/eds';
import { useDebouncedCallback } from '~/hooks';
import { api } from '~/redux';
import { DashboardEntityType } from '~/redux/api/methods';
import { APISearchSuggestion } from '~/redux/api/methods/searchV3';
import { SearchFilter } from '~/types';
import * as componentTypes from '~/types';

const getAsyncOptionsContainer = (
  searchFilter: SearchFilter,
  type: DashboardEntityType = 'ticket',
) => {
  const AsyncOptionsContainer: componentTypes.Filters.FieldRendererProps = ({
    Component,
    innerProps,
  }) => {
    const [
      getFilterSuggestions,
      result,
    ] = api.endpoints.getFilterSuggestionsV2.useLazyQuery();

    const debouncedGetFieldValues = useDebouncedCallback(getFilterSuggestions);
    const { toast } = useToast();

    const { data: response, error, isFetching } = result;
    const selectAllCount = response?.meta?.select_all_count;

    const data = response?.data?.map((entity) => entity.attributes);
    const asyncOptions = {
      data: formatData(data || [], searchFilter),
      error,
      isFetching,
      selectAllCount,
    };
    const fetchOptions = (substringQuery = '') => {
      debouncedGetFieldValues({
        filterId: searchFilter.id,
        type,
        query: substringQuery,
      });
    };
    const asyncProps = {
      asyncOptions,
      onMount: fetchOptions,
      onQuery: fetchOptions,
    };

    useEffect(() => {
      if (error) {
        toast({
          message:
            error?.response?.data?.detail ||
            'An error occurred while loading field suggestions.',
          status: 'danger',
        });
      }
    }, [error]);

    return <Component asyncProps={asyncProps} innerProps={innerProps} />;
  };
  return AsyncOptionsContainer;
};

const toFolderItem = (suggestion: APISearchSuggestion) => {
  return {
    label: suggestion.label,
    leftIcon: 'folder',
    leftIconTooltip: suggestion.sub_label,
    description: suggestion.sub_label,
    value: String(suggestion.value),
  };
};

const formatData = (
  data: APISearchSuggestion[],
  searchFilter: SearchFilter,
) => {
  switch (searchFilter.type) {
    case 'folder':
      return data.map(toFolderItem);

    default:
      return data;
  }
};

export default getAsyncOptionsContainer;
