import { noop, omit } from 'lodash';
import moment from 'moment';
import { Component } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { analyzerResetData } from '~/actions';
import {
  CommentHighlightRenderProps,
  createDocumentProvision,
  deleteDocument,
  deleteDocumentProvision,
  deleteDocumentTable,
  DocumentContentsPanel,
  DocumentKeyProvisions,
  DocumentOverviewPanel,
  DocumentRelatesPanel,
  DocumentsNavigation,
  DocumentsViewPageProvider,
  DocumentVersions,
  getDocumentAlgoStatus,
  getDocumentInformationSections,
  getDocumentProvisions,
  getDocumentTypes,
  getDocumentViewPageData,
  getTabIndexWithinRange,
  renameDocument,
  reprocessDocument,
  styles,
  updateDocumentProvision,
  updateDocumentTable,
} from '~/components/DocumentsViewPage';
import DocumentViewHeader from '~/components/DocumentsViewPage/DocumentViewHeader';
import { trackSegment } from '~/components/SegmentAnalytics';
import AppLayout from '~/components/Shared/AppLayout';
import { Comments } from '~/components/Shared/AppLayout/SidebarContent';
import { filterHighlightsOfDifferentVersion } from '~/components/Shared/AppLayout/SidebarContent/Comments/utils';
import { ColumnLayoutButtonGroup } from '~/components/Shared/ColumnLayoutButtonGroup';
import { PdfHighlighterProvider } from '~/components/Shared/EcPdfViewerHighlighter/components/PdfHighlighterContext';
import {
  EcTab,
  EcTabList,
  EcTabPanel,
  EcTabs,
} from '~/components/Shared/EcTabs';
import { showToast } from '~/components/Shared/EcToast';
import LoadingSpinner from '~/components/Shared/Icons/LoadingSpinner';
import WarningIcon from '~/components/Shared/Icons/WarningIcon';
import { DOC_VIEW_PAGE_LAYOUT_BUTTON_GROUP_TESTID } from '~/constants/testids';
import { PageSidebar } from '~/eds';
import {
  AlgorithmStatusType,
  EntityType,
  FeatureFlagType,
  FileExtensionType,
  HighlightSourceType,
  HighlightType,
  HttpStatusCodeType,
  LayoutType,
  QueryParamType,
} from '~/enums';
import { ChatBot } from '~/features/ask-anything/chatbot';
import { RedirectToXRayModal } from '~/features/x-ray';
import { FlagType, withFlags } from '~/flags';
import { withPermissions } from '~/hocs';
import {
  canUsePdfPreviewHighlighting,
  canUsePostSigActivityFeed,
  testHasFlag,
} from '~/permissions';
import { setContentTabLayout } from '~/reducers/documentsViewPage';
import { chatbotSlice } from '~/redux/slices/chatbot';
import {
  fillCurrentIndexList,
  resetNavigation,
  selectItem as selectNavigationItem,
  setCurrentDocumentAction,
  setIndexById as setNavigationIndexById,
  verifyHandlerId,
} from '~/redux/slices/documentsNavigation';
import pages from '~/redux/slices/pages';
import { redirectToFolder } from '~/services/redirects';
import { ERROR, INFO, SUCCESS } from '~/types/toast.types';
import { FlexLayout } from '~/ui';
import { getStorageItem, setStorageItem } from '~/utils/browser';
import { copyToClipboard } from '~/utils/helper.utils';
import {
  getPageSearchQueryByKey,
  updatePageSearchQuery,
} from '~/utils/searchQuery';
import { getClientInfo, getUserClientInfo } from '~/utils/user';

const CONTENTS_ACTIVE_TAB = 1;
const KEY_CLAUSES_TAB = 2;
const MAX_TAB_INDEX = 4;
const VERSIONS_TAB_INDEX = 3;
const docGroupViewPermission = {
  resourceId: 'document_groups',
  resourceType: 'view',
};
const conversationalAiPermission = {
  resourceId: 'conversational_ai',
  resourceType: 'edit',
};
const permissionsToCheck = [docGroupViewPermission, conversationalAiPermission];
class Page extends Component {
  constructor(props) {
    super(props);

    this.onDocumentDelete = this.onDocumentDelete.bind(this);
    this.onDocumentRename = this.onDocumentRename.bind(this);
    this.loadDocumentData = this.loadDocumentData.bind(this);
    this.refreshDocumentVersions = this.refreshDocumentVersions.bind(this);
    this.loadDocumentProvisions = this.loadDocumentProvisions.bind(this);
    this.handleUpdateProvision = this.handleUpdateProvision.bind(this);
    this.handleRemoveProvision = this.handleRemoveProvision.bind(this);
    this.handleUpdateProvisionTable = this.handleUpdateProvisionTable.bind(
      this,
    );
    this.handleRemoveProvisionTable = this.handleRemoveProvisionTable.bind(
      this,
    );
    this.handleSelectMessageSource = this.handleSelectMessageSource.bind(this);
    this.updateSingleField = this.updateSingleField.bind(this);
    this.getContextEntities = this.getContextEntities.bind(this);

    this.state = {
      documentId: props.match.params.documentId,
      document: {},
      OCRProcessing: {},
      processingInterval: null,
      htmlData: null,
      loading: true,
      informationSections: [],
      activePanel: null,
      dateModified: null,
      documentProvisions: [],
      documentTables: [],
      tabIndex: 0,
      sources: [],
      activeSourceIndex: 0,
      clauseId: null,
    };
  }

  componentDidMount() {
    const { flags } = this.props;
    const enableUnprocessedPDFPreview = flags[FlagType.UnprocessedPDFPreview];
    if (enableUnprocessedPDFPreview) {
      this.checkOCRStatus();
      const processingInterval = setInterval(this.checkOCRStatus, 3000);
      this.setState({ processingInterval });
    }

    const layout = getPageSearchQueryByKey(
      QueryParamType.Layout,
      getStorageItem(QueryParamType.Layout) || LayoutType.TwoColumn,
    );
    this.props.setContentTabLayout(layout);
    setStorageItem(QueryParamType.Layout, layout);
    updatePageSearchQuery({ [QueryParamType.Layout]: layout });

    const activeTab = getPageSearchQueryByKey(
      QueryParamType.ActiveTab,
      CONTENTS_ACTIVE_TAB,
    );
    const tabIndexWithinLimit = getTabIndexWithinRange(
      activeTab,
      MAX_TAB_INDEX,
    );

    this.setState({ tabIndex: tabIndexWithinLimit }, () => {
      updatePageSearchQuery({
        [QueryParamType.ActiveTab]: tabIndexWithinLimit,
      });
      this.loadDocumentData();
    });
    this.loadDocumentProvisions();
  }

  componentWillUnmount() {
    clearInterval(this.state.processingInterval);
    const { pathname } = this.props.history.location;
    const { dispatch } = this.props;
    if (pathname !== '/analyzer/results') {
      this.props.analyzerResetData();
    }
    //reset navigators, TODO: remove on final navigation version implemented
    const isDocumentViewPage = /document\/\d+/.test(pathname);
    if (!isDocumentViewPage) {
      this.props.resetNavigation();
    }

    dispatch(setCurrentDocumentAction(undefined));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.match.params.documentId !== prevState.documentId) {
      return { documentId: nextProps.match.params.documentId };
    } else {
      return null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const CONTENT_TAB_INDEX = 1;
    if (prevState.documentId !== this.state.documentId) {
      this.loadDocumentData();
      this.loadDocumentProvisions();
    } else if (
      this.isPdfPreviewHighlighting() &&
      (prevState.tabIndex !== this.state.tabIndex ||
        this.state.document !== prevState.document) &&
      this.state.tabIndex === CONTENT_TAB_INDEX
    ) {
      /** This verification is a temporary solution and aims to show a toast to the user informing that
       *  the provisions and key information coordinates are being calculated.
       *  This will only be shown to the first user accessing a document without coordinates and the calculation
       * is performed only once. We may remove this after all old document have their coordinates calculated. */
      this.showCoordinatesAreBeingCalculatedToast();
    }
  }

  checkOCRStatus = async () => {
    const { dispatch } = this.props;
    const { pathname } = this.props.history.location;
    try {
      const docResponse = await getDocumentAlgoStatus(this.state.documentId);
      if (docResponse.algo_status.OCR.status === AlgorithmStatusType.Success) {
        dispatch(pages.actions.clearPageBanner({ pathname }));
        if (
          this.state.OCRProcessing.status === AlgorithmStatusType.InProgress
        ) {
          showToast(SUCCESS, 'The document has finished processing');
        }
      }
      if (
        docResponse.algo_status.OCR.status !== AlgorithmStatusType.InProgress &&
        !docResponse.processing_status.includes('QUEUED')
      ) {
        clearInterval(this.state.processingInterval);
      }
      this.setState({
        OCRProcessing: {
          // in certain contexts BE does not return an object for failed state
          status:
            docResponse.algo_status.OCR === -1
              ? -1
              : docResponse.algo_status.OCR.status,
          message: docResponse.algo_status.OCR.message,
          allowReprocess: docResponse.algo_status.allow_reprocess,
          queued: docResponse.processing_status.includes('QUEUED'),
        },
      });
    } catch (e) {
      showToast(ERROR, 'An error occurred while fetching the document status.');
      if (
        e?.response?.status === HttpStatusCodeType.Forbidden ||
        e?.response?.status === HttpStatusCodeType.NotFound
      ) {
        // clear polling interval if user does not have permission to view the document
        clearInterval(this.state.processingInterval);
      }
    }
  };

  handleLayoutChange = (layout) => {
    this.props.setContentTabLayout(layout);
    setStorageItem(QueryParamType.Layout, layout);
    updatePageSearchQuery({ [QueryParamType.Layout]: layout });

    const { currentUser } = this.props;
    trackSegment('User Sets Document Content Tab Layout', {
      layout,
      groupId: getClientInfo(currentUser),
      userId: getUserClientInfo(currentUser),
    });
  };

  errorHandler(error) {
    let errorMessage = 'An error occurred while loading this document.';

    if (error) {
      if (404 === error?.response?.status) {
        errorMessage = "The document you're looking for cannot be found.";
      }
      if (HttpStatusCodeType.Forbidden === error?.response?.status) {
        errorMessage = 'You do not have permission to view this document.';
      }
      this.setState({
        errorLoadingDocument: true,
        loading: false,
        errorMessage,
      });
    }
  }

  loadDocumentData() {
    const { documentId } = this.state;
    const { dispatch } = this.props;

    this.setState({ loading: true });
    getDocumentViewPageData(
      documentId,
      (
        documentRes,
        documentContentRes,
        informationSectionsRes,
        documentTableRes,
      ) => {
        dispatch(
          setCurrentDocumentAction({
            id: documentRes.document,
            versionId: documentRes?.current_version?.id,
            name: documentRes?.current_version?.file_name,
            type: documentRes?.current_version?.file_type,
            folder: documentRes?.parent_folder,
          }),
        );
        this.setState({
          document: documentRes,
          htmlData: documentContentRes.html
            .replace(/\\"/g, '"')
            .replace(/<br\/>/gi, ''),
          informationSections: informationSectionsRes.sections,
          dateModified: informationSectionsRes.date_modified,
          loading: false,
          userVisibilityLevel: documentRes.visibility_level,
          documentTables: documentTableRes,
        });
      },
      (error) => {
        this.errorHandler(error);
      },
    );
  }

  loadDocumentProvisions() {
    const { documentId } = this.state;
    const { params } = this.props.match;

    getDocumentProvisions(documentId)
      .then((documentProvisions) => {
        const provision = documentProvisions.find(
          (provision) => provision.name === params.provisionName,
        );
        const id = provision ? provision.content.clauseId : null;

        this.setState({ clauseId: id });
        this.setState({ documentProvisions });
      })
      .catch((error) => {
        this.errorHandler(error);
      });
  }

  handleTabClick = (activeTabIndex) => {
    if (activeTabIndex === CONTENTS_ACTIVE_TAB) {
      this.loadDocumentProvisions();
    }

    this.setState({ tabIndex: activeTabIndex, clauseIdToScroll: null }, () =>
      updatePageSearchQuery({ [QueryParamType.ActiveTab]: activeTabIndex }),
    );
  };

  handleNavigateToClauseTab = (id) => {
    this.handleTabClick(KEY_CLAUSES_TAB);
    this.setState({ clauseIdToScroll: id });
  };

  shouldDisableSources = (message) => {
    return (
      message.sources?.[0]?.coordinates === null &&
      this.isPdfPreviewHighlighting()
    );
  };

  handleSelectMessageSource = (message, sourceIndex) => {
    if (this.shouldDisableSources(message)) {
      // Nuance PDF highlights not supported
      return;
    }
    if (this.state.tabIndex !== CONTENTS_ACTIVE_TAB) {
      this.setState({ tabIndex: CONTENTS_ACTIVE_TAB }, () => {
        updatePageSearchQuery({
          [QueryParamType.ActiveTab]: CONTENTS_ACTIVE_TAB,
        });
      });
    }

    this.setState({
      sources: this.transformSources(message.sources),
      activeSourceIndex: sourceIndex,
    });

    trackSegment('selectChatMessageSource', {
      messageId: message.id,
      sourceIndex,
    });
  };

  transformSources = (sources) => {
    if (sources?.length > 1) {
      const htmlTokenList = [];
      for (let source of sources) {
        htmlTokenList.push(source.htmlTokens);
      }
      return sources.map((source, index) => ({
        ...source,
        html_tokens: htmlTokenList,
        name: `Source ${index + 1} of ${sources.length}`,
        type: HighlightType.Source,
      }));
    } else {
      return sources.map((source) => ({
        ...source,
        html_tokens: [source.htmlTokens],
      }));
    }
  };

  async updateSingleField() {
    const { params } = this.props.match;
    try {
      const informationSections = await getDocumentInformationSections(
        params.documentId,
      );
      const documentTypes = getDocumentTypes(informationSections.sections);
      const newDocument = {
        ...this.state.document,
        document_types: documentTypes,
      };
      this.setState({
        document: newDocument,
        informationSections: informationSections.sections,
      });
    } catch (e) {
      showToast(ERROR, 'An error occurred while editing the field(s).');
      this.loadDocumentData();
    }
  }

  onDocumentDelete(reason, extraDetails) {
    const { currentUser } = this.props;
    const { document } = this.state;

    deleteDocument(document.id, reason, extraDetails).then(() => {
      showToast(SUCCESS, 'Document has been deleted.');
      redirectToFolder(
        currentUser.client_config.sub_domain,
        document.parent_folder,
      );
    });
  }

  onDocumentRename(documentName, parentId) {
    const { document } = this.state;

    renameDocument(document.id, documentName, parentId)
      .then(() => {
        const newDocument = { ...this.state.document };
        newDocument.document_name = documentName;
        this.setState({ document: newDocument });
        this.loadDocumentData();
      })
      .catch((err) => {
        if (err?.response?.data?.non_field_errors?.[0]) {
          showToast(ERROR, err.response.data.non_field_errors[0]);
        } else if (err.response.status === 422) {
          showToast(ERROR, 'Cannot have "/" in a document name');
        } else {
          showToast(ERROR, 'An error occurred while editing the document.');
        }
      });
  }

  handleCreateProvisionPdf = (
    provisionName,
    position,
    content,
    selectedApplyTo,
    searchQuery,
  ) => {
    const { documentId } = this.state;
    const sampleName = `${provisionName?.substring(0, 20)}_${moment().format(
      'YYYYMMDDHHmmss',
    )}`;

    const coordinates = [
      {
        source: HighlightSourceType.Tagging,
        coordinates: omit(position, 'boundingRect'),
      },
    ];
    const params = {
      documentId,
      provisionName,
      coordinates,
      content,
      selectedApplyTo,
      sampleName,
      searchQuery,
    };
    return this.createProvision(params);
  };

  handleCreateProvisionText = (
    provisionName,
    htmlTokens,
    content,
    selectedApplyTo,
    searchQuery,
  ) => {
    const { documentId } = this.state;

    const sampleName = `${provisionName?.substring(0, 20)}_${moment().format(
      'YYYYMMDDHHmmss',
    )}`;
    const params = {
      documentId,
      provisionName,
      htmlTokens,
      content,
      selectedApplyTo,
      sampleName,
      searchQuery,
    };
    return this.createProvision(params);
  };

  createProvision = (params) => {
    const { flags } = this.props;
    const { clauseId } = this.state;
    const hasQuickAiFiltersFlag = flags[FlagType.QuickAiFilters];

    return createDocumentProvision(params)
      .then(() => {
        const applyToAllDocs = Boolean(params.selectedApplyTo);
        const clauseNameParam = `${QueryParamType.Clause}=${encodeURIComponent(
          params.provisionName,
        )}`;
        const clauseParam = `${QueryParamType.clauseId}=${clauseId}`;
        const enableQuickAiFilters = applyToAllDocs && hasQuickAiFiltersFlag;

        this.loadDocumentProvisions();

        showToast(
          SUCCESS,
          enableQuickAiFilters
            ? 'The clause has been successfully created. Click here to go to your clause'
            : 'The clause has been successfully created.',
          enableQuickAiFilters
            ? `/automation/detail?&${clauseNameParam}&${clauseParam}`
            : null,
        );
      })

      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data.non_field_errors &&
          err.response.data.non_field_errors[0]
        ) {
          showToast(ERROR, err.response.data.non_field_errors[0]);
        } else {
          showToast(ERROR, 'An error occurred while creating the clause.');
        }
      });
  };

  handleUpdateProvision(
    provisionId,
    clauseId,
    selectedProvisionName,
    htmlTokens,
    content,
    selectedApplyTo,
  ) {
    const { documentId } = this.state;

    return updateDocumentProvision(
      documentId,
      provisionId,
      clauseId,
      selectedProvisionName,
      htmlTokens,
      content,
      selectedApplyTo,
    )
      .then(() => {
        showToast(
          SUCCESS,
          `The clause "${selectedProvisionName}" has been successfully saved.`,
        );
        this.loadDocumentProvisions();
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data.non_field_errors &&
          err.response.data.non_field_errors[0]
        ) {
          showToast(ERROR, err.response.data.non_field_errors[0]);
        } else {
          showToast(ERROR, 'An error occurred while updating the clause.');
        }
      });
  }

  handleRemoveProvision(provisionId, selectedProvisionName) {
    const { documentId } = this.state;

    return deleteDocumentProvision(documentId, provisionId)
      .then(() => {
        showToast(
          SUCCESS,
          `The clause "${selectedProvisionName}" has been successfully deleted.`,
        );
        this.loadDocumentProvisions();
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data.non_field_errors &&
          err.response.data.non_field_errors[0]
        ) {
          showToast(ERROR, err.response.data.non_field_errors[0]);
        } else {
          showToast(ERROR, 'An error occurred while deleting the clause.');
        }
      });
  }

  handleUpdateProvisionTable(tableId, tableContent) {
    const { document, documentTables } = this.state;

    updateDocumentTable(document.id, tableId, tableContent)
      .then((res) => {
        const tableIndex = documentTables.results
          .map((table) => table.id)
          .indexOf(tableId);
        const newDocumentTables = { ...documentTables };
        newDocumentTables.results[tableIndex] = res;
        this.setState({ documentTables: newDocumentTables });
        showToast(SUCCESS, 'Table has been updated.');
      })
      .catch(() =>
        showToast(ERROR, 'An error occurred while updating this table.'),
      );
  }

  handleRemoveProvisionTable(tableId) {
    const { document, documentTables } = this.state;

    deleteDocumentTable(document.id, tableId)
      .then(() => {
        const newTables = [
          ...documentTables.results.filter((table) => table.id !== tableId),
        ];
        this.setState({
          documentTables: { ...documentTables, results: [...newTables] },
        });
        showToast(SUCCESS, 'Table has been deleted.');
      })
      .catch(() =>
        showToast(ERROR, 'An error occurred while deleting this table.'),
      );
  }

  isPdfPreviewHighlighting = () => {
    const { currentUser } = this.props;
    const { document } = this.state;
    const filetypesWithPdfPreviewHighlighting = [
      FileExtensionType.Pdf,
      FileExtensionType.Jpg,
      FileExtensionType.Jpeg,
      FileExtensionType.Png,
      FileExtensionType.Tif,
      FileExtensionType.Tiff,
    ];
    return (
      canUsePdfPreviewHighlighting(currentUser) &&
      document &&
      filetypesWithPdfPreviewHighlighting.includes(document.file_type)
    );
  };

  refreshDocumentVersions() {
    this.loadDocumentData();
    this.setState({ tabIndex: VERSIONS_TAB_INDEX }, () => {
      updatePageSearchQuery({ [QueryParamType.ActiveTab]: VERSIONS_TAB_INDEX });
    });
  }

  onCopyLink(comment) {
    const linkConstructed = `${window.location.origin}${window.location.pathname}?commentId=${comment.id}&activeTab=1`;
    copyToClipboard(linkConstructed, undefined, undefined, undefined, false);
  }

  onHighlightClick(highlightId, highlightProps) {
    if (this.state.tabIndex !== CONTENTS_ACTIVE_TAB) {
      this.setState({ tabIndex: CONTENTS_ACTIVE_TAB }, () => {
        updatePageSearchQuery({
          [QueryParamType.ActiveTab]: CONTENTS_ACTIVE_TAB,
        });
      });
    }
    highlightProps.setActiveHighlight(highlightId, true);
  }

  showCoordinatesAreBeingCalculatedToast = () => {
    const { document } = this.state;
    if (document && document.is_processing_coordinates)
      showToast(
        INFO,
        'Some AI output clauses and key information are still loading in the background. Please refresh your page after 2 minutes.',
        null,
        10000,
      );
  };

  onCloseSidebar(commentHighlightRenderProps) {
    commentHighlightRenderProps.setActiveHighlight(null);
    commentHighlightRenderProps.setActiveCommentId(null);
    this.setState({ activePanel: null });
  }

  getContextEntities() {
    const { document, documentId } = this.state;
    const contextEntities = [];
    if (document?.current_version?.id) {
      contextEntities.push({
        id: document?.current_version?.id
          ? String(document?.current_version?.id)
          : null,
        type: EntityType.DocumentVersion,
      });
    }

    if (documentId) {
      contextEntities.push({
        id: documentId,
        type: EntityType.DocumentHandler,
      });
    }
    return contextEntities;
  }

  openConversationAIPanel = ({ isAskAiButton = false }) => {
    const { document } = this.state;
    const { dispatch, flags } = this.props;
    const context = this.getContextEntities();
    const documentVersionEntity = context.find(
      (context) => context.type === EntityType.DocumentVersion,
    );

    const documentHandlerEntity = context.find(
      (context) => context.type === EntityType.DocumentHandler,
    );
    const hasVersionId = Boolean(document?.current_version?.id);
    const entity = hasVersionId ? documentVersionEntity : documentHandlerEntity;
    const hasAskAnything = flags[FlagType.AskAnything];

    if (hasAskAnything) {
      this.setState({ isChatOpened: false });
      dispatch(chatbotSlice.actions.toggleChatbot());
    } else {
      this.setState({ activePanel: 'chatbot' });
    }

    trackSegment(isAskAiButton ? 'selectAskAI' : 'selectChat', {
      name: isAskAiButton ? 'Ask AI' : 'Conversational AI',
      entity,
    });
  };

  displayBanner({ status, message, allowReprocess, queued }) {
    const { dispatch } = this.props;
    const { pathname } = this.props.history.location;

    if (status === AlgorithmStatusType.InProgress || queued) {
      dispatch(
        pages.actions.setPageBanner({
          banner: {
            message:
              'Evisort AI is processing this document. It will be available for editing in a few minutes.',
            status: 'ai',
          },
          pathname,
        }),
      );
    } else if (status === AlgorithmStatusType.Failed) {
      dispatch(
        pages.actions.setPageBanner({
          banner: {
            message,
            status: 'danger',
            action: allowReprocess
              ? {
                  text: 'Process document again',
                  tooltip: 'Process document again',
                  onClick: async () => {
                    await reprocessDocument(this.state.documentId);
                    this.checkOCRStatus();
                    const processingInterval = setInterval(
                      this.checkOCRStatus,
                      3000,
                    );
                    this.setState({ processingInterval });
                  },
                }
              : null,
          },
          pathname,
        }),
      );
    }
  }

  render() {
    const activeCommentIdFromUrl = getPageSearchQueryByKey(
      QueryParamType.CommentId,
      null,
    );

    const {
      classes,
      currentUser,
      flags,
      history,
      navigationContext,
      isChatbotVisible,
      sources,
      activeSourceIndex,
      askAIEntity,
    } = this.props;

    const hasConversationalAIPermission = this.props.checkPermission(
      conversationalAiPermission.resourceId,
      conversationalAiPermission.resourceType,
    );

    const enableConversationalAI = flags[FlagType.ConversationalAI];
    const hasAskAnything = flags[FlagType.AskAnything];

    const {
      clauseIdToScroll,
      document,
      OCRProcessing,
      loading,
      errorLoadingDocument,
      htmlData,
      informationSections,
      activePanel,
      dateModified,
      documentProvisions,
      documentId,
      userVisibilityLevel,
      documentTables,
      tabIndex,
    } = this.state;

    const disableChat =
      OCRProcessing.status === AlgorithmStatusType.Failed ||
      OCRProcessing.status === AlgorithmStatusType.InProgress;

    const documentEntity = document.document
      ? {
          id: document.document,
          type: EntityType.Document,
        }
      : {};

    const enableUnprocessedPDFPreview = flags[FlagType.UnprocessedPDFPreview];
    if (
      enableUnprocessedPDFPreview &&
      (OCRProcessing.status === AlgorithmStatusType.InProgress ||
        OCRProcessing.status === AlgorithmStatusType.Failed)
    ) {
      this.displayBanner(OCRProcessing);
    }

    const contextEntities = this.getContextEntities();

    const documentVersionEntity = contextEntities.find(
      (context) => context.type === EntityType.DocumentVersion,
    );

    const documentHandlerEntity = contextEntities.find(
      (context) => context.type === EntityType.DocumentHandler,
    );

    const provisionsForRender = documentProvisions.map((item) => {
      const contentText =
        item.content && item.content.length
          ? item.content.map((content) => (
              <p key={content.id} id={content.id}>
                {content.text}
              </p>
            ))
          : '';
      return {
        content: [contentText],
        name: item.name,
      };
    });

    const hasEditClauseFlag = testHasFlag(FeatureFlagType.EditClause)(
      currentUser,
    );
    const renderDocumentView = (
      commentHighlightRenderProps,
      permissionRenderProps,
    ) => {
      return (
        <div className={classes.documentViewPage}>
          {!errorLoadingDocument && (
            <DocumentViewHeader
              user={currentUser}
              userVisibilityLevel={userVisibilityLevel}
              documentId={documentId}
              classes={classes}
              history={history}
              documentData={document}
              loading={loading}
              OCRProcessing={OCRProcessing}
              handleDocumentDelete={this.onDocumentDelete}
              handleDocumentRename={this.onDocumentRename}
              refreshDocumentVersions={this.refreshDocumentVersions}
              askAiButtonProps={{
                onClick: () =>
                  this.openConversationAIPanel({ isAskAiButton: true }),
                disabled: disableChat,
                tooltip: getChatTooltip(OCRProcessing.status),
              }}
            />
          )}
          {loading ? (
            <div className={classes.loadingContainer}>
              <LoadingSpinner size="medium" />
            </div>
          ) : errorLoadingDocument ? (
            <div className={classes.errorLoadingDocument}>
              <WarningIcon size="24" red />
              <span>{this.state.errorMessage}</span>
            </div>
          ) : (
            <div className={classes.documentContents}>
              <EcTabs
                className={classes.documentTabs}
                selectedIndex={tabIndex}
                onSelect={this.handleTabClick}
              >
                <FlexLayout>
                  <FlexLayout flex="auto">
                    <EcTabList className={classes.documentTabList}>
                      <EcTab>Overview</EcTab>
                      <EcTab>Contents</EcTab>
                      <EcTab>
                        {hasEditClauseFlag ? 'Key Clauses' : 'Key Provisions'}
                      </EcTab>
                      <EcTab>Versions</EcTab>
                      {this.props.checkPermission(
                        docGroupViewPermission.resourceId,
                        docGroupViewPermission.resourceType,
                      ) && <EcTab>Related Documents</EcTab>}
                    </EcTabList>
                  </FlexLayout>
                  <FlexLayout flex="none" alignItems="center" space={4}>
                    <DocumentsNavigation
                      context={navigationContext}
                      documentId={documentId}
                      fillCurrentIndexList={this.props.fillCurrentIndexList}
                      verifyHandlerId={this.props.verifyHandlerId}
                      history={history}
                      location={window.document.location}
                      selectItem={selectNavigationItem}
                      setIndexById={this.props.setNavigationIndexById}
                    />

                    {tabIndex === 1 && (
                      <ColumnLayoutButtonGroup
                        className={DOC_VIEW_PAGE_LAYOUT_BUTTON_GROUP_TESTID}
                        onChange={this.handleLayoutChange}
                        layout={this.props.contentTabLayout}
                      />
                    )}
                  </FlexLayout>
                </FlexLayout>

                <EcTabPanel>
                  <DocumentOverviewPanel
                    OCRProcessing={OCRProcessing}
                    documentId={documentId}
                    userVisibilityLevel={userVisibilityLevel}
                    sections={informationSections}
                    dateModified={dateModified}
                    onEditSubmitted={this.loadDocumentData}
                  />
                </EcTabPanel>
                <EcTabPanel forceRender>
                  <DocumentsViewPageProvider
                    value={{
                      document: { id: document.id },
                      refreshField: this.updateSingleField,
                    }}
                  >
                    <DocumentContentsPanel
                      user={currentUser}
                      userVisibilityLevel={userVisibilityLevel}
                      documentHandlerId={document.id}
                      documentId={document.document}
                      documentVersionId={document.current_version.id}
                      fileType={document.file_type}
                      handleNavigateToClauseTab={this.handleNavigateToClauseTab}
                      htmlData={htmlData}
                      sections={informationSections}
                      onCreateProvision={
                        this.isPdfPreviewHighlighting()
                          ? this.handleCreateProvisionPdf
                          : this.handleCreateProvisionText
                      }
                      onUpdateProvision={this.handleUpdateProvision}
                      onRemoveProvision={this.handleRemoveProvision}
                      documentProvisions={documentProvisions}
                      OCRStatus={
                        enableUnprocessedPDFPreview &&
                        OCRProcessing.status !== undefined
                          ? OCRProcessing.status
                          : document.algo_status.OCR.status
                      }
                      queued={OCRProcessing.queued}
                      displaySideInfoIn2Cols={
                        this.props.contentTabLayout === LayoutType.ThreeColumn
                      }
                      //highlights props
                      sources={
                        sources.length && isChatbotVisible
                          ? this.transformSources(sources)
                          : this.state.sources
                      }
                      activeSourceIndex={
                        isChatbotVisible && activeSourceIndex !== null
                          ? activeSourceIndex
                          : this.state.activeSourceIndex
                      }
                      //commentHighlightRenderProps is undefined in case FeatureFlagType.PostSigActivityFeed is OFF
                      {...commentHighlightRenderProps}
                      onCreateComment={
                        commentHighlightRenderProps
                          ? commentHighlightRenderProps.onCreateComment
                          : noop
                      }
                      setIsDocumentLoading={
                        commentHighlightRenderProps
                          ? commentHighlightRenderProps.setIsDocumentLoading
                          : noop
                      }
                      setActiveHighlight={
                        commentHighlightRenderProps
                          ? commentHighlightRenderProps.setActiveHighlight
                          : noop
                      }
                      //permissionRenderProps is undefined in case FeatureFlagType.PostSigActivityFeed is OFF
                      {...permissionRenderProps}
                    />
                  </DocumentsViewPageProvider>
                </EcTabPanel>
                <EcTabPanel>
                  <DocumentKeyProvisions
                    documentId={documentId}
                    user={currentUser}
                    rebateTables={documentTables.results}
                    keyProvisions={provisionsForRender}
                    clauseIdToScroll={clauseIdToScroll}
                    paymentTables={document.payment_table}
                    handleTableUpdate={this.handleUpdateProvisionTable}
                    handleTableDelete={this.handleRemoveProvisionTable}
                  />
                </EcTabPanel>
                <EcTabPanel>
                  <DocumentVersions
                    user={currentUser}
                    documentId={documentId}
                    userVisibilityLevel={userVisibilityLevel}
                    reloadDocumentContent={this.loadDocumentData}
                  />
                </EcTabPanel>
                {this.props.checkPermission(
                  docGroupViewPermission.resourceId,
                  docGroupViewPermission.resourceType,
                ) && (
                  <EcTabPanel>
                    <DocumentRelatesPanel
                      documentHandlerId={documentId}
                      documentId={document.document}
                      reloadDocumentContent={this.loadDocumentData}
                    />
                  </EcTabPanel>
                )}
              </EcTabs>
            </div>
          )}
          <RedirectToXRayModal />
        </div>
      );
    };

    const hasVersionId = Boolean(document?.current_version?.id);
    const conversationalAIEntity = hasVersionId
      ? documentVersionEntity
      : documentHandlerEntity;
    const chatbotEntity = hasAskAnything ? askAIEntity : conversationalAIEntity;
    if (canUsePostSigActivityFeed(currentUser)) {
      return (
        <PdfHighlighterProvider>
          <CommentHighlightRenderProps
            activeCommentId={activeCommentIdFromUrl}
            entity={documentEntity}
            context={contextEntities}
            highlightEntity={documentVersionEntity}
            render={(commentHighlightRenderProps) => (
              <AppLayout
                sidebar={
                  <PageSidebar
                    isExpanded={
                      Boolean(commentHighlightRenderProps.activeCommentId) ||
                      activePanel
                    }
                    panels={{
                      chatbot:
                        enableConversationalAI && hasConversationalAIPermission
                          ? {
                              action: {
                                disabled: disableChat,
                                icon: 'evisort-ai-static',
                                label: 'chatbot',
                                tooltip: getChatTooltip(OCRProcessing.status),
                                value: 'chatbot',
                              },
                              component:
                                chatbotEntity && !hasAskAnything ? (
                                  <ChatBot
                                    entity={chatbotEntity}
                                    shouldDisableSources={
                                      this.shouldDisableSources
                                    }
                                    onSelectMessageSource={
                                      this.handleSelectMessageSource
                                    }
                                  />
                                ) : null,
                            }
                          : {},
                      comments: {
                        action: {
                          icon: 'message',
                          label: 'comments',
                          tooltip: 'Comments',
                          value: 'comments',
                        },
                        component: (
                          <Comments
                            width="m"
                            activeCommentId={
                              commentHighlightRenderProps.activeCommentId
                            }
                            activeHighlightId={
                              commentHighlightRenderProps.activeHighlightId
                            }
                            entity={documentEntity}
                            context={contextEntities}
                            filterComment={(comment) =>
                              filterHighlightsOfDifferentVersion(comment, {
                                type: documentVersionEntity?.type,
                                id: documentVersionEntity?.id,
                              })
                            }
                            highlightResolver={
                              commentHighlightRenderProps.highlightResolver
                            }
                            isExpanded={Boolean(
                              commentHighlightRenderProps.activeCommentId,
                            )}
                            onCopyLink={this.onCopyLink}
                            onUpdateFilters={
                              commentHighlightRenderProps.onUpdateFilters
                            }
                            onHighlightClick={(highlight) =>
                              this.onHighlightClick(
                                highlight,
                                commentHighlightRenderProps,
                              )
                            }
                          />
                        ),
                      },
                    }}
                    selectedPanel={
                      commentHighlightRenderProps.activeCommentId
                        ? 'comments'
                        : activePanel
                    }
                    width="m" // fixed since it doesn't use the expanse function yet
                    onClose={() =>
                      this.onCloseSidebar(commentHighlightRenderProps)
                    }
                    onUpdate={(updatedPanel) => {
                      switch (updatedPanel) {
                        case 'comments':
                          this.setState({ activePanel: 'comments' });
                          break;
                        case 'chatbot':
                          this.openConversationAIPanel({
                            isAskAiButton: false,
                          });
                          break;
                        default:
                          break;
                      }
                    }}
                  />
                }
              >
                {renderDocumentView(commentHighlightRenderProps, {})}
              </AppLayout>
            )}
          />
        </PdfHighlighterProvider>
      );
    }

    return renderDocumentView();
  }
}

const mapStateToProps = ({
  currentUser,
  documentsNavigation: { context: navigationContext },
  documentsViewPage: { contentTabLayout },
  chatbot: { sources, activeSourceIndex, isVisible, entity },
}) => ({
  currentUser,
  contentTabLayout,
  navigationContext,
  sources,
  activeSourceIndex,
  isChatbotVisible: isVisible,
  askAIEntity: entity,
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        analyzerResetData,
        fillCurrentIndexList,
        verifyHandlerId,
        setContentTabLayout,
        setNavigationIndexById,
        resetNavigation,
      },
      dispatch,
    ),
  };
}

const getChatTooltip = (status) => {
  const baseTooltip = 'Ask AI';
  switch (status) {
    case AlgorithmStatusType.Failed:
      return baseTooltip + '\n(Document failed to process)';
    case AlgorithmStatusType.InProgress:
      return baseTooltip + '\n(Document is still processing)';
    default:
      return baseTooltip;
  }
};

Page = connect(mapStateToProps, mapDispatchToProps)(Page);

export default withPermissions(
  injectSheet(styles)(withRouter(withFlags(Page))),
  permissionsToCheck,
);
