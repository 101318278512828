import { noop } from 'lodash';
import React, { useMemo } from 'react';

import {
  Actions,
  types as edsTypes,
  Feedback,
  Layout,
  Markdown,
  Text,
  typedMemo,
  User,
} from '~/eds';

import { BlinkingCursor } from './BlinkingCursor';
import { CHOICE_DISPLAY_LENGTH, MESSAGE_ACTIONS_CLASSNAME } from './constants';
import { FeedbackDetails, GetUserTheme, Message as MessageType } from './types';

interface Props<Source = unknown, InlineSource = unknown> {
  message: MessageType<Source, InlineSource>;
  user: edsTypes.User;
  getUserTheme: GetUserTheme;
  onChoose: (
    message: MessageType<Source, InlineSource>,
    choice: string,
  ) => void;
  onSelectSource: (
    message: MessageType<Source, InlineSource>,
    sourceIndex: number,
  ) => void;
  onSubmitFeedback: (
    message: MessageType<Source, InlineSource>,
    feedbackDetails: FeedbackDetails,
  ) => void;
  disableChoices?: boolean;
  index?: number;
  isReplying?: boolean;
  messageActions?: edsTypes.UserAction[];
  shouldDisableSources?: (
    message: MessageType<Source, InlineSource>,
  ) => boolean;
}

export const Message = typedMemo(
  <Source extends unknown, InlineSource extends unknown>({
    disableChoices,
    getUserTheme,
    index = -1,
    isReplying,
    message,
    messageActions = [],
    onChoose,
    onSelectSource,
    onSubmitFeedback,
    shouldDisableSources,
    user,
  }: Props<Source, InlineSource>) => {
    const handleSubmitFeedback = (value: edsTypes.Nullable<boolean>) => {
      onSubmitFeedback(message, { value });
    };
    const { choices = [], sources = [], text, timestamp, userType } = message;

    const { bg } = getUserTheme(userType);

    const messageChoices = useMemo(
      () =>
        (message.choices ?? []).map((choice) => {
          /** TODO - `Button` should handle this truncation internally after design proposes the display length limit */
          const isLongText = choice.length > CHOICE_DISPLAY_LENGTH;
          return {
            disabled: disableChoices,
            level: 'secondary' as const,
            text: isLongText
              ? choice.substring(0, CHOICE_DISPLAY_LENGTH) + '…'
              : choice,
            tooltip: isLongText ? choice : undefined,
            onClick: () => onChoose(message, choice),
          };
        }),
      [disableChoices, message],
    );

    const sourcesDisabled = useMemo(
      () => shouldDisableSources?.(message) ?? false,
      [shouldDisableSources],
    );
    const messageSources = useMemo(
      () =>
        (message.sources ?? []).map((_source, sourceIndex) => ({
          level: 'secondary' as const,
          text: `${sourceIndex + 1}`,
          tooltip: sourcesDisabled
            ? 'Source highlights are not supported for this document.'
            : `View source ${sourceIndex + 1}`,
          onClick: () => onSelectSource(message, sourceIndex),
          disabled: sourcesDisabled,
        })),
      [message],
    );

    const userOptions = {
      size: 'l' as const,
      date: timestamp,
    };

    return (
      <Layout
        px={4}
        py={6}
        spacing={4}
        bg={bg}
        className={userType}
        styles={componentStyles.message}
        onMouseEnter={noop}
      >
        <User mode="avatar" user={user} options={userOptions} />
        <Layout
          direction="column"
          flex="auto"
          justify="center"
          minW={0}
          position="relative"
          spacing={2}
        >
          <Markdown text={text} />
          {choices && <Actions wrap actions={messageChoices} size="s" />}
          {sources.length > 0 && (
            <Layout align="center" spacing={1}>
              <Text preset="help">Based on: </Text>
              <Actions wrap actions={messageSources} size="s" />
            </Layout>
          )}
          {isReplying && <BlinkingCursor />}
        </Layout>
        {index > 0 && (
          <Layout
            bg="background"
            borderRadius={4}
            className={MESSAGE_ACTIONS_CLASSNAME}
            styles={componentStyles.actions}
          >
            <Actions actions={messageActions} />
            {Boolean(messageActions.length) && (
              <Feedback
                value={message.feedbackDetails?.value ?? null}
                onChange={handleSubmitFeedback}
              />
            )}
          </Layout>
        )}
      </Layout>
    );
  },
);

const componentStyles = {
  actions: {
    boxShadow: 'raised',
    bottom: 0,
    position: 'absolute',
    right: 4,
    transform: 'translateY(-10%)',
    visibility: 'hidden',
  },
  message: {
    position: 'relative',
    [`&:hover .${MESSAGE_ACTIONS_CLASSNAME}`]: {
      visibility: 'visible',
    },
    [`&.current:hover + .other .${MESSAGE_ACTIONS_CLASSNAME}`]: {
      visibility: 'visible',
    },
  },
};
