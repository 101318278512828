import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { withRouter } from 'react-router-dom';

import { withIsSelectedHash, withTreeNodeKeyDownHandler } from '~/hocs';
import { TreeNode } from '~/utils/TreeNode';

import EcFolderTreeItem from '../../Shared/EcFolderTreeItem';
import FolderIcon from '../../Shared/Icons/FolderIcon';
import LoadingSpinner from '../../Shared/Icons/LoadingSpinner';
import { getFolderTree } from '../../UploadPage/UploadPage.data';
import styles from './EcFolderPanel.styles';

const FolderTreeItem = withRouter(withTreeNodeKeyDownHandler(EcFolderTreeItem));

class EcFolderPanel extends Component {
  state = {
    folderTree: null,
  };

  componentDidMount() {
    this.setState({ loading: true });
    getFolderTree()
      .then((folderTree) => {
        this.setState({ folderTree: new TreeNode(folderTree), loading: false });
      })
      .catch(() => {
        this.setState({ loading: false, errorLoading: true });
      });
  }

  goToFolder = (folder) => this.props.history.push(`/documents/${folder.id}`);

  renderFolderTree(autoFocus = false) {
    const {
      classes,
      folderPathId,
      isRootFolder,
      getIsSelected,
      setIsSelected,
    } = this.props;
    const { folderTree, loading } = this.state;

    return (
      <div className={classes.folderTree}>
        {loading ? (
          <div className={classes.loadingContainer}>
            <LoadingSpinner size="medium" />
          </div>
        ) : folderTree ? (
          <FolderTreeItem
            autoFocus={autoFocus}
            firstToOpenId={folderPathId ? Number(folderPathId) : folderTree.id}
            folderIdsSelected={[]}
            folder={folderTree}
            forLinkOnly={true}
            getIsSelected={getIsSelected}
            isMultiSelect={false}
            isRootFolder={isRootFolder}
            onSelect={this.goToFolder}
            setIsSelected={setIsSelected}
          />
        ) : null}
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.folderPanelContainer}>
        <div className={classes.folderPanelHeader}>
          <span className={classes.folderPanelHeaderText}>
            <FolderIcon yellow />
            Go to a Folder
          </span>
        </div>
        <div className={classes.folderPanelContent}>
          <div />
          <div className={classes.modalBodyContent}>
            {this.renderFolderTree(true)}
          </div>
        </div>
      </div>
    );
  }
}

export default injectSheet(styles)(
  withRouter(withIsSelectedHash(EcFolderPanel)),
);
