import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';

import { formatDate, parseDate } from '~/eds';
import { DataFieldType } from '~/enums';
import { FlagType, withFlags } from '~/flags';

import { black5 } from '../../../assets/shared-styles/general';
import { DATE_PERIOD_OPTIONS } from '../../Modals/AlertModal/AlertModal.options';
import EcButton from '../EcButton';
import EcDatepicker from '../EcDatepicker';
import EcInput from '../EcInput';
import EcMultipleSelectValue from '../EcMultipleSelectValue';
import EcSelect from '../EcSelect';
import {
  mapOptionsToValues,
  mapValuesToOptions,
} from '../EcSelect/EcSelect.utils';
import EcTooltip from '../EcTooltip';
import FieldValuesMultiSelect from '../FieldValuesMultiSelect';
import FieldValuesSingleSelect from '../FieldValuesSingleSelect';
import withHover from '../HOCs/withHover';
import ChevronDownIcon from '../Icons/ChevronDownIcon';
import RemoveIcon from '../Icons/RemoveIcon';
import SubArrowIcon from '../Icons/SubArrowIcon';
import {
  ATTACHMENT_OPTIONS,
  BOOLEAN_OPTIONS,
  DOCUMENT_ID_OPTIONS,
  getRelationOptions,
} from './EcFilterField.options';
import styles from './EcFilterField.styles';
import {
  checkBetweenDatesRelationAndResetValues,
  coerceInputValueToNumber,
} from './EcFilterField.utils';

const RemoveIconWithHover = withHover(RemoveIcon);

class EcFilterField extends Component {
  constructor(props) {
    super(props);

    this.handleOnRelationChange = this.handleOnRelationChange.bind(this);
  }

  checkValuesAndResetError(value) {
    const { relation, type } = this.props;

    if (value && this.props.hasOwnProperty('error')) {
      if (type === DataFieldType.BOOLEAN || type === DataFieldType.ATTACHMENT) {
        return { ...value, error: false };
      }

      if (relation) {
        switch (type) {
          case DataFieldType.ARRAY_MULTIPLE:
            if (value.first_value && value.first_value.length) {
              return { ...value, error: false };
            }
            break;
          case DataFieldType.ARRAY_SINGLE:
          case DataFieldType.STRING:
          case DataFieldType.TEXT_AREA:
            if (value.first_value) {
              return { ...value, error: false };
            }
            break;
          case DataFieldType.DATE:
          case DataFieldType.NUMBER:
            if (
              ['next', 'last', 'between_dates', 'between'].includes(
                relation.value,
              )
            ) {
              const otherValue =
                Object.keys(value)[0] === 'first_value'
                  ? 'second_value'
                  : 'first_value';
              if (this.props[otherValue]) {
                return { ...value, error: false };
              }
            } else {
              return { ...value, error: false };
            }
            break;
          default:
            break;
        }
      }
    }

    return value;
  }

  handleOnValueChange(value) {
    const { type } = this.props;

    let newValue = { ...value };
    newValue = this.checkValuesAndResetError(newValue);

    if (
      (type === DataFieldType.BOOLEAN || type === DataFieldType.ATTACHMENT) &&
      value &&
      !this.isRelationBlank(value.value)
    ) {
      newValue['relation'] = null;
    }

    this.props.onChange(newValue);
  }

  isRelationBlank(value) {
    return ['is_blank', 'is_not_blank'].includes(value);
  }

  resetDateFieldCondition(previousRelation, relation) {
    return (
      (previousRelation.value !== 'next' &&
        previousRelation.value !== 'last' &&
        (relation.value === 'next' || relation.value === 'last')) ||
      ((previousRelation.value === 'next' ||
        previousRelation.value === 'last') &&
        relation.value !== 'next' &&
        relation.value !== 'last')
    );
  }

  // Reset `first_value` and `second_value` if `is_blank` or `is_not_blank` options are selected.
  checkBlankRelationAndResetValues(fieldProperties) {
    const { relation } = fieldProperties;
    if (relation && this.isRelationBlank(relation.value)) {
      return { ...fieldProperties, first_value: null, second_value: null };
    }

    return fieldProperties;
  }

  // Reset `first_value` and `second_value` due to conflicts in date field input types (datepicker vs. text input + select)
  checkAndResetDateValues(fieldProperties) {
    const { type, relation: previousRelation } = this.props;
    if (type === DataFieldType.DATE && previousRelation) {
      const resetCondition = this.resetDateFieldCondition(
        previousRelation,
        fieldProperties.relation,
      );
      if (resetCondition) {
        return { ...fieldProperties, first_value: null, second_value: null };
      }
    }

    return fieldProperties;
  }

  checkValidityAndResetError(fieldProperties) {
    const { first_value } = this.props;
    const { relation } = fieldProperties;

    if (
      relation &&
      (first_value || this.isRelationBlank(relation.value)) &&
      this.props.hasOwnProperty('error')
    ) {
      fieldProperties = { ...fieldProperties, error: false };
    }

    return fieldProperties;
  }

  handleOnRelationChange(relation) {
    let fieldProperties = { relation };
    fieldProperties = this.checkBlankRelationAndResetValues(fieldProperties);
    fieldProperties = this.checkAndResetDateValues(fieldProperties);
    fieldProperties = this.checkValidityAndResetError(fieldProperties);
    fieldProperties = checkBetweenDatesRelationAndResetValues(fieldProperties);

    this.props.onChange(fieldProperties);
  }

  renderNumberContainer() {
    const { classes, relation, first_value, second_value, error } = this.props;

    return (
      <div className={classes.valuesContainer}>
        <div className={classes.fieldContainer}>
          <EcInput
            input={{
              value: coerceInputValueToNumber(first_value),
              onChange: (e) =>
                this.handleOnValueChange({
                  first_value: coerceInputValueToNumber(e.target.value),
                }),
            }}
            placeholder="enter amount"
            type="number"
            min="0"
            max="1000000000"
            step="0.01"
            error={error && !first_value}
          />
        </div>
        {relation && relation.value === 'between' && (
          <Fragment>
            <span className={classes.horizontalAndSeparator}>and</span>
            <div className={classes.fieldContainer}>
              <EcInput
                input={{
                  value: coerceInputValueToNumber(second_value),
                  onChange: (e) =>
                    this.handleOnValueChange({
                      second_value: coerceInputValueToNumber(e.target.value),
                    }),
                }}
                placeholder="enter amount"
                type="number"
                min="0"
                max="1000000000"
                step="0.01"
                error={error && !second_value}
              />
            </div>
          </Fragment>
        )}
      </div>
    );
  }

  renderStringContainer() {
    const { classes, first_value, error } = this.props;

    return (
      <div className={classes.valuesContainer}>
        <div className={classes.fieldContainer}>
          <EcInput
            input={{
              value: first_value || '',
              onChange: (e) =>
                this.handleOnValueChange({ first_value: e.target.value }),
            }}
            placeholder="enter value"
            error={error && (!first_value || !first_value.trim())}
          />
        </div>
      </div>
    );
  }

  renderDateContainer() {
    const {
      classes,
      relation,
      first_value,
      second_value,
      error,
      name,
      flags,
    } = this.props;

    if (relation && (relation.value === 'next' || relation.value === 'last')) {
      const defaultValue = second_value
        ? mapValuesToOptions([second_value])
        : null;

      return (
        <div className={classes.valuesContainer}>
          <EcInput
            input={{
              value: first_value || '',
              type: 'text',
              onChange: (e) =>
                this.handleOnValueChange({
                  first_value: Number(e.target.value),
                }),
            }}
            width="64px"
            error={error && !first_value}
          />
          <hr className={classes.blueLine} />
          <div className={classes.fieldContainer}>
            <EcSelect
              aria-label={name}
              value={defaultValue}
              options={DATE_PERIOD_OPTIONS}
              onChange={({ value }) =>
                this.handleOnValueChange({ second_value: value })
              }
              error={error && !second_value}
            />
          </div>
        </div>
      );
    }

    const firstFieldContainerClass =
      !first_value && error
        ? classes.fieldContainerError
        : classes.fieldContainer;
    const secondFieldContainerClass =
      !second_value && error
        ? classes.fieldContainerError
        : classes.fieldContainer;

    const enableRefactorMoment = flags[FlagType.DeprecateMoment];

    return (
      <div className={classes.valuesContainer}>
        <div className={firstFieldContainerClass}>
          <EcDatepicker
            date={
              first_value ? parseDate(first_value, enableRefactorMoment) : null
            }
            onDateChange={(date) => {
              this.handleOnValueChange({ first_value: formatDate(date) });
            }}
            maxDate={
              second_value
                ? parseDate(second_value, enableRefactorMoment)
                : null
            }
            placeholder="Select date"
            isClearable={true}
          />
        </div>
        {relation && relation.value === 'between_dates' && (
          <Fragment>
            <span className={classes.horizontalAndSeparator}>and</span>
            <div className={secondFieldContainerClass}>
              <EcDatepicker
                date={
                  second_value
                    ? parseDate(second_value, enableRefactorMoment)
                    : null
                }
                onDateChange={(date) => {
                  this.handleOnValueChange({ second_value: formatDate(date) });
                }}
                minDate={
                  first_value
                    ? parseDate(first_value, enableRefactorMoment)
                    : null
                }
                placeholder="Select date"
                isClearable={true}
              />
            </div>
          </Fragment>
        )}
      </div>
    );
  }

  renderArraySingleContainer() {
    const { classes, error, first_value } = this.props;

    const sharedProps = {
      value: first_value ? mapValuesToOptions([first_value]) : null,
      onChange: ({ value }) => this.handleOnValueChange({ first_value: value }),
      error: error && !first_value,
    };

    return (
      <div
        className={classes.valuesContainer}
        data-testid="ec-filter-field-values-single"
      >
        <div className={classes.fieldContainer}>
          <FieldValuesSingleSelect
            {...sharedProps}
            fieldId={this.props.id}
            fieldValues={this.props.values}
            searchSuggestions={true}
          />
        </div>
      </div>
    );
  }

  renderArrayMultipleContainer() {
    const { classes, first_value, error, id, name, values } = this.props;

    const sharedProps = {
      value: first_value ? mapValuesToOptions(first_value) : [],
      onChange: (selectedOptions) =>
        this.handleOnValueChange({
          first_value: mapOptionsToValues(selectedOptions),
        }),
      placeholder: 'Search for values...',
      noOptionsMessage: 'No values found',
      error: error && !(first_value && first_value.length),
      useMinInputLength: ['Counterparties', 'Internal Parties'].includes(name),
      allowSelectAll: true,
    };

    return (
      <div className={classes.valuesContainer}>
        <div
          className={classes.fieldContainer}
          data-testid="ec-filter-field-values-multiple"
        >
          <FieldValuesMultiSelect
            {...sharedProps}
            aria-label={name}
            fieldId={id}
            fieldValues={values}
            searchSuggestions={true}
          />
        </div>
      </div>
    );
  }

  renderMultipleValues() {
    const { classes, first_value, relation } = this.props;

    if (relation && this.isRelationBlank(relation.value)) return;

    return (
      <div className={classes.multipleValuesContainer}>
        <SubArrowIcon color={black5} />
        <div className={classes.multipleValues}>
          {first_value && first_value.length ? (
            first_value.map((value, index) => (
              <EcMultipleSelectValue
                label={value}
                key={index}
                closeHandler={() => {
                  const multipleValues = [...first_value];
                  multipleValues.splice(index, 1);
                  this.handleOnValueChange({ first_value: multipleValues });
                }}
              />
            ))
          ) : (
            <div className={classes.multipleValuesEmpty}>
              Please make sure none of your sections are missing filters.
            </div>
          )}
        </div>
      </div>
    );
  }

  getBooleanFieldDefaultValue() {
    const { first_value, relation } = this.props;
    if (first_value) {
      return BOOLEAN_OPTIONS.find((option) => option.value === first_value);
    } else if (relation) {
      return BOOLEAN_OPTIONS.find((option) => option.value === relation.value);
    }

    return null;
  }

  getAttachmentDefaultValue() {
    const { first_value, relation } = this.props;
    if (first_value) {
      return ATTACHMENT_OPTIONS.find((option) => option.value === first_value);
    } else if (relation) {
      return ATTACHMENT_OPTIONS.find(
        (option) => option.value === relation.value,
      );
    }

    return null;
  }
  renderBooleanContainer() {
    const { classes, first_value, error, name } = this.props;

    let defaultValue = this.getBooleanFieldDefaultValue();

    return (
      <div className={classes.valuesContainer}>
        <div className={classes.fieldContainer}>
          <EcSelect
            aria-label={name}
            value={defaultValue}
            options={BOOLEAN_OPTIONS}
            onChange={(newValue) => {
              this.isRelationBlank(newValue.value)
                ? this.handleOnRelationChange(newValue)
                : this.handleOnValueChange({ first_value: newValue.value });
            }}
            error={error && !first_value}
            isSearchable={false}
          />
        </div>
      </div>
    );
  }

  renderAttachmentContainer() {
    const { classes, first_value, error, name } = this.props;

    let defaultValue = this.getAttachmentDefaultValue();

    return (
      <div className={classes.valuesContainer}>
        <div className={classes.fieldContainer}>
          <EcSelect
            aria-label={name}
            value={defaultValue}
            options={ATTACHMENT_OPTIONS}
            onChange={(newValue) => {
              this.isRelationBlank(newValue.value)
                ? this.handleOnRelationChange(newValue)
                : this.handleOnValueChange({ first_value: newValue.value });
            }}
            error={error && !first_value}
            isSearchable={false}
          />
        </div>
      </div>
    );
  }

  renderValueContainer() {
    const { classes, type, relation } = this.props;

    if (
      type !== DataFieldType.BOOLEAN &&
      type !== DataFieldType.ATTACHMENT &&
      relation &&
      this.isRelationBlank(relation.value)
    )
      return;

    let valueContainer;
    switch (type) {
      case DataFieldType.NUMBER:
        valueContainer = this.renderNumberContainer();
        break;
      case DataFieldType.STRING:
      case DataFieldType.TEXT_AREA:
        valueContainer = this.renderStringContainer();
        break;
      case DataFieldType.DATE:
        valueContainer = this.renderDateContainer();
        break;
      case DataFieldType.ARRAY_SINGLE:
        valueContainer = this.renderArraySingleContainer();
        break;
      case DataFieldType.ARRAY_MULTIPLE:
        valueContainer = this.renderArrayMultipleContainer();
        break;
      case DataFieldType.BOOLEAN:
        valueContainer = this.renderBooleanContainer();
        break;
      case DataFieldType.ATTACHMENT:
        valueContainer = this.renderAttachmentContainer();
        break;
      default:
        valueContainer = [];
        break;
    }

    return (
      <Fragment>
        <hr className={classes.blueLine} />
        {valueContainer}
      </Fragment>
    );
  }

  renderOptionContainer() {
    const { classes, type, relation, error, name } = this.props;

    if (type === DataFieldType.BOOLEAN || type === DataFieldType.ATTACHMENT)
      return null;
    const options = getRelationOptions(type);

    return (
      <Fragment>
        <hr className={classes.blueLine} />
        <div
          className={classes.fieldContainer}
          data-testid="ec-filter-field-options"
        >
          <EcSelect
            aria-label={name}
            value={relation}
            options={name === 'Document Id' ? DOCUMENT_ID_OPTIONS : options} // Document Id workaround
            placeholder="select option"
            onChange={this.handleOnRelationChange}
            error={error && !relation}
          />
        </div>
      </Fragment>
    );
  }

  render() {
    const {
      classes,
      id,
      label,
      type,
      onRemoveClick,
      onFilterNameClick,
    } = this.props;

    return (
      <div className={classes.ecFilterFieldWrapper}>
        <div className={classes.ecFilterField}>
          <button
            title={`Remove ${label} field`}
            className={classes.removeFieldContainer}
            onClick={onRemoveClick}
          >
            <RemoveIconWithHover size="20" />
          </button>
          <div data-tip data-for={`field-tooltip-${id}`}>
            <EcButton
              text={label}
              width="200px"
              height="44px"
              iconRight={<ChevronDownIcon />}
              onClick={onFilterNameClick}
            />
            <EcTooltip id={`field-tooltip-${id}`}>{label}</EcTooltip>
          </div>
          {this.renderOptionContainer()}
          {this.renderValueContainer()}
        </div>
        {type === DataFieldType.ARRAY_MULTIPLE && this.renderMultipleValues()}
      </div>
    );
  }
}

EcFilterField.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  entity: PropTypes.string,
  relation: PropTypes.object,
  first_value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  second_value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  onFilterNameClick: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

EcFilterField = injectSheet(styles)(withFlags(EcFilterField));

export default EcFilterField;
