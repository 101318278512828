import { isEmpty, throttle } from 'lodash';
import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  intakeFormSet,
  intakeFormUpdateImplicitField,
  intakeFormValidate,
  ticketUpdate,
} from '~/actions';
import { createTicket, getWorkflowIntakeForm } from '~/api';
import { showToast } from '~/components/Shared/EcToast';
import FallbackContent from '~/components/Shared/FallbackContent';
import WorkflowForm from '~/components/Workflow/Form';
import WorkflowFormSectionHeader from '~/components/Workflow/Form/WorkflowFormSectionHeader';
import { MAX_DOCUMENT_NAME_LENGTH } from '~/constants/max_lengths';
import { ContentContainer } from '~/eds';
import {
  FileExtensionType,
  HttpStatusCodeType,
  WorkflowIntakeFormType,
} from '~/enums';
import { PartySelectField } from '~/features/turn-tracking/PartySelectField';
import { FlagType, withFlags } from '~/flags';
import { ERROR, SUCCESS, WARNING } from '~/types/toast.types';
import {
  Alert,
  Box,
  Button,
  FileInput,
  FlexLayout,
  Icon,
  Link,
  RadioGroup,
  Text,
} from '~/ui';
import { downloadFile, getFileIcon } from '~/utils/files';
import { copyToClipboard } from '~/utils/helper.utils';
import { isFormValid, testRequiredQuestionsAnswered } from '~/utils/intakeForm';
import {
  getAcceptedFiles,
  prepareIntakeFormSubmitData,
} from '~/utils/workflow';

import { TicketFormSidebar } from './TicketFormSidebar';
import WizardActionPage from './WizardActionPage';

const MARGIN_OFFSET = 64;

const counterpartyDocumentOptions = [
  {
    label: 'I would like to generate a new contract from our template',
    value: false,
  },
  {
    label: 'I am submitting a contract I received from the counterparty',
    value: true,
  },
];

function getShouldRenderCounterpartyUpload(workflowType) {
  return [
    WorkflowIntakeFormType.Counterparty,
    WorkflowIntakeFormType.CompanyAndCounterparty,
  ].includes(workflowType);
}

const SCROLL_PADDING = '100vh';

class TicketForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      activeSectionId: null,
      counterpartyFile: null,
      forCounterpartyUpload: null,
      isLoadingForm: true,
      isSubmittingForm: false,
      isCounterpartyPaperSectionCollapsed: false,
      isShowingWizardActionPage: false,
      isTurnTrackingCollapsed: false,
      loadingMessageOnSubmit: null,
      ticketId: null,
      turnTrackingSelectedPartyId: null,
    };
  }

  goBack = () => {
    this.props.history.push('/workflow/tickets');
  };

  setIntakeFormType() {
    const { intakeFormUpdateImplicitField } = this.props;
    const { forCounterpartyUpload, type } = this.state;
    if (type === WorkflowIntakeFormType.CompanyAndCounterparty) {
      intakeFormUpdateImplicitField({
        id: 'Paper Type',
        value: {
          value: forCounterpartyUpload
            ? WorkflowIntakeFormType.Counterparty
            : WorkflowIntakeFormType.Company,
        },
      });
    }
  }

  setUpSectionAndUpload() {
    this.setIntakeFormType();
  }

  componentDidMount() {
    const {
      match: {
        params: { workflowId },
      },
    } = this.props;
    this.getIntakeForm(workflowId);
  }

  componentDidUpdate(prevProps, prevState) {
    const { loadingMessageOnSubmit: prevMessage } = prevState;
    const { intakeForm, onUpdateFormState } = this.props;
    const {
      counterpartyFile,
      forCounterpartyUpload,
      isSubmittingForm,
      type,
    } = this.state;

    const shouldRenderCounterpartyUpload = getShouldRenderCounterpartyUpload(
      type,
    );

    const LOADING_MESSAGES = {
      1: shouldRenderCounterpartyUpload
        ? 'Uploading your document'
        : 'Generating document from template',
      2: 'Scanning your document for viruses',
    };

    // if intakeForm is empty we don't evaluate questions because it errors out
    const hasAllRequiredQuestionsAnswered = isEmpty(intakeForm)
      ? false
      : testRequiredQuestionsAnswered(intakeForm);
    let isDisabled;
    let disabledTooltipContent;

    if (isSubmittingForm) {
      if (!prevMessage) {
        this.setState({
          ...this.state,
          loadingMessageOnSubmit: LOADING_MESSAGES[1],
        });
      }
      if (prevMessage === LOADING_MESSAGES[1]) {
        const updateLoadingMessage = () => {
          this.setState({
            ...this.state,
            loadingMessageOnSubmit: LOADING_MESSAGES[2],
          });
        };
        setTimeout(updateLoadingMessage, 3000);
      }
    }

    if (
      type === WorkflowIntakeFormType.Counterparty ||
      (type === WorkflowIntakeFormType.CompanyAndCounterparty &&
        forCounterpartyUpload)
    ) {
      isDisabled = !counterpartyFile || !hasAllRequiredQuestionsAnswered;
      if (isDisabled) {
        disabledTooltipContent =
          'Please fill out all required fields in the form and upload a counterparty paper';
      }
    } else {
      isDisabled = !hasAllRequiredQuestionsAnswered;
      if (isDisabled) {
        disabledTooltipContent =
          'Please fill out all required fields in the form';
      }
    }
    onUpdateFormState({
      isSubmitDisabled: isDisabled,
      disabledSubmitTooltip: disabledTooltipContent,
    });
  }

  getIntakeForm = (workflowId, formData, native) => {
    const { intakeFormSet } = this.props;
    return getWorkflowIntakeForm(workflowId)
      .then((res) => {
        intakeFormSet({ res, formData, native });
        this.setState(
          {
            isLoadingForm: false,
            activeSectionId: res.sections[0]?.id,
            type: res.type,
            forCounterpartyUpload:
              res.type === WorkflowIntakeFormType.Counterparty,
          },
          this.setUpSectionAndUpload,
        );
      })
      .catch((e) => {
        this.setState({ isLoadingForm: false, error: e.response?.status });
        throw e;
      });
  };

  renderErrorMessage(error) {
    let icon = 'reject';
    let title = 'An error occurred while fetching an intake form.';
    let content = 'Contact our support team.';

    if (error === HttpStatusCodeType.Forbidden) {
      icon = 'lock';
      title = 'Sorry, you don’t have permission to access this page.';
      content = 'Contact your administrator to request access.';
    } else if (error === HttpStatusCodeType.NotFound) {
      icon = 'documentOutline';
      title = 'The page you requested does not exist.';
      content =
        'If you think this is a mistake, please contact our support team.';
    }

    return (
      <FallbackContent
        icon={<Icon color="red-600" icon={icon} size="xl" />}
        title={title}
        content={content}
        button={
          <Button
            text="Go back to Tickets"
            variant="secondary"
            onClick={this.goBack}
          />
        }
      />
    );
  }

  onFormSubmit = () => {
    const { counterpartyFile, turnTrackingSelectedPartyId } = this.state;
    const { intakeForm, intakeFormValidate } = this.props;
    const { name, workflowId, fields } = intakeForm;

    intakeFormValidate();

    if (
      counterpartyFile &&
      counterpartyFile.name.length > MAX_DOCUMENT_NAME_LENGTH
    ) {
      showToast(
        ERROR,
        'Uploaded file name cannot be longer than ' +
          MAX_DOCUMENT_NAME_LENGTH +
          ' characters.',
      );
    } else if (!isFormValid(intakeForm)) {
      showToast(WARNING, 'Please fill in all fields correctly.');
    } else {
      this.setState({ isSubmittingForm: true });

      const intakeFormData = prepareIntakeFormSubmitData({
        name,
        workflowId,
        fields,
      });

      createTicket({
        intakeForm: intakeFormData,
        counterpartyFile,
        turnTracking: {
          partyId: turnTrackingSelectedPartyId,
        },
      })
        .then((ticket) => {
          showToast(SUCCESS, 'A new ticket has been successfully created.');
          this.props.ticketUpdate({ stages: ticket.stages });
          this.setState({
            isShowingWizardActionPage: true,
            ticketId: ticket.id,
          });
        })
        .catch((err) => {
          const responseData = err?.response?.data;
          if (responseData?.code === HttpStatusCodeType.UnprocessableContent) {
            showToast(
              ERROR,
              responseData?.message,
              responseData?.details,
              10000,
              true,
            );
          } else {
            showToast(
              ERROR,
              'Sorry, something has gone wrong. Please check our Workflow Help for more information or to Submit a request.',
              'https://support.evisort.com/hc/en-us/sections/360008923994-Workflow',
              10000,
              true,
            );
          }
        })
        .finally(() => {
          this.setState({ isSubmittingForm: false });
        });
    }
  };

  onSidebarSectionClick = (activeSectionId) => {
    const activeSectionElement = document.getElementById(activeSectionId);
    activeSectionElement.scrollIntoView();
    this.setState({ activeSectionId });
  };

  // TODO: spec and build a formal ClipboardBox component allowing copying contents to a clipboard with relevant tooltip display.
  handleOnCopyAction = () => {
    this.setState({ copiedTooltip: true });
    copyToClipboard(window.location.href, null, null, this.handleCopiedTooltip);
  };

  handleCopiedTooltip = () => {
    setTimeout(() => {
      this.setState({ copiedTooltip: false });
    }, 2000);
  };

  renderUploadOptionText(type) {
    const { counterpartyFile, forCounterpartyUpload } = this.state;
    if (type === WorkflowIntakeFormType.Counterparty) {
      return 'This workflow requires an existing document.';
    }
    return (
      <>
        Are you submitting a contract you received from a counterparty or will
        you be generating our template contract?
        <Box mt={5}>
          <RadioGroup
            onChange={(value) => {
              if (!value && counterpartyFile) {
                this.setState({ counterpartyFile: null });
              }
              this.setState(
                { forCounterpartyUpload: value },
                this.setIntakeFormType,
              );
            }}
            options={counterpartyDocumentOptions}
            value={forCounterpartyUpload}
          />
        </Box>
      </>
    );
  }

  renderCounterpartyPaperUpload() {
    const {
      counterpartyFile,
      forCounterpartyUpload,
      isCounterpartyPaperSectionCollapsed,
      type,
    } = this.state;
    const shouldRenderCounterpartyUpload = getShouldRenderCounterpartyUpload(
      type,
    );
    const { workflow } = this.props.intakeForm;

    if (shouldRenderCounterpartyUpload) {
      return (
        <FlexLayout
          flexDirection="column"
          mb={8}
          sx={{ border: 'border', borderRadius: 'm' }}
        >
          <WorkflowFormSectionHeader
            isCollapsed={isCounterpartyPaperSectionCollapsed}
            title="Document"
            onToggleCollapse={() =>
              this.setState({
                isCounterpartyPaperSectionCollapsed: !this.state
                  .isCounterpartyPaperSectionCollapsed,
              })
            }
          />
          {!isCounterpartyPaperSectionCollapsed && (
            <FlexLayout flexDirection="column" p={4}>
              <FlexLayout space={3} alignItems="center">
                <Text variant="xs-dense" color="gray-600">
                  {this.renderUploadOptionText(type)}
                </Text>
              </FlexLayout>
              {counterpartyFile ? (
                <FlexLayout
                  alignItems="center"
                  bg="gray-200"
                  justifyContent="space-between"
                  mt={5}
                  mb={5}
                  px={3}
                  space={6}
                  sx={{
                    borderRadius: 'm',
                    maxWidth: '720px',
                    width: '100%',
                    height: '50px',
                  }}
                >
                  <FlexLayout alignItems="center" space={4}>
                    <Icon icon={getFileIcon(counterpartyFile)} />
                    <Text variant="xs-dense-bold">{counterpartyFile.name}</Text>
                  </FlexLayout>
                  <FlexLayout space={6}>
                    <Icon
                      color="gray-600"
                      icon="saveTo"
                      size="s"
                      onClick={() => downloadFile(counterpartyFile)}
                    />
                    <Icon
                      color="gray-600"
                      icon="trash"
                      size="s"
                      onClick={() => this.setState({ counterpartyFile: null })}
                    />
                  </FlexLayout>
                </FlexLayout>
              ) : forCounterpartyUpload ? (
                <FlexLayout mt={5}>
                  <FileInput
                    acceptedFiles={getAcceptedFiles(workflow, [
                      FileExtensionType.Docx,
                    ])}
                    enableDropzone
                    enableUpload
                    onChange={(counterpartyFile) => {
                      this.setState({ counterpartyFile: counterpartyFile[0] });
                    }}
                  />
                </FlexLayout>
              ) : null}
            </FlexLayout>
          )}
        </FlexLayout>
      );
    }
  }

  renderTurnTracking() {
    const { isTurnTrackingCollapsed } = this.state;
    const { intakeForm } = this.props;

    return (
      <FlexLayout
        flexDirection="column"
        mb={8}
        sx={{ border: 'border', borderRadius: 'm' }}
      >
        <WorkflowFormSectionHeader
          isCollapsed={isTurnTrackingCollapsed}
          title="Cycle Time Tracking"
          onToggleCollapse={() =>
            this.setState({
              isTurnTrackingCollapsed: !this.state.isTurnTrackingCollapsed,
            })
          }
        />
        {!isTurnTrackingCollapsed && (
          <FlexLayout mt={5} mb={5} px={3}>
            <PartySelectField
              workflowId={intakeForm.workflowId}
              onChange={(partyId) =>
                this.setState({ turnTrackingSelectedPartyId: partyId })
              }
              showDescription={true}
              errorMessage={{
                message: 'Start a new ticket to retry.',
                title:
                  "We're experiencing difficulties loading Responsible Parties",
              }}
              width="input.l.width"
            />
          </FlexLayout>
        )}
      </FlexLayout>
    );
  }

  sectionsContainer = React.createRef();

  _handleWorkflowFormScroll = () => {
    if (!this.sectionsContainer.current) return;

    const visibleElements = [];
    const sections = Array.from(
      this.sectionsContainer.current.lastElementChild?.children || [],
    );
    sections.forEach((el) => {
      const rectangle = el.getBoundingClientRect();
      const viewHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight,
      );
      const isVisible = !(
        rectangle.bottom <= MARGIN_OFFSET ||
        rectangle.top - viewHeight >= MARGIN_OFFSET
      );
      if (isVisible) visibleElements.push(el.id);
    });

    if (this.state.activeSectionId !== visibleElements[0]) {
      const activeSectionId = visibleElements[0];

      this.setState({ activeSectionId });
    }
  };

  handleWorkflowFormScroll = throttle(this._handleWorkflowFormScroll, 50);

  render() {
    const { intakeForm, flags } = this.props;
    const {
      error,
      activeSectionId,
      isLoadingForm,
      isShowingWizardActionPage,
      isSubmittingForm,
      loadingMessageOnSubmit,
      ticketId,
    } = this.state;

    const enableTurnTracking =
      flags[FlagType.TurnTracking] && intakeForm.isTurnTrackingEnabled;

    if (isLoadingForm) {
      return (
        <ContentContainer
          loadingContent={{ isLoading: true, description: 'Loading form…' }}
        />
      );
    }

    if (isSubmittingForm) {
      return (
        <ContentContainer
          loadingContent={{
            isLoading: true,
            description: loadingMessageOnSubmit,
          }}
        />
      );
    }

    if (error) {
      return <>{this.renderErrorMessage(error)}</>;
    }

    if (!intakeForm.fields) {
      return null;
    }

    if (isShowingWizardActionPage) {
      return <WizardActionPage ticketId={ticketId} intakeForm={intakeForm} />;
    }

    return (
      <div onScroll={this.handleWorkflowFormScroll}>
        <FlexLayout space={6}>
          <TicketFormSidebar
            activeSectionId={activeSectionId}
            onSidebarSectionClick={this.onSidebarSectionClick}
            isSubmittingForm={isSubmittingForm}
            intakeForm={intakeForm}
          />
          <FlexLayout
            ref={this.sectionsContainer}
            flexDirection="column"
            pb={SCROLL_PADDING}
            space={4}
            sx={{ width: '100%' }}
          >
            {intakeForm.hasErrors ? (
              <Alert enableIcon variant="danger">
                This workflow has errors, please
                <Link
                  variant="s-dense-bold"
                  to={`/workflow/builder/${intakeForm.workflowId}`}
                >
                  {' edit '}
                </Link>
                it to be able to create a ticket from it.
              </Alert>
            ) : (
              <>
                {this.renderCounterpartyPaperUpload()}
                {enableTurnTracking && this.renderTurnTracking()}
                {intakeForm.description && (
                  <Text color="gray-900" variant="s-dense">
                    {intakeForm.description}
                  </Text>
                )}
                <WorkflowForm />
              </>
            )}
          </FlexLayout>
        </FlexLayout>
      </div>
    );
  }
}

const withRouterAndRefAndFlags = (WrappedComponent) => {
  const WithFlagsComponent = withFlags(({ innerRef, ...props }) => (
    <WrappedComponent ref={innerRef} {...props} />
  ));
  const WithRouterComponent = withRouter(({ forwardRef, ...props }) => (
    <WithFlagsComponent innerRef={forwardRef} {...props} />
  ));
  const WithRouterAndRefComponent = forwardRef((props, ref) => (
    <WithRouterComponent forwardRef={ref} {...props} />
  ));
  return WithRouterAndRefComponent;
};

const mapStateToProps = ({ intakeForm }) => ({ intakeForm });

TicketForm = withRouterAndRefAndFlags(
  connect(
    mapStateToProps,
    {
      intakeFormSet,
      intakeFormValidate,
      intakeFormUpdateImplicitField,
      ticketUpdate,
    },
    null,
    {
      forwardRef: true,
    },
  )(TicketForm),
);

export default TicketForm;
