import { ReactNode } from 'react';

import { Text } from '~/eds';

import { MessageSource } from './components/Sources/MessageSource';

export const createMarkdownComponents = (
  {
    onSourceClick,
    isReplying,
  }: { onSourceClick: (sourceIndex: number) => void; isReplying: boolean },
  options?: Partial<{ shouldSkipLinks: boolean }>,
) => {
  if (!options) {
    return;
  }

  if (options.shouldSkipLinks) {
    const renderCustomLink = (props: { children: ReactNode[] }) => {
      const text = props.children[0] as string;
      if (isReplying) return <Text>{text}</Text>;
      return (
        <MessageSource
          text={text}
          onClick={() => onSourceClick(Number(text) - 1)}
          isInline
        />
      );
    };
    return {
      a: renderCustomLink,
    };
  }
};
