import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import DocVersionMenu from '~/components/Shared/DocVersionMenu';
import {
  getOptionLabel,
  getVersionLabelText,
  getVersionNumberIds,
} from '~/components/Shared/DocVersionMenu/utils';
import { Box, Button, IconButton, Layout, Text } from '~/eds';
import { FileExtensionType, QueryParamType } from '~/enums';
import { FlexLayout, Tooltip } from '~/ui';
import { coerceFileType } from '~/utils/files';
import { updatePageSearchQuery } from '~/utils/searchQuery';

function filterDocxFileType(item) {
  const coerceDocFileTypes = [
    FileExtensionType.Doc,
    FileExtensionType.Docx,
  ].map((type) => coerceFileType(type));
  return coerceDocFileTypes.includes(item.fileType);
}

function CompareDocVersionMenu({
  documentVersions,
  selectedDocVersion,
  onVersionCompare,
  onStopComparing,
  ...rest
}) {
  const { versionNumber } = useParams();
  const documentVersionNumber = Number(versionNumber);
  const [menuItemSelected, setMenuItemSelected] = useState(selectedDocVersion);
  const comparableVersions = documentVersions.filter(
    (version) =>
      filterDocxFileType(version) &&
      version.versionNumber !== documentVersionNumber,
  );
  const isCurrentVersionPDF =
    documentVersions.find(
      (version) => version.versionNumber === documentVersionNumber,
    )?.fileType === coerceFileType(FileExtensionType.Pdf);
  const menuIsDisabled = !comparableVersions.length || isCurrentVersionPDF;

  const renderMenuHeaderText = () => {
    return (
      <Layout align="center" justify="center">
        <Text color="text.quiet">Only .doc(x) files can be compared</Text>
      </Layout>
    );
  };

  const renderMenuButton = () => {
    return (
      <Layout preset="buttons" disabled={menuIsDisabled}>
        <Text>Compare to {getVersionLabelText(menuItemSelected)}</Text>
        <IconButton icon="chevron-down" text="compare to" size="s" />
      </Layout>
    );
  };

  function renderListItem(expanded) {
    const versionsNumberIds = getVersionNumberIds(comparableVersions);
    return comparableVersions.map((documentVersion) => {
      const isSelected = documentVersion.versionNumber === menuItemSelected;
      return {
        content: getOptionLabel({
          versionsObject: versionsNumberIds,
          expanded,
          version: documentVersion,
          isSelected,
        }),
        disabled: isSelected,
        onClick: () => {
          const {
            hasWorkingDraft,
            id,
            versionNumber: version,
          } = documentVersion;
          setMenuItemSelected(version);
          onVersionCompare(hasWorkingDraft, id, version);
          updatePageSearchQuery({ [QueryParamType.CompareTo]: version });
        },
      };
    });
  }

  function renderMenuFooter(closeMenu) {
    return (
      <FlexLayout pl={3} pt={3} sx={{ borderTop: 'border', width: '100%' }}>
        <Box p={2}>
          <Button
            disabled={!menuItemSelected}
            onClick={() => {
              setMenuItemSelected(null);
              onStopComparing();
              closeMenu();
            }}
            text="Stop Comparing"
            variant="action"
          />
        </Box>
      </FlexLayout>
    );
  }

  return (
    <Tooltip
      content={
        menuIsDisabled
          ? 'There must be at least two .doc(x) versions to compare.'
          : ''
      }
    >
      <DocVersionMenu
        {...rest}
        disabled={menuIsDisabled}
        renderMenuHeaderText={renderMenuHeaderText}
        renderListItem={renderListItem}
        renderMenuFooter={renderMenuFooter}
        renderMenuButton={() => renderMenuButton()}
      />
    </Tooltip>
  );
}

const mapStateToProps = ({ ticket }) => ({
  ticketId: ticket.id,
  documentVersions: ticket.documentVersions,
});

export default connect(mapStateToProps)(CompareDocVersionMenu);
