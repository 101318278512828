import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';

import { downloadAttachment, uploadAttachments } from '~/api';
import { showToast } from '~/components/Shared/EcToast';
import FieldValuesMultiSelect from '~/components/Shared/FieldValuesMultiSelect';
import FieldValuesSingleSelect from '~/components/Shared/FieldValuesSingleSelect';
import { EM_DASH } from '~/constants/unicode';
import { Button, NumberInput, StatusMessage } from '~/eds';
import { AlgorithmStatusType, DataFieldType } from '~/enums';
import { FlagType, withFlags } from '~/flags';
import * as toastTypes from '~/types/toast.types';
import { Box, FileInput, FileLabel, FlexLayout } from '~/ui';
import { getUniqueFieldValues } from '~/utils/document.utils';
import { downloadFile } from '~/utils/files';

import { black2, black5 } from '../../../assets/shared-styles/general';
import EcButton from '../../Shared/EcButton';
import EcCard from '../../Shared/EcCard';
import EcDatepicker from '../../Shared/EcDatepicker';
import { coerceInputValueToNumber } from '../../Shared/EcFilterField/EcFilterField.utils';
import EcInput from '../../Shared/EcInput';
import EcMultipleSelectValue from '../../Shared/EcMultipleSelectValue';
import EcSelect from '../../Shared/EcSelect';
import EcTooltip from '../../Shared/EcTooltip';
import EditIcon from '../../Shared/Icons/EditIcon';
import InfoIcon from '../../Shared/Icons/InfoIcon';
import SubArrowIcon from '../../Shared/Icons/SubArrowIcon';
import { editDocument } from '../Document.data';
import styles from './DocumentOverviewPanel.styles';
import {
  booleanOptions,
  capitalizeString,
  preprocessEditData,
  processSectionItems,
} from './DocumentOverviewPanel.utils';

const ecCardContentStyles = { display: 'flex', padding: '16px' };
class DocumentOverviewPanel extends Component {
  constructor(props) {
    super(props);
    this.setInitialState = this.setInitialState.bind(this);
    this.handleOnSaveChangesClick = this.handleOnSaveChangesClick.bind(this);
    this.handleOnPartiesShowAllClick = this.handleOnPartiesShowAllClick.bind(
      this,
    );
    this.state = {
      editMode: false,
      editData: {},
      isSaving: false,
      showAllParties: false,
    };
  }
  componentDidMount() {
    this.setInitialState();
  }
  setInitialState() {
    this.setState({ editData: processSectionItems(this.props.sections) });
  }
  handleOnSaveChangesClick() {
    const requestData = preprocessEditData(this.state.editData);
    this.setState({ isSaving: true });
    editDocument(this.props.documentId, requestData)
      .catch(() => {
        showToast(
          toastTypes.ERROR,
          'An error occurred while editing the field(s).',
        );
      })
      .finally(() => {
        this.setState({ isSaving: false, editMode: false });
        this.props.onEditSubmitted();
      });
  }
  handleOnPartiesShowAllClick() {
    this.setState({ showAllParties: true });
  }
  downloadFileAttachment(fileObject) {
    downloadAttachment(fileObject.value)
      .then((file) => {
        showToast(
          toastTypes.DOWNLOAD,
          `${fileObject.display_value} successfully downloaded.`,
        );
        downloadFile(file, fileObject.display_value);
      })
      .catch(() =>
        showToast(
          toastTypes.ERROR,
          'Download attachment failed. Please try again.',
        ),
      );
  }
  handleFileAttachError() {
    showToast(toastTypes.ERROR, 'Attach file failed. Please try again.');
  }
  filterSuggestions(input, options) {
    return options.filter((i) =>
      i.label.toLowerCase().includes(input.toLowerCase()),
    );
  }
  renderBooleanItem(item) {
    const { classes } = this.props;
    if (this.state.editMode) {
      let defaultValue = booleanOptions[0];
      if (item.value === null) {
        defaultValue = null;
      } else if (!item.value.value) {
        defaultValue = booleanOptions[1];
      }
      return (
        <div className={classes.editItemContainer}>
          <EcSelect
            aria-label={item.name}
            defaultValue={defaultValue}
            options={booleanOptions}
            onChange={(newValue) => {
              const newState = {};
              newState[item.id] = newValue ? newValue.value : newValue;
              this.setState({
                editData: { ...this.state.editData, ...newState },
              });
            }}
            isDisabled={!item.is_editable}
            isClearable={item.is_nullable}
            isSearchable={false}
          />
        </div>
      );
    }
    return item.value
      ? capitalizeString(item.value.display_value.toString())
      : EM_DASH;
  }
  renderNumberItem(item) {
    const { classes } = this.props;
    const presentValue = this.state.editData[item.id];
    if (this.state.editMode) {
      return (
        <div className={classes.editItemContainer}>
          <NumberInput
            name={item.name}
            value={coerceInputValueToNumber(presentValue)}
            onChange={(updatedValue) => {
              const newState = {};
              newState[item.id] = coerceInputValueToNumber(updatedValue);
              this.setState({
                editData: { ...this.state.editData, ...newState },
              });
            }}
            disabled={!item.is_editable}
            placeholder="enter amount"
          />
        </div>
      );
    }
    return item.value ? item.value.display_value : EM_DASH;
  }
  renderDateItem(item) {
    const { classes } = this.props;

    const presentValue = this.state.editData[item.id];

    if (this.state.editMode) {
      return (
        <div className={classes.editItemContainer}>
          <EcDatepicker
            title={item.name}
            date={presentValue ? moment(presentValue).toDate() : null}
            onDateChange={(date) => {
              const newState = {};
              newState[item.id] = date
                ? moment(date).format('YYYY-MM-DD')
                : null;

              this.setState({
                editData: { ...this.state.editData, ...newState },
              });
            }}
            disabled={!item.is_editable}
            isClearable={item.is_nullable}
          />
        </div>
      );
    }

    return item.value
      ? moment(item.value.display_value).format('MMMM DD, YYYY')
      : EM_DASH;
  }

  renderStringItem(item) {
    const { classes } = this.props;
    const presentValue = this.state.editData[item.id];
    if (this.state.editMode) {
      return (
        <div className={classes.editItemContainer}>
          <EcInput
            ariaLabel={item.name}
            input={{
              value: presentValue || '',
              onChange: (event) => {
                const newState = {};
                newState[item.id] = event.target.value;
                this.setState({
                  editData: { ...this.state.editData, ...newState },
                });
              },
            }}
            placeholder={`Enter ${item.name}`}
            width="100%"
            isDisabled={!item.is_editable}
          />
        </div>
      );
    }
    return item.value ? item.value.display_value : EM_DASH;
  }
  renderTextAreaItem(item) {
    const { classes } = this.props;
    const presentValue = this.state.editData[item.id] || '';
    if (this.state.editMode) {
      return (
        <div className={classes.editItemContainer}>
          <textarea
            aria-label={item.name}
            value={presentValue}
            rows="5"
            cols="59"
            className={classes.textArea}
            onChange={(event) => {
              const newState = {};
              newState[item.id] = event.target.value;
              this.setState({
                editData: { ...this.state.editData, ...newState },
              });
            }}
          />
        </div>
      );
    }
    return item.value ? item.value.display_value : EM_DASH;
  }
  renderSingleArrayItem(item) {
    const { classes } = this.props;
    const presentValue = this.state.editData[item.id];
    if (this.state.editMode) {
      return (
        <div className={classes.editItemContainer}>
          <FieldValuesSingleSelect
            aria-label={item.name}
            defaultValue={presentValue ? presentValue : null}
            onChange={(newValue) => {
              const newState = {};
              newState[item.id] = newValue ? newValue.value : newValue;
              this.setState({
                editData: { ...this.state.editData, ...newState },
              });
            }}
            isDisabled={!item.is_editable}
            isClearable={item.is_nullable}
            isCreatable={item.allow_new_options}
            fieldId={item.id}
            fieldValues={item.values}
          />
        </div>
      );
    }
    return item.value ? item.value.display_value : EM_DASH;
  }
  renderMultipleValues({ id, is_editable }) {
    const { classes } = this.props;
    const presentValues = this.state.editData[id];
    return (
      <div className={classes.multipleValuesContainer}>
        <SubArrowIcon color={black5} />
        <div className={classes.multipleValues}>
          {presentValues.length ? (
            presentValues.map((value, index) => (
              <EcMultipleSelectValue
                label={value.label}
                key={index}
                closeHandler={() => {
                  const newState = {};
                  const newMultiState = [...this.state.editData[id]];
                  newState[id] = newMultiState.filter(
                    (multiValue) => multiValue.value !== value.value,
                  );
                  this.setState({
                    editData: { ...this.state.editData, ...newState },
                  });
                }}
                isDisabled={!is_editable}
              />
            ))
          ) : (
            <span className={classes.multipleValuesEmpty}>
              No values selected...
            </span>
          )}
        </div>
      </div>
    );
  }
  renderMultipleArrayItem(item) {
    const { classes } = this.props;
    const { showAllParties } = this.state;
    const presentValue = this.state.editData[item.id] || [];
    if (this.state.editMode) {
      return (
        // We use replace on data-testid because Cypress cannot handle spaces
        <div
          className={classes.editItemContainerMultiple}
          data-testid={`doc-overview-panel-${item.name.replace(/\s/g, '-')}`}
        >
          <FieldValuesMultiSelect
            aria-label={item.name}
            value={presentValue}
            onChange={(newItems) => {
              const newState = {};
              newState[item.id] = newItems;
              this.setState({
                editData: { ...this.state.editData, ...newState },
              });
            }}
            width="25%"
            placeholder={`Search for ${item.name}...`}
            noOptionsMessage={`No ${item.name} found`}
            isDisabled={!item.is_editable}
            isCreatable={item.allow_new_options}
            useMinInputLength={['Counterparties', 'Internal Parties'].includes(
              item.class_name,
            )}
            allowSelectAll
            fieldId={item.id}
            fieldValues={item.values}
          />
          {this.renderMultipleValues(item)}
        </div>
      );
    }
    if (item.value && item.value.length) {
      if (
        !showAllParties &&
        item.name === 'Counterparties' &&
        item.value.length > 2
      ) {
        return (
          <Fragment>
            <span>{item.value[0].display_value}</span>
            {' • '}
            <span>{item.value[1].display_value}</span>
            {' • '}
            <button
              className={classes.partiesShowAll}
              onClick={this.handleOnPartiesShowAllClick}
            >
              Show All Parties
            </button>
          </Fragment>
        );
      } else {
        return item.value.map((val) => val.display_value).join(' • ');
      }
    } else {
      return EM_DASH;
    }
  }
  renderAttachmentItem(item) {
    const handleFileDataChangeHelper = (newFiles) => {
      if (!newFiles || !newFiles.length) {
        showToast(toastTypes.ERROR, 'Could not attach file.');
        return;
      }
      const oldItems = this.state.editData[item.id];
      const newItems = newFiles.map((fileData) => ({
        value: fileData.id,
        label: fileData.fileName + fileData.fileType,
      }));
      const newState = {};
      newState[item.id] = [...oldItems, ...newItems];
      this.setState({
        editData: { ...this.state.editData, ...newState },
      });
      showToast(toastTypes.SUCCESS, 'Attach file succeeded.');
    };
    const { classes } = this.props;
    if (this.state.editMode) {
      return (
        // We use replace on data-testid because Cypress cannot handle spaces
        // TODO: refactor this Box testid to automatically apply replace
        <div
          className={classes.editItemContainerMultiple}
          data-testid={`doc-overview-panel-${item.name.replace(/\s/g, '-')}`}
        >
          <Box sx={{ maxWidth: 800 }}>
            <FileInput
              acceptedFiles="*"
              enableUpload
              enableDropzone
              enableMultiple
              onChange={(files) =>
                uploadAttachments(
                  files,
                  handleFileDataChangeHelper,
                  this.handleFileAttachError,
                )
              }
            />
          </Box>
          {this.renderMultipleValues(item)}
        </div>
      );
    }
    if (item.value?.length) {
      return (
        <FlexLayout flexDirection="column" space={5}>
          {item.value.map((fileObject) => {
            const file = new File([], fileObject.display_value);
            return (
              <Box onClick={() => this.downloadFileAttachment(fileObject)}>
                <FileLabel shouldTruncate file={file} />
              </Box>
            );
          })}
        </FlexLayout>
      );
    } else {
      return EM_DASH;
    }
  }
  renderItemValue(item) {
    let returnValue;
    switch (item.type) {
      case DataFieldType.BOOLEAN:
        returnValue = this.renderBooleanItem(item);
        break;
      case DataFieldType.DATE:
        returnValue = this.renderDateItem(item);
        break;
      case DataFieldType.NUMBER:
        returnValue = this.renderNumberItem(item);
        break;
      case DataFieldType.STRING:
        returnValue = this.renderStringItem(item);
        break;
      case DataFieldType.TEXT_AREA:
        returnValue = this.renderTextAreaItem(item);
        break;
      case DataFieldType.ARRAY_SINGLE:
        returnValue = this.renderSingleArrayItem(item);
        break;
      case DataFieldType.ARRAY_MULTIPLE:
        returnValue = this.renderMultipleArrayItem({
          ...item,
          value: getUniqueFieldValues(item.value),
        });
        break;
      case DataFieldType.ATTACHMENT:
        returnValue = this.renderAttachmentItem(item);
        break;
      default:
        returnValue = EM_DASH;
        break;
    }
    return returnValue;
  }
  renderOverviewItem(item) {
    const { classes } = this.props;
    const overviewItemClass =
      this.state.editMode &&
      [DataFieldType.ARRAY_MULTIPLE, DataFieldType.ATTACHMENT].includes(
        item.type,
      )
        ? classes.overviewItemMultiple
        : classes.overviewItem;
    return (
      <div className={overviewItemClass} key={`overviewItem-${item.name}`}>
        <div className={classes.overviewItemTitle}>
          {item.name}
          {item.is_beta ? (
            <Fragment>
              <span className={classes.betaTooltip} data-tip data-for="betaTag">
                BETA
              </span>
              <div className={classes.tooltipContent}>
                <EcTooltip id="betaTag" width="260px" place="bottom">
                  This is a newly released intelligent field which is currently
                  in beta testing. <br />
                  If you have feedback about the outputs you're seeing, please
                  send us a note at beta@evisort.com
                </EcTooltip>
              </div>
            </Fragment>
          ) : null}
          {item.help_text ? (
            <Fragment>
              <span
                className={classes.helpTextTooltip}
                data-tip
                data-for={`helpTextTag-${item.id}`}
              >
                <InfoIcon color={black2} size="15px" />
              </span>
              <div className={classes.tooltipContent}>
                <EcTooltip
                  id={`helpTextTag-${item.id}`}
                  width="260px"
                  place="bottom"
                >
                  {item.help_text}
                </EcTooltip>
              </div>
            </Fragment>
          ) : null}
        </div>
        <div className={classes.overviewItemContent}>
          {this.renderItemValue(item)}
        </div>
      </div>
    );
  }
  renderSection(itemsToRender) {
    const { classes } = this.props;
    const overviewItemsArray = itemsToRender.fields.map((item) =>
      this.renderOverviewItem(item),
    );
    return (
      <div className={classes.overviewInformation}>{overviewItemsArray}</div>
    );
  }
  renderInformationSections(sections) {
    const { classes } = this.props;
    return sections.map((section, index) => (
      <Fragment key={section.title}>
        <EcCard title={section.title} contentStyles={ecCardContentStyles}>
          {this.renderSection(section)}
        </EcCard>
        {index === sections.length - 1 ? null : (
          <div className={classes.horizontalSeparator} />
        )}
      </Fragment>
    ));
  }
  renderEditCommandPanel() {
    const {
      classes,
      documentId,
      dateModified,
      flags,
      OCRProcessing,
      userVisibilityLevel,
    } = this.props;
    const { editMode } = this.state;
    const bypassEditInOverview =
      flags[FlagType.FieldValueMigrationDeferredFeaturesBypass];
    const canEdit = userVisibilityLevel === 'OPEN' && !bypassEditInOverview;
    const enableUnprocessedPDFPreview = flags[FlagType.UnprocessedPDFPreview];

    if (
      enableUnprocessedPDFPreview &&
      OCRProcessing.status === AlgorithmStatusType.Failed
    ) {
      return null;
    }
    if (
      enableUnprocessedPDFPreview &&
      OCRProcessing.status === AlgorithmStatusType.InProgress
    ) {
      return (
        <StatusMessage
          message="Evisort AI is processing this document. It will be available for editing in a few minutes."
          action={
            canEdit ? { text: 'Edit', disabled: true, icon: 'edit' } : null
          }
        />
      );
    }
    return (
      <div
        className={
          editMode ? classes.editCommandPanelActive : classes.editCommandPanel
        }
      >
        {editMode && canEdit ? (
          <Fragment>
            <div className={classes.panelText}>
              You are currently editing this document.
            </div>
            <Button
              text="Discard"
              onClick={() =>
                this.setState({ editMode: false }, () => this.setInitialState())
              }
              disabled={this.state.isSaving}
            />
            <span className={classes.verticalSeparator} />
            <Button
              text="Save Changes"
              onClick={this.handleOnSaveChangesClick}
              isLoading={this.state.isSaving}
              variant="primary"
            />
          </Fragment>
        ) : (
          <Fragment>
            <div className={classes.panelText}>
              This document was last edited on{' '}
              <span className={classes.editDate}>
                {moment(dateModified).format('MMMM DD, YYYY')}.
              </span>
            </div>
            {canEdit ? (
              <EcButton
                id={`document_overviewPanel_editButton?document=${documentId}`}
                testId="edit-document-overview-button"
                text="Edit"
                iconLeft={<EditIcon color="#000" />}
                onClick={() => this.setState({ editMode: true })}
              />
            ) : null}
          </Fragment>
        )}
      </div>
    );
  }
  render() {
    const { classes, sections } = this.props;
    return (
      <Fragment>
        {this.renderEditCommandPanel()}
        <div className={classes.horizontalSeparator} />
        {sections.length ? this.renderInformationSections(sections) : null}
      </Fragment>
    );
  }
}
DocumentOverviewPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  dateModified: PropTypes.string.isRequired,
  documentId: PropTypes.number.isRequired,
  sections: PropTypes.array.isRequired,
  onEditSubmitted: PropTypes.func.isRequired,
  userVisibilityLevel: PropTypes.string.isRequired,
};
export default injectSheet(styles)(withFlags(DocumentOverviewPanel));
