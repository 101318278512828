import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';
import { Link, withRouter } from 'react-router-dom';

import ActionsMenu from '../../Shared/ActionsMenu';
import ChevronRightIcon from '../Icons/ChevronRightIcon';
import FolderIcon from '../Icons/FolderIcon';
import styles from './EcBreadcrumbs.styles';

class EcBreadcrumbs extends Component {
  renderFolderLink(folder) {
    const { classes } = this.props;
    const url = `/documents/${folder.id}`;

    return (
      <Link to={url} className={classes.breadcrumbLink} key={folder.id}>
        {folder.name}
      </Link>
    );
  }

  getMenuItems(newPath) {
    const { history } = this.props;

    return newPath.map((folder) => {
      const url = `/documents/${folder.id}`;
      const menuMarkup = (
        <>
          <FolderIcon size="24" yellow />
          <span>{folder.name}</span>
        </>
      );

      return {
        content: menuMarkup,
        onClick: () => history.push(url),
      };
    });
  }

  renderBreadCrumbs() {
    const { classes, path } = this.props;

    if (path.length > 2) {
      let newPath = [...path];
      const firstFolder = newPath.shift();
      const lastFolder = newPath.pop();

      return (
        <div className={classes.breadcrumbs}>
          {this.renderFolderLink(firstFolder)}
          <ChevronRightIcon size="16" />
          <ActionsMenu
            id="breadcrumbs_dropdown_menu"
            items={this.getMenuItems(newPath)}
            title="breadcrumbs dropdown menu"
          />
          <ChevronRightIcon size="16" />
          {this.renderFolderLink(lastFolder)}
        </div>
      );
    }

    return (
      <div className={classes.breadcrumbs}>
        {path.map((folder, index) => {
          if (index === path.length - 1) {
            return this.renderFolderLink(folder);
          } else {
            return (
              <Fragment key={folder.id}>
                {this.renderFolderLink(folder)}
                <ChevronRightIcon size="16" />
              </Fragment>
            );
          }
        })}
      </div>
    );
  }

  renderEmptyBreadcrumbs() {
    const { classes } = this.props;

    return <div className={classes.emptyBreadcrumbs} />;
  }

  render() {
    const { path } = this.props;

    return path && path.length
      ? this.renderBreadCrumbs()
      : this.renderEmptyBreadcrumbs();
  }
}

EcBreadcrumbs.propTypes = {
  classes: PropTypes.object.isRequired,
  path: PropTypes.array,
};

export default injectSheet(styles)(withRouter(EcBreadcrumbs));
