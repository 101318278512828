import { useMemo } from 'react';

import {
  Actions,
  Feedback,
  formatDate,
  getUserName,
  Layout,
  Markdown,
  StatusText,
  Tooltip,
  types,
} from '~/eds';

import { BlinkingCursor } from './BlinkingCursor';
import { SourcesList } from './components/Sources/SourcesList';
import { CHOICE_DISPLAY_LENGTH, MESSAGE_ACTIONS_CLASSNAME } from './constants';
import { FeedbackDetails, Message as MessageType, SourceType } from './types';
import { createMarkdownComponents } from './utils';

interface Props<Source, InlineSource> {
  message: MessageType<Source, InlineSource>;
  user: types.User;
  getMessageSources: (
    message: MessageType<Source, InlineSource>,
  ) => SourceType[];
  onChoose: (
    message: MessageType<Source, InlineSource>,
    choice: string,
  ) => void;
  onSubmitFeedback: (
    message: MessageType<Source, InlineSource>,
    feedbackDetails: FeedbackDetails,
  ) => void;
  onSelectSource?: (
    message: MessageType<Source, InlineSource>,
    sourceIndex: number,
  ) => void;
  disableChoices?: boolean;
  isReplying?: boolean;
  messageActions: types.UserAction[];
  shouldShowActions: boolean;
}

export const AiMessage = <
  Source extends unknown,
  InlineSource extends unknown
>({
  disableChoices,
  isReplying,
  getMessageSources,
  message,
  messageActions,
  shouldShowActions,
  user,
  onChoose,
  onSelectSource,
  onSubmitFeedback,
}: Props<Source, InlineSource>) => {
  const handleSubmitFeedback = (value: types.Nullable<boolean>) => {
    if (message) {
      onSubmitFeedback(message, { value });
    }
  };
  const { choices = [], text, timestamp } = message ?? {};
  const tooltip = `${getUserName(user)}\n${formatDate(timestamp, 'full')}`;

  const messageChoices = useMemo(
    () =>
      (message?.choices ?? []).map((choice) => {
        /** TODO - `Button` should handle this truncation internally after design proposes the display length limit */
        const isLongText = choice.length > CHOICE_DISPLAY_LENGTH;
        return {
          disabled: disableChoices,
          level: 'secondary' as const,
          text: isLongText
            ? choice.substring(0, CHOICE_DISPLAY_LENGTH) + '…'
            : choice,
          tooltip: isLongText ? choice : undefined,
          onClick: () => {
            if (message) {
              onChoose(message, choice);
            }
          },
        };
      }),
    [disableChoices, message],
  );

  const handleInlineSourceClick = (sourceIndex: number) => {
    onSelectSource?.(message, sourceIndex);
  };

  const hasInlineSources = !!message.inlineSources?.length;
  const markdownComponents = useMemo(
    () =>
      createMarkdownComponents(
        { onSourceClick: handleInlineSourceClick, isReplying: !!isReplying },
        {
          shouldSkipLinks: hasInlineSources,
        },
      ),
    [hasInlineSources],
  );
  return (
    <Layout direction="column" spacing={4} styles={componentStyles.message}>
      <Tooltip tooltip={tooltip} placement="left">
        <StatusText
          iconSpacing="s"
          text="Ask AI"
          icon="evisort-ai-animated"
          variant="section-title"
        />
      </Tooltip>
      <Markdown components={markdownComponents} text={text} />
      <Layout
        direction="column"
        flex="auto"
        justify="center"
        minW={0}
        position="relative"
        spacing={2}
      >
        {choices && <Actions wrap actions={messageChoices} size="s" />}
        {isReplying && <BlinkingCursor />}
      </Layout>
      <SourcesList<Source, InlineSource>
        getMessageSources={getMessageSources}
        message={message}
      />
      {shouldShowActions && (
        <Layout
          bg="background"
          borderRadius={4}
          className={MESSAGE_ACTIONS_CLASSNAME}
          styles={componentStyles.actions}
        >
          <Actions actions={messageActions} />
          {Boolean(messageActions.length) && (
            <Feedback
              value={message?.feedbackDetails?.value ?? null}
              onChange={handleSubmitFeedback}
            />
          )}
        </Layout>
      )}
    </Layout>
  );
};

const componentStyles = {
  message: {
    position: 'relative',
    [`&:hover .${MESSAGE_ACTIONS_CLASSNAME}`]: {
      visibility: 'visible',
    },
    [`&.current:hover + .other .${MESSAGE_ACTIONS_CLASSNAME}`]: {
      visibility: 'visible',
    },
  },
  actions: {
    boxShadow: 'raised',
    bottom: 0,
    position: 'absolute',
    right: 4,
    transform: 'translateY(-10%)',
    visibility: 'hidden',
  },
};
