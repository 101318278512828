import { createFormattedDateString } from '../../Modals/AlertModal/AlertModal.utils';

export function preprocessSearchPayload(filters) {
  const begin_dates = ['last_sent_start_date', 'upcoming_send_start_date'];
  const end_dates = ['last_sent_end_date', 'upcoming_send_end_date'];
  return Object.keys(filters).reduce((acc, filterName) => {
    const filterValue = filters[filterName];

    if (!filterValue) return acc;

    if (Array.isArray(filterValue) && filterValue.length) {
      acc[filterName] = filterValue.map((val) =>
        filterName === 'selected_departments' ? val.department_id : val,
      );
    } else if (filterValue.value && filterValue.value.length) {
      acc[filterName] = filterValue.value;
    } else if (filterValue && filterValue.length) {
      if (begin_dates.indexOf(filterName) >= 0) {
        acc[filterName] = createFormattedDateString(filterValue);
      } else if (end_dates.indexOf(filterName) >= 0) {
        acc[filterName] = createFormattedDateString(filterValue, '23:59');
      } else {
        acc[filterName] = filterValue;
      }
    }

    return acc;
  }, {});
}
