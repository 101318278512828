import React from 'react';

import { Button, Layout, Select, types, useToggle } from '~/eds';
import { FilterViewType } from '~/evifields';
import { useTrackSegment } from '~/hooks';
import { Nullable } from '~/types';

import { AsyncSelectComponent } from '../AsyncSelectComponent';
import { TextDelimitedEnumSetValue } from '../subcomponents/TextDelimitedEnumSetValue';
import { AsyncValue, EnumValue, Field, Filter } from '../types';
import { testEnableSearch } from '../utils';

interface Props {
  field: Field<'enum_set'>;
  filter: Filter<EnumValue>;
  onChange: (
    updatedValues: EnumValue[],
    updatedAsyncValue?: AsyncValue<EnumValue>,
  ) => void;
  onViewChange?: (filterView: types.Nullable<FilterViewType>) => void;
}

export const EnumSetValues = ({
  field,
  filter,
  onChange,
  onViewChange,
}: Props) => {
  const { render, settings, id: fieldId } = field;
  const { options, __testEnableSelectAll } = settings;
  const { asyncValue, id, values, filterView } = filter;
  const [isMultiselect, _toggle, showMultiselect, showCopyPaste] = useToggle(
    !onViewChange || !filterView || filterView !== 'text-delimited',
  );
  const trackSegment = useTrackSegment();
  const handleChange = (
    updatedValues: Nullable<EnumValue[]>,
    updatedAsyncValue?: AsyncValue<EnumValue>,
  ) => {
    onChange(updatedValues === null ? [] : updatedValues, updatedAsyncValue);
  };

  const isAsync = Boolean(render);

  const enableSearch = testEnableSearch(isAsync, options?.length || 0);

  const enableSelectAll = __testEnableSelectAll?.(filter);

  const shouldHaveTextDelimitedView =
    onViewChange && !(fieldId === 'folder' || fieldId === 'document_group_id');

  const onCopyPasteClick = () => {
    onViewChange?.('text-delimited');
    showCopyPaste();
    trackSegment('textDelimitedFilterClicked', { fieldId });
  };

  const onBackClick = () => {
    onViewChange?.(null);
    showMultiselect();
  };

  const innerProps = {
    asyncValue,
    enableControl: enableSearch,
    enableSearchIcon: true,
    enableSelectAll,
    enableSorting: enableSearch,
    isClearable: false,
    isEmbedded: true,
    isMulti: true,
    name: id,
    value: values,
    onChange: handleChange,
  };

  const MultiSelect = (
    <Layout direction="column" mt={enableSearch ? undefined : -4} mx={-4}>
      {render?.({
        Component: AsyncSelectComponent,
        innerProps,
      }) || <Select {...innerProps} isMulti options={options} />}
      {shouldHaveTextDelimitedView && (
        <Layout pt={2} borderTop="border.divider">
          <Button
            variant="action"
            text="Copy and Paste Values"
            onClick={onCopyPasteClick}
          />
        </Layout>
      )}
    </Layout>
  );

  const content = isMultiselect ? (
    MultiSelect
  ) : (
    <TextDelimitedEnumSetValue
      fieldId={fieldId}
      values={values}
      onChange={onChange}
      onBackClick={onBackClick}
    />
  );

  return <Layout>{content}</Layout>;
};
