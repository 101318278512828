import { noop } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from 'uinix-ui';

import { RETRY_ERROR } from '~/constants/errors';
import {
  Box,
  Button,
  copy,
  FormField,
  INPUT_DEBOUNCE_MS,
  Layout,
  Modal,
  Popover,
  Text,
  TextInput,
  useDebounce,
  useModal,
  useToast,
  useToggle,
} from '~/eds';
import { LocalStorageKeyType } from '~/enums';
import {
  CreditBalance,
  CreditSummary,
  ERROR_ESTIMATION_MESSAGE,
} from '~/features/billing';
import { DocumentViewer, Highlight } from '~/features/document-viewer';
import { FlagType, useFlag } from '~/flags';
import { useClientId, useLocalStorageState } from '~/hooks';
import { actions, api, selectors } from '~/redux';
import { RoutePathType, useRouting } from '~/routing';
import { Nullable, PilotId } from '~/types';

import { getCreditsTooltip, getEstimatedCreditsTooltip } from '../billing';
import {
  HIGHLIGHT_SOURCE_LIMIT,
  RECOMMENDED_PUBLISH_TEST_CASE_COUNT,
} from '../constants';
import { selectAFieldTooltip, testIsConfigDirty } from '../fields/utils';
import { PublishModal } from '../modals/PublishModal';
import { BoostModal, BoostTrigger } from '../Review';
import { PromptModelTestCase } from '../types';
import {
  getModelPublishStatusChip,
  prepareGoldValueForTagSource,
  prepareGoldValueValueForTagAnswer,
  testIsActivePublishStatus,
  testIsNonOptimizedModel,
  testIsPublishingStatus,
  testIsTestCaseEvaluating,
} from '../utils';
import { Configuration } from './Configuration';
import { Evaluate } from './Evaluate';

const TOAST_ID = 70257; // arbitrary number id constant for toast

export const ModelDetails = () => {
  const { history, params } = useRouting();
  const { modelId } = params;
  const isNew = modelId === 'new';

  const clientId = useClientId();
  const isDocumentXRayBilling = useFlag(FlagType.DocumentXRayBilling);
  const enableDocumentXrayOptimize = useFlag(FlagType.DocumentXrayOptimize);
  const enableCopyConfig = useFlag(FlagType.DocumentXRayCopyConfig);

  const enableXRayTagging = useFlag(FlagType.DocumentXRayTagging);

  const styles = useStyles();

  const dispatch = useDispatch();

  const [
    enablePublishModal,
    _toggleEnablePublishModal,
    showEnablePublishModal,
    hideEnablePublishModal,
  ] = useToggle(false);

  const [
    enableTogglePopover,
    _toggleEnablePopover,
    showPopover,
    hidePopover,
  ] = useToggle(false);

  const [dismissWelcomeContent, toggleDismissWelcomeContent] = useToggle();

  const [selectedConfigId, setSelectedConfigId] = useState<Nullable<string>>(
    null,
  );
  const [isValidConfigStatus, setIsValidConfigStatus] = useState<
    Nullable<string>
  >(null);

  const activeModelVersion = useSelector(selectors.selectFieldAiActiveModel);
  const activeVersionNumber = useSelector(
    selectors.selectFieldAiActiveVersionNumber,
  );
  const versions = useSelector(selectors.selectFieldAiModelVersions);
  const config = useSelector(selectors.selectFieldAiConfig);
  const documentViewer = useSelector(selectors.selectDocumentViewer);
  const defaultHasViewedWelcomeContent = useSelector(
    selectors.selectFieldAiHasViewedWelcomeContent,
  );
  const activeFilters = useSelector(selectors.selectFieldAiActiveFilters);
  const creditSummary = useSelector(selectors.selectFieldAiCreditsSummary);
  const optimize = useSelector(selectors.selectFieldAiOptimize);

  const activeTestcaseNumber = useSelector(
    selectors.selectFieldAiActiveTestcaseNumber,
  );
  const activeTestCase =
    activeModelVersion?.testCases[activeTestcaseNumber - 1];

  const field = useSelector(selectors.selectFieldAiField);

  const { toast, isActive } = useToast();
  const toastIdRef = useRef(TOAST_ID);

  const editModelConfirmed =
    activeModelVersion &&
    testIsNonOptimizedModel(activeModelVersion, ['suspended']);

  const disableEditOnActiveModels =
    activeModelVersion &&
    testIsActivePublishStatus(activeModelVersion.publishStatus);

  const discardChanges = () => {
    dispatch(actions.resetFieldAi());
    history.push(RoutePathType.AutomationHubFields);
  };

  const [
    updatePromptModelProgress,
    { isLoading: isUpdatingPromptModelProgress, error: updatePromptModelError },
  ] = api.endpoints.updatePromptModelProgress.useMutation();

  const [
    suspendFieldModel,
    { isLoading: isSuspendingFieldModel },
  ] = api.endpoints.suspendPromptModel.useMutation();

  const [
    tagAnswer,
    { isLoading: isTaggingAnswer },
  ] = api.endpoints.tagAnswerForPromptModelTestCase.useMutation();

  const [
    updateTestCase,
    { isLoading: isUpdatingPromptModelTestCase },
  ] = api.endpoints.updatePromptModelTestCase.useMutation();

  const [confirmDiscardChangesModal, showConfirmDiscardChangesModal] = useModal(
    {
      title: 'Discard Changes?',
      enableHideOnPrimaryAction: true,
      children: (
        <Text>
          You have unsaved changes. Click Discard Changes to exit without
          saving, or Continue Editing to stay on this page.
        </Text>
      ),
      primaryAction: {
        text: 'Continue Editing',
      },
      secondaryActions: [
        {
          text: 'Discard Changes',
          variant: 'tertiary',
          onClick: discardChanges,
        },
      ],
      cancelText: null,
    },
  );

  const [
    editWarningdModal,
    showEditWarningModal,
    hideEditWarningModal,
  ] = useModal({
    cancelText: 'Cancel',
    children: (
      <Text>
        When you enter edit mode, your model will be suspended and will stop
        running on unprocessed documents. Are you sure you want to continue? You
        can come back to this page to resume your model, or republish your model
        with your changes.
      </Text>
    ),
    primaryAction: {
      isLoading: isSuspendingFieldModel,
      onClick: () => {
        suspendFieldModel({ modelId })
          .then(() => {
            toast({
              message: `Suspended **${activeModelVersion?.name}**`,
              status: 'success',
            });
            hideEditWarningModal();
          })
          .catch(() =>
            toast({
              message: `Suspended **${activeModelVersion?.name}** failed.  Please try again.`,
              status: 'danger',
            }),
          )
          .finally(() => {
            refetchFieldModel();
          });
      },
      text: 'Confirm',
      variant: 'primary',
    },
    title: 'Edit Field Model',
  });

  const [
    publishWarningModal,
    showPublishWarningModal,
    hidePublishWarningModal,
  ] = useModal({
    cancelText: 'Evaluate More Results',
    children: (
      <Text>
        We recommend that you rate at least 10 results before publishing your
        model to ensure that it works across your documents.
      </Text>
    ),
    primaryAction: {
      isLoading: isUpdatingPromptModelProgress,
      onClick: () => {
        editModelConfirmed
          ? updatePromptModelProgress({ modelId }).then(() =>
              showEnablePublishModal(),
            )
          : showEnablePublishModal();
      },
      text: 'Review & Publish',
      variant: 'danger',
    },
    title: 'Are you sure you want to publish?',
  });

  const [
    hasViewedWelcomeContent,
    setFieldAiHasViewedWelcomeContent,
  ] = useLocalStorageState(
    LocalStorageKeyType.WelcomeFieldAi,
    defaultHasViewedWelcomeContent,
    {
      skipUpdateLocalStorage: !dismissWelcomeContent,
    },
  );
  const enableWelcomeContent = isNew && !hasViewedWelcomeContent;

  const {
    data: model,
    isFetching: isLoadingModel,
    refetch: refetchFieldModel,
  } = api.endpoints.getPromptModel.useQuery(modelId, {
    skip: isNew,
  });

  const [
    getModelVersion,
    { currentData: modelVersion = null, isFetching: isLoadingModelVersion },
  ] = api.endpoints.DEPRECATED_getPromptModelVersion.useLazyQuery(modelId);

  const [
    getDocsInScope,
    {
      data: docsInScope = 0,
      isFetching: isFetchingDocsInScope,
      isUninitialized,
    },
  ] = api.endpoints.getDocsInScope.useLazyQuery();

  const {
    currentData: creditEstimation = 0,
    isFetching: isCreditEstimationFetching,
  } = api.endpoints.getCreditEstimation.useQueryState({
    clientId,
    estimateUnit: {
      modelId: isNew ? undefined : modelId,
      isLoadingUnitValue: isFetchingDocsInScope,
      isUninitialized,
      unit: 'document-count',
      value: docsInScope,
    },
  });

  const latestVersion = model ? Math.max(...model.versions) : 1;

  useEffect(() => {
    if (model?.versions.length) {
      dispatch(actions.setFieldAiActiveVersionNumber(latestVersion));
      dispatch(actions.setFieldAiModelVersions(model.versions));
      fetchModelVersion(model.fieldId, latestVersion);
    }
  }, [latestVersion, model]);

  useEffect(() => {
    if (versions && field) {
      const versionNumber =
        versions.length > 0 ? versions[activeVersionNumber - 1] : 1;
      handleFetchModelVersion(field, versionNumber);
    }
  }, [activeVersionNumber]);

  useEffect(() => {
    if (field) {
      getDocsInScope({ fieldId: field.id, filters: activeFilters }).catch(
        (err) => {
          toast({
            message: err?.detail.msg ?? ERROR_ESTIMATION_MESSAGE,
            status: 'danger',
          });
        },
      );
    }
  }, [field, activeFilters]);

  useEffect(() => {
    if (updatePromptModelError) {
      toast({
        message: 'Something went wrong. Please try again.',
        status: 'danger',
      });
    }
  }, [updatePromptModelError]);

  const isLoading = isLoadingModel || isLoadingModelVersion;

  const isConfigDirty = testIsConfigDirty(config, modelVersion?.config);

  const disablePublishTooltip = isLoading
    ? 'Loading model…'
    : activeModelVersion?.publishStatus === 'completed'
    ? 'This model has already been published.'
    : testIsPublishingStatus(activeModelVersion?.publishStatus)
    ? 'This model is currently being published.'
    : activeModelVersion?.version !== latestVersion
    ? 'You can only publish the latest version.'
    : isConfigDirty
    ? 'Please save your changes before publishing.'
    : !activeModelVersion?.testCases.length
    ? 'Please evaluate at least one document before publishing.'
    : isDocumentXRayBilling && isCreditEstimationFetching
    ? 'Loading credit estimation…'
    : isDocumentXRayBilling &&
      creditSummary &&
      creditEstimation > creditSummary.credits &&
      activeModelVersion.publishStatus !== 'suspended'
    ? 'Estimated credits to publish exceeds credits available. You can only save the model as a draft and not publish. Adjust scope or Get More Credits in Automation Hub to publish.'
    : undefined;

  const handleCancel = () => {
    if (hasViewedWelcomeContent && !isLoading && isConfigDirty) {
      showConfirmDiscardChangesModal();
    } else {
      discardChanges();
    }
  };

  const handleFetchModelVersion = useDebounce(
    (field, versionId) => {
      fetchModelVersion(field.id, versionId);
    },
    INPUT_DEBOUNCE_MS,
    [versions],
  );

  const fetchModelVersion = (fieldId: PilotId, versionNumber: number) =>
    getModelVersion({
      modelId,
      version: versionNumber,
      fieldId,
    })
      .unwrap()
      .then((modelVersion) => {
        if (modelVersion) {
          //TODO: Clean up: remove config from state and rely on updating modelVersion.
          dispatch(actions.setFieldAiConfiguration(modelVersion.config));
          dispatch(
            actions.setFieldAiActiveModel({
              ...modelVersion,
              publishStatus: model?.publishStatus ?? null, // TODO: this is not ideal and should be handled in upstream API (but requires team to discuss how APIs should be recomposed to assemble the data we need)
            }),
          );
          if (modelVersion.testCases.length) {
            dispatch(
              actions.setFieldAiActiveTestcaseNumber(
                modelVersion.testCases.length,
              ),
            );
          } else if (docsInScope === 0) {
            // reset the stale document viewer
            dispatch(
              actions.setFieldAiDocumentViewer({
                documentId: null,
                documentVersionId: null,
                activeHighlightId: undefined,
                highlights: [],
              }),
            );
            // show toast letting the user know they need to update the scope filters
            if (!isActive(TOAST_ID) && !isActive(toastIdRef.current)) {
              // prevent multiple duplicates
              toastIdRef.current = toast({
                key: TOAST_ID,
                title: 'No documents in scope',
                message:
                  'The configuration provided to the model did not yield any results.\n\nChange Scope: Try modifying your filters to adjust the scope and pull in relevant results.',
                status: 'warning',
                onDismiss: noop,
              });
            }
          }
          if (modelVersion.optimizeState) {
            dispatch(
              actions.setFieldAiOptimize({
                modelId,
                version: modelVersion.version,
              }),
            );
          }
        }
      })
      .catch(() => {
        toast({
          message: RETRY_ERROR,
          status: 'danger',
        });
      });

  const handlePublish = () => {
    if (
      activeModelVersion &&
      activeModelVersion.testCases.length < RECOMMENDED_PUBLISH_TEST_CASE_COUNT
    ) {
      showPublishWarningModal();
    } else {
      showEnablePublishModal();
    }
  };

  const onCreditSummary = (credit: CreditSummary) =>
    dispatch(actions.setFieldAiCreditsSummary(credit));

  const primaryAction = enableWelcomeContent
    ? {
        text: 'Get Started',
        onClick: () => {
          setFieldAiHasViewedWelcomeContent(true);
          dispatch(actions.setFieldAiHasViewedWelcomeContent(true));
        },
      }
    : Boolean(disableEditOnActiveModels)
    ? {
        text: 'Edit Model',
        onClick: showEditWarningModal,
      }
    : {
        disabled: Boolean(disablePublishTooltip),
        tooltip: disablePublishTooltip,
        text: 'Review & Publish',
        onClick: handlePublish,
      };

  const placeholderContent = documentViewer.documentVersionId
    ? undefined
    : {
        image: 'empty-no-groups' as const,
        title: 'There are no documents to evaluate yet.',
        description:
          'Fill in the Field Model Configuration and click on Evaluate to start evaluating.',
      };

  const title = `${isNew ? 'New' : 'Edit'} Field Model`;
  const loadingContent = {
    isLoading,
    message: 'Loading model details…',
  };

  const welcomeContent = enableWelcomeContent
    ? {
        title: 'Welcome to Document X-Ray',
        description:
          'Build a model to automatically populate data fields across your contracts',
        status: 'ai' as const,
        infoCards: [
          {
            title: 'Configure AI Model',
            description:
              'Build an AI model to find the information you need across documents',
            number: 1,
          },
          {
            title: 'Evaluate Model Performance',
            description:
              'Review the data identified by the AI to find areas of improvement',
            number: 2,
          },
          {
            title: 'Improve Model Performance',
            description:
              'Improve the model’s accuracy by modifying the instructions for the model',
            number: 3,
          },
          {
            title: 'Publish AI Model',
            description:
              'Run the model across both existing and new contracts for your organization to populate associated data fields',
            number: 4,
          },
        ],
        onDismiss: toggleDismissWelcomeContent,
      }
    : undefined;

  const disabled =
    !!activeModelVersion &&
    testIsActivePublishStatus(activeModelVersion?.publishStatus);

  const modelConfigInternalId = activeModelVersion?.config.internal;

  const moreActions =
    enableCopyConfig && !enableWelcomeContent
      ? [
          {
            disabled: !modelConfigInternalId,
            onClick: () => {
              copy(modelConfigInternalId!);
              toast({
                message: 'Configuration ID copied to clipboard.',
                status: 'success',
              });
            },
            text: 'Copy Configuration ID',
            tooltip: modelConfigInternalId
              ? undefined
              : 'No Configuration ID available',
            variant: 'tertiary',
          },
          {
            text: 'Update Configuration ID',
            variant: 'tertiary',
            onClick: showPopover,
            disabled,
            tooltip: disabled
              ? 'The model is not in an editing status'
              : undefined,
          },
        ]
      : [];

  const handleOnHide = () => {
    setSelectedConfigId(null);
    hidePopover();
    setIsValidConfigStatus(null);
  };

  const [
    checkConfig,
    {
      isLoading: isLoadingCheckConfigId,
      error: errorValidatingCondigId,
      isSuccess: isValidConfigId,
    },
  ] = api.endpoints.checkPromptModelConfig.useMutation();

  useEffect(() => {
    if (isValidConfigId) {
      setIsValidConfigStatus('success');
    } else if (errorValidatingCondigId) {
      setIsValidConfigStatus('failed');
    } else {
      setIsValidConfigStatus(null);
    }
  }, [isValidConfigId, errorValidatingCondigId]);

  // X-Ray Tagging
  const handleDeleteSource = (highlight: Highlight) => {
    if (!activeTestCase) {
      return;
    }
    const { goldValue, modelValue, outcome } = activeTestCase;

    const sourceHighlights =
      goldValue?.highlights ?? modelValue?.highlights ?? [];

    updateTestCase({
      modelId,
      version: activeVersionNumber,
      testCaseNumber: activeTestcaseNumber,
      outcome,
      goldValue: {
        highlights: sourceHighlights.filter((h) => h.id !== highlight.id),
        // @ts-expect-error 'string' is not assignable to 'classification'
        type:
          outcome === 'correct' || goldValue?.value
            ? modelValue?.type
            : 'unknown',
        // @ts-expect-error string[] is not assignable to string
        value: goldValue?.value,
      },
    })
      .unwrap()
      .then((updatedTestCase) => {
        toast({
          message: 'Source deleted.',
          status: 'success',
        });
        handleUpdateTestCaseHighlights(updatedTestCase, highlight);
      })
      .catch(() => toast({ message: RETRY_ERROR, status: 'danger' }));
  };

  const handleTagAnswer = (highlight: Highlight) => {
    if (!activeTestCase || !highlight.text) {
      return;
    }
    const { goldValue, modelValue, outcome } = activeTestCase;

    tagAnswer({
      modelId,
      version: activeVersionNumber,
      testCaseNumber: activeTestcaseNumber,
      outcome,
      fallbackGoldValue: prepareGoldValueForTagSource({
        goldValue,
        modelValue,
        outcome,
        highlight,
      }),
      goldValue: {
        highlights: [
          ...(goldValue?.highlights ?? modelValue?.highlights ?? []),
          highlight,
        ],
        // @ts-expect-error 'FieldValueType' is not assignable to 'classification'
        type: modelValue?.type,
        // @ts-expect-error string[] is not assignable to string
        value: prepareGoldValueValueForTagAnswer({
          value: highlight.text,
          goldValue,
          modelValue,
          config,
        }),
      },
    })
      .unwrap()
      .then(({ updatedTestCase, responseToast }) => {
        if (responseToast) {
          toast(responseToast);
        }
        if (updatedTestCase) {
          handleUpdateTestCaseHighlights(updatedTestCase, highlight, {
            isNew: true,
          });
        }
      })
      .catch(({ responseToast }) =>
        toast(responseToast ?? { message: RETRY_ERROR, status: 'danger' }),
      );
  };

  const handleTagSource = (highlight: Highlight) => {
    if (!activeTestCase) {
      return;
    }
    const { goldValue, modelValue, outcome } = activeTestCase;
    updateTestCase({
      modelId,
      version: activeVersionNumber,
      testCaseNumber: activeTestcaseNumber,
      outcome,
      goldValue: prepareGoldValueForTagSource({
        goldValue,
        modelValue,
        outcome,
        highlight,
      }),
    })
      .unwrap()
      .then((updatedTestCase) => {
        toast({
          message: 'Source added.',
          status: 'success',
        });
        handleUpdateTestCaseHighlights(updatedTestCase, highlight, {
          isNew: true,
        });
      })
      .catch(() => toast({ message: RETRY_ERROR, status: 'danger' }));
  };

  /** Util to update the active test case highlights.
   * @param updatedTestCase - The updated test case.
   * @param highlight - The highlight to update.
   * @param isNew - Flag to determine if the highlight is new. Defaults to false.
   */
  const handleUpdateTestCaseHighlights = (
    updatedTestCase: PromptModelTestCase,
    highlight: Highlight,
    { isNew = false } = {},
  ) => {
    const newHighlight =
      isNew && updatedTestCase.goldValue?.highlights?.length
        ? updatedTestCase.goldValue.highlights[
            updatedTestCase.goldValue.highlights.length - 1
          ]
        : highlight;
    dispatch(
      actions.setFieldAiDocumentViewer({
        documentId: documentViewer.documentId,
        documentVersionId: documentViewer.documentVersionId,
        activeHighlightId: newHighlight.id,
        highlights: isNew ? [newHighlight] : [],
      }),
    );
    dispatch(actions.setFieldAiUpdatedTestCase(updatedTestCase));
  };

  // X-Ray Tagging disabled logic
  const sourceLimitReached =
    (activeTestCase?.goldValue?.highlights?.length ?? 0) >=
    HIGHLIGHT_SOURCE_LIMIT;

  const isPreviousVersion = !(activeModelVersion?.version === latestVersion);

  const isTestCaseEvaluating =
    !activeTestCase || testIsTestCaseEvaluating(activeTestCase);

  const disableDeleteSource = disableEditOnActiveModels || isPreviousVersion;

  const disableDeleteSourceTooltip = disableEditOnActiveModels
    ? 'Model is already published. Edit Model to delete sources.'
    : isPreviousVersion
    ? 'You can only delete sources from the latest version.'
    : undefined;

  const disableTag =
    disableEditOnActiveModels ||
    isPreviousVersion ||
    isTestCaseEvaluating ||
    sourceLimitReached;

  const disableTagTooltip = disableEditOnActiveModels
    ? 'Model is already published. Edit Model to tag sources.'
    : isPreviousVersion
    ? 'You can only tag sources in the latest version.'
    : sourceLimitReached
    ? `You cannot tag more than ${HIGHLIGHT_SOURCE_LIMIT} sources.`
    : isTestCaseEvaluating
    ? 'Evaluating model…'
    : undefined;

  /**
   * X-Ray Tagging popover action on existing highlights.
   */
  const getHighlightClickActions = (highlight: Highlight) => [
    {
      disabled: disableDeleteSource,
      label: 'Delete Source',
      leftIcon: 'trash' as const,
      tooltip: disableDeleteSourceTooltip,
      value: 'delete',
      onClick: () => handleDeleteSource(highlight),
    },
  ];

  /**
   * X-Ray Tagging popover actions on new highlights.
   */
  const getHighlightCreateActions = (highlight: Highlight) => [
    {
      disabled: disableTag,
      isLoading: isTaggingAnswer,
      label: 'Tag as Answer',
      leftIcon: 'field' as const,
      tooltip: disableTagTooltip,
      value: 'answer',
      onClick: () => handleTagAnswer(highlight),
    },
    {
      disabled: disableTag,
      isLoading: isUpdatingPromptModelTestCase,
      label: 'Tag as Source',
      leftIcon: 'tag' as const,
      tooltip: disableTagTooltip,
      value: 'source',
      onClick: () => handleTagSource(highlight),
    },
  ];

  const panel = {
    children: (
      <Layout pb={12} preset="sections">
        <Popover
          containerStyle={{
            margin: '0',
            padding: '0',
            marginLeft: '300px',
            marginTop: '-40px',
          }}
          children={
            <Layout preset="form-fields">
              <FormField<string, false>
                description="Paste in the Configuration ID from other field models."
                input={TextInput}
                footer="Press Enter to submit"
                inputProps={{
                  isMulti: false,
                }}
                error={
                  isValidConfigStatus === 'failed'
                    ? 'Invalid Configuration ID'
                    : undefined
                }
                success={
                  isValidConfigStatus === 'success'
                    ? 'ID Validated!'
                    : undefined
                }
                key="model-internal-config-id"
                label="Update Configuration ID"
                name="Update Configuration ID"
                onChange={setSelectedConfigId}
                placeholder="Paste Configuration ID"
                value={selectedConfigId ?? ''}
              />
              <Box
                sx={{
                  flex: 'auto',
                }}
              >
                <Button
                  text="Enter"
                  icon="arrow-back"
                  iconPosition="left"
                  isLoading={isLoadingCheckConfigId}
                  variant="primary"
                  disabled={!selectedConfigId}
                  tooltip={
                    !selectedConfigId
                      ? 'Please enter a Configuration ID'
                      : undefined
                  }
                  onClick={() => {
                    const updatedConfigId = {
                      ...config,
                      internal: selectedConfigId,
                    };
                    checkConfig({ config: updatedConfigId })
                      .unwrap()
                      .then(() => {
                        dispatch(
                          actions.setFieldAiConfiguration({
                            ...config,
                            internal: selectedConfigId,
                          }),
                        );
                      });
                  }}
                />
              </Box>
            </Layout>
          }
          isVisible={enableTogglePopover}
          onClickOutside={handleOnHide}
          onHide={handleOnHide}
          position="absolute"
          preset="popover"
        />
        <Configuration
          isConfigDirty={isConfigDirty}
          modelVersion={activeModelVersion}
          model={model ?? null}
          readOnly={Boolean(disableEditOnActiveModels)}
        />
        {activeModelVersion && (
          <Evaluate
            isConfigDirty={isConfigDirty}
            model={activeModelVersion}
            sectionTitle="Step 2: Evaluate Field Model"
            readOnly={Boolean(disableEditOnActiveModels)}
          />
        )}
        {enableDocumentXrayOptimize && activeModelVersion && (
          <Box
            styleProps={{
              bottom: 0,
              left: 0,
              position: 'absolute',
              right: 0,
            }}
            styles={styles.position}
          >
            <BoostTrigger
              modelVersion={activeModelVersion}
              readOnly={Boolean(disableEditOnActiveModels)}
            />
          </Box>
        )}
      </Layout>
    ),
    loadingContent,
    moreActions,
    title: 'Step 1: Field Model Configuration',
    welcomeContent,
    width: 'm' as const,
  };

  return (
    <>
      {enableDocumentXrayOptimize && optimize ? (
        <BoostModal field={field} onRefetch={refetchFieldModel} />
      ) : (
        <Modal
          cancelText="Close"
          disableHideOnEscape
          headerCalloutContent={
            isDocumentXRayBilling && !isLoading ? (
              <CreditBalance
                clientId={clientId}
                estimateUnit={{
                  modelId: isNew ? undefined : modelId,
                  isLoadingUnitValue: isFetchingDocsInScope,
                  isUninitialized,
                  unit: 'document-count',
                  value: docsInScope,
                }}
                getCreditsTooltip={getCreditsTooltip}
                getEstimatedCreditsTooltip={
                  field ? getEstimatedCreditsTooltip : selectAFieldTooltip
                }
                onCreditsSummaryAvailable={onCreditSummary}
              />
            ) : null
          }
          isFullPage
          isVisible
          loadingContent={loadingContent}
          panel={panel}
          primaryAction={primaryAction}
          title={title}
          chips={
            activeModelVersion
              ? [getModelPublishStatusChip(activeModelVersion?.publishStatus)]
              : undefined
          }
          onCancel={handleCancel}
        >
          <DocumentViewer
            {...documentViewer}
            placeholderContent={placeholderContent}
            onHighlightClick={
              enableXRayTagging
                ? {
                    getActions: getHighlightClickActions,
                  }
                : undefined
            }
            onHighlightCreate={
              enableXRayTagging
                ? {
                    getActions: getHighlightCreateActions,
                  }
                : undefined
            }
          />
        </Modal>
      )}
      {activeModelVersion && (
        <PublishModal
          isVisible={enablePublishModal}
          modelVersion={activeModelVersion}
          onCancel={() => {
            hideEnablePublishModal();
            hidePublishWarningModal();
          }}
        />
      )}
      {confirmDiscardChangesModal}
      {!enablePublishModal && publishWarningModal}
      {editWarningdModal}
    </>
  );
};
