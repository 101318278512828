import { Button, Layout, Text } from '~/eds';

import { SourceType } from '../../types';

type Props = SourceType;

export const MessageSource = ({
  isInline = false,
  text,
  onClick,
  disabled,
  tooltip,
  label,
}: Props) => {
  return (
    <Layout
      spacing={2}
      align="center"
      styles={[isInline ? styles.inline : undefined]}
    >
      <Button
        text={text}
        onClick={onClick}
        disabled={disabled}
        tooltip={tooltip}
        size="xs"
      />
      {label && <Text variant="tiny-bold">{label}</Text>}
    </Layout>
  );
};

const styles = {
  inline: {
    display: 'inline-block',
    marginLeft: 1,
  },
};
