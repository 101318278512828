import _ from 'lodash';

import { EntityType } from '~/enums';

import {
  CommentFilters,
  CommentId,
  ContextEntityType,
  Entity,
  EntityResolvers,
  Nullable,
  User,
} from './types';

export const defaultFilters: CommentFilters = {
  order: 'chronological',
  source: 'all',
  status: 'open',
  flags: [],
};

export const testIsTargetComment = (
  referenceCommentId: CommentId,
  targetCommentId?: Nullable<CommentId>,
) => referenceCommentId === targetCommentId;

export const testIsViewerUser = (user: User, viewer: User) => {
  return user.id === viewer.id;
};

export const resolveHighlightEntity = (
  highlightEntity: Entity<ContextEntityType> | undefined,
  entityResolvers: EntityResolvers,
) => {
  return highlightEntity
    ? entityResolvers?.highlight?.(highlightEntity as Entity<'highlight'>)
    : null;
};

export const getHighlightFromContext = (
  enitites: Entity<ContextEntityType>[],
) => {
  return enitites.find((entity) =>
    ([
      EntityType.Highlight,
      EntityType.EditorHighlight,
    ] as ContextEntityType[]).includes(entity.type),
  );
};

export const getThreadMessage = (filters: CommentFilters) =>
  testIsFiltersUpdated(filters)
    ? 'There are no comments.\n Leave a comment below!'
    : 'There are no comments with\n this filter. Please filter again';

export const testIsFiltersUpdated = (filters: CommentFilters) =>
  _.isEqual(filters, defaultFilters);
