import React from 'react';

import FallbackContent from '~/components/Shared/FallbackContent';
import { HttpStatusCodeType, QueryParamType } from '~/enums';
import { api } from '~/redux';
import { Icon, Link } from '~/ui';
import { getPageSearchQueryByKey } from '~/utils/searchQuery';

function Page() {
  const exportId = getPageSearchQueryByKey(QueryParamType.ExportId);
  const {
    data,
    error,
    isFetching,
  } = api.endpoints.checkDownloadExportReportById.useQuery(exportId);
  const status = error?.response?.status;
  const expiredExportId = status === HttpStatusCodeType.Gone;
  const notFoundExportId = status === HttpStatusCodeType.NotFound;

  function generateContent(icon: string, iconColor: string, content: any) {
    return (
      <FallbackContent
        icon={<Icon color={iconColor} icon={icon} size="xl" />}
        title={content}
      />
    );
  }

  if (isFetching) {
    return generateContent(
      'loading',
      'black-alpha-75',
      'Your download link is being verified.',
    );
  } else if (expiredExportId) {
    return generateContent('reject', 'red-600', 'Your download link expired.');
  } else if (notFoundExportId) {
    return generateContent(
      'reject',
      'red-600',
      'Your download link does not exist.',
    );
  } else {
    const url = data?.url;
    if (url) {
      const btn = (
        <Link variant="button" href={url}>
          Click here to download report
        </Link>
      );
      return generateContent('approve', 'green-500', btn);
    } else {
      return generateContent(
        'reject',
        'red-600',
        'Your download link is not found. Please re-run the export report.',
      );
    }
  }
}

export default Page;
