import React, {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Actions,
  ActionsMenu,
  Box,
  Divider,
  IconButton,
  Layout,
  Panel,
  parseContentAst,
  scrollElementIntoView,
  serializeContentAst,
  StatusText,
  Text,
  TextEditor,
  typedMemo,
  types,
  useToggle,
  useUuid,
} from '~/eds';
import { FlagType, useFlag } from '~/flags';

import { MESSAGES_CONTAINER_ID } from './constants';
import { Message as DeprecatedMessage } from './DEPRECATED_Message';
import { Message as NewMessage } from './Message';
import {
  ActiveContext,
  FeedbackDetails,
  GetUserTheme,
  MessageAction,
  Message as MessageType,
  SourceType,
} from './types';

interface Props<MessageSource = unknown, InlineSource = unknown> {
  currentUser: types.User;
  disclaimer: {
    accept: types.UserAction;
    welcomeContent: types.BaseWelcomeContentProps;
    hasAccepted: boolean;
  };
  interruptAction: { text: string; onClick: () => void };
  messages: MessageType<MessageSource, InlineSource>[];
  otherUser: types.User;
  replyMessage: types.Nullable<MessageType<MessageSource, InlineSource>>;
  title: string;
  messageActions: MessageAction<MessageType<MessageSource, InlineSource>>[];
  footer?: React.ReactNode;
  /** This is the component that shows the active context above the TextEditor */
  activeContext?: ActiveContext;
  /** List of context actions in the active panel*/
  activeContextActions?: types.MenuActions<string>;
  /** A list of filter chips for current context swapping */
  context?: {
    actions: types.UserAction[];
    label: string;
  };
  /** if provided, will be rendered when `messages` array is empty */
  emptyConversation?: ReactElement;
  isPosting?: boolean;
  isPrivate?: boolean;
  limit?: number;
  loadingContent?: types.SharedPanelProps['loadingContent'];
  moreActions?: types.UserAction[];
  placeholderContent?: types.SharedPanelProps['placeholderContent'];
  panelProps?: types.SharedPanelProps;
  chatActions?: types.UserAction[];
  userMessageActions?: ({
    resolveIcon?: (
      message: MessageType<MessageSource, InlineSource>,
    ) => types.IconType;
  } & MessageAction<MessageType<MessageSource, InlineSource>>)[];
  getMessageSources: (
    message: MessageType<MessageSource, InlineSource>,
  ) => SourceType[];
  shouldDisableSources?: (
    message: MessageType<MessageSource, InlineSource>,
  ) => boolean;
  getUserTheme: GetUserTheme;
  onChoose: (
    message: MessageType<MessageSource, InlineSource>,
    choice: string,
  ) => void;
  onPost: (text: string) => void;
  onSelectMessageSource: (
    message: MessageType<MessageSource, InlineSource>,
    sourceIndex: number,
  ) => void;
  onSubmitFeedback: (
    message: MessageType<MessageSource, InlineSource>,
    feedbackDetails: FeedbackDetails,
  ) => void;
}

export const Chat = typedMemo(
  <MessageSource extends unknown, InlineSource extends unknown>({
    currentUser,
    disclaimer,
    footer,
    getMessageSources,
    messageActions,
    getUserTheme,
    activeContext,
    context,
    activeContextActions,
    emptyConversation,
    interruptAction,
    isPosting,
    isPrivate,
    limit,
    loadingContent,
    messages,
    moreActions,
    otherUser,
    panelProps,
    placeholderContent,
    replyMessage,
    title,
    chatActions,
    userMessageActions,
    shouldDisableSources,
    onChoose,
    onPost,
    onSelectMessageSource,
    onSubmitFeedback,
  }: Props<MessageSource, InlineSource>) => {
    const [isFocused, _toggleIsFocused, focus, blur] = useToggle();

    const isNewMessageFormatEnabled = useFlag(FlagType.AskAnything);
    const Message = isNewMessageFormatEnabled ? NewMessage : DeprecatedMessage;

    const [choice, setChoice] = useState('');
    const [choiceKey, updateChoiceKey] = useUuid();

    const hasAcceptedDisclaimer = disclaimer.hasAccepted;

    const isLoading = loadingContent?.isLoading;
    const isReplying = Boolean(replyMessage);
    const handleInterrupt = interruptAction.onClick;

    // when unmounting, call the interrupt callback as a cleanup
    useEffect(() => handleInterrupt, [handleInterrupt]);

    // anytime a message is updated/added, always scroll to the end of the container
    useEffect(() => {
      scrollElementIntoView(MESSAGES_CONTAINER_ID, {
        behavior: 'smooth',
        block: 'end',
      });
      focus();
    }, [messages, replyMessage]);

    const handleChoose = useCallback(
      async (
        message: MessageType<MessageSource, InlineSource>,
        choice: string,
      ) => {
        await blur();
        onChoose(message, choice);
        setChoice(choice);
        updateChoiceKey();
        focus();
      },
      [isFocused, onPost],
    );

    const handlePost = useCallback(
      (updatedContent: types.ContentAst) => {
        onPost(serializeContentAst(updatedContent));
        setChoice('');
        updateChoiceKey();
        blur();
      },
      [onPost],
    );
    const mapMessage = useCallback(
      (message: MessageType<MessageSource, InlineSource>, index: number) => (
        <Fragment key={message.id}>
          <Message<MessageSource, InlineSource>
            disableChoices={isReplying}
            getMessageSources={getMessageSources}
            message={message}
            messageActions={
              message.userType === 'other'
                ? messageActions.map((messageAction) => ({
                    ...messageAction,
                    mode: 'icon' as const,
                    text: messageAction.tooltip,
                    onClick: () => messageAction.onClick(message, messages),
                  }))
                : []
            }
            user={message.userType === 'other' ? otherUser : currentUser}
            onChoose={handleChoose}
            onSelectSource={onSelectMessageSource}
            onSubmitFeedback={onSubmitFeedback}
            shouldDisableSources={shouldDisableSources}
            shouldShowActions={index > 0}
            getUserTheme={getUserTheme}
            index={index}
            userMessageActions={userMessageActions?.map((action) => ({
              ...action,
              mode: 'icon' as const,
              icon: action.resolveIcon?.(message) ?? action.icon,
              text: action.tooltip,
              onClick: () => action.onClick(message, messages),
            }))}
          />
          {isNewMessageFormatEnabled && message.userType === 'other' && (
            <Divider />
          )}
        </Fragment>
      ),
      [
        messages,
        getUserTheme,
        handleChoose,
        isReplying,
        onSelectMessageSource,
        shouldDisableSources,
        loadingContent,
      ],
    );

    const messagesContent = useMemo(() => messages.map(mapMessage), [
      messages,
      getMessageSources,
      userMessageActions,
    ]);

    const getConversation = () => {
      if (emptyConversation && !messages.length) return emptyConversation;

      return (
        <Layout
          direction="column"
          id={MESSAGES_CONTAINER_ID}
          justify="space-between"
          maxW="680px"
          mx="auto"
          my={0}
        >
          {messagesContent}
          {replyMessage && (
            <Message<MessageSource, InlineSource>
              getMessageSources={getMessageSources}
              isReplying={isReplying}
              message={replyMessage}
              user={otherUser}
              onChoose={handleChoose}
              onSelectSource={onSelectMessageSource}
              onSubmitFeedback={onSubmitFeedback}
              shouldDisableSources={shouldDisableSources}
              getUserTheme={getUserTheme}
            />
          )}
        </Layout>
      );
    };

    const renderActiveContext = ({
      onReset,
      icon,
      text,
      disableReset,
    }: ActiveContext) => (
      <Layout
        align="center"
        justify="space-between"
        w="100%"
        bg={onReset ? 'status.active.secondary' : undefined}
        styles={[
          {
            borderTopLeftRadius: 'm',
            borderTopRightRadius: 'm',
          },
        ]}
      >
        <Box w={onReset ? '92%' : '100%'} h="100%">
          <ActionsMenu
            trigger={
              <Box px={2}>
                <StatusText
                  iconSpacing="s"
                  icon={icon}
                  text={text}
                  shouldTruncate
                />
              </Box>
            }
            actions={activeContextActions ?? []}
            name="chat context"
            triggerWidth="100%"
          />
        </Box>

        {onReset && (
          <IconButton icon="x" onClick={onReset} disabled={disableReset} />
        )}
      </Layout>
    );

    const panelFooter = {
      actions: undefined,
      children: hasAcceptedDisclaimer ? (
        <Layout direction="column" spacing={2} maxW="680px" mx="auto" my={0}>
          <Layout align="center" spacing={1}>
            {context && (
              <>
                <Text variant="body" fontWeight="400">
                  {context.label}{' '}
                </Text>
                <Actions actions={context?.actions ?? []} size="s" />
              </>
            )}
          </Layout>
          <TextEditor
            key={choiceKey}
            DEPRECATED_enableImprovedPost
            content={choice ? parseContentAst(choice) : undefined}
            disabled={isLoading}
            limit={limit}
            limitMode="words"
            isFocused={isFocused}
            isPosting={isPosting}
            isReplying={isReplying}
            mode="text"
            onBlur={blur}
            onFocus={focus}
            onPost={handlePost}
            onInterrupt={handleInterrupt}
            placeholder="Ask or search anything…"
            actions={chatActions}
            header={
              activeContext ? renderActiveContext(activeContext) : undefined
            }
          />
          {footer}
        </Layout>
      ) : null,
      enableBorder: false,
    };

    const icon = isPrivate
      ? {
          icon: 'lock' as const,
          tooltip:
            'This chat is only visible to you and system administrators.',
        }
      : undefined;

    return (
      <Panel
        enableContentPadding={!hasAcceptedDisclaimer}
        footer={panelFooter}
        icon={icon}
        loadingContent={loadingContent}
        moreActions={moreActions}
        placeholderContent={placeholderContent}
        welcomeContent={
          hasAcceptedDisclaimer ? undefined : disclaimer.welcomeContent
        }
        title={title}
        {...panelProps}
      >
        {getConversation()}
      </Panel>
    );
  },
);
