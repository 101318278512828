import PT from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { styles, Text, theme } from '~/ui';

import { EDS_ACTION_COLOR } from '../../constants';

export default function Link({
  children,
  disabled = false,
  href,
  id,
  shouldTruncate,
  shouldWrap,
  target = '_self',
  to,
  variant,
  onClick,
}) {
  let elementType;
  if (to) {
    elementType = RouterLink;
  } else if (href) {
    elementType = 'a';
  } else {
    elementType = 'span';
  }

  return (
    <Text
      as={elementType}
      color={EDS_ACTION_COLOR}
      disabled={disabled}
      href={href}
      id={id}
      shouldTruncate={shouldTruncate}
      shouldWrap={shouldWrap}
      sx={{
        textDecoration: 'none',
        ...styles.interactions.clickable,
      }}
      target={target}
      to={to}
      variant={variant}
      onClick={onClick}
    >
      {children}
    </Text>
  );
}

Link.propTypes = {
  /** React child */
  children: PT.node,
  /** Disables the link */
  disabled: PT.bool,
  /** If provided, render as an anchor link */
  href: PT.string,
  /** Element ID */
  id: PT.string,
  /** If link should be truncated if it exceeds the parent width */
  shouldTruncate: PT.bool,
  /** If link text should wrap if it exceeds the parent width */
  shouldWrap: PT.bool,
  /** If provided, render as a router Link */
  to: PT.string,
  /** Text variant */
  variant: PT.oneOf(Object.keys(theme.text)),
  /** Click handler.  For accessibility and semantics, avoid using this if either `href` or  the `to` prop is specified.  */
  onClick: PT.func,
};
