import { isEqual } from 'lodash';

import { types } from '~/eds';
import { DataFieldType } from '~/enums';
import { DataField, PilotId } from '~/types';
import { sortStrings } from '~/utils';
import { capitalizeWords } from '~/utils/strings';

import { MAX_TESTCASE_NUMBER } from '../constants';
import { PromptModelTestCase, TestCaseReviewStatus } from '../types';
import { getModelOptimizeStateChip, getModelPublishStatusChip } from '../utils';
import { DEFAULT_OPTION_TEXT } from './constants';
import {
  FieldModel,
  FieldModelConfigState,
  FieldModelOutputType,
} from './types';

export const getModelHeader = ({
  name,
  optimizeState,
  publishStatus,
}: FieldModel) => ({
  chips: [
    optimizeState
      ? getModelOptimizeStateChip(optimizeState)
      : getModelPublishStatusChip(publishStatus),
  ],
  title: capitalizeWords(name),
});

export const prepareClassificationOptions = (
  config: FieldModelConfigState,
  testCases: PromptModelTestCase[],
) => {
  if (!config.fieldClassification) {
    return [];
  }

  const optionsMap = new Map(
    config.fieldClassification.options.map((option) => [option.value, option]),
  );

  if (!optionsMap.has(DEFAULT_OPTION_TEXT)) {
    optionsMap.set(DEFAULT_OPTION_TEXT, {
      value: DEFAULT_OPTION_TEXT,
      label: DEFAULT_OPTION_TEXT,
    });
  }

  const prepareOption = (value: string) => {
    if (!optionsMap.has(value)) {
      optionsMap.set(value, {
        label: value,
        value,
      });
    }
  };

  testCases.forEach((testCase) => {
    if (Array.isArray(testCase.modelValue?.value)) {
      testCase.modelValue?.value.forEach((modelValue) =>
        prepareOption(modelValue),
      );
    }
    if (Array.isArray(testCase.goldValue?.value)) {
      testCase.goldValue?.value.forEach((goldValue) =>
        prepareOption(goldValue),
      );
    }
  });

  return Array.from(optionsMap.values());
};

export const isDataFieldUnavailable = (type: DataFieldType): boolean => {
  switch (type) {
    case DataFieldType.ATTACHMENT:
    case DataFieldType.BOOLEAN:
    case DataFieldType.CLAUSE:
      return true;
    case DataFieldType.ARRAY_MULTIPLE:
    case DataFieldType.ARRAY_SINGLE:
    case DataFieldType.DATE:
    case DataFieldType.NUMBER:
    case DataFieldType.STRING:
    case DataFieldType.TEXT_AREA:
      return false;
    default:
      return true;
  }
};

export const testIsClassificationModel = (field: DataField) =>
  field.type === DataFieldType.ARRAY_MULTIPLE ||
  field.type === DataFieldType.ARRAY_SINGLE;

export const testIsMultiClassificationModel = (field: DataField) =>
  field.type === DataFieldType.ARRAY_MULTIPLE;

export const testIsTextExtractionModel = (field: DataField) =>
  field.type === DataFieldType.STRING || field.type === DataFieldType.TEXT_AREA;

export const mapStatusToTestCaseOutcome = (
  status: TestCaseReviewStatus,
): PromptModelTestCase['outcome'] => {
  switch (status) {
    case 'success':
      return 'correct';
    case 'danger':
      return 'incorrect';
    case 'warning':
      return 'partially_correct';
    case 'inactive':
      return 'skipped';
    case null:
    default:
      return 'unknown';
  }
};

export const mapDataFieldTypeToOutputType = (
  type: DataFieldType,
): FieldModelOutputType => {
  switch (type) {
    case DataFieldType.DATE:
      return 'datetime';
    case DataFieldType.NUMBER:
      return 'float';
    case DataFieldType.TEXT_AREA:
      return 'text_area';
    case DataFieldType.STRING:
    default:
      return 'string';
  }
};

export const mapTestCaseOutcomeToStatus = (
  testCase: PromptModelTestCase,
): TestCaseReviewStatus => {
  if (!testCase) {
    return null;
  }
  const { outcome } = testCase;
  switch (outcome) {
    case 'correct':
      return 'success';
    case 'incorrect':
      return 'danger';
    case 'partially_correct':
      return 'warning';
    case 'skipped':
      return 'inactive';
    case 'unknown':
    default:
      return null;
  }
};

/**
 * Groups data fields by availability (available at the top and unavailable below)
 * and sorts each group alphabetically.
 */
export const sortDataFieldOptions = (
  a: types.Option<PilotId, DataField>,
  b: types.Option<PilotId, DataField>,
): number => {
  if (a.disabled && !b.disabled) {
    return 1;
  } else if (!a.disabled && b.disabled) {
    return -1;
  } else {
    return sortStrings(a.label, b.label);
  }
};

export const testIsConfigDirty = (
  config: FieldModelConfigState,
  originalConfig?: FieldModelConfigState,
) => {
  return originalConfig
    ? !isEqual(originalConfig, config)
    : Boolean(
        config.field ||
          config.instructions ||
          config.filters.some((filter) => filter.values.length > 0) ||
          config.internal,
      );
};

export const testIsTestCaseNotExecuted = (testCase: PromptModelTestCase) =>
  testCase.state === 'not_started' || testCase.state === 'error';

export const testIsTestCaseEvaluating = (testCase: PromptModelTestCase) =>
  testCase.state === 'not_started' ||
  testCase.state === 'queued' ||
  testCase.state === 'running';

export const getValuesFound = (model: FieldModel) => {
  return model.testCases.reduce((acc, testCase) => {
    if (testCase.modelValue?.value != null) {
      return acc + 1;
    }
    return acc;
  }, 0);
};

export const selectAFieldTooltip = () =>
  'Please select a field to get an estimation.';

export const getReviewPaginateTailTooltip = ({
  docsInScope,
  pageIndex,
}: {
  docsInScope: number;
  pageIndex: number;
}) => {
  if (pageIndex === docsInScope) {
    return 'There are no more documents to rate.';
  } else if (pageIndex === MAX_TESTCASE_NUMBER) {
    return `Congratulations! You’ve rated ${MAX_TESTCASE_NUMBER} documents. This is the maximum number of documents you can rate.`;
  } else {
  }
};
