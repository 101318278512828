import { WorkflowFieldType } from '~/enums';
import { getFieldsBySectionId } from '~/reducers/intakeForm';

import { testIsNonEmpty } from './validateValues';

export const isValidFieldValue = (field) => {
  switch (field.type) {
    case WorkflowFieldType.ShortResponse:
    case WorkflowFieldType.Email:
    case WorkflowFieldType.SingleSelect:
    case WorkflowFieldType.Date:
    case WorkflowFieldType.LongResponse:
    case WorkflowFieldType.Radio:
      return testIsNonEmpty(field.value?.value);
    case WorkflowFieldType.Checkbox:
    case WorkflowFieldType.MultiSelect:
    case WorkflowFieldType.File:
    case WorkflowFieldType.User:
      return field.value.value?.length !== 0;
    case WorkflowFieldType.Number:
    case WorkflowFieldType.Department:
    case WorkflowFieldType.Percentage:
      return testIsNonEmpty(field.value.value);
    case WorkflowFieldType.TimePeriod:
      return (
        testIsNonEmpty(field.value.period) && testIsNonEmpty(field.value.amount)
      );
    case WorkflowFieldType.MonetaryValue:
      return (
        testIsNonEmpty(field.value.amount) &&
        testIsNonEmpty(field.value.currency)
      );
    case WorkflowFieldType.Address:
      return [
        field.value.city,
        field.value.country,
        field.value.state,
        field.value.street_1,
        field.value.zip_code,
      ].every(testIsNonEmpty);
    default:
      return true;
  }
};

export function testRequiredQuestionsAnswered(form) {
  const { fields, questions } = form;
  const allQuestions = Object.values(questions);
  for (const i in allQuestions) {
    const question = allQuestions[i];
    const field = fields[question.field];
    if (question.isRequired && field.isEnabled) {
      if (!isValidFieldValue(field)) {
        return false;
      }
    }
  }
  return true;
}

export function getAnsweredFieldCount(fields) {
  return fields.reduce((count, field) => {
    const hasAnswer =
      Object.values(field.value).filter((value) => value).length > 0;
    return !field.errorMessage && hasAnswer ? count + 1 : count;
  }, 0);
}

export function getEnabledFieldCount(fields) {
  return fields?.filter((field) => field.isEnabled).length;
}

export function isFormValid(intakeForm) {
  return !Object.values(intakeForm.fields).some(
    (field) => field.isEnabled && field.errorMessage,
  );
}

export const hasEnabledFields = (intakeForm, section) => {
  const fields = getFieldsBySectionId(intakeForm, section.id);
  const enabledFieldCount = getEnabledFieldCount(fields);

  return !!enabledFieldCount;
};
