import React from 'react';

import { typedMemo } from '../../utils';
import { SortableGrid } from '../SortableGrid';
import type { SortableGridItem } from '../SortableGrid';

type SortableListItem<T> = Omit<SortableGridItem<T>, 'width'>;
interface Props<T> {
  items: SortableListItem<T>[];
  rows?: number;
  onChange: (updatedItems: T[]) => void;
  renderTitle: (item: T) => string;
  renderContents?: (item: T) => React.ReactNode;
  isRemovable?: boolean;
}

export const SortableList = typedMemo(
  <T extends unknown>({
    isRemovable,
    items,
    onChange,
    renderContents,
    renderTitle,
  }: Props<T>) => {
    const handleRemoveItem = isRemovable
      ? (_item: T, itemIndex: number) => {
          const itemsContent: T[] = items.map((item) => item.item);
          onChange([
            ...itemsContent.slice(0, itemIndex),
            ...itemsContent.slice(itemIndex + 1),
          ]);
        }
      : undefined;

    return (
      <SortableGrid
        columns={1}
        items={items}
        renderContents={renderContents}
        renderTitle={renderTitle}
        onRemoveItem={handleRemoveItem}
        onSortEnd={onChange}
      />
    );
  },
);
