import { auth } from '../services';

export async function domainCheck(data) {
  return await auth.post('auth/login-info', data);
}

export async function loginWithClientUsernamePassword(data) {
  return await auth.post('auth/login', data);
}

export async function loginWithToken(token) {
  return await auth.post('auth/login-token', token);
}

export async function logout() {
  try {
    return await auth.post(
      '/auth/logout',
      {},
      {
        withCredentials: true,
      },
    );
  } catch (error) {
    if (error.response?.status === 401) {
      console.log('session expired');
    } else {
      throw error;
    }
  }
}

export function getOneTimeUseToken() {
  return auth.post('/auth/user-one-use-token', {}, { withCredentials: true });
}

export function getUserEditorConfigToken(data) {
  return auth.post('/auth/user-editor-config', data, { withCredentials: true });
}

export function getForceSaveToken(data) {
  return auth.post('/auth/force-save-token', data, { withCredentials: true });
}
