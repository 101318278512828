import React from 'react';

import { showToast } from '~/components/Shared/EcToast';
import { PersistedTable } from '~/components/Shared/PersistedTable';
import { User } from '~/components/Shared/User';
import { PAGE_START, UI_TABLE_PAGE_SIZE } from '~/constants/page';
import { Layout, PageLayout, parseDate, Text, types } from '~/eds';
import { QueryParamType, TableContextType, TicketStageType } from '~/enums';
import { FlagType, useFlag, withFlags } from '~/flags';
import { useCurrentUser, useTableSettings, useUsers } from '~/hooks';
import { checkIsWorkflowOnly } from '~/permissions';
import { api } from '~/redux';
import { TableTicket } from '~/types';
import { ERROR } from '~/types/toast.types';
import {
  getPageSearchQueryByKey,
  updatePageSearchQuery,
} from '~/utils/searchQuery';
import { capitalizeWords } from '~/utils/strings';

import {
  getSortBy,
  preProcessNewTicketsPageData,
  SortBy,
  TabsConfig,
  TicketCompletionType,
  WizardWorkflowTemplatesList,
} from './common';

const { useState, useEffect } = React;

const SORT_DESC = 'desc';
const SORT_ASC = 'asc';

const stageOrder = ['edit', 'review', 'sign', 'finalize'];

const tabsConfig: Record<TicketCompletionType, TabsConfig> = {
  [TicketCompletionType.NeedsAction]: {
    value: TicketCompletionType.NeedsAction,
    label: 'Needs Action',
    emptyTitle: 'No tickets assigned to you.',
    emptyMessage:
      'Once you create a new ticket or when you are assigned to a ticket, it will be shown here.',
    filter: 'needsAction=true,completed=false',
  },
  [TicketCompletionType.InProgress]: {
    value: TicketCompletionType.InProgress,
    label: 'In Progress',
    emptyTitle: 'No active tickets.',
    emptyMessage: 'It looks like there are no tickets being reviewed.',
    filter: 'needsAction=false,completed=false',
  },
  [TicketCompletionType.Cancelled]: {
    value: TicketCompletionType.Cancelled,
    label: 'Cancelled',
    emptyTitle: 'No cancelled tickets.',
    emptyMessage: 'It looks like there are no cancelled tickets.',
    filter: 'cancelled=true',
    featureFlag: FlagType.TicketListCancelledTab,
  },
  [TicketCompletionType.Done]: {
    value: TicketCompletionType.Done,
    label: 'Done',
    emptyTitle: 'No completed tickets.',
    emptyMessage: 'Once a ticket is finalized, it will be shown here.',
    filter: 'completed=true',
  },
  [TicketCompletionType.All]: {
    value: TicketCompletionType.All,
    label: 'All',
    emptyTitle: 'No tickets match your criteria.',
    filter: '',
  },
};

function getActiveTabConfig(activeTab: number) {
  return Object.values(tabsConfig)[activeTab];
}

interface WorkflowTicketsPageProps {
  flags: Record<FlagType, boolean>;
}
/**
 * TODO: Try updating all 'any' types that are still being used.
 * TODO: Create types for ticketsData and templatesData. We need to check if these types have to be shared.
 * TODO: Check if we can update activeTab parameter from a number to the tab name.
 * TODO: Update to new tooltip when placement is available.
 * TODO: Start removing ts-ignore as we start switching more of our code to typescript.
 * TODO: Look into typing ticket endpoints.
 */
const TicketList = ({ flags }: WorkflowTicketsPageProps) => {
  const refactorMoment = useFlag(FlagType.DeprecateMoment);
  const { tableSettings } = useTableSettings(TableContextType.WorkflowTickets);
  const currentUser = useCurrentUser();
  const isWorkflowOnlyUser = checkIsWorkflowOnly(currentUser);
  const urlParams = {
    activeTab: getPageSearchQueryByKey(
      [QueryParamType.ActiveTab],
      TicketCompletionType.NeedsAction,
    ),
    page: getPageSearchQueryByKey([QueryParamType.Page], PAGE_START),
    pageSize:
      tableSettings?.pageSize ||
      getPageSearchQueryByKey([QueryParamType.PageSize], UI_TABLE_PAGE_SIZE),
    sortedColumn:
      tableSettings?.sortBy?.id ||
      getPageSearchQueryByKey([QueryParamType.SortedColumn], 'last activity'),
    sortedOrder:
      tableSettings?.sortBy?.desc !== undefined
        ? tableSettings.sortBy.desc
          ? SORT_DESC
          : SORT_ASC
        : getPageSearchQueryByKey([QueryParamType.SortedOrder], SORT_DESC),
  };
  const [activeTab, setActiveTab] = useState(urlParams.activeTab);
  const [pageIndex, setPageIndex] = useState(urlParams.page);
  const [initRender, setInitRender] = useState(true);
  const [sortBy, setSortBy] = useState<SortBy>({
    id: urlParams.sortedColumn,
    desc: urlParams.sortedOrder === SORT_DESC,
  });
  const [pageSize, setPageSize] = useState(
    tableSettings?.pageSize || urlParams.pageSize,
  );
  // TODO: Remove this as well when we move searching to the BE
  const [isSearching, setIsSearching] = useState(false);
  const [searchText, setSearchText] = useState('');

  const activeTabConfig = getActiveTabConfig(activeTab);
  // TODO: This request gets all tickets and can be removed once we make search available on the BE for now it will be used to handle search in the FE.
  const {
    currentData: allTickets,
    isLoading: allTicketsLoading,
    isFetching: allTicketsFetching,
  } = api.endpoints.getNewTicketsData.useQuery({
    filter: activeTabConfig.filter,
    page: 0,
    pageSize: -1,
    sort: getSortBy(sortBy),
  });

  //* Pagination request which is going to run each time our params update.
  const {
    data,
    error,
    isLoading,
    isFetching,
  } = api.endpoints.getNewTicketsData.useQuery({
    filter: activeTabConfig.filter,
    page: pageIndex - 1,
    pageSize,
    sort: getSortBy(sortBy),
  });

  const {
    data: inProgressCount,
    isLoading: inProgressLoading,
    // @ts-ignore
  } = api.endpoints.getNewTicketsData.useQuery({
    filter: tabsConfig[TicketCompletionType.InProgress].filter,
    pageSize: 0,
  });

  const {
    data: needsActionCount,
    isLoading: needsActionLoading,
    // @ts-ignore
  } = api.endpoints.getNewTicketsData.useQuery({
    filter: tabsConfig[TicketCompletionType.NeedsAction].filter,
    pageSize: 0,
  });

  const {
    data: cancelledCount,
    isLoading: cancelledLoading,
    // @ts-ignore
  } = api.endpoints.getNewTicketsData.useQuery({
    filter: tabsConfig[TicketCompletionType.Cancelled].filter,
    pageSize: 0,
  });

  const {
    data: templates,
    error: templatesError,
    isLoading: isTemplatesLoading,
  } = api.endpoints.getAvailableWorkflows.useQuery({});

  const usersData = useUsers();

  const shouldDisplayLoadingState =
    isLoading || inProgressLoading || needsActionLoading || cancelledLoading;

  // TODO: can the creatorId be the user's extId. Since we already have the usersData have the extId make it easy to get the user.
  const [shouldShowCreateTicketList, setShouldShowCreateTicketList] = useState(
    false,
  );

  useEffect(() => {
    updatePageSearchQuery(urlParams);
  }, []);

  useEffect(() => {
    if (data && isWorkflowOnlyUser && initRender) {
      setInitRender(false);
      const { count }: any = data;
      const workflowOnlyRoleDefaultTab = count
        ? TicketCompletionType.NeedsAction
        : TicketCompletionType.InProgress;

      if (workflowOnlyRoleDefaultTab !== activeTab && !count) {
        setActiveTab(workflowOnlyRoleDefaultTab);
        updatePageSearchQuery({ activeTab: workflowOnlyRoleDefaultTab });
      }
    }
  }, [data]);

  useEffect(() => {
    if (isSearching) {
      setIsSearching(false);
    }
  }, [allTickets]);

  useEffect(() => {
    if (error) {
      showToast(ERROR, 'An error occurred while loading tickets.');
    }
    if (templatesError) {
      showToast(ERROR, 'An error occurred while loading workflow templates.');
    }
  }, [error, templatesError]);

  const handleShowCreateTicketList = (showCreateTicketList: boolean) => {
    setShouldShowCreateTicketList(showCreateTicketList);
  };

  const handleTabClick = (nextActiveTab: string) => {
    setActiveTab(Number(nextActiveTab));
    setPageIndex(PAGE_START);
    updatePageSearchQuery(
      {
        [QueryParamType.ActiveTab]: Number(nextActiveTab),
        [QueryParamType.Page]: PAGE_START,
      },
      true,
    );
  };

  const renderEmptyTabContent = (
    tabName: TicketCompletionType,
  ): JSX.Element => {
    const tabConfig: TabsConfig = tabsConfig[tabName];

    return (
      <Layout
        direction="column"
        align="center"
        justify="center"
        spacing={2}
        py={16}
      >
        <Text variant="body-bold">{tabConfig?.emptyTitle}</Text>
        <Text variant="body">{tabConfig?.emptyMessage}</Text>
      </Layout>
    );
  };

  const renderErrorMessage = (): JSX.Element => (
    <Layout
      direction="column"
      align="center"
      justify="center"
      spacing={2}
      py={4}
    >
      <Text variant="body-bold">An error occurred retrieving tickets.</Text>
      <Text variant="body">
        Refresh the page or reach out to out support team if the issue persists.
      </Text>
    </Layout>
  );

  const columns = [
    {
      key: 'name',
      // @ts-ignore
      cellType: 'link',
      title: 'Name',
      mapCellProps: (ticket: TableTicket) => ({
        text: ticket.name,
        pathname: `/workflow/tickets/${ticket.id}`,
      }),
    },
    {
      key: 'status',
      // @ts-ignore
      cellType: 'steps',
      title: 'Status',
      width: 'm',
      disableResizing: true,
      mapCellProps: (ticket: TableTicket) => {
        return {
          isCompleted: ticket.isCompleted,
          isCancelled: ticket.isCancelled,
          currentStepValue: ticket.currentStageName,
          defaultStepLabel: capitalizeWords(ticket.status),
          steps: stageOrder
            .filter((stage: string) => {
              return ticket.enabledStages.includes(stage as TicketStageType);
            })
            .map((stage: string) => {
              return {
                label: capitalizeWords(stage),
                value: stage,
              };
            }),
        };
      },
    },
    {
      key: 'submitted by',
      // @ts-ignore
      cellType: 'user',
      title: 'Submitted By',
      minWidth: 'm',
      disableResizing: true,
      mapCellProps: (ticket: TableTicket) => ({
        asyncUser: {
          id: ticket.creator,
          render: User,
        },
        mode: 'avatar-name',
      }),
    },
    {
      key: 'submitted on',
      cellType: 'datetime',
      title: 'Submitted On',
      width: 'm',
      disableResizing: true,
      mapCellProps: (ticket: TableTicket) => ({
        datetime: parseDate(ticket.createdDate, refactorMoment),
        format: 'full',
      }),
    },
    {
      key: 'last activity',
      cellType: 'datetime',
      title: 'Last Activity',
      width: 'm',
      disableResizing: true,
      mapCellProps: ({ lastActivityDate }: TableTicket) => ({
        datetime: parseDate(lastActivityDate, refactorMoment),
        format: 'relative',
      }),
    },
    {
      key: 'assigned to',
      // @ts-ignore
      cellType: 'users',
      title: 'Assigned To',
      width: 'm',
      align: 'left',
      disableResizing: true,
      disableSortBy: true,
      mapCellProps: (ticket: TableTicket) => {
        const users = usersData
          ? ticket.assignees.reduce((acc, id) => {
              if (usersData[id]) {
                acc.push(usersData[id]);
              }
              return acc;
            }, [] as types.User[])
          : [];

        return {
          users,
          limit: 4,
        };
      },
    },
    {
      key: 'participants',
      cellType: 'users',
      title: 'Participants',
      width: 'm',
      align: 'left',
      disableResizing: true,
      disableSortBy: true,
      mapCellProps: (ticket: TableTicket) => {
        const users = usersData
          ? ticket.participants.reduce((acc, id) => {
              if (usersData[id]) {
                acc.push(usersData[id]);
              }
              return acc;
            }, [] as types.User[])
          : [];

        return {
          users,
          limit: 4,
        };
      },
    },
  ];

  const handleUpdate = (state: any, action: any) => {
    switch (action.type) {
      case 'toggleSortBy':
        if (state.sortBy.length) {
          const { id, desc } = state.sortBy[0];

          if (desc) {
            updatePageSearchQuery(
              { sortedOrder: SORT_DESC, sortedColumn: id },
              true,
            );
          } else {
            updatePageSearchQuery(
              { sortedOrder: SORT_ASC, sortedColumn: id },
              true,
            );
          }

          setSortBy(state.sortBy[0]);
        }
        break;
      default:
        return;
    }
  };

  const handlePagination = ({ pageIndex }: any) => {
    setPageIndex(pageIndex);
    updatePageSearchQuery({ page: pageIndex }, true);
  };

  const handleSearch = (searchText: string) => {
    setSearchText(searchText);
    setPageIndex(PAGE_START);
    if (allTicketsLoading || allTicketsFetching) {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
  };

  const handlePageSize = (value: number) => {
    updatePageSearchQuery({ pageSize: value }, true);
    setPageSize(value);
  };

  const getFilteredData = (data: TableTicket[]) =>
    data.filter((ticket: TableTicket) => {
      const matchesName = ticket.name
        .toLowerCase()
        .trim()
        .includes(searchText.toLocaleLowerCase().trim());
      const submittedBy = ticket.creatorName
        .toLowerCase()
        .trim()
        .includes(searchText.toLocaleLowerCase().trim());
      return matchesName || submittedBy;
    });

  const paginateTickets = (data: TableTicket[]) => {
    const startIndex = (pageIndex - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, data.length - 1);

    return data.slice(startIndex, endIndex + 1);
  };

  const getTable = (tableData: TableTicket[], count: number) => {
    const filteredTickets =
      searchText.length > 0 ? getFilteredData(tableData) : tableData;
    const tickets =
      searchText.length > 0 ? paginateTickets(filteredTickets) : tableData;
    const totalCount = searchText.length > 0 ? filteredTickets.length : count;

    return (
      <PersistedTable
        name="tickets"
        context={TableContextType.WorkflowTickets}
        columns={columns}
        data={tickets}
        options={{
          enableSearch: true,
          enableExportXlsx: false,
          enableManageColumns: true,
          enableSelectRows: false,
          enablePagination: true,
          enablePageSizeSelect: true,
        }}
        state={{
          columnOrder: tableSettings?.columnOrder,
          globalFilter: searchText,
          pageSize,
          pageIndex,
          sortBy: [sortBy],
        }}
        onPaginate={handlePagination}
        onPageSizeChange={handlePageSize}
        onUpdate={handleUpdate}
        isLoading={isFetching || isSearching}
        onSearch={handleSearch}
        reactTableOptions={{
          autoResetSortBy: false,
          disableSortRemove: true,
          manualSortBy: true,
        }}
        totalCount={totalCount}
        searchText={searchText}
      />
    );
  };

  const getCount = (tab: string) => {
    let count;
    switch (tab) {
      case 'Needs Action':
        // @ts-ignore
        count = needsActionCount?.count;
        break;
      case 'In Progress':
        // @ts-ignore
        count = inProgressCount?.count;
        break;
      case 'Cancelled':
        // @ts-ignore
        count = cancelledCount?.count;
        break;
      default:
        count = undefined;
    }
    return count;
  };

  // Deriving conditional page tabs
  const ticketData: any =
    searchText.length > 0 && allTickets ? allTickets : data;
  const count = ticketData ? ticketData.count : 0;
  const tickets = ticketData ? ticketData.data : [];
  const ticketTableData: TableTicket[] = preProcessNewTicketsPageData(
    tickets.map((ticket: TableTicket) => ({ ...ticket })),
  );
  const initWorkFlowOnlyLoad = isWorkflowOnlyUser && initRender;
  const tabConfigs = Object.values(tabsConfig);
  const selectedTabValue = tabConfigs.find(
    (config) => config.value === activeTab,
  );
  const tabs: types.Tab[] = tabConfigs
    .filter((config) => !config.featureFlag || flags[config.featureFlag])
    .map((config) => ({
      label: config.label,
      value: String(config.value),
      count: getCount(config.label),
      panel: error
        ? renderErrorMessage()
        : ticketTableData.length > 0 || initWorkFlowOnlyLoad || isFetching
        ? getTable(ticketTableData, count)
        : renderEmptyTabContent(
            Number(selectedTabValue) || TicketCompletionType.NeedsAction,
          ),
    }));

  if (shouldShowCreateTicketList) {
    return (
      <WizardWorkflowTemplatesList
        templatesData={templates}
        hideModal={() => handleShowCreateTicketList(false)}
      />
    );
  }

  return (
    <PageLayout
      actions={[
        {
          isLoading: isTemplatesLoading,
          text: 'New Ticket',
          level: 'primary',
          onClick: () => handleShowCreateTicketList(true),
          disabled: !templates || !templates.length,
        },
      ]}
      loadingContent={{
        isLoading: shouldDisplayLoadingState,
        message: 'Loading tickets…',
      }}
      tabs={{
        enableContentPadding: false,
        tabs,
        selectedTab: String(activeTab),
        onSelectTab: handleTabClick,
      }}
      title="Tickets"
    />
  );
};

export default withFlags(TicketList);
