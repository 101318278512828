/* eslint-disable prettier/prettier */
import * as amplitude from '@amplitude/analytics-browser';
import { defaultEventTrackingAdvancedPlugin } from '@amplitude/plugin-default-event-tracking-advanced-browser';
import * as Sentry from '@sentry/react';
import { registerLicense } from '@syncfusion/ej2-base';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'react-sortable-tree/style.css';
import { ThemeProvider } from 'theme-ui';

import { getEnvVariable, isDev } from '~/dev';
import { withFlagsProvider } from '~/flags';

import App from './components/App';
import './polyfill';
import store from './store';
import './styles/index.css';
import './styles/normalize.css';
import './styles/react-ckeditor_overrides.css';
import './styles/react-datepicker_overrides.css';
import './styles/react-pdf_overrides.css';
import './styles/react-tooltip_overrides.css';
import './styles/react_dates_overrides.css';
import { theme } from './ui/';
import { formatPathname, getClientName } from './utils';

// Amplitude initialization
if (!isDev) {
  amplitude.init(getEnvVariable('REACT_APP_AMPLITUDE_API_KEY'));
  amplitude.add(defaultEventTrackingAdvancedPlugin());
}

// Registering Syncfusion license key
registerLicense(getEnvVariable('REACT_APP_EJ2_LICENSE_KEY'));

Sentry.init({
  enabled: true,
  dsn: getEnvVariable('SENTRY_DSN'),
  environment: getEnvVariable('SENTRY_ENVIRONMENT'),
  integrations: [
    Sentry.browserTracingIntegration({
      shouldCreateSpanForRequest: (url) => {
        return !url.includes('sockjs-mt1.pusher.com');
      },
      beforeStartSpan: (context) => {
        return {
          ...context,
          // You could use your UI's routing library to find the matching
          // route template here. We don't have one right now, so do some basic
          // parameter replacements.
          attributes: {
            ...context.attributes,
            // eslint-disable-next-line
            clientName: getClientName(location.pathname),
          },
          // eslint-disable-next-line
          name: formatPathname(location.pathname),
        };
      },
      enableInp: true,
    }),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      block: ['#ej2-editor-container', '.PdfHighlighter'],
    }),
    Sentry.replayCanvasIntegration(),
  ],
  enableTracing: true,
  sendDefaultPii: true,

  // DO NOT CHANGE THIS VALUE unless you know what you are doing.
  tracesSampleRate: Number(getEnvVariable('SENTRY_TRACES_SAMPLE_RATE')),
  tracePropagationTargets: [
    /^https:\/\/(?!editor\.)(?!auth\.)[\w.]+evisort\.(com|dev)/,
  ],

  // DO NOT CHANGE THIS VALUE unless you know what you are doing.
  // Session Replay
  replaysSessionSampleRate: Number(
    getEnvVariable('SENTRY_REPLAYS_SESSION_SAMPLE_RATE'),
  ),
  replaysOnErrorSampleRate: Number(
    getEnvVariable('SENTRY_REPLAYS_ONERROR_SAMPLE_RATE'),
  ),

  // DO NOT CHANGE THIS VALUE unless you know what you are doing.
  profilesSampleRate: Number(getEnvVariable('SENTRY_PROFILES_SAMPLE_RATE')),

  // according to documentation, the plugin already injects it
  maxValueLength: 8192,
  denyUrls: [
    // Chrome extensions
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
  ],
  // https://sentry.io/answers/react-resizeobserver-loop-completed-with-undelivered-notifications/
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
  ],
});

const renderApp = async () => {
  const FlagsProvider = await withFlagsProvider();

  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <FlagsProvider>
          <App />
        </FlagsProvider>
      </ThemeProvider>
    </Provider>,
    document.getElementById('root'),
  );
};

renderApp();
