import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  EndpointBuilder,
  MutationExtraOptions,
  QueryExtraOptions,
} from '@reduxjs/toolkit/dist/query/endpointDefinitions';

import { TagType } from './TagType';

// TODO: confirm intended generic types for data and error
type Any = any;

type EndpointMethod<ResultType, QueryArg> = (
  arg: QueryArg,
) => Promise<ResultType>;

type EndpointOptions = {
  keepUnusedDataFor?: 60 | 600; // specific enum durations in seconds
};

type MutationOptions<
  ResultType,
  QueryArg,
  ReducerPath extends string
> = MutationExtraOptions<
  TagType,
  ResultType,
  QueryArg,
  BaseQueryFn<QueryArg, ResultType>,
  ReducerPath
> &
  EndpointOptions;

type QueryOptions<
  ResultType,
  QueryArg,
  ReducerPath extends string
> = QueryExtraOptions<
  TagType,
  ResultType,
  QueryArg,
  BaseQueryFn<QueryArg, ResultType>,
  ReducerPath
> &
  EndpointOptions;

export const createQueryFn = <ResultType, QueryArg>(
  asyncMethod: EndpointMethod<ResultType, QueryArg>,
) => async (arg: QueryArg): Promise<{ data?: Any; error: Any }> => {
  let data, error: Any;
  try {
    data = await asyncMethod(arg);
  } catch (e) {
    error = e;
  }

  return { data, error };
};

export const createEndpoint = <
  ResultType,
  QueryArg,
  ReducerPath extends string
>(
  builder: EndpointBuilder<Any, TagType, ReducerPath>,
  method: EndpointMethod<ResultType, QueryArg>,
  options: Partial<QueryOptions<ResultType, QueryArg, ReducerPath>> = {},
) =>
  builder.query({
    queryFn: createQueryFn(method),
    ...options,
  });

export const createMutation = <
  ResultType,
  QueryArg,
  ReducerPath extends string
>(
  builder: EndpointBuilder<Any, TagType, ReducerPath>,
  method: EndpointMethod<ResultType, QueryArg>,
  options: Partial<MutationOptions<ResultType, QueryArg, ReducerPath>> = {},
) =>
  builder.mutation({
    queryFn: createQueryFn(method),
    ...options,
  });
