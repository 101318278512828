/**
 * This implementation repackages a minimal API based on a heavily outdated version of react-toast.  We plan to swap/update the internal implementation after the app is fully using the `eds.useToast` solution.
 * https://github.com/fkhadra/react-toastify/tree/v4.5.2
 */
import React from 'react';
import {
  Slide,
  toast as vendorToast,
  ToastContainer as VendorToastContainer,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { SharedToastProps } from '../../types';
import { wordCount } from '../../utils';
import { Toast } from '../Toast';

interface VendorToastProps {
  closeToast: () => void;
}

export const useToast = () => {
  /**
   * API to create a toast instance.
   */
  const toast = (props: SharedToastProps) => {
    const autoCloseIsFalse = props.autoClose === false;
    const textContent = props.message + '\n' + (props.title ?? '');

    // integrate and determine `vendor.closeToast` with `Toast.onDismiss`
    const enableDismiss = props.action || props.onDismiss || autoCloseIsFalse;

    const renderToast = ({ closeToast }: VendorToastProps) => {
      const onDismiss = enableDismiss
        ? () => {
            props.onDismiss?.();
            closeToast();
          }
        : undefined;

      const action = props.action
        ? {
            ...props.action,
            onClick: () => {
              props.action?.onClick?.();
              closeToast();
            },
          }
        : undefined;

      const toastProps = {
        ...props,
        action,
        onDismiss,
      };

      return <Toast {...toastProps} />;
    };

    return vendorToast(renderToast, {
      ...DEFAULT_OPTIONS,
      autoClose:
        props.action || autoCloseIsFalse
          ? false
          : getAutoDismissDuration(textContent),
      toastId: props.key,
    });
  };

  /**
   * API to programmatically dismiss a toast.  Use this sparingly and rely more on the `toast` API to manage the toast instance.
   * - When called without arguments, will dismiss all toasts.
   * - When called with a specific argument (toastId), will dismiss the specific toast.  Consumers are required to track/manage toastIds returned from the `toast` API
   *
   * @example
   * ```js
   * const { dismiss, toast } = useToast();
   * const toastId = toast(...);
   *
   * dismiss(toastId); // dismisses just the specified toast
   * dismiss(); // dismisses all toasts
   * ```
   */
  const dismiss = vendorToast.dismiss;
  const isActive = vendorToast.isActive;

  return {
    dismiss,
    isActive,
    toast,
    ToastContainer,
  };
};

const ToastContainer = () => <VendorToastContainer />; // intentionally NOT configurable (to aid safe migration)

// https://github.com/fkhadra/react-toastify/tree/v4.5.2#toastcontainer
export const DEFAULT_OPTIONS = {
  closeButton: false,
  closeOnClick: false,
  draggable: false,
  hideProgressBar: true,
  className: 'eds-react-toastify_toast-container',
  position: vendorToast.POSITION.BOTTOM_CENTER,
  transition: Slide,
};

export const getAutoDismissDuration = (content: string) => {
  return Math.max(wordCount(content) * DURATION_PER_WORD_MS, MIN_DURATION_MS);
};

export const DURATION_PER_WORD_MS = 500;
export const MIN_DURATION_MS = 6000;
