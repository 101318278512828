import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackSegment } from '~/components/SegmentAnalytics';
import { showToast } from '~/components/Shared/EcToast';
import { MAX_DASHBOARD_NAME_LENGTH } from '~/constants/max_lengths';
import { Modal, Text, TextInput } from '~/eds';
import { SavedDashboardsActions } from '~/enums';
import { useCurrentUser } from '~/hooks';
import { api, selectors } from '~/redux';
import savedDashboards from '~/redux/slices/savedDashboards';
import { ERROR, SUCCESS } from '~/types/toast.types';

export const RenameDashboard = () => {
  const [name, setName] = useState('');
  const activeModal = useSelector(selectors.selectSavedDashboardsActiveModal);
  const user = useCurrentUser();

  const [
    updateDashboard,
    updateDashboardResult,
  ] = api.endpoints.updateDashboard.useMutation();
  const {
    isError: isErrorUpdate,
    isLoading: isLoadingUpdate,
    isSuccess: isSuccessUpdate,
    error: errorUpdate,
  } = updateDashboardResult;

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccessUpdate) {
      showToast(SUCCESS, 'Dashboard renamed successfully.');
      dispatch(savedDashboards.actions.closeModal());
    }
  }, [isSuccessUpdate]);

  useEffect(() => {
    if (isErrorUpdate) {
      const resp = errorUpdate?.response || {};
      const msg = resp.data?.errors?.[0];
      showToast(
        ERROR,
        msg?.title || 'An error occurred while renaming the dashboard.',
      );
      dispatch(savedDashboards.actions.closeModal());
    }
  }, [isErrorUpdate]);

  useEffect(() => {
    if (activeModal?.selectedDashboard) {
      setName(activeModal.selectedDashboard.name);
    } else {
      setName('');
    }
  }, [activeModal?.selectedDashboard]);

  const getTooltip = () => {
    if (!name?.trim()) {
      return 'Please enter a name.';
    } else if (name?.length > MAX_DASHBOARD_NAME_LENGTH) {
      return `A dashboard name can not exceed ${MAX_DASHBOARD_NAME_LENGTH} characters.`;
    } else {
      return '';
    }
  };

  return (
    <Modal
      isVisible={activeModal?.name === SavedDashboardsActions.Rename}
      onHide={() => {
        dispatch(savedDashboards.actions.closeModal());
      }}
      onCancel={() => {
        dispatch(savedDashboards.actions.closeModal());
      }}
      title="Rename Dashboard"
      primaryAction={{
        text: 'Save Dashboard',
        onClick: () => {
          trackSegment('submitRenameDashboard', {
            id: activeModal!.selectedDashboard.id,
            name: activeModal!.selectedDashboard.name,
            user_id: user.id,
            client_id: user.client,
          });
          updateDashboard({
            uuid: activeModal!.selectedDashboard.id,
            attributes: { name },
          });
        },
        isLoading: isLoadingUpdate,
        disabled:
          !name?.trim() ||
          name?.length > MAX_DASHBOARD_NAME_LENGTH ||
          isLoadingUpdate,
        tooltip: getTooltip(),
      }}
    >
      <Text mb={2} variant="body-medium">
        Dashboard Name
      </Text>
      <TextInput
        autoFocus
        name="dashboardName"
        placeholder="Dashboard name"
        value={name}
        onChange={(value) => setName(value || '')}
      />
    </Modal>
  );
};
