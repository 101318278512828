import React from 'react';

import UserName from '~/components/Client/UserName';
import Datetime from '~/components/Shared/Datetime';
import { Box, FlexLayout, Icon, Text } from '~/ui';
import { getFileIcon } from '~/utils/files';

export function getLatestVersion(documentVersions) {
  return documentVersions?.length ? documentVersions[0].versionNumber : 1;
}

export function getVersionLabelText(versionNumber) {
  if (!versionNumber) return null;
  return `Version ${versionNumber}`;
}

function renderItemDetail(versionsObject, item) {
  const {
    isGenerated,
    referenceVersionId,
    targetVersionId,
    userId,
    versionNumber,
  } = item;
  const isCounterpartyEditVersion = isGenerated && !targetVersionId;
  if (versionNumber === 1 || isCounterpartyEditVersion) {
    return (
      <>
        Created by <UserName userId={userId} />
      </>
    );
  } else if (isGenerated) {
    if (referenceVersionId === targetVersionId) {
      return (
        <>
          Saved by <UserName userId={userId} />
        </>
      );
    } else {
      return (
        <>
          Redline of Version {versionsObject[targetVersionId]} compared to
          Version {versionsObject[referenceVersionId]} saved by{' '}
          <UserName userId={userId} />
        </>
      );
    }
  } else {
    return (
      <>
        Uploaded by <UserName userId={userId} />
      </>
    );
  }
}

export function getVersionNumberIds(versionsArray) {
  return Object.fromEntries(
    Object.entries(versionsArray).map(([_key, val]) => [
      [val.id],
      val.versionNumber,
    ]),
  );
}

export function getOptionLabel({
  versionsObject,
  expanded,
  version,
  isSelected,
  disabled,
}) {
  const { fileType, isCounterparty, lastUpdated, versionNumber } = version;

  const dummyFile = new File([], `file.${fileType}`); // create a dummy file for reference with file APIs.
  return (
    <Box
      p={4}
      disabled={disabled}
      sx={{
        width: '100%',
        backgroundColor: isSelected ? 'gray-200' : 'transparent',
      }}
    >
      <FlexLayout alignItems="center" justifyContent="space-between" space={1}>
        <FlexLayout space={1}>
          <Icon icon={getFileIcon(dummyFile)} size="s" />
          <Text color="gray-700" variant="body1">
            {getVersionLabelText(versionNumber)}
          </Text>
        </FlexLayout>
        {isCounterparty && (
          <FlexLayout
            p={2}
            sx={{
              border: 'border',
              borderRadius: '4px',
              backgroundColor: 'white',
            }}
          >
            <Text color="black-alpha-75" variant="tiny">
              Counterparty
            </Text>
          </FlexLayout>
        )}
      </FlexLayout>
      {expanded && (
        <>
          <Box>
            <Text color="gray-900" variant="tiny">
              {renderItemDetail(versionsObject, version)}
            </Text>
          </Box>
          <Box>
            <Text color="gray-600" variant="2xs-dense">
              <Datetime datetime={lastUpdated} format="duration" />
            </Text>
          </Box>
        </>
      )}
    </Box>
  );
}
