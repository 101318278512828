import { CollapsibleList } from '~/eds';

import { SourceType } from '../../types';
import { MessageSource } from './MessageSource';

interface Props {
  inlineSources: SourceType[];
}
export const InlineSources = ({ inlineSources = [] }: Props) => {
  return (
    <CollapsibleList
      name="sources_list"
      limit={3}
      items={inlineSources}
      renderItem={(item) => <MessageSource {...item} />}
      serializeItem={(item) => `Source ${item.text}`}
    />
  );
};
