import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ClauseForm,
  State,
  VariationForm,
} from '~/components/ClauseLibrary/types';
import { types } from '~/eds';
import { ClauseLibraryViewType, SliceType } from '~/enums';
import { ClauseLibraryFilters } from '~/types';

export const initialState: State = {
  clauseSummaries: [],
  isLoadingSummaries: true,
  view: ClauseLibraryViewType.MainPage,
  editMode: false,
  selectedClause: undefined,
  filters: null,
  clauseForm: {
    name: '',
    guidanceNotes: '',
    title: '',
    errors: [],
    validationErrors: [],
  },
  variationForm: {
    name: '',
    guidanceNotes: '',
    clauseText: '',
    title: '',
    validationErrors: [],
  },
};

export const name = SliceType.ClauseLibrary;

///// REDUCERS //////

const resetClauseLibraryExceptSelectedClauseReducer = (state: State) => {
  return { ...initialState, selectedClause: state.selectedClause };
};

const cleanupClauseSummariesReducer = (state: State) => {
  return { ...state, clauseSummaries: [], isLoadingSummaries: true };
};

const setClauseLibraryEditModeReducer = (
  state: State,
  action: PayloadAction<boolean>,
) => {
  state.editMode = action.payload;
};

const setClauseLibraryClauseFormReducer = (
  state: State,
  action: PayloadAction<ClauseForm>,
) => {
  state.clauseForm = action.payload;
};

const setClauseLibraryClauseGuidanceNotesReducer = (
  state: State,
  action: PayloadAction<string>,
) => {
  state.clauseForm = { ...state.clauseForm, guidanceNotes: action.payload };
};

const setClauseLibraryVariationFormReducer = (
  state: State,
  action: PayloadAction<VariationForm>,
) => {
  state.variationForm = action.payload;
};

const setClauseLibraryVariationGuidanceNotesReducer = (
  state: State,
  action: PayloadAction<string>,
) => {
  state.variationForm = {
    ...state.variationForm,
    guidanceNotes: action.payload,
  };
};

const setClauseLibraryClauseSummariesReducer = (
  state: State,
  action: PayloadAction<types.TreeNode[]>,
) => {
  state.clauseSummaries = action.payload;
};

const setClauseLibraryIsLoadingSummariesReducer = (
  state: State,
  action: PayloadAction<boolean>,
) => {
  state.isLoadingSummaries = action.payload;
};

const setClauseLibraryVariationClauseTextReducer = (
  state: State,
  action: PayloadAction<string>,
) => {
  state.variationForm = { ...state.variationForm, clauseText: action.payload };
};

// validation errors returned by component, errors returned by backend
const resetClauseLibraryClauseFormGuidanceErrorReducer = (state: State) => {
  state.clauseForm = {
    ...state.clauseForm,
    errors: state.clauseForm.errors?.filter(
      (error) => error.source.pointer !== '/guidance',
    ),
  };
};

const resetClauseLibraryVariationEditorsValidationErrorsReducer = (
  state: State,
) => {
  state.variationForm = {
    ...state.variationForm,
    validationErrors: state.variationForm.validationErrors?.filter(
      (error) => error !== 'text missing',
    ),
  };
};

const resetClauseLibraryVariationGuidanceErrorReducer = (state: State) => {
  state.variationForm = {
    ...state.variationForm,
    errors: state.variationForm.errors?.filter(
      (error) => error.source.pointer !== '/guidance',
    ),
  };
};

const resetClauseLibraryVariationTextErrorReducer = (state: State) => {
  state.variationForm = {
    ...state.variationForm,
    errors: state.variationForm.errors?.filter(
      (error) => error.source.pointer !== '/text',
    ),
  };
};

const setClauseLibraryViewReducer = (
  state: State,
  action: PayloadAction<ClauseLibraryViewType>,
) => {
  state.view = action.payload;
};

const setClauseLibrarySelectedClauseReducer = (
  state: State,
  action: PayloadAction<types.TreeNode | undefined>,
) => {
  state.selectedClause = action.payload;
};

const setClauseLibraryFilterReducer = (
  state: State,
  action: PayloadAction<ClauseLibraryFilters>,
) => {
  state.filters = action.payload;
};

////// SLICE DEFINITION ///////

export default createSlice({
  name,
  initialState,
  reducers: {
    resetClauseLibraryExceptSelectedClause: resetClauseLibraryExceptSelectedClauseReducer,
    cleanupClauseSummaries: cleanupClauseSummariesReducer,
    setClauseLibraryEditMode: setClauseLibraryEditModeReducer,
    setClauseLibraryClauseForm: setClauseLibraryClauseFormReducer,
    setClauseLibraryClauseGuidanceNotes: setClauseLibraryClauseGuidanceNotesReducer,
    setClauseLibraryClauseSummaries: setClauseLibraryClauseSummariesReducer,
    setClauseLibraryIsLoadingSummaries: setClauseLibraryIsLoadingSummariesReducer,
    setClauseLibraryVariationForm: setClauseLibraryVariationFormReducer,
    setClauseLibraryVariationGuidanceNotes: setClauseLibraryVariationGuidanceNotesReducer,
    setClauseLibraryVariationClauseText: setClauseLibraryVariationClauseTextReducer,
    resetClauseLibraryClauseFormGuidanceError: resetClauseLibraryClauseFormGuidanceErrorReducer,
    resetClauseLibraryVariationEditorsValidationErrors: resetClauseLibraryVariationEditorsValidationErrorsReducer,
    resetClauseLibraryVariationGuidanceError: resetClauseLibraryVariationGuidanceErrorReducer,
    resetClauseLibraryVariationTextError: resetClauseLibraryVariationTextErrorReducer,
    setClauseLibraryView: setClauseLibraryViewReducer,
    setClauseLibrarySelectedClause: setClauseLibrarySelectedClauseReducer,
    setClauseLibraryFilter: setClauseLibraryFilterReducer,
  },
});
