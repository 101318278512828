/**
 * Purpose: Provides a single point of entry for the application to
 * obtain a new access token.
 *
 * It has no context of who is asking for a refresh, while only cares about:
 * - there is only one concurrent request
 * - if the request fails, it assumes the refresh token has expired (and we log the user out)
 */
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

import AuthenticationStore from '~/auth';
import { LOGIN_CONSTANTS } from '~/features/login';
import Configuration from '~/services/configuration';
import {
  redirectToDomainLogin,
  redirectToLoginEmbed,
} from '~/services/redirects';

function getRefreshAccessToken(refreshToken) {
  const AS = AuthenticationStore();

  const headers = { Authorization: `Bearer ${refreshToken}` };
  if (AS.getCsrf()) {
    headers[LOGIN_CONSTANTS.HEADERS.CSRF_TOKEN] = AS.getCsrf();
  }

  return axios.post(
    `${Configuration.authEndpoint}/v1/auth/refresh`,
    {},
    { headers },
  );
}

function getRefreshAccessHttpOnlyToken() {
  const AS = AuthenticationStore();

  return axios.post(
    `${Configuration.authEndpoint}/v1/auth/refresh`,
    {},
    {
      headers: AS.getCsrf()
        ? { [LOGIN_CONSTANTS.HEADERS.CSRF_TOKEN]: AS.getCsrf() }
        : {},
      withCredentials: true,
    },
  );
}

let refreshPromise;

export const refreshAccessToken = async () => {
  try {
    const AS = AuthenticationStore();
    const refreshToken = AS.getRefreshToken();

    if (refreshToken) {
      // when httponly flag is not enabled
      refreshPromise = refreshPromise || getRefreshAccessToken(refreshToken);
      const response = await refreshPromise;

      const accessToken = response.data.access_token;
      AS.saveAccessToken(accessToken);
    } else {
      // when httponly flag is enabled
      refreshPromise = getRefreshAccessHttpOnlyToken();
      await refreshPromise;
    }
    AS.saveAccessTokenCreationTime();
  } catch (error) {
    const AS = AuthenticationStore();
    AS.clearAuthentication();
    AS.isEmbed() ? redirectToLoginEmbed() : redirectToDomainLogin();
  } finally {
    refreshPromise = undefined;
  }
};

export const shouldRefreshAccessToken = () => {
  const AS = AuthenticationStore();
  const accessToken = AS.getAccessToken();
  const accessTokenCreationTime = AS.getAccessTokenCreationTime() + 900000; // creation date + 15 minutes
  const tokenExpTimestamp = accessToken
    ? jwtDecode(accessToken).exp * 1000
    : accessTokenCreationTime;

  // TODO: remove this check and the ternary above? Or return true?
  // It is unclear how this can happen without manually fiddling with the cookie in the browser console,
  // the addition of this IF block is to call this out explicitly without making actual changes.
  if (!tokenExpTimestamp) {
    return false;
  }

  const now = Date.now();
  const expiration = tokenExpTimestamp;

  if (now > expiration) {
    return true;
  }

  // 1000 * 60 * 5 is the buffer for some users browser time being minute(s) off and tokenExpTimestamp is server-issued - better renew the token sooner than later
  const fiveMinutesFromNow = now + 1000 * 60 * 5;
  if (fiveMinutesFromNow > expiration) {
    return true;
  }

  return false;
};
