export enum SliceType {
  Automation = 'automation',
  Chatbot = 'chatbot',
  ClauseLibrary = 'clauseLibrary',
  Client = 'client',
  CurrentUser = 'currentUser',
  Dashboards = 'dashboards',
  DashboardV2 = 'dashboard_v2',
  DocumentEditors = 'documentEditors',
  DocumentsNavigation = 'documentsNavigation',
  DocumentGroup = 'Document Group',
  FieldAi = 'fieldAi',
  Pages = 'pages',
  SavedDashboards = 'savedDashboards',
  SearchV3 = 'search_v3',
  TurnTracking = 'turnTracking',
}
