import { connect } from 'react-redux';

import { getContractFields, getFieldErrors } from '~/reducers/workflow';
import { PageLayout } from '~/ui';

import EmptySectionItem from './EmptySectionItem';
import FieldSectionItem from './FieldSectionItem';
import { getErrorMessage } from './utils';

function ContractFieldSection({
  // connected
  fields,
  errors,
}) {
  return (
    <PageLayout.SidebarSection title="Contract">
      {fields.length === 0 ? (
        <EmptySectionItem />
      ) : (
        fields.map((field) => (
          <FieldSectionItem
            key={field.id}
            field={field}
            errorMessage={getErrorMessage(errors, field.id)}
            isAssigned
          />
        ))
      )}
    </PageLayout.SidebarSection>
  );
}

function mapStateToProps({ workflow, builder }) {
  return {
    fields: getContractFields(workflow),
    errors: getFieldErrors(workflow, builder),
  };
}

export default connect(mapStateToProps)(ContractFieldSection);
