import React from 'react';

import { OoDocsDocModeType } from '~/enums';
import { Box, FlexLayout, Icon, Text } from '~/ui';
import Menu from '~/ui/components/Menu';

import './react-menu_override.css';

const menuButtonStyles = {
  backgroundColor: 'transparent',
  border: 'none',
  width: '230px',
  justifyContent: 'flex-end',
  '&:hover': {
    backgroundColor: 'transparent',
    border: 'none',
  },
  '&:focus': {
    backgroundColor: 'transparent',
    border: 'none',
  },
};

const documentModes = {
  edit: {
    title: 'Show All Markup',
    detail: 'Shows document with changes',
    value: OoDocsDocModeType.AllMarkup,
  },
  original: {
    title: 'Show Original Document',
    detail: 'Shows original document without changes',
    value: OoDocsDocModeType.Original,
  },
  final: {
    title: 'Show Final Document',
    detail: 'Shows final document if all changes are accepted',
    value: OoDocsDocModeType.Final,
  },
};

function DocModeMenu({ disabled, docMode, id, onMenuItemClick, ...rest }) {
  const modeTitleSelected = documentModes[docMode].title;
  const getOptionLabel = (item) => {
    return (
      <Box
        sx={{
          width: '100%',
          backgroundColor: item.value === docMode ? 'gray-200' : 'transparent',
        }}
      >
        <FlexLayout p={4}>
          <Box>
            <Text color="gray-700" variant="body2">
              {item.title}
            </Text>
            <Box mt={2}>
              <Text color="gray-600" variant="tiny">
                {item.detail}
              </Text>
            </Box>
          </Box>
        </FlexLayout>
      </Box>
    );
  };

  const getOptionsByValueSelected = () => {
    return Object.entries(documentModes).map(([key, value]) => {
      return {
        content: getOptionLabel(value),
        onClick: () => onMenuItemClick(key),
      };
    });
  };

  return (
    <Menu
      {...rest}
      align="end"
      className="document-mode-menu"
      disabled={disabled}
      id={id}
      items={getOptionsByValueSelected()}
      title="Document Mode"
      menuButtonProps={{
        content: (
          <FlexLayout justifyContent="flex-end">
            <Text shouldTruncate variant="body2">
              {modeTitleSelected}
            </Text>
            <Icon ml={3} icon="chevronDown" size="m" />
          </FlexLayout>
        ),
        styles: menuButtonStyles,
      }}
    />
  );
}

export default DocModeMenu;
