import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { withRouter } from 'react-router-dom';

import DepartmentItem from '~/components/Shared/DepartmentItem';

import { evisortBlue } from '../../../assets/shared-styles/general';
import { MODAL_NAME } from '../../../types/modal.types';
import PlusIcon from '../Icons/PlusIcon';
import styles from './EcDepartmentsTable.styles';

class EcDepartmentsTable extends Component {
  goToDepartment = (department) => {
    const { history, clientId } = this.props;
    history.push(
      `/admin/console/${clientId}/department/${department.department_id}`,
    );
  };

  renderDepartmentItems() {
    const { classes, clientId, listItems, handleShowModal } = this.props;
    if (!listItems.length)
      return (
        <div>
          <div>
            <div className={classes.noDataMessage}>No departments found.</div>
          </div>
        </div>
      );
    return listItems.map((item, index) => {
      return (
        <DepartmentItem
          onClick={this.goToDepartment}
          clientId={clientId}
          item={item}
          index={index}
          key={`department-${item.department_id}`}
          handleShowModal={handleShowModal}
        />
      );
    });
  }

  render() {
    const { classes, header, handleShowModal } = this.props;

    return (
      <div
        className={classes.departmentTable}
        data-testid="department-table-container"
      >
        {header ? (
          <div className={classes.tableHeaderWrapper}>
            <div className={classes.tableHeaderText}>{header}</div>
            <div className={classes.headerButtonWrapper}>
              <button
                className={classes.headerButton}
                onClick={() => handleShowModal(MODAL_NAME, null, true)}
              >
                <PlusIcon size="16" color={evisortBlue} />
                <span>Add Subdepartment</span>
              </button>
            </div>
          </div>
        ) : null}
        {this.renderDepartmentItems()}
      </div>
    );
  }
}

EcDepartmentsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  listItems: PropTypes.array.isRequired,
  handleShowModal: PropTypes.func,
};

export default injectSheet(styles)(withRouter(EcDepartmentsTable));
