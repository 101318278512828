import React from 'react';
import { connect } from 'react-redux';

import {
  getCoercedPartyById,
  getFieldErrors,
  getPartyFieldsByPartyId,
  getSignersByPartyId,
} from '~/reducers/workflow';
import { Box, Collapsible, PageLayout, useToggle } from '~/ui';

import EmptySectionItem from '../EmptySectionItem';
import FieldSectionItem from '../FieldSectionItem';
import { getErrorMessage } from '../utils';
import Signer from './Signer';

function Party({
  // connected
  party,
  partyFields,
  signers,
  errors,
}) {
  const [isCollapsed, toggleIsCollapsed] = useToggle(false);

  const Content = !isCollapsed && (
    <>
      {partyFields.length === 0 && <EmptySectionItem indent={1} />}
      {partyFields.map((field) => (
        <FieldSectionItem
          key={field.id}
          field={field}
          indent={1}
          isAssigned
          errorMessage={getErrorMessage(errors, field.id)}
        />
      ))}
      {signers.map((signer) => (
        <Signer key={signer.id} signer={signer} />
      ))}
    </>
  );

  return (
    <Box mb={isCollapsed ? '0 !important' : undefined}>
      <PageLayout.SidebarSectionItem py={0}>
        <Collapsible
          isCollapsed={isCollapsed}
          sx={{
            borderTop: 'border',
            height: 'collapsible-header-height',
          }}
          title={party.name}
          onToggleCollapse={toggleIsCollapsed}
        />
      </PageLayout.SidebarSectionItem>
      {Content}
    </Box>
  );
}

function mapStateToProps({ workflow, builder }, { partyId }) {
  return {
    party: getCoercedPartyById(workflow, partyId),
    partyFields: getPartyFieldsByPartyId(workflow, partyId),
    signers: getSignersByPartyId(workflow, partyId),
    errors: getFieldErrors(workflow, builder),
  };
}

export default connect(mapStateToProps)(Party);
