import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { mapValueObjectsToOptions } from '~/components/Shared/EcSelect/EcSelect.utils';
import { showToast } from '~/components/Shared/EcToast';
import { Filters_DEPRECATED } from '~/components/Shared/Filters_DEPRECATED';
import { SEARCH_DEBOUNCE_MS } from '~/constants/debounces';
import {
  EnumValue,
  Field,
  FieldId,
  Filter,
  Filters as FiltersType,
} from '~/evifields';
import { FlagType, useFlag } from '~/flags';
import { api, selectors } from '~/redux';
import { SearchableFieldId } from '~/redux/api/methods';
import { selectPinnedFieldIds } from '~/redux/selectors/search';
import search from '~/redux/slices/search';
import { DropdownFieldValue } from '~/types';
import * as componentTypes from '~/types';
import { ERROR } from '~/types/toast.types';
import { debounce } from '~/utils/debounce';

type Fields = Record<FieldId, Field>;

const updateSettings = (field: componentTypes.Filters.Field): Field => {
  switch (field.type) {
    case 'enum':
    case 'enum_set':
      break;

    default:
      return field;
  }

  const shouldEnableSelectAll = (filter: Filter<EnumValue>) => {
    return (
      field.type === 'enum_set' &&
      (filter.operatorId === 'contains_any' ||
        filter.operatorId === 'not_contains_any')
    );
  };

  field.settings = {
    ...field.settings,
    __testEnableSelectAll: shouldEnableSelectAll,
  };

  return field;
};

const attachRenderer = (field: componentTypes.Filters.Field): Field => {
  // only need to attach data source for single/multi select filters
  // maybe one day we will support suggestions for text and/or other filters?
  switch (field.type) {
    case 'enum':
    case 'enum_set':
      break;

    default:
      return field;
  }

  const AsyncOptionsContainer: componentTypes.Filters.FieldRendererProps = ({
    Component,
    innerProps,
  }) => {
    const [
      getFieldValues,
      result,
    ] = api.endpoints.getFieldValues.useLazyQuery();
    const debouncedGetFieldValues = useCallback(
      debounce(getFieldValues, SEARCH_DEBOUNCE_MS),
      [getFieldValues],
    );
    const { data, error, isFetching } = result;
    const options = mapValueObjectsToOptions(data?.values || []);
    const selectAllCount = data?.selectAllCount;
    const asyncOptions = { data: options, error, isFetching, selectAllCount };
    const fetchOptions = (substringQuery = '') => {
      debouncedGetFieldValues(
        {
          fieldId: field.id,
          searchSuggestions: true,
          substringQuery,
        },
        true,
      );
    };
    const asyncProps = {
      asyncOptions,
      onMount: fetchOptions,
      onQuery: fetchOptions,
    };

    useEffect(() => {
      if (error) {
        showToast(
          ERROR,
          error?.response?.data?.detail ||
            'An error occurred while loading field suggestions.',
        );
      }
    }, [error]);

    return (
      <Component
        asyncProps={asyncProps}
        innerProps={{ ...innerProps, autoFocus: true }}
      />
    );
  };

  field.render = AsyncOptionsContainer;

  return field;
};

const attachFolderOptionsRenderer = (
  field: componentTypes.Filters.Field,
  folders: any[],
): Field => {
  const AsyncOptionsContainer: componentTypes.Filters.FieldRendererProps = ({
    Component,
    innerProps,
  }) => {
    const [getFieldValues, result] = api.endpoints.getFolders.useLazyQuery();
    const debouncedGetFieldValues = useCallback(
      debounce(getFieldValues, SEARCH_DEBOUNCE_MS),
      [getFieldValues],
    );
    const { data, error, isFetching } = result;
    const asyncOptions = { data, error, isFetching };
    const fetchOptions = (search = '') => {
      debouncedGetFieldValues(
        {
          folders,
          search,
        },
        true,
      );
    };
    const asyncProps = {
      asyncOptions,
      onMount: fetchOptions,
      onQuery: fetchOptions,
    };

    useEffect(() => {
      if (error) {
        showToast(
          ERROR,
          error?.response?.data?.detail ||
            'An error occurred while loading field suggestions.',
        );
      }
    }, [error]);

    return <Component asyncProps={asyncProps} innerProps={innerProps} />;
  };

  field.render = AsyncOptionsContainer;

  return field;
};

const FIELDS_WITH_COMPLEX_VALUES: string[] = ['document_group_id', 'folder'];

const attachSerializer = ({
  field,
  values,
}: {
  field: componentTypes.Filters.Field;
  values?: DropdownFieldValue[];
}): Field => {
  if (!FIELDS_WITH_COMPLEX_VALUES.includes(field.id)) {
    return field;
  }

  const filterSerializer = (filter: Filter) => {
    return values?.find((value) => value.value === filter.values[0])
      ?.display_value;
  };

  field.serialize = filterSerializer;

  return field;
};

const attachRenderersAndSerializers = ({
  fields,
  fieldValues,
}: {
  fields: Fields;
  fieldValues: {
    [fieldId: string]: DropdownFieldValue[];
  };
}): Fields => {
  const entries = Object.entries(fields)
    .map(([fieldId, field]): [FieldId, Field] => [fieldId, { ...field }]) // objects created by RTKQ are immutable, make a shallow copy so we can extend it later
    .map(([fieldId, field]): [FieldId, Field] => [
      fieldId,
      updateSettings(field),
    ])
    .map(([fieldId, field]): [FieldId, Field] => [
      fieldId,
      attachRenderer(field),
    ])
    .map(([fieldId, field]) => [
      fieldId,
      attachSerializer({ field, values: fieldValues[field.id] }),
    ]);

  return Object.fromEntries(entries);
};

const PreFetchFieldValues = memo(({ fieldId }: { fieldId: string }) => {
  const result = api.endpoints.getFieldValues.useQuery({
    fieldId,
    searchSuggestions: true,
    substringQuery: '',
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (result.data) {
      const values = result.data.values;
      dispatch(search.actions.setFieldValues({ fieldId, values }));
    }
  }, [result]);

  return null;
});

const DataContainer = () => {
  const filters = useSelector(selectors.selectSearchFilters);

  const filtersRequiringFieldValues = filters.filter((filter) =>
    FIELDS_WITH_COMPLEX_VALUES.includes(filter.fieldId),
  );

  return (
    <>
      {filtersRequiringFieldValues.map((filter) => {
        if (filter.fieldId === 'folder') {
          return null;
        } else {
          return (
            <PreFetchFieldValues key={filter.id} fieldId={filter.fieldId} />
          );
        }
      })}
    </>
  );
};

interface Props {
  fields: Fields;
  groups: componentTypes.Filters.Group[];
  showInlineManageFilters?: boolean;
}
const SearchFilters = memo(
  ({ fields = {}, groups = [], showInlineManageFilters }: Props) => {
    const dispatch = useDispatch();
    const setFilters = (filters: FiltersType) => {
      dispatch(search.actions.setFilters(filters));
    };
    const hasTextDelimitedFilterView = useFlag(
      FlagType.TextDelimitedMultiselectFilter,
    );

    const fieldValues = useSelector(selectors.selectSearchFieldValues);
    const clauses = useSelector(selectors.selectClauseSuggestions);
    const filters = useSelector(selectors.selectSearchFilters);
    const folderTree = useSelector(selectors.selectFolderTree);

    const fieldsWithLazyLoad = useMemo(() => {
      const folderOptions = folderTree?.map((item) => {
        return {
          label: item.display_value,
          value: item.value,
          description: item.path,
          leftIcon: 'folder',
          leftIconTooltip: item.path,
        };
      });

      const fieldItems = attachRenderersAndSerializers({ fields, fieldValues });

      fieldItems.folder = attachFolderOptionsRenderer(
        {
          ...fieldItems.folder,
          settings: {
            options: [],
            __testEnableSelectAll: () => false,
          },
        },
        folderOptions,
      );

      fieldItems.clause = {
        ...fieldItems.clause,
        settings: {
          clauses,
        },
      };

      return fieldItems;
    }, [clauses, fields, fieldValues, folderTree]);

    const hasUserPreferencesPinnedFilters = useFlag(FlagType.PinnedFilters);
    const {
      data: sections,
      isSuccess,
    } = api.endpoints.getFilterSections.useQuery(undefined);
    const [
      getPinnedFilters,
      getPinnedFiltersResult,
    ] = api.endpoints.getPinnedFilters.useLazyQuery();
    const {
      data: serverPinnedFilters,
      isFetching: isLoadingServerPinnedFilters,
    } = getPinnedFiltersResult;
    const [
      updatePinnedFilters,
    ] = api.endpoints.updatePinnedFilters.useMutation();
    const [localPinnedFilters, setLocaLPinnedFilters] = useState<
      Array<SearchableFieldId> | undefined
    >();
    const pinnedFieldIds = useSelector(selectPinnedFieldIds);
    const [updatePinnedField] = api.endpoints.updatePinnedField.useMutation();

    useEffect(() => {
      if (isSuccess && sections) {
        const pins = sections.searchableFields
          .filter((field) => field.is_pinned)
          .map((field) => String(field.id));
        dispatch(search.actions.setPinnedFieldIds(pins));
      }
    }, [isSuccess, sections]);

    useEffect(() => {
      setLocaLPinnedFilters(serverPinnedFilters?.data);
    }, [serverPinnedFilters]);

    useEffect(() => {
      if (hasUserPreferencesPinnedFilters) {
        getPinnedFilters('SEARCH');
      }
    }, []);

    const hasNewPins =
      !isLoadingServerPinnedFilters &&
      localPinnedFilters &&
      hasUserPreferencesPinnedFilters;
    const pinnedFilters = hasNewPins
      ? localPinnedFilters
      : hasUserPreferencesPinnedFilters
      ? [...(pinnedFieldIds ?? []), 'folder', 'clause']
      : pinnedFieldIds;

    const onUpdatePinnedFieldIds = useCallback(
      (pins: SearchableFieldId[], action: { value: string }) => {
        if (hasUserPreferencesPinnedFilters) {
          updatePinnedFilters({ context: 'SEARCH', pinnedFilters: pins });
          setLocaLPinnedFilters(pins);
        } else {
          updatePinnedField({ id: action.value });
          dispatch(search.actions.setPinnedFieldIds(pins));
        }
      },
      [hasUserPreferencesPinnedFilters],
    );

    const parsedGroups = useMemo(() => {
      return groups.map((group) => ({
        ...group,
        isPinnable:
          group.id === 'More filters' ? hasUserPreferencesPinnedFilters : true,
      }));
    }, [hasUserPreferencesPinnedFilters, groups]);

    return (
      <>
        <DataContainer />
        {/* eslint-disable-next-line react/jsx-pascal-case -- deprecating */}
        <Filters_DEPRECATED
          enableFilterViews={hasTextDelimitedFilterView}
          options={{
            showInlineManageFilters,
          }}
          fields={fieldsWithLazyLoad}
          filters={filters}
          groups={parsedGroups}
          onChange={setFilters}
          pinnedFieldIds={pinnedFilters}
          onUpdatePinnedFieldIds={onUpdatePinnedFieldIds}
        />
      </>
    );
  },
);

export default SearchFilters;
