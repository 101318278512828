import { createSelector } from 'reselect';

import { SliceType } from '~/enums';

import { SavedDashboardsSlice } from '../slices/savedDashboards';

const slice = (rootState: any): SavedDashboardsSlice =>
  rootState[SliceType.SavedDashboards];

export const selectActiveModal = createSelector(
  slice,
  (state) => state.activeModal,
);
