import { first } from 'lodash';
import React, { useEffect } from 'react';

import {
  Accordion,
  Box,
  ContentContainer,
  Layout,
  SearchInput,
  testHasTextMatch,
  useThrottledState,
} from '~/eds';
import { api } from '~/redux';
import { ClauseContent, DocumentViewClause, Nullable, Uuid } from '~/types';

import { Clause } from './Clause';

interface Props {
  activeClauseId?: Nullable<number>;
  id?: Uuid;
  isLoading?: boolean;
  versionHasAi: boolean;
  onClauseClick?: (clause: ClauseContent) => void;
  onClearHighlight?: () => void;
}

export const Clauses = ({
  activeClauseId,
  versionHasAi,
  id,
  isLoading,
  onClauseClick,
  onClearHighlight,
}: Props) => {
  const [throttledSearch, search, setSearch] = useThrottledState<
    Nullable<string>
  >('');
  const {
    data,
    isLoading: isLoadingClauses,
    isError,
  } = api.endpoints.getDocumentDetailClauses.useQuery(
    { documentTag: id! },
    {
      skip: !id || !versionHasAi || isLoading,
    },
  );

  useEffect(() => {
    // called when the clauses panel is destroyed
    return () => {
      onClearHighlight?.();
    };
  }, []);

  // TODO: refactor to use `eds.Panel[placeholderContent]` directly instead of explicit `ContentContainer`
  if (!versionHasAi) {
    return (
      <ContentContainer
        placeholderContent={{
          icon: 'field-clause',
          message:
            'No clauses available because user uploaded document without running AI. Please upload/save a new version to see clauses identified by Evisort AI.',
        }}
      />
    );
  }

  if (id === undefined || isLoading || isLoadingClauses) {
    return (
      <ContentContainer
        loadingContent={{
          isLoading: true,
          message: 'Clauses are being loaded in the panel',
          description: 'Please try refreshing after 2 minutes',
        }}
      />
    );
  }

  if (isError) {
    return (
      <ContentContainer
        placeholderContent={{
          icon: 'field-clause',
          message:
            'Something went wrong when retrieving the clauses. Please refresh and load again or contact your admin.',
        }}
      />
    );
  }

  if (data === undefined || data.length <= 0) {
    return (
      <ContentContainer
        placeholderContent={{
          icon: 'field-clause',
          message: 'No clauses exists for this document.',
        }}
      />
    );
  }

  const getClauseContent = (clauses: ClauseContent[], clauseName: string) => (
    <Clause
      activeClauseId={activeClauseId}
      clauseName={clauseName}
      clauses={clauses}
      onClauseClick={onClauseClick}
    />
  );

  const clauses = data.map((clause: DocumentViewClause) => ({
    content: getClauseContent(clause.content, clause.name),
    data: clause,
    title: `${clause.name} (${clause.content.length})`,
    isExpanded: clause.content.some(
      (contentClause) => contentClause.id === activeClauseId,
    ),
  }));

  const filteredClauses = search?.length
    ? clauses.filter((clause) => testHasTextMatch(clause.title, search))
    : clauses;

  return (
    <Layout direction="column">
      <Box px={6} py={2}>
        <SearchInput
          placeholder="Search for clauses"
          name="Search Clauses"
          value={search}
          onChange={setSearch}
        />
      </Box>
      <Accordion
        items={filteredClauses}
        search={throttledSearch ?? ''}
        onToggle={(isExpanding, item) => {
          const { content: clauses = [], name } = item.data ?? {};
          if (isExpanding) {
            const firstClause = first(clauses);
            if (firstClause) {
              onClauseClick?.({ ...firstClause, name });
            }
          } else {
            // when clicking another clause in the clause panel
            if (clauses.map((clause) => clause.id).includes(activeClauseId!)) {
              onClearHighlight?.();
            }
          }
        }}
      />
    </Layout>
  );
};
