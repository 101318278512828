import { union } from 'lodash';
import React from 'react';

import { CheckboxGroup, Layout } from '~/eds';
import { Nullable } from '~/types';

type Option<V> = {
  value: V;
  label: string;
  description: string;
};
type OptionGroup<V> = {
  title: string;
  options: Option<V>[];
};
type Value<V> = Nullable<Record<string, V[]>>;
type GroupValues<V> = Nullable<V[]>;

interface Props<V> {
  optionGroups: OptionGroup<V>[];
  pins?: Array<V>;
  value: Value<V>;
  onChange: (updatedValue: Value<V>) => void;
  onUpdatePinnedFilters?: (updatedPinnedFilters: V[]) => void;
  search?: string;
}

const GroupedCheckboxSelect = <T extends unknown>({
  optionGroups,
  value,
  pins,
  onChange,
  onUpdatePinnedFilters,
  search = '',
}: Props<T>) => {
  const plainOptions = optionGroups.flatMap((group) => group.options);
  const pinnedOptions = plainOptions.filter((plainOption) =>
    pins?.includes(plainOption.value),
  );

  const handleChange = (groupName: string) => (
    updatedGroupValues: GroupValues<T>,
  ) => {
    const updatedValue = {
      ...(value || {}),
      [groupName]: updatedGroupValues || [],
    };
    onChange(updatedValue);
  };

  const handleChangePinValue = (updatedPinValues: Nullable<T[]>) => {
    const updatedValue = { ...value };
    pinnedOptions.forEach((pinnedOption) => {
      const { description, value: pinnedValue } = pinnedOption;
      if (updatedValue && description) {
        const groupValues = updatedValue[description] || [];
        updatedValue[description] = updatedPinValues?.includes(pinnedValue)
          ? union([...groupValues, pinnedValue])
          : groupValues.filter((groupValue) => groupValue !== pinnedValue);
      }
    });
    onChange(updatedValue);
  };

  const pinnedGroup = pins && (
    <CheckboxGroup
      columns={3}
      label="Pinned"
      name="pinned"
      options={pinnedOptions}
      pins={pins}
      value={value ? Object.values(value).flat() : []}
      search={search}
      onChange={handleChangePinValue}
      onUpdatePins={onUpdatePinnedFilters}
    />
  );

  return (
    <Layout direction="column" spacing={6}>
      {pinnedGroup}
      {optionGroups.map(({ title, options }) => (
        <CheckboxGroup
          search={search}
          key={title}
          columns={3}
          label={title}
          name={title}
          options={options}
          pins={pins}
          value={value ? value[title] : []}
          onChange={handleChange(title)}
          onUpdatePins={onUpdatePinnedFilters}
        />
      ))}
    </Layout>
  );
};

export default GroupedCheckboxSelect;
