import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackSegment } from '~/components/SegmentAnalytics';
import { showToast } from '~/components/Shared/EcToast';
import { Modal, Text } from '~/eds';
import { SavedDashboardsActions } from '~/enums';
import { useCurrentUser } from '~/hooks';
import { api, selectors } from '~/redux';
import savedDashboards from '~/redux/slices/savedDashboards';
import { ERROR, SUCCESS } from '~/types/toast.types';

type Props = {
  isLast: boolean;
};

export const DeleteDashboard = ({ isLast }: Props) => {
  const activeModal = useSelector(selectors.selectSavedDashboardsActiveModal);
  const dispatch = useDispatch();
  const user = useCurrentUser();

  const [
    deleteDashboard,
    deleteDashboardResult,
  ] = api.endpoints.deleteDashboard.useMutation();
  const { isError, isLoading, isSuccess, error } = deleteDashboardResult;

  useEffect(() => {
    if (isSuccess) {
      showToast(SUCCESS, 'Dashboard deleted successfully.');
      dispatch(savedDashboards.actions.closeModal());
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      const resp = error?.response || {};
      const msg = resp.data?.errors?.[0];
      showToast(
        ERROR,
        msg?.title || 'An error occurred while deleting the dashboard.',
      );
      dispatch(savedDashboards.actions.closeModal());
    }
  }, [isError]);

  return (
    <Modal
      isVisible={activeModal?.name === SavedDashboardsActions.Delete}
      title="Delete Dashboard"
      onCancel={() => {
        dispatch(savedDashboards.actions.closeModal());
      }}
      onHide={() => {
        dispatch(savedDashboards.actions.closeModal());
      }}
      primaryAction={{
        text: 'Delete Dashboard',
        onClick: () => {
          trackSegment('submitDeleteDashboard', {
            id: activeModal!.selectedDashboard.id,
            name: activeModal!.selectedDashboard.name,
            user_id: user.id,
            client_id: user.client,
          });
          deleteDashboard({ uuid: activeModal!.selectedDashboard.id });
        },
        isLoading,
        variant: 'danger',
      }}
    >
      {isLast ? (
        <Text>
          Deleting your last Dashboard is final and cannot be undone. Dashboard
          section will reset back to the two default ones.
        </Text>
      ) : (
        <Text>
          Deleting{' '}
          <Text variant="body-bold">
            {activeModal?.selectedDashboard?.name}
          </Text>{' '}
          is final and cannot be undone.
        </Text>
      )}
    </Modal>
  );
};
