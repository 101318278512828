import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { EM_DASH } from '~/constants/unicode';
import { Text } from '~/ui';

import {
  MODAL_ADMIN_USER,
  MODAL_DELETE,
  MODAL_SEND_EMAIL,
} from '../../../types/modal.types';
import ActionsMenu from '../../Shared/ActionsMenu';
import styles from './EcUserItem.styles';

class EcUserItem extends Component {
  renderExtraOptions(item) {
    const { classes, handleShowModal } = this.props;
    const getMenuItems = () => {
      const items = [];

      items.push({
        content: 'Edit User',
        onClick: () => handleShowModal(MODAL_ADMIN_USER, true, item),
      });

      items.push({
        content: 'Send Link to Set New Password',
        onClick: () => handleShowModal(MODAL_SEND_EMAIL, false, item),
      });

      items.push({
        content: 'Delete User',
        onClick: () => handleShowModal(MODAL_DELETE, false, item),
      });

      return items;
    };

    return (
      <div className={classes.actionsWrapper}>
        <ActionsMenu
          id={`party_item_actions_menu?id=${item.name}`}
          items={getMenuItems()}
          align="end"
          title="party item actions menu"
        />
      </div>
    );
  }

  render() {
    const { item } = this.props;

    return (
      <tr>
        <td>{item.email}</td>
        <td>{`${item.firstName} ${item.lastName}`}</td>
        <td>
          {item.lastLogin
            ? moment(item.lastLogin).format('YYYY-MM-DD hh:mm A')
            : EM_DASH}
        </td>
        <td>
          <Text variant="m-dense" shouldTruncate={true}>
            {item.positionName}
          </Text>
        </td>
        <td>
          {item.departmentsSelected
            .map((dept) => dept.department_name)
            .join(' • ')}
        </td>
        <td>{item.jobTitle}</td>
        <td>{this.renderExtraOptions(item)}</td>
      </tr>
    );
  }
}

EcUserItem.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object,
  handleShowModal: PropTypes.func,
};

export default injectSheet(styles)(EcUserItem);
