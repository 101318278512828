import { CLIENTLESS_ROUTES } from './constants';

/** Serializes/stringifies a JS object to base64-encoded string */
export const serializeToBase64 = <X>(object: X): string =>
  btoa(JSON.stringify(object));

/** Parses a base64-encoded string to a JS object */
export const parseFromBase64 = <X>(string: string): X =>
  JSON.parse(atob(string));

export const getClientFromUrl = (): string => {
  const url = window.location.pathname;
  const route = url.split('/')[1];

  return (CLIENTLESS_ROUTES as string[]).includes(route) ? '' : route;
};

export const setSearchParams = (
  path: string,
  searchParams: Record<string, string> = {},
): string => {
  const url = new URL(path, window.location.origin);
  for (const [key, value] of Object.entries(searchParams)) {
    url.searchParams.set(key, value);
  }
  return url.toString();
};
