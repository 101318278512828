import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getUserEditorConfigToken } from '~/api';
import FallbackContent from '~/components/Shared/FallbackContent';
import { OODOCS_VIEWER_TICKET_REVIEWER_PAGE_TESTID } from '~/constants/testids';
import { OoDocsEventType, ScriptLoadingStatusType } from '~/enums';
import { withUsers } from '~/hocs';
import { useScript } from '~/hooks';
import Configuration from '~/services/configuration';
import { Box, Button, Icon, Link } from '~/ui';
import { captureException } from '~/utils';

import { generateOoDocsConfig } from './utils';

function OoDocsViewer({
  // connected
  currentUser,
  users,
  documentId,
  documentName,
  editMode,
  statusBar,
  toolbar,
  versionId,
  viewWorkingDraft,
  workflowId,
  onCreateBookmarksFinish,
  onDocumentReadyCallback,
  onPluginError,
  onGetBookmarksCallback,
  onUserMentionCallback,
  onUsersNotifyCallback,
  onCloseCommentPopover,
  onRedlinesLoadedCallback,
  setOoDocsFrameEditor,
  setFileDownloadUrl,
}) {
  let frameEditor = null;
  let docEditor = useRef();

  const history = useHistory();
  const OoDocsViewerScriptSrc = `${Configuration.oodocsEndpoint}/web-apps/apps/api/documents/api.js`;
  const OoDocsViewerScriptLoadStatus = useScript(OoDocsViewerScriptSrc);
  const configObj = generateOoDocsConfig(currentUser, {
    documentName,
    editMode,
    statusBar,
    toolbar,
  });
  const [errorLoadingOoDocsScript, setErrorLoadingOoDocsScript] = useState(
    false,
  );
  const [errorLoadingEditorConfig, setErrorLoadingEditorConfig] = useState(
    false,
  );

  function onDownloadAs(event) {
    if (frameEditor.contentWindow) {
      setFileDownloadUrl(event.data.url);
    }
  }

  function onError(event) {
    if (frameEditor.contentWindow) {
      captureException(
        'An error occured in Only Office editor.',
        { error: event.data },
        { section: 'document editor' },
      );
    }
  }

  function onInfo(event) {
    if (frameEditor.contentWindow) {
      switch (event.data.event) {
        case OoDocsEventType.OnDocumentContentReady:
          onDocumentReadyCallback(frameEditor);
          break;
        case OoDocsEventType.OnMentionUser:
          onUserMentionCallback(docEditor, event.data.userEmail);
          break;
        case OoDocsEventType.OnAddComment:
          onUsersNotifyCallback(event.data.comment);
          break;
        case OoDocsEventType.OnCloseComment:
          onCloseCommentPopover();
          break;
        case OoDocsEventType.OnRedlinesLoaded:
          onRedlinesLoadedCallback();
          break;
        case OoDocsEventType.OnGetBookmarks:
          onGetBookmarksCallback?.(event.data);
          break;
        case OoDocsEventType.OnError:
          if (frameEditor.contentWindow) {
            captureException(
              'An error occured in Only Office editor.',
              { error: event.data },
              { section: 'document editor' },
            );
          }
          onPluginError(event.data);
          break;
        case OoDocsEventType.OnCreateBookmarksFinish:
          onCreateBookmarksFinish?.(event.data);
          break;
        default:
          // do nothing.
          break;
      }
    }
  }

  function onRequestUsers() {
    if (frameEditor.contentWindow) {
      const parsedUsers = Object.values(users).map((user) => ({
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
      }));

      docEditor.setUsers({
        users: parsedUsers,
      });
    }
  }

  configObj.events = {
    onDownloadAs,
    onError,
    onInfo,
    onRequestUsers,
  };

  useEffect(() => {
    let docEditorRefCurrent = docEditor.current;

    if (OoDocsViewerScriptLoadStatus === ScriptLoadingStatusType.Error) {
      captureException(
        'Error loading Only Office editor script',
        { error: 'Failed to load script' },
        { section: 'document editor' },
      );
      setErrorLoadingOoDocsScript(true);
    } else if (!frameEditor) {
      initializeFrameEditor();
    }
    return () => {
      if (docEditorRefCurrent) {
        docEditorRefCurrent.destroyEditor();
        docEditorRefCurrent = null;
      }

      if (frameEditor) {
        frameEditor = null;
      }
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [OoDocsViewerScriptLoadStatus]);

  function initializeFrameEditor() {
    if (window.DocsAPI) {
      if (!frameEditor) {
        const editorData = { isWorkingDraft: editMode || viewWorkingDraft };
        if (workflowId) {
          editorData.workflowId = workflowId;
        } else {
          editorData.documentId = documentId;
          editorData.versionId = versionId;
        }
        getUserEditorConfigToken(editorData)
          .then((editorToken) => {
            configObj.token = editorToken.editor_token;

            docEditor = new window.DocsAPI.DocEditor('frameEditor', configObj);
            frameEditor = document.getElementsByName('frameEditor')[0];
            if (setOoDocsFrameEditor) {
              setOoDocsFrameEditor(frameEditor);
            }
          })
          .catch((error) => {
            captureException('Error loading Only Office editor', error, {
              section: 'document editor',
            });
            setErrorLoadingEditorConfig(true);
          });
      }
    } else {
      setTimeout(() => {
        initializeFrameEditor();
      }, 1000);
    }
  }

  if (errorLoadingOoDocsScript || errorLoadingEditorConfig) {
    const content = (
      <>
        {errorLoadingEditorConfig
          ? 'Please reload the page'
          : 'Please try again later'}{' '}
        or{' '}
        <Link href="mailto:support@evisort.com" variant="s-dense">
          contact Support
        </Link>{' '}
        for further assistance.
      </>
    );
    return (
      <FallbackContent
        icon={<Icon color="red-600" icon="reject" size="xl" />}
        title="The Workflow Document Viewer is currently unavailable."
        content={content}
        button={
          <Button
            text="Go back to Tickets"
            color="white"
            variant="secondary"
            onClick={() => history.push('/workflow/tickets/')}
          />
        }
      />
    );
  }
  return (
    <Box
      data-testid={OODOCS_VIEWER_TICKET_REVIEWER_PAGE_TESTID}
      id="frameEditor"
      sx={{ flexGrow: 1 }}
    />
  );
}

export default withUsers(OoDocsViewer);
