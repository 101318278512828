import { saveAs } from 'file-saver';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';

import { withCurrentUser } from '~/hocs';

import { black2 } from '../../../assets/shared-styles/general';
import {
  MODAL_DELETE,
  MODAL_NAME,
  MODAL_VERSION_DOCUMENT_VIEWER,
} from '../../../types/modal.types';
import { ERROR, SUCCESS } from '../../../types/toast.types';
import {
  getDocumentVersionDOCX,
  getDocumentVersionOriginal,
} from '../../DocumentsViewPage/DocumentVersions/DocumentVersions.data';
import ActionsMenu from '../../Shared/ActionsMenu';
import { showToast } from '../../Shared/EcToast';
import EcTooltip from '../../Shared/EcTooltip';
import ChevronDownIcon from '../Icons/ChevronDownIcon';
import ChevronRightIcon from '../Icons/ChevronRightIcon';
import InfoIcon from '../Icons/InfoIcon';
import styles from './EcVersionItem.styles';

class EcVersionItem extends Component {
  constructor(props) {
    super(props);

    this.handleExpandRow = this.handleExpandRow.bind(this);
    this.handleFileAttachment = this.handleFileAttachment.bind(this);

    this.state = { expanded: false, fileAttached: null };
  }

  handleExpandRow() {
    this.setState({ expanded: !this.state.expanded });
  }

  handleDownloadOriginal(item) {
    const { id, file_name, file_type } = item;
    getDocumentVersionOriginal(id)
      .then((res) => {
        saveAs(res, `${file_name}${file_type}`);
        showToast(SUCCESS, `"${file_name}" is downloaded successfully.`);
      })
      .catch(() =>
        showToast(
          ERROR,
          `Something went wrong with downloading "${file_name}".`,
        ),
      );
  }

  handleDownloadOCR(item) {
    const { id, file_name } = item;
    getDocumentVersionDOCX(id)
      .then((res) => {
        saveAs(res, `${file_name}.${res.type.split('/').pop()}`);
        showToast(SUCCESS, `"${file_name}" is downloaded successfully.`);
      })
      .catch(() =>
        showToast(
          ERROR,
          `Something went wrong with downloading "${file_name}".`,
        ),
      );
  }

  handleFileAttachment() {
    const { item, handleVersionReplace } = this.props;

    handleVersionReplace(item, this.refs.fileContainer.files[0]);
  }

  getMenuItems = (item) => {
    const items = [];

    const {
      userVisibilityLevel,
      handleShowModal,
      handleVersionRestore,
      isLastVersionLeft,
    } = this.props;
    const canEdit = userVisibilityLevel === 'OPEN';
    const canDownload = canEdit || userVisibilityLevel === 'OPEN_NO_EDIT';

    if (canEdit) {
      items.push(
        {
          content: 'Edit',
          onClick: () => handleShowModal(MODAL_NAME, item),
        },
        {
          content: 'Replace',
          onClick: () => this.refs.fileContainer.click(),
        },
      );
      if (!item.is_latest) {
        items.push({
          content: 'Restore',
          onClick: () => handleVersionRestore(item),
        });
      }
    }

    if (canDownload) {
      const text = 'Download';
      items.push({
        content: text,
        onClick: () => this.handleDownloadOriginal(item),
      });
    }

    if (canEdit && !isLastVersionLeft) {
      items.push({
        content: 'Delete',
        onClick: () => handleShowModal(MODAL_DELETE, item),
      });
    }

    return items;
  };

  renderExtraOptions(item) {
    const { classes, userVisibilityLevel } = this.props;
    const noEditDownload = userVisibilityLevel === 'OPEN_NO_EDIT_DOWNLOAD';

    if (noEditDownload) return null;
    return (
      <div className={classes.actionsWrapper}>
        <ActionsMenu
          id={`document_versions_row_actions_menu?id=${item.id}`}
          items={this.getMenuItems(item)}
          align="end"
          title="document versions row actions menu"
        />
      </div>
    );
  }

  renderFilenameTooltip(item) {
    const { classes } = this.props;

    return (
      <div>
        {item.file_name}
        <span
          className={classes.ruleTooltip}
          data-tip
          data-for={`ruleText-${item.id}`}
        >
          <Fragment>
            <EcTooltip id={`ruleText-${item.id}`} width="228px" place="top">
              {item.file_name}
            </EcTooltip>
          </Fragment>
        </span>
      </div>
    );
  }

  renderDetail(item) {
    const { classes } = this.props;

    return (
      <div className={classes.detailRowContainer}>
        <div className={classes.detailInfoHeader}>
          <InfoIcon color={black2} size="18px" /> Details
        </div>
        <div className={classes.detailRow}>
          <div className={classes.detailItem}>
            <div className={classes.detailItemTitle}>CREATED AT</div>
            <div className={classes.detailItemValue}>
              {moment(item.created_date).format('MM-DD-YYYY hh:mm A')}
            </div>
          </div>
          <div className={classes.detailItem}>
            <div className={classes.detailItemTitle}>CREATED BY</div>
            <div className={classes.detailItemValue}>{item.created_by}</div>
          </div>
          <div className={classes.detailItem}>
            <div className={classes.detailItemTitle}>LAST MODIFIED AT</div>
            <div className={classes.detailItemValue}>
              {moment(item.date_modified).format('MM-DD-YYYY hh:mm A')}
            </div>
          </div>
          <div className={classes.detailItem}>
            <div className={classes.detailItemTitle}>LAST MODIFIED BY</div>
            <div className={classes.detailItemValue}>
              {item.last_modified_by}
            </div>
          </div>
          <div className={classes.detailItem}>
            <div className={classes.detailItemTitle}>DESCRIPTION</div>
            <div className={classes.detailItemValue}>{item.description}</div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { classes, item, dragHandle, handleShowModal } = this.props;
    const { expanded } = this.state;

    return (
      <div className={classes.versionItemRowContainer}>
        <div className={classes.fieldRow}>
          <span className={classes.fieldNameContainer}>
            <span className={classes.dragHandleContainer}>{dragHandle}</span>
            <button
              aria-label={`${expanded ? 'collapse' : 'expand'} version details`}
              aria-expanded={expanded}
              className={classes.expandIcon}
              onClick={this.handleExpandRow}
              data-testid="version-expand-detail-icon"
            >
              {expanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
            </button>

            {item.label}
          </span>
          <span className={classes.fieldVersionContainer}>
            {item.version_tag}
          </span>
          <span className={classes.fieldFilenameContainer}>
            {this.renderFilenameTooltip(item)}
          </span>
          <span className={classes.fieldLastModContainer}>
            {moment(item.date_modified).format('MM-DD-YYYY hh:mm A')} by{' '}
            {item.last_modified_by}
          </span>
          <span className={classes.fieldDescripContainer}>
            {item.description}
          </span>
          <span className={classes.attachFileContainer}>
            <input
              aria-hidden={true}
              tabIndex="-1"
              type="file"
              ref="fileContainer"
              id="file"
              data-testid="version-file-replace"
              name="file"
              accept="*"
              onChange={this.handleFileAttachment}
            />
          </span>
          <button
            className={classes.fieldViewContainer}
            onClick={() => handleShowModal(MODAL_VERSION_DOCUMENT_VIEWER, item)}
          >
            View
          </button>
          <span>{this.renderExtraOptions(item)}</span>
        </div>
        {expanded ? this.renderDetail(item) : null}
      </div>
    );
  }
}

EcVersionItem.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  handleShowModal: PropTypes.func.isRequired,
};

export default injectSheet(styles)(withCurrentUser(EcVersionItem));
