import get from 'lodash/get';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import SelectDocumentInGroup from '~/components/SearchV2/AddSearchResultsToGroupV2/SelectDocumentInGroup';
import SelectGroup from '~/components/SearchV2/AddSearchResultsToGroupV2/SelectGroup';
import { useTableSelection, useToast } from '~/eds';
import { AddToGroupStepType, HttpStatusCodeType } from '~/enums';
import { api } from '~/redux';
import { PilotSearchQuery } from '~/redux/api/methods';
import { LoadingContainer, WizardPageLayout } from '~/ui';

const MAX_TITLE_WIDTH = 355;

type Props = {
  query: PilotSearchQuery;
  tableSelection: ReturnType<typeof useTableSelection>;
  onHide: () => void;
  onCompleted: () => void;
};

export const AddSearchResultsToGroup = ({
  query: pilotSearchQuery,
  tableSelection,
  onHide,
  onCompleted,
}: Props) => {
  const { selectedIds, isAllSelected } = tableSelection;
  const [step, setStep] = useState<AddToGroupStepType>(
    AddToGroupStepType.GROUP,
  );
  const [selectedGroupId, setSelectedGroupId] = useState<number | undefined>(
    undefined,
  );
  const [selectedDocumentId, setSelectedDocumentId] = useState<
    number | undefined
  >(undefined);

  const [
    addDocumentsToGroup,
    addDocumentsToGroupResult,
  ] = api.endpoints.addDocumentsToGroup.useMutation();
  const {
    isError: addDocumentsToGroupError,
    isSuccess: addDocumentsToGroupSuccess,
    isLoading: addDocumentsToGroupLoading,
    error: addDocumentsToGroupResponseError,
  } = addDocumentsToGroupResult;

  const [
    addDocumentsToGroupLinkParent,
    addDocumentsToGroupLinkParentResult,
  ] = api.endpoints.addDocumentsToGroupLinkParent.useMutation();
  const {
    isError: addDocumentsToGroupLinkParentError,
    isSuccess: addDocumentsToGroupLinkParentSuccess,
    isLoading: addDocumentsToGroupLinkParentLoading,
    error: addDocumentsToGroupLinkParentResponseError,
  } = addDocumentsToGroupLinkParentResult;

  const [
    createDocumentGroup,
    createDocumentGroupResult,
  ] = api.endpoints.createDocumentGroup.useMutation();
  const {
    isError: createDocumentGroupError,
    isSuccess: createDocumentGroupSuccess,
    isLoading: createDocumentGroupLoading,
    error: createDocumentGroupResponseError,
  } = createDocumentGroupResult;

  const { toast } = useToast();
  const documentIds = isAllSelected
    ? undefined
    : selectedIds.map((id) => Number(id));
  const query = isAllSelected ? pilotSearchQuery : undefined;

  useEffect(() => {
    if (
      addDocumentsToGroupSuccess ||
      addDocumentsToGroupLinkParentSuccess ||
      createDocumentGroupSuccess
    ) {
      toast({
        status: 'success',
        message: 'Documents have been added to the group successfully',
      });
      onCompleted();
    }
  }, [
    addDocumentsToGroupSuccess,
    addDocumentsToGroupLinkParentSuccess,
    createDocumentGroupSuccess,
  ]);

  useEffect(() => {
    if (
      addDocumentsToGroupError ||
      addDocumentsToGroupLinkParentError ||
      createDocumentGroupError
    ) {
      const error =
        addDocumentsToGroupResponseError ||
        addDocumentsToGroupLinkParentResponseError ||
        createDocumentGroupResponseError;
      const { response } = error;
      const defaultErrorMessage =
        'An error occurred while adding documents to the group.';
      if (response?.status === HttpStatusCodeType.BadRequest) {
        toast({
          status: 'danger',
          message: get(response, 'data.message', defaultErrorMessage),
        });
      } else {
        toast({
          status: 'danger',
          message: defaultErrorMessage,
        });
      }
      onHide();
    }
  }, [
    addDocumentsToGroupError,
    addDocumentsToGroupLinkParentError,
    createDocumentGroupError,
  ]);

  const handleSelectGroup = useCallback((groupId) => {
    setStep(AddToGroupStepType.STRUCTURE);
    setSelectedGroupId(groupId);
  }, []);

  const handleNewGroupCreated = ({
    name,
    note,
  }: {
    name: string;
    note: string;
  }) => {
    createDocumentGroup({
      name,
      note,
      documents: documentIds,
      query_v3: query,
    });
  };

  const handleSelectedDocument = useCallback((documentId) => {
    setSelectedDocumentId(documentId);
  }, []);

  const handleAddDocumentsToGroup = () => {
    if (selectedDocumentId && selectedGroupId) {
      addDocumentsToGroupLinkParent({
        groupId: selectedGroupId,
        parentId: selectedDocumentId,
        documentIds,
        query_v3: query,
      });
    } else {
      if (selectedGroupId) {
        addDocumentsToGroup({
          groupId: selectedGroupId,
          documentIds,
          query_v3: query,
        });
      }
    }
    setSelectedDocumentId(undefined);
    setSelectedGroupId(undefined);
  };

  const steps: Record<any, any> = useMemo(() => {
    return {
      [AddToGroupStepType.GROUP]: {
        title: 'Adding document to group',
        component: (
          <SelectGroup
            onSelectGroup={handleSelectGroup}
            onNewGroupCreated={handleNewGroupCreated}
          />
        ),
        action: {
          promise: onHide,
          text: 'Cancel',
          variant: 'ghost',
        },
      },
      [AddToGroupStepType.STRUCTURE]: {
        title: 'Add to group',
        component: (
          <SelectDocumentInGroup
            group={selectedGroupId}
            documentGroupIds={selectedIds}
            onDocumentSelected={handleSelectedDocument}
          />
        ),
        action: {
          text: 'Add to group',
        },
      },
    };
  }, [selectedGroupId]);

  const currentStep = steps[step];

  const isLoading =
    addDocumentsToGroupLoading ||
    addDocumentsToGroupLinkParentLoading ||
    createDocumentGroupLoading;

  const buttonTitle = selectedDocumentId
    ? 'Add to group as linked documents'
    : currentStep.action.text;
  const handleActionPromise =
    step === AddToGroupStepType.STRUCTURE
      ? handleAddDocumentsToGroup
      : currentStep.action.promise;
  return (
    <WizardPageLayout
      currentStep={step}
      steps={[AddToGroupStepType.GROUP, AddToGroupStepType.STRUCTURE]}
      title={{ text: currentStep.title, maxWidth: MAX_TITLE_WIDTH }}
      onClose={onHide}
      actionButton={{
        ...currentStep.action,
        text: buttonTitle,
        promise: handleActionPromise,
      }}
      isContentFill={true}
      rightContent={undefined}
    >
      <LoadingContainer isLoading={isLoading}>
        {currentStep.component}
      </LoadingContainer>
    </WizardPageLayout>
  );
};
