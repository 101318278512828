import logos from '~/components/Shared/ProviderLogo/logos';
import { OoDocsPluginType } from '~/enums';

const defaultOptions = { statusBar: true, toolbar: false };

export const generateOoDocsConfig = (currentUser, options = defaultOptions) => {
  const toolbar = options.toolbar
    ? { file: true, home: { mailmerge: false }, view: false }
    : false;
  return {
    width: '100%',
    height: '100%',
    type: 'desktop',
    documentType: 'word',
    token: '',
    document: {
      title: options.documentName,
      permissions: {
        deleteCommentAuthorOnly: false,
        editCommentAuthorOnly: true,
        chat: false,
      },
      url: 'jwt',
      fileType: 'docx',
    },
    editorConfig: {
      actionLink: null,
      lang: 'en',
      user: {
        id: currentUser.id?.toString(),
        name: currentUser.first_name + ' ' + currentUser.last_name,
      },
      customization: {
        customer: {
          logo: `${window.location.origin}${logos.evisort}`,
        },
        logo: {
          image: `${window.location.origin}/empty-2x2.png`,
          imageEmbedded: `${window.location.origin}${logos.evisort}`,
          url: '',
        },
        review: {
          hideReviewDisplay: true,
          hoverMode: false,
          reviewDisplay: 'markup',
        },
        layout: {
          toolbar: toolbar,
          leftMenu: false,
          rightMenu: false,
          statusBar: options.statusBar || false,
        },
        loaderLogo: `${window.location.origin}${logos.evisort}`,
        loaderName: 'EVISORT',
        about: false,
        comments: options.editMode,
        feedback: false,
        forcesave: true,
        help: false,
        hideRulers: true,
        hideRightMenu: true,
        compactHeader: true,
        compactToolbar: false,
        plugins: true,
        toolbarHideFileName: true,
        toolbarNoTabs: true,
        uiTheme: 'theme-classic-light',
        zoom: 100,
      },
      fileChoiceUrl: '',
      coEditing: {
        mode: 'fast',
        change: false,
      },
      plugins: {
        UIpluginsData: [
          '../../../../sdkjs-plugins/interface_hide_fmi/config.json',
        ],
      },
    },
  };
};

export const frameEditorData = {
  frameEditorId: 'iframeEditor',
  type: 'onExternalPluginMessage',
};

export const pluginsGuid = {
  [OoDocsPluginType.EvisortBookmarks]:
    'asc.{B89F35E7-19EA-4A59-831C-F1A8C15CACB5}',
  [OoDocsPluginType.EvisortComment]:
    'asc.{A450C5E2-5E6B-11EC-BF63-0242AC130002}',
  [OoDocsPluginType.ExternalListener]:
    'asc.{A8705DEE-7544-4C33-B3D5-168406D92F72}',
};

export const getOoDocsDataMessage = (
  data,
  pluginName = OoDocsPluginType.ExternalListener,
) => {
  return {
    ...frameEditorData,
    guid: pluginsGuid[pluginName],
    data,
  };
};

export const postOoDocsFrameEditorMessage = (
  ooDocsFrameEditor,
  dataMessage,
  endpoint,
) => {
  return ooDocsFrameEditor.contentWindow?.postMessage(
    JSON.stringify(dataMessage),
    endpoint,
  );
};
