import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import uuid from 'uuid';

import { SORT_TIMEOUT_MS } from '../../constants';
import { MenuActions, SharedGridProps } from '../../types';
import { typedMemo } from '../../utils';
import { Grid } from '../Grid';
import { GridItem } from '../Grid/GridItem';
import { Item } from './Item';
import SortableItem from './SortableItem';

export type ItemType<T> = {
  item: T;
  /** represents how many columns the item will ocupy on the grid */
  width?: number;
  /** represents how many rows the item will ocupy on the grid */
  height?: number;
};
interface Props<T = unknown> extends SharedGridProps {
  items: ItemType<T>[];
  itemSettings?: {
    moreActions: (item: T) => MenuActions<string> | undefined;
  };
  onSortEnd: (items: T[], activeItem?: T) => void;
  renderTitle: (item: T) => string;
  onRemoveItem?: (removedItem: T, removedItemIndex: number) => void;
  renderContents?: (item: T) => ReactNode;
}

export const SortableGrid = typedMemo(
  <T extends unknown>({
    items,
    itemSettings,
    onRemoveItem,
    onSortEnd,
    columns = 3,
    rows,
    columnSpacing,
    renderContents,
    renderTitle,
    rowSpacing,
  }: Props<T>) => {
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
    const [activeId, setActiveId] = useState<string | null>(null);

    /* START DATA SOURCE */
    const [localItems, setLocalItems] = useState<
      Array<{ id: string; original: T; width?: number; height?: number }>
    >([]);

    useEffect(() => {
      setTimeout(() => {
        setLocalItems(
          items.map((gridItem) => ({
            id: uuid.v4(),
            original: gridItem.item,
            width: gridItem.width,
            height: gridItem.height,
          })),
        );
      }, SORT_TIMEOUT_MS);
    }, [items]);
    /* END DATA SOURCE */

    /* MOTION EVENTS */
    const handleDragStart = useCallback((event: DragStartEvent) => {
      setActiveId(String(event.active.id));
    }, []);

    const handleDragEnd = useCallback(
      (event: DragEndEvent) => {
        const { active, over } = event;

        if (active.id !== over?.id) {
          setLocalItems((localItems) => {
            const oldIndex = localItems.findIndex(
              (item) => item.id === active.id,
            );
            const newIndex = localItems.findIndex(
              (item) => item.id === over!.id,
            );

            const updatedLocalItems = arrayMove(localItems, oldIndex, newIndex);
            onSortEnd(
              updatedLocalItems.map((item) => item?.original),
              localItems[oldIndex]?.original,
            );
            return updatedLocalItems;
          });
        }

        setActiveId(null);
      },
      [localItems],
    );

    const handleDragCancel = useCallback(() => {
      setActiveId(null);
    }, []);
    /* END MOTION EVENTS */

    const currentItem = useMemo(
      () => localItems.find((item) => item.id === activeId),
      [activeId, localItems],
    );

    return (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <SortableContext items={localItems} strategy={rectSortingStrategy}>
          <Grid
            columns={columns}
            rows={rows}
            columnSpacing={columnSpacing}
            rowSpacing={rowSpacing}
          >
            {localItems.map((item, itemIndex) => (
              <GridItem key={item.id} width={item.width} height={item.height}>
                <SortableItem
                  id={item.id}
                  title={renderTitle(item.original)}
                  onRemove={
                    onRemoveItem
                      ? () => onRemoveItem?.(item.original, itemIndex)
                      : undefined
                  }
                  actions={itemSettings?.moreActions(item.original)}
                >
                  {renderContents?.(item.original)}
                </SortableItem>
              </GridItem>
            ))}
          </Grid>
        </SortableContext>
        <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
          {activeId && currentItem ? (
            <Item isDragging title={renderTitle(currentItem.original)}>
              {renderContents?.(currentItem.original)}
            </Item>
          ) : null}
        </DragOverlay>
      </DndContext>
    );
  },
);
