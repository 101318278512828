import { isArray } from 'lodash';

import { Field } from './types';

export const requiredValidator = (requiredFields: string[]) => (
  field: Field,
  value: unknown,
) => {
  const isValueValid = isArray(value) ? value.length > 0 : !!value;
  if (requiredFields.includes(field.name)) {
    switch (typeof value) {
      case 'string':
        return value.trim().length > 0 ? null : 'This field is required';
      case 'number':
        return value === 0 || value ? null : 'This field is required';
      default:
        return isValueValid ? null : 'This field is required';
    }
  }
  return null;
};
