import { Component } from 'react';
import injectSheet from 'react-jss';
import { Link, withRouter } from 'react-router-dom';

import { EM_DASH } from '~/constants/unicode';

import ActionsMenu from '../../Shared/ActionsMenu';
import styles from './EcClientItem.styles';

class EcClientItem extends Component {
  renderExtraOptions(item) {
    const { classes, handleShowModal, history } = this.props;

    function getMenuItems() {
      const items = [];

      items.push({
        content: 'Edit Client',
        onClick: () => handleShowModal('update', item),
      });

      items.push({
        content: 'Delete Client',
        onClick: () => handleShowModal('delete', item),
      });

      items.push({
        content: 'View Credits',
        onClick: () =>
          history.push(`/admin/${item.id}/credits`, { clientName: item.name }),
      });

      return items;
    }

    return (
      <div
        id={`admin_clientsRow_actions?client=${item.id}`}
        className={classes.actionsWrapper}
      >
        <ActionsMenu
          id={`client_list_actions_menu?id=${item.id}`}
          items={getMenuItems()}
          align="end"
          title="client list actions menu"
        />
      </div>
    );
  }

  render() {
    const { item, classes } = this.props;
    return (
      <tr>
        <td>
          <Link
            id={`admin_clientsRow_name?client=${item.id}`}
            className={classes.linkColor}
            to={{ pathname: `/admin/console/${item.id}/`, state: item }}
          >
            {item.name}
          </Link>
        </td>
        <td>{item.bucket}</td>
        <td>{item.index}</td>
        <td>{item.sub_domain}</td>
        <td>{item.client_type}</td>
        <td>{item.doc_count}</td>
        <td>{item.pre_sig_doc_count}</td>
        <td>{item.post_sig_doc_count}</td>
        <td>{item.restoration_hub_count}</td>
        <td>{item.billable_doc_count}</td>
        <td>{item.doc_limit || EM_DASH}</td>
        <td>{this.renderExtraOptions(item)}</td>
      </tr>
    );
  }
}

EcClientItem = withRouter(EcClientItem);

export default injectSheet(styles)(EcClientItem);
