import { AxiosError } from 'axios';

import { ColumnSortOrder } from '~/types';

interface Identifiable {
  id: any;
}

export const toSortParam = (sortBy: ColumnSortOrder): string => {
  return `${sortBy.desc ? '-' : ''}${sortBy.id}`;
};

export const testIsAxiosError = <E>(error: unknown): error is AxiosError<E> =>
  (error as AxiosError<E>).isAxiosError;

export function patchById<T extends Identifiable>(
  list: T[] | undefined,
  id: any,
  patch: T | undefined,
) {
  const item = list?.find((key) => key.id === id);

  if (item) {
    Object.assign(item, patch);
  }
}

export function filterById<T extends Identifiable>(
  list: T[] | undefined,
  id: any,
) {
  return list?.filter((item) => item.id !== id);
}
