import {
  DocumentEditorContainerComponent,
  internalZoomFactorChange,
  Toolbar,
  ToolbarItem,
} from '@syncfusion/ej2-react-documenteditor';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM, { createPortal } from 'react-dom';

import AuthenticationStore from '~/auth';
import { isDev, isTest } from '~/dev';
import { Box, Button, getUserName, useToast, useToggle } from '~/eds';
import { BaseDocumentViewerProps } from '~/features/document-viewer/types';
import {
  useCurrentUser,
  useRefreshTokenWithInterval,
  useStateAndRef,
} from '~/hooks';
import Configuration from '~/services/configuration';
import { Nullable } from '~/types';
import { captureException } from '~/utils';
import { isAsync } from '~/utils/function';

import {
  DOCUMENT_EDITOR_CONTAINER_ID,
  EDITOR_SIZE_ADJUSTMENT_INTERVAL,
  SAVE_INTERVAL,
} from './constants';
import { useDocumentEditorContext } from './DocumentEditorContext';
import { Highlight as Ej2Highlight, IHighlight } from './Highlight';
import './styles';

DocumentEditorContainerComponent.Inject(Toolbar);

export type {
  Revision,
  RevisionType,
  RevisionCollection,
  DocumentEditor as DocumentEditorType,
} from '@syncfusion/ej2-react-documenteditor';
export {
  WCharacterFormat,
  TextElementBox,
} from '@syncfusion/ej2-react-documenteditor';

interface DocumentEditorProps
  extends BaseDocumentViewerProps<{ type: string }> {
  /**
   * The file that will be loaded in the document editor
   */
  file: File;
  /**
   * width of the document editor
   */
  width: string;
  /**
   * the edit button tooltip
   */
  editButtonTooltip?: string;
  /**
   * enables the toolbar
   */
  enableToolbar?: boolean;
  /**
   * if it's in edit mode
   */
  isEditing?: boolean;
  /**
   * if the edit button is disabled
   */
  isEditButtonDisabled?: boolean;
  /**
   * the interval between savings
   */
  saveInterval?: number;
  /**
   * When true removes the auto saving feature and the edit button. Defaults to false.
   */
  readonly?: boolean;
  /** function to resolve the document content, must be passed to generate source and clause highlights */
  contentResolver?: () => { words: { word: string; id: Nullable<number> }[] };
  /**
   * callback to be called before loading a document
   *
   * @param sfdt the loaded document sfdt
   */
  onBeforeDocumentLoad?: () => void;
  /**
   *  callback to be called whenever the content changes
   */
  onContentChange?: () => void;
  /**
   * callback to be called after loading a document
   *
   * @param sfdt the loaded document sfdt
   */
  onDocumentLoad?: (sfdt: any) => void;
  /**
   * callback to be called whenever the document will be saved
   *
   * @param file the file generated from the sfdt in the document editor
   */
  onDocumentSave?: (file: File, isDoneEditing: boolean) => Promise<void> | void;
  /**
   * callback to be called whenever the edit button is pressed
   */
  onEditClick?: () => void;
  /**
   * element to be rendered in the status bar for editting online
   */
  editOnlineButton?: React.ReactNode;
}

const toolbarItems: ToolbarItem[] = [
  'Undo',
  'Redo',
  'Separator',
  'Image',
  'Table',
  'Hyperlink',
  'TableOfContents',
  'InsertFootnote',
  'InsertEndnote',
  'Separator',
  'Find',
  'Separator',
  'TrackChanges',
];

export const DocumentEditor = memo(
  ({
    activeHighlightId,
    editButtonTooltip,
    editOnlineButton,
    enableToolbar = false,
    file,
    highlights = [],
    isEditButtonDisabled = false,
    isEditing: overrideIsEditing = false,
    saveInterval = SAVE_INTERVAL,
    readonly = false,
    width,
    contentResolver,
    onBeforeDocumentLoad,
    onContentChange,
    onDocumentLoad,
    onDocumentSave,
    onEditClick,
  }: DocumentEditorProps) => {
    const documentEditorRef = useRef<DocumentEditorContainerComponent | null>();
    const {
      isLoading,
      isDocumentReady,
      viewerClientWidth,
      zoomFactor,
      parseHighlights,
      setIsLoading,
      setDocumentEditorContainer,
      setIsDocumentReady,
      setIsInEditMode,
      toggleTrackChanges,
      setZoomFactor,
    } = useDocumentEditorContext();
    const statusBarRef = useRef<HTMLElement>();
    const [isEditorReady, setIsEditorReady] = useState(false);
    const [isEditButtonLoading, setIsEditButtonLoading] = useState(false);
    const [
      isEditing,
      toggleEditing,
      enableEditMode,
      enableViewMode,
    ] = useToggle(overrideIsEditing || false);
    const currentUser = useCurrentUser();
    const [
      _hasContentChanged,
      setHasContentChanged,
      hasContentChangedRef,
    ] = useStateAndRef(false);
    const [_isSaving, setIsSaving, isSavingRef] = useStateAndRef(false);
    const [editorHeight, setEditorHeight, editorHeightRef] = useStateAndRef(0);
    const { toast } = useToast();
    // this will update the token in case of inactivity
    useRefreshTokenWithInterval();

    const setupHeaders = (args: any) => {
      const AS = AuthenticationStore();
      const token = AS.getAccessToken();
      if (token) {
        args.headers = [{ Authorization: `Bearer ${token}` }];
      } else {
        args.withCredentials = true;
      }
    };

    useEffect(() => {
      setIsInEditMode(isEditing);
      // enable track changes when start editing
      try {
        isEditing && toggleTrackChanges(isEditing);
      } catch (e) {
        captureException('An error occurred when enabling track changes.', e, {
          section: 'ej2 document editor',
        });
        toast({
          message:
            'An error occurred when loading the editor, please refresh the page.',
          status: 'danger',
        });
      }
      // hide properties panel when start editing
      if (documentEditorRef.current) {
        documentEditorRef.current.showHidePropertiesPane(false);
        documentEditorRef.current.showPropertiesPane = false;
      }

      const handleBeforeUnload = (e: BeforeUnloadEvent) => {
        e.preventDefault();
      };

      if (isEditing) {
        window.addEventListener('beforeunload', handleBeforeUnload);
      }

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, [isEditing]);

    useEffect(() => {
      overrideIsEditing ? enableEditMode() : enableViewMode();
    }, [overrideIsEditing]);

    const onLoad = async () => {
      setIsDocumentReady(false);
      const { type } = file;
      const documentEditor = documentEditorRef.current?.documentEditor;
      let content = '';
      switch (type) {
        case 'application/json':
        case 'application/sfdt':
          content = await file.text();
          break;
        default:
          throw new Error('Unsuported file type');
      }
      if (documentEditor) {
        try {
          onBeforeDocumentLoad?.();
          documentEditor.open(content);
          setIsDocumentReady(true);
          onDocumentLoad?.(JSON.parse(documentEditor.serialize() || ''));
        } catch (e) {
          captureException('An error occurred when loading the document.', e, {
            section: 'ej2 document editor',
          });
          setIsLoading(false);
        }
      }
      setIsLoading(false);
    };

    const cleanup = () => {
      // we need to destroy it since it's not a direct child of the editor.
      // similar issue related in syncfusion https://www.syncfusion.com/forums/169679/failed-to-execute-removechild-on-node-the-node-to-be-removed-is-not-a-child-of-this-node
      // please, do not change the order
      statusBarRef.current?.remove();
      documentEditorRef.current?.documentEditor?.documentHelper?.viewerContainer?.remove();
      setIsDocumentReady(false);
    };

    useEffect(() => {
      if (isEditorReady && file) {
        setIsLoading(true);
        onLoad();
      }
    }, [isEditorReady, file]);

    useEffect(() => {
      let saveInterval: Nullable<NodeJS.Timeout> = null;
      if (isEditorReady) {
        if (!readonly) {
          saveInterval = setupSaveInterval();
        }
      }
      return () => {
        saveInterval && clearInterval(saveInterval);
      };
    }, [isEditorReady, readonly]);

    useEffect(() => {
      documentEditorRef.current?.showHidePropertiesPane(enableToolbar);
    }, [enableToolbar]);

    const saveDocument = async (isDoneEditing = false) => {
      if (onDocumentSave) {
        setIsSaving(true);
        try {
          const documentBlob = await documentEditorRef.current?.documentEditor.saveAsBlob(
            'Sfdt',
          );
          if (documentBlob) {
            isAsync(onDocumentSave)
              ? await onDocumentSave(
                  new File([documentBlob], 'document.json', {
                    type: 'application/json',
                  }),
                  isDoneEditing,
                )
              : onDocumentSave(
                  new File([documentBlob], 'document.json', {
                    type: 'application/json',
                  }),
                  isDoneEditing,
                );
            setHasContentChanged(false);
          }
        } catch (e) {
          // if the user is online, we should capture the error
          if (window.navigator.onLine) {
            captureException('An error occurred when saving the document.', e, {
              section: 'ej2 document editor',
            });
          }
        } finally {
          setIsSaving(false);
        }
      }
    };

    const setupSaveInterval = () => {
      return setInterval(async () => {
        if (hasContentChangedRef.current && !isSavingRef.current) {
          await saveDocument();
        }
      }, saveInterval);
    };

    const handleEdit = async () => {
      setIsEditButtonLoading(true);
      if (isEditing) {
        await saveDocument(true);
      }
      onEditClick ? onEditClick() : toggleEditing();

      setIsEditButtonLoading(false);
    };

    const renderChangeModeButton = () => {
      statusBarRef.current = document.getElementsByClassName(
        'e-de-status-bar',
      )?.[0] as HTMLElement;
      if (statusBarRef.current) {
        return ReactDOM.createPortal(
          <Box
            mr={8}
            // ej2 added a class to button and this overrides to use our own height
            styles={{
              '& #change-mode-button': {
                height: '40px',
              },
            }}
          >
            <Button
              id="change-mode-button"
              disabled={isLoading || !isEditorReady || isEditButtonDisabled}
              variant="primary"
              tooltip={editButtonTooltip}
              text={isEditing ? 'Done Editing' : 'Edit'}
              onClick={handleEdit}
              isLoading={isEditButtonLoading}
            />
          </Box>,
          statusBarRef.current,
        );
      }
      return null;
    };

    const renderEditOnlineButton = () => {
      statusBarRef.current = document.getElementsByClassName(
        'e-de-status-bar',
      )?.[0] as HTMLElement;
      if (statusBarRef.current) {
        return ReactDOM.createPortal(
          <Box
            mr={4}
            // ej2 added a class to button and this overrides to use our own height
            styles={{
              '& #open-online-button': {
                height: '40px',
              },
            }}
          >
            {editOnlineButton}
          </Box>,
          statusBarRef.current,
        );
      }
      return null;
    };

    const onInit = (scope: DocumentEditorContainerComponent) => {
      if (scope) {
        // really helpful when debugging.
        if (isDev || isTest) {
          window.documentEditor = scope;
        }
        if (!documentEditorRef.current) {
          documentEditorRef.current = scope;
          // adds a listener to the zoom factor change
          documentEditorRef.current.documentEditor.on(
            internalZoomFactorChange,
            () =>
              setZoomFactor(
                documentEditorRef.current?.documentEditor?.zoomFactor || 1,
              ),
          );
          setDocumentEditorContainer(scope);
          setIsEditorReady(true);
        }
      }
    };

    // EJ2 has some issues with the height of the container, so we need to adjust it manually
    useEffect(() => {
      const container = document.getElementById(DOCUMENT_EDITOR_CONTAINER_ID);
      if (container && isDocumentReady) {
        const internval = setInterval(() => {
          const { y } = container.getBoundingClientRect();
          const fixedHeight = window.innerHeight - y;
          if (fixedHeight !== editorHeightRef.current) {
            setEditorHeight(fixedHeight);
          }
        }, EDITOR_SIZE_ADJUSTMENT_INTERVAL);

        return () => clearInterval(internval);
      }
    }, [isDocumentReady]);

    const formattedHighlights = useMemo(() => {
      if (!documentEditorRef.current?.documentEditor && !isDocumentReady) {
        return [];
      }
      const documentContent = contentResolver?.();
      const extractedHighlights: IHighlight[] = parseHighlights(
        documentEditorRef.current!.documentEditor,
        highlights,
        documentContent,
      );
      if (extractedHighlights.length !== highlights.length) {
        toast({
          message: 'Some highlights could not be found in the document.',
          status: 'danger',
        });
      }
      return extractedHighlights;
    }, [highlights, viewerClientWidth, zoomFactor, isDocumentReady]);

    /**
     * Creates the portal where the highlights will be rendered
     */
    const renderHighlightsPortal = () => {
      if (documentEditorRef.current) {
        const highlightComponents = formattedHighlights.map(
          (highlight, index) => (
            <Ej2Highlight
              highlightRects={highlight.rects}
              variant={highlight.variant}
              isActive={highlight.id === activeHighlightId}
              key={`${highlight.variant}_${index}`}
            />
          ),
        );
        return createPortal(
          highlightComponents,
          documentEditorRef.current.documentEditor.documentHelper
            .viewerContainer,
        );
      }
      return null;
    };

    const onBeforeRightButtonContextMenuOpen = () => {
      if (documentEditorRef.current) {
        const commentOption = documentEditorRef.current.documentEditor.contextMenu.menuItems.find(
          (menu) => menu.text === 'New Comment',
        );
        if (commentOption) {
          const commentButton = document.getElementById(commentOption.id!);
          if (commentButton) {
            commentButton.style.display = 'none';
          }
        }
      }
    };

    return (
      <Box
        // these styles were needed to adjust the screen after adding the button in the statusBar of EJ2
        id={DOCUMENT_EDITOR_CONTAINER_ID}
        h="100%"
        styles={{
          // this hides the commentsReviewPanel
          '& .e-de-review-pane': {
            display: 'none',
            width: '0px',
          },
          '& div.e-de-status-bar': {
            alignItems: 'center',
            '& > :first-child': {
              width: '100%',
            },
            '& > button.e-de-statusbar-zoom': {
              width: '100px',
            },
            '& .e-de-ctnr-pg-no-spellout': {
              alignItems: 'center',
            },
          },
          '& div.e-de-tool-ctnr-properties-pane': {
            height: 'calc(100% - 125px)',
          },
          '& div.e-de-ctnr-properties-pane': {
            height: 'calc(100% - 48px)',
          },
        }}
      >
        {/**
         * do not change this rendering order. Portal should always come first
         * https://github.com/facebook/react/issues/14811#issuecomment-518430470
         */}
        {isDocumentReady && renderHighlightsPortal()}
        <DocumentEditorContainerComponent
          destroyed={cleanup}
          currentUser={getUserName(currentUser)}
          ref={onInit}
          width={width}
          enableToolbar={enableToolbar || isEditing}
          height={editorHeight ? `${editorHeight}px` : '100%'}
          toolbarItems={toolbarItems}
          readOnly={!isEditing}
          restrictEditing={!isEditing}
          serviceUrl={`${Configuration.museEndpoint}/document/`}
          serverActionSettings={{ systemClipboard: 'content-to-sfdt' }}
          beforeXmlHttpRequestSend={setupHeaders}
          contentChange={() => {
            if (isDocumentReady) {
              onContentChange?.();
              setHasContentChanged(true);
            }
          }}
          enableTrackChanges={true}
          customContextMenuBeforeOpen={onBeforeRightButtonContextMenuOpen}
          documentEditorSettings={{
            optimizeSfdt: false,
            searchHighlightColor: '#FFFF00',
          }}
        />
        {editOnlineButton &&
          isEditorReady &&
          !readonly &&
          renderEditOnlineButton()}
        {isEditorReady && !readonly && renderChangeModeButton()}
      </Box>
    );
  },
);
