import { PAGE_START } from '~/constants/page';
import { Highlight } from '~/features/document-viewer';
import { PromptModelTestCase } from '~/features/x-ray';
import { sylvanus } from '~/services';
import { Nullable, Uuid } from '~/types';

import {
  FieldValueSource,
  HighlightingCoordinates,
  PromptModelTestCase as PromptModelTestCaseResponse,
} from '../../types/sylvanus/model';
import { mapTestCase } from './transformers';

interface Request {
  modelId: Uuid;
  version: number;
  testCaseNumber: number;
  outcome: PromptModelTestCase['outcome'];
  feedback?: Nullable<string>;
  goldValue?: PromptModelTestCase['goldValue'];
}

type Response = PromptModelTestCaseResponse;

export const updatePromptModelTestCase = async ({
  modelId,
  version,
  testCaseNumber,
  outcome,
  feedback,
  goldValue,
}: Request): Promise<PromptModelTestCase> => {
  const response = (await sylvanus.patch(
    `/model/${modelId}/version/${version}/testcase/${testCaseNumber}`,
    {
      outcome,
      gold_value: goldValue
        ? {
            value: goldValue.value,
            value_type: goldValue.type,
            output_type:
              goldValue.type === 'classification' ? 'string' : 'field_value',
            // only set the sources if the highlights array is well-defined
            ...(goldValue.highlights
              ? {
                  sources: mapHighlightsToFieldValueSources(
                    goldValue.highlights,
                  ),
                }
              : {}),
          }
        : null,
      user_feedback: feedback || null,
    },
  )) as Response;

  return mapTestCase(response);
};

const mapHighlightingCoordinates = ({
  pdfCoordinates,
  htmlTokens,
  highlighter,
}: Highlight): HighlightingCoordinates[] => {
  return [
    {
      coordinates: {
        rects:
          pdfCoordinates?.rects?.map((rect) => ({
            ...rect,
            pageNumber:
              rect.pageNumber ?? pdfCoordinates?.pageNumber ?? PAGE_START,
            pageRotation: 0,
          })) ?? [],
        pageNumber: pdfCoordinates?.pageNumber ?? PAGE_START,
      },
      source: highlighter ?? 'ai',
      token_ids: htmlTokens ?? [],
    },
  ];
};

const mapHighlightsToFieldValueSources = (
  highlights: Highlight[],
): FieldValueSource[] =>
  highlights.map((highlight) => ({
    context: {
      coordinates: mapHighlightingCoordinates(highlight),
      html_tokens: highlight.htmlTokens,
    },
  }));
