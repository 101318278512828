import { Actions } from '~/eds';

import { SourceType } from '../../types';

interface Props {
  sources: SourceType[];
}
export const Sources = ({ sources = [] }: Props) => {
  if (!sources.length) return null;

  return <Actions wrap actions={sources} size="xs" />;
};
