import React, { memo } from 'react';

import { DEFAULT_PLACEHOLDER_LABEL } from '../../constants';
import { DateFormat, TextVariant } from '../../types';
import { formatDate } from '../../utils';
import { Text } from '../Text';

interface Props {
  /** JS Date */
  date?: Date;
  /** Date format */
  format?: DateFormat;
  /** Text variant */
  variant?: TextVariant;
}

export const DateText = memo(({ date, format, variant = 'body' }: Props) => {
  return (
    <Text color="unset" variant={variant}>
      {date ? formatDate(date, format) : DEFAULT_PLACEHOLDER_LABEL}
    </Text>
  );
});
