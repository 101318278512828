import { noop } from 'lodash';
import React from 'react';

import { types as edsTypes, Layout, typedMemo } from '~/eds';

import { AiMessage } from './AiMessage';
import { FeedbackDetails, Message as MessageType, SourceType } from './types';
import { UserMessage } from './UserMessage';

interface Props<Source = unknown, InlineSource = unknown> {
  getMessageSources: (
    message: MessageType<Source, InlineSource>,
  ) => SourceType[];
  message: MessageType<Source, InlineSource>;
  user: edsTypes.User;
  onChoose: (
    message: MessageType<Source, InlineSource>,
    choice: string,
  ) => void;
  onSelectSource: (
    message: MessageType<Source, InlineSource>,
    sourceIndex: number,
  ) => void;
  onSubmitFeedback: (
    message: MessageType<Source, InlineSource>,
    feedbackDetails: FeedbackDetails,
  ) => void;
  disableChoices?: boolean;
  isReplying?: boolean;
  messageActions?: edsTypes.UserAction[];
  shouldDisableSources?: (
    message: MessageType<Source, InlineSource>,
  ) => boolean;
  shouldShowActions?: boolean;
  userMessageActions?: edsTypes.UserAction[];
}

export const Message = typedMemo(
  <Source extends unknown, InlineSource extends unknown>({
    disableChoices,
    isReplying,
    getMessageSources,
    message,
    messageActions = [],
    onChoose,
    onSelectSource,
    onSubmitFeedback,
    shouldShowActions = true,
    user,
    userMessageActions,
  }: Props<Source, InlineSource>) => {
    const { userType } = message;

    return (
      <Layout
        direction="column"
        px={6}
        py={2}
        spacing={4}
        className={userType}
        onMouseEnter={noop}
      >
        {message.userType === 'current' ? (
          <UserMessage
            message={message}
            actions={userMessageActions}
            user={user}
          />
        ) : (
          <AiMessage
            user={user}
            getMessageSources={getMessageSources}
            message={message}
            messageActions={messageActions}
            onChoose={onChoose}
            onSubmitFeedback={onSubmitFeedback}
            disableChoices={disableChoices}
            onSelectSource={onSelectSource}
            isReplying={isReplying}
            shouldShowActions={shouldShowActions}
          />
        )}
      </Layout>
    );
  },
);
