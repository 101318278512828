import { useMemo } from 'react';

import { Layout, Text } from '~/eds';

import { Message, SourceType } from '../../types';
import { InlineSources } from './InlineSources';
import { Sources } from './Sources';

interface Props<Source, InlineSource> {
  message: Message<Source, InlineSource>;
  getMessageSources: (message: Message<Source, InlineSource>) => SourceType[];
}
export const SourcesList = <
  Source extends unknown,
  InlineSource extends unknown
>({
  message,
  getMessageSources,
}: Props<Source, InlineSource>) => {
  const { sources: originalSources = [], inlineSources = {} } = message;
  const sources = useMemo(() => {
    return getMessageSources(message);
  }, [getMessageSources]);

  if (!originalSources.length && !Object.values(inlineSources).length)
    return null;

  return (
    <Layout direction="column" spacing={1}>
      <Text preset="help">Sources: </Text>
      {!!originalSources.length ? (
        <Sources sources={sources} />
      ) : (
        <InlineSources inlineSources={sources} />
      )}
    </Layout>
  );
};
