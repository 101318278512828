export enum OoDocsEventType {
  OnAddComment = 'onAddComment',
  OnCloseComment = 'onCloseComment',
  OnCreateBookmarksFinish = 'onCreateBookmarksFinish',
  OnDocumentContentReady = 'onDocumentContentReady',
  OnError = 'OnError',
  OnGetBookmarks = 'onGetBookmarks',
  OnMentionUser = 'onMentionUser',
  OnRedlinesLoaded = 'onRedlinesLoaded',
}
