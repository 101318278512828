import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Button } from '~/eds';

import Datetime from '../Datetime';

const REMINDER_MINUTE_THRESHOLD = 60;
const SECOND = 1000;

function getTimeDifference(date) {
  return moment().diff(date, 'minutes');
}

function SendReminder({
  lastReminderDate = moment().subtract(2, 'hour').toISOString(),
  actionHandler,
}) {
  const [timeDifference, setTimeDifference] = useState(
    getTimeDifference(lastReminderDate),
  );

  useEffect(() => {
    let intervalId;
    setTimeDifference(getTimeDifference(lastReminderDate));
    intervalId = setInterval(() => {
      setTimeDifference(getTimeDifference(lastReminderDate));
    }, 60 * SECOND);

    return () => clearInterval(intervalId);
  }, [lastReminderDate]);

  const isReminderSent = timeDifference < REMINDER_MINUTE_THRESHOLD;

  const datetimeDuration = (
    <Datetime datetime={lastReminderDate} format="duration" />
  );
  const tooltip = isReminderSent ? (
    <>A reminder was sent {datetimeDuration}.</>
  ) : undefined;

  return (
    <Button
      text="Send Reminder"
      icon="expiring"
      variant={isReminderSent ? 'tertiary' : 'action'}
      disabled={isReminderSent}
      tooltip={tooltip}
      onClick={() => actionHandler()}
    />
  );
}

SendReminder.propTypes = {
  lastReminderDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  actionHandler: PropTypes.func,
};

export default SendReminder;
