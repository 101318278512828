import React, { useMemo } from 'react';

import FilterChips from '~/components/Shared/Filters/FilterChips';
import {
  addFilters,
  clearFilter,
  removeFilter,
  updateFilter,
} from '~/components/Shared/Filters/Filters.utils';
import ManageFilters from '~/components/Shared/Filters/ManageFilters';
import { Box, Button, Layout } from '~/eds';
import { Filter } from '~/evifields';
import { FlagType, useFlag } from '~/flags';
import { FiltersCount, PilotId, SearchFilter } from '~/types';

import { CrossFilters } from '../cross-filters';

type Props = {
  crossFilters?: Filter[];
  selectedFilters: Filter[];
  searchFilters: SearchFilter[];
  pinnedFiltersIds?: Array<PilotId | string>;
  onChange: (filters: Filter[]) => void;
  onChangeCrossFilters?: (crossFilters: Filter[]) => void;
  onQueryButtonClick?: () => void;
  onUpdatePinnedFilters?: (
    updatedPinnedFilters: Array<PilotId | string>,
  ) => void;
};

export const Filters = ({
  crossFilters = [],
  pinnedFiltersIds = [],
  selectedFilters,
  searchFilters,
  onUpdatePinnedFilters,
  onQueryButtonClick,
  onChange,
  onChangeCrossFilters,
}: Props) => {
  const hasTextDelimitedMultiselectFilter = useFlag(
    FlagType.TextDelimitedDocumentIdFilter,
  );

  const sortedFilters = useMemo(() => {
    return [...selectedFilters].sort((a, b) => {
      const isAPinned = pinnedFiltersIds.indexOf(a.fieldId) !== -1;
      const isBPinned = pinnedFiltersIds.indexOf(b.fieldId) !== -1;

      return isAPinned === isBPinned ? 0 : isAPinned ? -1 : 1;
    });
  }, [pinnedFiltersIds, selectedFilters]);

  const getActionButtonTooltip = ({ selectedFiltersCount }: FiltersCount) => {
    if (selectedFiltersCount === 0) {
      return 'Please select at least one filter.';
    }
    return '';
  };

  return (
    <>
      <Layout justify="space-between">
        <Layout
          spacing="1"
          minW={0}
          wrap
          role="list"
          aria-label="pinned"
          align="center"
        >
          <Box mb={1}>
            <ManageFilters
              preset="add-filters-button"
              getActionButtonTooltip={getActionButtonTooltip}
              groupsOrder={[]}
              onApply={(selectedFilterIds) =>
                onChange(addFilters(selectedFilters, selectedFilterIds))
              }
              pinnedFiltersIds={pinnedFiltersIds}
              searchFilters={searchFilters}
              onUpdatePinnedFilters={onUpdatePinnedFilters}
            />
          </Box>

          <FilterChips
            pinnedFiltersIds={pinnedFiltersIds}
            enableFilterViews={hasTextDelimitedMultiselectFilter}
            disableRemove={false}
            searchFilters={searchFilters}
            filters={sortedFilters}
            onRemoveFilter={(filterToRemove) =>
              onChange(removeFilter(selectedFilters, filterToRemove))
            }
            onUpdateFilter={(updatedFilter) =>
              onChange(updateFilter(selectedFilters, updatedFilter))
            }
          />
          {onChangeCrossFilters && (
            <CrossFilters
              searchFilters={searchFilters}
              filters={crossFilters}
              onChange={onChangeCrossFilters}
            />
          )}
        </Layout>
        <Layout alignSelf="flex-start">
          <Button
            iconPosition="left"
            icon="reload"
            variant="action"
            text="Restore Filters"
            onClick={() => {
              onChange(
                selectedFilters
                  .map(clearFilter)
                  .filter((filter) =>
                    pinnedFiltersIds.includes(filter.fieldId),
                  ),
              );
            }}
          />
          {/* Temporary */}
          <Button
            variant="action"
            text="Query Builder"
            onClick={onQueryButtonClick}
          />
        </Layout>
      </Layout>
    </>
  );
};
