import type { Link, Parent } from 'mdast';
import remarkParse from 'remark-parse';
import remarkStringify from 'remark-stringify';
import { unified } from 'unified';
import { CONTINUE, visit } from 'unist-util-visit';
import uuid from 'uuid';

import { types } from '~/eds';
import Configuration from '~/services/configuration';
import { Uuid } from '~/types';

import { FILENAME_REGEXP } from './constants';
import { SseRequestProps } from './hooks';
import type { InlineSource, MessageSource, MessageType } from './types';

export const replyAiMessage = (): MessageType => ({
  id: uuid.v4(),
  text: '',
  timestamp: new Date(),
  userType: 'other',
});

export const replyUserMessage = (text: string): MessageType => ({
  id: uuid.v4(),
  text,
  timestamp: new Date(),
  userType: 'current',
});

export const getUserTheme = (userType: types.Chat.UserType) => ({
  bg: userType === 'other' ? 'brand.ai.light' : 'background',
});

export const getStreamEndpoint = (id: Uuid) =>
  `${Configuration.sylvanusEndpoint}/api/v1/conversation/${id}/ai_message/stream`;

export const SSE_REQUEST_PROPS: SseRequestProps = {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  openWhenHidden: true,
};

export const parseStreamEndMessage = (message: string) => {
  //* Discussed that this might be more then just for errors in the future.(e.g INFO: <message>, WARNING: <message>)
  //* We expect the server message to be in the format of "ERROR: <message>".
  const splitMessage = message.split(':');
  return splitMessage[1].trim();
};

export const STATIC_CONTEXT_ACTIONS = [
  {
    label: 'Search Range',
    options: [
      {
        label: 'Documents',
        value: 'documents',
      },
      {
        label: 'Workflow',
        value: 'workflow',
        disabled: true,
        chips: [
          {
            text: 'Coming soon',
          },
        ],
      },
      {
        label: 'Clause Library',
        value: 'clause-library',
        disabled: true,
        chips: [
          {
            text: 'Coming soon',
          },
        ],
      },
      {
        label: 'Knowledge Center',
        value: 'kownledge-center',
        disabled: true,
        chips: [
          {
            text: 'Coming soon',
          },
        ],
      },
    ],
  },
];

const findFileReference = (node: Parent): string | undefined => {
  const text = node.children.find(
    (child) => child.type === 'text' && child.value.match(FILENAME_REGEXP),
  )?.value as string | undefined;

  return text?.match(FILENAME_REGEXP)?.[0] ?? undefined;
};

export const linkProcessor = (): any => {
  return (tree: Parent, file: any) => {
    const links: InlineSource[] = [];
    let linkIndex = 1;
    //@ts-ignore
    visit(tree, 'link', (node: Link, index: number, parent: Parent) => {
      if (parent && !node.data?.visited) {
        /** if link node is not at the end of the parent node,
         * move it to the end and continue so it will be visited later.
         */
        if (
          !(node.children[0].value as string).toLowerCase().includes('link')
        ) {
          /** if link node text is different than: "Link", "Document Link", we will replace the link
           * node with its text which we assume is the document name (or a reference to the document). Otherwise we'll
           * just remove the link node and assume the document name is referenced somewhere else.*/
          parent.children.splice(index, 1, { ...node.children[0] });
        } else {
          parent.children.splice(index, 1);
        }
        node.data = { visited: true };
        parent.children.push(node);
        return CONTINUE;
      }

      const linkText = node.children[0].value as string;
      if (linkText.match(FILENAME_REGEXP)) {
        links.push({
          link: node.url,
          title: linkText,
        });
      } else {
        const nameReference = findFileReference(parent);
        links.push({
          link: node.url,
          title: nameReference ?? linkText,
        });
      }
      // Update link text to be the index of the link in the AST, like a reference link.
      node.children[0].value = `${linkIndex++}`;
    });

    file.data.links = links;
  };
};

export const parseMarkdownAndExtractInlineSources = (
  text: string,
): { inlineSources: InlineSource[]; text: string } => {
  const file = unified()
    .use(remarkParse)
    .use(linkProcessor)
    .use(remarkStringify)
    .processSync(text);

  return {
    inlineSources: file.data.links as InlineSource[],
    text: String(file),
  };
};

export const testIsInlineSources = (message: MessageType) => {
  const sources = message.sources?.length
    ? message.sources
    : message.inlineSources;

  return (sources ?? []).some(
    (source: MessageSource | InlineSource) =>
      source && testIsInlineSource(source),
  );
};

export const testIsInlineSource = (
  source: MessageSource | InlineSource,
): source is InlineSource => !!(source as InlineSource).link;
