import React, { useEffect, useState } from 'react';

import {
  domainCheck,
  loginWithClientUsernamePassword,
  loginWithToken,
  sendForgotPasswordEmail,
} from '~/api';
import { fetchCsrfToken } from '~/api/currentUser';
import evisortConversationalAI from '~/assets/images/login/evisort-conversational-ai.gif';
import AuthenticationStore from '~/auth';
import { getDecodedUserInfoFromToken } from '~/components/LoginPage';
import { trackSegment } from '~/components/SegmentAnalytics';
import { getEnvVariable } from '~/dev';
import { Box, ContentContainer, LoginPage as EdsLoginPage } from '~/eds';
import { LoginViewType, QueryParamType } from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { RoutePathType, setSearchParams, useRouting } from '~/routing';
import { getAbsoluteRedirectUrl } from '~/services/redirects';
import { SUCCESS } from '~/types/toast.types';
import { copyToClipboard } from '~/utils/helper.utils';
import {
  getPageSearchQuery,
  getPageSearchQueryByKey,
} from '~/utils/searchQuery';

const AS = AuthenticationStore();

const loginErrorMessage = {
  ACCOUNT_LOCKED: 'You have failed too many times. Try again in 30 minutes.',
  INVALID_CREDENTIALS: 'Invalid email or password.',
  INVALID_TOKEN: 'Invalid token.',
  SAML_ERROR:
    "Failed to log in using the supplied credentials. Contact your organization's administrator to verify that your account is set up correctly.  Click the button to copy the error code.",
  SERVER_ERROR: 'An error occurred. Please try again later.',
  SSO_ENFORCED:
    'Single sign-on is enforced for your organization and you must log in using your organization’s identity provider. Please click on the Sign In button above to log in.',
};

const welcomeBanner = {
  banner: '',
  bannerImageBackgroundSize: '100%',
  bannerImagePosition: 'center',
  bannerImageSrc: evisortConversationalAI,
  padding: 0,
};

function Page(props) {
  const { history } = useRouting();

  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState(LoginViewType.Domain);
  const [returnTo, setRedirectTo] = useState(null);
  const [ssoInfo, setSSO] = useState(false);
  const [error, setError] = useState();
  const [client, handleDomainChange] = useState('');
  const [resetPasswordQueryResult, setResetPasswordQueryResult] = useState({});
  const [isLoadingLoginAccount, setIsLoadingLoginAccount] = useState(false);
  const [authRedirectTo, setAuthRedirectTo] = useState(null);

  const isEmbed = AS.isEmbed();

  const searchParamKey = [...getPageSearchQuery().keys()][0];

  const enableSsoAutoLogin = isEmbed && ssoInfo; // enable auto-login if in embedded login mode and ssoInfo exists

  // login initialization
  useEffect(() => {
    AS.setCsrf(null); // clean csrf token in auth store on login page load
  }, []);

  // auto-login with SSO-only if enabled appropriately
  useEffect(() => {
    if (enableSsoAutoLogin) {
      handleLoginSso();
    }
  }, [client, enableSsoAutoLogin]);

  useEffect(
    () => {
      const searchParamValue = getPageSearchQueryByKey(searchParamKey, '');
      const handleSAMLCallback = () => {
        if (!searchParamKey) return;

        const { client_id, user_id } = JSON.parse(atob(searchParamValue)) ?? {};

        if (client_id && user_id) {
          trackSegment('User SSO Login', {
            groupId: client_id,
            userId: `${user_id}_${client_id}`,
          });
        }

        // EKP-15827 when httponly enabled
        if (!AS.getAccessToken() || !AS.getRefreshToken()) {
          AS.removeInactiveTimer();
          AS.saveAccessTokenCreationTime();
        }

        setLogIn();
      };

      const handleResetPasswordCallback = (loggedInWithWelcome) => {
        loginWithToken({ token: searchParamValue })
          .then((data) => {
            // EKP-15827 when httponly not enabled
            if (data.access_token && data.refresh_token) {
              AS.saveAuthentication(data.access_token, data.refresh_token);
            }
            // EKP-15827 when httponly enabled
            if (data.user_id && data.client_id) {
              AS.removeInactiveTimer();
              AS.saveAccessTokenCreationTime();
            }
            setLogIn(loggedInWithWelcome);
          })
          .catch(() => setError('An error occurred with log in.'));
      };

      const isLoggedIn = AS.isLoggedIn();
      const hasReturnTo =
        props.location && props.location.state && props.location.state.from;

      const windowPathname = window.location.pathname;

      let tempSubDomain = windowPathname.split('/')[1];

      if (isLoggedIn && !isEmbed) {
        return props.history.push('/');
      }
      if (hasReturnTo) {
        const { pathname, search } = props.location.state.from;
        if (search) {
          setRedirectTo(`${pathname}/${search}`);
        } else {
          setRedirectTo(`${pathname}`);
        }
      }
      if (tempSubDomain) {
        passDomain(tempSubDomain);
      }

      switch (searchParamKey) {
        case QueryParamType.SamlSuccess:
          return handleSAMLCallback();
        case QueryParamType.SamlError:
          return setStage(LoginViewType.SamlError);
        case QueryParamType.FirstLoginSuccessToken:
          return handleResetPasswordCallback(true);
        case QueryParamType.ResetSuccessToken:
          return handleResetPasswordCallback();
        default:
          return undefined;
      }
    },
    [props.location.search, props.history], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    const from = getPageSearchQueryByKey('from', '');
    const redirectTo = getPageSearchQueryByKey('redirect_to', '');

    if (from === 'teamsApp' && redirectTo) {
      const envAllowedDomains = getEnvVariable(
        'REACT_APP_ALLOWED_REDIRECT_TO_DOMAIN',
      );
      const allowedDomains = envAllowedDomains
        ? envAllowedDomains.split(',')
        : [];
      const isAllowedDomain = allowedDomains.some((domain) => {
        // The domain can be a wildcard domain, so we need to convert it to a regex pattern
        // to match the origin of the redirect_to URL.
        // e.g. https://*.devtunnels.ms -> https:\/\/.*\.devtunnels\.ms$/
        const regexPattern = domain.replace(/\./g, '\\.').replace(/\*/g, '.*');
        const redirectToOrigin = new URL(redirectTo).origin;

        // This domain 'https://.*\\.devtunnels\\.ms' should match with
        // 'https://m349ndw7-3978.us.devtunnels.ms'
        return new RegExp(`^${regexPattern}$`).test(redirectToOrigin);
      });

      if (isAllowedDomain) {
        setAuthRedirectTo(redirectTo);
      } else {
        setError('The auth success redirection will not be allowed.');
      }
    }
  }, [props.location.search, props.history]);

  if (stage === LoginViewType.LoggedIn) {
    const clientLowercase = client.toLowerCase();
    window.location.href = returnTo
      ? `/${clientLowercase}${returnTo}`
      : `/${clientLowercase}/`;
  }

  const shouldUseSearchAsHomePage = useFlag(FlagType.SearchAsHomePage);
  if (stage === LoginViewType.LoggedInWithWelcome) {
    if (shouldUseSearchAsHomePage) {
      window.location.href = `/${client}/search?welcomeMessage=true`;
    } else {
      window.location.href = `/${client}/?welcomeMessage=true`;
    }
  }

  const setLogIn = (loggedInWithWelcome = false) => {
    setTimeout(() => {
      if (AS.isLoggedIn()) {
        loggedInWithWelcome
          ? setStage(LoginViewType.LoggedInWithWelcome)
          : setStage(LoginViewType.LoggedIn);
      }
    }, 100);
  };

  const passDomain = (domain) => {
    handleDomainChange(domain);
    checkClient(domain);
  };

  const checkClient = (client) => {
    const fromLocation = props.location.state?.from;
    const clientLowercase = client.toLowerCase();
    const formData = fromLocation
      ? {
          client,
          return_to: getAbsoluteRedirectUrl(
            window.location.origin,
            clientLowercase,
            fromLocation,
          ),
        }
      : { client };
    setLoading(true);
    domainCheck(formData)
      .then((response) => {
        setLoading(false);
        if (response) {
          if (!props.location.search.includes(QueryParamType.SamlError)) {
            setStage(LoginViewType.Email);
          }
          if (response.sso.error) {
            setError(`Invalid SSO Configuration: ${response.sso.error}`);
          } else {
            setError(null);
          }
          if (response.sso.enabled) {
            setSSO(response.sso);
          }
        }
      })
      .catch(() => {
        setLoading(false);
        setError('An error occurred. Please check again.');
      });
  };

  const handleLogin = (formData) => {
    setIsLoadingLoginAccount(true);
    return loginWithClientUsernamePassword(formData)
      .then(async (data) => {
        // EKP-15827 when httponly not enabled
        if (data.access_token && data.refresh_token) {
          AS.saveAuthentication(data.access_token, data.refresh_token);

          const { client_id, user_id } = getDecodedUserInfoFromToken(
            data.access_token,
          );
          trackSegment('User Login', {
            groupId: client_id,
            userId: `${user_id}_${client_id}`,
          });
        }

        // EKP-15827 when httponly enabled
        if (data.user_id && data.client_id) {
          AS.removeInactiveTimer();
          AS.saveAccessTokenCreationTime();

          trackSegment('User Login', {
            groupId: data.client_id,
            userId: `${data.user_id}_${data.client_id}`,
          });
        }

        if (isEmbed) {
          try {
            if (!AS.getCsrf()) {
              await fetchCsrfToken();
            }
            history.push(RoutePathType.LoginEmbedSuccess);
            return;
          } catch {
            history.push(RoutePathType.Login);
          }
        }

        setTimeout(() => {
          if (AS.isLoggedIn()) {
            setStage(LoginViewType.LoggedIn);
          }

          hanleExternalRedirect();
        }, 100);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const {
            response: {
              data: { invalid_attempts, msg },
            },
          } = error;

          if (invalid_attempts >= 3) {
            setError(
              `You have attempted to login ${invalid_attempts} times unsuccessfully.  After 5 failed attempts, your account will be locked for 30 minutes.`,
            );
          } else {
            setError(loginErrorMessage[msg]);
          }
        }
      })
      .finally(() => setIsLoadingLoginAccount(false));
  };

  const hanleExternalRedirect = () => {
    if (authRedirectTo) {
      window.location.href = authRedirectTo;
    }
  };

  const samlErrorMsg = getPageSearchQueryByKey(QueryParamType.SamlError);

  const handleLoginSso = () => {
    AS.clearAuthentication();
    const redirectUrl = isEmbed
      ? setSearchParams(ssoInfo.signon_url, {
          RelayState: `${window.location.origin}/${client}${RoutePathType.LoginEmbedSuccess}`,
        })
      : ssoInfo.signon_url;
    window.location.href = redirectUrl;
  };

  const handleCopySsoError = () => {
    copyToClipboard(samlErrorMsg, 'Error code copied to clipboard.', SUCCESS);
  };

  /**
   * Mapping of old/DEPRECATED LoginViewType to new LoginPage.Stage
   */
  const handleChangeWorkspace = (updatedWorkspace) => {
    checkClient(updatedWorkspace);
    handleDomainChange(updatedWorkspace);
  };

  const handleLoginAccount = (updatedAccount) => {
    handleLogin({
      client,
      username: updatedAccount.email,
      password: updatedAccount.password,
    });
  };

  const handleResetPassword = ({ email, workspace }) => {
    setResetPasswordQueryResult({ isFetching: true });
    sendForgotPasswordEmail({ client: workspace, userId: email })
      .then(() => {
        setResetPasswordQueryResult({
          isFetching: false,
          isSuccess: true,
        });
      })
      .catch(() => {
        setResetPasswordQueryResult({
          isError: true,
          isFetching: false,
        });
      });
  };

  const queryChangeWorkspace = [
    handleChangeWorkspace,
    ssoInfo ? { error: ssoInfo.error, isSuccess: true } : {},
  ];

  // Not all legacy queries handle results (isLoading/isSuccess/isError);
  const queryLoginAccount = [
    handleLoginAccount,
    {
      error: stage === LoginViewType.SamlError ? undefined : error,
      isFetching: isLoadingLoginAccount,
    },
  ];
  const queryLoginSso = [
    handleLoginSso,
    {
      error:
        stage === LoginViewType.SamlError
          ? loginErrorMessage.SAML_ERROR
          : undefined,
    },
  ];
  const queryResetPassword = [handleResetPassword, resetPasswordQueryResult];

  const onBackToSignIn = () => setResetPasswordQueryResult({});

  const sso = ssoInfo
    ? {
        id: ssoInfo.provider_name,
        name: ssoInfo.provider_name,
        url: ssoInfo.signon_url,
      }
    : null;

  const loginPageProps = {
    isSsoOnly: enableSsoAutoLogin,
    queryChangeWorkspace,
    queryLoginAccount,
    queryLoginSso,
    queryResetPassword,
    onBackToSignIn,
    onResolveLoginSsoError: handleCopySsoError,
    sso,
    stage: stage === LoginViewType.Domain && !error ? 'workspace' : 'login', // this logic maps to the eds.LoginPage.stage.
    workspace: client,
  };

  const componentStyles = {
    page: {
      alignItems: 'flex-start',
      backgroundColor: 'inverse.background.secondary',
      display: 'flex',
      height: '100vh',
      margin: 'auto',
      overflowX: 'hidden',
      overflowY: 'auto',
      position: 'fixed',
      width: '100vw',
    },
    container: {
      flex: 'auto',
      padding: 4,
      margin: 'auto',
      maxWidth: '1280px',
    },
  };

  if (enableSsoAutoLogin) {
    return null;
  }

  return (
    <Box styles={componentStyles.page}>
      <Box styles={componentStyles.container}>
        <ContentContainer loadingContent={{ isLoading: loading }}>
          <EdsLoginPage {...loginPageProps} welcomeBanner={welcomeBanner} />
        </ContentContainer>
      </Box>
    </Box>
  );
}

export default Page;
