import { addDays } from 'date-fns';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';

import { updateFilterValues } from '~/components/AnalyzerPage/AnalyzerPage.utils';
import DeprecatedUserMultiSelect from '~/components/Client/DeprecatedUserMultiSelect';
import { formatDate, parseDate } from '~/eds';
import { FlagType, withFlags } from '~/flags';

import { black5, evisortBlue } from '../../../assets/shared-styles/general';
import {
  MODAL_DEPARTMENT_TREE,
  MODAL_FOLDER_TREE,
} from '../../../types/modal.types';
import { ERROR } from '../../../types/toast.types';
import { togglePinFilterField } from '../../AlertsPage/AlertsPage.data';
import EcButton from '../../Shared/EcButton';
import EcCheckbox from '../../Shared/EcCheckbox';
import EcDatepicker from '../../Shared/EcDatepicker';
import EcFiltersSection from '../../Shared/EcFiltersSection';
import EcInput from '../../Shared/EcInput';
import EcModal from '../../Shared/EcModal';
import EcModalCard from '../../Shared/EcModalCard';
import EcMultipleSelectValue from '../../Shared/EcMultipleSelectValue';
import EcMultiSelect from '../../Shared/EcMultiSelect';
import EcSelect from '../../Shared/EcSelect';
import { showToast } from '../../Shared/EcToast';
import CheckmarkIcon from '../../Shared/Icons/CheckmarkIcon';
import LoadingSpinner from '../../Shared/Icons/LoadingSpinner';
import PlusIcon from '../../Shared/Icons/PlusIcon';
import SubArrowIcon from '../../Shared/Icons/SubArrowIcon';
import { updateFilterPinnedProperty } from '../AddFilterModal/AddFilterModal.utils';
import { getAlertModalData } from './AlertModal.data';
import {
  OCCURRENCE_END_TIMESPAN_OPTIONS,
  OCCURRENCE_PERIOD_OPTIONS,
} from './AlertModal.options';
import styles from './AlertModal.styles';
import {
  createFormattedDateString,
  processData,
  validateFiltersForm,
} from './AlertModal.utils';

class AlertModal extends Component {
  constructor(props) {
    super(props);

    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.handleFoldersChange = this.handleFoldersChange.bind(this);
    this.handleDepartmentsChange = this.handleDepartmentsChange.bind(this);
    this.handleCreateAlert = this.handleCreateAlert.bind(this);
    this.handleUpdateAlert = this.handleUpdateAlert.bind(this);
    this.handleFilterOptionsChange = this.handleFilterOptionsChange.bind(this);
    this.handleSubmitFilters = this.handleSubmitFilters.bind(this);

    this.state = {
      loading: false,
      currentModal: null,
      userFieldsSuggestions: [],
      editMode: !!this.props.alertId && !this.props.isDuplicated,
      step: this.props.step,
      alertData: null,
      active: this.props.active,
      name: this.props.name,
      message: this.props.message,
      occurrenceInterval: this.props.occurrenceInterval,
      occurrencePeriod: this.props.occurrencePeriod,
      startDate: this.props.startDate,
      occurrenceEndTimespan: this.props.occurrenceEndTimespan,
      occurrenceEndDate: this.props.occurrenceEndDate,
      occurrenceEndLimit: this.props.occurrenceEndLimit,
      folders: this.props.folders,
      filters: this.props.filters,
      filterOptions: [],
      provisionOptions: [],
      users: this.props.users.map((user) => user.id),
      userFields: this.props.userFields,
      excludedUsers: this.props.excludedUsers.map((user) => user.id),
      departments: this.props.departments,
      editAccess: this.props.editAccess,
    };
  }

  componentDidMount() {
    const { currentUser, alertId } = this.props;

    this.setState({ loading: true });
    getAlertModalData(
      currentUser.client,
      alertId,
      (customFieldsRes, sections, provisionOptions, alertData) => {
        // Current workaround for 'Document Id' field
        const newSections = sections.sections.map((sec) => {
          if (sec.title === 'Other Fields') {
            return { ...sec };
          }

          return sec;
        });

        this.setState({
          userFieldsSuggestions: this.setUserFieldFormat(customFieldsRes),
          filterOptions: newSections,
          provisionOptions: provisionOptions.suggestions,
          loading: false,
        });

        if (alertId) {
          this.setAlertData(alertData);
        }
      },
      () => {
        showToast(
          ERROR,
          'Something went wrong with fetching alert modal options.',
        );
      },
    );
  }

  setUserDataFormat(usersList) {
    return usersList.map((user) => user.id);
  }

  setUserFieldFormat(userFields) {
    return userFields.map(({ id, label }) => {
      return { id, label, name: label, value: label };
    });
  }

  setAlertData(res) {
    const { isDuplicated, flags } = this.props;
    const { filterOptions } = this.state;

    const refactorMoment = flags[FlagType.DeprecateMoment];

    let occurrenceEndTimespan = OCCURRENCE_END_TIMESPAN_OPTIONS.find(
      (obj) => obj.value === 'never',
    );
    if (res.recurrences) {
      occurrenceEndTimespan = OCCURRENCE_END_TIMESPAN_OPTIONS.find(
        (obj) => obj.value === 'number_of_occurrences',
      );
    } else if (res.end_date) {
      occurrenceEndTimespan = OCCURRENCE_END_TIMESPAN_OPTIONS.find(
        (obj) => obj.value === 'on_a_specific_date',
      );
    }
    const flattenedSections = filterOptions.reduce((acc, val) => {
      return acc.concat(val.fields);
    }, []);
    const filterQuery = res.query || this.props.filters;
    const mappedFiltersWithFieldValues = updateFilterValues(
      flattenedSections,
      filterQuery,
    );

    this.setState({
      alertData: res,
      active: res.active || this.props.active,
      name: res.name || this.props.name,
      message: res.message || this.props.message,
      occurrenceInterval:
        res.occurrence_interval || this.props.occurrenceInterval,
      occurrencePeriod:
        OCCURRENCE_PERIOD_OPTIONS.find(
          (option) => option.value === res.occurrence_period,
        ) || this.props.occurrencePeriod,
      startDate: isDuplicated
        ? this.props.startDate
        : res.start_date
        ? parseDate(res.start_date, refactorMoment)
        : this.props.startDate,
      occurrenceEndTimespan: isDuplicated
        ? this.props.occurrenceEndTimespan
        : occurrenceEndTimespan,
      occurrenceEndDate: isDuplicated
        ? this.props.occurrenceEndDate
        : res.end_date
        ? parseDate(res.end_date, refactorMoment)
        : this.props.occurrenceEndDate,
      occurrenceEndLimit: res.recurrences || this.props.occurrenceEndLimit,
      folders: res.folders || this.props.folders,
      filters: mappedFiltersWithFieldValues,
      users: res.users ? this.setUserDataFormat(res.users) : this.props.users,
      userFields: res.user_fields
        ? this.setUserFieldFormat(res.user_fields)
        : this.props.userFields,
      excludedUsers: res.excluded_users
        ? this.setUserDataFormat(res.excluded_users)
        : this.props.excludedUsers,
      departments: res.departments || this.props.departments,
      editAccess: res.edit_access || this.props.editAccess,
    });
  }

  handleShowModal(currentModal) {
    this.setState({ currentModal });
  }

  handleHideModal() {
    this.setState({ currentModal: null });
  }

  onCalendarOpenClose = (open) => {
    this.props.disableEscapeKey(open);
  };

  handleCreateAlert() {
    const { handleAlertAction, hideModal } = this.props;
    const { step: _step, ...data } = this.state;

    const processedData = processData(data);

    handleAlertAction(processedData);
    hideModal();
  }

  handleUpdateAlert() {
    const { handleAlertAction, hideModal, alertId } = this.props;
    const { step: _step, ...data } = this.state;
    const { alertData } = this.state;

    const processedData = processData(data);

    const updatedKeys = Object.keys(processedData)
      .map((key) => {
        const initialValue = alertData[key];
        const currentValue = processedData[key];

        if ((key === 'start_date' || key === 'end_date') && initialValue) {
          if (createFormattedDateString(initialValue) !== currentValue)
            return key;
        } else if (Array.isArray(initialValue)) {
          if (key === 'departments') {
            if (
              initialValue.map((val) => val.department_id).toString() !==
              currentValue.toString()
            )
              return key;
          } else {
            if (
              initialValue.map((val) => val.id).toString() !==
              currentValue.toString()
            )
              return key;
          }
        } else if (
          !Array.isArray(initialValue) &&
          initialValue !== currentValue
        ) {
          return key;
        }
        return null;
      })
      .filter((el) => el);

    const patchData = {};
    updatedKeys.forEach((key) => (patchData[key] = processedData[key]));

    handleAlertAction(alertId, patchData);
    hideModal();
  }

  handleFoldersChange(locations) {
    const newFolders = [...this.state.folders];
    newFolders.push(locations);

    this.setState({ folders: newFolders.flat(Infinity) });
  }

  handleDepartmentsChange(departments) {
    this.setState({ departments });
  }

  updateFilterOptionUI(filterId) {
    const { filterOptions } = this.state;

    const newFilterOptions = updateFilterPinnedProperty(
      filterId,
      filterOptions,
    );
    this.setState({ filterOptions: newFilterOptions });
  }

  handleFilterOptionsChange(filterId) {
    this.updateFilterOptionUI(filterId);

    togglePinFilterField(filterId).catch(() => {
      showToast(ERROR, "There's been an error with pinning this filter.");
      this.updateFilterOptionUI(filterId);
    });
  }

  renderSelectDepartmentTreeModal() {
    const { currentUser } = this.props;
    const { departments } = this.state;

    return (
      <EcModal
        modalType={MODAL_DEPARTMENT_TREE}
        width="540px"
        client={currentUser.client}
        title="Select a Department"
        departmentsSelected={departments}
        handleDepartmentChange={this.handleDepartmentsChange}
        confirmButtonIcon={<CheckmarkIcon size="20" />}
        confirmButtonText="Select Department"
        hideModal={this.handleHideModal}
      />
    );
  }

  renderFirstStep() {
    const { classes, hideModal, isDuplicated, flags } = this.props;
    const {
      loading,
      editMode,
      name,
      message,
      occurrenceInterval,
      occurrencePeriod,
      startDate,
      occurrenceEndTimespan,
      occurrenceEndDate,
      occurrenceEndLimit,
      folders,
    } = this.state;

    const refactorMoment = flags[FlagType.DeprecateMoment];

    return (
      <EcModalCard
        title={
          editMode
            ? 'Edit Alert → Basic Details'
            : 'Add Alert → Basic Details (Step 1/3)'
        }
        content={
          loading && (editMode || isDuplicated) ? (
            <div className={classes.loadingContainer}>
              <LoadingSpinner size="medium" />
            </div>
          ) : (
            <>
              <div className={classes.nameSection}>
                <div className={classes.optionLabel}>NAME</div>
                <EcInput
                  width="100%"
                  input={{
                    value: name,
                    onChange: (event) =>
                      this.setState({ name: event.target.value }),
                  }}
                />
              </div>
              <div className={classes.messageSection}>
                <div className={classes.optionLabel}>MESSAGE</div>
                <textarea
                  className={classes.optionTextArea}
                  rows="6"
                  value={message}
                  onChange={(e) => this.setState({ message: e.target.value })}
                />
              </div>
              <div className={classes.schedulingSection}>
                <div className={classes.schedulingLabel}>Scheduling</div>
                <div className={classes.schedulingSubSections}>
                  <div className={classes.frequencySection}>
                    <div className={classes.optionLabel}>FREQUENCY</div>
                    <div className={classes.optionInlineFields}>
                      <EcInput
                        width="80px"
                        type="number"
                        min="1"
                        step="1"
                        input={{
                          value: occurrenceInterval,
                          onChange: (event) =>
                            this.setState({
                              occurrenceInterval: event.target.value,
                            }),
                        }}
                      />
                      <div className={classes.optionFieldsSeparator} />
                      <EcSelect
                        width="120px"
                        options={OCCURRENCE_PERIOD_OPTIONS}
                        value={occurrencePeriod}
                        onChange={(option) =>
                          this.setState({ occurrencePeriod: option })
                        }
                      />
                    </div>
                  </div>
                  <div className={classes.startDateSection}>
                    <div className={classes.optionLabel}>START DATE</div>
                    <div className={classes.optionDatepicker}>
                      <EcDatepicker
                        date={
                          startDate
                            ? parseDate(startDate, refactorMoment)
                            : null
                        }
                        isInModal={true}
                        onDateChange={(date) => {
                          this.setState({
                            startDate: date ? formatDate(date) : null,
                          });
                        }}
                        onCalendarOpenClose={this.onCalendarOpenClose}
                      />
                    </div>
                  </div>
                  <div className={classes.endsSection}>
                    <div className={classes.optionLabel}>ENDS</div>
                    <div className={classes.optionInlineFields}>
                      <EcSelect
                        width="216px"
                        options={OCCURRENCE_END_TIMESPAN_OPTIONS}
                        value={occurrenceEndTimespan}
                        onChange={(option) => {
                          if (option.value === 'on_a_specific_date') {
                            this.setState({
                              occurrenceEndDate: addDays(
                                parseDate(startDate, refactorMoment),
                                1,
                              ),
                              occurrenceEndLimit: '',
                            });
                          } else if (option.value === 'number_of_occurrences') {
                            this.setState({ occurrenceEndDate: null });
                          } else {
                            this.setState({
                              occurrenceEndDate: null,
                              occurrenceEndLimit: '',
                            });
                          }
                          this.setState({ occurrenceEndTimespan: option });
                        }}
                      />
                      <div className={classes.optionFieldsSeparator} />
                      {occurrenceEndTimespan.value === 'never' && null}
                      {occurrenceEndTimespan.value === 'on_a_specific_date' && (
                        <div className={classes.optionDatepicker}>
                          <EcDatepicker
                            date={
                              occurrenceEndDate
                                ? parseDate(occurrenceEndDate, refactorMoment)
                                : addDays(
                                    parseDate(
                                      occurrenceEndDate,
                                      refactorMoment,
                                    ),
                                  )
                            }
                            isInModal={true}
                            minDate={addDays(
                              parseDate(startDate, refactorMoment),
                              1,
                            )}
                            onDateChange={(date) => {
                              this.setState({
                                occurrenceEndDate: date
                                  ? formatDate(date)
                                  : null,
                              });
                            }}
                            onCalendarOpenClose={this.onCalendarOpenClose}
                          />
                        </div>
                      )}
                      {occurrenceEndTimespan.value ===
                        'number_of_occurrences' && (
                        <EcInput
                          width="80px"
                          type="number"
                          min="1"
                          step="1"
                          input={{
                            value: occurrenceEndLimit,
                            onChange: (event) =>
                              this.setState({
                                occurrenceEndLimit: event.target.value,
                              }),
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.foldersSection}>
                <div className={classes.foldersLabel}>Folders</div>
                <div className={classes.optionsInfo}>
                  You can specify one or more folders to limit the documents
                  being tracked for changes. If no folders are specified, all
                  documents will be tracked.
                </div>
                <div className={classes.foldersSelected}>
                  {folders.length ? (
                    <div className={classes.selectedFolders}>
                      {folders.map((folder, index) => (
                        <EcMultipleSelectValue
                          key={`folder-${index}`}
                          label={folder.name}
                          closeHandler={() => {
                            const newFolders = [...this.state.folders].filter(
                              (f) => f.id !== folder.id,
                            );

                            this.setState({ folders: newFolders });
                          }}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className={classes.selectedFoldersEmpty}>
                      You haven’t selected any folders.
                    </div>
                  )}
                  <button
                    aria-haspopup="true"
                    className={classes.foldersAddLink}
                    onClick={() => this.handleShowModal(MODAL_FOLDER_TREE)}
                  >
                    <PlusIcon size="20" color={evisortBlue} />
                    <span>Add a Folder</span>
                  </button>
                </div>
              </div>
            </>
          )
        }
        footer={
          <>
            <EcButton white borderless text="Cancel" onClick={hideModal} />
            {editMode ? (
              <EcButton
                yellow
                text="Save Alert"
                disabled={!name || !message}
                onClick={this.handleUpdateAlert}
              />
            ) : (
              <EcButton
                yellow
                text="Next Step: Filters"
                disabled={
                  !(name && name.trim()) ||
                  !(message && message.trim()) ||
                  !occurrenceInterval
                }
                onClick={() => this.setState({ step: 2 })}
              />
            )}
          </>
        }
        hideModal={hideModal}
      />
    );
  }

  renderSecondStep() {
    const { classes, hideModal, isDuplicated } = this.props;
    const {
      loading,
      editMode,
      filters,
      filterOptions,
      provisionOptions,
    } = this.state;

    return (
      <EcModalCard
        title={
          editMode ? 'Edit Alert → Filters' : 'Add Alert → Filters (Step 2/3)'
        }
        content={
          <>
            {loading && (editMode || isDuplicated) ? (
              <div className={classes.loadingContainer}>
                <LoadingSpinner size="medium" />
              </div>
            ) : (
              <>
                <div className={classes.filtersSection}>
                  <div className={classes.filtersLabel}>Filters</div>
                  <EcFiltersSection
                    filters={filters}
                    filterOptions={filterOptions}
                    provisionOptions={provisionOptions}
                    onChange={(selectedFilters) =>
                      this.setState({ filters: selectedFilters })
                    }
                    handleFilterOptionsChange={this.handleFilterOptionsChange}
                    rootSection
                  />
                </div>
              </>
            )}
          </>
        }
        footer={
          <>
            {editMode ? (
              <Fragment>
                <EcButton white borderless text="Cancel" onClick={hideModal} />
                <EcButton
                  yellow
                  text="Save Alert"
                  onClick={this.handleSubmitFilters}
                />
              </Fragment>
            ) : (
              <Fragment>
                <EcButton
                  white
                  borderless
                  text="Back"
                  onClick={() => this.setState({ step: 1 })}
                />
                <EcButton
                  yellow
                  text="Next Step: Recipients"
                  onClick={this.handleSubmitFilters}
                />
              </Fragment>
            )}
          </>
        }
        hideModal={hideModal}
        disableEscapeKey={this.state.disableEscapeCloseModal}
      />
    );
  }

  renderThirdStep() {
    const { classes, hideModal, isDuplicated } = this.props;
    const {
      loading,
      editMode,
      users,
      userFields,
      departments,
      excludedUsers,
      editAccess,
      userFieldsSuggestions,
    } = this.state;

    return (
      <EcModalCard
        title={
          editMode
            ? 'Edit Alert → Recipients'
            : 'Add Alert → Recipients (Step 3/3)'
        }
        content={
          <>
            {loading && (editMode || isDuplicated) ? (
              <div className={classes.loadingContainer}>
                <LoadingSpinner size="medium" />
              </div>
            ) : (
              <>
                <div className={classes.recipientsSection}>
                  <div className={classes.recipientsLabel}>Recipients</div>
                  <div className={classes.recipientsSubsections}>
                    <div className={classes.recipientsSectionContainer}>
                      <div className={classes.optionLabel}>USERS</div>

                      <DeprecatedUserMultiSelect
                        width="280px"
                        selectedUserIds={users}
                        onChange={(selectedUserIds) => {
                          this.setState({ users: selectedUserIds });
                        }}
                      />
                    </div>

                    <div className={classes.recipientsSectionContainer}>
                      <div className={classes.optionLabel}>FIELDS</div>
                      <EcMultiSelect
                        value={userFields}
                        options={userFieldsSuggestions}
                        onChange={(selectedFields) =>
                          this.setState({ userFields: selectedFields })
                        }
                        getOptionLabel={(option) => option.label}
                        width="280px"
                        placeholder="Search for fields..."
                        noOptionsMessage="No fields found"
                      />
                      <div className={classes.multipleValuesContainer}>
                        <SubArrowIcon color={black5} />
                        <div className={classes.multipleValues}>
                          {userFields && userFields.length ? (
                            userFields.map((field) => (
                              <EcMultipleSelectValue
                                label={field.label}
                                key={field.label}
                                closeHandler={() => {
                                  const newCustomFields = [
                                    ...userFields,
                                  ].filter((f) => f.label !== field.label);
                                  this.setState({
                                    userFields: newCustomFields,
                                  });
                                }}
                              />
                            ))
                          ) : (
                            <span className={classes.multipleValuesEmpty}>
                              No Fields selected...
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className={classes.recipientsSectionContainer}>
                      <div className={classes.optionLabel}>DEPARTMENTS</div>
                      {departments.length ? (
                        <div className={classes.selectedDepartments}>
                          {departments.map((department, index) => (
                            <EcMultipleSelectValue
                              key={`department-${index}`}
                              label={department.department_name}
                              closeHandler={() => {
                                const newDepartments = [...departments].filter(
                                  (d) =>
                                    d.department_id !==
                                    department.department_id,
                                );
                                this.setState({ departments: newDepartments });
                              }}
                            />
                          ))}
                        </div>
                      ) : (
                        <div className={classes.departmentsEmptyState}>
                          You haven’t selected any departments.
                        </div>
                      )}
                      <button
                        aria-haspopup="true"
                        className={classes.departmentsAddLink}
                        onClick={() =>
                          this.handleShowModal(MODAL_DEPARTMENT_TREE)
                        }
                      >
                        <PlusIcon size="20" color={evisortBlue} />
                        <span>Add a Department</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className={classes.excludeUsersSection}>
                  <div className={classes.excludeUsersLabel}>Exclude Users</div>
                  <div className={classes.optionsInfo}>
                    You can exclude users from receiving this alert (i.e. if
                    they are part of a department and would automatically
                    receive it otherwise).
                  </div>

                  <DeprecatedUserMultiSelect
                    width="280px"
                    selectedUserIds={excludedUsers}
                    onChange={(selectedUserIds) => {
                      this.setState({ excludedUsers: selectedUserIds });
                    }}
                  />
                </div>
                <div className={classes.permissionsSection}>
                  <div className={classes.permissionsLabel}>Permissions</div>
                  <div className={classes.optionsInfo}>
                    By default, only you and admins will be able to edit the
                    alert.
                  </div>
                  <div className={classes.permissionsCheckbox}>
                    <EcCheckbox
                      id="allow-recipient-edit-alert"
                      checked={editAccess}
                      onClick={() =>
                        this.setState((prevState) => ({
                          editAccess: !prevState.editAccess,
                        }))
                      }
                    />
                    <label
                      className={classes.checkboxLabel}
                      htmlFor="allow-recipient-edit-alert"
                    >
                      Allow any recipient to edit this alert
                    </label>
                  </div>
                </div>
              </>
            )}
          </>
        }
        footer={
          <>
            {editMode ? (
              <Fragment>
                <EcButton white borderless text="Cancel" onClick={hideModal} />
                <EcButton
                  yellow
                  text="Save Alert"
                  onClick={this.handleUpdateAlert}
                />
              </Fragment>
            ) : (
              <Fragment>
                <EcButton
                  white
                  borderless
                  text="Back"
                  onClick={() => this.setState({ step: 2 })}
                />
                <EcButton
                  yellow
                  text="Add Alert"
                  onClick={this.handleCreateAlert}
                />
              </Fragment>
            )}
          </>
        }
        hideModal={hideModal}
      />
    );
  }

  handleSubmitFilters() {
    const { filters, editMode } = this.state;
    const { filtersFormValid, validatedFilters } = validateFiltersForm(filters);

    this.setState({ filters: validatedFilters }, () => {
      if (filtersFormValid) {
        if (editMode) {
          this.handleUpdateAlert();
        } else {
          this.setState({ step: 3 });
        }
      } else {
        showToast(
          ERROR,
          'Please make sure that none of the selected filters are blank or not set.',
        );
      }
    });
  }

  renderMultiSelectFolderTreeModal() {
    const { folders } = this.state;

    const folderIdsSelected = folders.map((folder) => folder.id);

    return (
      <EcModal
        modalType={MODAL_FOLDER_TREE}
        width="540px"
        title="Choose Folders to Search"
        folderIdsSelected={folderIdsSelected}
        handleChooseMultipleLocationsClick={this.handleFoldersChange}
        confirmButtonIcon={<CheckmarkIcon size="20" />}
        confirmButtonText="Choose Folders"
        hideModal={this.handleHideModal}
        isMultiSelect={true}
      />
    );
  }

  render() {
    const { step, currentModal } = this.state;

    return (
      <Fragment>
        {step === 1 && this.renderFirstStep()}
        {step === 2 && this.renderSecondStep()}
        {step === 3 && this.renderThirdStep()}
        {currentModal === MODAL_FOLDER_TREE &&
          this.renderMultiSelectFolderTreeModal()}
        {currentModal === MODAL_DEPARTMENT_TREE &&
          this.renderSelectDepartmentTreeModal()}
      </Fragment>
    );
  }
}

AlertModal.defaultProps = {
  alertId: null,
  step: 1,
  active: true,
  name: '',
  message: '',
  occurrenceInterval: '',
  occurrencePeriod: OCCURRENCE_PERIOD_OPTIONS.find(
    (obj) => obj.value === 'Hour',
  ),
  startDate: formatDate(new Date()),
  occurrenceEndTimespan: OCCURRENCE_END_TIMESPAN_OPTIONS.find(
    (obj) => obj.value === 'never',
  ),
  occurrenceEndDate: null,
  occurrenceEndLimit: '',
  folders: [],
  filters: [],
  users: [],
  excludedUsers: [],
  userFields: [],
  departments: [],
  editAccess: false,
  isDuplicated: false,
};

AlertModal.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAlertAction: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  alertId: PropTypes.number,
  step: PropTypes.number,
  active: PropTypes.bool,
  name: PropTypes.string,
  message: PropTypes.string,
  occurrenceInterval: PropTypes.string,
  occurrencePeriod: PropTypes.object,
  startDate: PropTypes.string,
  occurrenceEndTimespan: PropTypes.object,
  occurrenceEndDate: PropTypes.string,
  occurrenceEndLimit: PropTypes.string,
  folders: PropTypes.array,
  filters: PropTypes.array,
  users: PropTypes.array,
  excludedUsers: PropTypes.array,
  departments: PropTypes.array,
  editAccess: PropTypes.bool,
  isDuplicated: PropTypes.bool,
};

const mapStateToProps = ({ currentUser }) => ({ currentUser });

export default injectSheet(styles)(
  connect(mapStateToProps)(withFlags(AlertModal)),
);
