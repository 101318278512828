import { isEqual, noop, uniq } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  getRevisionText,
  useDocumentEditorContext,
} from '~/components/Shared/DocumentEditor';
import type {
  DocumentEditorType,
  Revision,
} from '~/components/Shared/DocumentEditor';
import { PAGINATION_FOCUS_DEBOUNCE_MS } from '~/constants/debounces';
import { DEFAULT_PAGINATION_SIZE } from '~/constants/revisionsPanel';
import {
  ContentContainer,
  Layout,
  Paginate,
  Panel_DEPRECATED,
  scrollElementIntoView,
  SearchInput,
  testHasTextMatch,
  types,
  useThrottledState,
} from '~/eds';
import { Nullable, Uuid } from '~/types';

import { RevisionItem } from './RevisionItem';
import { DEFAULT_FILTER, RevisionsFilter } from './RevisionsFilter';
import type { Filters } from './RevisionsFilter';
import { RevisionProps } from './types';

interface Props {
  width?: 'm' | 'l' | 'fill';
  actions?: types.Action[];
  documentEditor: Nullable<DocumentEditorType>;
  disabled: boolean;
  revisions: RevisionProps | undefined;
  onRevisionClick?: (revision: string) => void;
}

export function RevisionsPanel({
  actions,
  width = 'm',
  revisions,
  disabled,
  onRevisionClick,
}: Props) {
  //state
  const [throttledSearch, search, setSearch] = useThrottledState<
    Nullable<string>
  >('');
  const [activeRevision, setActiveRevision] = React.useState<Nullable<Uuid>>(
    null,
  );
  const [page, setPage] = useState(1);
  const trackChangesContainer = useRef<HTMLDivElement>(null);

  const { isDocumentReady, documentEditor } = useDocumentEditorContext();
  const [filter, setFilter] = useState<Filters>(DEFAULT_FILTER);

  useEffect(() => {
    if (isDocumentReady) {
      documentEditor?.documentHelper.viewerContainer.addEventListener(
        'mouseup',
        onEditorMouseUp,
      );
    }

    return () =>
      documentEditor?.documentHelper?.viewerContainer.removeEventListener(
        'mouseup',
        onEditorMouseUp,
      );
  }, [isDocumentReady]);

  useEffect(() => {
    if (activeRevision) {
      setTimeout(() => {
        scrollElementIntoView(activeRevision, { block: 'nearest' });
      }, PAGINATION_FOCUS_DEBOUNCE_MS);
      onRevisionClick?.(activeRevision);
    }
  }, [activeRevision]);

  const filteredRevisions = useMemo(() => {
    if (!throttledSearch && isEqual(filter, DEFAULT_FILTER))
      return revisions?.changes;

    return revisions?.changes?.filter(
      (revision) =>
        (!throttledSearch ||
          testHasTextMatch(getRevisionText(revision), throttledSearch)) &&
        (filter.author === 'all' || revision.author === filter.author) &&
        (filter.changeType === 'all' ||
          testHasTextMatch(revision.revisionType, filter.changeType)),
    );
  }, [throttledSearch, filter, revisions]);

  function onEditorMouseUp() {
    //@ts-ignore
    const editorActiveRevisions = documentEditor?.selection.getCurrentRevision();
    if (editorActiveRevisions && editorActiveRevisions.length) {
      const currentRevision = editorActiveRevisions[0];
      const revisionPage = getRevisionPage(currentRevision);
      setPage(revisionPage);
      setActiveRevision(currentRevision.revisionID);
    } else {
      setActiveRevision(null);
    }
  }

  function onClick(revision: Revision) {
    if (documentEditor) {
      documentEditor.selection.selectRevision(revision);
      // clear selection. For some reason, selection.clear() doesnt work here.
      documentEditor.selection.select(
        documentEditor.selection.startOffset,
        documentEditor.selection.startOffset,
      );
      setActiveRevision(revision.revisionID);
    }
  }

  function getAuthors() {
    return uniq(revisions?.changes.map((change) => change.author));
  }

  const handlePageChange = (pageIndex: number) => {
    setPage(pageIndex);
    if (trackChangesContainer.current) {
      trackChangesContainer.current.scrollTop = 0;
    }
  };

  const handleSearch = (search: Nullable<string>) => {
    setSearch(search);
    setPage(1);
  };

  const getRevisionPage = (revision: Revision) => {
    const index = filteredRevisions?.findIndex(
      (r) => r.revisionID === revision.revisionID,
    );
    let page = Math.ceil((index ?? 1) / DEFAULT_PAGINATION_SIZE);
    page = page > 0 ? page : 1;
    return index ? page : 1;
  };

  if (!revisions || revisions.changes.length === 0) {
    // TODO: use `eds.Panel[placeholderContent]` directly
    return (
      <ContentContainer
        placeholderContent={{
          icon: 'review',
          message: 'There are no changes to be shown',
        }}
      />
    );
  }
  return (
    // eslint-disable-next-line react/jsx-pascal-case -- deprecating
    <Panel_DEPRECATED
      actions={actions}
      isEmbedded
      isVisible
      title="Track Changes"
      width={width}
      onHide={noop}
    >
      <Layout h="100%" py={4} direction="column" spacing={2}>
        <Layout justify="space-between" px={4}>
          <Layout w="100%" direction="column">
            <SearchInput
              name="search"
              placeholder="Search Activities"
              value={search}
              onChange={handleSearch}
            />
          </Layout>
          <RevisionsFilter
            filters={filter}
            onUpdateFilters={setFilter}
            authors={getAuthors()}
            revisions={revisions}
            disabled={disabled}
          />
        </Layout>
        <div
          ref={trackChangesContainer}
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
          }}
        >
          {filteredRevisions?.length ? (
            filteredRevisions
              .slice(
                (page - 1) * DEFAULT_PAGINATION_SIZE,
                page * DEFAULT_PAGINATION_SIZE,
              )
              .map((revision: Revision, index) => (
                <RevisionItem
                  id={revision.revisionID}
                  key={revision.revisionID}
                  isActive={activeRevision === revision.revisionID}
                  onClick={() => onClick(revision)}
                  revision={revision}
                  disabled={disabled}
                  index={`Changes ${index + 1}/${revisions.changes.length}`}
                  text={getRevisionText(revision)}
                />
              ))
          ) : (
            <ContentContainer
              placeholderContent={{
                icon: 'search',
                message: 'Search returned no results',
              }}
            />
          )}
        </div>
        <Layout direction="column" alignSelf="flex-end">
          <Paginate
            name="revisions paginate"
            pageIndex={page}
            pageSize={DEFAULT_PAGINATION_SIZE}
            totalCount={filteredRevisions?.length ?? 0}
            onUpdatePageIndex={handlePageChange}
          />
        </Layout>
      </Layout>
    </Panel_DEPRECATED>
  );
}
