import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

function getMomentDuration(datetime) {
  return moment.duration(moment().diff(datetime));
}

function formatDuration(momentDuration, datetime) {
  if (momentDuration.asHours() >= 35) {
    return moment().subtract(momentDuration).format('YYYY-MM-DD [at] h:mm A');
  } else if (
    momentDuration.asHours() >= 21.5 &&
    Math.floor(momentDuration.asHours()) < 35
  ) {
    return moment(datetime).calendar().toLowerCase();
  } else if (momentDuration.asHours() > 1) {
    return moment().subtract(momentDuration).fromNow();
  } else if (momentDuration.asMinutes() > 1) {
    return moment().subtract(momentDuration).fromNow();
  } else {
    return 'less than a minute ago';
  }
}

const SECOND = 1000;

function Datetime({ datetime, format = 'YYYY-MM-DD [at] h:mm A' }) {
  const [momentDuration, setMomentDuration] = useState(
    getMomentDuration(datetime),
  );

  useEffect(() => {
    let intervalId;

    if (format === 'duration') {
      setMomentDuration(getMomentDuration(datetime));

      intervalId = setInterval(() => {
        setMomentDuration(getMomentDuration(datetime));
      }, 60 * SECOND);
    }

    return () => clearInterval(intervalId);
  }, [datetime, format]);

  const formattedDatetime =
    format === 'duration'
      ? formatDuration(momentDuration, datetime)
      : moment(datetime).format(format);

  return formattedDatetime;
}

Datetime.propTypes = {
  datetime: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  format: PropTypes.string,
};

export default Datetime;
