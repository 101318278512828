import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect } from 'react';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom';
import {
  useBlockLayout,
  useResizeColumns,
  useRowSelect,
  useRowState,
  useTable,
} from 'react-table';

import ActionsMenu from '~/components/Shared/ActionsMenu';
import EcCheckbox from '~/components/Shared/EcCheckbox';
import {
  getTableColumnWidthName,
  persistTableColumnWidth,
} from '~/components/Shared/ReactTable/ReactTable.utils';
import { Button, Icon } from '~/eds';
import { getStorageItem } from '~/utils/browser';

import { black3 } from '../../../assets/shared-styles/general';
import {
  MODAL_DELETE,
  MODAL_DOCUMENT_GROUP,
  MODAL_DOCUMENTS_COLUMN_FIELDS,
  MODAL_DOCUMENTS_COLUMN_FIELDS_SAVE,
  MODAL_DOCUMENTS_COLUMN_FIELDS_VIEW_SWITCH,
  MODAL_FOLDER_TREE,
  MODAL_MULTI_EDIT,
} from '../../../types/modal.types';
import { documentTypeIcon } from '../../DocumentsPage/Document.utils';
import EcTooltip from '../../Shared/EcTooltip';
import CopyToClipboardIcon from '../../Shared/Icons/CopyToClipboardIcon';
import EditIcon from '../../Shared/Icons/EditIcon';
import FolderIcon from '../../Shared/Icons/FolderIcon';
import FolderLockIcon from '../../Shared/Icons/FolderLockIcon';
import FolderViewIcon from '../../Shared/Icons/FolderViewIcon';
import TreeIcon from '../../Shared/Icons/TreeIcon';
import styles from './DocumentsTable.styles';

function SelectAllMessage(props) {
  const {
    classes,
    rows,
    folderItemsCount,
    selectedItems,
    selectedItemsList,
    handleOnSelectAllClick,
    isAllSelected,
  } = props;

  const isPageSelected = selectedItemsList.length === rows.length;
  const itemsCount = isAllSelected ? folderItemsCount : selectedItems.length;

  return (
    <div className={classes.selectAllMessage}>
      {`${itemsCount} ${pluralize('item', itemsCount)} selected`}
      {isPageSelected && !isAllSelected && folderItemsCount > itemsCount && (
        <button
          className={classes.selectAllContent}
          onClick={() => handleOnSelectAllClick(true)}
        >{`Select all ${folderItemsCount} ${pluralize(
          'item',
          folderItemsCount,
        )}`}</button>
      )}
    </div>
  );
}

function HeaderAddtoGroupAction(props) {
  const { classes, selectedItemsList, handleShowModal } = props;
  const hasFolderSelected = selectedItemsList.some(
    (item) => item.original.type === 'folder',
  );

  if (hasFolderSelected) {
    return (
      <Fragment>
        <div
          className={classes.editActionForbidden}
          data-tip
          data-for="helpAddtoGroupTooltip"
        >
          <TreeIcon blue size="20" />
          Add to Group
        </div>
        <EcTooltip id="helpAddtoGroupTooltip" width="240px" place="bottom">
          To Add to Group, make sure your selection does not include folders
        </EcTooltip>
      </Fragment>
    );
  }
  return (
    <button
      aria-haspopup="true"
      className={classes.editAction}
      onClick={() => handleShowModal(MODAL_DOCUMENT_GROUP, selectedItemsList)}
    >
      <TreeIcon blue size="20" />
      Add to Group
    </button>
  );
}

function HeaderEditAction(props) {
  const { classes, selectedItemsList, handleShowModal } = props;

  const movableItems = selectedItemsList.filter(
    (item) => item.original.isMovable,
  );

  return selectedItemsList.length === movableItems.length ? (
    <button
      aria-haspopup="true"
      className={classes.editAction}
      onClick={() =>
        handleShowModal(MODAL_MULTI_EDIT, selectedItemsList, 'edit')
      }
    >
      <EditIcon blue size="20" />
      Edit
    </button>
  ) : (
    <Fragment>
      <div
        className={classes.editActionForbidden}
        data-tip
        data-for="helpEditTooltip"
      >
        <EditIcon blue size="20" />
        Edit
      </div>
      <EcTooltip id="helpEditTooltip" width="240px" place="bottom">
        You do not have privileges to edit one or more of the items you have
        selected.
      </EcTooltip>
    </Fragment>
  );
}

function HeaderCopyAction(props) {
  const { classes, selectedItemsList, handleShowModal } = props;

  const movableItems = selectedItemsList.filter(
    (item) => item.original.isMovable,
  );

  return selectedItemsList.length === movableItems.length ? (
    <button
      aria-haspopup="true"
      className={classes.copyAction}
      onClick={() =>
        handleShowModal(MODAL_FOLDER_TREE, selectedItemsList, 'Copy')
      }
    >
      <CopyToClipboardIcon blue size="20" />
      Copy
    </button>
  ) : (
    <Fragment>
      <div
        className={classes.copyActionForbidden}
        data-tip
        data-for="helpCopyTooltip"
      >
        <CopyToClipboardIcon blue size="20" />
        Copy
      </div>
      <EcTooltip id="helpCopyTooltip" width="240px" place="bottom">
        You do not have privileges to copy one or more of the items you have
        selected.
      </EcTooltip>
    </Fragment>
  );
}

function HeaderMoveAction(props) {
  const { classes, selectedItemsList, handleShowModal } = props;
  const movableItems = selectedItemsList.filter(
    (item) => item.original.isMovable,
  );

  return selectedItemsList.length === movableItems.length ? (
    <button
      aria-haspopup="true"
      className={classes.moveAction}
      onClick={() =>
        handleShowModal(MODAL_FOLDER_TREE, selectedItemsList, 'Move')
      }
    >
      <FolderIcon blue size="20" />
      Move
    </button>
  ) : (
    <Fragment>
      <div
        className={classes.moveActionForbidden}
        data-tip
        data-for="helpMoveTooltip"
      >
        <FolderIcon blue size="20" />
        Move
      </div>
      <EcTooltip id="helpMoveTooltip" width="240px" place="bottom">
        You do not have privileges to move one or more of the items you have
        selected.
      </EcTooltip>
    </Fragment>
  );
}

function HeaderDeleteAction(props) {
  const { selectedItemsList, handleShowModal } = props;
  const movableItems = selectedItemsList.filter(
    (item) => item.original.isMovable,
  );
  const containSyncpairs = selectedItemsList.filter(
    (item) => item.original.contains_sync_pair,
  );

  const disabled =
    selectedItemsList.length !== movableItems.length ||
    selectedItemsList.length === containSyncpairs.length;

  const tooltip =
    selectedItemsList.length === containSyncpairs.length
      ? 'The selected item(s) contain synchronizations with external storage providers, please contact your Evisort admin to stop synchronization before deleting the selected item(s)'
      : selectedItemsList.length !== movableItems.length
      ? ' You do not have privileges to delete one or more of the items you have selected.'
      : undefined;

  return (
    <Button
      aria-haspopup="true"
      text="Delete"
      variant="action"
      onClick={() => handleShowModal(MODAL_DELETE, selectedItemsList, 'delete')}
      tooltip={tooltip}
      disabled={disabled}
    />
  );
}

function DocumentGroup(props) {
  const { classes, item } = props;

  if (item.groups && item.groups.length) {
    return (
      <Link
        className={classes.groupIcon}
        to={`/document-group/${item.groups[0].groupId}`}
      >
        <span
          data-tip
          data-for={`documents_row_group_name?name=${item.groups[0].name}`}
          id={`documents_row_group_name?name=${item.groups[0].name}`}
        >
          <TreeIcon size="18" color={black3} />
          <label className="screenReaderText">
            document group link {item.groups[0].name}
          </label>
        </span>
        <div>
          <EcTooltip
            delayShow={500}
            id={`documents_row_group_name?name=${item.groups[0].name}`}
            width="260px"
            place="bottom"
          >
            <div>
              <span className={classes.tooltipContentHeader}>Group name:</span>
            </div>
            {item.groups[0].name}
          </EcTooltip>
        </div>
      </Link>
    );
  } else return null;
}

function DocumentNameType(props) {
  const { classes, item } = props;
  const visibilityLevel =
    item.content_visibility_level || item.inherited_visibility_level;

  return (
    <span className={classes.documentGroupIcon}>
      <span className={classes.documentIcon}>
        {item.type === 'document' ? (
          documentTypeIcon(item.fileType)
        ) : visibilityLevel === 'RESTRICTED' ||
          visibilityLevel === 'NOT_PERMITTED' ? (
          <FolderLockIcon />
        ) : visibilityLevel === 'OPEN_NO_EDIT' ||
          visibilityLevel === 'OPEN_NO_EDIT_DOWNLOAD' ? (
          <FolderViewIcon />
        ) : (
          <FolderIcon size="24" yellow />
        )}
      </span>
      <span className={classes.documentName}>{item.name}</span>
    </span>
  );
}

function Table(props) {
  const {
    classes,
    columns,
    data,
    folderItemsCount,
    sortField,
    order,
    handleSortClick,
    handleShowModal,
    handleRowSelect,
    handleOnSelectAllClick,
    isAllSelected,
    selectedItems,
  } = props;

  let autoResetSelectedRowsFlag = false;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleRowSelected,
    state: { columnResizing },
  } = useTable(
    {
      columns,
      data,
      disableMultiSort: true,
      autoResetSelectedRows: autoResetSelectedRowsFlag,
      initialState: { selectedFlatRows: null },
      manualSorting: true,
    },
    useRowSelect,
    useRowState,
    useBlockLayout,
    useResizeColumns,
  );

  useEffect(() => {
    persistTableColumnWidth(columnResizing, 'documents');
  }, [columnResizing]);

  autoResetSelectedRowsFlag =
    selectedFlatRows && selectedFlatRows.length ? false : true;

  const selectedFlatRowsIds = selectedFlatRows
    .map((row) => row.original.id)
    .join(',');
  useEffect(
    () => {
      handleRowSelect(selectedFlatRows);
    },
    [selectedFlatRowsIds], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    () => {
      const hasItemsSelected = selectedItems && selectedItems.length > 0;

      if (hasItemsSelected) {
        const ids = selectedItems.map((item) => item.id);
        const selectedRowsData = rows.filter((d) =>
          ids.includes(d.original.id),
        );
        selectedRowsData.forEach((row) => {
          toggleRowSelected(row.id, true);
        });
      }
    },
    [rows, selectedItems], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const forcedMinWidthClass = columns.length < 12 ? 'forcedMinWidthClass' : '';

  function getSortArrow(column) {
    if (sortField === column.id) {
      if (column.id === 'name' || sortField === column.Header) {
        return order === 'desc' ? (
          <Icon icon="arrow-down" label="sort descending" />
        ) : (
          <Icon icon="arrow-up" label="sort ascending" />
        );
      }
    } else {
      return <span aria-label="not sorted" />;
    }
  }

  return (
    <div className={classes.tableWrap}>
      <div {...getTableProps()} className={classes.documentTable}>
        {selectedItems.length ? (
          <div className={forcedMinWidthClass}>
            {headerGroups.map((headerGroup) => {
              return (
                <div
                  {...headerGroup.getHeaderGroupProps()}
                  className={classes.documentTableHeader}
                >
                  {[headerGroup.headers[0]].map((column) => (
                    <div
                      className={classes.documentTableThead}
                      key="selectAllCheckbox"
                    >
                      {column.render('Header')}
                    </div>
                  ))}

                  <div className={classes.theadBulkActionMenu}>
                    <HeaderAddtoGroupAction
                      classes={classes}
                      selectedItemsList={selectedFlatRows}
                      handleShowModal={handleShowModal}
                    />
                    <HeaderMoveAction
                      classes={classes}
                      selectedItemsList={selectedFlatRows}
                      handleShowModal={handleShowModal}
                    />
                    <HeaderCopyAction
                      classes={classes}
                      selectedItemsList={selectedFlatRows}
                      handleShowModal={handleShowModal}
                    />
                    <HeaderEditAction
                      classes={classes}
                      selectedItemsList={selectedFlatRows}
                      handleShowModal={handleShowModal}
                    />
                    <HeaderDeleteAction
                      classes={classes}
                      selectedItemsList={selectedFlatRows}
                      handleShowModal={handleShowModal}
                    />
                    <SelectAllMessage
                      classes={classes}
                      rows={rows}
                      folderItemsCount={folderItemsCount}
                      selectedItemsList={selectedFlatRows}
                      selectedItems={selectedItems}
                      handleOnSelectAllClick={handleOnSelectAllClick}
                      isAllSelected={isAllSelected}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <>
            {headerGroups.map((headerGroup) => (
              <div
                {...headerGroup.getHeaderGroupProps()}
                className={classes.documentTableHeader}
              >
                {headerGroup.headers.map((column) => {
                  if (column.id === 'actionMenu') {
                    return (
                      <span
                        key={column.id}
                        className={classes.actionMenuHeader}
                        role="columnheader"
                      />
                    );
                  } else if (column.id === 'selectAll') {
                    return (
                      <span
                        key={column.id}
                        className={classes.documentTableThead}
                        role="columnheader"
                      >
                        {column.render('Header')}
                      </span>
                    );
                  } else if (column.id === 'cog') {
                    return (
                      <span
                        key={column.id}
                        className={classes.documentTableThead}
                      >
                        {column.render('Header')}
                      </span>
                    );
                  }
                  return (
                    <div key={column.id} className={classes.documentTableThead}>
                      <button
                        {...column.getHeaderProps()}
                        aria-label={`${column.render(
                          'Header',
                        )} sortable column`}
                        className={classes.documentTableTheadButton}
                        onClick={() => handleSortClick(column.id)}
                      >
                        <span className={classes.headerText}>
                          {column.render('Header')}
                        </span>

                        <span className={classes.documentTableSortIcon}>
                          {getSortArrow(column)}
                        </span>
                      </button>
                      <button
                        {...column.getResizerProps()}
                        className={classes.documentTableColumnResizer}
                        title="resize column"
                      />
                    </div>
                  );
                })}
              </div>
            ))}
          </>
        )}

        <div {...getTableBodyProps()} className={classes.documentTableRow}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className={forcedMinWidthClass}>
                {row.cells.map((cell) => {
                  return (
                    <div
                      {...cell.getCellProps()}
                      className={classes.documentTableTd}
                    >
                      {cell.render('Cell')}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function DocumentsTable(props) {
  const {
    classes,
    handleOnSelectPageClick,
    handleSelectRowScreenReader,
    onNavigate,
  } = props;

  const hardColumns = [
    {
      id: 'selectAll',
      Header: ({ getToggleAllRowsSelectedProps }) => {
        const toggleProps = { ...getToggleAllRowsSelectedProps() };
        const checkboxChecked = getToggleAllRowsSelectedProps().checked;

        return (
          <div className={classes.documentTableCheckboxContainer}>
            <EcCheckbox
              {...toggleProps}
              checked={checkboxChecked}
              id="selectAllCheckBox"
              onClick={(e) => {
                handleOnSelectPageClick(!checkboxChecked);
                toggleProps.onChange(e);

                const srStatus = `Select All Rows checkbox ${
                  checkboxChecked ? 'unchecked' : 'checked'
                }`;
                handleSelectRowScreenReader(srStatus);
              }}
            />
            <label htmlFor="selectAllCheckBox" className="screenReaderText">
              Select All Rows {checkboxChecked ? 'checked' : 'unchecked'}
            </label>
          </div>
        );
      },
      Cell: ({ row }) => {
        const toggleProps = { ...row.getToggleRowSelectedProps() };
        const checkboxId = row.original.name.replace(/\s/g, '-');
        return (
          <div className={classes.documentTableCheckboxContainer}>
            <EcCheckbox
              id={`selectRow-${checkboxId}`}
              {...toggleProps}
              checked={row.getToggleRowSelectedProps().checked}
              onClick={(e) => {
                toggleProps.onChange(e);
                const srStatus = `Select ${checkboxId} checkbox ${
                  row.getToggleRowSelectedProps().checked
                    ? 'unchecked'
                    : 'checked'
                }`;
                handleSelectRowScreenReader(srStatus);
              }}
            />
            <label
              htmlFor={`selectRow-${checkboxId}`}
              className="screenReaderText"
            >
              Select {row.original.name}
            </label>
          </div>
        );
      },
      width: 40,
    },
    {
      Header: '',
      accessor: 'actionMenu',
      Cell: ({ row }) => {
        return (
          <span className={classes.documentTableTheadActionMenu}>
            {row.original.actionMenu}
          </span>
        );
      },
      width: 50,
    },
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ row }) => {
        const itemUrl =
          row.original.type === 'document'
            ? `/document/${row.original.id}`
            : `/documents/${row.original.id}`;
        return (
          <Fragment>
            <Link
              onClick={() => onNavigate(row.original)}
              data-tip
              data-for={`tooltip_documents_row_name?${row.original.type}=${row.original.id}`}
              aria-label={`${row.original.type} ${row.original.name}`}
              to={itemUrl}
              id={`documents_row_name?${row.original.type}=${row.original.id}`}
              className={classes.folderCell}
            >
              <DocumentNameType classes={classes} item={row.original} />
            </Link>
            <div aria-hidden="true">
              <EcTooltip
                delayShow={500}
                id={`tooltip_documents_row_name?${row.original.type}=${row.original.id}`}
                width="260px"
                place="bottom"
              >
                <div>
                  <span className={classes.tooltipContentHeader}>Name:</span>
                </div>
                {row.original.name}
              </EcTooltip>
            </div>
            <DocumentGroup classes={classes} item={row.original} />
          </Fragment>
        );
      },
      width: getStorageItem('documents_table_name_column_width') || 300,
      minWidth: 180,
      maxWidth: 900,
    },
  ];

  const dynamicColumns = props.columns.map((column, index) => {
    return {
      Header: column.field_name,
      accessor: column.field_name,
      Cell: ({ row }) => {
        const rowItemContent = row.original[index]
          ? Object.values(row.original[index])[0].replace(/[\r\n]/gm, ' ')
          : '';
        return (
          <Fragment>
            <span data-tip data-for={rowItemContent}>
              {row.original[index] ? Object.values(row.original[index]) : null}
            </span>
            {row.original[index] &&
            Object.values(row.original[index])[0].length ? (
              <div>
                <EcTooltip
                  delayShow={500}
                  id={rowItemContent}
                  width="260px"
                  place="left"
                >
                  <div>
                    <span className={classes.tooltipContentHeader}>
                      {column.field_name}:
                    </span>
                  </div>
                  {rowItemContent}
                </EcTooltip>
              </div>
            ) : null}
          </Fragment>
        );
      },
      width:
        getStorageItem(
          getTableColumnWidthName('documents', column.field_name),
        ) || 150,
      minWidth: 60,
      maxWidth: 400,
    };
  });

  const getColumnSettingMenuItems = () => {
    const items = [];

    items.push({
      id: 'edit_columns_menu_item',
      content: 'Edit Columns',
      onClick: () => props.handleShowModal(MODAL_DOCUMENTS_COLUMN_FIELDS),
    });

    items.push({
      id: 'edit_columns_menu_item',
      content: 'Save View',
      onClick: () => props.handleShowModal(MODAL_DOCUMENTS_COLUMN_FIELDS_SAVE),
    });

    items.push({
      id: 'edit_columns_menu_item',
      content: 'Load Views',
      onClick: () =>
        props.handleShowModal(MODAL_DOCUMENTS_COLUMN_FIELDS_VIEW_SWITCH),
    });

    return items;
  };

  const columnsMapped = React.useMemo(
    () => [
      // Let's make a column for selection
      ...hardColumns,
      ...dynamicColumns,

      {
        id: 'cog',
        accessor: '',
        disableSortBy: true,
        Cell: () => <span />,
        Header: () => {
          return (
            <div className={classes.documentTableGearWrapper}>
              <ActionsMenu
                align="end"
                aria-label="Documents column settings"
                title="Documents column settings"
                id="documents_table_columns_settings"
                icon="cog"
                items={getColumnSettingMenuItems()}
              />
            </div>
          );
        },
        sortable: false,
        width: 25,
      },
    ],
    [dynamicColumns, hardColumns, props], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Table
      classes={classes}
      columns={columnsMapped}
      data={props.data}
      folderItemsCount={props.folderItemsCount}
      sortField={props.sortField}
      order={props.order}
      handleRowSelect={props.handleRowSelect}
      handleSortClick={props.handleSortClick}
      handleShowModal={props.handleShowModal}
      handleOnSelectAllClick={props.handleOnSelectAllClick}
      isAllSelected={props.isAllSelected}
      selectedItems={props.selectedItems}
    />
  );
}

DocumentsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(DocumentsTable);
