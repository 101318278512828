import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from 'uinix-ui';

import {
  ContentContainer,
  formatDate,
  Icon,
  Layout,
  TruncateText,
} from '~/eds';
import { api, selectors } from '~/redux';
import { chatbotSlice } from '~/redux/slices/chatbot';
import { Entity } from '~/types';

export const ConversationHistory = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const currentChatbot = useSelector(selectors.selectCurrentChatbot);
  const currentConversationId = useSelector(
    selectors.selectCurrentConversationId,
  );

  const {
    data: conversations,
    isLoading: isLoadingConversations,
  } = api.endpoints.getConversations.useQuery(
    currentChatbot as Entity<
      'document_handler' | 'document_version' | 'all_documents'
    >,
  );

  const handleConversationClick = (conversationId: string) => {
    dispatch(chatbotSlice.actions.setCurrentConversationId(conversationId));
  };

  const isEmpty = conversations?.items.length === 0;

  return (
    <ContentContainer
      loadingContent={{ isLoading: isLoadingConversations }}
      placeholderContent={
        isEmpty ? { message: 'No conversations found' } : undefined
      }
    >
      <Layout w="sideContent.width" h="100%">
        <Layout direction="column" w="100%">
          {conversations?.items.map((conversation) => {
            const isActive = conversation.id === currentConversationId;
            return (
              <Layout
                key={`conversation_${conversation.id}`}
                px={4}
                py={4}
                borderRadius="m"
                onClick={
                  isActive
                    ? undefined
                    : () => handleConversationClick(conversation.id)
                }
                styles={styles.colors.status}
                styleProps={{
                  status: isActive ? 'active' : undefined,
                }}
              >
                <Icon
                  icon="chatbot"
                  color={isActive ? 'status.active' : undefined}
                />
                <Layout ml={4}>
                  <TruncateText variant="body-medium">
                    {formatDate(new Date(conversation.datetimeCreated), 'full')}
                  </TruncateText>
                </Layout>
              </Layout>
            );
          })}
        </Layout>
      </Layout>
    </ContentContainer>
  );
};
