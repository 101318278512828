import React, { memo } from 'react';
import { useStyles } from 'uinix-ui';

import { ActionMode, IconType, SharedButtonProps } from '../../types';
import { resolvePresetProps } from '../../utils';
import { Icon } from '../Icon';
import { Layout } from '../Layout';
import { Text, TruncateText } from '../Text';
import { Tooltip } from '../Tooltip';
import { presets } from './presets';

interface Props extends SharedButtonProps {
  /** For setting aria-label */
  label?: string;
  preset?: keyof typeof presets;
  PRIVATE_isPadded?: boolean;
}

export const Button = memo((props: Props) => {
  const styles = useStyles();

  const {
    disabled,
    icon: initialIcon,
    iconPosition = 'right',
    isFullWidth: initialIsFullWidth = false,
    isInline = false,
    isLoading = false,
    PRIVATE_isPadded = true,
    label,
    level,
    mode,
    preset,
    shouldTruncate = false,
    size = 'm',
    status,
    text,
    theme,
    tooltip,
    type = 'button',
    variant: initialVariant = 'secondary',
    onClick,
    ...rest
  } = resolvePresetProps(props, presets);

  const isIconMode = mode === 'icon';
  const isAreaMode = mode === 'area';
  const isChipMode = mode === 'chip';
  const variant = level ?? (isAreaMode ? 'ghost' : initialVariant); // always override the variant in specific modes
  const isFullWidth = isAreaMode || initialIsFullWidth;

  const isAction = variant === 'action';
  const isDarkTheme = theme === 'dark';
  const isRightIcon = iconPosition === 'right';
  const isNoop = !Boolean(onClick) && type !== 'submit';

  const as = isNoop ? 'div' : 'button';
  const role = isNoop ? 'button' : undefined;
  const icon = isLoading ? 'loading' : initialIcon;
  const isSmallSize = size === 's' || size === 'xs';

  const { h, pl, pr, w } = resolveStyleProps({
    icon,
    isAction,
    isPadded: PRIVATE_isPadded,
    isFullWidth,
    isRightIcon,
    mode,
    size,
  });

  const buttonIcon = icon && <Icon icon={icon} />;

  const buttonVariantStyle = isDarkTheme
    ? styles.button.dark[variant]
    : styles.button[variant];

  const darkThemeInteractionStyle =
    isDarkTheme && !isAction ? styles.interactionsInverse : undefined;

  const TextComponent = shouldTruncate ? TruncateText : Text;

  const borderRadius = isAreaMode ? 'unset' : isChipMode ? 'pill' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      event.preventDefault();
      event.stopPropagation();
      onClick(event);
    }
  };

  return (
    <Tooltip
      tooltip={tooltip}
      triggerStyle={isFullWidth ? { width: '100%' } : undefined}
    >
      <Layout
        {...rest}
        as={as}
        align="center"
        aria-label={label ?? (isIconMode ? text : undefined)}
        borderRadius={borderRadius}
        data-eds-styles-disable-interactable={isAction}
        disabled={disabled || isLoading}
        h={h}
        inline={isInline}
        justify={isAreaMode ? 'flex-start' : 'center'}
        maxW={shouldTruncate ? 'button.max-width' : undefined}
        pl={pl}
        pr={pr}
        py={0}
        role={role}
        spacing={1}
        styles={[
          styles.button.base,
          status ? styles.colors.status : null,
          status ? null : buttonVariantStyle,
          darkThemeInteractionStyle,
        ]}
        styleProps={{
          status,
        }}
        w={w}
        type={type}
        onClick={handleClick}
      >
        {!isRightIcon && buttonIcon}
        {!isIconMode && (
          <TextComponent
            color="inherit"
            variant={isSmallSize ? 'tiny' : 'body-medium'}
          >
            {text}
          </TextComponent>
        )}
        {isRightIcon && buttonIcon}
      </Layout>
    </Tooltip>
  );
});

const resolveStyleProps = ({
  icon,
  isAction,
  isFullWidth,
  isPadded,
  isRightIcon,
  mode,
  size,
}: {
  icon?: IconType;
  isAction: boolean;
  isFullWidth: boolean;
  isPadded: boolean;
  isRightIcon: boolean;
  mode?: ActionMode;
  size: 'xs' | 's' | 'm' | 'l';
}) => {
  const h = isAction ? undefined : `height.${size}`;
  let w = isFullWidth ? '100%' : undefined;

  let px;
  switch (size) {
    case 'l': {
      px = [8, 8];
      break;
    }
    case 'xs': {
      px = [2, 2];
      break;
    }
    default: {
      px = [4, 4];
    }
  }

  switch (mode) {
    case 'area': {
      px = [0, 0];
      break;
    }
    case 'icon': {
      px = [0, 0];
      w = h; // same size as height
      break;
    }
    default: {
      if (icon) {
        if (size === 's') {
          px = isRightIcon ? [3, 2] : [2, 3];
        } else {
          px = isRightIcon ? [4, 3] : [3, 4];
        }
      }
      break;
    }
  }

  const [pl, pr] = isPadded ? px : [0, 0];

  return { h, pl, pr, w };
};
