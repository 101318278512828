import uuid from 'uuid';

import { types } from '~/eds';
import { parseMarkdownAndExtractInlineSources } from '~/features/ask-anything/';
import {
  Entity,
  EntityType,
  Nullable,
  Query,
  SearchEntity,
  Uuid,
} from '~/types';

import {
  createNewConversation,
  getAllConversations,
  getExistingConversation,
  postNewUserMessage,
  postUserRating,
} from '../methods/conversationalAi';
import {
  ConversationMessage,
  ConversationMessageSource,
  ConversationStreamEvent,
  ExternalEntity,
  Conversation as ServerConversation,
  UserRating,
} from '../types/sylvanus/conversation';
import { camelizeKeys, ParsedType } from './utils';

export type Message = ParsedType<ConversationMessage>;
export type Conversation = ParsedType<ServerConversation>;
export type MessageStreamEvent = ParsedType<ConversationStreamEvent>;
export interface ClientConversation {
  id: Uuid;
  created: string;
  createdBy: string;
  userEmail: string;
  messages: types.Chat.Message[];
  metadata?: {
    documentHash: string;
  };
}

export interface MessageContext {
  search_query?: Query;
  search_entity: SearchEntity;
  selected_ids?: string[];
}
export interface PostUserMessageParams {
  conversationId: Uuid;
  message: string;
  context?: MessageContext[];
  metadata?: {
    questionId: Uuid;
    questionGroupId: Uuid;
  };
}

export interface PostUserFeedbackParams {
  conversationId: Uuid;
  messageId: Uuid;
  feedbackDetails: types.Chat.FeedbackDetails;
}

interface ClientPageConversation {
  items: Omit<Conversation, 'messages'>[];
  total: number;
  page?: number;
  size?: number;
  pages?: number;
}

export const getConversations = async (
  params: Entity<EntityType>,
): Promise<ClientPageConversation> => {
  const serverConversations = await getAllConversations({
    entityId: params.id,
    entityType: params.type as ExternalEntity['type'],
  });
  // TODO remove ts-ignore when server response is fixed
  //@ts-ignore
  return camelizeKeys(serverConversations);
};

export const createConversation = async (
  entity: Entity<EntityType>,
): Promise<Uuid> => {
  const response = await createNewConversation(entity as ExternalEntity);
  return response.id;
};

export const getConversation = async ({
  conversationId,
  isInlineSourcesEnabled = false,
}: {
  conversationId: Uuid;
  isInlineSourcesEnabled: boolean;
}): Promise<ClientConversation> => {
  const response = await getExistingConversation(conversationId);
  const conversation = {
    id: response.id,
    created: response.datetime_created,
    createdBy: response.created_by_user_id,
    userEmail: response.created_by_user_email,
    messages: response.messages
      .map(mapServerMessageToChatMessage)
      .map((message) =>
        mapMessageInlineSources(message, isInlineSourcesEnabled),
      ),
    metadata: response.metadata,
  };
  // TODO remove ts-ignore when server response is fixed
  //@ts-ignore
  return conversation;
};

export const postUserMessage = async ({
  conversationId,
  message,
  context,
  metadata,
}: PostUserMessageParams): Promise<types.Chat.Message> => {
  const response = await postNewUserMessage({
    conversationId,
    message,
    context,
    metadata,
  });
  return mapServerMessageToChatMessage(response);
};

export const postUserFeedback = async ({
  conversationId,
  messageId,
  feedbackDetails,
}: PostUserFeedbackParams): Promise<types.Chat.Message> => {
  const response = await postUserRating({
    conversationId,
    messageId,
    userRating: mapUserRatingFromFeedbackDetails(feedbackDetails),
  });
  return mapServerMessageToChatMessage(response);
};

export const mapServerMessageToChatMessage = (
  response: ConversationMessage,
): types.Chat.Message => ({
  choices: response.response_suggestions ?? [],
  id: response.id,
  sources: (response.sources ?? []).map(
    (source: ConversationMessageSource) => ({
      ...camelizeKeys(source),
      id: uuid.v4(),
    }),
  ),
  text: response.message_text,
  timestamp: new Date(response.datetime_created),
  userType: response.role === 'user' ? 'current' : 'other',
  feedbackDetails: mapFeedbackDetailsFromUserRating(
    response.user_rating ?? null,
  ),
});

const mapMessageInlineSources = (
  message: types.Chat.Message,
  isInlineSourcesEnabled = false,
): types.Chat.Message => {
  if (!isInlineSourcesEnabled) return message;

  const { text, inlineSources } = parseMarkdownAndExtractInlineSources(
    message.text,
  );
  return {
    ...message,
    text,
    inlineSources,
  };
};

export const parseServerMessage = (
  message: Message,
  isInlineSourcesEnabled: boolean,
): types.Chat.Message => {
  const parsedMessage: types.Chat.Message = {
    choices: (message.responseSuggestions ?? []) as string[],
    id: message.id,
    sources: ((message.sources ?? []) as ConversationMessageSource[]).map(
      (source) => ({
        ...camelizeKeys(source),
        id: uuid.v4(),
      }),
    ),
    text: message.messageText,
    timestamp: new Date(message.datetimeCreated),
    userType: message.role === 'user' ? 'current' : 'other',
    feedbackDetails: mapFeedbackDetailsFromUserRating(
      message.userRating ?? null,
    ),
  };

  if (!isInlineSourcesEnabled) return parsedMessage;
  const { text, inlineSources } = parseMarkdownAndExtractInlineSources(
    parsedMessage.text,
  );
  return {
    ...parsedMessage,
    text,
    inlineSources,
  };
};

const mapFeedbackDetailsFromUserRating = (
  rating: Nullable<UserRating>,
): types.Chat.FeedbackDetails => {
  switch (rating) {
    case 'positive':
      return { value: true };
    case 'negative':
      return { value: false };
    default:
      return { value: null };
  }
};

export const mapUserRatingFromFeedbackDetails = (
  details: types.Chat.FeedbackDetails,
): Nullable<UserRating> => {
  if (!details) return null;
  const { value } = details;
  switch (value) {
    case true:
      return 'positive';
    case false:
      return 'negative';
    default:
      return null;
  }
};
