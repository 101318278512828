import PropTypes from 'prop-types';
import React, { useState } from 'react';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom';

import { activateUserAccount } from '~/api';
import {
  black2,
  evisortBlue,
  evisortRed,
  evisortYellow,
  semibold,
} from '~/assets/shared-styles/general';
import {
  getDecodedUserInfoFromLoginToken,
  LoginPageContainer,
  LoginPassword,
} from '~/components/LoginPage';
import { trackSegment } from '~/components/SegmentAnalytics';
import LoadingSpinner from '~/components/Shared/Icons/LoadingSpinner';
import { formatDate } from '~/eds';
import { LoginViewType, QueryParamType } from '~/enums';
import { useAsync, usePasswordsWithValidation } from '~/hooks';
import { getPageSearchQueryByKey } from '~/utils/searchQuery';

function ErrorMessageView({ classes }) {
  return (
    <div className={classes.errorMessage}>
      An error occurred. Please <Link to="/">go here</Link> and try again.
    </div>
  );
}

function ChangedPasswordView() {
  return (
    <div>
      Your password has been successfully updated. You will be redirected to the
      platform. If not, go to <Link to="/">login page</Link> and login to the
      platform.
    </div>
  );
}

function NoTokenView({ classes }) {
  return (
    <div className={classes?.errorMessage}>
      No valid reset password link found.
    </div>
  );
}

function FormView({
  classes,
  firstName,
  setFirstNameChange,
  lastName,
  setLastNameChange,
  jobTitle,
  setJobTitleChange,
  submitResetPassword,
  ...rest
}) {
  const { validationResult } = rest;
  return (
    <div className={classes.formContainer}>
      <form onSubmit={submitResetPassword}>
        <label className={classes.loginFormLabel} htmlFor="firstName">
          First name
        </label>
        <div className={classes.inputContainer}>
          <input
            autoFocus={true}
            className={classes.textInput}
            defaultValue={firstName}
            id="firstName"
            type="text"
            onChange={(e) => setFirstNameChange(e.target.value)}
          />
        </div>
        <label className={classes.loginFormLabel} htmlFor="lastName">
          Last name
        </label>
        <div className={classes.inputContainer}>
          <input
            defaultValue={lastName}
            id="lastName"
            type="text"
            onChange={(e) => setLastNameChange(e.target.value)}
            className={classes.textInput}
          />
        </div>
        <label className={classes.loginFormLabel} htmlFor="jobTitle">
          Job title
        </label>
        <div className={classes.inputContainer}>
          <input
            className={classes.textInput}
            defaultValue={jobTitle}
            id="jobTitle"
            type="text"
            onChange={(e) => setJobTitleChange(e.target.value)}
          />
        </div>
        <LoginPassword
          passwordLabel="Create password"
          confirmPasswordLabel="Verify password"
          {...rest}
        />
        <div className={classes.footnote}>
          By clicking below, you confirm that you agree to use the platform in
          accordance with the Evisort{' '}
          <a
            href="https://www.evisort.com/terms/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service.
          </a>
        </div>
        <div className={classes.formButtonContainer}>
          <button
            className={classes.formButton}
            disabled={!validationResult.allValid}
            type="submit"
            onClick={submitResetPassword}
          >
            Start using Evisort
          </button>
        </div>
      </form>
    </div>
  );
}

function Page(props) {
  const { classes } = props;

  const token = getPageSearchQueryByKey(QueryParamType.Token);
  const [
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    validationResult,
  ] = usePasswordsWithValidation();
  const [view, setView] = useState();
  const [firstName, setFirstNameChange] = useState();
  const [lastName, setLastNameChange] = useState();
  const [jobTitle, setJobTitleChange] = useState();

  const formData = {
    first_name: firstName,
    last_name: lastName,
    job_title: jobTitle,
    new_password: password,
  };

  function submitResetPassword(e) {
    e.preventDefault();
    if (!token) setView(LoginViewType.TokenMissing);
    else {
      setView(LoginViewType.FormSubmitting);
      handleFirstTimeLoginFormSubmit();
    }
  }

  const { executor: handleFirstTimeLoginFormSubmit } = useAsync(
    activateUserAccount,
    { token, formData },
    {
      successHandler: async (response) => {
        setView(LoginViewType.FormSubmitted);
        if (response?.msg) {
          const data = response.msg;
          try {
            const { client_id, user_id } = getDecodedUserInfoFromLoginToken(
              data,
            );
            trackSegment('User First Login', {
              groupId: client_id,
              userId: `${user_id}_${client_id}`,
              firstLoginDate: formatDate(new Date(), 'utc'),
            });
          } catch (error) {
            console.err(error);
            throw error;
          }
          setTimeout(() => {
            window.location.href = data;
          }, 1000);
        }
      },
      errorHandler: () => {
        setView(LoginViewType.Error);
      },
    },
  );

  function renderView() {
    switch (view) {
      case LoginViewType.Error:
        return <ErrorMessageView classes={classes} />;
      case LoginViewType.FormSubmitting:
        return <LoadingSpinner />;
      case LoginViewType.FormSubmitted:
        return <ChangedPasswordView />;
      case LoginViewType.TokenMissing:
        return <NoTokenView classes={classes} />;
      default:
        return (
          <FormView
            classes={classes}
            firstName={firstName}
            setFirstNameChange={setFirstNameChange}
            lastName={lastName}
            setLastNameChange={setLastNameChange}
            jobTitle={jobTitle}
            setJobTitleChange={setJobTitleChange}
            submitResetPassword={submitResetPassword}
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            validationResult={validationResult}
          />
        );
    }
  }

  return (
    <LoginPageContainer title="Welcome to Evisort!">
      {renderView()}
    </LoginPageContainer>
  );
}

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

const loginFormLabel = {
  display: 'block',
  fontSize: '15px',
  fontWeight: semibold,
  textAlign: 'left',
};

const styles = {
  formContainer: {
    margin: '40px auto',
    width: '360px',
    '& a': {
      color: evisortBlue,
      textDecoration: 'none',
      '&:visited': {
        color: evisortBlue,
      },
    },
  },
  formButtonContainer: {
    marginTop: '40px',
  },
  formButton: {
    padding: '10px 20px',
    width: '350px',
    display: 'inline-block',
    backgroundColor: evisortYellow,
    borderRadius: '3px',
    border: 'solid 1px rgba(0, 0, 0, 0.25)',
    color: black2,
    fontSize: '15px',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:disabled': {
      opacity: 0.5,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  errorMessage: {
    margin: '20px',
    color: evisortRed,
    fontSize: '15px',
  },
  loginFormLabel: {
    ...loginFormLabel,
  },
  loginFormLabelDisabled: {
    ...loginFormLabel,
    opacity: 0.5,
  },
  textInput: {
    margin: '10px 0',
    padding: '10px',
    width: '360px',
    border: '1px solid #ccc',
    borderRadius: '3px',
    color: black2,
    fontSize: '16px',
    '&:placeholder': {
      opacity: 0.5,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  inputContainer: {
    marginBottom: '12px',
    position: 'relative',
    display: 'inline-block',
  },
  footnote: {
    marginTop: '24px',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'left',
  },
};

export default injectSheet(styles)(Page);
