import { useMemo, useState } from 'react';
import { connect } from 'react-redux';

import {
  workflowAddDataFieldsMapping,
  workflowRemoveDataFieldsMapping,
  workflowSetDataFieldsMapping,
} from '~/actions';
import DataFieldSelect from '~/components/Client/DataFieldSelect';
import { fieldTypesToDataFieldTypes } from '~/constants/workflow';
import {
  Button,
  ContentContainer,
  Icon,
  Layout,
  Text,
  TruncateText,
} from '~/eds';
import { useDataFieldSection } from '~/hooks';
import { getFieldErrors, getFieldOptions } from '~/reducers/workflow';
import { Nullable, PilotId, Uuid, Workflow, WorkflowField } from '~/types';
import { renameKey } from '~/utils/object';

import { FieldSelect } from './FieldSelect';

interface DataFieldsMappingProps {
  dataFieldsMapping: Record<Uuid, PilotId>;
  errors: {
    count: number;
    invalidFieldMappingIds: Uuid[];
  };
  fields: Record<Uuid, WorkflowField>;
  shouldValidate: boolean;
  workflow: Workflow;
  workflowAddDataFieldsMapping: () => void;
  workflowRemoveDataFieldsMapping: (fieldId: Uuid) => void;
  workflowSetDataFieldsMapping: (mapping: Record<Uuid, PilotId>) => void;
}

type FieldMapType = keyof typeof fieldTypesToDataFieldTypes;

function DataFieldsMappingComponent({
  // connected
  dataFieldsMapping,
  errors,
  fields,
  shouldValidate,
  workflow,
  workflowAddDataFieldsMapping,
  workflowRemoveDataFieldsMapping,
  workflowSetDataFieldsMapping,
}: DataFieldsMappingProps) {
  const [editingFieldId, setEditingFieldId] = useState<Nullable<Uuid>>(null);
  const { allDataFields, isFetching } = useDataFieldSection();

  const usedFieldIds = Object.keys(dataFieldsMapping).filter(
    (fieldId) => fieldId,
  );
  const usedDataFieldIds = Object.values(dataFieldsMapping).filter(
    (dataFieldId) => dataFieldId,
  );
  // fields without valid mapping to data field type are considered invalid
  const invalidFieldIds = Object.values(fields)

    .filter(
      (field) =>
        fieldTypesToDataFieldTypes?.[field?.type as FieldMapType]?.length === 0,
    )
    .map((field) => field.id);

  function handleUpdateField(fieldId: Uuid, updatedFieldId: Uuid) {
    const updatedDataFieldsMapping = renameKey(
      fieldId,
      updatedFieldId,
      dataFieldsMapping,
    ) as Record<Uuid, PilotId>;
    workflowSetDataFieldsMapping(updatedDataFieldsMapping);
  }

  function handleUpdateDataField(fieldId: Uuid, updatedDataFieldId: Uuid) {
    const updatedDataFieldsMapping = {
      ...dataFieldsMapping,
      [fieldId]: updatedDataFieldId,
    } as Record<Uuid, PilotId>;
    workflowSetDataFieldsMapping(updatedDataFieldsMapping);
  }

  const onAddDataFieldsMapping = () => {
    setEditingFieldId('newField');
    workflowAddDataFieldsMapping?.();
  };

  const onRemoveDataFieldsMapping = (fieldId: Uuid) => {
    setEditingFieldId(null);
    workflowRemoveDataFieldsMapping?.(fieldId);
  };

  const fieldOptions = useMemo(() => getFieldOptions(workflow, true), [
    workflow,
  ]);

  function renderFields() {
    return Object.keys(dataFieldsMapping).length ? (
      <Layout direction="column" spacing={6}>
        {Object.entries(dataFieldsMapping).map(
          ([fieldId, dataFieldId], index) => {
            const fieldIdValue = fieldId === 'null' ? null : fieldId;
            const isEditing =
              editingFieldId === fieldIdValue ||
              (editingFieldId === 'newField' &&
                index === Object.keys(dataFieldsMapping).length - 1);
            const hasError = errors.invalidFieldMappingIds.includes(fieldId);
            return (
              <Layout key={fieldId} align="center" justify="space-between">
                <Layout align="center" spacing={4} maxW={880}>
                  <Text minW={60} variant="body">
                    Map field
                  </Text>
                  {isEditing ? (
                    <FieldSelect
                      filteredValues={[...usedFieldIds, ...invalidFieldIds]}
                      id={`workflow--settings-data-mapping-field-${index + 1}`}
                      name={`Select Field ${index + 1}`}
                      options={fieldOptions}
                      placeholder="Pick a ticket field"
                      shouldValidate={shouldValidate}
                      value={fieldIdValue}
                      onChange={(updatedFieldId) => {
                        setEditingFieldId(updatedFieldId);
                        // the dataFieldsMapping in redux uses 'null' as key for new mappings
                        handleUpdateField(fieldId, updatedFieldId || 'null');
                      }}
                    />
                  ) : (
                    <TruncateText variant="body-bold" maxW={350}>
                      {fields[fieldId]?.name || 'N/A'}
                    </TruncateText>
                  )}
                  <Text minW={50} variant="body">
                    to field
                  </Text>
                  {isEditing ? (
                    <DataFieldSelect
                      blacklistValues={usedDataFieldIds}
                      id={`workflow--settings-data-mapping-data-field-${
                        index + 1
                      }`}
                      fieldTypes={
                        fieldTypesToDataFieldTypes?.[
                          fields[fieldId]?.type as FieldMapType
                        ]
                      }
                      placeholder="Pick a data field"
                      value={dataFieldId}
                      onChange={(updatedDataFieldId: Uuid) =>
                        handleUpdateDataField(fieldId, updatedDataFieldId)
                      }
                    />
                  ) : (
                    <TruncateText variant="body-bold" maxW={350}>
                      {allDataFields[dataFieldId]?.label || 'N/A'}
                    </TruncateText>
                  )}
                  <Text minW={100} variant="body">
                    in the platform
                  </Text>
                </Layout>
                <Layout spacing={4}>
                  {hasError && (
                    <Icon
                      icon="status-warning"
                      color="status.warning"
                      tooltip="The mapped data is incompatible with the field type."
                    />
                  )}
                  {isEditing ? (
                    <Icon
                      icon="check"
                      onClick={() => setEditingFieldId(null)}
                      color="status.info"
                    />
                  ) : (
                    <Icon
                      icon="edit"
                      onClick={() => setEditingFieldId(fieldIdValue)}
                      color="status.info"
                    />
                  )}
                  <Icon
                    icon="trash"
                    onClick={() => onRemoveDataFieldsMapping(fieldId)}
                    color="status.info"
                  />
                </Layout>
              </Layout>
            );
          },
        )}
      </Layout>
    ) : null;
  }

  const disableAddMapping = Object.keys(dataFieldsMapping).some(
    (fieldId) => fieldId === 'null',
  );

  return (
    <ContentContainer
      loadingContent={{
        isLoading: isFetching,
        message: 'Loading field mapping',
      }}
    >
      <Layout direction="column">
        <Layout direction="row" spacing={2} align="center">
          <Icon icon="field" size="l" color="status.active" />
          <Text variant="section">Data Fields Mapping</Text>
        </Layout>
        <Text variant="body" color="text.quiet" mb={4} mt={2}>
          Map specific fields from the ticket to data fields in the Evisort
          platform.
        </Text>
        {renderFields()}
        <Layout mt={6}>
          <Button
            disabled={disableAddMapping}
            iconPosition="left"
            icon="plus"
            id="workflow--data-mapping-add"
            text="Add Mapping"
            variant="secondary"
            onClick={() => onAddDataFieldsMapping()}
          />
        </Layout>
      </Layout>
    </ContentContainer>
  );
}

function mapStateToProps({
  workflow,
  builder,
}: {
  workflow: any;
  builder: any;
}): {
  dataFieldsMapping: Record<Uuid, PilotId>;
  fields: Record<Uuid, WorkflowField>;
  workflow: Workflow;
  shouldValidate: boolean;
  errors: {
    count: number;
    invalidFieldMappingIds: Uuid[];
  };
} {
  return {
    dataFieldsMapping: workflow.settings.dataFieldsMapping,
    fields: workflow.fields,
    workflow,
    shouldValidate: builder.shouldValidate,
    errors: getFieldErrors(workflow, builder),
  };
}
const DataFieldsMapping = connect(mapStateToProps, {
  workflowAddDataFieldsMapping,
  workflowRemoveDataFieldsMapping,
  workflowSetDataFieldsMapping,
})(DataFieldsMappingComponent);

export { DataFieldsMapping };
