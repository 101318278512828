import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom';

import { black2 } from '../../../assets/shared-styles/general';
import { MODAL_DOCUMENT_GROUP } from '../../../types/modal.types';
import { ERROR } from '../../../types/toast.types';
import DocumentTree from '../../Shared/DocumentTree';
import EcButton from '../../Shared/EcButton';
import EcModal from '../../Shared/EcModal';
import { showToast } from '../../Shared/EcToast';
import GroupEmptyIcon from '../../Shared/Icons/GroupEmptyIcon';
import LoadingSpinner from '../../Shared/Icons/LoadingSpinner';
import TreeIcon from '../../Shared/Icons/TreeIcon';
import { getDocumentPath, getGroupForDocument } from '../Document.data';
import styles from './DocumentRelatesPanel.styles';

class DocumentRelatesPanel extends Component {
  constructor(props) {
    super(props);

    this.loadDocumentGroup = this.loadDocumentGroup.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);

    this.state = {
      loading: false,
      currentModal: null,
      relatedDocuments: null,
    };
  }

  componentDidMount() {
    this.loadDocumentGroup();
  }

  componentDidUpdate(prevProps) {
    if (this.props.documentHandlerId !== prevProps.documentHandlerId) {
      this.loadDocumentGroup();
    }
  }

  async loadDocumentGroup() {
    const { documentHandlerId } = this.props;
    this.setState({ loading: true });
    try {
      const { results } = await getGroupForDocument(documentHandlerId, true);
      const relatedDocuments = results?.length ? results[0] : null;
      if (relatedDocuments) {
        this.loadHandlerInfo(relatedDocuments);
      }
    } catch (e) {
      showToast(ERROR, 'An error occurred while loading the document’s group.');
    } finally {
      this.setState({ loading: false });
    }
  }

  loadHandlerInfo(relatedDocuments) {
    const { documentTree } = relatedDocuments;

    const setDocGroupHandlers = (docGroup) => {
      const { children, handlerIds } = docGroup;
      const handlers = docGroup.handlers || [];
      handlerIds.forEach((handlerId) => {
        getDocumentPath(handlerId)
          .then((handlerData) => {
            handlerData.file_type = docGroup.fileType;
            handlers.push(handlerData);
          })
          .catch(() => {});
      });
      if (children.length) {
        children.forEach((childDocGroup) => setDocGroupHandlers(childDocGroup));
      }
      docGroup.handlers = handlers;
    };

    for (let relationship in documentTree) {
      const relationshipTree = documentTree[relationship];
      if (!relationshipTree.length) continue;
      relationshipTree.forEach((docGroup) => setDocGroupHandlers(docGroup));
    }
    this.setState({ relatedDocuments });
  }

  handleShowModal(modal) {
    this.setState({ currentModal: modal });
  }

  handleHideModal() {
    this.setState({ currentModal: null });
  }

  renderAddToGroupModal() {
    const { documentId, reloadDocumentContent } = this.props;

    return (
      <EcModal
        modalType={MODAL_DOCUMENT_GROUP}
        width="660px"
        title="Add to Group"
        documentIds={[documentId]}
        hideModal={this.handleHideModal}
        reloadDocumentContent={reloadDocumentContent}
      />
    );
  }

  renderDocumentTree() {
    const { classes } = this.props;
    const { relatedDocuments } = this.state;

    return (
      <div className={classes.documentTreeContent}>
        <div className={classes.groupText}>GROUP</div>
        <div className={classes.groupName}>{relatedDocuments.name}</div>
        <div>
          <div className={classes.groupCategory}>Uncategorized</div>
          {relatedDocuments.documentTree &&
          relatedDocuments.documentTree.uncategorized &&
          relatedDocuments.documentTree.uncategorized.length ? (
            <div className={classes.groupCategoryContainer}>
              <DocumentTree
                allowEdit={false}
                treeId="uncategorizedTree"
                treeData={relatedDocuments.documentTree.uncategorized}
                handleShowModal={this.handleShowModal}
              />
            </div>
          ) : (
            <div className={classes.groupCategoryEmptyDocs}>
              This group does not have any uncategorized documents.
            </div>
          )}

          <div className={classes.groupCategory}>Document Tree</div>
          {relatedDocuments.documentTree &&
          relatedDocuments.documentTree.linked &&
          relatedDocuments.documentTree.linked.length ? (
            <div className={classes.groupCategoryContainer}>
              <DocumentTree
                allowEdit={false}
                treeId="linkedTree"
                treeData={relatedDocuments.documentTree.linked}
                handleShowModal={this.handleShowModal}
              />
            </div>
          ) : (
            <div className={classes.groupCategoryEmptyDocs}>
              This group does not have a document tree.
            </div>
          )}

          <div className={classes.groupCategory}>Supporting Documents</div>
          {relatedDocuments.documentTree &&
          relatedDocuments.documentTree.supporting &&
          relatedDocuments.documentTree.supporting.length ? (
            <div className={classes.groupCategoryContainer}>
              <DocumentTree
                allowEdit={false}
                treeId="supportingTree"
                treeData={relatedDocuments.documentTree.supporting}
                handleShowModal={this.handleShowModal}
              />
            </div>
          ) : (
            <div className={classes.groupCategoryEmptyDocs}>
              This group does not have any supporting documents.
            </div>
          )}
        </div>

        <div className={classes.footerButtonContainer}>
          <Link
            className={classes.viewGroupLink}
            id="document_relates_viewGroupButton"
            to={`/document-group/${relatedDocuments.groupId}`}
          >
            <TreeIcon size="20" color={black2} />
            View Group
          </Link>
        </div>
      </div>
    );
  }

  renderEmptyDocumentTreeContent() {
    const { classes } = this.props;

    return (
      <div className={classes.tableEmptyState}>
        <div>
          <GroupEmptyIcon size="200" />
        </div>
        <div className={classes.tableEmptyStateTitle}>
          This document is not part of a group.
        </div>
        <div className={classes.tableEmptyStateMessage}>
          Groups are an easy way to keep track of related documents. Add this
          document to an existing group or create a new group.
        </div>
        <div>
          <EcButton
            id="documents_relates_addDocumentToGroupButton"
            onClick={() => {
              this.handleShowModal(MODAL_DOCUMENT_GROUP);
            }}
            text="Add to Group"
          />
        </div>
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    const { currentModal, relatedDocuments, loading } = this.state;

    if (loading) {
      return (
        <div className={classes.loadingContainer}>
          <LoadingSpinner size="medium" />
        </div>
      );
    }
    return (
      <>
        {!!relatedDocuments
          ? this.renderDocumentTree()
          : this.renderEmptyDocumentTreeContent()}
        {currentModal === MODAL_DOCUMENT_GROUP
          ? this.renderAddToGroupModal()
          : null}
      </>
    );
  }
}

DocumentRelatesPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(DocumentRelatesPanel);
