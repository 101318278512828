/**
 * This file contains bridge APIs from pdfjs for PdfDocumentViewer
 */
import { noop } from 'lodash';
import { getDocument } from 'pdfjs-dist/legacy/build/pdf';
import type { PDFDocumentProxy } from 'pdfjs-dist/legacy/build/pdf';
import React, { useEffect, useState } from 'react';

import { Nullable } from '~/types';

import { Highlight, InteractionPosition } from '../types';
import { HighlightElement } from './HighlightElement';
import { PdfHighlight } from './types';

// TODO: formalize in this module instead of `EcPdfViewerHighlighter`
export {
  PdfHighlighter,
  PdfHighlighterProvider,
  Toolbar,
  usePdfHighlighterContext,
} from '~/components/Shared/EcPdfViewerHighlighter';

export { scrollToHighlight } from '~/utils/highlights';

export const usePdfDocument = (
  file?: File,
): [PDFDocumentProxy | undefined, boolean] => {
  const [pdfDocument, setPdfDocument] = useState<PDFDocumentProxy>();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const fetchPdfDocument = async () => {
      if (file) {
        const buffer = await file.arrayBuffer();
        setPdfDocument(
          await getDocument({ data: new Uint8Array(buffer) }).promise,
        );
        setIsReady(true);
      }
    };

    fetchPdfDocument();
  }, [file]);

  return [pdfDocument, isReady];
};

// TODO: this will be refactored away once `PdfHighlighter` is reworked
export const defaultPdfHighlighterProps = {
  enableAreaSelection: () => false,
  scrollRef: noop,
  onScrollChange: noop,
};

export const mapPdfHighlights = (
  highlights: Highlight[],
): Nullable<PdfHighlight>[] => {
  return highlights.map((highlight) => {
    const { highlighter, id, pdfCoordinates } = highlight;
    if (pdfCoordinates) {
      const { pageNumber, rects } = pdfCoordinates;
      return {
        id,
        highlighter,
        position: {
          boundingRect: {
            pageNumber: 0,
            height: 0,
            width: 0,
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 0,
          },
          pageNumber,
          rects,
        },
      };
    }
    return null;
  });
};

export const createHighlightTransform = ({
  containerRef,
  onClick,
}: {
  containerRef: React.RefObject<HTMLDivElement>;
  onClick?: (pdfHighlight: PdfHighlight, position: InteractionPosition) => void;
}) => (pdfHighlight: PdfHighlight) => {
  return (
    <HighlightElement
      containerRef={containerRef}
      pdfHighlight={pdfHighlight}
      onClick={onClick}
    />
  );
};
