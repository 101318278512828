export const hasInvalidMapping = (
  errors: { invalidFieldMappingIds: string[] },
  fieldId: string,
) => errors.invalidFieldMappingIds.includes(fieldId);

export const getErrorMessage = (
  errors: { invalidFieldMappingIds: string[] },
  fieldId: string,
) => {
  if (hasInvalidMapping(errors, fieldId)) {
    return 'Data Fields Mapping Error. The field type is incompatible with the mapped data in Settings.';
  }

  return undefined;
};
