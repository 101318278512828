import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { MODAL_MULTIPLE_LOCATIONS } from '../../../types/modal.types';
import EcModal from '../EcModal';

class EcMultipleLocationsLink extends Component {
  constructor(props) {
    super(props);

    this.handleHideModal = this.handleHideModal.bind(this);
    this.handleWrapperOnClick = this.handleWrapperOnClick.bind(this);
    this.state = { showModal: false };
  }

  handleHideModal() {
    this.setState({ showModal: false });
  }

  handleWrapperOnClick() {
    this.setState({ showModal: true });
  }

  renderMultipleLocationsModal() {
    const {
      documentHandlers,
      documentName,
      multipleLocationsCallback,
    } = this.props;

    return (
      <EcModal
        multipleLocationsCallback={multipleLocationsCallback}
        hideModal={this.handleHideModal}
        modalType={MODAL_MULTIPLE_LOCATIONS}
        width="560px"
        documentName={documentName}
        documents={documentHandlers}
      />
    );
  }

  render() {
    const {
      children,
      documentHandlers,
      wrapperStyles,
      multipleLocationsCallback,
    } = this.props;
    const { showModal } = this.state;

    if (!documentHandlers) {
      return null;
    }

    if (documentHandlers.length > 1) {
      return (
        <Fragment>
          <button onClick={this.handleWrapperOnClick} className={wrapperStyles}>
            {children}
          </button>
          {showModal && this.renderMultipleLocationsModal()}
        </Fragment>
      );
    }
    const firstDocumentId = documentHandlers[0].id;
    return (
      <Link
        className={wrapperStyles}
        to={`/document/${firstDocumentId}`}
        onClick={() => multipleLocationsCallback?.(documentHandlers[0])}
      >
        {children}
      </Link>
    );
  }
}

EcMultipleLocationsLink.propTypes = {
  documentName: PropTypes.string,
  documentHandlers: PropTypes.array,
  multipleLocationsCallback: PropTypes.func,
  wrapperStyles: PropTypes.string,
};

export default EcMultipleLocationsLink;
