import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { Document, Page, pdfjs } from 'react-pdf';

import { formatDate } from '~/eds';
import { HtmlContent } from '~/ui';

import { black2 } from '../../../assets/shared-styles/general';
import {
  getDocumentVersionContent,
  getDocumentVersionOriginal,
} from '../../DocumentsViewPage/DocumentVersions/DocumentVersions.data';
import EcModalCard from '../../Shared/EcModalCard';
import EcSelect from '../../Shared/EcSelect';
import ExpiringIcon from '../../Shared/Icons/ExpiringIcon';
import LoadingSpinner from '../../Shared/Icons/LoadingSpinner';
import WarningIcon from '../../Shared/Icons/WarningIcon';
import styles from './VersionDocumentViewerModal.styles';
import {
  loadDocumentVersions,
  shouldExpandMenu,
} from './VersionDocumentViewerModal.utils';

if (typeof window !== 'undefined' && 'Worker' in window) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
}

class VersionDocumentViewerModal extends Component {
  constructor(props) {
    super(props);

    this.handleOCRClick = this.handleOCRClick.bind(this);
    this.handlePDFClick = this.handlePDFClick.bind(this);
    this.handleMenuChange = this.handleMenuChange.bind(this);
    this.loadDocumentPDF = this.loadDocumentPDF.bind(this);
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    this.onDocumentLoadError = this.onDocumentLoadError.bind(this);

    this.expandMenuPromise = null;

    this.state = {
      showOCR: true,
      pdfFile: null,
      versionSelected: this.props.documentObject,
      versionList: this.props.versionList,
      menuPageTop: this.props.page,
      menuPageBottom: this.props.page,
    };
  }

  componentDidMount() {
    this.loadDocument();
  }

  loadDocument() {
    const { versionSelected } = this.state;

    this.setState({ loading: true });
    getDocumentVersionContent(versionSelected.id)
      .then((data) =>
        this.setState({
          versionContent: data.html.replace(/\\"/g, '"'),
          loading: false,
          errorLoadDocument: false,
        }),
      )
      .catch(() => this.setState({ loading: false, errorLoadDocument: true }));
  }

  loadDocumentPDF = () => {
    const { versionSelected } = this.state;

    this.setState({ loading: true });
    getDocumentVersionOriginal(versionSelected.id)
      .then((data) => {
        if (data.type === 'application/html') {
          data.text().then((text) => {
            this.setState({
              loading: false,
              errorLoadDocument: false,
              versionContent: text
                .replace(
                  '#page-container{position:absolute;',
                  '#page-container{position:inherit;',
                )
                .replace(/\\"/g, '"')
                .replace(/<br\/>/gi, ''),
            });
          });
        } else {
          const myFile = new File([data], 'file.pdf');
          this.setState({
            pdfFile: myFile,
            loading: false,
            errorLoadDocument: false,
          });
        }
      })
      .catch(() => this.setState({ loading: false, errorLoadDocument: true }));
  };

  onDocumentLoadSuccess(document) {
    this.setState({
      numPages: document.numPages,
      errorLoadDocument: false,
    });
  }

  onDocumentLoadError() {
    this.setState({ numPages: document.numPages, errorLoadDocument: true });
  }

  renderLoader() {
    const { classes } = this.props;
    const { loading, errorLoadDocument } = this.state;

    if (!loading && errorLoadDocument) {
      return <div>An error occurred while loading the document.</div>;
    } else if (loading && !errorLoadDocument) {
      return (
        <div className={classes.loadingContainer}>
          <LoadingSpinner size="medium" />
        </div>
      );
    } else {
      return null;
    }
  }

  handleOCRClick() {
    this.setState({ showOCR: true, pdfFile: null }, () => {
      this.loadDocument();
    });
  }

  handlePDFClick() {
    this.setState({ showOCR: false, versionContent: '' }, () => {
      this.loadDocumentPDF();
    });
  }

  handleScrollToMenuEnd(isTop) {
    const { documentId, totalPageCount } = this.props;
    const { menuPageTop, menuPageBottom } = this.state;
    if (
      this.expandMenuPromise === null &&
      shouldExpandMenu(isTop, menuPageTop, menuPageBottom, totalPageCount)
    ) {
      const updatedMenuPage = isTop ? menuPageTop - 1 : menuPageBottom + 1;
      this.expandMenuPromise = loadDocumentVersions(
        documentId,
        updatedMenuPage,
      );
      this.expandMenuPromise
        .then((moreVersions) => {
          this.setState({
            versionList: isTop
              ? moreVersions.concat(this.state.versionList)
              : this.state.versionList.concat(moreVersions),
            menuPageTop: isTop ? updatedMenuPage : menuPageTop,
            menuPageBottom: isTop ? menuPageBottom : updatedMenuPage,
          });
        })
        .finally(() => (this.expandMenuPromise = null));
    }
  }

  handleMenuChange(name, value) {
    this.setState(
      {
        versionSelected: value,
        pdfFile: null,
        versionContent: '',
        showOCR:
          value.file_type === '.pdf' && !this.state.showOCR ? false : true,
        [name]: value,
      },
      () => {
        this.state.showOCR ? this.loadDocument() : this.loadDocumentPDF();
      },
    );
  }

  renderPdfContent() {
    const { classes } = this.props;
    const { numPages, pdfFile, versionContent } = this.state;

    if (!pdfFile && !versionContent) {
      return null;
    } else if (!pdfFile && versionContent) {
      return (
        <div className={classes.documentViewerInModal}>
          <HtmlContent
            className={classes.documentContentWrapper}
            html={versionContent}
          />
        </div>
      );
    } else {
      return (
        <div className={classes.documentViewerInModal}>
          <Document
            file={pdfFile}
            onLoadSuccess={this.onDocumentLoadSuccess}
            onLoadError={this.onDocumentLoadError}
            loading={this.renderLoader()}
            noData={this.renderLoader()}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                renderAnnotationLayer={false}
                renderTextLayer={false}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                loading={this.renderLoader()}
              />
            ))}
          </Document>
        </div>
      );
    }
  }

  renderOcrContent() {
    const { classes } = this.props;
    const { versionContent } = this.state;

    if (!versionContent) {
      return null;
    }
    return (
      <div className={classes.documentViewerInModal}>
        <HtmlContent
          className={classes.documentContentWrapper}
          html={versionContent}
          onMouseDown={this.handleOnMouseDown}
          onMouseUp={this.handleOnMouseUp}
        />
      </div>
    );
  }

  renderOcrPdfSwitch() {
    const { classes } = this.props;
    const { showOCR, versionSelected } = this.state;

    if (versionSelected.file_type !== '.pdf') return null;

    const viewOcrClass = showOCR ? classes.viewSelected : null;
    const viewPdfClass = showOCR ? null : classes.viewSelected;

    return (
      <div className={classes.viewTypeSwitch}>
        <button
          aria-pressed={showOCR}
          className={viewOcrClass}
          onClick={this.handleOCRClick}
        >
          Text
        </button>
        <button
          aria-pressed={!showOCR}
          className={viewPdfClass}
          onClick={this.handlePDFClick}
        >
          PDF
        </button>
      </div>
    );
  }

  renderDocumentViewerWarning() {
    const { classes } = this.props;
    return (
      <div className={classes.viewerWarningWrapper}>
        <div>
          <WarningIcon size="48" color="#eb890f" />
        </div>
        <div className={classes.viewerWarningHeader}>
          We could not perform optical character recognition on this document.
        </div>
        <div className={classes.viewerWarningBody}>
          OCR failed because of an issue with this document. Please open the
          original file using the “Download” button and contact support if you
          require further assistance.
        </div>
      </div>
    );
  }

  renderYellowWarningBanner() {
    const { classes } = this.props;
    return (
      <div className={classes.viewerAlgoWarningWrapper}>
        <div>
          The document you are viewing experienced one or more processing errors
          and may not contain the correct outputs or flags. Please review this
          document closely or re-upload the document.
        </div>
      </div>
    );
  }

  renderDocumentViewerError() {
    const { classes } = this.props;
    return (
      <div className={classes.viewerWarningWrapper}>
        <div>
          <WarningIcon size="48" color="#eb890f" />
        </div>
        <div className={classes.viewerWarningHeader}>
          An error occurred when loading the document.
        </div>
      </div>
    );
  }

  renderDocumentViewerContent() {
    return this.state.showOCR
      ? this.renderOcrContent()
      : this.renderPdfContent();
  }

  render() {
    const {
      classes,
      errorLoadDocument,
      hideModal,
      pageSize,
      totalPageCount,
    } = this.props;
    const { showOCR, versionSelected, versionList, warningState } = this.state;
    if (totalPageCount > 1 && versionList.length < pageSize) {
      // This check and action are here to resolve an issue with the onMenuScroll* events not firing if the
      // menu is not scrollable when mounted. The only time this will happen is when looking at the last page
      // of the versions and opening the menu, as it is the remainder of the operation versionCount/pageSize.
      this.handleScrollToMenuEnd(true);
    }

    const dateModified = formatDate(
      versionSelected.date_modified
        ? new Date(versionSelected.date_modified)
        : new Date(),
      'full',
    );

    return (
      <EcModalCard
        title="Document Viewer"
        content={
          <div>
            <div
              className={
                versionSelected.is_latest
                  ? classes.documentVersionControlContainer
                  : classes.documentVersionControlContainerOutdated
              }
            >
              {this.renderOcrPdfSwitch()}
              {!versionSelected.is_latest ? (
                <div className={classes.documentVersionBannerText}>
                  <ExpiringIcon color={black2} size="24" />
                  You are viewing an older version of this document. This is{' '}
                  <b>version {versionSelected.version_tag}</b>, last modified on{' '}
                  {dateModified} by {versionSelected.last_modified_by}.
                </div>
              ) : null}
              <div
                className={classes.versionDropdownContainer}
                data-testid="version-drop-down-menu"
              >
                <EcSelect
                  width="170px"
                  options={versionList}
                  defaultValue={versionList.find(
                    (option) => option.value === versionSelected.id,
                  )}
                  customDropdownIndicator
                  onChange={(option) =>
                    this.handleMenuChange('versionSelected', option)
                  }
                  onMenuScrollToTop={() => this.handleScrollToMenuEnd(true)}
                  onMenuScrollToBottom={() => this.handleScrollToMenuEnd(false)}
                  isSearchable={false}
                />
              </div>
            </div>
            {this.renderLoader()}
            {warningState && showOCR
              ? this.renderDocumentViewerWarning()
              : this.renderDocumentViewerContent()}
            {errorLoadDocument ? this.renderDocumentViewerError() : null}
          </div>
        }
        hideModal={hideModal}
      />
    );
  }
}

VersionDocumentViewerModal.propTypes = {
  title: PropTypes.string.isRequired,
  handleActionClick: PropTypes.func,
  hideModal: PropTypes.func.isRequired,
  width: PropTypes.string,
};

export default injectSheet(styles)(VersionDocumentViewerModal);
