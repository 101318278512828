import PT from 'prop-types';
import React from 'react';

import { Box, styles } from '~/ui';
import { withInputError } from '~/ui/hocs';
import { validateEmail } from '~/utils/strings';

const widths = {
  s: '200px',
  m: '260px',
  l: '520px',
  fullWidth: '100%',
};

function validate({ value }) {
  if (value && !validateEmail(value)) {
    return 'Invalid email';
  }
  return null;
}

function EmailInput({
  disabled = false,
  error,
  placeholder,
  value,
  width = 'm',
  onChange,
}) {
  return (
    <Box
      as="input"
      disabled={disabled}
      placeholder={placeholder}
      px={4}
      sx={{
        ...styles.form.input,
        ...(error ? styles.form.error : {}),
        width: widths[width],
      }}
      type="email"
      value={value || ''}
      onChange={(e) => onChange(e.target.value)}
    />
  );
}

EmailInput.propTypes = {
  /** Disables the input component */
  disabled: PT.bool,
  /** Input error */
  error: PT.string,
  /** Placeholder */
  placeholder: PT.string,
  /** Text input value */
  value: PT.string,
  /** Input width */
  width: PT.oneOf(['s', 'm', 'l', 'fullWidth']),
  /** Callback that returns new selected value */
  onChange: PT.func.isRequired,
};

export default withInputError(EmailInput, validate);
