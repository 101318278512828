import moment, { unitOfTime } from 'moment';
import uuid from 'uuid';

import { DateFormatType } from '~/enums';
import { FieldType, Filter, OperatorId } from '~/evifields';

const CROSS_FILTER_OPERATORS: Record<string, OperatorId> = {
  age: 'between',
  boolean: 'equals',
  text: 'text_contains_any',
  date: 'date_between',
  enum: 'equals',
  enum_set: 'contains_all',
  number: 'between',
};

const getValuesAndOperator: (
  type: FieldType,
  value: string,
) => { values: unknown[]; operatorId: OperatorId } = (type, value) => {
  switch (type) {
    case 'age':
      return {
        values: value.split(' - ').map((item) => Number(item)),
        operatorId: CROSS_FILTER_OPERATORS[type],
      };
    case 'date':
      const dateValues = value
        .split(' to ')
        .map((date) => moment(date, DateFormatType.Iso).toDate());
      if (dateValues.length > 1) {
        return {
          values: dateValues,
          operatorId: CROSS_FILTER_OPERATORS[type],
        };
      } else {
        return {
          values: dateValues,
          operatorId: 'date_on',
        };
      }

    case 'number':
      const numberValues = `${value}`
        .split(' - ')
        .map((numberValue) => Number(numberValue.replaceAll(',', '')));
      if (numberValues.length > 1) {
        return {
          values: numberValues,
          operatorId: CROSS_FILTER_OPERATORS[type],
        };
      } else {
        return {
          values: numberValues,
          operatorId: 'equals',
        };
      }

    case 'boolean':
      return {
        values: [],
        operatorId: value === 'True' ? 'is_true' : 'is_false',
      };
    case 'enum':
    case 'enum_set':
    default:
      return {
        values: [value],
        operatorId: CROSS_FILTER_OPERATORS[type],
      };
  }
};

export const toFilter = (
  fieldId: string,
  value: string,
  type: FieldType,
): Filter => {
  return {
    id: uuid.v4(),
    fieldId,
    ...getValuesAndOperator(type, value),
  };
};

export const toBucketValue = (
  filterValues: Array<unknown>,
  type: FieldType,
) => {
  switch (type) {
    case 'date':
      return filterValues.length === 1
        ? moment(filterValues[0] as Date).format(DateFormatType.Iso)
        : filterValues
            .map((value) => moment(value as Date).format(DateFormatType.Iso))
            .join(' to ');

    case 'boolean':
    case 'enum':
    case 'enum_set':
    case 'number':
    default:
      return filterValues.length === 1
        ? (filterValues[0] as string)
        : filterValues.join(' - ');
  }
};

export const getIntervalValues = (date: Date, interval: string) => {
  switch (interval) {
    case 'YEAR':
    case 'QUARTER':
    case 'MONTH':
      return [
        moment(date)
          .startOf(interval.toLowerCase() as unitOfTime.StartOf)
          .toDate(),
        moment(date)
          .endOf(interval.toLowerCase() as unitOfTime.StartOf)
          .toDate(),
      ];

    case 'WEEK':
      return [
        moment(date).startOf('isoWeek').toDate(),
        moment(date).endOf('isoWeek').toDate(),
      ];

    default:
      return [date];
  }
};
