import { getClientIdWithEnvPrefix } from '~/utils/user';

export const integrateSegment = (user) => {
  if (typeof window.analytics != 'undefined' && user) {
    window.analytics.page();
    window.analytics.identify(`${user.id}_${user.client}`, {
      email: user.email,
      name: `${user.first_name} ${user.last_name}`,
      firstName: user.first_name,
      lastName: user.last_name,
      role: user.segment_info ? user.segment_info.position : '',
      groupId: user.client,
      clientName: user.client_name,
      workflowAdmin: user.is_workflow_admin,
    });
    window.analytics.group(getClientIdWithEnvPrefix(user), {
      clientName: user.client_name,
      numBillableDocs: user.segment_info
        ? user.segment_info.num_billable_docs
        : 0,
      numUsers: user.segment_info ? user.segment_info.num_users : 0,
      lastSyncDate: user.segment_info ? user.segment_info.last_sync_date : '',
      clientType: user.segment_info ? user.segment_info.client_type : '',
      storageProvider: user.segment_info
        ? user.segment_info.storage_provider
        : '',
      aiActivation: user.segment_info ? user.segment_info.ai_activation : false,
    });
  }
};

export const trackSegment = (name, properties) => {
  if (typeof window.analytics != 'undefined') {
    window.analytics.track(name, properties);
  }
};
