import { useDispatch, useSelector } from 'react-redux';

import { types } from '~/eds';
import { FlagType, useFlag } from '~/flags';
import { selectors } from '~/redux';
import { chatbotSlice } from '~/redux/slices/chatbot';

import { ConversationHistory } from './ConversationHistory';
import { QuestionGroups } from './QuestionGroups';
import { Questions } from './Questions';

export type SideContentTabs =
  | 'conversationHistory'
  | 'questions'
  | 'questionGroups';

interface UseChatBotSideContent {
  sideContent: types.SharedPanelProps['sideContent'];
}

export const useChatBotSideContent = (): UseChatBotSideContent => {
  const isVisible = useSelector(selectors.selectIsSideContentVisible);
  const selectedTab = useSelector(selectors.selectSideContentTab);
  const hasConversationHistory = useFlag(
    FlagType.AskAnythingConversationHistory,
  );
  const dispatch = useDispatch();

  const tabs = [
    {
      label: 'History',
      panel: <ConversationHistory />,
      value: 'conversationHistory',
      hidden: !hasConversationHistory,
    },
    {
      label: 'Saved Questions',
      panel: <Questions />,
      value: 'questions',
    },
    {
      label: 'Question Groups',
      panel: <QuestionGroups />,
      value: 'questionGroups',
    },
  ];

  return {
    sideContent: {
      action: {
        onClick: () => {
          // Default to conversation history if the FF is enabled.
          if (hasConversationHistory) {
            dispatch(
              chatbotSlice.actions.setSideContentTab('conversationHistory'),
            );
          }
          dispatch(chatbotSlice.actions.toggleSideContent());
        },
        icon: 'left-panel',
        text: 'Show/Hide side content',
      },
      tabs: {
        selectedTab,
        onSelectTab: (updatedTab) =>
          dispatch(
            chatbotSlice.actions.setSideContentTab(
              updatedTab as SideContentTabs,
            ),
          ),
        tabs: tabs.filter((tab) => !tab.hidden),
      },
      isVisible,
    },
  };
};
