import moment, { unitOfTime } from 'moment';

import { getIntervalValues } from '~/components/DashboardV2/DashboardV2.utils';
import { pluralize } from '~/eds';
import { DateFormatType, NumeralFormatType } from '~/enums';
import { Bucket } from '~/types';
import { formatNumeral } from '~/utils';
import { capitalizeWords } from '~/utils/strings';

const formatMapping: Record<string, string> = {
  M: 'YYYY-MM',
  d: DateFormatType.Iso,
  y: 'YYYY',
};
const intervalMapping: Record<string, string> = {
  M: 'MONTH',
  d: 'DAY',
  y: 'YEAR',
};

export const formatTitleCase = ({
  label,
  value,
  bucketValue,
}: Bucket): Bucket => {
  return { label: capitalizeWords(label), value, bucketValue: bucketValue };
};

export const formatDate = (
  { label, value }: Bucket,
  interval: string,
  multiple: number,
  selectedInterval?: string,
) => {
  if (multiple > 1) {
    const lastDate = moment(label)
      .add(multiple, interval as unitOfTime.Base)
      .subtract(1, 'day');

    return {
      label: `${moment(label, DateFormatType.Iso).format(
        formatMapping[interval] || DateFormatType.Iso,
      )} to ${lastDate.format(formatMapping[interval])}`,
      bucketValue: `${moment(label, DateFormatType.Iso).format(
        DateFormatType.Iso,
      )} to ${lastDate.format(DateFormatType.Iso)}`,
      value,
    };
  } else {
    const intervalForValues = selectedInterval
      ? selectedInterval
      : intervalMapping[interval];
    const intervalValues = getIntervalValues(
      moment(label, DateFormatType.Iso).toDate(),
      intervalForValues,
    );
    return {
      label: `${moment(label, DateFormatType.Iso).format(
        formatMapping[interval] || DateFormatType.Iso,
      )}`,
      bucketValue: intervalValues
        .map((intervalValue) =>
          moment(intervalValue).format(DateFormatType.Iso),
        )
        .join(' to '),
      value,
    };
  }
};

export const formatBoolean = ({ label, value }: Bucket) => {
  return { label: String(label) === '1' ? 'True' : 'False', value };
};

export const formatNumber = ({ label, value }: Bucket, interval: number) => {
  const truncatedLabel = Math.trunc(Number(label));
  const firstNumber = formatNumeral(NumeralFormatType.Number)(truncatedLabel);
  if (interval > 1) {
    const lastNumber = formatNumeral(NumeralFormatType.Number)(
      truncatedLabel + interval - 1,
    );
    return {
      label: `${firstNumber} - ${lastNumber}`,
      bucketValue: `${label} - ${Number(label) + interval - 1}`,
      value,
    };
  } else {
    return { label: firstNumber, bucketValue: Number(label), value };
  }
};

export const formatAge = (bucket: Bucket, interval = 0) => {
  const numericLabel = Number(bucket.label);
  const label =
    interval === 1
      ? `${pluralize(numericLabel, 'day')}`
      : `${bucket.label} - ${interval + numericLabel - 1} days`;
  return {
    ...bucket,
    label,
    bucketValue: `${bucket.label} - ${interval + numericLabel - 1}`,
  };
};
