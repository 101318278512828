export enum FeatureFlagType {
  Adobesign = 'ADOBESIGN',
  AllowClauseRenaming = 'ALLOW_CLAUSE_RENAMING',
  AuditLogsNewPage = 'AUDIT_LOGS_NEW_PAGE',
  AutomationSmartClauseApi = 'AUTOMATION_SMART_CLAUSE_API',
  AutomationSmartClauseFull = 'AUTOMATION_SMART_CLAUSE_FULL',
  BulkUser = 'BULK_USER',
  BulkDocumentMetadata = 'BULK_DOCUMENT_METADATA',
  BulkDocumentMetadataFileMove = 'BULK_DOCUMENT_METADATA_FILE_MOVE',
  BulkDocumentMetadataFilenameUpdate = 'BULK_DOCUMENT_METADATA_FILENAME_UPDATE',
  BulkDocumentMetadataMultipleTasks = 'BULK_DOCUMENT_METADATA_MULTIPLE_TASKS',
  Docusign = 'DOCUSIGN',
  DisableDocTagging = 'DISABLE_DOC_TAGGING',
  DocumentGroupRevamp = 'DOCUMENT_GROUP_REVAMP',
  DocumentRestore = 'DOCUMENT_RESTORE',
  EditClause = 'EDIT_CLAUSE',
  EsWeightedSearch = 'ES_WEIGHTED_SEARCH',
  ExportExcel = 'EXPORT_EXCEL',
  ExternalApi = 'EXTERNAL_API',
  HidePhantomProvisions = 'HIDE_PHANTOM_PROVISIONS',
  InboundEmail = 'INBOUND_EMAIL',
  IntakeFormAllFileTypes = 'INTAKE_FORM_ALL_FILE_TYPES',
  MultiSyncPairs = 'MULTI_SYNC_PAIRS',
  OoDocs = 'OODOCS',
  OoDocsEditing = 'OODOCS_EDIT',
  PdfPreviewHighlighting = 'PDF_PREVIEW_HIGHLIGHTING',
  PostSigActivityFeed = 'POST_SIG_ACTIVITY_FEED',
  PreSigComments = 'PRE_SIG_COMMENTS',
  SearchV2 = 'SEARCH_V2',
  Settings = 'SETTINGS',
  SingleSignOn = 'SSO',
  SyncPair = 'SYNC_PAIR',
  SyncPairWebhook = 'SYNC_PAIR_WEBHOOK',
  TicketInboundEmail = 'TICKET_INBOUND_EMAIL',
  Upload = 'UPLOAD',
  Workflow = 'WORKFLOW_DEMO',
}
