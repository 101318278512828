import { isString } from 'lodash';
import mapValues from 'lodash/mapValues';
import uuid from 'uuid';

import {
  customSettingsKeys,
  customSettingsOptionValues,
  fieldTypesToDataFieldTypes,
  timePeriodFieldDefaultOptions,
  valueKeys,
} from '~/constants/workflow';
import {
  DateFormatType,
  FileExtensionType,
  WorkflowFieldType,
  WorkflowIntakeFormType,
  WorkflowPartyType,
} from '~/enums';
import { coerceToArray } from '~/utils/array';
import { coerceFileType } from '~/utils/files';
import {
  capitalizeWords,
  removeBreaklinesFromText,
  toLowercaseTrimmed,
} from '~/utils/strings';

// * To get the values of these fields we use 'field.value' while all other fields use 'field.value.value'.
const unconvententialFields = [
  WorkflowFieldType.Address,
  WorkflowFieldType.MonetaryValue,
  WorkflowFieldType.TimePeriod,
];

function setInitialFieldValue(fieldType) {
  switch (fieldType) {
    case WorkflowFieldType.Address:
      return {
        [valueKeys[fieldType].COUNTRY]: null,
        [valueKeys[fieldType].STREET_1]: null,
        [valueKeys[fieldType].STREET_2]: null,
        [valueKeys[fieldType].CITY]: null,
        [valueKeys[fieldType].STATE]: null,
        [valueKeys[fieldType].ZIP_CODE]: null,
      };
    case WorkflowFieldType.MonetaryValue:
      return {
        [valueKeys[fieldType].AMOUNT]: null,
        [valueKeys[fieldType].CURRENCY]: null,
      };
    case WorkflowFieldType.TimePeriod:
      return {
        [valueKeys[fieldType].AMOUNT]: null,
        [valueKeys[fieldType].PERIOD]: null,
      };
    default:
      return { value: null };
  }
}

export function prefillMissingFieldOptions(customSettings, fieldValue) {
  if (customSettings.enable_create_options) {
    const options = [...(customSettings.options || [])];
    const optionValueSet = new Set(options.map((option) => option.value));
    const { value } = fieldValue;
    if (value) {
      const valueArray = coerceToArray(value);
      valueArray.forEach((v) => {
        if (!optionValueSet.has(v)) {
          options.push({
            label: v,
            value: v,
          });
        }
      });
    }
    return {
      ...customSettings,
      options,
    };
  } else {
    return customSettings;
  }
}

export function normalizeIntakeForm(response) {
  let normalizedConditions;
  const normalizedSections = {};
  const normalizedQuestions = {};
  const normalizedFields = {};

  normalizedConditions = response.conditions.reduce((acc, condition) => {
    acc[condition.id] = condition;
    return acc;
  }, {});

  const sections = [...response.sections];

  sections
    .sort((a, b) => a.position - b.position)
    .forEach((section) => {
      const { id, name, description, position, questions } = section;

      const questionsArr = [...questions];

      normalizedSections[id] = {
        ...{ id, name, description, position },
        questions: questionsArr
          .sort((a, b) => a.position - b.position)
          .map((question) => question.id),
      };

      questionsArr
        .sort((a, b) => a.position - b.position)
        .forEach((question) => {
          const {
            id,
            title,
            description,
            isReadOnly,
            isRequired,
            field,
            position,
          } = question;

          normalizedQuestions[id] = {
            ...{ id, title, description, isReadOnly, isRequired, position },
            field: field.id,
          };

          const {
            customSettings: { defaultValue },
          } = field;

          /*
            TODO: Should work with BE to make sure all field objects follow path `field.value.value` to get field values.
            * Also would require making some FE updates to address, monetary, and time period fields.
          */
          let fieldValue;

          if (unconvententialFields.includes(field.type)) {
            fieldValue = field.value
              ? field.value
              : setInitialFieldValue(field.type);
          } else if (field.type === WorkflowFieldType.Number) {
            fieldValue =
              field.value && Number.isFinite(field.value.value)
                ? field.value
                : setInitialFieldValue(field.type);
          } else {
            fieldValue =
              field.value && field.value.value
                ? field.value
                : setInitialFieldValue(field.type);
          }

          if (defaultValue) {
            Object.keys(defaultValue).forEach((key) => {
              if (fieldValue[key] === null) {
                fieldValue[key] = defaultValue[key];
              }
            });
          }

          const customSettings = prefillMissingFieldOptions(
            field.customSettings,
            fieldValue,
          );

          normalizedFields[field.id] = {
            ...field,
            customSettings,
            question: question.id,
            value: fieldValue,
            errorMessage: null,
            modified: false,
            isPlaceHolder: field?.placeHolder != null,
          };
        });
    });

  return {
    ...response,
    conditions: normalizedConditions,
    sections: normalizedSections,
    questions: normalizedQuestions,
    fields: normalizedFields,
    isValidated: false,
  };
}

export function prepareIntakeFormSubmitData(formData) {
  const fields = Object.values(formData.fields)
    .filter((field) => field.isEnabled)
    .map((field) => ({
      fieldId: field.id,
      value: mapValues(field.value, (v) => isEmptyValue(v)),
    }))
    .concat(clearDisabledEmailSignerFields(formData));

  return { ...formData, fields };
}

export function clearDisabledEmailSignerFields(formData) {
  return Object.values(formData.fields)
    .filter(
      (field) =>
        field.isSignerField &&
        field.type === WorkflowFieldType.Email &&
        !field.isEnabled,
    )
    .map((field) => ({
      fieldId: field.id,
      value: mapValues(field.value, () => ''),
    }));
}

export function isEmptyValue(value) {
  if (value === '' || value === null || value === undefined) {
    return '';
  } else {
    if (isString(value)) {
      return removeBreaklinesFromText(value);
    }
    return value;
  }
}

export function coerceName(name, entityName, index) {
  return name || `${entityName} ${index + 1}`;
}

export function testConditionalTextField(field) {
  return field.conditionalText;
}

export function testContractField(field, fieldLinks = {}) {
  return (
    field.type && ['contract', 'custom'].includes(fieldLinks[field.id]?.entity)
  );
}

export function testCustomField(field) {
  return !field.placeholder;
}

export function testImplicitField(field) {
  return field.isImplicit;
}

export function testDocumentField(field) {
  return field.placeholder && !field.conditionalText;
}

export function testUnassignedField(field) {
  return field.placeholder && !field.type;
}

export function testInvalidConditionRule(conditionRule, conditions) {
  if (!!conditionRule) {
    const conditionId = conditionRule.id;
    return !conditionId || !conditions[conditionId];
  }
  return false;
}

export function testInvalidFieldMapping(field, builder, settings) {
  const allowedTypes = fieldTypesToDataFieldTypes[field.type];
  const mappedFieldSectionId = settings.dataFieldsMapping[field.id];
  // if there are no mapped field for this field id, then it is valid (e.g return false);
  if (!mappedFieldSectionId) return false;
  const mappedFieldSection = builder.fieldSections[mappedFieldSectionId];
  return !allowedTypes.includes(mappedFieldSection?.fieldType);
}

function testIsStageDisabledWithoutCondition(isEnabled, conditionRule) {
  return !(isEnabled || conditionRule);
}

export function testShouldEvaluateStageConditions(isEnabled, conditionRule) {
  return (
    isEnabled || !testIsStageDisabledWithoutCondition(isEnabled, conditionRule)
  );
}

export function testIsStageEnabledOrIsDisabledWithCondition(
  isEnabled,
  conditionRule,
) {
  return isEnabled || (!isEnabled && conditionRule);
}

export function testInvalidSigner(signer, signers) {
  if (!!signer) {
    const signerId = signer.id;
    return !signerId || !signers[signerId];
  }
}

export function testESignatureField(field) {
  return field.isEsignatureTag;
}

export function getPartyName(type, index) {
  return coerceName(
    null,
    type === WorkflowPartyType.Counterparty ? 'Counterparty' : 'Internal Party',
    index,
  );
}

// determines if a provided fieldDefinition isSelected or isUsed based on other contextual data (entityId, fieldLinks, selectedFieldDefinition)
export function getFieldDefinitionStatus({
  entityId,
  fieldDefinition,
  fieldLinks,
  selectedEntityId,
  selectedFieldDefinition,
}) {
  const fieldLinkValues = Object.values(fieldLinks);
  const { entity: fieldDefinitionEntity, key: fieldDefinitionKey } =
    fieldDefinition || {};

  let isSelected = false;
  let isUsed = false;
  let tooltip;

  if (entityId) {
    isSelected = entityId === selectedEntityId;
  } else if (selectedFieldDefinition) {
    isSelected =
      selectedFieldDefinition.entity === fieldDefinitionEntity &&
      selectedFieldDefinition.key === fieldDefinitionKey;
  }

  isUsed = fieldLinkValues.some((fieldLink) => {
    let matches =
      fieldLink.entity === fieldDefinitionEntity &&
      fieldLink.key === fieldDefinitionKey;
    if (entityId) {
      matches = matches && entityId === fieldLink.id;
    }
    return matches;
  });

  if (isUsed) {
    tooltip = 'This field has already been assigned';
  }

  return {
    isSelected,
    isUsed,
    tooltip,
  };
}

/* ----------------------------------------------------
 *  Workflow Builder: Field Validator
 *  ----------------------------------------------------
 */

export function testInvalidField(fieldId, fields, fieldLinks) {
  const doesFieldExist = !!fields[fieldId];
  const doesFieldLinkExist = !!fieldLinks[fieldId];
  return !doesFieldExist || !doesFieldLinkExist;
}

/* ----------------------------------------------------
 *  Workflow Builder: Permissions
 *  ----------------------------------------------------
 */
export const creatorPermissionEnums = {
  EDIT_VIEW: 'EDIT_VIEW',
  REVIEW_VIEW: 'REVIEW_VIEW',
  SIGN_VIEW: 'SIGN_VIEW',
  FINALIZE_VIEW: 'FINALIZE_VIEW',
  COMPLETED_VIEW: 'COMPLETED_VIEW',
  TICKET_VIEW: 'TICKET_VIEW',

  VIEW_APPROVAL_BASIC: 'VIEW_APPROVAL_BASIC',
  VIEW_APPROVAL_DETAILED: 'VIEW_APPROVAL_DETAILED',

  ACTIVITY_LOG: 'ACTIVITY_LOG',
  REASSIGN_ANY: 'REASSIGN_ANY',
  SEND_FOR_REVIEW: 'SEND_FOR_REVIEW',
  SEND_FOR_SIGNATURES: 'SEND_FOR_SIGNATURES',
  SEND_FOR_FINALIZATION: 'SEND_FOR_FINALIZATION',
  MARK_COMPLETE: 'MARK_COMPLETE',

  EDIT_FORM: 'EDIT_FORM',
  UPLOAD_VERSION: 'UPLOAD_VERSION',
  REVIEWER: 'REVIEWER',
  DOWNLOAD: 'DOWNLOAD',
  SHARE: 'SHARE',
  EDIT_PARTICIPANTS: 'EDIT_PARTICIPANTS',

  UPLOAD_SIGNED: 'UPLOAD_SIGNED',
  COLLECT_SIGNATURES: 'COLLECT_SIGNATURES',
  APPROVE_ANY: 'APPROVE_ANY',
  REJECT_ANY: 'REJECT_ANY',
  RESET_ANY: 'RESET_ANY',
};

export const editOptions = [
  { value: creatorPermissionEnums.EDIT_VIEW, label: 'View edit stage' },
  { value: creatorPermissionEnums.SEND_FOR_REVIEW, label: 'Send for Review' },

  {
    value: creatorPermissionEnums.ACTIVITY_LOG,
    label: 'View / Participate in activity feed',
  },
  { value: creatorPermissionEnums.REASSIGN_ANY, label: 'Reassign' },
  { value: creatorPermissionEnums.EDIT_FORM, label: 'Edit form information' },
  { value: creatorPermissionEnums.UPLOAD_VERSION, label: 'Upload new version' },
  { value: creatorPermissionEnums.REVIEWER, label: 'View document' },
  { value: creatorPermissionEnums.DOWNLOAD, label: 'Download document' },
  { value: creatorPermissionEnums.SHARE, label: 'Share document' },
  {
    value: creatorPermissionEnums.EDIT_PARTICIPANTS,
    label: 'Add participants',
  },
];

export const reviewOptions = [
  { value: creatorPermissionEnums.REVIEW_VIEW, label: 'View review stage' },
  {
    value: creatorPermissionEnums.SEND_FOR_SIGNATURES,
    label: 'Send for Signing',
  },

  {
    value: creatorPermissionEnums.VIEW_APPROVAL_BASIC,
    label: 'View only stage and who’s approving/has to approve',
  },
  {
    value: creatorPermissionEnums.VIEW_APPROVAL_DETAILED,
    label: 'View all details of approval in Review Phase',
  },

  {
    value: creatorPermissionEnums.ACTIVITY_LOG,
    label: 'View / Participate in activity feed',
  },
  { value: creatorPermissionEnums.REASSIGN_ANY, label: 'Reassign' },
  { value: creatorPermissionEnums.EDIT_FORM, label: 'Edit form information' },
  { value: creatorPermissionEnums.UPLOAD_VERSION, label: 'Upload new version' },
  { value: creatorPermissionEnums.REVIEWER, label: 'View document' },
  { value: creatorPermissionEnums.DOWNLOAD, label: 'Download document' },
  { value: creatorPermissionEnums.SHARE, label: 'Share document' },
  {
    value: creatorPermissionEnums.EDIT_PARTICIPANTS,
    label: 'Add participants',
  },

  { value: creatorPermissionEnums.APPROVE_ANY, label: 'Approve Approvals' },
  { value: creatorPermissionEnums.REJECT_ANY, label: 'Reject Approvals' },
  { value: creatorPermissionEnums.RESET_ANY, label: 'Reset Approvals' },
];

export const signOptions = [
  { value: creatorPermissionEnums.SIGN_VIEW, label: 'View sign stage' },
  {
    value: creatorPermissionEnums.SEND_FOR_FINALIZATION,
    label: 'Send for Finalization',
  },

  {
    value: creatorPermissionEnums.VIEW_APPROVAL_BASIC,
    label: 'View only stage and who’s approving/has to approve',
  },
  {
    value: creatorPermissionEnums.VIEW_APPROVAL_DETAILED,
    label: 'View all details of approval in Signing Phase',
  },

  {
    value: creatorPermissionEnums.ACTIVITY_LOG,
    label: 'View / Participate in activity feed',
  },
  { value: creatorPermissionEnums.REASSIGN_ANY, label: 'Reassign' },
  { value: creatorPermissionEnums.EDIT_FORM, label: 'Edit form information' },
  { value: creatorPermissionEnums.UPLOAD_VERSION, label: 'Upload new version' },
  { value: creatorPermissionEnums.REVIEWER, label: 'View document' },
  { value: creatorPermissionEnums.DOWNLOAD, label: 'Download document' },
  { value: creatorPermissionEnums.SHARE, label: 'Share document' },
  {
    value: creatorPermissionEnums.EDIT_PARTICIPANTS,
    label: 'Add participants',
  },

  {
    value: creatorPermissionEnums.UPLOAD_SIGNED,
    label: 'Upload signed document',
  },
  {
    value: creatorPermissionEnums.COLLECT_SIGNATURES,
    label: 'Collect Signatures',
  },
  { value: creatorPermissionEnums.RESET_ANY, label: 'Reset signatures' },
];

export const finalizeOptions = [
  { value: creatorPermissionEnums.FINALIZE_VIEW, label: 'View finalize stage' },
  {
    value: creatorPermissionEnums.COMPLETED_VIEW,
    label: 'View finalized ticket',
  },
  { value: creatorPermissionEnums.MARK_COMPLETE, label: 'Mark as Complete' },

  {
    value: creatorPermissionEnums.VIEW_APPROVAL_BASIC,
    label: 'View only stage and who’s approving/has to approve',
  },
  {
    value: creatorPermissionEnums.VIEW_APPROVAL_DETAILED,
    label: 'View all details of approval in Finalize Phase',
  },

  {
    value: creatorPermissionEnums.ACTIVITY_LOG,
    label: 'View / Participate in activity feed',
  },
  { value: creatorPermissionEnums.REASSIGN_ANY, label: 'Reassign' },
  { value: creatorPermissionEnums.EDIT_FORM, label: 'Edit form information' },
  { value: creatorPermissionEnums.UPLOAD_VERSION, label: 'Upload new version' },
  { value: creatorPermissionEnums.REVIEWER, label: 'View document' },
  { value: creatorPermissionEnums.DOWNLOAD, label: 'Download document' },
  { value: creatorPermissionEnums.SHARE, label: 'Share document' },
  {
    value: creatorPermissionEnums.EDIT_PARTICIPANTS,
    label: 'Add participants',
  },

  { value: creatorPermissionEnums.RESET_ANY, label: 'Reset Tasks (finalize)' },
  { value: creatorPermissionEnums.APPROVE_ANY, label: 'Approve Finalization' },
  { value: creatorPermissionEnums.REJECT_ANY, label: 'Reject Finalization' },
];

// TODO: cleanup and move this to TicketPermissionType enum and ticketPermissionOptions constants
export const ticketOptions = [
  { value: creatorPermissionEnums.TICKET_VIEW, label: 'View Ticket' },
  {
    value: creatorPermissionEnums.ACTIVITY_LOG,
    label: 'View / Participate in activity feed',
  },
  { value: creatorPermissionEnums.REASSIGN_ANY, label: 'Reassign' },
  { value: creatorPermissionEnums.EDIT_FORM, label: 'Edit form information' },
  { value: creatorPermissionEnums.UPLOAD_VERSION, label: 'Upload new version' },
  { value: creatorPermissionEnums.DOWNLOAD, label: 'Download document' },
  { value: creatorPermissionEnums.SHARE, label: 'Share document' },
  {
    value: creatorPermissionEnums.EDIT_PARTICIPANTS,
    label: 'Add participants',
  },
];

export function mergeImplicitFields(workflow) {
  const fields = workflow.fields;
  workflow.implicitFields.forEach((implicitField) => {
    const { name, customSettings, description, type } = implicitField;
    fields[name] = {
      id: name,
      name,
      customSettings,
      description,
      isImplicit: true,
      type,
    };
  });
  return {
    ...workflow,
    fields,
  };
}

function dropImplicitFields(workflow) {
  const fields = Object.values(workflow.fields).reduce((acc, field) => {
    if (!testImplicitField(field)) {
      acc[field.id] = field;
    }
    return acc;
  }, {});
  return {
    ...workflow,
    fields,
  };
}

function dropUnsavedQuestions(workflow) {
  const form = workflow.form;

  const sections = form.sections.map((section) => {
    return {
      ...section,
      questions: section.questions.filter((question) => !question.creating),
    };
  });

  return {
    ...workflow,
    form: {
      ...form,
      sections,
    },
  };
}

function dropUnspecifiedConditionRules(workflow) {
  const { conditions, form, stages } = workflow;
  const { sign, review, finalize } = stages;

  function dropConditionRule(sections, itemKey) {
    return sections.map((section) => {
      return {
        ...section,
        [itemKey]: section[itemKey].map((item) => {
          const { conditionRule } = item;
          return {
            ...item,
            conditionRule: testInvalidConditionRule(conditionRule, conditions)
              ? null
              : conditionRule,
          };
        }),
      };
    });
  }

  const fields = Object.values(workflow.fields).reduce((acc, field) => {
    acc[field.id] = field;
    if (
      testConditionalTextField(field) &&
      testInvalidConditionRule(field.conditionRule, conditions)
    ) {
      acc[field.id] = {
        ...field,
        conditionRule: null,
      };
    }
    return acc;
  }, {});

  return {
    ...workflow,
    fields,
    form: {
      ...form,
      sections: dropConditionRule(form.sections, 'questions'),
    },
    stages: {
      ...stages,
      sign: {
        ...sign,
        phases: dropConditionRule(sign.phases, 'signers'),
      },
      review: {
        ...review,
        phases: dropConditionRule(review.phases, 'approvals'),
      },
      finalize: {
        ...finalize,
        phases: dropConditionRule(finalize.phases, 'approvals'),
      },
    },
  };
}

function dropUnsavedSigners(workflow) {
  const { sign } = workflow.stages;
  const signStage = {
    ...sign,
    phases: sign.phases.map((phase) => ({
      ...phase,
      signers: phase.signers.filter((signer) => signer.id),
    })),
  };
  return {
    ...workflow,
    stages: {
      ...workflow.stages,
      sign: signStage,
    },
  };
}

function dropVersions(workflow) {
  const workflowWithoutVersions = { ...workflow };
  delete workflowWithoutVersions.versions;
  return workflowWithoutVersions;
}

function dropInvalidDataFieldsMapping(workflow) {
  const dataFieldsMapping = workflow.settings.dataFieldsMapping;
  let validDataFieldsMapping = {};
  Object.keys(dataFieldsMapping).forEach(function (key) {
    if (dataFieldsMapping[key] !== null && workflow.fields[key])
      validDataFieldsMapping[key] = dataFieldsMapping[key];
  });

  return {
    ...workflow,
    settings: {
      ...workflow.settings,
      dataFieldsMapping: validDataFieldsMapping,
    },
  };
}

// This method will be deprecated and it exists only to aid in refactoring the API.
export const DEPRECATED_coerceSigners = (workflow) => {
  return {
    ...workflow,
    signers: Object.fromEntries(
      Object.entries(workflow.signers).map(([signerId, signer]) => [
        signerId,
        { ...signer, userId: signer.userId || null },
      ]),
    ),
  };
};

// This method will be deprecated and it exists only to aid in refactoring the API.
export const DEPRECATED_dropNoneRoleBasedAttributes = (workflow) => {
  const review = { ...workflow.stages.review };
  const sign = { ...workflow.stages.sign };
  const finalize = { ...workflow.stages.finalize };
  delete review.coordinator;
  delete review.coordinatorId;
  delete sign.coordinator;
  delete sign.coordinatorId;
  delete finalize.coordinator;
  delete finalize.coordinatorId;
  return {
    ...workflow,
    signers: Object.entries(workflow.signers).reduce(
      (acc, [signerId, signer]) => {
        const { userId: _unused, ...signerWithoutUserIdAttribute } = signer;
        acc[signerId] = signerWithoutUserIdAttribute;
        return acc;
      },
      {},
    ),
    stages: {
      ...workflow.stages,
      review,
      sign,
      finalize,
    },
  };
};

// TODO: The backend should handle this and ideally store the UI state as is.  This allows the UI to be reproduced faithfully when users revist where they left off.  In addition, it will also take advantage of the UI error checking which currently highlights these errors in the UI, but is now dropping the values out when we save a draft because the backend does not accept these situations.  To the user, they should be able to save a draft (with errors), and the UI is able to indicate them to areas with errors.  The errors should not disable saving the draft, but should only disable publishing the workflow.  The current code here is a compromise of scope that the FE is helping cover for the BE.
export function cleanupWorkflow(workflow, shouldPublish) {
  workflow = dropInvalidDataFieldsMapping(workflow);
  workflow = dropImplicitFields(workflow);
  workflow = dropUnsavedQuestions(workflow);
  workflow = dropUnsavedSigners(workflow);
  workflow = dropUnspecifiedConditionRules(workflow);
  workflow = dropVersions(workflow);
  workflow = DEPRECATED_coerceSigners(workflow);
  workflow = DEPRECATED_dropNoneRoleBasedAttributes(workflow);

  if (shouldPublish) {
    workflow = coerceEmptyWorkflowValues(workflow);
  }
  return workflow;
}

// if users have left out empty values, coerce these to meaningful values consistent to what is displayed on the UI.  Should be used before publishing.
export function coerceEmptyWorkflowValues(workflow) {
  const { form } = workflow;
  const { finalize, review, sign } = workflow.stages;
  form.sections.forEach((section, sectionIndex) => {
    section.name = coerceName(section.name, 'Form Section', sectionIndex);
  });
  finalize.phases.forEach((phase, phaseIndex) => {
    phase.title = coerceName(phase.title, 'Finalize Phase', phaseIndex);
    phase.approvals.forEach((approval, approvalIndex) => {
      approval.name = coerceName(approval.name, 'Finalize Task', approvalIndex);
    });
  });
  review.phases.forEach((phase, phaseIndex) => {
    phase.title = coerceName(phase.title, 'Review Phase', phaseIndex);
    phase.approvals.forEach((approval, approvalIndex) => {
      approval.name = coerceName(approval.name, 'Review Task', approvalIndex);
    });
  });
  sign.phases.forEach((phase, phaseIndex) => {
    phase.title = coerceName(phase.title, 'Signing Phase', phaseIndex);
    const newApprovals = phase.approvals.map((approval, approvalIndex) => ({
      ...approval,
      name: coerceName(approval.name, 'Signer', approvalIndex),
    }));
    phase.approvals = newApprovals;
  });
  return workflow;
}

export function createPartiesSigners() {
  const parties = {};
  const signers = {};
  const newParty = createParty({
    type: WorkflowPartyType.Party,
    name: 'Internal Party 1',
  });
  const newParty2 = createParty({
    type: WorkflowPartyType.Counterparty,
    name: 'Counterparty 1',
  });
  const newSigner = {
    id: uuid(),
    name: 'Signer 1',
    partyId: newParty.id,
    index: 0,
  };
  const newSigner2 = {
    id: uuid(),
    name: 'Signer 2',
    partyId: newParty2.id,
    index: 1,
  };

  parties[newParty.id] = newParty;
  parties[newParty2.id] = newParty2;
  signers[newSigner.id] = newSigner;
  signers[newSigner2.id] = newSigner2;
  return {
    parties,
    signers,
  };
}

function createParty({ type, name }) {
  return { id: uuid(), name, type };
}

export function getFieldIdsByTrimmedKey(fields, labelKey) {
  return Object.values(fields).reduce((acc, field) => {
    if (field[labelKey]) {
      const trimmed = toLowercaseTrimmed(field[labelKey]);
      if (!acc[trimmed]) {
        acc[trimmed] = [];
      }
      acc[trimmed].push(field.id);
    }
    return acc;
  }, {});
}

export function testIsFieldNameUsed(fields, name, excludedName) {
  const fieldIdsByFieldName = getFieldIdsByTrimmedKey(fields, 'name');
  const isFieldNameUsed =
    fieldIdsByFieldName[toLowercaseTrimmed(name)]?.length > 0;
  return excludedName
    ? toLowercaseTrimmed(excludedName) !== toLowercaseTrimmed(name) &&
        isFieldNameUsed
    : isFieldNameUsed;
}

export function createFieldNameFromPlaceholder(placeholder) {
  return capitalizeWords(placeholder.split('_').join(' '));
}

/**
 * Business logic:
  fields    | internal signer    | external signer
  name      | not required       | required
  email     | not                | required
  title     | not                | not
  signature | when company paper | when company paper
  sign date | not                | not
*/
export function testSignerFieldDefinitionRequired(
  signer,
  fieldDefinition,
  workflowType,
) {
  const { entity, key } = fieldDefinition;

  if (entity !== 'signer') {
    return false;
  }

  const isSignatureRequired =
    [
      WorkflowIntakeFormType.Company,
      WorkflowIntakeFormType.CompanyAndCounterparty,
    ].includes(workflowType) && key === 'signature';

  switch (signer.partyType) {
    case WorkflowPartyType.Party:
      return isSignatureRequired;
    case WorkflowPartyType.Counterparty:
      return ['name', 'email'].includes(key) || isSignatureRequired;
    default:
      return false;
  }
}

/**
 * Merge fields and fieldLinks based on the following requirements:
 *
 * - Keep all custom fields as-is.
 * - Keep placeholder fields/fieldLinks if they match new placeholder fields.
 * - Remove placeholder fields/fieldLinks for entries not found in newFields.
 */
export function mergeFields(newFields, fields, fieldLinks) {
  // keep all properties of old field and selectively overwrite special fields (e.g. conditionalText);
  function mergeField(newField = {}, oldField) {
    const mergedField = { ...oldField };
    mergedField.conditionalText = newField.conditionalText;
    // only when old field type is CONDITONAL_TEXT, is it always safe to rewrite with the newField.type
    if (oldField.type === WorkflowFieldType.ConditionalText) {
      mergedField.type = newField.type;
    } else {
      mergedField.type = newField.type || oldField.type;
    }
    return mergedField;
  }
  const newPlaceholderFieldsMapping = Object.values(newFields).reduce(
    (acc, field) => {
      const { placeholder } = field;
      if (placeholder) {
        if (!acc[placeholder]) {
          acc[placeholder] = [];
        }
        acc[placeholder].push(field);
      }
      return acc;
    },
    {},
  );

  const merged = Object.values(fields).reduce(
    ([mergedFields, mergedFieldLinks], field) => {
      const { id, placeholder } = field;
      const matchingFields = newPlaceholderFieldsMapping[placeholder] || [];
      const hasMatchingPlaceholder = matchingFields.length > 0;
      const matchingField = matchingFields[0] || {};
      // keep placeholder fields and fieldLinks that match new placeholder fields
      if (testCustomField(field) || hasMatchingPlaceholder) {
        const mergedField = mergeField(matchingField, field);
        mergedFields[id] = mergedField;
        // if a field is converted to a conditional text field, drop its previous link.
        if (fieldLinks[id] && !testConditionalTextField(mergedField)) {
          mergedFieldLinks[id] = fieldLinks[id];
        }
      }
      return [mergedFields, mergedFieldLinks];
    },
    [{}, {}],
  );
  const [mergedFields, mergedFieldLinks] = merged;

  // add new placeholder fields that do not match new placeholder fields
  const currentPlaceholderSet = Object.values(fields).reduce((acc, field) => {
    const { placeholder } = field;
    if (placeholder) {
      acc.add(placeholder);
    }
    return acc;
  }, new Set());
  Object.keys(newPlaceholderFieldsMapping).forEach((placeholder) => {
    if (!currentPlaceholderSet.has(placeholder)) {
      const newFields = newPlaceholderFieldsMapping[placeholder];
      newFields.forEach((newField) => {
        mergedFields[newField.id] = newField;
      });
    }
  });

  return [mergedFields, mergedFieldLinks];
}

export function isLatestVersion(workflow) {
  const latestVersion = Math.max(
    ...workflow.versions.map((version) => version.number),
  );
  return workflow.versionNumber === latestVersion;
}

// TODO: product to confirm behaviors for missing/unamed fields to be consistently applied in the UI.
export function getFieldDisplayName(field) {
  if (!field) {
    return 'Missing field';
  } else if (!field.name) {
    return 'Unnamed field';
  } else {
    return field.name;
  }
}

export function pathObjectToString(path, fields) {
  return path
    .map((token) => {
      if (token.type === 'field') {
        return getFieldDisplayName(fields[token.value]);
      }
      return token.value;
    })
    .join('');
}

// the newly created ID for a condition rule is explicitly set to `null` because it can be updated later
export const createConditionRule = () => ({
  id: null,
  satisfiedWhenCondMet: true,
  enabledWhenSatisfied: true,
});

// to check if every counterparty signer has both questions for name and email fields,
// we can just check if every counterparty signer name or email field has linked questions
export function testLackCounterpartySignerQuestion(workflow) {
  const { form, fieldLinks, parties, signers } = workflow;
  const { sections } = form;

  const counterpartySignerIds = Object.values(signers)
    .filter(
      (signer) =>
        parties[signer.partyId].type === WorkflowPartyType.Counterparty,
    )
    .map((signer) => signer.id);

  const requiredFieldTypes = ['name', 'email'];
  let requiredFieldIds = [];
  for (const [key, value] of Object.entries(fieldLinks)) {
    if (
      counterpartySignerIds.includes(value.id) &&
      requiredFieldTypes.includes(value.key)
    ) {
      requiredFieldIds.push(key);
    }
  }

  const questions = sections.map((section) => section.questions).flat();

  const questionFieldIds = Object.values(questions).map(
    (question) => question.fieldId,
  );

  return requiredFieldIds.some(
    (requiredFieldId) => !questionFieldIds.includes(requiredFieldId),
  );
}

/**
 * Returns a joined and lowercased file extension (usable in FileInput.accept) given workflow.acceptedFileTypes.  Returns the default file extensions if no file types are specified.
 * @param {object} workflow
 *    Partially typed object to aid usage of util.
 * @param {string[]} workflow.acceptedFileTypes
 *    An array of file extensions (`.`-inclusive)
 * @param {FileExtensionType[]} [defaultFileExtensions]
 *    An array of file extensions (`.`-inclusive)
 * @param {FileExtensionType[]} [requiredFileExtensions]
 *    An array of required file extensions to always include.
 * @returns {string}
 *    Joined file extension (`.`-inclusive)
 */
export function getAcceptedFiles(
  workflow,
  defaultFileExtensions = [FileExtensionType.Doc, FileExtensionType.Docx],
  requiredFileExtensions = [],
) {
  const { acceptedFileTypes } = workflow;
  const fileExtensions =
    acceptedFileTypes.length > 0 ? acceptedFileTypes : defaultFileExtensions;
  return [...fileExtensions, ...requiredFileExtensions]
    .map((x) => '.' + coerceFileType(x).toLowerCase())
    .join(', ');
}

export const DefaultCustomSettings = {
  defaultValue: {},
  options: [],
};

export function getDefaultCustomSettingsByFieldType(fieldType) {
  let customSettings = { ...DefaultCustomSettings };

  const customSettingKey = customSettingsKeys[fieldType];
  const customSettingsOptionValue = customSettingsOptionValues[fieldType];
  const defaultValue = {};

  switch (fieldType) {
    case WorkflowFieldType.Address: {
      customSettings[customSettingKey.DOCUMENT_PLACEMENT] =
        customSettingsOptionValue.DOCUMENT_PLACEMENT.SINGLE_LINE;
      defaultValue[valueKeys[fieldType].COUNTRY] = 'United States';
      break;
    }
    case WorkflowFieldType.Date: {
      customSettings[customSettingKey.FORMAT] = DateFormatType.Long;
      break;
    }
    case WorkflowFieldType.Department:
    case WorkflowFieldType.User: {
      customSettings[customSettingKey.RULE] =
        customSettingsOptionValue.RULE.ALL;
      break;
    }
    case WorkflowFieldType.Email: {
      customSettings[customSettingKey.EMAIL_TYPE] =
        customSettingsOptionValue.EMAIL_TYPE.NONE;
      break;
    }
    case WorkflowFieldType.File: {
      customSettings[customSettingKey.FILE_LIMIT] =
        customSettingsOptionValue.FILE_LIMIT.UNLIMITED;
      customSettings[customSettingKey.ALLOWED_FILE_TYPE_RULE] =
        customSettingsOptionValue.FILE_TYPE_RULE.ANY;
      break;
    }
    case WorkflowFieldType.SingleSelect: {
      customSettings[customSettingKey.ENABLE_CREATE_OPTIONS] = true;
      break;
    }
    case WorkflowFieldType.MonetaryValue: {
      customSettings[customSettingKey.CURRENCY_RULE] =
        customSettingsOptionValue.CURRENCY_RULE.ANY;
      defaultValue[valueKeys[fieldType].CURRENCY] = 'USD';
      break;
    }
    case WorkflowFieldType.TimePeriod: {
      customSettings.options = timePeriodFieldDefaultOptions;
      break;
    }
    default:
      break;
  }

  return { ...customSettings, defaultValue };
}
